import {  Typography } from "@mui/material"
import { ModalName } from "../Constants/app-enums"
import { ModalSizes } from "../Constants/client-side-enums"
import GlobalModal from "../Requests/Modals/global-modal"
import { ModalFooterWithCustomSave, ModalFooterWithSave, ModalHeader, StyledDialogContent, globalClose } from "../Requests/Modals/modal-components"
import { StyledFilledButton, StyledSectionOutlineButton } from "../Utility/shared-components"
import { batch, useDispatch, useSelector } from "react-redux"
import { selectModalPresent } from "../ReduxRelatedUtils/customConfigureStore"
import { ApplicationState, Modal } from "../ReduxRelatedUtils/redux-types"
import { ConfirmExitModalInfo } from "../Utility/general-types"
import { push } from "connected-react-router"
import path from "path"
import queryString from 'query-string'
import { setShowConfirmExit } from "../ReduxRelatedUtils/utilsReducer"
import { removeModal } from "../ReduxRelatedUtils/modals-reducer"

export function ConfirmExitModal() {
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<ConfirmExitModalInfo>;
    const dispatch = useDispatch();

    function onExitClick(){
        batch(()=>{
            dispatch(push(modalInfo.pathname+"?"+queryString.stringify(modalInfo.navigationInfo)))
            dispatch(setShowConfirmExit(false))
            dispatch(removeModal())
        })
        globalClose(true, dispatch);
    }
    
    return (
        <GlobalModal size={ModalSizes.xs} modalKey={ModalName.ConfirmExitModal} closeClick={()=>globalClose(true, dispatch)}>

            <ModalHeader headerText="Confirm Exit" errorMessage=""  />

            <StyledDialogContent>
                <Typography paddingBottom="2rem">Are You Sure You Would Like To Exit Without Saving Changes?</Typography>

            </StyledDialogContent>         
            
            <ModalFooterWithCustomSave closeClick={()=>globalClose(true, dispatch)} >                  
                <StyledFilledButton onClick={onExitClick} >Exit</StyledFilledButton>
            </ModalFooterWithCustomSave>

        </GlobalModal>

    )
}