import { Inventory } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { ItemCardFields, ModalFlow, ModalName, OrderMethod, PageTypeEnum } from "../../Constants/app-enums";
import { CardTabsType, TabsName } from "../../Constants/client-side-enums";
import { selectEditDrawerViewModelByID, selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { setError } from "../../ReduxRelatedUtils/errorReducer";
import { setPageSkeleton } from "../../ReduxRelatedUtils/utilsReducer";
import { setModal } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal, NavigationInfo, TempData } from "../../ReduxRelatedUtils/redux-types";
import { removeEditDrawerViewModel, setEditDrawerViewModel } from "../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { setViewModel } from "../../ReduxRelatedUtils/viewmodel-reducer";
import AddCardForm from "../../Shared/AddCardComponents/add-card";
import { AddCardType, AddItemFormData } from "../../Shared/AddCardComponents/add-types";
import { CardTabs, Tab } from "../../Shared/AddCardComponents/card-tabs";
import IndexTableSkeleton from "../../Shared/IndexTable/index-table-skeleton";
import { StyledFormInputTitle } from "../../Utility/custom-input-components";
import { Comment } from "../../Utility/general-types";
import { Request, RequestItemViewModel } from "../../Utility/request-types";
import { DocumentTypeWithID, extractErrorValues, getAddItemCardFieldTypesByCategory, getDocumentTypesByCategory, itemPost } from "../../Utility/root-function";
import { StyledError, StyledFilledButton } from "../../Utility/shared-components";
import { SelectLocationFormData } from "../Modals/receive-location-modal";
import CommentsTab from "./comments-tab";
import { ItemTabs } from "./item-card-tabs";
import { DocumentsTab, getTabs } from "./item-components";
import { LocationTab } from "./location-tab";
import OrderTab from "./order-tab";
import PriceTab from "./price-tab";


export function AddItemInnerForm() {
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state,"0")?.viewModel) as RequestItemViewModel;
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const navigationInfo = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo;
    //console.log(viewModel);
    const [view, setView] = useState<{ fields: ItemCardFields[], docCards: DocumentTypeWithID[] }>();
    const { control, formState, watch, getValues, reset, setValue, handleSubmit } = useFormContext<TempData<AddItemFormData>>();
    const guid = useSelector<ApplicationState>(state => state.tempData.present.guid) as string;
    const dispatch = useDispatch();
    const parentCategory= watch("request.product.productSubcategory.parentCategory");
    const productSubcategoryID = watch("request.product.productSubcategoryID");
    const [tabs, setTabs] = useState<Tab[]>([])
    const showPageSkeleton  = useSelector<ApplicationState>(state=> state.showPageSkeleton)
    const request = watch("request")
    useEffect(() => {
        setTabs(getTabs(false, navigationInfo.pageType == PageTypeEnum.RequestInventory));
    }, [])


    useEffect(() => {
        dispatch(setPageSkeleton(true))

       // console.log("parent category change")
       dispatch(removeEditDrawerViewModel({id:"0"}));
        fetch("/Requests/GetAddItemJson"+location.search,{
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: RequestItemViewModel) => {
                batch(()=>{
                dispatch(setEditDrawerViewModel({viewModel:result, id:"0", reloadEditModal:false}));
                dispatch(setPageSkeleton(false))
                })

                setView({
                    fields: getAddItemCardFieldTypesByCategory(result.request?.product?.productSubcategory?.parentCategory?.parentCategoryDescriptionEnum) ?? [],
                    docCards: getDocumentTypesByCategory(guid, result.request.requestID, result.request?.product?.productSubcategory?.parentCategory?.parentCategoryDescriptionEnum) ?? []
                })
                setValue("request.exchangeRate", result.request?.exchangeRate ?? 1)
                setValue("isSample", result.isSample)

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text.toString(), showBody: false }))
                })
            })

    }, []);


    
    useEffect(() => {
        if(parentCategory!=undefined){
            setView({
                fields: getAddItemCardFieldTypesByCategory(parentCategory?.parentCategoryDescriptionEnum) ?? [],
                docCards: getDocumentTypesByCategory(guid, request.requestID, parentCategory?.parentCategoryDescriptionEnum) ?? []
            })
            
        }
       
    }, [parentCategory]);

    useEffect(() => {
        let productSubcategory = viewModel?.productSubcategories?.filter(p=>p.id== productSubcategoryID)?.[0];
        if(productSubcategory!=undefined){
            let parentCategory = viewModel?.parentCategories?.filter(p=>p.id== productSubcategory.parentCategoryID)?.[0];       

            setView({
                fields: getAddItemCardFieldTypesByCategory(parentCategory?.parentCategoryDescriptionEnum, productSubcategory?.description) ?? [],
                docCards: getDocumentTypesByCategory(guid, viewModel?.request?.requestID, parentCategory?.parentCategoryDescriptionEnum, productSubcategory?.description) ?? []
            })
        }
       

    }, [productSubcategoryID]);

    useEffect(() => {

        dispatch(setModal({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.None } as Modal))
    }, []);


    useEffect(() => {
        setValue("guid", guid)
    }, [guid]);


    return (   <>
        {showPageSkeleton?
        <Box padding="2rem" height="75vh" overflow={"hidden"}>
        <IndexTableSkeleton/>
        </Box>
        :
        <>
            <Box sx={{ padding: "3rem" }}>
                <Stack direction="row" justifyContent={"space-between"}>
                    <StyledFormInputTitle placeholder="Product Name" name="request.product.productName" data-testid="product-name" />
                    {navigationInfo.pageType == PageTypeEnum.RequestInventory && <StyledFilledButton className={(formState.isSubmitting || !formState.isValid) ? "disabled-section-button" : ""} type="submit" name="Save">Save</StyledFilledButton>}
                </Stack>
            </Box>

            <CardTabs key={CardTabsType.AddCard} cardTabType={CardTabsType.AddCard} selectedTab={0} tabs={tabs} >
                <ItemTabs key={"itemtabs"} tabs={tabs} view={view} />
            </CardTabs>
        </>}</>

    )
}


export default function AddItem() {
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo
    function onSubmit(data: TempData<AddItemFormData>, e: any) {
  

        itemPost(e.nativeEvent.submitter.name as OrderMethod, data, dispatch, navigationInfo);
        

    }

    return (
        <AddCardForm addType={AddCardType.AddItem} onSubmit={(data, e) => onSubmit(data, e)} />

    )
}



