import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Skeleton from 'react-loading-skeleton';

export default function IndexTableSkeleton(){
    return(
        <Box data-testid="index-skeleton">
        <Stack spacing={2} direction = "column">
            <Skeleton/>
            <Skeleton style={{marginTop:"1rem"}} height="6rem" count={10}/>
        </Stack>
        </Box>
    )
}