import { Grid } from "@mui/material"
import { StyledHeader, StyledSubheader } from "../../Utility/shared-components"
import { IndexViewModel } from "../../Utility/general-types"

export default function PageTitle ({viewModel} : {viewModel: IndexViewModel} ){
    return (
        <>
            <Grid item >
                <StyledHeader > {viewModel.headerText}</StyledHeader>
            </Grid>
            <Grid item >
                <StyledSubheader> {viewModel.subheaderText}</StyledSubheader>
            </Grid>
        </>
    )
}
