import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import queryString from 'query-string';

import { Controller, useForm } from 'react-hook-form';
import { AnySchema, boolean, date, object, string } from 'yup';
import { AddItemResult, FolderNamesEnum, ModalFlow, ModalName, SidebarEnum } from '../../Constants/app-enums';
import { selectModalLoading, selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState, Modal, ModalInfo, NavigationInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError, EditEmailModalInfo } from '../../Utility/general-types';
import { ParentRequest } from '../../Utility/request-types';
import { GlobalForm, SpacedColumnStack, StyledError } from '../../Utility/shared-components';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import { TermsFormData } from './terms-modal';
import { push } from 'connected-react-router';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading, setShowConfirmExit } from '../../ReduxRelatedUtils/utilsReducer';
import { MuiChipsInput } from 'mui-chips-input';


type EditEmailViewModel = {
    emailAddresses: string[],
    emailSubject: string,
    emailContent: string
}
type EditEmailFormData = {

}
export default function EditEmailModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const [error, setError] = useState<CustomError>(new CustomError());
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<EditEmailModalInfo>;
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<TermsFormData>
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo
    const [editSubject, setEditSubject] = useState(false)
    const [editContent, setEditContent] = useState(false)

    const validationSchema = object<Partial<Record<keyof TermsFormData, AnySchema>>>({
        emailSubject: string().required("Email Subject is required"),
        emailContent: string().required("Email Content is required")
    })
    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:
            {
                emailAddresses: [] as string[],
                emailContent: "",
                emailSubject: ""
            } as TermsFormData
    }
    );
    const emails = methods.watch("emailAddresses")

    function onSubmit(data: TermsFormData) {
        console.log("on submit upload order")
        console.log(data)
        let tempDataCopy = { ...tempData, emailAddresses: data.emailAddresses, emailContent:data.emailContent, emailSubject:data.emailSubject} as TempData<TermsFormData>
        var url = '/Requests/EditEmailModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(tempDataCopy),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((res: { result: AddItemResult }) => {
                dispatch(setLoading(false))
                switch (res.result) {
                    case AddItemResult.Ordered:
                        batch(() => {

                            dispatch(removeModal())
                            dispatch(setGlobalSnack({ open: true, message: "Item Ordered!" }))
                            if (navigationInfo.sidebarType == SidebarEnum.Add) {
                                dispatch(setShowConfirmExit(false))
                                dispatch(push("/RequestsList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))
                            }
                            else {
                                dispatch(setReloadIndex(true))
                            }   
                        })

                        break;
                }

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.errorMessage, severity: "error" }))
                })
            })
    }

    useEffect(() => {
        var url = "/Requests/EditEmailModal?orderNumber="+ modalInfo.orderNumber +"&selectedShippingAddress=" + tempData.selectedShippingAddress
        +(tempData.addItemFormData.request?"&vendorID="+tempData.addItemFormData.request.product.vendorID
        +"&quoteNumber="+ tempData.addItemFormData.request.parentQuote.quoteNumber
        :
        "&requestIDs="+tempData.requestIDs);
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: EditEmailViewModel) => {
                methods.setValue("emailAddresses", result.emailAddresses)
                methods.setValue("emailSubject", result.emailSubject)
                methods.setValue("emailContent", result.emailContent)

                dispatch(setModalLoading(false))

                })
                .catch(err => {
                    console.log("in error")
                    Promise.resolve(err).then(text => {
                        setError({ message: text.errorMessage, showBody: false })
                        dispatch(setModalLoading(false))
                    })
                })
             

    }, []);

    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Edit Email"} errorMessage={error.message} />
                    {error.showBody &&
                        <>
                            <StyledDialogContent>
                                <GlobalForm formID={ModalName.EditEmail} onSubmit={onSubmit} methods={methods}>
                                    <SpacedColumnStack>
                                        <FormControl fullWidth variant="standard"><>
                                            <Controller
                                                control={methods.control}
                                                name="emailAddresses"
                                                rules={{ required: true }}
                                                
                                                render={() => (
                                                    <MuiChipsInput label="Send To: (Add Up To 5 Email Addresses- Including The Suppliers Email Address)"
                                                        variant='standard'
                                                        InputLabelProps={
                                                            { shrink: true }
                                                        }
                                                        InputProps={{
                                                            className: "section-input",
                                                        }}
                                                        fullWidth value={emails}
                                                        onChange={(newEmails) => {
                                                            if (newEmails.length <= 5 && newEmails.length > 0) {
                                                                methods.setValue("emailAddresses", newEmails);
                                                            }
                                                        }}
                                                        disableEdition
                                                        validate={(chipValue) => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(chipValue)}
                                                        hideClearAll
                                                        disableDeleteOnBackspace
                                                        clearInputOnBlur
                                                    />
                                                )} />
                                            <StyledError>{methods.formState.errors.emailAddresses?.message}</StyledError>
                                        </>
                                        </FormControl>
                                        <FormControl fullWidth variant="standard">
                                            <Controller
                                                control={methods.control}
                                                name="emailSubject"
                                                rules={{ required: true }}
                                                
                                                render={({field}) => (
                                        <TextField
                                            {...field}
                                            label="Email Subject"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                className: "section-input",
                                                inputProps:{
                                                    readOnly:!editSubject
                                                 },
                                                sx: {
                                                    paddingRight: "0.8rem",
                                                    marginTop: "1.3rem !important"
                                                },
                                                endAdornment: (
                                                    error && <InputAdornment position="end">
                                                        <Typography fontSize={"1rem"} className='section-color' onClick={() => setEditSubject(!editSubject)}>{editSubject ? "Save" : "Edit"}</Typography>
                                                    </InputAdornment>)
                                            }}
                                        />)}
                                        />
                                        </FormControl>
                                        <FormControl fullWidth variant="standard">
                                            <Controller
                                                control={methods.control}
                                                name="emailContent"
                                                rules={{ required: true }}
                                                
                                                render={({field}) => (
                                        <TextField
                                            {...field}
                                            label="Email Content"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            multiline
                                            InputProps={{
                                                className: "section-input",
                                                 inputProps:{
                                                   readOnly:!editContent
                                                },
                                                sx: {
                                                    paddingRight: "0.8rem",
                                                    marginTop: "1.3rem !important"
                                                },
                                                endAdornment: (
                                                    error && <InputAdornment position="end">
                                                        <Typography fontSize={"1rem"} className='section-color' onClick={() => setEditContent(!editContent)}>{editContent ? "Save" : "Edit"}</Typography>
                                                    </InputAdornment>)
                                            }}
                                        />)}
                                        />
                                        </FormControl>
                                    </SpacedColumnStack>
                                </GlobalForm>
                            </StyledDialogContent>

                            <ModalFooterWithSave formID={ModalName.EditEmail} submitButtonText={"Submit"} >
                                <ModalBackButton modalFlowKey={ModalFlow.AddItem} undoTempData={true} />
                            </ModalFooterWithSave>
                        </>

                    }

                </>
            }
        </>
    )

}