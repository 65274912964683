import { Edit } from "@mui/icons-material";
import { Drawer } from "@mui/material";

export enum ModalSizes{
    xs,
    sm,
    md,
    lg,
    xl
}


export enum CardTabsType {
    EditDrawer,
    AddCard
}








export enum TabsName {
    Locations = "Locations",
    WorkScope = "Work Scope",
    Security = "Security",
    Permissions = "Permissions",
    Budget = "Budget",
    Comments = "Comments", Price = "Price", Item = "Item", Documents = "Documents", Order = "Order", Details = "Details", Payment = "Payment", Contact = "Contact",
    History = "History",
    Payments = "Payments"
}

