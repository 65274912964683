export type ElixirSvg = {
icon: any,
name: string
}
export const Svgs = 
[
{
 name: 'AccountBox',
 icon: require('./svgIcons/AccountBox.svg')
},
{
 name: 'Add',
 icon: require('./svgIcons/Add.svg')
},
{
 name: 'AddBox',
 icon: require('./svgIcons/AddBox.svg')
},
{
 name: 'AddCircle',
 icon: require('./svgIcons/AddCircle.svg')
},
{
 name: 'AddCircleOutline',
 icon: require('./svgIcons/AddCircleOutline.svg')
},
{
 name: 'AddTask',
 icon: require('./svgIcons/AddTask.svg')
},
{
 name: 'Approve',
 icon: require('./svgIcons/Approve.svg')
},
{
 name: 'Archive',
 icon: require('./svgIcons/Archive.svg')
},
{
 name: 'ArrowBackIos',
 icon: require('./svgIcons/ArrowBackIos.svg')
},
{
 name: 'ArrowDropDown',
 icon: require('./svgIcons/ArrowDropDown.svg')
},
{
 name: 'ArrowForwardIos',
 icon: require('./svgIcons/ArrowForwardIos.svg')
},
{
 name: 'Assignment',
 icon: require('./svgIcons/Assignment.svg')
},
{
 name: 'AttachFile',
 icon: require('./svgIcons/AttachFile.svg')
},
{
 name: 'AttachItem',
 icon: require('./svgIcons/AttachItem.svg')
},
{
 name: 'AttachProtocol',
 icon: require('./svgIcons/AttachProtocol.svg')
},
{
 name: 'Block',
 icon: require('./svgIcons/Block.svg')
},
{
 name: 'BlockNew',
 icon: require('./svgIcons/BlockNew.svg')
},
{
 name: 'Book',
 icon: require('./svgIcons/Book.svg')
},
{
 name: 'Build',
 icon: require('./svgIcons/Build.svg')
},
{
 name: 'BuildingAddress',
 icon: require('./svgIcons/BuildingAddress.svg')
},
{
 name: 'Business',
 icon: require('./svgIcons/Business.svg')
},
{
 name: 'Calibrate',
 icon: require('./svgIcons/Calibrate.svg')
},
{
 name: 'CameraAlt',
 icon: require('./svgIcons/CameraAlt.svg')
},
{
 name: 'CancelPresentation',
 icon: require('./svgIcons/CancelPresentation.svg')
},
{
 name: 'Categories',
 icon: require('./svgIcons/Categories.svg')
},
{
 name: 'Category',
 icon: require('./svgIcons/Category.svg')
},
{
 name: 'CentarixBrokenLink',
 icon: require('./svgIcons/CentarixBrokenLink.svg')
},
{
 name: 'CentarixCloseItem',
 icon: require('./svgIcons/CentarixCloseItem.svg')
},
{
 name: 'CentarixComments',
 icon: require('./svgIcons/CentarixComments.svg')
},
{
 name: 'CentarixDropDownExpand',
 icon: require('./svgIcons/CentarixDropDownExpand.svg')
},
{
 name: 'CentarixFileDownload',
 icon: require('./svgIcons/CentarixFileDownload.svg')
},
{
 name: 'CentarixFileUpload',
 icon: require('./svgIcons/CentarixFileUpload.svg')
},
{
 name: 'CentarixFileView',
 icon: require('./svgIcons/CentarixFileView.svg')
},
{
 name: 'CentarixHumanTrials',
 icon: require('./svgIcons/CentarixHumanTrials.svg')
},
{
 name: 'CentarixInstallments',
 icon: require('./svgIcons/CentarixInstallments.svg')
},
{
 name: 'CentarixItemTab',
 icon: require('./svgIcons/CentarixItemTab.svg')
},
{
 name: 'CentarixKitProtocol',
 icon: require('./svgIcons/CentarixKitProtocol.svg')
},
{
 name: 'CentarixLists',
 icon: require('./svgIcons/CentarixLists.svg')
},
{
 name: 'CentarixMainTab',
 icon: require('./svgIcons/CentarixMainTab.svg')
},
{
 name: 'CentarixMoveList',
 icon: require('./svgIcons/CentarixMoveList.svg')
},
{
 name: 'CentarixNotificationApprove',
 icon: require('./svgIcons/CentarixNotificationApprove.svg')
},
{
 name: 'CentarixNotificationBirthday',
 icon: require('./svgIcons/CentarixNotificationBirthday.svg')
},
{
 name: 'CentarixNotificationCart',
 icon: require('./svgIcons/CentarixNotificationCart.svg')
},
{
 name: 'CentarixNotificationDidntArrive',
 icon: require('./svgIcons/CentarixNotificationDidntArrive.svg')
},
{
 name: 'CentarixNotificationLiked',
 icon: require('./svgIcons/CentarixNotificationLiked.svg')
},
{
 name: 'CentarixNotificationList',
 icon: require('./svgIcons/CentarixNotificationList.svg')
},
{
 name: 'CentarixNotificationOrdered',
 icon: require('./svgIcons/CentarixNotificationOrdered.svg')
},
{
    name: 'CentarixNotificationReceived',
    icon: require('./svgIcons/CentarixNotificationReceived.svg')
},{
    name: 'CentarixNotificationQuote',
    icon: require('./svgIcons/CentarixNotificationQuote.svg')
},
{
 name: 'CentarixNotificationsBorder',
 icon: require('./svgIcons/CentarixNotificationsBorder.svg')
},
{
 name: 'CentarixNotificationShared',
 icon: require('./svgIcons/CentarixNotificationShared.svg')
},
{
 name: 'CentarixNotificationsNumber',
 icon: require('./svgIcons/CentarixNotificationsNumber.svg')
},
{
 name: 'CentarixNotificationTime',
 icon: require('./svgIcons/CentarixNotificationTime.svg')
},
{
 name: 'CentarixNotificationTimekeeper',
 icon: require('./svgIcons/CentarixNotificationTimekeeper.svg')
},
{
 name: 'CentarixNotificationTopMenu',
 icon: require('./svgIcons/CentarixNotificationTopMenu.svg')
},
{
 name: 'CentarixOrderedTab',
 icon: require('./svgIcons/CentarixOrderedTab.svg')
},
{
 name: 'CentarixPageFirst',
 icon: require('./svgIcons/CentarixPageFirst.svg')
},
{
 name: 'CentarixPageLast',
 icon: require('./svgIcons/CentarixPageLast.svg')
},
{
 name: 'CentarixPageNext',
 icon: require('./svgIcons/CentarixPageNext.svg')
},
{
 name: 'CentarixPagePrevious',
 icon: require('./svgIcons/CentarixPagePrevious.svg')
},
{
 name: 'CentarixProtocolTab',
 icon: require('./svgIcons/CentarixProtocolTab.svg')
},
{
 name: 'CentarixResourcesTab',
 icon: require('./svgIcons/CentarixResourcesTab.svg')
},
{
 name: 'CentarixResults',
 icon: require('./svgIcons/CentarixResults.svg')
},
{
 name: 'CentarixResultsDropdown',
 icon: require('./svgIcons/CentarixResultsDropdown.svg')
},
{
 name: 'CentarixResultsTab',
 icon: require('./svgIcons/CentarixResultsTab.svg')
},
{
 name: 'CentarixSearchAdvanced',
 icon: require('./svgIcons/CentarixSearchAdvanced.svg')
},
{
 name: 'CentarixSearchFilter',
 icon: require('./svgIcons/CentarixSearchFilter.svg')
},
{
 name: 'CentarixSearchList',
 icon: require('./svgIcons/CentarixSearchList.svg')
},
{
 name: 'CentarixSharedListBlack',
 icon: require('./svgIcons/CentarixSharedListBlack.svg')
},
{
 name: 'CentarixSopProtocol',
 icon: require('./svgIcons/CentarixSopProtocol.svg')
},
{
 name: 'CentarixStandingOrders',
 icon: require('./svgIcons/CentarixStandingOrders.svg')
},
{
 name: 'CentarixStar',
 icon: require('./svgIcons/CentarixStar.svg')
},
{
 name: 'CentarixStarBlack',
 icon: require('./svgIcons/CentarixStarBlack.svg')
},
{
 name: 'CentarixStop',
 icon: require('./svgIcons/CentarixStop.svg')
},
{
 name: 'CentarixTabSelected',
 icon: require('./svgIcons/CentarixTabSelected.svg')
},
{
 name: 'CentarixTheoryTab',
 icon: require('./svgIcons/CentarixTheoryTab.svg')
},
{
 name: 'CentarixTip',
 icon: require('./svgIcons/CentarixTip.svg')
},
{
 name: 'CentarixTroubleshooting',
 icon: require('./svgIcons/CentarixTroubleshooting.svg')
},
{
 name: 'CentarixVisits',
 icon: require('./svgIcons/CentarixVisits.svg')
},
{
 name: 'CentarixYearly',
 icon: require('./svgIcons/CentarixYearly.svg')
},
{
 name: 'CheckBox',
 icon: require('./svgIcons/CheckBox.svg')
},
{
 name: 'CheckBoxOutlineBlank',
 icon: require('./svgIcons/CheckBoxOutlineBlank.svg')
},
{
 name: 'ChromeReaderMode',
 icon: require('./svgIcons/ChromeReaderMode.svg')
},
{
 name: 'Clear',
 icon: require('./svgIcons/Clear.svg')
},
{
 name: 'Clipboard',
 icon: require('./svgIcons/Clipboard.svg')
},
{
 name: 'Comment',
 icon: require('./svgIcons/Comment.svg')
},
{
 name: 'ConclusionsTab',
 icon: require('./svgIcons/ConclusionsTab.svg')
},
{
 name: 'ConfirmOrder',
 icon: require('./svgIcons/ConfirmOrder.svg')
},
{
 name: 'Create',
 icon: require('./svgIcons/Create.svg')
},
{
 name: 'CreateProject',
 icon: require('./svgIcons/CreateProject.svg')
},
{
 name: 'CreditCard',
 icon: require('./svgIcons/CreditCard.svg')
},
{
 name: 'Dashboard',
 icon: require('./svgIcons/Dashboard.svg')
},
{
 name: 'Delete',
 icon: require('./svgIcons/Delete.svg')
},
{
 name: 'DeleteNew',
 icon: require('./svgIcons/DeleteNew.svg')
},
{
 name: 'Description',
 icon: require('./svgIcons/Description.svg')
},
{
 name: 'Done',
 icon: require('./svgIcons/Done.svg')
},
{
 name: 'DoneBox',
 icon: require('./svgIcons/DoneBox.svg')
},
{
 name: 'Download',
 icon: require('./svgIcons/Download.svg')
},
{
 name: 'EditDoc',
 icon: require('./svgIcons/EditDoc.svg')
},
{
 name: 'EditListBlack',
 icon: require('./svgIcons/EditListBlack.svg')
},
{
 name: 'EmployeesCounter',
 icon: require('./svgIcons/EmployeesCounter.svg')
},
{
 name: 'Entry',
 icon: require('./svgIcons/Entry.svg')
},
{
 name: 'Event',
 icon: require('./svgIcons/Event.svg')
},
{
 name: 'Exit',
 icon: require('./svgIcons/Exit.svg')
},
{
 name: 'Face',
 icon: require('./svgIcons/Face.svg')
},
{
 name: 'Favorite',
 icon: require('./svgIcons/Favorite.svg')
},
{
 name: 'FavoriteBorder',
 icon: require('./svgIcons/FavoriteBorder.svg')
},
{
 name: 'FileCopy',
 icon: require('./svgIcons/FileCopy.svg')
},
{
 name: 'FolderOpen',
 icon: require('./svgIcons/FolderOpen.svg')
},
{
 name: 'FormatListBulleted',
 icon: require('./svgIcons/FormatListBulleted.svg')
},
{
 name: 'FormatListNumbered',
 icon: require('./svgIcons/FormatListNumbered.svg')
},
{
 name: 'HighlightOff',
 icon: require('./svgIcons/HighlightOff.svg')
},
{
 name: 'History',
 icon: require('./svgIcons/History.svg')
},
{
 name: 'Home',
 icon: require('./svgIcons/Home.svg')
},
{
 name: 'HomeWorkBlack',
 icon: require('./svgIcons/HomeWorkBlack.svg')
},
{
 name: 'Https',
 icon: require('./svgIcons/Https.svg')
},
{
 name: 'IncomingOrders',
 icon: require('./svgIcons/IncomingOrders.svg')
},
{
 name: 'Info',
 icon: require('./svgIcons/Info.svg')
},
{
 name: 'InhouseMaintainance',
 icon: require('./svgIcons/InhouseMaintainance.svg')
},
{
 name: 'InsertChart',
 icon: require('./svgIcons/InsertChart.svg')
},
{
 name: 'InsertDriveFile',
 icon: require('./svgIcons/InsertDriveFile.svg')
},
{
 name: 'Inventory',
 icon: require('./svgIcons/Inventory.svg')
},
{
 name: 'InventoryBlack',
 icon: require('./svgIcons/InventoryBlack.svg')
},
{
 name: 'InvoiceProforma',
 icon: require('./svgIcons/InvoiceProforma.svg')
},
{
    name: 'ItemReceive',
    icon: require('./svgIcons/ItemReceive.svg')
},
{
 name: 'Label',
 icon: require('./svgIcons/Label.svg')
},
{
 name: 'LibraryBooks',
 icon: require('./svgIcons/LibraryBooks.svg')
},
{
 name: 'LocalMall',
 icon: require('./svgIcons/LocalMall.svg')
},
{
 name: 'LocalOffer',
 icon: require('./svgIcons/LocalOffer.svg')
},
{
 name: 'LocalShipping',
 icon: require('./svgIcons/LocalShipping.svg')
},
{
 name: 'LocationMap',
 icon: require('./svgIcons/LocationMap.svg')
},
{
 name: 'LocationMove',
 icon: require('./svgIcons/LocationMove.svg')
},
{
 name: 'LocationsLabs',
 icon: require('./svgIcons/LocationsLabs.svg')
},
{
 name: 'Logbook',
 icon: require('./svgIcons/Logbook.svg')
},
{
 name: 'Luggage',
 icon: require('./svgIcons/Luggage.svg')
},
{
 name: 'Measurements',
 icon: require('./svgIcons/Measurements.svg')
},
{
 name: 'MiscellaneousServices',
 icon: require('./svgIcons/MiscellaneousServices.svg')
},
{
 name: 'MonetizationOn',
 icon: require('./svgIcons/MonetizationOn.svg')
},
{
 name: 'More',
 icon: require('./svgIcons/More.svg')
},
{
 name: 'MoreVert',
 icon: require('./svgIcons/MoreVert.svg')
},
{
 name: 'NeedsConfirmation',
 icon: require('./svgIcons/NeedsConfirmation.svg')
},
{
 name: 'NotificationImportant',
 icon: require('./svgIcons/NotificationImportant.svg')
},
{
 name: 'Notifications',
 icon: require('./svgIcons/Notifications.svg')
},
{
 name: 'OpenFull',
 icon: require('./svgIcons/OpenFull.svg')
},
{
 name: 'OrderList',
 icon: require('./svgIcons/OrderList.svg')
},
{
 name: 'Pause',
 icon: require('./svgIcons/Pause.svg')
},
{
 name: 'Payment',
 icon: require('./svgIcons/Payment.svg')
},
{
 name: 'PaymentList',
 icon: require('./svgIcons/PaymentList.svg')
},
{
 name: 'Pending',
 icon: require('./svgIcons/Pending.svg')
},
{
 name: 'PendingOrders',
 icon: require('./svgIcons/PendingOrders.svg')
},
{
 name: 'PermContactCalendar',
 icon: require('./svgIcons/PermContactCalendar.svg')
},
{
 name: 'PieChart',
 icon: require('./svgIcons/PieChart.svg')
},
{
 name: 'Place',
 icon: require('./svgIcons/Place.svg')
},
{
 name: 'PlayCircleOutline',
 icon: require('./svgIcons/PlayCircleOutline.svg')
},
{
 name: 'Preview',
 icon: require('./svgIcons/Preview.svg')
},
{
 name: 'Print',
 icon: require('./svgIcons/Print.svg')
},
{
 name: 'PriorityHigh',
 icon: require('./svgIcons/PriorityHigh.svg')
},
{
 name: 'RadioButtonChecked',
 icon: require('./svgIcons/RadioButtonChecked.svg')
},
{
 name: 'RadioButtonUnchecked',
 icon: require('./svgIcons/RadioButtonUnchecked.svg')
},
{
 name: 'Received',
 icon: require('./svgIcons/Received.svg')
},
{
 name: 'RemoveRedEye',
 icon: require('./svgIcons/RemoveRedEye.svg')
},
{
 name: 'RemoveShoppingCart',
 icon: require('./svgIcons/RemoveShoppingCart.svg')
},
{
 name: 'ReportProblem',
 icon: require('./svgIcons/ReportProblem.svg')
},
{
 name: 'Research',
 icon: require('./svgIcons/Research.svg')
},
{
 name: 'RevertStatus',
 icon: require('./svgIcons/RevertStatus.svg')
},
{
 name: 'RoomCounter',
 icon: require('./svgIcons/RoomCounter.svg')
},
{
 name: 'Schedule',
 icon: require('./svgIcons/Schedule.svg')
},
{
 name: 'School',
 icon: require('./svgIcons/School.svg')
},
{
    name: 'Search',
    icon: require('./svgIcons/Search.svg')
   },
{
 name: 'Security',
 icon: require('./svgIcons/Security.svg')
},
{
 name: 'Settings',
 icon: require('./svgIcons/Settings.svg')
},
{
 name: 'SettingsNew',
 icon: require('./svgIcons/SettingsNew.svg')
},
{
 name: 'Share',
 icon: require('./svgIcons/Share.svg')
},
{
 name: 'ShelfEmpty',
 icon: require('./svgIcons/ShelfEmpty.svg')
},
{
 name: 'ShoppingCart',
 icon: require('./svgIcons/ShoppingCart.svg')
},
{
 name: 'Sick',
 icon: require('./svgIcons/Sick.svg')
},
{
 name: 'SpecifyPayment',
 icon: require('./svgIcons/SpecifyPayment.svg')
},
{
 name: 'StorageOccupancy',
 icon: require('./svgIcons/StorageOccupancy.svg')
},
{
 name: 'StorageOptions',
 icon: require('./svgIcons/StorageOptions.svg')
},
{
 name: 'StorageSelected',
 icon: require('./svgIcons/StorageSelected.svg')
},
{
 name: 'StorageTemperature',
 icon: require('./svgIcons/StorageTemperature.svg')
},
{
 name: 'StorageType',
 icon: require('./svgIcons/StorageType.svg')
},
{
 name: 'StorageUnitCounter',
 icon: require('./svgIcons/StorageUnitCounter.svg')
},
{
 name: 'Storefront',
 icon: require('./svgIcons/Storefront.svg')
},
{
 name: 'Sync',
 icon: require('./svgIcons/Sync.svg')
},
{
 name: 'TableChart',
 icon: require('./svgIcons/TableChart.svg')
},
{
 name: 'Timer',
 icon: require('./svgIcons/Timer.svg')
},
{
 name: 'ToggleOff',
 icon: require('./svgIcons/ToggleOff.svg')
},
{
 name: 'ToggleOn',
 icon: require('./svgIcons/ToggleOn.svg')
},
{
 name: 'Update',
 icon: require('./svgIcons/Update.svg')
},
{
 name: 'UploadFile',
 icon: require('./svgIcons/UploadFile.svg')
},
{
 name: 'UploadQuote',
 icon: require('./svgIcons/UploadQuote.svg')
},
{
 name: 'WidgetsBlack',
 icon: require('./svgIcons/WidgetsBlack.svg')
},
{
 name: 'ZoomIn',
 icon: require('./svgIcons/ZoomIn.svg')
},
{
    name: 'EditLocationDetails',
    icon: require('./svgIcons/EditLocationDetails.svg')
   },
   {
    name: 'MoreHorizontal',
    icon: require('./svgIcons/MoreHorizontal.svg')
   },
] as ElixirSvg[]
