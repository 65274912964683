import { ButtonProps, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { BoxProps } from '@mui/system';
import { PopupState } from 'material-ui-popup-state/core';
import { PropsWithChildren, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SvgNames } from '../../Constants/app-enums';
import { setSelectedFilters } from '../../ReduxRelatedUtils/index-table-reducer';
import { ApplicationState } from '../../ReduxRelatedUtils/redux-types';
import { StyledFormInput } from '../../Utility/custom-input-components';
import { FilterState, RequestIndexViewModel } from '../../Utility/request-types';
import { BoldTypography, SectionOutlinedTableButton, SpacedRowStack, StyledButton, StyledFilledButton, StyledNoOutlineButton, SvgIcon } from '../../Utility/shared-components';

export default function IndexFilterResults(props: {popupState: PopupState}) {
    const {inventoryFilterViewModel} = useSelector<ApplicationState>(state => state.viewModel) as RequestIndexViewModel
    const [state, setState] = useState<FilterState>(initialState())

    function initialState() {
        return {
            numFilters: inventoryFilterViewModel?.numFilters,
            searchByName: inventoryFilterViewModel?.searchName,
            catalogNumber: inventoryFilterViewModel?.catalogNumber,
          //  isArchived: inventoryFilterViewModel?.archive,
            vendors: { filterText: "", notSelectedShown: inventoryFilterViewModel?.vendors, selected: inventoryFilterViewModel?.selectedVendors || [], notSelectedHidden: [] },
            owners: { filterText: "", notSelectedShown: inventoryFilterViewModel?.owners, selected: inventoryFilterViewModel?.selectedOwners || [], notSelectedHidden: [] },
            locations: { filterText: "", notSelectedShown: inventoryFilterViewModel?.locations, selected: inventoryFilterViewModel?.selectedLocations || [], notSelectedHidden: [] },
            categories: { filterText: "", notSelectedShown: inventoryFilterViewModel?.categories, selected: inventoryFilterViewModel?.selectedCategories || [], notSelectedHidden: [] },
            subcategories: { filterText: "", notSelectedShown: inventoryFilterViewModel?.subcategories, selected: inventoryFilterViewModel?.selectedSubcategories || [], notSelectedHidden: [] },

        } as FilterState;
    }

    const dispatch = useDispatch();
    const {tabValue} = useSelector<ApplicationState>(state => state.viewModel) as RequestIndexViewModel
    // const markAsArchived = (e: any) => {
    //     console.log("is archived " + e.target.checked)
    //     setState({ ...state, isArchived: e.target.checked });
    // }
    const updateSearchName = (e: any) => {
        setState({ ...state, searchByName: e.target.value });
    }
    const updateCatalogNumber = (e: any) => {
        setState({ ...state, catalogNumber: e.target.value });
    }
    const moveVendorToSelected = (e: any) => {

        var newNotSelectedList = state.vendors.notSelectedShown.filter(v => v.vendorID != e.currentTarget.value);
        var vendorToMoveToSelected = state.vendors.notSelectedShown.filter(v => v.vendorID == e.currentTarget.value)[0];
        setState({ ...state, vendors: { ...state.vendors, filterText: '', selected: [...state.vendors.selected, vendorToMoveToSelected], notSelectedShown: [...newNotSelectedList || [], ...state.vendors.notSelectedHidden || []], notSelectedHidden: [] }, numFilters: state.numFilters + 1 });
    };
    const moveVendorToNotSelected = (e: any) => {
        var newSelectedList = state.vendors.selected.filter(v => v.vendorID != e.currentTarget.value);
        var vendorToMoveToNotSelected = state.vendors.selected.filter(v => v.vendorID == e.currentTarget.value)[0];
        setState({ ...state, vendors: { ...state.vendors, filterText: '', selected: newSelectedList, notSelectedShown: [...[...state.vendors.notSelectedShown || [], ...state.vendors.notSelectedHidden || []], vendorToMoveToNotSelected], notSelectedHidden: [] }, numFilters: state.numFilters - 1 });
    };
    const moveOwnersToSelected = (e: any) => {
        var newNotSelectedList = state.owners.notSelectedShown.filter(v => v.id != e.currentTarget.value);
        var ownerToMoveToSelected = state.owners.notSelectedShown.filter(v => v.id == e.currentTarget.value)[0];
        setState({ ...state, owners: { ...state.owners, filterText: '', selected: [...state.owners.selected, ownerToMoveToSelected], notSelectedShown: newNotSelectedList }, numFilters: state.numFilters + 1 });
    };
    const moveOwnersToNotSelected = (e: any) => {
        var newSelectedList = state.owners.selected.filter(v => v.id != e.currentTarget.value);
        var ownerToMoveToNotSelected = state.owners.selected.filter(v => v.id == e.currentTarget.value)[0];
        setState({ ...state, owners: { ...state.owners, filterText: '', selected: newSelectedList, notSelectedShown: [...state.owners.notSelectedShown, ownerToMoveToNotSelected] }, numFilters: state.numFilters - 1 });
    };

    const moveLocationsToSelected = (e: any) => {
        var newNotSelectedList = state.locations.notSelectedShown.filter(v => v.locationTypeID != e.currentTarget.value);
        var locationToMoveToSelected = state.locations.notSelectedShown.filter(v => v.locationTypeID == e.currentTarget.value)[0];
        setState({ ...state, locations: { ...state.locations, filterText: '', selected: [...state.locations.selected, locationToMoveToSelected], notSelectedShown: newNotSelectedList || [] }, numFilters: state.numFilters + 1 });
    };
    const moveLocationsToNotSelected = (e: any) => {
        var newSelectedList = state.locations.selected.filter(v => v.locationTypeID != e.currentTarget.value);
        var locationToMoveToNotSelected = state.locations.selected.filter(v => v.locationTypeID == e.currentTarget.value)[0];
        setState({ ...state, locations: { ...state.locations, filterText: '', selected: newSelectedList, notSelectedShown: [...state.locations.notSelectedShown, locationToMoveToNotSelected] }, numFilters: state.numFilters - 1 });
    };
    const moveCategoriesToSelected = (e: any) => {
        var newNotSelectedList = state.categories.notSelectedShown.filter(v => v.id != e.currentTarget.value);
        var categoryToMoveToSelected = state.categories.notSelectedShown.filter(v => v.id == e.currentTarget.value)[0];
        setState({ ...state, categories: { ...state.categories, filterText: '', selected: [...state.categories.selected, categoryToMoveToSelected], notSelectedShown: newNotSelectedList }, numFilters: state.numFilters + 1 });
    };
    const moveCategoriesToNotSelected = (e: any) => {
        var newSelectedList = state.categories.selected.filter(v => v.id != e.currentTarget.value);
        var categoryToMoveToNotSelected = state.categories.selected.filter(v => v.id == e.currentTarget.value)[0];
        setState({ ...state, categories: { ...state.categories, filterText: '', selected: newSelectedList, notSelectedShown: [...state.categories.notSelectedShown, categoryToMoveToNotSelected] }, numFilters: state.numFilters - 1 });
    };

    const moveSubCategoriesToSelected = (e: any) => {
        var newNotSelectedList = state.subcategories.notSelectedShown.filter(v => v.id != e.currentTarget.value);
        var subCategoryToMoveToSelected = state.subcategories.notSelectedShown.filter(v => v.id == e.currentTarget.value)[0];
        setState({ ...state, subcategories: { ...state.subcategories, filterText: '', selected: [...state.subcategories.selected, subCategoryToMoveToSelected], notSelectedShown: newNotSelectedList }, numFilters: state.numFilters + 1 });
    };
    const moveSubCategoriesToNotSelected = (e: any) => {
        var newSelectedList = state.subcategories.selected.filter(v => v.id != e.currentTarget.value);
        var subCategoryToMoveToNotSelected = state.subcategories.selected.filter(v => v.id == e.currentTarget.value)[0];
        setState({ ...state, subcategories: { ...state.subcategories, filterText: '', selected: newSelectedList, notSelectedShown: [...state.subcategories.notSelectedShown, subCategoryToMoveToNotSelected] }, numFilters: state.numFilters - 1 });
    };
    const filterVendors = (e: any) => {
        var value = e.currentTarget.value.toLowerCase();
        setState({
            ...state, vendors: {
                ...state.vendors,
                filterText: value,
                notSelectedShown: [...state.vendors.notSelectedShown?.filter(v => v.vendorEnName.toLowerCase().indexOf(value) >= 0) || [], ...state.vendors.notSelectedHidden?.filter(v => v.vendorEnName.toLowerCase().indexOf(value) >= 0) || []],
                notSelectedHidden: [...state.vendors.notSelectedShown?.filter(v => v.vendorEnName.toLowerCase().indexOf(value) < 0) || [], ...state.vendors.notSelectedHidden?.filter(v => v.vendorEnName.toLowerCase().indexOf(value) < 0)],
            }
        });
    };
    const filterOwners = (e: any) => {
        console.log(e)
        var value = e.currentTarget.value.toLowerCase();
        setState({
            ...state, owners: {
                ...state.owners,
                filterText: value,
                notSelectedShown: [...state.owners.notSelectedShown?.filter(v => (v.firstName.toLowerCase() + " " + v.lastName.toLowerCase()).indexOf(value) >= 0) || [], ...state.owners.notSelectedHidden?.filter(v => (v.firstName.toLowerCase() + " " + v.lastName.toLowerCase()).indexOf(value) >= 0) || []],
                notSelectedHidden: [...state.owners.notSelectedShown?.filter(v => (v.firstName.toLowerCase() + " " + v.lastName.toLowerCase()).indexOf(value) < 0) || [], ...state.owners.notSelectedHidden?.filter(v => (v.firstName.toLowerCase() + " " + v.lastName.toLowerCase()).indexOf(value) < 0)],
            }
        });
    };

    const filterLocations = (e: any) => {
        var value = e.currentTarget.value.toLowerCase();
        setState({
            ...state, locations: {
                ...state.locations,
                filterText: value,
                notSelectedShown: [...state.locations.notSelectedShown?.filter(v => v.locationTypeName.toLowerCase().indexOf(value) >= 0) || [], ...state.locations.notSelectedHidden?.filter(v => v.locationTypeName.toLowerCase().indexOf(value) >= 0) || []],
                notSelectedHidden: [...state.locations.notSelectedShown?.filter(v => v.locationTypeName.toLowerCase().indexOf(value) < 0) || [], ...state.locations.notSelectedHidden?.filter(v => v.locationTypeName.toLowerCase().indexOf(value) < 0)],
            }
        });
    };

    const filterCategories = (e: any) => {
        var value = e.currentTarget.value.toLowerCase();
        setState({
            ...state, categories: {
                ...state.categories,
                filterText: value,
                notSelectedShown: [...state.categories.notSelectedShown?.filter(v => v.description.toLowerCase().indexOf(value) >= 0) || [], ...state.categories.notSelectedHidden?.filter(v => v.description.toLowerCase().indexOf(value) >= 0) || []],
                notSelectedHidden: [...state.categories.notSelectedShown?.filter(v => v.description.toLowerCase().indexOf(value) < 0) || [], ...state.categories.notSelectedHidden?.filter(v => v.description.toLowerCase().indexOf(value) < 0)],
            }
        });
    };

    const filterSubcategories = (e: any) => {
        var value = e.currentTarget.value.toLowerCase();
        setState({
            ...state, subcategories: {
                ...state.subcategories,
                filterText: value,
                notSelectedShown: [...state.subcategories.notSelectedShown?.filter(v => v.description.toLowerCase().indexOf(value) >= 0) || [], ...state.subcategories.notSelectedHidden?.filter(v => v.description.toLowerCase().indexOf(value) >= 0) || []],
                notSelectedHidden: [...state.subcategories.notSelectedShown?.filter(v => v.description.toLowerCase().indexOf(value) < 0) || [], ...state.subcategories.notSelectedHidden?.filter(v => v.description.toLowerCase().indexOf(value) < 0)],
            }
        });
    };



    const clearFilter = () => {
        dispatch(setSelectedFilters({
            selectedVendorsIDs: [],
            selectedOwnersIDs: [],
            selectedLocationsIDs: [],
            selectedCategoriesIDs: [],
            selectedSubcategoriesIDs: [],
            numFilters: 0,
           // archived: state.isArchived,
            catalogNumber: "",
            searchText: ""
        }));
    }
    const applyFilter = () => {
        dispatch(setSelectedFilters({
            selectedVendorsIDs: state.vendors.selected.map(v => v.vendorID),
            selectedOwnersIDs: state.owners.selected.map(v => v.id),
            selectedLocationsIDs: state.locations.selected.map(v => v.locationTypeID),
            selectedCategoriesIDs: state.categories.selected.map(v => v.id),
            selectedSubcategoriesIDs: state.subcategories.selected.map(v => v.id),
            numFilters: state.numFilters,
           // archived: state.isArchived,
            catalogNumber: state.catalogNumber,
            searchText: state.searchByName
        }));
    }
    return (<Box padding="2rem" >
        <Stack spacing="1rem">
            <Box sx={{cursor:"pointer"}} onClick={() => {props.popupState.setOpen(false)}}><SvgIcon name={SvgNames.Clear} className="icon-gray" height={1} /></Box>
            <SpacedRowStack justifyContent={"space-between"}>
                <SpacedRowStack alignItems={"center"}>
                    <BoldTypography width={"50%"} marginTop="1.3rem">{"Total Filters:" + state.numFilters}</BoldTypography>
                    <StyledFormInput onChange={updateSearchName} placeholder="Search By Name" value={state.searchByName ?? ""} />
                    {!inventoryFilterViewModel.isProprietary && <StyledFormInput onChange={updateCatalogNumber} placeholder="By Catalog Number" value={state.catalogNumber ?? ""} />}
                </SpacedRowStack>
                <Stack direction="row" spacing="1rem" alignItems={"center"} paddingTop="1.3rem">
                    {/* {(props.viewModel.isProprietary || tabValue == "Received") &&
                        <FormGroup>
                            <FormControlLabel control={<SectionCheckbox checked={state.isArchived} onChange={markAsArchived} />} label="Archived" />
                        </FormGroup>
                    } */}
                    <StyledNoOutlineButton onClick={clearFilter}>Clear All</StyledNoOutlineButton>
                    <SectionOutlinedTableButton onClick={applyFilter}>Apply</SectionOutlinedTableButton>
                </Stack>
            </SpacedRowStack>
            <Stack spacing="0.8rem" direction="row">
                {!inventoryFilterViewModel.isProprietary &&
                    <FilterColumn showBorder>
                        <StyledFormInput placeholder="Vendor" onChange={filterVendors} value={state.vendors.filterText} />
                        <FilterScrollBox>
                            {state.vendors?.notSelectedShown?.map(v =>
                                <FilterButton key={v.vendorID} onClick={moveVendorToSelected} value={v.vendorID}>{v.vendorEnName}</FilterButton>)}
                        </FilterScrollBox>
                        <FilterScrollBox>
                            {state.vendors?.selected?.map(v =>
                                <StyledFilledButton sx={{fontSize:"1rem"}} key={v.vendorID} onClick={moveVendorToNotSelected} value={v.vendorID}>{v.vendorEnName}</StyledFilledButton>)}
                        </FilterScrollBox>
                    </FilterColumn>}
                <FilterColumn showBorder>
                    <StyledFormInput placeholder="Owner" onChange={filterOwners} value={state.owners.filterText} />
                    <FilterScrollBox>
                        {state.owners?.notSelectedShown?.map(o =>
                            <FilterButton key={o.id} onClick={moveOwnersToSelected} value={o.id}>{o.firstName + " " + o.lastName}</FilterButton>)}
                    </FilterScrollBox>
                    <FilterScrollBox>
                        {state.owners?.selected?.map(o =>
                            <StyledFilledButton sx={{fontSize:"1rem"}} key={o.id} onClick={moveOwnersToNotSelected} value={o.id}>{o.firstName + " " + o.lastName}</StyledFilledButton>)}
                    </FilterScrollBox>
                </FilterColumn>
                {(inventoryFilterViewModel.isProprietary || tabValue == "Received") &&
                    <FilterColumn showBorder>
                        <StyledFormInput placeholder="Location" onChange={filterLocations} value={state.locations.filterText} />
                        <FilterScrollBox>{state.locations?.notSelectedShown?.map(l =>
                            <FilterButton key={l.locationTypeID} onClick={moveLocationsToSelected} value={l.locationTypeID}>{l.locationTypeName}</FilterButton>)}
                        </FilterScrollBox>
                        <FilterScrollBox>
                            {state.locations?.selected?.map(l =>
                                <StyledFilledButton sx={{fontSize:"1rem"}} key={l.locationTypeID} onClick={moveLocationsToNotSelected} value={l.locationTypeID}>{l.locationTypeName}</StyledFilledButton>)}
                        </FilterScrollBox>
                    </FilterColumn>
                }
                <FilterColumn showBorder>
                    <StyledFormInput placeholder="Category" onChange={filterCategories} value={state.categories.filterText} />
                    <FilterScrollBox>
                        {state.categories?.notSelectedShown?.map(c =>
                            <FilterButton key={c.id} onClick={moveCategoriesToSelected} value={c.id}>{c.description}</FilterButton>)}
                    </FilterScrollBox>
                    <FilterScrollBox>
                        {state.categories?.selected?.map(c =>
                            <StyledFilledButton sx={{fontSize:"1rem"}} key={c.id} onClick={moveCategoriesToNotSelected} value={c.id}>{c.description}</StyledFilledButton>)}
                    </FilterScrollBox>
                </FilterColumn>
                <FilterColumn >
                    <StyledFormInput placeholder="Subcategory" onChange={filterSubcategories} value={state.subcategories.filterText} />
                    <FilterScrollBox>
                        {state.subcategories?.notSelectedShown?.map(s =>
                            <FilterButton key={s.id} onClick={moveSubCategoriesToSelected} value={s.id}>{s.description}</FilterButton>)}
                    </FilterScrollBox>
                    <FilterScrollBox>
                        {state.subcategories?.selected?.map(s =>
                            <StyledFilledButton sx={{fontSize:"1rem"}} key={s.id} onClick={moveSubCategoriesToNotSelected} value={s.id}>{s.description}</StyledFilledButton>)}
                    </FilterScrollBox>
                </FilterColumn>
            </Stack>
        </Stack>
    </Box>
    )


}

function FilterColumn(props: PropsWithChildren<{ showBorder?: boolean }>) {
    return (
        <Stack spacing="1rem" width="15rem" paddingRight={props.showBorder ? "0.8rem" : "0"} borderRight={props.showBorder ? theme => "1px solid " + theme.palette.grey[300] : "none"} >
            {props.children}
        </Stack>
    )
}

function FilterButton(props: PropsWithChildren<ButtonProps>) {
    return (
        <StyledButton {...props} sx={{
            fontSize: "1rem !important",
            backgroundColor: (theme) => theme.palette.grey[200], ":hover": {
                backgroundColor: (theme) => theme.palette.grey[200]
            }
        }}>
            <Typography textOverflow={"ellipsis"} overflow="hidden" whiteSpace={"nowrap"}>{props.children}</Typography>
        </StyledButton>
    )
}

function FilterScrollBox(props: PropsWithChildren<BoxProps>) {
    return (
        <Box height="10rem" overflow="auto" paddingRight="0.5rem" marginBottom="1rem">
            <Stack spacing={"0.6rem"}>
                {props.children}
            </Stack>
        </Box>
    )
}