import { push } from "connected-react-router";
import moment, {Moment} from "moment";
import { FieldError } from "react-hook-form";
import { batch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { CheckboxCondition, FolderNamesEnum, ItemCardFields, ModalFlow, ModalName, MenuItemEnum, OrderMethod, PageTypeEnum, ParentCategoryEnum, PaymentsPopoverEnum, ParentFolderName, SidebarEnum, SvgNames, RoleDefinition, IndexTabs } from "../Constants/app-enums";
import { documentsIcons } from "../Constants/client-side-constants";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex, setTabValue } from "../ReduxRelatedUtils/index-table-reducer";
import { setLoading, setShowConfirmExit } from "../ReduxRelatedUtils/utilsReducer";
import { removeModal, setModal, setModalLoading, setModalStep } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, CheckboxInfo, Modal, NavigationInfo, RoleGroup, TempData } from "../ReduxRelatedUtils/redux-types";
import { setTempData } from "../ReduxRelatedUtils/temp-data-reducer";
import { AddItemFormData } from "../Shared/AddCardComponents/add-types";
import { ActionBarLink, BasicModalInfo, CustomError } from "./general-types";
import { Request } from "./request-types";
import queryString from 'query-string';
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import NumberFormat from "react-number-format";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { isNullOrUndefined } from "util";
import JSZip from "jszip";

export var combineTwoFormDatas = function (formdata1: FormData, formdata2: any) {
    if (formdata1 == undefined) {
        var formdata1 = new FormData();
    }
    if (formdata2 != undefined) {
        for (var pair of formdata2.entries()) {
            formdata1.append(pair[0], pair[1]);
        }
    }
    return formdata1;
}


export function objectToFormData(inJSON: any, inTestJSON?: any, inFormData?: any, parentKey?: any) {
    var form_data = inFormData || new FormData();
    var testJSON = inTestJSON || {};
    for (var key in inJSON) {
        // 1. If it is a recursion, then key has to be constructed like "parent.child" where parent JSON contains a child JSON
        // 2. Perform append data only if the value for key is not a JSON, recurse otherwise!
        var constructedKey = key;
        if (parentKey) {
            constructedKey = parentKey + "." + key;
        }

        var value = inJSON[key];
        if (value && value.constructor === {}.constructor) {
            // This is a JSON, we now need to recurse!
            objectToFormData(value, testJSON, form_data, constructedKey);
        }
        else if (Array.isArray(value)) {

            if (value.length == 0) {
                form_data.append(constructedKey, "")
            }
            for (var i = 0; i < value.length; i++) {
                if (value[i] && value[i].constructor === {}.constructor) {
                    // This is a JSON, we now need to recurse!
                    objectToFormData(value[i], testJSON, form_data, constructedKey + "[" + i + "]");
                }
                else {
                    if (value[i] == null) {
                        form_data.append(constructedKey + "[" + i + "]", "");
                    }
                    else {
                        form_data.append(constructedKey + "[" + i + "]", value[i]);
                    }
                }
            }
        }
        else {
            if (inJSON[key] == null) {
                form_data.append(constructedKey, "");
                testJSON[constructedKey] = "";
            }
            else {
                form_data.append(constructedKey, inJSON[key]);
                testJSON[constructedKey] = inJSON[key];
            }
        }
    }
    return form_data;
}

export function getDisplayNameOfEnumValue(enumValue: any) {
    if(enumValue != null) {
        let enumDisplayName = enumValue.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1')
        return enumDisplayName;
    }
    else return "";
}

export function getAddItemCardFieldTypesByCategory(categoryTypeEnum?: ParentCategoryEnum, productSubcategoryName?: string, isProduct?:boolean) {
    if(isProduct==true){
        return [ItemCardFields.Vendor, ItemCardFields.Unit, ItemCardFields.Url]
    }
    switch (categoryTypeEnum) {
        case ParentCategoryEnum.Consumables:
            return [ItemCardFields.Vendor, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.ReagentsAndChemicals:
            return [ItemCardFields.Vendor, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.Biological:
            return [ItemCardFields.Vendor, ItemCardFields.BatchLot, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.Reusable:
            return [ItemCardFields.Vendor, ItemCardFields.Warranty, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.Clinical:
            return [ItemCardFields.Vendor, ItemCardFields.BatchLot, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.Safety:
            return [ItemCardFields.Vendor, ItemCardFields.Warranty, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.General:
            return [ItemCardFields.Vendor, ItemCardFields.Warranty, ItemCardFields.SupplyDays, ItemCardFields.Url]
        case ParentCategoryEnum.Samples:
            switch (productSubcategoryName) {
                case "Cells":
                    return [ItemCardFields.Passage, ItemCardFields.CreationDate]
                default:
                    return [ItemCardFields.CreationDate]
            }
    }

}
export type DocumentTypeWithID = {
    folderName: FolderNamesEnum,
    id: string,
}

export function getDocumentTypesByCategory(guid: string, id: number, categoryTypeEnum?: ParentCategoryEnum, productSubcategoryName?: string) {
 //   console.log(categoryTypeEnum + "  ," + productSubcategoryName)
    let finalID = (id != null && id != 0) ? id + "" : guid;
    switch (categoryTypeEnum) {
        case ParentCategoryEnum.Consumables:
        case ParentCategoryEnum.ReagentsAndChemicals:
        case ParentCategoryEnum.Biological:
        case ParentCategoryEnum.Reusable:
        case ParentCategoryEnum.Clinical:
        case ParentCategoryEnum.Safety:
        case ParentCategoryEnum.General:
            return [{ folderName: FolderNamesEnum.Shipments, id: finalID }, { folderName: FolderNamesEnum.Pictures, id: finalID }, { folderName: FolderNamesEnum.Info, id: finalID }] as DocumentTypeWithID[]
        case ParentCategoryEnum.Samples:
            switch (productSubcategoryName?.toLowerCase()) {
                case "blood":
                case "serum":
                    return [{ folderName: FolderNamesEnum.S, id: finalID }, { folderName: FolderNamesEnum.Info, id: finalID }] as DocumentTypeWithID[]
                case "virus":
                case "plasmid":
                case "bacteria with plasmids":
                    return [{ folderName: FolderNamesEnum.Map, id: finalID }, { folderName: FolderNamesEnum.Info, id: finalID }]
                default:
                    return [{ folderName: FolderNamesEnum.Info, id: finalID }]
            }
    }

}


export function getDocumentCardsDetailsByFolderEnum(folderName: FolderNamesEnum) {
    return documentsIcons.get(folderName);
}

export function basicModalPost(url: string, setError: Function, dispatch: Dispatch<any>, formData?: any) {
    dispatch(setLoading(true))
    console.log("in basic modal post")
    fetch(url, {
        method: "POST",
        body: formData
    })
        .then(result => {
            if (!result.ok) throw result.text()
            console.log("basic modal result")
            batch(() => {
                dispatch(setLoading(false))
                dispatch(removeModal())
                dispatch(setReloadIndex(true))
                dispatch(setGlobalSnack({ open: true, message: "Success" }))
            })

        })
        .catch(err => {
            Promise.resolve(err).then(text => {
                setError({ message: text, showBody: true })
                dispatch(setLoading(false))
            })
        })
}

export function getFloatingActionBarLink(dispatch: Dispatch<any>, pageType: PageTypeEnum, sidebarType: SidebarEnum, checkboxes: CheckboxInfo[], userInfo: RoleGroup[], tab?:IndexTabs) {
    var actionBarLinks = [] as ActionBarLink[]
    function modalOpenClick(modalFlow: ModalFlow, modalStep: ModalName) {
        batch(() => {
            dispatch(setModal({ modalFlow: modalFlow, modalStep: modalStep, modalInfo: { ids: checkboxes?.filter(c => c.isChecked).map(c => c.checkboxID) } } as Modal<BasicModalInfo>))
            dispatch(setModalLoading(true))
        })
    }

    function payLaterClick(){
            var url = "/Requests/ChangePaymentStatus/?newStatus=" + PaymentsPopoverEnum.PayLater;
            fetch(url,
                {
                    method: "POST",
                    body: objectToFormData({requestIDs:checkboxes?.filter(c => c.isChecked).map(c => c.checkboxID)})
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    else { throw response.text() }
                })
                .then((result) => {
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Moved To Pay Later" }))

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        console.log(text)
                        dispatch(setError({ message: text, showBody: false } as CustomError))
                    })
                })
    }
    function requestItemClick(){
        var url = "/Requests/RequestItem";
        fetch(url,
            {
                method: "POST",
                body: objectToFormData({requestIDs:checkboxes?.filter(c => c.isChecked).map(c => c.checkboxID)})
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.text()
                }
                else { throw response.text() }
            })
            .then((result) => {
                dispatch(setReloadIndex(true))
                dispatch(setGlobalSnack({ open: true, message: "Item(s) Requested"}))

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    dispatch(setError({ message: text, showBody: false } as CustomError))
                })
            })
}
    const payLink ={icon: SvgNames.MonetizationOn, title:"Pay", onClick:()=>modalOpenClick(ModalFlow.Pay, ModalName.Pay) } as ActionBarLink
    const payLaterLink ={icon: SvgNames.Timer, title:"Pay Later", onClick:()=>payLaterClick() } as ActionBarLink;
    const deleteLink = { icon: SvgNames.Delete, title: "Delete", onClick: () => modalOpenClick(ModalFlow.DeleteItem, ModalName.DeleteItem) } as ActionBarLink
    const orderLink = ({ icon: SvgNames.Approve, title: "Order", onClick: () => modalOpenClick(ModalFlow.AddItem, ModalName.Terms) } as ActionBarLink);
   const addInvoiceLink = {icon: SvgNames.CancelPresentation, title:"Add Invoice", onClick:()=>modalOpenClick(ModalFlow.AddInvoice,ModalName.AddInvoice) } as ActionBarLink
    const uploadQuoteLink = { icon: SvgNames.MonetizationOn, title: "Upload Quote", onClick: () => modalOpenClick(ModalFlow.EditQuoteDetails, ModalName.EditQuoteDetails) } as ActionBarLink
    const askForQuoteLink = { icon: SvgNames.MonetizationOn, title: "Ask for Quote", onClick: () => modalOpenClick(ModalFlow.AskForQuote, ModalName.AskForQuote) } as ActionBarLink
    const requestItemLink = { icon: SvgNames.MonetizationOn, title: "Request Item", onClick: () => requestItemClick() } as ActionBarLink

   // const reviewQuoteLink = { icon: SvgNames.NeedsConfirmation, title: "Review Quote", onClick: () => modalOpenClick(ModalFlow.ReviewQuote, ModalName.ReviewQuote) } as ActionBarLink
   
    const conditions = checkboxes?.filter(c => c.conditions?.length > 0 && c.isChecked)
        ?.map(c => c.conditions.map(c => { return c }))?.flat()//?.filter((v, i, a) => a.indexOf(v) === i);
    switch (pageType) {
        case PageTypeEnum.LabManagementQuotes:
            switch (sidebarType) {
                case SidebarEnum.Quotes:
                    switch(tab){
                        case IndexTabs.NeedsQuote:
                        if (!conditions?.includes(CheckboxCondition.Resend) || (conditions.filter(c => c == CheckboxCondition.Resend).length == checkboxes.filter(c => c.isChecked).length)) actionBarLinks.push(askForQuoteLink)
                        actionBarLinks.push(uploadQuoteLink)
                        break;
                        case IndexTabs.NeedsConfirmation:
                       //     actionBarLinks.push(reviewQuoteLink)
                            break;
                
                    }
                    actionBarLinks.push(deleteLink);
                        
                    break;
                case SidebarEnum.Orders:
                    actionBarLinks.push(orderLink)
                    actionBarLinks.push(deleteLink);
                    break;
            }
            break;
        case PageTypeEnum.AccountingNotifications:
            switch (sidebarType) {
                case SidebarEnum.NoInvoice:
                     actionBarLinks.push(addInvoiceLink);
                    break;
            }
            break;
        case PageTypeEnum.AccountingPayments:
            switch (sidebarType) {
                case SidebarEnum.Installments:
                case SidebarEnum.PayLater:
                       actionBarLinks.push(payLink)
                    break;
                default:
                actionBarLinks.push(payLink)
                if(!conditions?.includes(CheckboxCondition.Installment)) actionBarLinks.push(payLaterLink)       
            }
            break;
        case PageTypeEnum.RequestCart:
            userInfo.find(ur => ur.mainRole==MenuItemEnum.Requests)?.subRoles.includes(RoleDefinition.RequestsOrderItem) && actionBarLinks.push(orderLink)
            actionBarLinks.push(requestItemLink)
            actionBarLinks.push(deleteLink);
            break;

    }
    return actionBarLinks;
}

export function formatNumber(value: number) {
    return parseFloat(value.toFixed(2))
}

export function itemPost(orderMethod: OrderMethod, data: TempData<AddItemFormData>, dispatch: any, navigationInfo?: NavigationInfo) {
    let nextModalType: ModalName;

    switch (orderMethod) {
        case OrderMethod.OrderNow:
            data.request.orderMethod = { descriptionEnum: OrderMethod.OrderNow };
            nextModalType = ModalName.UploadQuote;
            moveToNextStep();
            break;
        case OrderMethod.AlreadyPurchased:
            data.request.orderMethod = { descriptionEnum: OrderMethod.AlreadyPurchased };
            nextModalType = ModalName.UploadOrder;
            moveToNextStep();

            break;
        case OrderMethod.AddToCart:
            data.request.orderMethod = { descriptionEnum: OrderMethod.AddToCart };
            nextModalType = ModalName.UploadQuote;
            moveToNextStep();
            break;
        case OrderMethod.RequestItem:
            data.request.orderMethod = { descriptionEnum: OrderMethod.RequestItem };
            nextModalType = ModalName.UploadQuote;
            moveToNextStep();
            // dispatch(setLoading(true))
            // fetch("/Requests/RequestPriceQuote", {
            //     method: "POST",
            //     body: JSON.stringify(data),
            //     headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            // }).then(response => {
            //     if (response.ok) { return response.json() }
            //     else { throw response.text() }
            // }).then((result) => {
            //     batch(() => {
            //         dispatch(setLoading(false))
            //         dispatch(removeModal())
            //         dispatch(setTempData({}))
            //         dispatch(setGlobalSnack({ open: true, message: "Request for a price quote succeeded!" }))
            //     })

            // })
            //     .catch(err => {
            //         Promise.resolve(err).then(text => {
            //             batch(() => {
            //                 dispatch(setLoading(false))
            //                 dispatch(removeModal())
            //                 dispatch(setTempData({}))
            //                 dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
            //             })
            //         })
            //     })
            break;
        case OrderMethod.Save:
            dispatch(setLoading(true))
            fetch("/Requests/AddSample", {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            }).then(response => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            }).then((result) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(setShowConfirmExit(false))
                    dispatch(setTempData({}))
                    dispatch(push("/RequestsInventoryList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)));
                    dispatch(setTabValue("Samples"))
                    dispatch(setGlobalSnack({ open: true, message: "Add sample succeeded!" }))
                    
                })
            })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        batch(() => {
                            dispatch(setLoading(false))
                            dispatch(setTempData({}))
                            dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                        })
                    })
                })
            break;
    }

    function moveToNextStep() {
        console.log("move to next step")
        batch(() => {
            dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalInfo: { ids: [data.request.requestID?.toString() ?? "0"] }, modalStep: nextModalType } as Modal<BasicModalInfo>));
            dispatch(setModalLoading(true));
            dispatch(setTempData(JSON.parse(JSON.stringify(data))));
        });
    }
}

export function handleCalendarUnitCalcChange(e: any, daysName: string, dateName: string, setValue: any, calendarUnit: "days" | "months") {
    setValue(daysName, e.target.value);
    setValue(dateName, moment().add(e.target.value, calendarUnit).format("YYYY-MM-DD"),)
}
export function handleDateCalcChange(e: any, daysName: string, dateName: string, setValue: any, calendarUnit: "days" | "months") {
    setValue(dateName, moment(e).format("YYYY-MM-DD"));
    setValue(daysName, moment(e).diff(moment().startOf('day'), calendarUnit))
}

export const handleFetchErrors = (response: any) => {
    //console.log(window.location.pathname)
    if (response.status == 401 && window.location.pathname != '/Login' && window.location.pathname != '/Login2FA') {
        console.log("in handle fetch errors")
        window.location.href = "/Logout";
    }
    return response;
}

export function convertStyleSheetsToCss() {
    // Get the list of stylesheets
    var styleSheets = document.styleSheets;

    // Initialize a string to hold the CSS content
    var css = "";

    // Loop through the list of stylesheets
    for (var i = 0; i < styleSheets.length; i++) {
        // Get the current stylesheet
        var styleSheet = styleSheets[i];

        // Loop through the list of rules in the stylesheet
        for (var j = 0; j < styleSheet.cssRules.length; j++) {
            // Get the current rule
            var rule = styleSheet.cssRules[j];

            // Extract the text content of the rule
            css += rule.cssText;
        }
    }

    // Return the CSS content
    return css;
}
export function convertScriptsToScriptTag() {
    // Get the list of scripts
    var scripts = document.scripts;

    // Initialize a string to hold the script tag
    var scriptTag = "";

    // Loop through the list of scripts
    for (var i = 0; i < scripts.length; i++) {
        // Get the current script
        var script = scripts[i];

        // Check if the script has an src attribute
        if (script.hasAttribute("src")) {
            // If the script has an src attribute, it is linked from an external source
            // Extract the src attribute and build the script tag using it
            var src = script.getAttribute("src");
            scriptTag += "<script src='" + src + "'></script>";
        } else {
            // If the script does not have an src attribute, it is defined inline
            // Extract the content of the script
            var scriptContent = script.innerHTML || script.innerText;

            // Build the script tag using the content of the script
            scriptTag += "<script>" + scriptContent + "</script>";
        }
    }

    // Return the script tag
    return scriptTag;
}

export const extractErrorValues = (obj: any): string[] => {
    const values = [];

    for (const [key, value] of Object.entries(obj)) {
        console.log(value)
        if (typeof value === 'object') {
            if ("message" in (value ?? {})) {
                values.push((value as FieldError).message)
            }
            else {
                values.push(...extractErrorValues(value));
            }
        }
    }

    return values as string[];
}

export function ChangeLocationNumberToLetterForm(number: number, width: number) {
    // Determine the letter of the label
    let letter = String.fromCharCode('A'.charCodeAt(0) + ((number - 1) / width));

    // Determine the number of the label
    let num = (number - 1) % width + 1;

    // Combine the letter and number and return
    return letter + num.toString();

}

export function getTimeDuration(time1:Moment, time2:Moment) {
    const diffInSeconds = time1.diff(time2, 'seconds');

    // Convert the difference to hours, minutes, and seconds
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    // Format the result as a time in hours, minutes, and seconds
    const result = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    return result
}

export function getParentFolderName(folderName: FolderNamesEnum, sectionType: MenuItemEnum) {
    switch (sectionType) {
        case MenuItemEnum.Requests:
        case MenuItemEnum.Operations:
        case MenuItemEnum.Accounting:
        case MenuItemEnum.LabManagement:
            switch (folderName) {
                case FolderNamesEnum.Quotes:
                    return ParentFolderName.ParentQuote;
                case FolderNamesEnum.Orders:
                    return ParentFolderName.ParentRequest;
                case FolderNamesEnum.Users:
                    return ParentFolderName.UserImages;
                case FolderNamesEnum.Invoices:
                    return ParentFolderName.Invoice;
                default:
                    return ParentFolderName.Requests;
            }
        case MenuItemEnum.Protocols:
            return ParentFolderName.Reports;
        case MenuItemEnum.Biomarkers:
            return ParentFolderName.ExperimentEntries;
        case MenuItemEnum.TimeKeeper:
        case MenuItemEnum.Users:
        default:
            return ParentFolderName.None;
    }
}

export function downloadFile(filePath:string) {
    fetch(filePath)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filePath.split('\\').pop()??"");
        document.body.appendChild(link);
        link.click();
      });
  }

  export function downloadFilesAsZip(filePaths: string[]) {
    const zip = new JSZip();
  
    const fetchPromises = filePaths.map((filePath) => {
      return fetch(filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const fileName = filePath.split('/').pop();
          return zip.file(fileName??"", blob);
        });
    });
  
    Promise.all(fetchPromises)
      .then(() => {
        return zip.generateAsync({ type: 'blob' });
      })
      .then((zipBlob) => {
        const url = window.URL.createObjectURL(zipBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'files.zip');
        document.body.appendChild(link);
        link.click();
      });
  }

  export function getElixirDateFormat(date?: Date | string) {
    let dateFormatString = "DD MMM yyyy";
    if(date) {
        return moment(date).format(dateFormatString)
    }
    else {
        return moment().format(dateFormatString)
    }
  }