import { Tabs, Box } from "@mui/material"
import { useDispatch } from "react-redux"
import { setTabValue } from "../../ReduxRelatedUtils/index-table-reducer"
import { IndexTab, RequestIndexViewModel } from "../../Utility/request-types"
import { getDisplayNameOfEnumValue } from "../../Utility/root-function"
import { StyledTab, SvgIcon } from "../../Utility/shared-components"

type IndexTableTabProps = {
    viewModel: RequestIndexViewModel
}

export default function IndexTableTabs({viewModel}: IndexTableTabProps){
    const dispatch = useDispatch();
    return (
        <Box>
            <Tabs value={viewModel?.tabValue} onChange={(e, newValue) => dispatch(setTabValue(newValue))}>
                {viewModel?.tabs?.map((t: IndexTab, i: number) => {
                    var selected  = t.tabValue == viewModel.tabValue
                    return (
                        <StyledTab className={selected ? "section-color" : ""} 
                        value={t.tabValue} 
                        icon={<SvgIcon  name={t.icon} className={selected ?"section-fill":""} height={2} />} 
                        iconPosition="start" 
                        key={t.tabValue} 
                        label={getDisplayNameOfEnumValue(t.tabName)} />
                    )
                }
                )}
            </Tabs>
        </Box>
    )
}

