import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InfoPopupName, ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, InfoPopup, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, BasicModalInfo, CustomError } from '../Utility/general-types';
import { basicModalPost, ChangeLocationNumberToLetterForm, objectToFormData } from '../Utility/root-function';
import { GlobalForm, SectionBoldTypography, SmallTypography, SpacedColumnStack, StyledLocationBox } from '../Utility/shared-components';
import { LocationInstance } from './locations-types';
import queryString from 'query-string'
import { push } from 'connected-react-router'
import EditItem, { EditItemInnerForm } from '../Requests/ItemCard/edit-item';
import { yupResolver } from "@hookform/resolvers/yup";
import { requestDefaultValues } from '../Shared/AddCardComponents/default-values';
import { requestValidationSchema } from '../Shared/AddCardComponents/validation-schemas';
import EditCardPopover from '../Shared/edit-card-popover';
import { EditCardType } from '../Shared/edit-card';
import { r } from 'msw/lib/glossary-dc3fd077';
import { setInfoPopup } from '../ReduxRelatedUtils/infoPopupReducer';


type ZoomedLocation = {
    location: LocationInstance,
    requestID: number,
    productName: string
}
type LocationZoomModalViewModal = {
    parentLocation: LocationInstance,
    locationName: string,
    locations: ZoomedLocation[],
    locationParents: string[]
}
export default function LocationZoomModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<LocationModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState<LocationZoomModalViewModal>()
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const [itemModal, setItemModal] = useState<{open:boolean, id:string}>({open:false, id:""})

    useEffect(() => {
        var url = "/Locations/LocationZoomModal?parentLocationID=" + modalInfo.parentLocationID + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: LocationZoomModalViewModal) => {
                setViewModel(result);
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.parentLocationID]);


    return (
        <>
        <GlobalModal key={ModalName.LocationZoom} size={ModalSizes.xl} modalKey={ModalName.LocationZoom}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={(viewModel?.parentLocation.locationType?.locationTypeName??"")+" "+(viewModel?.locationName??"")}  subHeaderText={viewModel?.locationParents.join(" > ")} errorMessage={error.message} />
                    {error.showBody ? <>
                        <StyledDialogContent>

                            <Box border={(theme) => "solid 1px " + theme.palette.grey[400]}>
                                <Grid container height={"100%"} borderLeft={(theme) => "solid 1px " + theme.palette.grey[400]} borderTop={(theme) => "solid 1px " + theme.palette.grey[400]} columns={viewModel?.parentLocation.width}>
                                    {viewModel?.locations?.map((l, i) =>
                                        <StyledLocationBox sx={{ aspectRatio: "1/1", backgroundColor: (l.requestID && l.requestID != 0) ? "#eff4f8" : "white", padding: "0.5rem" }} item xs={1} key={l.location.locationInstanceID}>
                                            <Stack direction={"column-reverse"} height="100%" justifyContent={"space-between"} >
                                            <Typography color={(theme) => l.requestID ? theme.palette.primary.main : theme.palette.grey[400]}>{ChangeLocationNumberToLetterForm(l.location.locationNumber, viewModel.parentLocation.width)}</Typography>

                                                {(l.requestID != 0) && <SmallTypography onClick={() => dispatch(setInfoPopup({infoPopupName: InfoPopupName.EditItem, id: l.requestID.toString()} as InfoPopup))} sx={{ textDecoration: "underline", cursor:"pointer" }}>{l.productName}</SmallTypography>}
                                            </Stack>
                                        </StyledLocationBox>
                                    )}

                                </Grid>
                            </Box>
                        </StyledDialogContent>
                    </>
                        : null
                    }
                </>
            }
        </GlobalModal>
        
</>
    );
}

