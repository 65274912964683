import Drawer from "@mui/material/Drawer"
import { push } from "connected-react-router"
import { Location } from "history"
import queryString from 'query-string'
import { Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { EditDrawerEnum } from "../../Constants/app-enums"
import { selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore"
import { setError } from "../../ReduxRelatedUtils/errorReducer"
import { ApplicationState, NavigationInfo } from "../../ReduxRelatedUtils/redux-types"
import { clearEditDrawerViewModel, removeEditDrawerViewModel } from "../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer"
import { ConfirmExitModal } from "../../Requests/Modals/confirm-exit-modal"
import { CloseButtonLeftAlign } from "../../Utility/shared-components"
import { EditCardContext, EditCardType } from "../edit-card"

type GlobalDrawerProps={
   drawerEnum: EditCardType
}

export default function GlobalDrawer(props:PropsWithChildren<GlobalDrawerProps>){
// console.log("drawer" + props.drawerEnum)
 const {pathname} = useSelector<ApplicationState>(state => state.router.location) as Location;
 const dispatch= useDispatch()
 const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo;
 //console.log(navigationInfo)
 const editCardContext = useContext(EditCardContext);

 return(
    <Drawer sx={{zIndex: (theme) => theme.zIndex.drawer + 2}} PaperProps={{sx:{overflow:"hidden"}}} open={true}   anchor="right" container={() => document.getElementById('section-wrapper')}>
       
         {props.children}

         
    </Drawer>
 )
}
