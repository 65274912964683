import { Grid } from "@mui/material";
import { AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { StyledCurrencyFormInput } from "../Utility/custom-input-components";
import { BoldTypography, SpacedColumnStack, TitleTypography } from "../Utility/shared-components";

export default function BudgetTab() {

    return (
        <SpacedColumnStack paddingBottom={"2rem"}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BoldTypography fontSize={"1.25rem"}>Lab</BoldTypography>
                </Grid>
                <Grid item xs={4}>
                    <StyledCurrencyFormInput currency="₪" name="employee.labMonthlyLimit" label="Monthly Limit" />
                </Grid>
                <Grid item xs={4}>
                    <StyledCurrencyFormInput currency="₪" name="employee.labUnitLimit" label="Unit Limit" />
                </Grid>
                <Grid item xs={4}>
                    <StyledCurrencyFormInput currency="₪" name="employee.labOrderLimit" label="Order Limit" />
                </Grid>
                <Grid item xs={12}>
                    <BoldTypography fontSize={"1.25rem"}>Operations</BoldTypography>
                </Grid>
                <Grid item xs={4}>
                    <StyledCurrencyFormInput currency="₪" name="employee.operationsMonthlyLimit" label="Monthly Limit" />
                </Grid>
                <Grid item xs={4}>
                    <StyledCurrencyFormInput currency="₪" name="employee.operationsUnitLimit" label="Unit Limit" />
                </Grid>
                <Grid item xs={4}>
                    <StyledCurrencyFormInput currency="₪" name="employee.operationsOrderLimit" label="Order Limit" />
                </Grid>
            </Grid>
        </SpacedColumnStack>
    )
}