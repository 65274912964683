import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { AnySchema, boolean, date, number, object, string } from "yup";
import { CurrencyEnum, FolderNamesEnum, ModalName } from "../../Constants/app-enums";
import { ModalSizes } from "../../Constants/client-side-enums";
import { selectModalLoading, selectModalPresent } from "../../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../../ReduxRelatedUtils/index-table-reducer";
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { removeModal, setModalLoading } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../../ReduxRelatedUtils/redux-types";
import { setViewModel } from "../../ReduxRelatedUtils/viewmodel-reducer";
import DocumentsCard from "../../Shared/documents-card";
import { ControlledCheckbox, StyledControlledFormDateInput, StyledControlledFormInput, StyledCurrencyFormInput } from "../../Utility/custom-input-components";
import { BasicModalInfo, CustomError } from "../../Utility/general-types";
import { CompanyAccount, CreditCard, Invoice, Payment, PaymentType } from "../../Utility/request-types";
import { basicModalPost } from "../../Utility/root-function";
import { AddInvoiceBlock, GlobalForm, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack } from "../../Utility/shared-components";
import GlobalModal from "./global-modal";
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from "./modal-components";
import ModalSkeleton from "./modal-skeleton";

export type RevertStatusFormData={
    requestID:number
}


export function RevertStatusModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const methods = useForm<RevertStatusFormData>({
   
      defaultValues:{
        requestID: Number(modalInfo.ids[0])
      }
    });


    useEffect(()=>{
        dispatch(setModalLoading(false))
    },[])


    function onSubmit(data: RevertStatusFormData) {       
        var url = '/Requests/RevertStatus'
        dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then(( result:string ) => {
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(removeModal())
                        dispatch(setGlobalSnack({ open: true, message: result, severity: "success" }))
                        dispatch(setReloadIndex(true))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
    }

    return (
        <GlobalModal size={ModalSizes.xs} modalKey={ModalName.RevertStatus}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Revert Status" errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.RevertStatus} onSubmit={(data: any) => onSubmit(data)} methods={methods}>

                              <Typography>Are you sure you want to revert the item's status?                              
                              </Typography>
                              <Typography>  This will remove all location details and move the item back to the 'Ordered' tab.</Typography>
                            </GlobalForm>


                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.RevertStatus} submitButtonText="Revert" />
                    </>}
                </>
            }
        </GlobalModal>

    );
}

