import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { ModalName } from "../Constants/app-enums";
import { ModalSizes } from "../Constants/client-side-enums";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setInfoPopup } from "../ReduxRelatedUtils/infoPopupReducer";
import { ApplicationState, InfoPopup, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { removeEditDrawerViewModel } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { EditItemInnerForm, onSubmitItemEdit } from "../Requests/ItemCard/edit-item";
import { ConfirmExitModal } from "../Requests/Modals/confirm-exit-modal";
import GlobalModal from "../Requests/Modals/global-modal";
import { EditSupplierInnerForm, onSubmitSupplierEdit } from "../Suppliers/edit-supplier";
import { EditUserInnerForm } from "../Users/edit-user";
import { GlobalForm, IsEdittableContext } from "../Utility/shared-components";
import { AddItemFormData, AddSupplierFormData } from "./AddCardComponents/add-types";
import { requestDefaultValues, supplierDefaultValues } from "./AddCardComponents/default-values";
import { requestValidationSchema, supplierValidationSchema } from "./AddCardComponents/validation-schemas";
import { EditCardContext, EditCardType } from "./edit-card";


export default function EditCardPopover(props: { editCardType: EditCardType }) {
    const [submitSucceeded, setSubmitSucceeded] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const resolver = yupResolver(props.editCardType == EditCardType.EditItem ? requestValidationSchema : supplierValidationSchema)
    const defaultValues = props.editCardType == EditCardType.EditItem ?  requestDefaultValues() : supplierDefaultValues()
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const { id } = useSelector<ApplicationState>(state => state.infoPopup) as InfoPopup
    const dispatch = useDispatch()

    const methods = useForm({
        resolver: async (data, context, options) => {
            // you can debug your validation schema here
            console.log('formData', data)
            console.log('validation result', await resolver(data, context, options))
            return resolver(data, context, options)
        }, defaultValues: defaultValues
    });

    
    function getOnSubmit(data:any, e:any) {
        
        switch (props.editCardType) {
            case EditCardType.EditItem:
                return onSubmitItemEdit(data as AddItemFormData, dispatch,  setSubmitSucceeded, e, location, true )
            case EditCardType.EditSupplier:
                return onSubmitSupplierEdit(data as AddSupplierFormData, dispatch,  setSubmitSucceeded, e, location, true)
            default: return (<></>)
        }
    }

    return (
        <EditCardContext.Provider value={{ submitSucceeded: submitSucceeded, toggleSubmitSucceeded: setSubmitSucceeded, toggleConfirmModalOpen:setConfirmModalOpen, confirmModalOpen:confirmModalOpen }}>
          
                  <GlobalForm methods={methods} onSubmit={(data:any, e:any) =>getOnSubmit(data, e)} formID={props.editCardType+id} isEdittable={false}>
                
                <InnerFormWithConfirmExit editType={props.editCardType}/>
                
          
                </GlobalForm>
        

        </EditCardContext.Provider>
    )
}


function InnerFormWithConfirmExit(props:{editType:EditCardType}){
    const editCardContext = useContext(EditCardContext);
    const isEdittableContext = useContext(IsEdittableContext)
    const dispatch= useDispatch()
    const { id } = useSelector<ApplicationState>(state => state.infoPopup) as InfoPopup
    const {formState} = useFormContext();
    function discardAndExit(closeModal:boolean){
        if(closeModal){
            batch(()=>{
                dispatch(setInfoPopup({} as InfoPopup))
                dispatch(removeEditDrawerViewModel({id:id}))
            })
        }
        else{
            editCardContext.toggleConfirmModalOpen(true)
        }
    
    
    }
    return(<>
      <GlobalModal hideBackdrop modalKey={ModalName.ItemCard} size={ModalSizes.md} height="85vh" closeClick={()=>discardAndExit((!isEdittableContext.isEdittable|| !formState.isDirty))} >

            <InnerForm editCardType={props.editType}/>
    {editCardContext.confirmModalOpen &&
             <ConfirmExitModal setConfirmModalOpen={editCardContext.toggleConfirmModalOpen} discardAndExit={()=>discardAndExit(true)} formID={id} editType={props.editType} />}

        </GlobalModal></>)
}
function InnerForm(props:{editCardType:EditCardType}) {
    const { id } = useSelector<ApplicationState>(state => state.infoPopup) as InfoPopup
    const editCardContext = useContext(EditCardContext);
    const isEdittableContext = useContext(IsEdittableContext)
    useEffect(()=>{
        console.log(editCardContext.submitSucceeded)
        if(editCardContext.submitSucceeded){
            isEdittableContext.toggleIsEdittable(false);
            editCardContext.toggleSubmitSucceeded(false);
        }
    }, [editCardContext.submitSucceeded])
        switch (props.editCardType) {
            case EditCardType.EditItem:
                return (<EditItemInnerForm  id={id} />)
            case EditCardType.EditSupplier:
                return (<EditSupplierInnerForm id={id} />)
            case EditCardType.EditUser:
                return (<EditUserInnerForm id={id}/>)
            default: return (<></>)
        }
    }