import { createAction, createSlice } from '@reduxjs/toolkit';
import { StatusFilterEnum } from '../Constants/app-enums';
import { RequestsSearchFormData } from '../Shared/item-search-page';
import { AccountingSelectsViewModel, ApplicationState, CheckboxInfo, IndexTableCheckboxes, MonthYearFilters, SelectedFilters } from './redux-types';

export const clearIndexTableObject = createAction('clearIndexTableObject');

const accountingSelectsViewModelSlice = createSlice({
    name: 'accountingSelectsViewModel',
    initialState: {
        orderStatuses: [StatusFilterEnum.Ordered, StatusFilterEnum.Received],
        paymentStatuses: [StatusFilterEnum.Paid, StatusFilterEnum.PendingPayment, StatusFilterEnum.PartialPayment],
        invoiceStatuses: [StatusFilterEnum.Invoiced, StatusFilterEnum.NoInvoice, StatusFilterEnum.PartialInvoice],
        selectAllStatus: [StatusFilterEnum.SelectAll]
    } as AccountingSelectsViewModel,
    reducers: {
        setSelectedVendor(state, action) {
            state.selectedVendor = action.payload
        },
        setGroupBy(state, action) {
            state.groupBy = action.payload
        },
        setSortBy(state, action) {
            state.sortBy = action.payload
        },
        orderStatusFilterToggled(state, action) {
            if (state.orderStatuses?.includes(action.payload)) {
                state.orderStatuses = state.orderStatuses.filter(o => o != action.payload)
                state.selectAllStatus = []
            }
            else {
                state.orderStatuses = [...state.orderStatuses ?? [], action.payload]
            }
        },
        paymentStatusFilterToggled(state, action) {
            if (state.paymentStatuses?.includes(action.payload)) {
                state.paymentStatuses = state.paymentStatuses.filter(o => o != action.payload)
                state.selectAllStatus = []
            }
            else {
                state.paymentStatuses = [...state.paymentStatuses ?? [], action.payload]
            }
        },
        invoiceStatusFilterToggled(state, action) {
            if (state.invoiceStatuses?.includes(action.payload)) {
                state.invoiceStatuses = state.invoiceStatuses.filter(o => o != action.payload)
                state.selectAllStatus = []
            }
            else {
                state.invoiceStatuses = [...state.invoiceStatuses ?? [], action.payload]
            }
        },
        allFiltersToggled(state) {
            state.orderStatuses = [StatusFilterEnum.Ordered, StatusFilterEnum.Received]
            state.paymentStatuses = [StatusFilterEnum.Paid, StatusFilterEnum.PendingPayment, StatusFilterEnum.PartialPayment]
            state.invoiceStatuses = [StatusFilterEnum.Invoiced, StatusFilterEnum.NoInvoice, StatusFilterEnum.PartialInvoice]
            state.selectAllStatus = [StatusFilterEnum.SelectAll]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return {} as AccountingSelectsViewModel;
        })
    }
})


export const { setSelectedVendor, setGroupBy, setSortBy, orderStatusFilterToggled, paymentStatusFilterToggled, invoiceStatusFilterToggled, allFiltersToggled } = accountingSelectsViewModelSlice.actions

export const accountingSelectsViewModelReducer = accountingSelectsViewModelSlice.reducer

export function selectAccountingSelectsViewModel(state: ApplicationState) {
    return state.indexTableObject.accountingSelectsViewModel
}

const columnDisplayValueOptionsSlice = createSlice({
    name: 'columnDisplayValueOptions',
    initialState: [],
    reducers: {
        setDisplayValueOptions(state, action) {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return [];
        })
    }
})

export const { setDisplayValueOptions } = columnDisplayValueOptionsSlice.actions

export const columnDisplayValueOptionsReducer = columnDisplayValueOptionsSlice.reducer

const columnDisplayFormatOptionsSlice = createSlice({
    name: 'columnDisplayFormatOptions',
    initialState: [],
    reducers: {
        setDisplayFormatOptions(state, action) {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return [];
        })
    }
})

export const { setDisplayFormatOptions } = columnDisplayFormatOptionsSlice.actions

export const columnDisplayFormatOptionsReducer = columnDisplayFormatOptionsSlice.reducer


const tabValueSlice = createSlice({
    name: 'tabValue',
    initialState: "",
    reducers: {
        setTabValue(state, action) {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return '';
        })
    }
})

export const { setTabValue } = tabValueSlice.actions
export const tabValueReducer = tabValueSlice.reducer

const selectedFiltersSlice = createSlice({
    name: 'selectedFilters',
    initialState: {} as SelectedFilters,
    reducers: {
        setSelectedFilters(state, action) {
            return action.payload
        },
        setSearchText(state, action) {
            state.searchText = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return {} as SelectedFilters;
        })
    }
})

export const { setSelectedFilters, setSearchText } = selectedFiltersSlice.actions
export const selectedFiltersReducer = selectedFiltersSlice.reducer


const pageNumberSlice = createSlice({
    name: 'pageNumber',
    initialState: 1,
    reducers: {
        setPageNumber(state, action) {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return 1;
        })
    }
})

export const { setPageNumber } = pageNumberSlice.actions
export const pageNumberReducer = pageNumberSlice.reducer

const reloadIndexSlice = createSlice({
    name: 'reloadIndex',
    initialState: false,
    reducers: {
        setReloadIndex(state, action) {
            return action.payload
        }
    }
})

export const { setReloadIndex } = reloadIndexSlice.actions
export const reloadIndexReducer = reloadIndexSlice.reducer

const checkboxInfoSlice = createSlice({
    name: 'indexTableCheckboxes',
    initialState: {checkboxGroup:"", checkboxInfo:[] as CheckboxInfo[]} as IndexTableCheckboxes,
    reducers: {
        setCheckboxGroupID(state, action: { payload: string }) {
            state.checkboxGroup = action.payload
        },
        //addSelectedCheckbox(state, action:{payload:CheckboxInfo}) {
        //     state.selectedCheckboxes.push(action.payload)
        // },
        setCheckboxInfo(state, action:{payload:CheckboxInfo[]}){
            state.checkboxInfo = action.payload
        },
        handleCheckboxChange(state, action: { payload: { checkboxID: string, checked: boolean } }) {

            if (action.payload.checked)//is being checked
            {
                //filter by checked and get the first if groupid is different
                const currentCheckedBoxes = state.checkboxInfo.filter(c => c.isChecked)
                var updatedCheckboxes = [] as CheckboxInfo[];
                if (currentCheckedBoxes.length == 0) {
                   updatedCheckboxes = state.checkboxInfo.map(c => { 
                        if (c.checkboxID == action.payload.checkboxID){
                            return {...c, isChecked:true} 
                         } return c;
                        })
                }
                else {
                 updatedCheckboxes =   state.checkboxInfo.map(c => { if (c.checkboxID == action.payload.checkboxID && c.groupID == currentCheckedBoxes[0].groupID){
                            return {...c, isChecked:true} 
                         } return c;})
                }
            }
            else {
               updatedCheckboxes =  state.checkboxInfo.map(c => { if (c.checkboxID == action.payload.checkboxID) {
                    return {...c, isChecked:false} 
                 } return c; })
            }
            state.checkboxInfo = updatedCheckboxes;
        },
        // removeSelectedCheckbox(state, action:{payload:CheckboxInfo}) {
        //     state.selectedCheckboxes = state.selectedCheckboxes.filter(id => id != action.payload)
        // }, 
        clearCheckboxes(state){
               var updatedCheckboxes =  state.checkboxInfo.map(c => {
                     return {...c, isChecked:false} 
                  } )
             state.checkboxInfo = updatedCheckboxes;
        }   
    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return {checkboxGroup:"", checkboxInfo:[] as CheckboxInfo[]} as IndexTableCheckboxes;
        })
    }
})

export const { setCheckboxGroupID, handleCheckboxChange,clearCheckboxes, setCheckboxInfo,/*addSelectedCheckbox, setSelectedCheckbox, removeSelectedCheckbox,*/ } = checkboxInfoSlice.actions
export const checkboxInfoReducer = checkboxInfoSlice.reducer

const monthYearFiltersSlice = createSlice({
    name: 'monthYearFilter',
    initialState: {} as MonthYearFilters,
    reducers: {
        yearlyMonthlyEnumChanged(state, action) {
            state.yearlyMonthlyEnum = action.payload
        },
        yearChanged(state, action) {
            state.year = action.payload
        },
        monthChanged(state, action) {
            state.month = action.payload
        },

    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return {} as MonthYearFilters;
        })
    }
})

export const { yearlyMonthlyEnumChanged, yearChanged, monthChanged } = monthYearFiltersSlice.actions
export const monthYearFiltersReducer = monthYearFiltersSlice.reducer


const requestsSearchViewModelSlice = createSlice({
    name: 'requestsSearchViewModel',
    initialState: {} as RequestsSearchFormData,
    reducers: {
        setRequestsSearchViewModel(state, action) {
            return action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(clearIndexTableObject, (state, action) => {
            return {} as RequestsSearchFormData;
        })
    }
})

export const { setRequestsSearchViewModel } = requestsSearchViewModelSlice.actions
export const requestsSearchViewModelReducer = requestsSearchViewModelSlice.reducer