import moment from "moment";
import { includeAction } from "redux-undo";
import { AnySchema, array, date, mixed, number, object, string } from "yup";
import { CurrencyEnum } from "../../Constants/app-enums";
import { TabsName } from "../../Constants/client-side-enums";
import { Product, ProductSubcategory, Vendor, Request, ParentQuote, ParentRequest, Payment, Invoice } from "../../Utility/request-types";
import { VendorContact } from "../../Utility/supplier-types";
import { Employee, JobSubcategoryType, SalariedEmployee } from "../../Utility/user-types";
import { AddItemFormData, AddSupplierFormData, AddUserFormData, EditProductFormData } from './add-types'
import { is } from "date-fns/locale";

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const requestValidationSchema = object<Partial<Record<keyof AddItemFormData, AnySchema>>>({
    currentTab: string(),
    selectedLocations: array().when(["currentTab", "isTemporary", "isSubmitting"], {
        is: (currentTab: TabsName, isTemporary: boolean, isSubmitting: boolean) => {console.log(isSubmitting); console.log(isTemporary); console.log(currentTab); return (currentTab == TabsName.Locations || isSubmitting) && !isTemporary;},
        then: array().test("selected location min", "Selected Location Required", function (this, options) {
            
            
            if (this.parent.request.requestStatusID == 3 || this.parent.isSample) {
                console.log("testing selected locations")
                return this.parent.selectedLocations.length > 0
            }
            else {
                return true;
            }
        }),
        otherwise: array().nullable()
    }),
    selectedFloor: number().when("isTemporary", {
        is: true,
        then: number().min(1, "Floor field is required").required("Floor field is required"),
        otherwise: number().nullable()
    }),
    selectedTemperature: number().when(["currentTab", "isTemporary", "isSubmitting", "isSample"], {
        is: (currentTab: TabsName, isTemporary: boolean, isSubmitting: boolean) => ((currentTab == TabsName.Locations || isSubmitting)),
        then: number().test("selected location min", "Temperature field is required", function (this) {
            if ((this.parent.request.requestStatusID == 3 || this.parent.isSample) && !this.parent.isQuartzy) {
                console.log("checking temperature")
                return this.parent.selectedTemperature > 0
            }
            else {
                return true;
            }
        }),
        otherwise: number()
    }),
    request: object<Partial<Record<keyof Request, AnySchema>>>({
        product: object<Partial<Record<keyof Product, AnySchema>>>({
            productName: string().required("Product Name field is required."),
            productSubcategory: object<Partial<Record<keyof ProductSubcategory, AnySchema>>>({
                parentCategoryID: number().min(1, "Parent Category field is required").required("Parent Category field is required")
            }).label("Subcategory*"),
            productSubcategoryID: number().min(1, "Product Subcategory field is required.").required("Product Subcategory field is required."),

        }),
        parentQuote: object<Partial<Record<keyof ParentQuote, AnySchema>>>({}).when("parentQuoteID", {
            is: (id?: number) => id != null && id!=0,
            then: object<Partial<Record<keyof ParentQuote, AnySchema>>>({
                quoteNumber: string().required("Quote Number is required"),
                expirationDate: date().typeError("Expiration Date must be a vaild date").required("Expiration Date is required"),
            }),
            otherwise: object().nullable()
        }),
        parentRequest: object<Partial<Record<keyof ParentRequest, AnySchema>>>({}).when("parentRequestID", {
            is: (id?: number) => id != null,
            then: object<Partial<Record<keyof ParentRequest, AnySchema>>>({
                //supplierOrderNumber: string().required("Supplier Order Number is required"),
                orderDate: date().typeError("Order date must be a valid date").required("Order date is required"),
            }),
            otherwise: object().nullable()
        }),
        arrivalDate: date().when("requestStatusID", {
            is: 3,
            then: date().typeError("Arrival date must be a valid date").required("Arrival date is required"),
            otherwise: date().nullable()
        }),
        applicationUserReceiverID: string().when("requestStatusID", {
            is: 3,
            then: string().required("Receiver user is required"),
            otherwise: string().nullable()
        })

    })
        .when("isSample", {
            is: false,
            then: object<Partial<Record<keyof Request, AnySchema>>>({
                product: object<Partial<Record<keyof Product, AnySchema>>>({

                    vendorID: number().min(1, "Vendor field is required.").required("Vendor field is required."),
                    catalogNumber: string()
                        .when('uniqueVendorCatalogNumber', (uniqueVendorCatalogNumber, schema) => {
                            // console.log('uniqueVendorCatalogNumber '+ uniqueVendorCatalogNumber)
                            return (uniqueVendorCatalogNumber ?
                                string().required("Catalog number is a required field.")
                                : schema.test({
                                    test: () => uniqueVendorCatalogNumber,
                                    message: "Catalog Number already exists for this vendor"
                                })
                            )
                        }
                        ),

                }),
 
                expectedSupplyDays: number().when("requestStatusID", {
                    is: (statusID: number) => (statusID != 4 && statusID != 5),
                    then: number().typeError("Supply Days field must be at least 0").integer("Supply Days field must be at least 0").min(0, "Supply Days field must be more than 0").max(255, "Supply Days field must be less than 256").required("Supply Days field is required"),
                    otherwise: number().nullable()
                }),
            }),
            otherwise: object<Partial<Record<keyof Request, AnySchema>>>({
                editableCreationDate: date().typeError("Creation Date must be a valid date").required("Creation Date is required").max(moment().endOf("day"), "Creation date cannot be in the future")
            })
        })
        .when(['currentTab', 'isSample', "isSubmitting"], {
            is: (currentTab: TabsName, isSample: boolean, isSubmitting: boolean) => !isSample && (currentTab == TabsName.Price || currentTab == TabsName.Order || isSubmitting),
            then: object({
                cost: number().when("requestStatusID", {
                    is: (statusID: number) => (statusID != 4 && statusID != 5),
                    then: number().min(0, "Cost field must be at least 0").required("Cost field is required").test("full cost sum",
                        "Total plus Shipping must be more than already paid", function (this) {
                            let currentCost = (this.parent.cost + Number(this.parent.includeVAT ? this.parent.cost * .17 : 0) + 
                                (this.parent.shipping!= null ? (this.parent.includeVAT? this.parent.shipping*1.17 : this.parent.shipping ): 0))
                            let alreadyPaid = (this.parent.payments as Payment[])?.filter((p: Payment) => p.isPaid)?.map(p => p.sum)?.reduce((a, b) => a + b, 0) ?? 0
                            //console.log('currentCost:' + currentCost)
                            //console.log('alreadyPaid:' + alreadyPaid)
                            return currentCost >= (alreadyPaid - .005)
                        }),
                    otherwise: number().nullable()
                }),
                currency: mixed().oneOf(Object.values(CurrencyEnum).filter(c => c != CurrencyEnum.None)),
                exchangeRate: number().moreThan(0, "Exchange Rate field must be more than 0").required("Exchange Rate field is required"),
                pricePerUnitDollar: number(),
                pricePerUnitShekel: number(),
                unit: number().integer().min(1, "Unit field must be more than 0").required("Unit field is required"),
                product: object<Partial<Record<keyof Product, AnySchema>>>({
                    unitTypeID: number().min(1, "Unit field is required.").required("Unit field is required."),
                    subUnit: number().nullable().integer().min(1, "subUnit field must be more than 0"),
                    subUnitTypeID: number().nullable().when('subUnit', {
                        is: (val: number) => val > 0,
                        then: number().typeError("subUnitType field is required").min(1, "subUnitType field is required").required("subUnitType field is required"),
                        otherwise: number().nullable()
                    }),
                    subSubUnit: number().nullable().integer().min(1, "subSubUnit field must be more than 0"),
                    subSubUnitTypeID: number().nullable().when('subSubUnit', {
                        is: (val: number) => val > 0,
                        then: number().typeError("subSubUnitType field is required").min(1, "subSubUnitType field is required").required("subSubUnitType field is required"),
                        otherwise: number().nullable()
                    }),
                }),
                shipping: number().when("requestStatusID", {
                    is: (statusID: number) => (statusID == 2 || statusID == 3),
                    then: number().min(0, "Shipping must be at least 0"),
                    otherwise: number().nullable()
                }),
                payments: array<Partial<Record<keyof Payment, AnySchema>>>()
                    .of(
                        object<Partial<Record<keyof Payment, AnySchema>>>(
                            {
                                // paymentReferenceDate: date().when("isPaid", {
                                //     is: true,
                                //     then: date().typeError("Reference date must be a valid date").required("Reference date is required"),
                                //     otherwise: date().nullable()
                                // }),
                                // companyAccountID: number().when("isPaid", {
                                //     is: true,
                                //     then: number().min(1, "Bank field is required.").required("Bank field is required."),
                                //     otherwise: number().nullable()
                                // }),
                                // paymentTypeID: number().when("isPaid", {
                                //     is: true,
                                //     then: number().min(1, "Payment Type field is required.").required("Payment Type field is required."),
                                //     otherwise: number().nullable()
                                // }),
                                // creditCardID: number().when("paymentTypeID", {
                                //     is: 1,
                                //     then: number().min(1, "Credit Card field is required.").required("Credit Card field is required."),
                                //     otherwise: number().nullable()
                                // }),
                                // checkNumber: string().when("paymentTypeID", {
                                //     is: 2,
                                //     then: string().min(1, "Check Number field is required.").required("Check Number field is required."),
                                //     otherwise: string().nullable()
                                // }),
                                // reference: string().when("paymentTypeID", {
                                //     is: 3,
                                //     then: string().min(1, "Reference field is required.").required("Reference field is required."),
                                //     otherwise: string().nullable()
                                // }),
                                invoice: object().when("invoiceID", {
                                    is: (invoiceID?: number) => invoiceID != null,
                                    then: object<Partial<Record<keyof Invoice, AnySchema>>>({
                                        invoiceDate: date().when("invoiceID", {
                                            is: (invoiceID?: number) => invoiceID != null,
                                            then: date().typeError("Invoice date is required").required("Invoice date is required"),
                                            otherwise: date().nullable()
                                        }),
                                        invoiceNumber: string().when("invoiceID", {
                                            is: (invoiceID?: number) => invoiceID != null,
                                            then: string().typeError("Invoice number is required").required("Invoice number is required"),
                                            otherwise: string().nullable()
                                        })
                                    }),
                                    otherwise: object().nullable()
                                })
                            }
                        )
                    ),
            })
        })
});


export const productValidationSchema = object<Partial<Record<keyof EditProductFormData, AnySchema>>>({
    currentTab: string(),
    product: object<Partial<Record<keyof Product, AnySchema>>>({
        productName: string().required("Product Name field is required."),
        productSubcategory: object<Partial<Record<keyof ProductSubcategory, AnySchema>>>({
            parentCategoryID: number().min(1, "Parent Category field is required").required("Parent Category field is required")
        }),
        productSubcategoryID: number().min(1, "Product Subcategory field is required.").required("Product Subcategory field is required."),


        vendorID: number().min(1, "Vendor field is required.").required("Vendor field is required."),
        catalogNumber: string()
            .when('uniqueVendorCatalogNumber', (uniqueVendorCatalogNumber, schema) => {
                // console.log('uniqueVendorCatalogNumber '+ uniqueVendorCatalogNumber)
                return (uniqueVendorCatalogNumber ?
                    string().required("Catalog number is a required field.")
                    : schema.test({
                        test: () => uniqueVendorCatalogNumber,
                        message: "Catalog Number already exists for this vendor"
                    })
                )
            }),


    })
        .when(["isSubmitting"], {
            is: (isSubmitting: boolean) => (isSubmitting),
            then: object({

                unitTypeID: number().min(1, "Unit field is required.").required("Unit field is required."),
                subUnit: number().nullable().integer().min(1, "subUnit field must be more than 0"),
                subUnitTypeID: number().nullable().when('subUnit', {
                    is: (val: number) => val > 0,
                    then: number().typeError("subUnitType field is required").min(1, "subUnitType field is required").required("subUnitType field is required"),
                    otherwise: number().nullable()
                }),
                subSubUnit: number().nullable().integer().min(1, "subSubUnit field must be more than 0"),
                subSubUnitTypeID: number().nullable().when('subSubUnit', {
                    is: (val: number) => val > 0,
                    then: number().typeError("subSubUnitType field is required").min(1, "subSubUnitType field is required").required("subSubUnitType field is required"),
                    otherwise: number().nullable()
                }),
            })
        })

});

export const supplierValidationSchema = object<Partial<Record<keyof AddSupplierFormData, AnySchema>>>({
    vendor: object<Partial<Record<keyof Vendor, AnySchema>>>({
        vendorEnName: string().required("Supplier English Name field is required."),
        vendorHeName: string().required("Supplier Hebrew Name field is required."),
        vendorBuisnessID: string()
            .when('uniqueCountryCompanyID', (uniqueCountryCompanyID, schema) => {
                return (uniqueCountryCompanyID ?
                    string().required("Company ID field is required.")
                    : schema.test({
                        test: () => uniqueCountryCompanyID,
                        message: "Company ID already exists for this country"
                    })
                )
            }
            ),
        vendorCity: string().required("City field is required."),
        countryID: number().moreThan(0, "Country field is required").required("Country field is required."),
        vendorTelephone: string().min(9, "Telephone must be at least 9 characters").required("Telephone field is required."),
        ordersEmail: string().email("Orders Email must be a valid email").required("Orders email field is required."),
        quotesEmail: string().email("Quotes Email must be a valid email").required("Quotes email field is required."),
        vendorCellPhone: string().nullable().min(9, "Cell must be at least 9 characters").transform((value) => !!value ? value : null),
        vendorAccountNum: string().nullable().matches(/^\d+$/, "Account number may only contain digits"),
        vendorRoutingNum: string().nullable().matches(/^\d+$/, "Routing number may only contain digits")

    }),
    vendorCategoryTypes: array().min(1, "Category Type field is required").required("Category Type field is required"),
    vendorContacts: array<Partial<Record<keyof VendorContact, AnySchema>>>().when(['currentTab', "isSubmitting"], {
        is: (value: number, submitting: boolean) => { return value == 3 || submitting },
        then: array().min(1).of(
            object<Partial<Record<keyof VendorContact, AnySchema>>>(
                {
                    vendorContactEmail: string().min(1, "Contact Email field is required").required("Contact Email field is required"),
                    vendorContactName: string().min(1, "Contact Name field is required").required("Contact Name field is required"),
                    vendorContactPhone: string().min(9, "Contact Phone must be at least 9 characters").required("Contact Phone field is required"),
                }
            )
        ),
    })

})

export const userValidationSchema = object<Partial<Record<keyof AddUserFormData, AnySchema>>>({
    employee: object<Partial<Record<keyof Employee, AnySchema>>>({
        firstName: string().required("First Name field is required."),
        lastName: string().required("Last Name field is required."),
        idNumber: string().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: string().required("ID number field is required."),
            otherwise: string().nullable()
        }),
        startedWorking: date().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: date().typeError("Started Working must be a valid date").required("Started Working date is required"),
            otherwise: date().nullable()
        }),
        jobSubcategoryTypeID: number().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: number().typeError("Job Title is Required").notOneOf([0], "Job Title is Required").required("Job Title is required"),
            otherwise: number().nullable()
        }),
        degreeID: number().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: number().typeError("Degree is Required").moreThan(0, "Degree is Required").required("Degree is required"),
            otherwise: number().nullable()
        }),
        maritalStatusID: number().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: number().typeError("Relationship Status is Required").moreThan(0, "Relationship Status is Required").required("Relationship Status is required"),
            otherwise: number().nullable()
        }),
        citizenshipID: number().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: number().typeError("Citizenship is Required").moreThan(0, "Citizenship is Required").required("Citizenship is required"),
            otherwise: number().nullable()
        }),
        email: string().email("Not a valid email").required("Email field is required."),
        phoneNumber: string().min(9, "Phone must be at least 9 characters").required("Phone field is required."),
        phoneNumber2: string().nullable().min(9, "Phone must be at least 9 characters"),
        jobSubcategoryType: object().when("employeeStatusID", {
            is: (employeeStatusID: number) => employeeStatusID != 4,
            then: object<Partial<Record<keyof JobSubcategoryType, AnySchema>>>({
                jobCategoryTypeID: number().typeError("Job Category is Required").moreThan(0, "Job Category is Required").required("Job Category is required"),
            }),
            otherwise: object().nullable()
        })
    })
        .when(['currentTab', "isSubmitting"], {
            is: (currentTab: TabsName, isSubmitting: boolean) => currentTab == TabsName.WorkScope || isSubmitting,
            then: object<Partial<Record<keyof Employee, AnySchema>>>({
                salariedEmployee: object().when("employeeStatusID", {
                    is: (employeeStatusID: number) => {console.log(employeeStatusID); return employeeStatusID == 1},

                    then: object<Partial<Record<keyof SalariedEmployee, AnySchema>>>({
                        hoursPerDay: number().typeError("Hours per Day is Required").moreThan(0, "Hours per Day is Required").required("Hours per Day is required"),
                        workScope: number().typeError("Work Scope is Required").moreThan(0, "Work Scope is Required").required("Work Scope is required"),
                    }),
                    otherwise: object().nullable()
                }),
            }),
            otherwise: object().nullable()
        }),
    password: string()
        .when(['currentTab', "isSubmitting", "employee"], {
            is: (currentTab: TabsName, isSubmitting: boolean, employee: Employee) => (currentTab == TabsName.Security || isSubmitting) && employee.id == "",

            then: string().nullable().min(8, "Password must be at least 8 characters").max(20, "Password may not be more than 20 characters").matches(RegExp(/\d/), "Password must contain at least 1 number").required("Password is required"),
            otherwise: string().nullable()
        }),
})