import { Checkbox, FormControl, Grid, InputAdornment, ListItemText, MenuItem, Select, TextField, TextFieldProps, ToggleButton } from "@mui/material";
import { getByPlaceholderText } from "@testing-library/react";
import { useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { GroupByOptions, ModalFlow, ModalName, PageTypeEnum, SidebarEnum, SortByOptions, StatusFilterEnum, StatusFilterType, SvgNames, YearlyMonthlyEnum } from "../../Constants/app-enums";
import { selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { allFiltersToggled, invoiceStatusFilterToggled, monthChanged, orderStatusFilterToggled, paymentStatusFilterToggled, selectAccountingSelectsViewModel, setGroupBy, setSearchText, setSelectedVendor, setSortBy, yearChanged, yearlyMonthlyEnumChanged } from "../../ReduxRelatedUtils/index-table-reducer";
import { ApplicationState, AccountingSelectsViewModel, NavigationInfo, Modal, ModalInfo } from "../../ReduxRelatedUtils/redux-types";
import { StyledSectionAutoCompleteInput, StyledSectionSelectInput } from "../../Utility/custom-input-components";
import { AutocompleteOption, GlobalSnackState } from "../../Utility/general-types";
import { StatusFilterViewModel, Vendor } from "../../Utility/request-types";
import { getDisplayNameOfEnumValue } from "../../Utility/root-function";
import { StyledOutlinedSectionDropdown, StyledTableButton, SvgIcon } from "../../Utility/shared-components";
import Theme from "../../Utility/theme";
import StyledToggleButtonGroup from "../../Users/monthly-yearly-button";
import { yearStartedTimekeeper } from "../../Constants/client-side-constants";
import { setModal } from "../../ReduxRelatedUtils/modals-reducer";
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { Download } from "@mui/icons-material";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";

export function VendorDropdown(props: { vendorList: Vendor[] }) {
    const { selectedVendor } = useSelector<ApplicationState>(state => state.indexTableObject.accountingSelectsViewModel) as AccountingSelectsViewModel

    const dispatch = useDispatch()
    function handleChange(e: any) {
        if (e == null) {
            dispatch(setSelectedVendor(null))
        }
        else {
            if (props.vendorList.findIndex(v => v.vendorID == e.value) != -1) {
                dispatch(setSelectedVendor(e.value))
            }

        }
    }
    return (
        <StyledSectionAutoCompleteInput value={props.vendorList.filter(v => v.vendorID == selectedVendor).map(v => { return { text: v.vendorEnName, value: v.vendorID } as AutocompleteOption })[0]} placeholder='Select a Vendor' onChange={handleChange} options={props.vendorList?.map(v => { return { text: v.vendorEnName, value: v.vendorID } as AutocompleteOption })} />
    )
}

export function InstallmentsGroupAndSort() {

    const dispatch = useDispatch();

    const accountingSelectsViewModel = useSelector(selectAccountingSelectsViewModel)

    function handleGroupByChange(e: any) {
        console.log(e.target.value)
        dispatch(setGroupBy(e.target.value))
    }
    function handleSortByChange(e: any) {
        console.log(e.target.value)
        dispatch(setSortBy(e.target.value))
    }

    return (
        <>
            <Grid item>
                <StyledSectionSelectInput renderValue={() => "Group By"} onChange={handleGroupByChange}>
                    {Object.keys(GroupByOptions).map(v =>
                        <MenuItem key={v} value={v} selected={v == accountingSelectsViewModel.groupBy} >
                            {v}
                        </MenuItem>
                    )}
                </StyledSectionSelectInput>
            </Grid>

            <Grid item>
                <StyledSectionSelectInput renderValue={() => "Sort By"} onChange={handleSortByChange}>
                    {Object.keys(SortByOptions).map(v =>
                        <MenuItem key={v} value={v} selected={v == accountingSelectsViewModel.sortBy} >
                            {v}
                        </MenuItem>
                    )}
                </StyledSectionSelectInput>
            </Grid>

        </>
    )
}


export function StatusDropdown() {
    const { orderStatuses, paymentStatuses, invoiceStatuses, selectAllStatus } = useSelector(selectAccountingSelectsViewModel)

    return (
        <Grid item >
            <StyledSectionSelectInput fullWidth
                renderValue={() => 'Select a Status'}
            >
                <StatusSelectItems statuses={[StatusFilterEnum.SelectAll]} selectedStatuses={selectAllStatus} statusType={StatusFilterType.SelectAll} />
                <StatusSelectItems statuses={[StatusFilterEnum.Ordered, StatusFilterEnum.Received]} selectedStatuses={orderStatuses} statusType={StatusFilterType.Order} />
                <hr className={'my-0'} />
                <StatusSelectItems statuses={[StatusFilterEnum.Paid, StatusFilterEnum.PendingPayment, StatusFilterEnum.PartialPayment]} selectedStatuses={paymentStatuses} statusType={StatusFilterType.Payment} />
                <hr className={'my-0'} />
                <StatusSelectItems statuses={[StatusFilterEnum.Invoiced, StatusFilterEnum.NoInvoice, StatusFilterEnum.PartialInvoice]} selectedStatuses={invoiceStatuses} statusType={StatusFilterType.Invoice} />
            </StyledSectionSelectInput>
        </Grid>
    )
}

function StatusSelectItems(props: { statuses: StatusFilterEnum[], statusType: StatusFilterType, selectedStatuses: StatusFilterEnum[] }) {
    const dispatch = useDispatch();
    const didMount = useRef(false);

    function handleStatusFilterChange(statusFilter: StatusFilterEnum) {
        switch (props.statusType) {
            case StatusFilterType.Order:
                dispatch(orderStatusFilterToggled(statusFilter));
                break;
            case StatusFilterType.Payment:
                dispatch(paymentStatusFilterToggled(statusFilter));
                break;
            case StatusFilterType.Invoice:
                dispatch(invoiceStatusFilterToggled(statusFilter));
                break;
            case StatusFilterType.SelectAll:
                dispatch(allFiltersToggled());
                break;
            default:
                console.log('invalid status type')
                break;
        }

    }
    return (
        <>
            {props.statuses?.map(s =>
                <MenuItem key={s} onClick={() => handleStatusFilterChange(s)} sx={{ paddingLeft: 0, paddingY: 0 }}  >
                    <Checkbox checked={props.selectedStatuses?.includes(s)}
                        sx={{
                            '&.Mui-checked': {
                                color: "#A26FF0",
                            }
                        }}
                    />
                    <ListItemText primary={getDisplayNameOfEnumValue(s)} />
                </MenuItem>
            )}
        </>
    )
}

export function MonthlyYearlyToggleButtons({ yearlyMonthlyEnum }: { yearlyMonthlyEnum: YearlyMonthlyEnum }) {
    const dispatch = useDispatch();
    return (
        <Grid item>
            <StyledToggleButtonGroup
                size="small"
                value={yearlyMonthlyEnum}
                exclusive
                onChange={(event, newVal) => {
                    if (newVal != null) {
                        dispatch(yearlyMonthlyEnumChanged(newVal))
                    }
                }}
            >
                <ToggleButton value={YearlyMonthlyEnum.Monthly}>Monthly</ToggleButton>
                <ToggleButton value={YearlyMonthlyEnum.Yearly}>Yearly</ToggleButton>
            </StyledToggleButtonGroup>
        </Grid>

    )
}

export function MonthDropdown({ selectedMonth }: { selectedMonth: number }) {
    const dispatch = useDispatch();
    return (
        <Grid item>
            <FormControl variant="standard" sx={{ minWidth: '11.125rem', marginTop: "1.3rem" }}>
                <StyledOutlinedSectionDropdown
                    id="month"
                    value={selectedMonth}
                    onChange={(e) => { dispatch(monthChanged(e.target.value)) }}
                >
                    {Array.from({ length: 12 }, (item, i) => {
                        const month = new Date(0, i).toLocaleString('en-US', { month: 'long' });
                        return (
                            <MenuItem key={i} value={i + 1} >
                                {month}
                            </MenuItem>
                        )
                    })}
                </StyledOutlinedSectionDropdown>
            </FormControl>
        </Grid>
    )
}

export function YearDropdown({ selectedYear }: { selectedYear: number }) {
    const dispatch = useDispatch();
    return (
        <Grid item>
            <FormControl variant="standard" sx={{ minWidth: '9.375rem', marginTop: "1.3rem" }}>
                <StyledOutlinedSectionDropdown
                    id="year"
                    value={selectedYear}
                    onChange={(e) => { dispatch(yearChanged(e.target.value)) }}
                >
                    {Array.from({ length: new Date().getFullYear() - yearStartedTimekeeper + 1 }, (item, i) => {
                        const year = yearStartedTimekeeper + i;
                        return (
                            <MenuItem key={year} value={year} >
                                {year}
                            </MenuItem>
                        )
                    })}
                </StyledOutlinedSectionDropdown>
            </FormControl>
        </Grid>
    )
}

export function SearchInput(props: TextFieldProps) {
    const [value, setValue] = useState("")
    const searchText = useSelector<ApplicationState>(state => state.indexTableObject.selectedRequestFilters.searchText) as string
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo

    function getSearchPlaceholder() {
        switch (navigationInfo.pageType) {
            case PageTypeEnum.LabManagementSuppliers:
            case PageTypeEnum.AccountingSuppliers:
                return "Search Name/Company ID"
            case PageTypeEnum.AccountingPayments:
            case PageTypeEnum.AccountingNotifications:
            case PageTypeEnum.AccountingGeneral:
                return "Search Name/Order No."
            case PageTypeEnum.AccountingSettings:
                return navigationInfo.sidebarType == SidebarEnum.CreditCards ? "Search Card No." : "Search"
            default:
                return "Search"
        }
    }
    const dispatch = useDispatch()
    return (<>
        <FormControl fullWidth variant="standard"   >

            <TextField
                variant="standard"
                InputLabelProps={{ shrink: true }}
                placeholder={getSearchPlaceholder()}

                defaultValue={searchText}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        dispatch(setSearchText(value))

                    }
                }}
                onChange={(e) => setValue(e.target.value)}
                onBlur={(e) => {
                    console.log(e.target.value)
                    if (e.target.value.length > 2) {
                        dispatch(setSearchText(e.target.value))
                    }
                }}
                InputProps={{ // <-- This is where the toggle button is added.
                    className: "section-input",
                    inputProps: {
                        sx: {
                            "&::placeholder": {
                                opacity: "0.5 !important",
                            },
                            lineHeight: "1.6rem",
                            height: "auto"
                        }
                    },
                    sx: {
                        marginTop: "1.3rem !important",
                        color: Theme.palette.grey[600],

                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <SvgIcon name={SvgNames.Search} height={1.5} color={Theme.palette.grey[500]} />
                        </InputAdornment>),
                    endAdornment: (
                        searchText ?
                            <InputAdornment sx={{ cursor: "pointer" }} position="end"
                                onClick={() => { dispatch(setSearchText("")) }}>
                                <SvgIcon name={SvgNames.Clear} height={1.2} color={Theme.palette.grey[500]} />
                            </InputAdornment>
                            : null)
                }}
                {...props}
            />


        </FormControl>
    </>
    )
}

export function ExcelExportButton(){
    const dispatch = useDispatch()
    function DownloadExcel(){
    {
            dispatch(setLoading(true))
            fetch("/Requests/DownloadRequestsToExcel", {
                method: "GET",
            })
                .then((response) => {
                    if (response.ok) { return response.blob() }
                    else { throw response.text() }
                })
                .then((res: any) => {
                    console.log(res)
                    var blob = new Blob([res], {
                        type: 'text/csv;charset=utf-8;',
                    });
        
                    const element = document.createElement('a');
                    element.href = URL.createObjectURL(blob);
                    element.setAttribute('download', "ExcelExport.zip");
                    document.body.appendChild(element);
                    element.click();
                    dispatch(setLoading(false))
                 })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({message:text, severity:"error", open:true} as GlobalSnackState))
                    })
                })
        }
    }
    return (
        <Grid item>
        <StyledTableButton onClick={()=>DownloadExcel()} sx={{backgroundColor:"#00CA72",   "&:hover": {
            backgroundColor: "#00CA72"
        }, marginTop:"1.3rem"}} startIcon={<SvgIcon name={SvgNames.TableChart} height={1.5} className="icon-white"/>}>
            Export Table
        </StyledTableButton>
        </Grid>
    )
}