import { createSlice } from '@reduxjs/toolkit'
import { InfoPopup } from './redux-types'

const infoPopupSlice = createSlice({
    name: 'infoPopup',
    initialState: {} as InfoPopup,
    reducers: {
        setInfoPopup(state:InfoPopup, action:{payload:InfoPopup}) {
            return action.payload
        },
       
    }
})

export const { setInfoPopup } = infoPopupSlice.actions
export const infoPopupReducer = infoPopupSlice.reducer

