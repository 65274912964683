import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogActions, Table, TableBody, TableCell, TableRow } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import Carousel from 'react-multi-carousel';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { AnySchema, array, boolean, date, mixed, number, object, string } from 'yup';
import { CurrencyEnum, FolderNamesEnum, ModalFlow, ModalName, SidebarEnum } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledCurrencyFormInput, StyledPercentFormInput } from '../../Utility/custom-input-components';
import { GetFileFramePath } from '../../Utility/document-functions';
import { AutocompleteOption, BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Country, ParentQuote, Request, Vendor } from '../../Utility/request-types';
import { handleCalendarUnitCalcChange, handleDateCalcChange } from '../../Utility/root-function';
import { GlobalForm, ModalItemTableCell, ModalItemTableRow, SectionCheckbox, SpacedColumnStack, SpacedRowStack, StyledFilledButton, StyledOutlineButton, StyledTableImage } from '../../Utility/shared-components';
import Theme from '../../Utility/theme';
import { CarouselItem } from './documents-modal';
import GlobalModal from './global-modal';
import { globalClose, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


export type ReviewQuoteViewModel = {
    fileStrings: string[],
}
export type ReviewQuoteFormData = {
    id: string
}
export default function ReviewQuoteModal() {
    const dispatch = useDispatch();
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState({} as ReviewQuoteViewModel);

    function onSubmit(data: any, e: any) {
        var url = '/Requests/ReviewQuote?id=' + Number(data.id);
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
        })
            .then(result => {
                if (!result.ok) throw result.text()
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Confirm Quote Success" }))
                })

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }

    const validationSchema = object<Partial<Record<keyof ReviewQuoteFormData, AnySchema>>>({

    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues:
            {
                id: modalInfo.ids[0]
            } as ReviewQuoteFormData
    }
    );

    useEffect(() => {
        var url = "/Requests/ReviewQuote?id=" + modalInfo.ids[0] + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: string[]) => {
                setViewModel({ fileStrings: result });
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);
    return (<>
        {loading ?
            <ModalSkeleton />
            :
            <>
                <ModalHeader headerText="Review and Confirm Quote" errorMessage={error.message} />
                {error.showBody ? <>
                    <StyledDialogContent>
                        <GlobalForm formID={ModalName.ReviewQuote} onSubmit={onSubmit} methods={methods}>
                            <Box height={"100%"} overflow="auto" display={"inline-block"}>
                                <Carousel

                                    renderButtonGroupOutside

                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 93
                                            },
                                            paritialVisibilityGutter: 80,
                                            items: 3
                                        }
                                    }}
                                    deviceType="desktop">

                                    {viewModel.fileStrings.map((fileString: string, i) => (
                                        <CarouselItem key={fileString + i} isEdittable={false} fileString={fileString} openDeleteDocsModal={() => { }} />
                                    )
                                    )}
                                </Carousel>

                            </Box>
                        </GlobalForm>

                    </StyledDialogContent>
                    <DialogActions sx={{ paddingX: "3rem" }}>
                        <Stack  width="100%" justifyContent={"space-between"} alignItems="center" direction={"row"}>


                      
                                <StyledOutlineButton onClick={(e: any) => { globalClose(true, dispatch) }}>Cancel</StyledOutlineButton>
                     
                            <SpacedRowStack width={"43%"}>
                            <StyledOutlineButton onClick={(e: any) => {
                                batch(() => {
                                    dispatch(setModalStep({ modalFlow: ModalFlow.ReviewQuote, modalStep: ModalName.EditQuoteDetails }))
                                    dispatch(setModalLoading(true))
                                })
                            }}>Replace Quote</StyledOutlineButton>
                            <StyledFilledButton type="submit" form={ModalName.ReviewQuote} >Confirm Quote</StyledFilledButton>

                            </SpacedRowStack>




                        </Stack>
                    </DialogActions>
                </>
                    : null
                }
            </>
        }</>

    );
}
