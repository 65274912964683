import { Divider, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider, Typography, TypographyProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { CurrencyEnum, SvgNames } from '../Constants/app-enums';
import { Request } from '../Utility/request-types';
import { BoldTypography, NameLogo, SpacedRowStack, SvgIcon } from '../Utility/shared-components';
import Theme from '../Utility/theme';
import { ConfirmEmailViewModel } from './Modals/confirm-email-modal';


type OrderEmailProps = ConfirmEmailViewModel & {
    isRequestPriceQuote?: boolean
}
const styles = {
    // Stack: {

    //     justifyContent: "space-between",
    //     padding: "1rem"
    // },
    bordered: {
        borderTop: "1px solid " + Theme.palette.grey[300],
    },
    boldTitle: {
        fontSize: "1.25rem",
        fontWeight: "bold"
    }
}
export default function OrderEmail(props: OrderEmailProps) {

    return (<>
        <Box
            sx={{
                border: "1px solid " + Theme.palette.grey[500],
                padding: "1rem"
            }}
        >
            <Box style={{ height: "100%", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>

                <OrderEmailContent  {...props} />
            </Box>
        </Box>
    </>)
}

function OrderEmailTableCell(props: TypographyProps & { value: string, colSpan?: number }) {
    return (
        <TableCell colSpan={props.colSpan ?? 1} sx={{ border: `1px solid ${Theme.palette.grey[400]} !important`, textAlign: "left", padding: "1rem" }}>
            <Typography {...props}>{props.value}</Typography>
        </TableCell>

    )
}
function OrderEmailTableHeaderCell(props: TypographyProps & { value: string, width: number }) {
    return (
        <TableCell width={props.width + "%"} sx={{ backgroundColor: Theme.palette.error.main, border: `1px solid ${Theme.palette.error.main} !important`, padding: "0.5rem 1rem" }}>
            <Typography {...props} color="white">{props.value}</Typography>
        </TableCell>

    )
}
export function OrderEmailWithLayout(props: OrderEmailProps) {

    return (
        <ThemeProvider theme={Theme}>

            <div style={{ padding: "3rem" }}>
                <Box style={{ height: "1350px", justifyContent: "space-between", display: "flex", flexDirection: "column" }}>

                    <OrderEmailContent {...props} />
                </Box>
            </div>
        </ThemeProvider>


    )


}

function OrderEmailContent(props: OrderEmailProps) {

    const currencySymbol = props.requests[0].currency == CurrencyEnum.NIS ? "₪" : "$"
    const shipping = props.requests[0].currency == CurrencyEnum.NIS ? props.shipping : props.shipping / props.requests[0].exchangeRate
    const total = props.requests.reduce((a, b) => a + getCostofRequestByCurrency(b), 0)
    const vat = props.requests.reduce((a, b) => a + getVATofRequestByCurrency(b), 0)
        + (props.requests.filter(r => r.includeVAT == true).length > 0 && shipping ? shipping * 0.17 : 0)
    // 
    const svgToDataURL = require('svg-to-dataurl')

    const centarixInstallmentsSVG = renderToString(<SvgIcon name={SvgNames.CentarixInstallments} height={4} />)
    const centarixNotificationSVG = renderToString(<SvgIcon name={SvgNames.CentarixNotificationDidntArrive} height={4} />)

    function getVATofRequestByCurrency(request: Request) {
        return request.includeVAT ?
            (request.currency == CurrencyEnum.NIS ? request.cost * .17 : (request.cost / request.exchangeRate * .17))
            : 0
    }
    function getCostofRequestByCurrency(request: Request) {
        return (request.currency == CurrencyEnum.NIS ? request.cost : (request.cost / request.exchangeRate))
    }
    return (
        <>
            <Box>
                <Stack direction="column">
                    <Stack direction={"row"} style={{ justifyContent: "space-between", padding: "1rem 0" }}>
                        <Box style={{ height: "50%" }}><NameLogo width={65} /></Box>
                        <Stack direction="column">
                            <Typography style={styles.boldTitle}>Centarix Biotech</Typography>
                            <Typography>ID: 515655215</Typography>
                            <Typography>Tel: 073-7896888</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction={"row"} borderTop={styles.bordered.borderTop}
                        style={{ justifyContent: "space-between", padding: "1rem 0", alignItems: "center" }}>
                        <Typography fontSize={"1.5rem"} fontWeight="bold">
                            {props.isRequestPriceQuote ? "Price Quote Request" : "Purchase Order: " + props.parentRequest.orderNumber}
                        </Typography>
                        <Typography>{moment().format("DD MMM yyyy")}</Typography>
                    </Stack>
                    <Stack direction={"row"} borderTop={styles.bordered.borderTop}
                        style={{ justifyContent: "space-between", padding: "1.5rem 0", alignContent: "center", }}>
                        <Stack direction="column" style={{ minWidth: "40%" }}>
                            <Typography style={styles.boldTitle}>{props.vendor.vendorEnName}</Typography>
                            <Typography>ID: {props.vendor.vendorBuisnessID}</Typography>
                            <Typography>{props.vendor.vendorCity}</Typography>
                            <Typography>Tel: {props.vendor.vendorCellPhone}</Typography>
                        </Stack>
                        {(!props.isRequestPriceQuote && props.parentRequest?.noteToSupplier?.trim() != "") &&
                            <Stack direction={"column"} style={{ width: "100%" }}>
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Box style={{ width: "1.3rem", marginRight: "0.2rem" }} component={"img"} src={svgToDataURL(centarixNotificationSVG)}></Box>
                                    <Typography style={{ fontSize: "1.25rem", fontWeight: "bold", textAlign: "left", width: "100%", margin: "0" }} >Please Note</Typography>
                                </Stack>
                                <Box sx={{ padding: "0.5rem", borderRadius: "0.1rem", backgroundColor: "#FEECEF", minWidth: "10rem", border: "1px solid " + Theme.palette.grey[300] }}>
                                    <Typography >{props.parentRequest.noteToSupplier}</Typography>
                                </Box>
                            </Stack>}
                    </Stack>
                    {!props.isRequestPriceQuote && <Box padding={"2rem"} marginBottom={"2rem"} border={theme => "2px solid " + theme.palette.grey[300]}>
                        <SpacedRowStack alignItems={"center"}>
                            <BoldTypography variant='h5'>Shipping Address</BoldTypography>
                            <Divider sx={{width:"2px"}} orientation='vertical' flexItem />
                            <Typography>{props.shippingAddress}</Typography>
                        </SpacedRowStack>
                    </Box>}
                    <Table sx={{ minWidth: 650, marginBottom: "1rem" }}>
                        <TableHead >
                            <TableRow >
                                <OrderEmailTableHeaderCell width={30} value="Items" />
                                <OrderEmailTableHeaderCell width={15} value="ID" />
                                <OrderEmailTableHeaderCell width={10} value="Units" />
                                {!props.isRequestPriceQuote &&
                                    <>
                                        <OrderEmailTableHeaderCell width={16} value="Price" />
                                        <OrderEmailTableHeaderCell align="right" width={16} value="Total" />
                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ border: "none" }}>
                            {props.requests.map((r, i) => (
                                <TableRow key={r.requestID} sx={{ borderLeft: theme => "2px solid " + theme.palette.grey[300] }}>
                                    <OrderEmailTableCell fontWeight={"bold"} value={r.product.productName} />
                                    <OrderEmailTableCell value={r.product.catalogNumber} />
                                    <OrderEmailTableCell value={r.unit.toString()} />
                                    {!props.isRequestPriceQuote &&
                                        <>
                                            <OrderEmailTableCell value={currencySymbol + ((r.currency == CurrencyEnum.NIS ? r.cost : (r.cost / r.exchangeRate)) / r.unit).toFixed(2)} />
                                            <OrderEmailTableCell value={currencySymbol + (r.currency == CurrencyEnum.NIS ? r.cost : (r.cost / r.exchangeRate)).toFixed(2)} />
                                        </>
                                    }
                                </TableRow>
                            ))}
                            {!props.isRequestPriceQuote &&
                                <>
                                    {(props.shipping && props.shipping != 0) ?
                                        <TableRow sx={{borderBottom:"none"}}>
                                            <TableCell colSpan={3} sx={{ borderLeft: theme => "2px solid " + theme.palette.grey[300] }} />
                                            <OrderEmailTableCell  value="Shipping" />
                                            <OrderEmailTableCell value={currencySymbol + shipping.toFixed(2)} />
                                        </TableRow>
                                        : null
                                    }
                                    <TableRow>
                                        <TableCell colSpan={3} rowSpan={3} sx={{ borderLeft: theme => "2px solid " + theme.palette.grey[300] }} />
                                        <OrderEmailTableCell  value="Total" />
                                        <OrderEmailTableCell value={currencySymbol + (total+shipping).toFixed(2)} />
                                    </TableRow>
                                    {props.requests.filter(r => r.includeVAT).length > 0 &&
                                        <TableRow>
                                            <OrderEmailTableCell value="VAT" />
                                            <OrderEmailTableCell value={currencySymbol + vat.toFixed(2)} />
                                        </TableRow>
                                    }
                                    <TableRow>
                                        <OrderEmailTableCell fontWeight="bold" value="Total To Pay" />
                                        <OrderEmailTableCell fontWeight="bold" value={currencySymbol + ((total + vat + shipping).toFixed(2))} />
                                    </TableRow>
                                </>
                            }
                        </TableBody>
                    </Table>
                </Stack>
            </Box>
            <Box style={{ paddingTop: "2rem" }}>
               
                <Stack direction="row" style={{ justifyContent: "space-between", alignItems:"end"}}>
                    {!props.isRequestPriceQuote ?
                      <Stack direction="column" style={{ display: "flex", width: "75%", borderRadius: " 0.1rem", margin: "0.5rem 0", padding: "0.7rem", border: "1px solid " + Theme.palette.grey[300] }}>
              
                        <Typography style={{ fontSize: "1.25rem", fontWeight: "bold", textAlign: "left", margin: "0", width: "100%" }}>According to Quote Number(s)</Typography>

                        <Typography > {props.quoteNumbers.join(", ")}</Typography>
                    
                        <Typography style={{ fontSize: "1.25rem", fontWeight: "bold", textAlign: "left", margin: "0", width: "100%" }}>Payment Terms:</Typography>

                        <Typography > {props.paymentStatus.paymentStatusID == 5 ? props.installments + " Installments" : props.paymentStatus.paymentStatusDescription}</Typography>
                       {props.requests[0].currency == CurrencyEnum.NIS && <Typography > *We are aware that prices in ILS could change due to changes in exchange rate on the invoice issue date, according to the terms in the price quote.</Typography>}

                    </Stack>: <Box />
                    }
                    <Stack style={{ display: "flex", alignItems: "end", width: "30%", flexDirection: "column", paddingLeft: "1rem" }}>
                        <Box style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end" }}><NameLogo width={100} /></Box>
                        <BoldTypography style={{ borderTop: "1px solid " + Theme.palette.grey[300], fontSize: "1.25rem",  textAlign: "right", paddingTop: "0.3rem", width: "100%" }}>{`${props.applicationUser.firstName} ${props.applicationUser.lastName} `}</BoldTypography>
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}