import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, array, date, object, string } from 'yup';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { StyledControlledFormDateInput, StyledControlledMultipleSelect } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError, SelectListItem } from '../../Utility/general-types';
import { basicModalPost, objectToFormData } from '../../Utility/root-function';
import { GlobalForm, SpacedColumnStack, SpacedRowStack } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


type ConfirmOrderFormData = {
    requestIDs: string[],
    orderDate: string
}

export default function ConfirmOrderModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const validationSchema = object<Partial<Record<keyof ConfirmOrderFormData, AnySchema>>>({
        orderDate: date().typeError("Order date must be a valid date").required("Order date is required"),
    })
    const [error, setError] = useState<CustomError>(new CustomError())

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:
            { requestIDs: modalInfo.ids, orderDate: "" } as ConfirmOrderFormData
    }
    );

    useEffect(()=>{
        dispatch(setModalLoading(false))
    },[])


    function onSubmit(data: ConfirmOrderFormData) {
            var url = '/Requests/ConfirmOrderModal'
            dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.json() }
                    throw response.text();
                })
                .then((res: { result: any }) => {
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(removeModal())
                        dispatch(setGlobalSnack({ open: true, message: "Order Confirmed", severity: "success" }))
                        dispatch(setReloadIndex(true))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
    }

    return (
        <GlobalModal key={ModalName.Share} size={ModalSizes.xs} modalKey={ModalName.ConfirmOrder}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Confirm Item" errorMessage={error.message} />
                        <StyledDialogContent>

                            <GlobalForm methods={methods} formID={ModalName.ConfirmOrder} onSubmit={onSubmit}>
                                <Stack spacing={1}>
                                    <Typography>Confirm that this item has been ordered on:</Typography>
                                    <StyledControlledFormDateInput name="orderDate" /> 
                                </Stack>
                            </GlobalForm>

                        </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.ConfirmOrder} submitButtonText="Confirm" />
                </>
            }
        </GlobalModal>
    );

}