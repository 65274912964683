import { Table, TableBody, TableCell, TableCellProps, TableHead, TableProps, TableRow, Typography } from "@mui/material"
import { Box, Stack, TypographyProps } from "@mui/system"
import moment from "moment"
import { useState, useEffect } from "react"
import { useSelector, useDispatch, batch } from "react-redux"
import { setPageSkeleton } from "../ReduxRelatedUtils/utilsReducer"
import { ApplicationState } from "../ReduxRelatedUtils/redux-types"
import viewmodelReducer, { setViewModel } from "../ReduxRelatedUtils/viewmodel-reducer"
import IndexTablePageHeaders from "../Shared/IndexTable/index-table-page-headers"
import IndexTableRow from "../Shared/IndexTable/index-table-row"
import IndexTableSkeleton from "../Shared/IndexTable/index-table-skeleton"
import { BoldTypography, SmallTypography, SpacedColumnStack, SpacedRowStack, TitleTypography, TitleTypography2 } from "../Utility/shared-components"
import { EmployeeHours, ReportDaysViewModel } from "../Utility/timekeeper-types"
import { ReportHoursViewModel } from "./report-hours"

export function SummaryDaysOff() {
    const [reloadPage, setReloadPage] = useState(true)
    const viewModel = useSelector<ApplicationState>(state => state.viewModel) as ReportDaysViewModel
    const { year } = useSelector<ApplicationState>(state => state.indexTableObject.monthYearFilters) as ReportDaysViewModel
    const showPageSkeleton = useSelector<ApplicationState>(state => state.showPageSkeleton) as boolean
    const dispatch = useDispatch()
    useEffect(() => {
        if (reloadPage) {
            var url = "/Timekeeper/SummaryDaysOff?year=" + year
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response;
                })
                .then((result: ReportDaysViewModel) => {
                    batch(() => {
                        dispatch(setViewModel(result))
                        dispatch(setPageSkeleton(false))
                    })
                    setReloadPage(false)
                })
                .catch(err => {
                    console.log(err)
                })
        }

    }, [reloadPage])


    useEffect(() => {
        setReloadPage(true)
        batch(() => {
            dispatch(setPageSkeleton(true))
        })
    }, [year])


    return (showPageSkeleton ? <IndexTableSkeleton /> :
        (viewModel != null && !reloadPage) ?
            <Stack height="70vh" sx={{ overflow: "hidden" }}>
                <IndexTablePageHeaders />
                <Box width="100%" flexGrow={"1"} overflow={"hidden"}>
                    <Stack direction="row" spacing={4} justifyContent="space-between" width="100%" height="100%">
                        <Stack sx={{ width: "100%", height: "100%" }} >
                            <Table >
                                <TableHead>
                                    <CustomTableRow >
                                        <TableTitle>Vacation Days</TableTitle>
                                        <CustomTableCell>Total</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>Taken</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>Left</CustomTableCell>
                                        <TableTitle />
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody className="section-border">
                                    <CustomTableRow >
                                        <TableTitle />
                                        <CustomTableCell>{viewModel.vacationDays}</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>{viewModel.vacationDaysTakenCount}</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>{viewModel.vacationDaysLeft.toFixed(2)}</CustomTableCell>
                                        <TableTitle />
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                            <DaysOffBlock days={viewModel.vacationDaysTaken} />
                        </Stack>
                        <Stack sx={{ width: "100%", height: "100%" }}>
                            <Table >
                                <TableHead>
                                    <CustomTableRow >
                                        <TableTitle>Sick Days</TableTitle>
                                        <CustomTableCell>Total</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>Taken</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>Left</CustomTableCell>
                                        <TableTitle />
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody className="section-border">
                                    <CustomTableRow>
                                        <TableTitle />
                                        <CustomTableCell>{viewModel.sickDays}</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>{viewModel.sickDaysTakenCount}</CustomTableCell>
                                        <TableCellWithSlash />
                                        <CustomTableCell>{viewModel.sickDaysLeft.toFixed(2)}</CustomTableCell>
                                        <TableTitle />
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                            <DaysOffBlock days={viewModel.sickDaysTaken} />
                        </Stack>
                        <Stack sx={{ width: "100%", height: "100%" }}>
                            <Table >
                                <TableHead>
                                    <CustomTableRow>
                                        <TableTitle>Special Days</TableTitle>
                                        <TableCell width="32%">Taken</TableCell>
                                        <TableTitle />
                                    </CustomTableRow>
                                </TableHead>
                                <TableBody className="section-border">
                                    <CustomTableRow>
                                        <TableTitle />
                                        <TableCell width="32%">{viewModel?.specialDaysTaken?.length ?? "0"}</TableCell>
                                        <TableTitle />
                                    </CustomTableRow>
                                </TableBody>
                            </Table>

                            <DaysOffBlock days={viewModel.specialDaysTaken} />
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
            : null

    )
}

function DaysOffBlock(props: { days: EmployeeHours[] }) {
    console.log(props.days)
    return <Box sx={{flexGrow:"1", overflow:"auto"}}>
        <Stack>
            {props.days.map((v, i) => 
            <Stack key={i} sx={{ padding: '1rem', borderColor: (theme) => `${theme.palette.grey[300]}`, borderWidth: "0px 2px 2px 2px", borderStyle: "solid" }} direction={"row"}>
                <SmallTypography>{moment(v.date).format("dddd, MMMM Do, YYYY")}</SmallTypography>
            </Stack>
            )}
        </Stack>
    </Box>
}

function TableTitle(props: { children?: string }) {
    return (
        <TableCell width="34%"><BoldTypography textAlign="left" >{props.children}</BoldTypography></TableCell>
    )
}

function CustomTableCell(props: { children?: string | number }) {
    return (
        <TableCell width="8%">{props.children}</TableCell>
    )
}
function CustomTableRow(props: { children?: JSX.Element[] }) {
    return (
        <TableRow sx={{ border: "none" }}>{props.children}</TableRow>
    )
}

function TableCellWithSlash() {
    return (
        <TableCell width="4%">/</TableCell>
    )
}