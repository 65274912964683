import { Typography, DialogActions } from "@mui/material"
import { Stack } from "@mui/system"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { InfoPopupName, ModalName } from "../../Constants/app-enums"
import { ModalSizes } from "../../Constants/client-side-enums"
import { StyledFilledButton, StyledNoOutlineButton, StyledOutlineButton, StyledSectionOutlineButton } from "../../Utility/shared-components"
import GlobalModal from "./global-modal"
import { ModalHeader, StyledDialogContent, ModalFooterWithCustomSave, ModalFooterWithSave } from "./modal-components"
import { EditCardType } from "../../Shared/edit-card"
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer"
import ModalSkeleton from "./modal-skeleton"
import Skeleton from "react-loading-skeleton"
import { RequestItemViewModel } from "../../Utility/request-types"
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore"
import { ApplicationState } from "../../ReduxRelatedUtils/redux-types"
import { useSelector } from "react-redux"
import { RowViewModel } from "../../Utility/general-types"

export function ConfirmSaveModal(props: { setConfirmModalOpen: (arg:boolean)=>void,  formID:string , editType:EditCardType, isExit?:boolean}) {

    const methods = useFormContext()

    const requestByProduct = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.formID ?? 0)?.viewModel as RequestItemViewModel).requestsByProduct) as  RowViewModel[];
    const [message, setMessage] = useState("There are " + requestByProduct.length + " requests associated with this item. Are you sure you would like to save the edits you made?");
    useEffect(() => {
        switch(props.editType){
            case EditCardType.EditProduct:
            case EditCardType.EditItem:                   
                         setMessage("There are " + requestByProduct.length + " requests associated with this item. Are you sure you would like to save the edits you made?")       
                break;
        }
    }, [])


    return (
        <GlobalModal size={ModalSizes.sm} modalKey={ModalName.ConfirmSaveModal} hideBackdrop={true} closeClick={() => { props.setConfirmModalOpen(false) }}>

            <ModalHeader headerText="Confirm Save" errorMessage="" />
       
            <StyledDialogContent>
         
                <Typography>{message}</Typography>
    
            </StyledDialogContent>         
            
            <ModalFooterWithSave closeClick={() => { props.setConfirmModalOpen(false) }} formID={props.editType+ props.formID} submitValue={props.isExit?"exit":""} submitButtonText="Save" >                  
            </ModalFooterWithSave>

        </GlobalModal>

    )
}