import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import AdditionalRequestInfoModal from './additional-request-info-modal';
import ConfirmEmailModal from './confirm-email-modal';
import ConfirmOrderModal from './confirm-order-modal';
import EditEmailModal from './edit-email-modal';
import EditQuoteDetailsModal from './edit-quote-details-modal';
import GlobalModal from './global-modal';
import ReorderModal from './reorder-modal';
import ReviewQuoteModal from './review-quote-modal';
import TermsModal from './terms-modal';
import UploadOrderModal from './upload-order-modal';
import UploadQuoteModal from './upload-quote-modal';



export default function ReviewQuoteFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName
    const dispatch = useDispatch();
   

    console.log("in list modal flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.ReviewQuote:
                return (<ReviewQuoteModal />)
            case ModalName.EditQuoteDetails:
                return (<EditQuoteDetailsModal />)
   
            default: return null;
        }
    }

    return (<>{modalStep!=ModalName.None&&
        <GlobalModal size={ModalSizes.lg} modalKey={modalStep}>
            <ModalContent />
        </GlobalModal>}</>
    );

}
