import { Box, DialogContent, DialogContentProps, Stack, styled, Typography } from "@mui/material";
import { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import React, { PropsWithChildren, useContext } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { InfoPopupName, ModalFlow, ModalName, SidebarEnum, SvgNames } from "../../Constants/app-enums";
import { selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { removeModal, setModal, setModalLoading, setModalStep } from "../../ReduxRelatedUtils/modals-reducer";
import { resetTempData } from "../../ReduxRelatedUtils/temp-data-reducer";
import { EditCardType } from "../../Shared/edit-card";
import { IsEdittableContext, SmallTypography, SpacedRowStack, StyledError, StyledFilledButton, StyledNoOutlineButton, StyledOutlineButton, SvgIcon } from "../../Utility/shared-components";

type ModalHeaderProps = {
    headerText: string,
    subHeaderText?: string,
    errorMessage: string
}

export function ModalHeader(props: ModalHeaderProps) {
    return (
        <Box padding="1rem 3rem">
            <SpacedRowStack alignItems={"center"}><DialogTitle paddingX="0 !important" width="fit-content">{props.headerText}</DialogTitle>
                {props.subHeaderText && <SmallTypography >{props.subHeaderText}</SmallTypography>}
            </SpacedRowStack>
            <Divider  />
            {props.errorMessage && <StyledError>{props.errorMessage}</StyledError>}
        </Box>
    )
}


type ModalFooterProps = {
    submitButtonText?: string,
    formID: ModalName|InfoPopupName|string,
    closeClick?: React.MouseEventHandler<HTMLButtonElement>,
    submitValue?:string
}
export function ModalBackButton(props: {
    modalFlowKey: ModalFlow,
    undoTempData?: boolean
}) {
    const dispatch = useDispatch()
    return (
        <Box width="10rem">
            <StyledOutlineButton
                startIcon={<SvgIcon name={SvgNames.ArrowBackIos} height={1} />}
                onClick={() => {
                    batch(() => {
                        if (props.undoTempData) dispatch({ type: "undoTempData" })
                        dispatch({ type: "undoModals" })
                        dispatch(setModalLoading(true))
                    })
                }}>Back</StyledOutlineButton>
        </Box>
    )
}

export function ModalFooterWithSave(props: PropsWithChildren<ModalFooterProps>) {
    return (
        <DialogActions sx={{ padding: "1rem 0 1rem 3rem" }}>
            <Stack direction={'row-reverse'} spacing={1} width="100%" justifyContent={"space-between"} alignItems="center">
                <ModalFooterWithCustomSave closeClick={props.closeClick}>
                    <StyledFilledButton type="submit" form={props.formID} name={props.submitValue}>{props.submitButtonText ?? "Save"}</StyledFilledButton>
                </ModalFooterWithCustomSave>
                {props.children}
            </Stack>
        </DialogActions>
    )
}
// export function ModalFooterWithNext(props: PropsWithChildren<ModalFooterProps>) {
//     return (
//         <DialogActions>
//             {props.children}
//             <ModalFooterWithCustomSave closeClick={props.closeClick}>
//                 <StyledFilledButton type="submit" form={props.formID} >{props.submitButtonText ?? "Save"}</StyledFilledButton>
//             </ModalFooterWithCustomSave>
//         </DialogActions>
//     )
// }
export function globalClose(clearModals: boolean, dispatch: any) {
    if (clearModals) {
        dispatch(removeModal())

        dispatch(resetTempData())
        dispatch({ type: "clearModalsHistory" })
    }
    else {
        dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.None, modalInfo: {} }))

    };

}
export function ModalFooterWithCustomSave(props: PropsWithChildren<{ closeClick?: React.MouseEventHandler<HTMLButtonElement>, cancelButtonText?:string }>) {
    const dispatch = useDispatch()
    const sidebarType = useSelector(selectNavigationInfo).sidebarType


    return (
        <DialogActions sx={{ marginLeft: "auto", paddingRight: "3rem" }}>
            <Stack direction='row' width="100%" spacing={1} justifyContent="end">
            
                <StyledOutlineButton onClick={(e: any) => { props.closeClick != undefined ? props.closeClick(e) : globalClose(sidebarType != SidebarEnum.Add, dispatch) }}>{props.cancelButtonText ??"Cancel"}</StyledOutlineButton>
                {props.children}
            </Stack>
        </DialogActions>
    )
}

export const StyledDialogContent = (props: PropsWithChildren<DialogContentProps>) => {
    return (
        <DialogContent sx={{ display: "flex", overflow:"auto", padding:"1rem 3rem" }}>
            <Box height="100%" width="100%">
                {props.children}
            </Box>
        </DialogContent>
    )
}