import TableRow, { TableRowProps } from '@mui/material/TableRow';
import { RowViewModel } from '../../Utility/general-types';
import IndexTableColumn from './index-table-column';
import { memo } from 'react';


  
  type IndexTableRowProps = {
    row: RowViewModel;
    displaytable?: "true" | "false";
    isChecked?: boolean;
  } & TableRowProps;
  
  const IndexTableRow: React.FC<IndexTableRowProps> = memo((props) => {
  
    return (
      <TableRow sx={{ ...props.sx, height: '4rem', display: props.displaytable ? 'table' : 'table-row', width: '100%' }}>
        {props.row?.columns?.map((col, i) => (
          <IndexTableColumn isChecked={props.isChecked} key={props.row.id + 'id' + i} columnData={col} />
        ))}
      </TableRow>
    );
  });
  
  export default IndexTableRow;