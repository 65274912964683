import Stack from '@mui/material/Stack';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import 'regenerator-runtime/runtime';
import { AddItemResult, ModalFlow, ModalName, SidebarEnum } from '../../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading, setShowConfirmExit } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal, ModalInfo, NavigationInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { BasicModalInfo, CustomError, EditEmailModalInfo } from '../../Utility/general-types';
import { ParentRequest, PaymentStatus, Request, Vendor } from '../../Utility/request-types';
import { convertScriptsToScriptTag, convertStyleSheetsToCss, downloadFile, downloadFilesAsZip } from '../../Utility/root-function';
import { GlobalForm, StyledSectionOutlineButton } from '../../Utility/shared-components';
import { Employee } from '../../Utility/user-types';
import OrderEmail, { OrderEmailWithLayout } from '../order-email';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { TermsFormData } from './terms-modal';

export type ConfirmEmailViewModel = {
    vendor: Vendor,
    paymentStatus: PaymentStatus,
    requests: Request[],
    shipping: number,
    parentRequest: ParentRequest,
    applicationUser: Employee, 
    installments:number,
    shippingAddress:string,
    quoteNumbers:string[],
}

export default function ConfirmEmailModal() {
    const dispatch = useDispatch();
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<TermsFormData>
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo

    function onSubmit(data: TermsFormData, e: any, viewModel: ConfirmEmailViewModel) {
        var orderPdf = ReactDOMServer.renderToString(<OrderEmailWithLayout {...viewModel} />)
        const fullHtml = `
            <!doctype html>
            <html>
                <head>
                <style type="text/css">${convertStyleSheetsToCss()}</style>
                ${convertScriptsToScriptTag()}
                </head>
                <body>
                ${orderPdf}
                </body>
            </html>
            `;
        let tempDataCopy = { ...tempData, addItemFormData: { ...tempData.addItemFormData } }
        tempDataCopy.orderPDF = fullHtml;
        console.log("in submit "+ e.nativeEvent.submitter.name)
        if (e.nativeEvent.submitter.name == "DownloadSave") {
            var url = '/Requests/ConfirmEmailModal'
            dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(tempDataCopy),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((res: { result: AddItemResult, filePath:string }) => {
                    dispatch(setLoading(false))
                    switch (res.result) {
                        case AddItemResult.RequiresOrderConfirmation:
                            downloadFile(res.filePath)
                            batch(() => {

                                dispatch(removeModal())
                                dispatch(setGlobalSnack({ open: true, message: "Item Saved" }))
                                if (navigationInfo.sidebarType == SidebarEnum.Add) {
                                    dispatch(setShowConfirmExit(false))
                                    dispatch(push("/RequestsList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))
                                }
                                else {
                                    dispatch(setReloadIndex(true))

                                }
                            })

                            break;
                    }

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text.errorMessage, severity: "error" }))
                    })
                })
        }
        else {
            batch(() => {
                dispatch(setTempData(tempDataCopy))
                dispatch(setModalLoading(true))
                dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.EditEmail, modalInfo:{orderNumber:viewModel.parentRequest.orderNumber} as EditEmailModalInfo }))
            }
            )

        }
    }


    return (
        <ConfirmEmailForm onSubmit={onSubmit} tempData={tempData} />
    );
}
type ConfirmEmailFormProps = {
    onSubmit: Function,
    tempData: TempData<TermsFormData>
}

export function ConfirmEmailForm(props: ConfirmEmailFormProps) {
    const dispatch = useDispatch();

    const [viewModel, setViewModel] = useState({} as ConfirmEmailViewModel);
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const modalPast = (useSelector<ApplicationState>(state => state.modalWithLoading.modal.past) as Modal<ModalInfo>[])
    const fromTermsModal = modalPast.length > 0 && modalPast.slice(-1)[0].modalStep == ModalName.Terms

    const methods = useForm()

    useEffect(() => {
        var url = "/Requests/GetConfirmEmailModalJson";
        if (props.tempData.addItemFormData == null) url += "?IDs=" + modalInfo.ids
        fetch(url, {
            method: "POST",
            body: JSON.stringify(props.tempData),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: ConfirmEmailViewModel) => {
                setViewModel(result);
                dispatch(setModalLoading(false))
                if (!fromTermsModal) {
                    dispatch(setTempData({ guid: props.tempData.guid, requestIDs: modalInfo.ids } as TempData<TermsFormData>))
                }

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);
    return (<>
        {loading ?
            <ModalSkeleton />
            :
            <>
                <ModalHeader headerText={"Place Order"} errorMessage={error.message} />
                {error.showBody ? <>
                    <StyledDialogContent>
                        <GlobalForm methods={methods} formID={ModalName.ConfirmEmail} onSubmit={(data: any, e: any) => props.onSubmit(data, e, viewModel)}>
                            <OrderEmail {...viewModel} />
                        </GlobalForm>
                    </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.ConfirmEmail} submitButtonText={"Save Order"}>
                        <Stack direction="row" spacing={1}>

                        {fromTermsModal && <ModalBackButton modalFlowKey={ModalFlow.AddItem} undoTempData={true} />}
                        <StyledSectionOutlineButton type="submit" form={ModalName.ConfirmEmail} name="DownloadSave">Download & Save</StyledSectionOutlineButton>

                        </Stack>
                    </ModalFooterWithSave>
                </>
                    : null}

            </>}
    </>
    )
}

