import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { BasicModalInfo, CustomError, TimekeeperActionModalInfo } from '../Utility/general-types';
import { GlobalForm } from '../Utility/shared-components';
import { getDisplayNameOfEnumValue } from '../Utility/root-function';


type ConfirmDayOffViewModel = {
  date: string
  offDayType: string
}
type ConfirmDayOffFormData = {
  employeeHoursID:number,
  typeID:number
}
export default function ConfirmDayOffModal() {
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<TimekeeperActionModalInfo>;    
    const [error, setError] = useState<CustomError>(new CustomError());
    const methods = useForm<ConfirmDayOffFormData>({
        defaultValues:{
            employeeHoursID:modalInfo.ids?.[0]!=null?Number(modalInfo.ids[0]):undefined,
            typeID: modalInfo.typeID,
            
        }
      });
  
    const [viewModel, setViewModel] = useState<ConfirmDayOffViewModel>({} as ConfirmDayOffViewModel)
    
    useEffect(() => {
        var url = '/Timekeeper/ConfirmDaysOffModal?offDayTypeID='+modalInfo.typeID+"&employeeHoursID="+modalInfo.ids?.[0];
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: ConfirmDayOffViewModel) => {
                dispatch(setModalLoading(false))
                setViewModel(result);     
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, []);

       
    function onSubmit(data: ConfirmDayOffFormData) {       
        var url = '/Timekeeper/ConfirmDaysOffModal'
        dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then(( result:string ) => {
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(removeModal())
                        dispatch(setGlobalSnack({ open: true, message: "Off Day Reported", severity: "success" }))
                        dispatch(setReloadIndex(true))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
    }

    return (
        <GlobalModal key={ModalName.ConfirmDayOff} size={ModalSizes.xs} modalKey={ModalName.ConfirmDayOff}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Report "+ getDisplayNameOfEnumValue(viewModel.offDayType)} errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.ConfirmDayOff} onSubmit={(data: any) => onSubmit(data)} methods={methods}>
                                <Typography>
                                   {viewModel.date}
                                </Typography>
                              
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.ConfirmDayOff} submitButtonText="Confirm" />
                    </>}
                </>
            }
        </GlobalModal>

    );
}
