import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormGroup, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, number, object, ref, string } from 'yup';
import { LoginResult } from '../Constants/app-enums';
import { setErrorMessage as globalSetErrorMessage } from "../ReduxRelatedUtils/errorReducer";
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import { setIsAuthenticated } from '../ReduxRelatedUtils/userInfoReducer';
import { LoginStyledInput } from '../Utility/custom-input-components';
import { CustomError } from '../Utility/general-types';
import LoginPasswordInput from '../Utility/password-input';
import { objectToFormData } from '../Utility/root-function';
import { CenterChildren, LoginButton, NameLogo, StyledError } from '../Utility/shared-components';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
const validationSchema = object<Partial<Record<keyof ResetPasswordFormData, AnySchema>>>({
    Password: string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password must not exceed 20 characters')
        .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, "Password must contain a lowercase, uppercase, number, symbol character"),
    ConfirmPassword: string()
        .required('Confirm Password is required')
        .oneOf([ref("Password"), null], 'Confirm Password does not match'),
    AuthenticationCode: number().nullable().typeError("Authentication code must be a number").required("Authentication Code is required"),
});

const StyledBox = styled(Box)`   
    text-align: center;
    width: 27em;
    margin: auto; 
    border:1px solid ${props => props.theme.palette.error.main};
    border-radius: 1rem;
    height: 65%;
    overflow:scroll
`


type ResetPasswordFormData = {
    email: string,
    Password: string,
    ConfirmPassword: string,
    Token: string,
    AuthenticationCode?: number | null
}
type ResetPasswordFormProps = {
    onSubmit: (data: ResetPasswordFormData) => void
}
export default function ResetPassword() {
    const dispatch = useDispatch();
    //const reg=`(?=.\d)(?=.[a-z])(?=.[A-Z])((?=.\W)|(?=.*_))^[^ ]+$` as RegExp;

    function onSubmit(resetPasswordFormData: ResetPasswordFormData) {
        dispatch(setLoading(true))
        console.log(resetPasswordFormData)
        fetch("Login/ResetPassword", {
            method: 'POST',
            body: objectToFormData(resetPasswordFormData),
        }).then((response) => {
            if (response.status == 500) {
                dispatch(setGlobalSnack({ open: true, message: "Response 500: something went wrong", severity: "error" }))
                throw response.statusText
            }
            return response.json()
        })
            .then(result => {
                dispatch(setLoading(false))
                const loginResult = result as LoginResult
                switch (loginResult) {
                    case LoginResult.Succeeded:
                        dispatch(setIsAuthenticated(true));
                        break;
                    case LoginResult.InvalidTwoFACode:
                        batch(() => {
                            dispatch(push("/Login"))
                            dispatch(setGlobalSnack({ open: true, message: "Reset password unsucessful. Authentication code invalid.", severity: "error" }))
                        })
                        break;
                    case LoginResult.InvalidAttempt:
                        dispatch(push("/Login"))
                        dispatch(setGlobalSnack({ open: true, message: "Invalid Attempt", severity: "error" }))
                        break;
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (
        <>
            <CenterChildren >
                <NameLogo width={15} />
                <StyledBox textAlign={'center'}>
                    <Typography variant="h5" paddingBottom={'1rem'} paddingTop={'2rem'}>Set Up Elixir Account</Typography>
                    <ResetPasswordForm onSubmit={onSubmit} />
                </StyledBox>
            </CenterChildren>
        </>
    )
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
    const dispatch = useDispatch();
    const code = useSelector<ApplicationState>(state => state.router.location.query["code"]) as string;
    const email = useSelector<ApplicationState>(state => state.router.location.query["email"]) as string;
    const methods = useForm({
        resolver: yupResolver(validationSchema), defaultValues: { Password: "", ConfirmPassword: "", AuthenticationCode: null, email: email, Token: code } as ResetPasswordFormData
    });
    const authenticatorUri = useSelector<ApplicationState>(state => state.router.location.query["authenticatorUri"]) as string;
    console.log(authenticatorUri)

    useEffect(() => {
        if (code == undefined) {
            batch(() => {
                dispatch(push("/Login"));
                dispatch(setGlobalSnack({ open: true, message: "Need a reset password token", severity: "error" }))
            })
        }
    }, [])

    return (
        <FormProvider {...methods}>
        <form style={{ display: "inline-block", width: "80%" }} onSubmit={methods.handleSubmit(props.onSubmit)}>
            {process.env.NODE_ENV !== 'production' && <DevTool control={methods.control} />}
            <Stack spacing={2}>
            <LoginPasswordInput
                name="Password"   
                placeholder='Password'              
            />
            <LoginPasswordInput
                 name="ConfirmPassword" 
                 placeholder="Confirm Password"               
            />
                <FormGroup>
                    <Typography pb='3%' variant="body1">Set Up 2FA:</Typography>
                    <QRCodeSVG value={decodeURIComponent(authenticatorUri)} size={150} style={{ margin: 'auto' }} />

                </FormGroup>
                <LoginStyledInput name="AuthenticationCode" placeholder="Authentication Code" />     
                <LoginButton type="submit">Set Up</LoginButton>
            </Stack>
        </form>
        </FormProvider>
    )
}
