import { useState, useEffect } from 'react'
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { CurrencyEnum, ItemCardFields } from "../Constants/app-enums";
import { VendorBlock, BatchLotBlock, WarrantyBlock, SupplyDaysBlock, UrlBlock, PassageAmountBlock, CreationDateBlock, QuoteBlock, SupplierOrderNumberBlock, InvoiceBlock, ReceivedBlock, OrderBlock, CategoryBlock, PaymentsBlock } from "../Requests/ItemCard/item-components";
import ItemTab from "../Requests/ItemCard/item-details-tab";
import { StyledControlledAutoCompleteInput, StyledControlledFormDateInput, StyledControlledFormInput, StyledFormInput } from "../Utility/custom-input-components";
import { AutocompleteOption, CustomError, ViewModelBase } from "../Utility/general-types";
import { CompanyAccount, CreditCard, ParentCategory, Payment, PaymentType, ProductSubcategory, RequestItemViewModel, Vendor } from "../Utility/request-types";
import { GlobalForm, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack, StyledFilledButton, StyledFullPageOutlinedBox, TitleTypography } from "../Utility/shared-components";
import { Employee } from '../Utility/user-types';
import { ApplicationState, NavigationInfo } from '../ReduxRelatedUtils/redux-types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { selectNavigationInfo } from '../ReduxRelatedUtils/customConfigureStore';
import { setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { setLoading, setPageSkeleton } from '../ReduxRelatedUtils/utilsReducer';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { requestsSearchViewModelReducer, setReloadIndex, setRequestsSearchViewModel } from '../ReduxRelatedUtils/index-table-reducer';
import { SEARCH_REQUESTS_RESULTS } from '../Constants/Routes';
import { setViewModel } from '../ReduxRelatedUtils/viewmodel-reducer';
import IndexTableSkeleton from './IndexTable/index-table-skeleton';
export type RequestsSearchFormData = {
    parentCategoryID?: number,
    productSubcategoryID?: number|null,
    vendorID?: number|null,
    itemName?: string,
    productSecondaryName?: string,
    invoiceNumber?: string,
    invoiceDate?: string | null,
    batch?: number,
    expirationDate?: string | null,
    creationDate?: string | null,
    currency?: string,
    arrivalDate?: string | null,
    applicationUserReceiverID?: string|null,
    quoteNumber?: string,
    catalogNumber?: string,
    productSerialNumber?: string,
    requestSerialNumber?: number | null,
    orderDate?: string | null,
    orderNumber?: number | null,
    supplierOrderNumber?: string,
    applicationUserOwnerID?: string|null,
    payment: Payment,
}
export type RequestsSearchViewModel = {
    parentCategories: ParentCategory[],
    productSubcategories: ProductSubcategory[],
    vendors: Vendor[],
    currencies: CurrencyEnum[],
    applicationUsers: Employee[],
    paymentTypes: AutocompleteOption[],
    creditCards: CreditCard[],
    companyAccounts: AutocompleteOption[],
} & ViewModelBase
export function ItemSearchPage() {
    const [state, setState] = useState() as any;
    const viewModel =  useSelector<ApplicationState>(state=>state.viewModel)as RequestsSearchViewModel;
    const showPageSkeleton =  useSelector<ApplicationState>(state=>state.showPageSkeleton)as RequestsSearchViewModel;
    const formData = useSelector<ApplicationState>(state=>state.indexTableObject.requestsSearchViewModel)as RequestsSearchFormData;
    const [error, setError] = useState<CustomError>();
    const methods = useForm<RequestsSearchFormData>({
        defaultValues: {            
            parentCategoryID:formData.parentCategoryID,
            productSubcategoryID:formData.productSubcategoryID??null,
            vendorID:formData.vendorID??null,
            itemName:formData.itemName,
            productSecondaryName:formData.productSecondaryName,
            invoiceNumber:formData.invoiceNumber,
            invoiceDate:formData.invoiceDate??"",
            batch:formData.batch,
            expirationDate:formData.expirationDate??"",
            creationDate:formData.creationDate??"",
            currency:formData.currency,
            arrivalDate:formData.arrivalDate??"",
            applicationUserReceiverID:formData.applicationUserReceiverID??null,
            quoteNumber:formData.quoteNumber,
            catalogNumber:formData.catalogNumber,
            productSerialNumber:formData.productSerialNumber,
            requestSerialNumber:formData.requestSerialNumber,
            orderDate:formData.orderDate??"",
            orderNumber:formData.orderNumber,
            supplierOrderNumber:formData.supplierOrderNumber,
            applicationUserOwnerID:formData.applicationUserOwnerID??null,
            payment:formData.payment ?? {
                creditCardID: null,
                companyAccountID:null,
                paymentTypeID: null
            } as Payment
        }
    });
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo;
    useEffect(() => {
        dispatch(setPageSkeleton(true))
        var url = "/Requests/Search?sectionType=" + navigationInfo.sectionType;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response;
            })
            .then((result: RequestsSearchViewModel) => {
                batch(()=>{
                    dispatch(setPageSkeleton(false))
                    dispatch(setViewModel(result))
                    
                })
            })
            .catch(err => {
                Promise.resolve(err).then((text: RequestsSearchViewModel) => {
                    console.log(text)
                    setError({ message: text.errorMessage ?? "", showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [])

    function onSubmit(data:RequestsSearchFormData) {
        batch(()=>{
                dispatch(setReloadIndex(true))
                dispatch(setRequestsSearchViewModel(data))
                dispatch(push(queryString.stringifyUrl({ url: SEARCH_REQUESTS_RESULTS, query: navigationInfo })))
        })
    }
    const [productSubcategories, setProductSubCategories]= useState<ProductSubcategory[]>();
    const payment = methods.watch("payment");
    const parentCategoryID = methods.watch("parentCategoryID");

    useEffect(()=>{       
        setProductSubCategories(viewModel.productSubcategories?.filter(ps=>ps.parentCategoryID==parentCategoryID))        
        methods.setValue("productSubcategoryID", null)
    }, [parentCategoryID, viewModel])

    return (showPageSkeleton ? <IndexTableSkeleton /> :
        <StyledFullPageOutlinedBox overflow={"hidden"}>
            <Box padding={"1rem"} overflow="auto"  height={"80vh"}>
                <GlobalForm methods={methods} onSubmit={onSubmit} formID="addCard">
                    <SpacedColumnStack padding={"2rem"} >
                        <SpacedRowStack justifyContent={"space-between"}>
                            <TitleTypography>Search Requests</TitleTypography>
                            <Box sx={{width:"30% "}} >
                            <StyledFilledButton   type="submit">Search</StyledFilledButton>
                            </Box>
                        </SpacedRowStack>
                        <SpacedRowStack>
                            <StyledControlledFormInput name="itemName" label="Item Name" />
                        </SpacedRowStack>
                        <SpacedRowStack>
                            <StyledControlledAutoCompleteInput name="parentCategoryID" label="Parent Category"
                                options={viewModel?.parentCategories?.map(u => { return { text: u.description, value: u.id } as AutocompleteOption }) ?? []}
                            />
                            <StyledControlledAutoCompleteInput name="productSubcategoryID" label="Product Subcategory"
                                options={productSubcategories?.map(u => { return { text: u.description, value: u.id } as AutocompleteOption }) ?? []}
                            />
                            <StyledControlledAutoCompleteInput
                            
                                label="Vendor"
                                name="vendorID"
                                options={viewModel?.vendors?.map(v => { return { text: v.vendorEnName, value: v.vendorID } as AutocompleteOption }) ?? []}
                            />

                        </SpacedRowStack>
                        <SpacedRowStack>
                            <StyledControlledAutoCompleteInput name="applicationUserOwnerID" label="Owner" 
                                options={viewModel?.applicationUsers?.map(u => { return { text: u.firstName + " " + u.lastName, value: u.id } as AutocompleteOption }) ?? []}
                            />
                            <StyledControlledAutoCompleteInput name="applicationUserReceiverID" label="Received By"
                                options={viewModel?.applicationUsers?.map(u => { return { text: u.firstName + " " + u.lastName, value: u.id } as AutocompleteOption }) ?? []}
                            />
                            <StyledControlledAutoCompleteInput name="currency" label="Currency" 
                                options={viewModel?.currencies?.map(u => { return { text: u, value: u } as AutocompleteOption }) ?? []}
                            />
                        </SpacedRowStack>

                        <SpacedRowStack>
                            <StyledControlledFormDateInput name="creationDate" label="Date Created" />
                            <StyledControlledFormDateInput name="orderDate" label="Order Date" />
                            <StyledControlledFormDateInput name="arrivalDate" label="Arrival Date"  />
                        </SpacedRowStack>
                        <SpacedRowStack>
                            <StyledControlledFormInput type="number" label="Batch/Lot" name="batch" />
                            <StyledControlledFormDateInput label="Expiration Date" name="expirationDate" value=""/>
                            <StyledControlledFormInput name="orderNumber" label="Company Order Number" />
                        </SpacedRowStack>
                        <SpacedRowStack>
                            <StyledControlledFormInput name="supplierOrderNumber" label="Supplier Order Number" />
                            <StyledControlledFormInput name="catalogNumber" label="Catalog Number" />
                            <StyledControlledFormInput name="productSerialNumber" label="Product Serial Number" />
                            <StyledControlledFormInput name="requestSerialNumber" label="Request Serial Number" />
                        </SpacedRowStack>
                        <SpacedRowStack>
                            <StyledControlledFormInput name="invoiceNumber" label="Invoice Number" />
                            <StyledControlledFormInput name="quoteNumber" label="Quote Number" />
                            <StyledControlledFormDateInput name="invoiceDate" label="Invoice Date" />
                        </SpacedRowStack>
                        <PaymentDetailsBlock name='payment'  creditCards={viewModel?.creditCards} companyAccounts={viewModel?.companyAccounts} paymentTypes={viewModel?.paymentTypes} />


                    </SpacedColumnStack>
                </GlobalForm>
            </Box>
        </StyledFullPageOutlinedBox>
    )
}