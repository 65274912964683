import { yupResolver } from "@hookform/resolvers/yup";
import { push } from "connected-react-router";
import { createContext, useContext, useEffect, useState } from "react";

import queryString from 'query-string'

import { useForm, useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { PageTypeEnum, SidebarEnum } from "../Constants/app-enums";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { ApplicationState, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { clearEditDrawerViewModel, setReloadEditModal } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { EditItemInnerForm } from "../Requests/ItemCard/edit-item";
import { EditProductInnerForm } from "../Requests/ItemCard/edit-product";
import { ConfirmExitModal } from "../Requests/Modals/confirm-exit-modal";
import { AddSupplierInnerForm } from "../Suppliers/add-supplier";
import { EditSupplierInnerForm } from "../Suppliers/edit-supplier";
import { EditUserInnerForm } from "../Users/edit-user";
import { CustomError } from "../Utility/general-types";
import { RequestItemViewModel } from "../Utility/request-types";
import { CloseButtonLeftAlign, GlobalForm, IsEdittableContext, StyledError } from "../Utility/shared-components";
import { productDefaultValues, requestDefaultValues, supplierDefaultValues, userDefaultValues } from "./AddCardComponents/default-values";
import { productValidationSchema, requestValidationSchema, supplierValidationSchema, userValidationSchema } from "./AddCardComponents/validation-schemas";
import GlobalDrawer from "./EditDrawers/global-drawer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import Box from "@mui/material/Box";

type EditCardFormProps = {
    onSubmit: (data: any, optionalParam1?: any) => void,
    editType: EditCardType,
}



export default function EditCard(props: EditCardFormProps) {
    const error = useSelector<ApplicationState>(state => state.error) as CustomError
    const navigationInfo = useSelector(selectNavigationInfo);
    const resolver = yupResolver(props.editType == EditCardType.EditItem ? requestValidationSchema : props.editType ==EditCardType.EditProduct?productValidationSchema:
        props.editType == EditCardType.EditSupplier? supplierValidationSchema : userValidationSchema)
    const defaultValues = props.editType == EditCardType.EditItem ?  requestDefaultValues({} as RequestItemViewModel,Number(navigationInfo.id)) : props.editType == EditCardType.EditProduct? productDefaultValues(Number(navigationInfo.id)) :
    props.editType == EditCardType.EditSupplier? supplierDefaultValues() : userDefaultValues(navigationInfo.id)
    const methods = useForm({ resolver: async (data, context, options) => {
    //     //you can debug your validation schema here
    //    console.log('formData', data)
    //    console.log('validation result', await resolver(data, context, options))
        return resolver(data, context, options)
      }, defaultValues: {...defaultValues, submittedSuccessful:false} });

    return (<>
  <GlobalDrawer drawerEnum={props.editType}>
  {error.message && <StyledError>{error.message}</StyledError>}
{error.showBody ?
            <GlobalForm methods={methods} onSubmit={props.onSubmit} formID={props.editType+navigationInfo.id} isEdittable={false}>
                   <InnerFormWithConfirmExit editType={props.editType}/>
                    
            </GlobalForm>
   : null}
   </GlobalDrawer>
    </>
    )
}

export enum EditCardType {
    EditItem = "EditRequest",
    EditSupplier = "EditSupplier",
    EditUser = "EditUser",
    EditProduct = "EditProduct",
}

function InnerFormWithConfirmExit(props:{editType:EditCardType}){
    const editCardContext = useContext(EditCardContext);
    const isEdittableContext = useContext(IsEdittableContext)
    const {pathname} = useSelector<ApplicationState>(state => state.router.location) as Location;
    const dispatch= useDispatch()
    const {formState} = useFormContext();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo;

    useEffect(()=>{
        console.log(            "is dirty"+formState.isDirty)
    },[formState.isDirty])

    function discardAndExit(closeDrawer: boolean) {
        if(closeDrawer || !formState.isDirty){
            let newPathname = pathname.replace("/"+props.editType,"")
           batch(()=>{
              dispatch(push(queryString.stringifyUrl({ url: newPathname, query: {pageType:navigationInfo.pageType, sectionType:navigationInfo.sectionType, sidebarType: navigationInfo.sidebarType }})))
              dispatch(setError({showBody:true, message:""}))
              dispatch(clearEditDrawerViewModel())
           })
        
        }
        else{
           editCardContext.toggleConfirmModalOpen(true)
        }
     
     }



    return(<Box sx={{height:"100vh", overflow:"hidden", display:"flex", flexDirection:"column"}} className={isEdittableContext.isEdittable?"section-edit-view-top-border":""}>
       <CloseButtonLeftAlign onClick={()=> discardAndExit((!isEdittableContext.isEdittable))} ></CloseButtonLeftAlign>
  
            <InnerForm editType={props.editType}/>
    {editCardContext.confirmModalOpen &&
        <ConfirmExitModal setConfirmModalOpen={editCardContext.toggleConfirmModalOpen} discardAndExit={()=>discardAndExit(true)} editType={props.editType} formID={navigationInfo.id} />}</Box>)
}

function InnerForm(props:{editType: EditCardType}) {
    
    const navigationInfo = useSelector(selectNavigationInfo);
    const editCardContext = useContext(EditCardContext);
    const isEdittableContext = useContext(IsEdittableContext)
    useEffect(()=>{
        if(editCardContext.submitSucceeded){
            isEdittableContext.toggleIsEdittable(false);
            editCardContext.toggleSubmitSucceeded(false);
        }
    }, [editCardContext.submitSucceeded])
    //console.log(props.editType)
    switch (props.editType) {
        case EditCardType.EditItem:
        return (<EditItemInnerForm id={navigationInfo.id} />)
        case EditCardType.EditSupplier:
            return (<EditSupplierInnerForm  id={navigationInfo.id}/>)
        case EditCardType.EditUser:
            return (<EditUserInnerForm  id={navigationInfo.id}/>)
        case EditCardType.EditProduct:
            return (<EditProductInnerForm id={navigationInfo.id}/>)
              
        default:return(<></>)
    }
}

export const EditCardContext = createContext({ submitSucceeded: false, toggleSubmitSucceeded : (v: boolean) => { }, confirmModalOpen: false, toggleConfirmModalOpen : (v: boolean) => { }  });


