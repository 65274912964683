import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindPopover, bindTrigger, bindHover } from 'material-ui-popup-state';
import { CSSProperties } from 'react';


type GlobalPopoverProps = {
    style?: CSSProperties,
    width?: number,
    closeOnClick?: boolean,
    popoverClassName?:string,
    children: JSX.Element[];
};
export default function GlobalPopover (props : GlobalPopoverProps) {
    return (
        <PopupState variant="popover" >
            {(popupState) => (
                <div style={props.style}>
                    <div style={{cursor:"pointer"}}  {...bindTrigger(popupState)} >
                        {props.children[0]}
                    </div>
                    <Popover disableScrollLock={true}
                    className={props.popoverClassName}
                    container={() => document.getElementsByTagName("main")[0]}
                        {...bindPopover(popupState)}   
                        onClick={ ()=> {props.closeOnClick ? popupState.setOpen(false) : null} }         
                        sx={{ width: props.width, '& .MuiPaper-root':{ boxShadow: "0px 3px 8px rgb(218 218 218)"} }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        {props.children[1]}
                    </Popover>
                </div>
            )}
        </PopupState>);
}