import { Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlow, ModalName, SvgNames } from '../Constants/app-enums';
import { setModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { Modal } from '../ReduxRelatedUtils/redux-types';
import { LocationModalInfo, CustomError } from '../Utility/general-types';
import { StyledError, SvgIcon } from '../Utility/shared-components';
import { LocationVisualItem } from './location-visual-item';
import { VisualLocationViewModel } from './locations-types';

export function LocationsVisual(props: { parentLocationID: number }) {
    const [viewModel, setViewModel] = useState<VisualLocationViewModel>({} as VisualLocationViewModel)
    const [error, setError] = useState<CustomError>(new CustomError());
    const dispatch = useDispatch()

    useEffect(() => {
        var url = "/Locations/GetLocationsVisual?parentLocationInstanceID=" + props.parentLocationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: VisualLocationViewModel) => {
                setViewModel(result);

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: "There was an error loading the visual: " + text.errorMessage, showBody: false })
                })
            })
    }, [props.parentLocationID])

    function openZoom() {
        dispatch(setModalLoading(true))
        dispatch(setModal({ modalFlow: ModalFlow.LocationZoom, modalStep: ModalName.LocationZoom, modalInfo: { parentLocationID: props.parentLocationID } } as Modal<LocationModalInfo>))
    }
    return (<Grid position={"relative"} container overflow={"scroll"} justifyContent="center" alignItems="center" sx={{ marginLeft: "1rem", marginBottom: "1.5rem", border: (theme) => "solid 1px " + theme.palette.grey[400] }} width="50%" padding="2rem 5rem">
        {viewModel?.width > 1 && <Button sx={{ top: "1rem", right: "1rem", position: "absolute" }} onClick={openZoom}>
            <SvgIcon name={SvgNames.OpenFull} height={2} />
        </Button>}
        <Grid container maxWidth={viewModel?.width > 1 ? "100%" : "20rem"} borderTop={(theme) => "solid 1px " + theme.palette.grey[400]} columns={viewModel?.width}>
            <StyledError>{error.message}</StyledError>
            {viewModel?.visualItems?.map((v, i) =>
                <LocationVisualItem item={v} width={viewModel?.width} index={i} />
            )}

        </Grid></Grid>)
}