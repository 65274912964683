import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { PropsWithChildren, useEffect, useState, useRef } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { AnySchema, array, boolean, date, mixed, number, object, string } from 'yup';
import { CurrencyEnum, FolderNamesEnum, ModalName, SvgNames } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import viewmodelReducer from '../../ReduxRelatedUtils/viewmodel-reducer';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledCurrencyFormInput, StyledPercentFormInput, ControlledCheckbox } from '../../Utility/custom-input-components';
import { AutocompleteOption, BasicModalInfo, CustomError } from '../../Utility/general-types';
import { CompanyAccount, Country, CreditCard, Invoice, ParentQuote, Payment, PaymentType, Product, Request, Vendor } from '../../Utility/request-types';
import { handleCalendarUnitCalcChange, handleDateCalcChange } from '../../Utility/root-function';
import { AddInvoiceBlock, GlobalForm, ModalItemTableCell, ModalItemTableRow, PaymentDetailsBlock, SectionCheckbox, SpacedColumnStack, SpacedRowStack, StyledTableImage, SvgIcon } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


export type AddInvoiceViewModel = {
    requests: RequestWithAmountPaid[]
    paymentTypes: AutocompleteOption[],
    companyAccounts: AutocompleteOption[],
    creditCards: CreditCard[],
    canPay: boolean
}
export type AddInvoiceFormData = {
    payment: Payment,
    invoice: Invoice,
    hasFile: boolean,
    requests: Request[],
    guid: string,
    shippingInCurrency: number,
    shippingVat: number,
    sum: number,
    sumVat: number,
    paid: boolean,
    discountAmount: number,
    discountPercent: number
    totalWithVatShippingDiscount: number,
    amountPaid:number
}

type RequestWithAmountPaid = { r: Request, amountPaid: number }

export default function AddInvoiceModal() {
    const dispatch = useDispatch();
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState({} as AddInvoiceViewModel);

    function onSubmit(data: any, e: any) {
        var url = '/Requests/AddInvoiceModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(result => {
                if (!result.ok) throw result.text()
                console.log("basic modal result")
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Add Invoice Successful" }))
                })

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }

    const validationSchema = object<Partial<Record<keyof AddInvoiceFormData, AnySchema>>>({
        discountAmount:number().test("max discount amount", "Discount amount cannot be greated than sum", function (this) {
                return this.parent.sum >= this.parent.discountAmount;          
        }),
        discountPercent: number().max(100, "Discount cannot be greater than 100%"),
        shippingInCurrency:number().test("shipping validation", "Shipping and price must be more than already paid", function (this) {
            return this.parent.totalWithVatShippingDiscount >= this.parent.amountPaid;          
        }),
        payment: object<Partial<Record<keyof Payment, AnySchema>>>({
            /*paymentReferenceDate:date().when("paymentTypeID", {
                is: (paymentTypeID: number) => paymentTypeID != 2,
                then: date().nullable().max(moment().endOf("day"), "Reference Date cannot be in the future"),
                otherwise: date().nullable()
            })*/
        }),
        //.when("paid", {is:true,
           // then:object<Partial<Record<keyof Payment, AnySchema>>>({paymentReferenceDate: date().typeError("Reference date must be a valid date").max(moment().add("day").startOf("day"), "Reference date cannot be in the future"),
           
    // }),
        //     otherwise:object()
        //}),
        invoice: object<Partial<Record<keyof Invoice, AnySchema>>>({
            invoiceNumber: string().required("Invoice Number is required"),
            invoiceDate: date().typeError("Invoice Date must be a vaild date").required("Invoice Date is required").max(moment().add("day").startOf("day"), "Invoice date cannot be in the future"),
    
        }),
        hasFile: boolean().isTrue("File is required"),
       
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            requests:[] as Request[],
            guid: v4(),
            discountAmount: 0,
            discountPercent: 0,
            totalWithVatShippingDiscount: 0,
            shippingInCurrency: 0,
            shippingVat: 0,
            sum: 0,
            sumVat: 0,
            payment: {
                paymentTypeID:0,
                paymentReferenceDate:"",
                companyAccountID:0,
                creditCardID:0,
                checkNumber:"",
                reference:""
            } as Payment,
            invoice: {invoiceDate:"", invoiceNumber:""} as Invoice,
            paid: false,
            hasFile: false
        } as AddInvoiceFormData

    }
    );
    const guid = methods.watch(`guid`)
    const isPaid = methods.watch(`paid`)
    const currency = methods.watch(`requests.0.currency`)
    const shipping = methods.watch(`shippingInCurrency`);
    const shippingVat = methods.watch(`shippingVat`)
    const sum = methods.watch("sum");
    const sumVat = methods.watch(`sumVat`)
    const payment = methods.watch("payment");
    const requests = methods.watch(`requests`);
    const discountAmount = methods.watch(`discountAmount`);


    useEffect(() => {        
        dispatch(setModalLoading(true))
        setViewModel({} as AddInvoiceViewModel);
        methods.reset();
        var url = "/Requests/AddInvoiceModal?requestIDs=" + modalInfo.ids + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: AddInvoiceViewModel) => {
                setViewModel(result)
                methods.setValue(`requests`, result.requests.map(r => {
                    return {cost: r.r.cost, exchangeRate:r.r.exchangeRate, includeVAT:r.r.includeVAT, requestID:r.r.requestID, shipping:r.r.shipping, currency:r.r.currency} as Request
                }))
                methods.setValue("amountPaid", result.requests.map(r=>r.amountPaid).reduce((prev, next) => { return +prev + +next }));
                if(result.requests[0].r.currency==CurrencyEnum.NIS){
                    methods.setValue("shippingInCurrency", result.requests.map(r=>r.r.shipping).reduce((prev, next) => { return +prev + +next }));
                }
                else{
                    methods.setValue("shippingInCurrency", result.requests.map(r=>r.r.shipping/r.r.exchangeRate).reduce((prev, next) => { return +prev + +next }));
                }
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);


    useEffect(() => {
        console.log("requests useeffect")
        var sumCost = 0;
        var sumVatCost = 0;
        if (currency == CurrencyEnum.NIS) {

            requests?.map(r => {
                console.log(r.cost)
                sumCost = +sumCost+ +r.cost;
                if (r.includeVAT) {
                    sumVatCost = +sumVatCost+ +(r.cost * .17);
                }
            })

        }
        else {
            requests?.map(r => {
                console.log(r.costDollar)
                sumCost = +sumCost+ +r.costDollar;
                if (r.includeVAT) {
                    sumVatCost = +sumVatCost + +(r.costDollar * .17);
                }
            })
        }

        methods.setValue("totalWithVatShippingDiscount", +sumCost + +sumVatCost + +shipping + +shippingVat)
        methods.setValue("sum", sumCost)
        methods.setValue("sumVat", sumVatCost)

    }, [JSON.stringify(requests)]);



    useEffect(() => {
        var total = +sum+ +sumVat+ +shipping;
        if(viewModel.requests?.filter(r=>!r.r.includeVAT)?.length==0)
        {
            methods.setValue("shippingVat", shipping*.17);
        }
        methods.setValue("totalWithVatShippingDiscount", total);
    }, [shipping]);


    useEffect(() => {
        var total = +sum+ +sumVat+ +shipping+ +shippingVat;        
        methods.setValue("totalWithVatShippingDiscount", total);
    }, [shippingVat]);



    function onChangeDiscountAmount(e: any) {
        var discountAmount = e.target.value;
        methods.setValue("discountAmount", discountAmount);
        console.log("onchange discount amount" + discountAmount)
        var discountPercent = 0;
        if (discountAmount != 0) {
            var sum = requests.map(r => r.costBeforeDiscount).reduce((prev, next) => { return +prev + +next });
            console.log(sum)
            discountPercent = (discountAmount / sum);
            methods.setValue("discountPercent", discountPercent * 100);
        }
        else {
            methods.setValue("discountPercent", 0);
        }

        resetRequestBasedOnDiscount(discountPercent)

    }

    function onChangeDiscountPercent(e: any) {
        var discountPercent = +e.target.value;
        methods.setValue("discountPercent", discountPercent);
        console.log("onchange discount percent" + discountPercent)
        var sum = 0
        if (discountPercent != 0) {
            sum = requests.map(r => r.costBeforeDiscount).reduce((prev, next) => { return +prev + +next });
            methods.setValue("discountAmount", sum * discountPercent * .01);
        }
        else {
            methods.setValue("discountAmount", 0);
        }

        resetRequestBasedOnDiscount(discountPercent * .01);
    }

    function resetRequestBasedOnDiscount(discountPercent: number) {
        if(currency==CurrencyEnum.NIS){
        if (discountPercent != 0) {
            requests?.map((r, i) => {
                methods.setValue(`requests.${i}.cost`, +r.costBeforeDiscount - +(+r.costBeforeDiscount * +discountPercent));
            });
        }
        else {
            requests?.map((r, i) => {
                methods.setValue(`requests.${i}.cost`, r.costBeforeDiscount);
            });
        }
    }
    else{
        if (discountPercent != 0) {
            requests?.map((r, i) => {
                methods.setValue(`requests.${i}.costDollar`, +r.costBeforeDiscount - +(+r.costBeforeDiscount * +discountPercent));
            });
        }
        else {
            requests?.map((r, i) => {
                methods.setValue(`requests.${i}.costDollar`, r.costBeforeDiscount);
            });
        }
    }
    }

    return (
        <GlobalModal key={ModalName.AddInvoice} size={ModalSizes.lg} modalKey={ModalName.AddInvoice}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Add Invoice" errorMessage={error.message} />
                    {error.showBody ? <>
                        <StyledDialogContent>

                            <GlobalForm formID={ModalName.AddInvoice} onSubmit={onSubmit} methods={methods}>

                                
                                <SpacedColumnStack >
                                <Table>
                                    <TableBody sx={{ width: "100%" }}>
                                        {viewModel?.requests?.map((r, i) => {
                                            return (
                                                <RequestRow requestWithCanEditCost={r} key={r.r.requestID} index={i} />
                                            )
                                        })
                                        }
                                    </TableBody>

                                </Table>
                                    <AddInvoiceBlock name="" guid={guid} />

                                    <SpacedRowStack key="3">
                                        <StyledPercentFormInput onChange={onChangeDiscountPercent} disabled={!viewModel.canPay} label="Invoice Discount" name="discountPercent" />
                                        <StyledCurrencyFormInput onChange={onChangeDiscountAmount} disabled={!viewModel.canPay} currency={currency == CurrencyEnum.NIS ? "₪" : "$"} name="discountAmount" />
                                        <StyledCurrencyFormInput disabled currency={currency == CurrencyEnum.NIS ? "₪" : "$"} label="Sum" name="sum" />
                                        <StyledCurrencyFormInput disabled currency={currency == CurrencyEnum.NIS ? "₪" : "$"} label="VAT" name="sumVat" />
                                    </SpacedRowStack>
                                    <SpacedRowStack>
                                        <SpacedRowStack key="2" sx={{ width: "100%" }}>
                                            <StyledCurrencyFormInput disabled={viewModel.requests?.filter(r=>r.amountPaid==0)?.length==0}  currency={currency == CurrencyEnum.NIS ? "₪" : "$"} label="Shipping" name="shippingInCurrency" />
                                            <StyledCurrencyFormInput disabled currency={currency == CurrencyEnum.NIS ? "₪" : "$"} label="VAT Shipping" name="shippingVat" />
                                        </SpacedRowStack>
                                        <StyledCurrencyFormInput disabled currency={currency == CurrencyEnum.NIS ? "₪" : "$"} name="totalWithVatShippingDiscount" label="Total" />
                                    </SpacedRowStack>
                                   {viewModel.canPay && <>  <SpacedRowStack key="3" >
                                    <FormControlLabel key="isPaid" 
                        control={<ControlledCheckbox name="paid" />}
                        label={<Typography>Is Paid</Typography>} />
                        </SpacedRowStack>{isPaid&&   <PaymentDetailsBlock  name="payment" creditCards={viewModel.creditCards} paymentTypes={viewModel.paymentTypes} companyAccounts={viewModel.companyAccounts}/>}</>}
                                </SpacedColumnStack>
                            </GlobalForm>

                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddInvoice} />
                    </>
                        : null
                    }
                </>
            }
        </GlobalModal >

    );
}

function RequestRow(props: { requestWithCanEditCost: RequestWithAmountPaid, index: number }) {
    const { watch, setValue } = useFormContext<AddInvoiceFormData>()
    const cost = watch(`requests.${props.index}.cost`)
    const costDollar = watch(`requests.${props.index}.costDollar`)
    const includeVAT = watch(`requests.${props.index}.includeVAT`)
    const exchangeRate = watch(`requests.${props.index}.exchangeRate`)
    const currency = watch(`requests.${props.index}.currency`)
    const costBeforeDiscount = watch(`requests.${props.index}.costBeforeDiscount`)
    const requestID = watch(`requests.${props.index}.requestID`)
    const requests = watch(`requests`)
    const dispatch= useDispatch();
    const modal = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const didMount = useRef(false);
    useEffect(() => {
        if (currency == CurrencyEnum.NIS) {
            setValue(`requests.${props.index}.costDollar`, cost / exchangeRate)
            if (includeVAT) {
                setValue(`requests.${props.index}.vat`, cost * .17)
                setValue(`requests.${props.index}.totalWithVat`, (cost * 1.17))
            }
            else {
                setValue(`requests.${props.index}.totalWithVat`, cost)
            }

        }


    }, [cost])
    useEffect(() => {
        if (didMount.current == true) {
            if (currency == CurrencyEnum.USD) {
                setValue(`requests.${props.index}.cost`, costDollar * exchangeRate)
                if (includeVAT) {
                    setValue(`requests.${props.index}.vat`, costDollar * .17)
                    setValue(`requests.${props.index}.totalWithVat`, (costDollar * 1.17))
                }
                else {
                    setValue(`requests.${props.index}.totalWithVat`, costDollar)
                }
            }
        }
        else {

            didMount.current = true;
            setValue(`requests.${props.index}.costDollar`, cost / exchangeRate)
            setValue(`requests.${props.index}.costBeforeDiscount`, cost / exchangeRate)
        }

    }, [costDollar])

    useEffect(() => {
        if (currency == CurrencyEnum.NIS) {

            setValue(`requests.${props.index}.costBeforeDiscount`, cost)
        }
        else {
            setValue(`requests.${props.index}.costBeforeDiscount`, cost / exchangeRate)
        }

    }, [])

    function onChangeCost(e: any) {
        setValue("discountPercent", 0)
        setValue("discountAmount", 0)
        if (currency == CurrencyEnum.NIS) {
            setValue(`requests.${props.index}.cost`, e.target.value)
        }
        else {
            setValue(`requests.${props.index}.costDollar`, e.target.value)
        }

        setValue(`requests.${props.index}.costBeforeDiscount`, e.target.value)

    }

    return (
        <ModalItemTableRow key={props.requestWithCanEditCost.r.requestID} >
            <ModalItemTableCell key="1" width={10}>
                <StyledTableImage width={4.7} src={props.requestWithCanEditCost.r.product.productSubcategory.imageURL} />
            </ModalItemTableCell>
            <ModalItemTableCell key="2" width={25}>
                <Typography>{props.requestWithCanEditCost.r.product.productName}</Typography>
            </ModalItemTableCell>
            <ModalItemTableCell key="3" width={15}>
                <Stack>
                    <Typography>{`${props.requestWithCanEditCost.r.unit} ${props.requestWithCanEditCost.r.product.unitType.unitTypeDescription}`}</Typography>
                    {props.requestWithCanEditCost.r.product.subUnit && <Typography>{`${props.requestWithCanEditCost.r.product.subUnit ?? ""} ${props.requestWithCanEditCost.r.product.subUnitType?.unitTypeDescription ?? ""}`}</Typography>}
                    {props.requestWithCanEditCost.r.product.subSubUnit && <Typography>{`${props.requestWithCanEditCost.r.product.subSubUnit ?? ""} ${props.requestWithCanEditCost.r.product.subSubUnitType?.unitTypeDescription ?? ""}`}</Typography>}
                </Stack>
            </ModalItemTableCell>
            <ModalItemTableCell key="4"  width={15}>
            <Stack direction={"row"} alignItems="center" spacing="0.5rem" marginTop={"-1rem"}><Typography  marginTop={"1.3rem"}>T:</Typography>
                {currency == CurrencyEnum.NIS ?
                    <StyledCurrencyFormInput disabled={props.requestWithCanEditCost.amountPaid>0} onChange={onChangeCost} name={`requests.${props.index}.cost`} currency={"₪"} />

                    : <StyledCurrencyFormInput disabled={props.requestWithCanEditCost.amountPaid>0} onChange={onChangeCost} name={`requests.${props.index}.costDollar`} currency={"$"} />}
            </Stack>
            </ModalItemTableCell>
            <ModalItemTableCell key="5"  width={14}>
                <Stack direction={"row"} alignItems="center" spacing="0.5rem" marginTop={"-1rem"}><Typography  marginTop={"1.3rem"}>V:</Typography><StyledCurrencyFormInput disabled name={`requests.${props.index}.vat`} currency={currency == CurrencyEnum.NIS ? "₪" : "$"} /></Stack>
            </ModalItemTableCell>
            <ModalItemTableCell key="6"  width={15}>
            <Stack direction={"row"} alignItems="center" spacing="0.5rem" marginTop={"-1rem"}><Typography  marginTop={"1.3rem"}>P:</Typography><StyledCurrencyFormInput disabled name={`requests.${props.index}.totalWithVat`} currency={currency == CurrencyEnum.NIS ? "₪" : "$"} /></Stack>
            </ModalItemTableCell>

            <ModalItemTableCell key="7" width={5}>
             {requests.length>1&&   <Button  onClick={(e:any)=>{
                    dispatch(setModal({...modal, modalInfo:{...modal.modalInfo, ids:modal.modalInfo.ids.filter(i=>i!=requestID.toString())}} ))
                    }}>   <SvgIcon name={SvgNames.Delete} height={2} className={"icon-black"} /></Button>}
            </ModalItemTableCell>
        </ModalItemTableRow>
    )
}

