import { MenuItemEnum, PageTypeEnum, SidebarEnum } from "../Constants/app-enums";
import { SUMMARY_HOURS } from "../Constants/Routes";
import { NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { MenuItem } from "./section-menus";
import * as SidebarConsts from './sidebar-menus';

export const requestsRequests: MenuItem = {
    description: "Requests",
    href: "",
    pageTypeEnum: PageTypeEnum.RequestRequest,
    childMenuItems: [SidebarConsts.requestRequestLastItem, SidebarConsts.requestRequestAddItem, /*SidebarConsts.requestRequestType, SidebarConsts.requestRequestVendors, SidebarConsts.requestRequestOwner,*/ SidebarConsts.requestRequestSearch]
}

export const requestsInventory: MenuItem = {
    description: "Items",
    href: "",
    pageTypeEnum: PageTypeEnum.RequestInventory,
    childMenuItems: [SidebarConsts.requestInventoryLastItem, SidebarConsts.requestInventoryAddItem,/* SidebarConsts.requestInventoryType, SidebarConsts.requestInventoryVendors, SidebarConsts.requestInventoryOwner, SidebarConsts.requestInventorySearch*/]
}

export const requestsLocations: MenuItem = {
    description: "Locations",
    href: "",
    pageTypeEnum: PageTypeEnum.RequestLocation,
    childMenuItems: [SidebarConsts.requestLocationsStorageTypes, SidebarConsts.requestLocationsLocationMap, SidebarConsts.requestLocationsLabs]

}

export const requestsPersonal: MenuItem = {
    description: "Personal",
    href: "",
    pageTypeEnum: PageTypeEnum.RequestCart,
    childMenuItems: [SidebarConsts.requestCartOrders, SidebarConsts.requestCartPendingOrders, SidebarConsts.requestCartFavorite, SidebarConsts.requestCartShared, SidebarConsts.requestCartNotifications, SidebarConsts.requestCartDidntArrive/* SidebarConsts.requestCartMyLists, SidebarConsts.requestCartSharedLists*/]
}

export const protocolsWorkflow: MenuItem = {
    description: "Workflow",
    href: "",
    pageTypeEnum: PageTypeEnum.ProtocolsWorkflow,
}

export const protocolsProtocols: MenuItem = {
    description: "Protocols",
    href: "",
    pageTypeEnum: PageTypeEnum.ProtocolsProtocols,
    childMenuItems: [SidebarConsts.protocolsProtocolsList, SidebarConsts.protocolsProtocolsMyProtocols, SidebarConsts.protocolsReportsFavorites, SidebarConsts.protocolsProtocolsSharedWithMe, SidebarConsts.protocolsProtocolsLastProtocol]
}

export const protocolsCreate: MenuItem = {
    description: "Create",
    href: "",
    pageTypeEnum: PageTypeEnum.ProtocolsCreate,
    childMenuItems: [SidebarConsts.protocolsCreateResearchProtocol, SidebarConsts.protocolsCreateKitProtocol, SidebarConsts.protocolsCreateSOPProtocol, SidebarConsts.protocolsCreateBufferCreating, SidebarConsts.protocolsCreateRoboticProtocol, SidebarConsts.protocolsCreateMaintenanceProtocol]
}

export const protocolsReport: MenuItem = {
    description: "Report",
    href: "",
    pageTypeEnum: PageTypeEnum.ProtocolsReports,
    childMenuItems: [SidebarConsts.protocolsReportsWeeklyReports, SidebarConsts.protocolsReportsFavorites]
}

export const protocolsResources: MenuItem = {
    description: "Resources",
    href: "",
    pageTypeEnum: PageTypeEnum.ProtocolsResources,
    childMenuItems: [SidebarConsts.requestInventoryLastItem, SidebarConsts.requestInventoryAddItem, SidebarConsts.protocolsResourcesLibrary, SidebarConsts.protocolsResourcesPersonal, SidebarConsts.protocolsResourcesSharedWithMe, SidebarConsts.protocolsResourcesFavorites]
}

export const operationsRequests: MenuItem = {
    description: "Requests",
    href: "",
    pageTypeEnum: PageTypeEnum.OperationsRequest,
    childMenuItems: [SidebarConsts.operationsRequestLastItem, SidebarConsts.operationsRequestAddItem, SidebarConsts.operationsRequestType, SidebarConsts.operationsRequestVendor, SidebarConsts.operationsRequestOwner]
}

export const operationsItemList: MenuItem = {
    description: "Item List",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsInventory, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.OperationsInventory,
    childMenuItems: [SidebarConsts.operationsInventoryLastItem, SidebarConsts.operationsInventoryAddItem, SidebarConsts.operationsInventoryType, SidebarConsts.operationsInventoryVendor]
}

export const operationsPersonal: MenuItem = {
    description: "Personal",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsCart, sidebarType: SidebarEnum.Cart, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.OperationsCart,
    childMenuItems: [SidebarConsts.operationsCartCart, SidebarConsts.operationsCartNotifications, SidebarConsts.operationsCartDidntArrive]
}

export const biomarkersExperiments: MenuItem = {
    description: "Experiments",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Biomarkers } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.BiomarkersExperiments,
    childMenuItems: [SidebarConsts.biomarkersExperimentsHumanTrials]
}

export const timeKeeperReports: MenuItem = {
    description: "Reports",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.TimeKeeper } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.TimeKeeperReport,
    childMenuItems: [SidebarConsts.timeKeeperReportHours, SidebarConsts.timeKeeperReportDaysOff]
}

export const timeKeeperSummary: MenuItem = {
    description: "Summary",
    href: SUMMARY_HOURS,
    navigationInfo: { sectionType: MenuItemEnum.TimeKeeper } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.TimekeeperSummary,
    childMenuItems: [SidebarConsts.timekeeperSummaryHours, SidebarConsts.timekeeperSummaryDaysOff]
}

export const labManagementSuppliers: MenuItem = {
    description: "Suppliers",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementSuppliers, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.LabManagementSuppliers,
    childMenuItems: [SidebarConsts.labManagementSuppliersAll, SidebarConsts.labManagementSuppliersNewSupplier]
}

export const labManagementLocations: MenuItem = {
    description: "Locations",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.LabManagementLocations,
    childMenuItems: [SidebarConsts.labManagementLocationsStorageTypes, SidebarConsts.labManagementLocationsLocationMap, SidebarConsts.labManagementLocationsLabs]
}

export const labManagementOrders: MenuItem = {
    description: "Requests",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.LabManagementQuotes,
    childMenuItems: [SidebarConsts.labManagementQuotesQuotes, SidebarConsts.labManagementQuotesOrders, SidebarConsts.labManagementPendingOrders]
}

export const labManagementSettings: MenuItem = {
    description: "Settings",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.LabManagementSettings,
    childMenuItems: [SidebarConsts.labManagementSettingsInventory]
}

export const accountingPayments: MenuItem = {
    description: "Payments",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.AccountingPayments,
    childMenuItems: [SidebarConsts.accountingPaymentsMonthlyPayment, SidebarConsts.accountingPaymentsPayNow, SidebarConsts.accountingPaymentsPayLater, SidebarConsts.accountingPaymentsInstallments,/* SidebarConsts.accountingPaymentsStandingOrders,*/ SidebarConsts.accountingPaymentsSpecifyPayment]
}

export const accountingNotifications: MenuItem = {
    description: "Notifications",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.AccountingNotifications, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.AccountingNotifications,
    childMenuItems: [SidebarConsts.accountingNotificationsNoInvoice, SidebarConsts.accountingNotificationsMissingPaymentDetails, SidebarConsts.accountingNotificationsDidntArrive, SidebarConsts.accountingNotificationsPartialDelivery, SidebarConsts.accountingNotificationsForClarification]
}

export const accountingGeneral: MenuItem = {
    description: "General",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.AccountingGeneral, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.AccountingGeneral,
    childMenuItems: [SidebarConsts.accountingGeneralList, SidebarConsts.accountingGeneralSearch]
}

export const accountingSuppliers: MenuItem = {
    description: "Suppliers",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.AccountingSuppliers,
    childMenuItems: [SidebarConsts.accountingSuppliersAll, SidebarConsts.accountingSuppliersNewSupplier]
}

export const accountingSettings: MenuItem = {
    description: "Settings",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.AccountingSettings,
    childMenuItems: [SidebarConsts.accountingSettingsBankAccounts, SidebarConsts.accountingSettingsCreditCards]
}

export const reportsSummary: MenuItem = {
    description: "Summary",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Reports } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.ExpensesSummary,
}

export const reportsStatistics: MenuItem = {
    description: "Statistics",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Reports } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.ExpensesStatistics,
}

export const reportsCosts: MenuItem = {
    description: "Costs",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Reports } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.ExpensesCost
}

export const usersUsers: MenuItem = {
    description: "Users",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Users } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.UsersUser,
    childMenuItems: [SidebarConsts.usersUserList, SidebarConsts.usersUserAdd]
}

export const usersEmployees: MenuItem = {
    description: "Employees",
    href: "",
    navigationInfo: { sectionType: MenuItemEnum.Users } as NavigationInfo,
    pageTypeEnum: PageTypeEnum.UsersWorkers,
    childMenuItems: [SidebarConsts.usersWorkersDetails, SidebarConsts.usersWorkersHours, SidebarConsts.usersWorkersAwaitingApproval]
}
