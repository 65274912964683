import { Box,  Stack } from "@mui/material";
import Skeleton from 'react-loading-skeleton';
import Theme from "../Utility/theme";

export default function LocationColumnSkeleton(){
    return(
    <Box position="relative" paddingBottom="1.5rem" width={"12.625rem"}>
    <Stack position="relative" height="100%" >
        <Box overflow="auto"   border={`2px solid ${Theme.palette.grey[300]}`} minHeight={"100%"} sx={{ backgroundColor: Theme.palette.grey[100] }} >
            <Stack>
                <Skeleton  height={"6.4rem"} count={7} />
            </Stack>
        </Box>
       
    </Stack>
    </Box>
    )
}