import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, array, number, object, string } from 'yup';
import { ModalName } from '../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal, NavigationInfo } from '../ReduxRelatedUtils/redux-types';
import { setLastSelectedLocation, setLastSelectedLocationReload } from '../ReduxRelatedUtils/selectedLocationReducer';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput, StyledControlledFreeSoloMultipleSelect, StyledControlledAutoCompleteInput, StyledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, AutocompleteOption, CustomError, SelectListItem } from '../Utility/general-types';
import { GlobalForm, SpacedColumnStack, SpacedRowStack, StyledHoverOutlineButton } from '../Utility/shared-components';
import { FloorRooms, RoomsBlock } from './lab-modal';
import { Lab, LocationInstance } from './locations-types';

export type AssociateRoomViewModel = {
    buildings: AutocompleteOption[]
    floors: LocationInstance[],

}
export type AssociateRoomFormData={  
    buildingID: number,
    labID :number
    floorRooms: FloorRooms[]
}



export default function AssociateRoomModal() {
    const dispatch = useDispatch();
    const [viewModel, setViewModel] = useState<AssociateRoomViewModel>({} as AssociateRoomViewModel)
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [error, setError] = useState<CustomError>(new CustomError());
    const { sidebarType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo


    const validationSchema = object<Partial<Record<keyof AssociateRoomFormData, AnySchema>>>({
        floorRooms: array().of(object<Partial<Record<keyof FloorRooms, AnySchema>>>({
            floorID:number().min(1, "Floor field is required.").required("Floor is a required field"),
            rooms: array().min(1, "One room must be selected")

        }))
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            labID: modalInfo.parentLocationID,
            buildingID: 0,
            floorRooms: [{ floorID: 0, rooms: [] }] as FloorRooms[]

        } as AssociateRoomFormData,
    }
    );
    const fieldArrayMethods = useFieldArray(
        {
            control:methods.control,
            name: "floorRooms"
        }
    );
    useEffect(() => {

        var url = "/Locations/AssociateRoomModal?parentID=" + modalInfo.parentLocationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: AssociateRoomViewModel) => {
                setViewModel(result);

                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);

    function onSubmit(data: any, e: any) {
        var url = '/Locations/AssociateRoomModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: { result: any }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: "Rooms Added", severity: "success" }))
                    
                    dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }

 

    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Associate Room"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AssociateRoom} onSubmit={onSubmit} methods={methods}>
                                <SpacedColumnStack alignItems="center">
                                    
                                {fieldArrayMethods.fields.map((f, i) => <RoomsBlock key={fieldArrayMethods.fields.length+"selectedFloors"+i} buildings={viewModel.buildings} floors={viewModel?.floors} index={i} />)}
                                    {viewModel?.floors.length > 1 && <StyledHoverOutlineButton sx={{ width: "25rem" }} onClick={() => fieldArrayMethods.append({ floorID: 0, rooms: [] })}>+ Add Rooms from a different floor</StyledHoverOutlineButton>}
                              </SpacedColumnStack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AssociateRoom} />
                    </>
                    }
                </>
            }
        </>
    )
}
