import Badge, { BadgeProps } from '@mui/material/Badge';
import Box, { BoxProps } from '@mui/material/Box';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 13,
        top: 28,
        border: `.125rem solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: `${theme.palette.error.main}`,
        color: '#ffff'
    },
}));



const drawerWidth = 119;
export const StyledDrawer = styled(Drawer)<DrawerProps>(({theme}) => ({    
        width: drawerWidth,
        flexShrink: 0,
        boxShadow: '2px 0px 6px rgba(0,0,0, .16)',
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', boxShadow: '2px 3px 3px rgba(0,0,0, .16)' } 
}));

export const StyledNavGridItem = styled(Grid)<GridProps>(({theme}) => ({    
    textAlign:'center',
    alignItems:'center',
    padding:'14px !important',
    '&:hover': {
        borderRadius: "15px",
        backgroundColor: "rgb(232,240,254)"
    },
}));

export const StyleNavBoxItem = styled(Box)<BoxProps>(({theme}) => ({    
    alignItems:"center",
    display:"flex",
    textAlign:"center",
    height:"100%",
    width:"100%",
    justifyContent:"center"
}));