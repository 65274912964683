import { Stack} from "@mui/material";
import { Box } from "@mui/system";
import { push } from "connected-react-router";
import queryString from 'query-string';
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FieldError } from "react-hook-form/dist/types";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { ModalFlow, ModalName, SidebarEnum, SvgNames } from "../Constants/app-enums";
import { CardTabsType, TabsName } from "../Constants/client-side-enums";
import { selectEditDrawerViewModelByID, selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setLoading, setPageSkeleton, setShowConfirmExit } from "../ReduxRelatedUtils/utilsReducer";
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal, ModalInfo, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { setEditDrawerViewModel } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { setViewModel } from "../ReduxRelatedUtils/viewmodel-reducer";
import AddCardForm from "../Shared/AddCardComponents/add-card";
import { AddUserViewModel, AddSupplierFormData, AddCardType, AddUserFormData } from "../Shared/AddCardComponents/add-types";
import { CardTabs, GlobalTab, Tab } from "../Shared/AddCardComponents/card-tabs";
import IndexTableSkeleton from "../Shared/IndexTable/index-table-skeleton";
import { BasicModalInfo, Comment } from "../Utility/general-types";
import { StyledFilledButton, StyledTableImage, TitleTypography } from "../Utility/shared-components";
import UserTabs, { userTabInfo } from "./user-card-tabs";
import UserHeader from "./user-header";





export function AddUserInnerForm() {
    //const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, "")?.viewModel) as AddUserViewModel;
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const showPageSkeleton  = useSelector<ApplicationState>(state=> state.showPageSkeleton)
    const [tabs, setTabs] = useState<Tab[]>(userTabInfo)

    //console.log(viewModel);
    const { control, formState, watch, getValues, reset, setValue, handleSubmit } = useFormContext<AddUserFormData>();
    const dispatch = useDispatch();
    const employeeStatusID = watch("employee.employeeStatusID")

    useEffect(() => {
        setTabs(employeeStatusID == 4 ? userTabInfo.filter(t => t.name!= TabsName.WorkScope): userTabInfo);
    }, [employeeStatusID])

    useEffect(() => {
        dispatch(setPageSkeleton(true))

        fetch("/Users/CreateUser", {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: AddUserViewModel) => {
                dispatch(setEditDrawerViewModel({viewModel:result, id:"", reloadEditModal:false}));
                //setValue("guid", v4())
                dispatch(setPageSkeleton(false))

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text.toString(), showBody: false }))
                })
            })

    }, [])

    

    return (
        <>
        {showPageSkeleton?
        <Box padding="2rem" height="75vh" overflow={"hidden"}>
        <IndexTableSkeleton/>
        </Box>
        :
        <>
            <Box sx={{ padding: "3rem" }}>
                <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <UserHeader/>
                    <StyledFilledButton className={(formState.isSubmitting || !formState.isValid) ? "disabled-section-button" : ""} type="submit" name="Save">Add User</StyledFilledButton>
                </Stack>
            </Box>
            <CardTabs key={CardTabsType.AddCard} cardTabType={CardTabsType.AddCard} selectedTab={0} tabs={tabs} >
                <UserTabs key={"usertabs"} tabs={tabs}/>
            </CardTabs>
        </>
}
        </>

    )
}

const extractValues = (obj: any): string[] => {
    const values = [];

    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object') {
            if ("message" in (value ?? {})) {
                values.push((value as FieldError).message)
            }
            else {
                values.push(...extractValues(value));
            }
        }
    }

    return values as string[];
}

export default function AddUser() {
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo

    function onSubmit(data: AddUserFormData, e: any) {
        console.log("submitting form")
        console.log(data)
        
        dispatch(setLoading(true))
        fetch("/Users/CreateUser", {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        }).then(response => {
            if (response.ok) { return response.json() }
            else { throw response.text() }
        }).then((result) => {
            dispatch(setLoading(false))
            dispatch(setGlobalSnack({ open: true, message: "User Added" }))
            dispatch(setShowConfirmExit(false))
            dispatch(push("/UsersTable?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))

        })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, severity: "error", message: text }))
                })
            })
    }

    return (
        <AddCardForm addType={AddCardType.AddUser} onSubmit={(data, e) => onSubmit(data, e)} />

    )
}



