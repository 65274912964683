import { Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { ApplicationState, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { AddUserFormData, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { StyledControlledFormInput, StyledPercentFormInput } from "../Utility/custom-input-components";
import { SpacedColumnStack } from "../Utility/shared-components";

export default function WorkScopeTab() {
    const dispatch = useDispatch()
    const { watch, setValue, control, formState: { errors } } = useFormContext<AddUserFormData>();
    const { sidebarType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo

    function handleWorkScopeChange(e:any){
        console.log(e.target.value)
        setValue("employee.salariedEmployee.workScope", e.target.value)
        setValue("employee.salariedEmployee.hoursPerDay", (Number(e.target.value)/100)*8.4)
    }

    function handleHoursPerDayChange(e:any){
        console.log(e.target.value)
        setValue("employee.salariedEmployee.hoursPerDay", e.target.value)
        setValue("employee.salariedEmployee.workScope", (e.target.value/8.4*100))
    }
    return (
        <SpacedColumnStack paddingBottom={"2rem"}>

            <Grid container spacing={2}>
            <Grid item xs={6}>
                    <StyledPercentFormInput onChange={handleWorkScopeChange}  name="employee.salariedEmployee.workScope" label="Employee Work Scope" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput  onChange={handleHoursPerDayChange}  type="number" name="employee.salariedEmployee.hoursPerDay" label="Hours Per Day" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput type="number" name="employee.vacationDays" label="Vacation Days" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput type="number" name="employee.rollOverVacationDays" label="Roll Over Vacation Days" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput type="number" name="employee.rollOverSickDays" label="Roll Over Sick Days" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput type="number" name="employee.specialDays" label="Special Days" />
                </Grid>
            </Grid>
        </SpacedColumnStack>
    )
}