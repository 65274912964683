import { yupResolver } from "@hookform/resolvers/yup";
import { Box, DialogActions, DialogTitle, Divider, FormControlLabel, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import moment from "moment";
import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { AnySchema, boolean, date, number, object, string } from "yup";
import { CurrencyEnum, FolderNamesEnum, MenuItemEnum, ModalFlow, ModalName, SvgNames } from "../../Constants/app-enums";
import { ModalSizes } from "../../Constants/client-side-enums";
import { selectModalLoading, selectModalPresent, selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../../ReduxRelatedUtils/index-table-reducer";
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { removeModal, setModal, setModalLoading } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../../ReduxRelatedUtils/redux-types";
import { setReloadEditModal } from "../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import DocumentsCard from "../../Shared/documents-card";
import { ControlledCheckbox, StyledControlledFormDateInput, StyledControlledFormInput, StyledCurrencyFormInput } from "../../Utility/custom-input-components";
import { BasicModalInfo, CustomError, DocInfoModalInfo } from "../../Utility/general-types";
import { CompanyAccount, CreditCard, Invoice, Payment, PaymentType } from "../../Utility/request-types";
import { basicModalPost, getParentFolderName } from "../../Utility/root-function";
import { AddInvoiceBlock, GlobalForm, IsEdittableContext, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack, StyledError, StyledFilledButton, StyledNoOutlineButton, SvgIcon } from "../../Utility/shared-components";
import DocumentsModal from "./documents-modal";
import GlobalModal from "./global-modal";
import { ModalFooterWithCustomSave, ModalFooterWithSave, ModalHeader, StyledDialogContent } from "./modal-components";
import ModalSkeleton from "./modal-skeleton";
import { setLoading as setGlobalLoading } from '../../ReduxRelatedUtils/utilsReducer'

export type DocInfoModalFormData = {
    folderName: FolderNamesEnum;
    guid: string,
    infoID: number,
    infoNumber: string,
    infoDate: string,
    saveConfirmed: boolean,
    associatedMembersCount: number,
    hasFile: boolean
}
export type DocInfoModalViewModel = {
    fileStrings: string[];
    infoNumber: string;
    infoDate: string;
    associatedMembersCount: number
}

const validationSchema = object<Partial<Record<keyof DocInfoModalFormData, AnySchema>>>({
    hasFile: boolean().isTrue("File is required"),
    infoNumber: string().typeError("Info Number is required").required("Info Number is required"),
    infoDate: date().typeError("Info Date must be a vaild date").required("Info Date is required").max(moment().endOf("day"), "Info date cannot be in the future"),

})


export function DocInfoModal() {
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<DocInfoModalInfo>;

    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const [confirmSaveIsOpen, setConfirmSaveIsOpen] = useState<boolean>(false);
    const methods = useForm<DocInfoModalFormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            guid: v4(),
            infoDate: moment().format("YYYY-MM-DD"),
            infoNumber: "",
            infoID: modalInfo.infoID,
            folderName: modalInfo.folderName,
            saveConfirmed: false
        }
    });
    const dispatch = useDispatch()
    const [fileCount, setFileCount] = useState<number>(0)
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState<DocInfoModalViewModel>({} as DocInfoModalViewModel);


    useEffect(() => {
        var url = "/Requests/DocInfoModal?infoID=" + modalInfo.infoID + "&folderName=" + modalInfo.folderName;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: DocInfoModalViewModel) => {
                dispatch(setModalLoading(false))
                dispatch(setGlobalLoading(false))
                setViewModel(result);
                setFileCount(result.fileStrings?.length)
                methods.setValue("hasFile", result?.fileStrings?.length > 0 ? true : false)
                methods.setValue("infoDate", moment(result.infoDate).format("YYYY-MM-DD"))
                methods.setValue("infoNumber", result.infoNumber)
                methods.setValue("associatedMembersCount", result.associatedMembersCount)
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, []);


    function onSubmit(data: DocInfoModalFormData, e: any) {
        setConfirmSaveIsOpen(true)
    }

    return (


        <GlobalModal size={ModalSizes.md} modalKey={ModalName.DocModalInfo} hideBackdrop={false}>
            {loading ? <ModalSkeleton /> :
                <GlobalForm formID={ModalName.DocModalInfo} onSubmit={(data: any, e: any) => onSubmit(data, e)} methods={methods} isEdittable={false}>
                    <DocInfoInnerForm viewModel={viewModel} setConfirmSaveIsOpen={setConfirmSaveIsOpen} confirmSaveIsOpen={confirmSaveIsOpen} setFileCount={setFileCount} setViewModel={setViewModel} fileCount={fileCount} />
                </GlobalForm>


            }
        </GlobalModal>

    );
}

function DocInfoInnerForm(props: {
    setConfirmSaveIsOpen: Dispatch<SetStateAction<boolean>>,
    confirmSaveIsOpen: boolean, setFileCount: Dispatch<SetStateAction<number>>, fileCount: number, viewModel: DocInfoModalViewModel, setViewModel: Dispatch<SetStateAction<DocInfoModalViewModel>>
}) {
    const methods = useFormContext();
    const isEdittableContext = useContext(IsEdittableContext);
    const [docsModalIsOpen, setDocsModalIsOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<DocInfoModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const sectionType = useSelector<ApplicationState>(state => selectNavigationInfo(state).sectionType) as MenuItemEnum

    const guid = methods.watch("guid");
    const saveConfirmed = methods.watch("saveConfirmed");
    const infoNumber = methods.watch("infoNumber");
    const infoDate = methods.watch("infoDate");

    const didMount = useRef(false);

    const [moveFiles, setMoveFiles] = useState(false)
    const docModalDidMount = useRef(false);
    const [parentFolderName, setParentFolderName] = useState(getParentFolderName(modalInfo.folderName, sectionType))

    useEffect(() => {
        if (docModalDidMount.current) {
            console.log("filecount " + props.fileCount)
            let urlbeginning = "/Requests/"
            if (sectionType === "Protocols") {
                urlbeginning = "/Protocols/"
            }
            else if (sectionType == "Biomarkers") {
                urlbeginning = "/Biomarkers/"
            }
            var url = urlbeginning + "DocumentsModal?id=" + (isEdittableContext.isEdittable ? guid : modalInfo.infoID.toString()) + "&folderName=" + modalInfo.folderName;
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: string[]) => {
                    props.setViewModel({ ...props.viewModel, fileStrings: result });
                    methods.setValue("hasFile", result?.length > 0 ? true : false)
                    dispatch(setGlobalLoading(false))
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setError({ message: text.toString(), showBody: false } as CustomError))

                    })
                })
        }
        else {
            docModalDidMount.current = true;
        }

    }, [props.fileCount]);

    useEffect(() => {
        if (saveConfirmed) {
            var url = '/Requests/DocInfoModal'
            dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(methods.watch()),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then((result: string) => {
                    props.setConfirmSaveIsOpen(false);
                    methods.setValue("saveConfirmed", false)
                    isEdittableContext.toggleIsEdittable(false)
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(setReloadEditModal({ reloadEditModal: true, id: modalInfo.editDrawerID }))
                        dispatch(setGlobalSnack({ open: true, message: "Changes Saved", severity: "success" }))
                        dispatch(setReloadIndex(true))

                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                        methods.setValue("saveConfirmed", false)
                    })
                })

        }


    }, [saveConfirmed])
    useEffect(() => {

        if (didMount.current && moveFiles) {

            var url = "/Requests/MoveDocInfoToGuid?infoID=" + modalInfo.infoID + "&folderName=" + modalInfo.folderName + "&guid=" + guid + "&deleteGuid=" + !isEdittableContext.isEdittable + "&infoNumber=" + infoNumber + "&infoDate=" + infoDate;
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: DocInfoModalViewModel) => {

                    props.setViewModel(result);
                    props.setFileCount(result.fileStrings.length)
                    setMoveFiles(false)
                    methods.setValue("infoDate", moment(result.infoDate).format("YYYY-MM-DD"))
                    methods.setValue("infoNumber", result.infoNumber)

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        console.log(text)
                        setError({ message: text.errorMessage, showBody: false })
                        dispatch(setModalLoading(false))
                    })
                })
        }
        else {
            didMount.current = true
        }

    }, [moveFiles]);


    function discardChanges() {
        //      console.log("in discard changes")
        setMoveFiles(true)
        dispatch(setGlobalSnack({ open: true, message: "Discarded Item Changes" }))
        isEdittableContext.toggleIsEdittable(false)

    }
    function setEditable() {
        setMoveFiles(true)
        isEdittableContext.toggleIsEdittable(true)
    }
    return (<>
        <SpacedRowStack alignItems={"center"}><DialogTitle width="fit-content">{parentFolderName + " Details"}</DialogTitle>


            {!isEdittableContext.isEdittable && <StyledNoOutlineButton endIcon={<SvgIcon name={SvgNames.Create} className="section-fill" height={1.5} />} onClick={setEditable}>
                Edit Item
            </StyledNoOutlineButton>
            }

            {isEdittableContext.isEdittable &&
                <>
                    <StyledNoOutlineButton onClick={discardChanges}>Discard Edits</StyledNoOutlineButton>
                    <StyledFilledButton form={ModalName.DocModalInfo} type="submit">Save Edits</StyledFilledButton>
                </>
            }
        </SpacedRowStack>
        <Divider sx={{ margin: "0rem 1.5rem" }} />
        {error.message && <StyledError>{error.message}</StyledError>}

        {error.showBody && <>
            <StyledDialogContent>
                <SpacedColumnStack>
                    <SpacedRowStack>
                        <StyledControlledFormInput label={`${parentFolderName} Number`} name="infoNumber" />
                        <StyledControlledFormDateInput label={`${parentFolderName} Date`} name="infoDate" />

                        <Box sx={{ width:"100%", borderRadius: "0.3rem", marginTop: '1.3rem !important', border: (theme) => `.1rem solid ${theme.palette.grey[300]}` }} width="100%" >
                            <Button sx={{ width: "100%", height: "100%" }} onClick={() => {
                                setDocsModalIsOpen(true);
                            }}>
                                <Tooltip title={props.viewModel.fileStrings?.map(f => f.split('\\').pop())?.join(", ")} arrow>
                                <Typography sx={{
                                    whiteSpace: 'nowrap', textAlign:"left",
                                    textOverflow: 'ellipsis', overflow: 'hidden', width: '15rem', padding: "0.3rem 0.8rem"
                                }}>   {props.viewModel.fileStrings?.map(f => f.split('\\').pop())?.join(", ")}</Typography>
                                </Tooltip>
                            </Button>
                        </Box>
                        {methods.formState.errors["hasFile"] && <StyledError>{methods.formState.errors["hasFile"]?.message}</StyledError>}
                    </SpacedRowStack>
                </SpacedColumnStack>

            </StyledDialogContent>
        </>}
        {props.confirmSaveIsOpen && <ConfirmInfoDocChange setConfirmModalOpen={props.setConfirmSaveIsOpen} />}
        {docsModalIsOpen && <DocumentsModal {...{ setFileCount: props.setFileCount, fileStrings: props.viewModel?.fileStrings ?? [], setDocsModalIsOpen: setDocsModalIsOpen, docsModalIsOpen: docsModalIsOpen, isEdittable: isEdittableContext.isEdittable }} folderID={isEdittableContext.isEdittable ? guid : modalInfo.infoID.toString()} folderName={modalInfo.folderName} allowMultipleFiles={true} hideBackDrop={true} />}
    </>
    )
}

function ConfirmInfoDocChange(props: { setConfirmModalOpen: Dispatch<SetStateAction<boolean>> }) {
    const methods = useFormContext<DocInfoModalFormData>();
    const associatedMembersCount = methods.watch("associatedMembersCount");

    return (
        <GlobalModal size={ModalSizes.xs} modalKey={ModalName.ConfirmDocInfoChange} hideBackdrop={true} closeClick={() => { props.setConfirmModalOpen(false) }}>

            <ModalHeader headerText="Edit Document Details Item" errorMessage="" />

            <StyledDialogContent>
                <Typography>{`The details you are trying to edit are associated with ${associatedMembersCount} more items.
                             Editing them will change the details in all of the associated items.
                              How would you like to to proceed?`}</Typography>

            </StyledDialogContent>
            <DialogActions sx={{ paddingX: "2rem" }}>
                <Stack direction={'row-reverse'} width="100%" justifyContent={"space-between"} alignItems="center">
                    <ModalFooterWithCustomSave closeClick={() => { props.setConfirmModalOpen(false) }}>
                        <StyledFilledButton type="button" onClick={() => { methods.setValue("saveConfirmed", true) }}>Save Edits</StyledFilledButton>
                    </ModalFooterWithCustomSave>
                </Stack>
            </DialogActions>


        </GlobalModal>

    )
}