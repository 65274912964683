import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete } from "@mui/lab";
import { FormControlLabel, Grid, Typography } from "@mui/material";
import Box from "@mui/system/Box";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import undoable from "redux-undo";
import { v4 } from "uuid";
import { AnySchema, array, boolean, date, number, object, string } from "yup";
import { CurrencyEnum, FolderNamesEnum, InfoPopupName, InstallmentDivisionType, ModalName, SetPaymentDatesType } from "../../Constants/app-enums";
import { ModalSizes } from "../../Constants/client-side-enums";
import { selectModalLoading, selectModalPresent } from "../../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../../ReduxRelatedUtils/index-table-reducer";
import { setInfoPopup } from "../../ReduxRelatedUtils/infoPopupReducer";
import { setLoading as setGlobalLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { removeModal, setModalLoading } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, InfoPopup } from "../../ReduxRelatedUtils/redux-types";
import { setReloadEditModal } from "../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";

import { ControlledCheckbox, StyledControlledAutoCompleteInput, StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledRadioButton, StyledControlledRadioGroup, StyledCurrencyFormInput, StyledFormInput, StyledSectionAutoCompleteInput } from "../../Utility/custom-input-components";
import {  ConfigureInstallmentsModalInfo, CustomError } from "../../Utility/general-types";
import { CompanyAccount, CreditCard, Invoice, Payment, PaymentType, Request } from "../../Utility/request-types";
import { basicModalPost, getDisplayNameOfEnumValue } from "../../Utility/root-function";
import { AddInvoiceBlock, GlobalForm, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack, StyledError, StyledFilteredButton, TitleTypography } from "../../Utility/shared-components";
import GlobalModal from "./global-modal";
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from "./modal-components";
import ModalSkeleton from "./modal-skeleton";
import { TermsFormData } from "./terms-modal";
import { extractErrorValues } from '../../Utility/root-function';
import { propsToClassKey } from "@mui/styles";
import viewmodelReducer from "../../ReduxRelatedUtils/viewmodel-reducer";
export type ConfigureInstallmentsModalFormData = {
    isSetPayment: boolean,
    payments: Payment[]
    installments: number,
    setPaymentDateType:SetPaymentDatesType,
    currentBalance:number,
    fullCost: number,
    requestID:number,
}
export type ConfigureInstallmentsModalViewModel = {
    request: Request,
    payments: Payment[],
    installments: number,
    divisionType: InstallmentDivisionType,
    setPaymentDateType: SetPaymentDatesType
    fullCost: number,

}

const validationSchema = object<Partial<Record<keyof ConfigureInstallmentsModalFormData, AnySchema>>>({


    installments: number().typeError("Installments must be a number").min(1, "Installments must be greated than 1"),
    payments: array().of(
        object<Partial<Record<keyof Payment, AnySchema>>>({
        paymentDate: date().typeError("Payment date must be a valid date").required("Payment date is required"),
        sum: number().typeError("Sum must be a number").required("Sum is a required field").min(1,"Sum must be greated than one")
     })
    ).test("payment sums must add up to current balance", "The Installments Must Add Up To The Item's Total Cost", function (this) {
        console.log(+this.parent.payments.map((p:Payment)=>p.sum).reduce((a:number, b:number) => +a + +b,0))
        return this.parent.payments.map((p:Payment)=>{
            return p.sum}).reduce((a:number, b:number) => Number(a) + Number(b),0).toFixed(2)==this.parent.fullCost.toFixed(2);     
    }),
    setPaymentDateType: string().required("Set Payment Date is a required field").test("set payment type not none", "Set Payment Date is a required field", function (this) {
        return (String(this.parent.isSetPayment)=="true" && this.parent.setPaymentDateType!=SetPaymentDatesType.None) ||String(this.parent.isSetPayment)=="false";     
    }),
})

export function ConfigureInstallmentsModal() {
    const dispatch = useDispatch();
    const modalInfo = useSelector<ApplicationState>(state => state.infoPopup) as ConfigureInstallmentsModalInfo
    const [loading, setLoading] = useState(Number(modalInfo.id)!= 0)
    const [error, setError] = useState<CustomError>(new CustomError());
    const methods = useForm<ConfigureInstallmentsModalFormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            isSetPayment:modalInfo.comingFromTerms? (modalInfo.viewModel.divisionType==InstallmentDivisionType.SetPayments):true,
            payments:modalInfo.comingFromTerms? modalInfo.viewModel.payments:[],
            installments:modalInfo.comingFromTerms? modalInfo.viewModel.installments:1,
            currentBalance:0,
            fullCost: modalInfo.comingFromTerms? modalInfo.viewModel?.fullCost:0,
            requestID: Number(modalInfo.id),
            setPaymentDateType:modalInfo.comingFromTerms? modalInfo.viewModel.setPaymentDateType: SetPaymentDatesType.SetDate
        }
    });
    console.log(methods.watch())
    const [viewModel, setViewModel] = useState<ConfigureInstallmentsModalViewModel>({} as ConfigureInstallmentsModalViewModel)
    const fieldArrayMethods = useFieldArray(
        {
            control:methods.control,
            name: "payments"
        }
    );
    const isSetPayment = methods.watch("isSetPayment")
    const installments = methods.watch("installments")    
    const payments = methods.watch("payments")
    const currentBalance = methods.watch("currentBalance")
    const firstPaymentDate = methods.watch("payments.0.paymentDate")
    const viewModelDidMount = useRef(false)
    const [disableSetPayments, setDisableSetPayments] = useState(false);

    useEffect(() => {
        if (!modalInfo.comingFromTerms) {
            var url = "/Requests/ConfigureInstallmentsModal?requestID=" + modalInfo.id;
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: ConfigureInstallmentsModalViewModel) => {
                    setLoading(false)
                    result.payments.forEach(p => {p.sumDollar =p.sum/result.request.exchangeRate});
                    setViewModel(result);              
                    methods.setValue("isSetPayment", result.divisionType== InstallmentDivisionType.SetPayments)
                    methods.setValue("payments", result.payments);
                    methods.setValue("installments", result.request.installments)
                    methods.setValue("fullCost", result.fullCost)
               
  
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        console.log(text)
                        setError({ message: text.errorMessage, showBody: false })
                        dispatch(setModalLoading(false))
                    })
                })

        }
        else{
            setViewModel(modalInfo.viewModel??{} as ConfigureInstallmentsModalViewModel)       
            setLoading(false)
        }

    }, []);

    useEffect(() => {
        console.log("change viewmodel")
      if(viewModelDidMount.current){
        setCurrentBalanceState();
        decideWhetherToDisableSetPayments()

      }
      else{
        viewModelDidMount.current=true;
      }

    }, [viewModel]);


    const installmentsDidMount = useRef(false)
    useEffect(() => {
        console.log("console log 1")
        if(installmentsDidMount.current){
        let paymentsCopy =JSON.parse(JSON.stringify(payments))  as Payment[];
    
        const paidPayments = paymentsCopy.filter(p=>p.isPaid)
        let lastPaidInstallmentNumber = paidPayments?.[paidPayments.length-1]?.installmentNumber??0
        console.log("console log 2")
        if (installments < lastPaidInstallmentNumber ) {
            return;
        }
        if(installments<1){
            methods.setValue("installments", 1)
            return;
        }
        if (String(isSetPayment)=="true") {
    
            let newSum = +((viewModel.fullCost / installments));
            for (let i = 0; i <= installments; i++) {
                (paymentsCopy[i] == undefined) ?
                    paymentsCopy.push( {
                        sum: newSum,
                        sumDollar:newSum/viewModel.request.exchangeRate,
                        paymentDate: moment(paymentsCopy[i - 1].paymentDate).add(1, 'M').format("YYYY-MM-DD"),
                        installmentNumber: i + 1,
                    }  as Payment ):
                    paymentsCopy[i]= { ...paymentsCopy[i], sum: newSum }
            }
        } else {
            let currentPaymentsLength = paymentsCopy.length;
            if (installments > paymentsCopy.length) {
                for (let i = +currentPaymentsLength; i < installments; i++) {
                    paymentsCopy.push({
                        sum: 0,
                        sumDollar:0,
                        paymentDate: moment(paymentsCopy[i - 1]?.paymentDate).add(1, 'M').format("YYYY-MM-DD"),
                        installmentNumber: i + 1
                    }as Payment)
                }
                
            }
        }
        console.log("console log 3")
        var paymentSlice =paymentsCopy.slice(0, installments);
        console.log(paymentSlice)
        methods.setValue(`payments`, paymentSlice)

        }
        else{
            installmentsDidMount.current=true;
        }
    }, [installments]);

    const setPaymentDidMount = useRef(false)
    useEffect(() => {
        if(setPaymentDidMount.current){
        let paymentsCopy =JSON.parse(JSON.stringify(payments))  as Payment[];
        if (String(isSetPayment)=="true") {
            let newSum=0;
            if (viewModel.request.currency == CurrencyEnum.NIS) {
                newSum = +((viewModel.fullCost / installments));
            }
            else {
                let dollarFullCost = + ((viewModel.fullCost / viewModel.request.exchangeRate).toFixed(2))
                let newDollarSum = +((dollarFullCost / installments))
                newSum = newDollarSum * viewModel.request.exchangeRate;
            }

            paymentsCopy.forEach((p, i) => {
                p.sum = newSum;
                if (i != 0) {
                    p.paymentDate = moment(payments[0].paymentDate).add(i, 'M').format("YYYY-MM-DD")
                }
            });
            methods.setValue("payments", paymentsCopy)
            if(!modalInfo.comingFromTerms){
                methods.setValue("setPaymentDateType", SetPaymentDatesType.SetDate)
            }
        }
        else{
            methods.setValue("setPaymentDateType", SetPaymentDatesType.None)
        }

        }
        else{
            setPaymentDidMount.current=true;
        }
    }, [isSetPayment]);

    useEffect(() => {
        if (String(isSetPayment)=="true") {
            let paymentsCopy =JSON.parse(JSON.stringify(payments))  as Payment[];
            paymentsCopy.forEach((p, i) => {
                if (i != 0) {
                    p.paymentDate = moment(payments[0].paymentDate).add(i, 'M').format("YYYY-MM-DD")
                }
            });
            methods.setValue("payments", paymentsCopy)
        }

    }, [firstPaymentDate]);

  function decideWhetherToDisableSetPayments(){        
        let canChooseSetPayments = false;
        if (viewModel.divisionType == InstallmentDivisionType.SetPayments) {
            canChooseSetPayments = true;
        } else {
            // check if all paid sums are equal to cost/installments and payment date is correct amt of months after first payment date
            let equalSum = viewModel.fullCost / viewModel.request.installments;
            let firstDate = moment(viewModel.payments[0].paymentDate);
            canChooseSetPayments = viewModel.payments.filter(p => p.isPaid)
                .every(p => p.sum == equalSum && moment(p.paymentDate).isSame(firstDate.add(p.installmentNumber - 1, 'M'), 'day'));
        }
        setDisableSetPayments(!canChooseSetPayments)
        if(!canChooseSetPayments)
            methods.setValue("isSetPayment", false)
        }



    function setCurrentBalanceState() {
        
        let paidSum = viewModel.payments.filter(p => p.isPaid).reduce((sum, payment) => sum + payment.sum, 0);
        let balanceShekel = viewModel.fullCost - paidSum;
        let balance = viewModel.request?.currency == CurrencyEnum.USD ? balanceShekel / viewModel.request.exchangeRate : balanceShekel;
        methods.setValue("currentBalance", Number(balance.toFixed(2)));
    }

    function onSubmit(data: ConfigureInstallmentsModalFormData) {
        if (!modalInfo.comingFromTerms) {
            var url = '/Requests/ConfigureInstallmentsModal'
            dispatch(setGlobalLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then((result: string) => {
                    batch(() => {
                        dispatch(setInfoPopup({} as InfoPopup))
                        dispatch(setGlobalLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: result, severity: "success" }))
                        dispatch(setReloadIndex(true))
                        dispatch(setReloadEditModal({ reloadEditModal: true, id: modalInfo.id }))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
        }
        else{
           modalInfo.saveConfigureInstallments(data);
           batch(() => {
            dispatch(setInfoPopup({} as InfoPopup))
        })
        }
     

    }

    return (
        <GlobalModal size={ModalSizes.md} modalKey={InfoPopupName.ConfigureInstallments} closeClick={() => dispatch(setInfoPopup({} as InfoPopup))} >
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Reconfigure Installments" errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={InfoPopupName.ConfigureInstallments} onSubmit={onSubmit} methods={methods}>
                                <Typography variant="h6" className="section-color" sx={{paddingBottom:"2rem"}}>{`Current Balance ${viewModel?.request?.currency == CurrencyEnum.USD ? '$' : '₪'}${currentBalance}`}</Typography>
                                <SpacedColumnStack>
                                    <SpacedRowStack>
                                        <StyledControlledFormInput type="number" name="installments" label="Installments"   disabled={currentBalance==0} sx={{width:"25%"}} />
                                        <StyledControlledRadioGroup name="isSetPayment">
                                            <FormControlLabel  sx={{ margin: "0" }} key={"setPayments"} value={true} disabled={disableSetPayments || currentBalance==0}
                                                control={<StyledControlledRadioButton />}
                                                label={<Typography>Set Payments</Typography>} />
                                            <FormControlLabel sx={{ margin: "0" }} key={"variedPayment"} value={false} disabled={currentBalance==0} 
                                                control={<StyledControlledRadioButton />}
                                                label={<Typography>Varied Payments</Typography>} />
                                        </StyledControlledRadioGroup>
                                    </SpacedRowStack>
                                    <SpacedColumnStack>
                                        { String(isSetPayment)=="true" &&   
                                                  <SetPaymentRow key={fieldArrayMethods.fields.length+"payment"+fieldArrayMethods.fields?.[0]?.sum} payment={fieldArrayMethods.fields?.[0]} comingFromTerms={modalInfo.comingFromTerms} exchangeRate={viewModel?.request?.exchangeRate} currency={viewModel?.request?.currency}/>
                                       }
                                       { String(isSetPayment)=="false"  &&
                                         fieldArrayMethods.fields?.map((p,i)=>{ return(
                                            <SpacedRowStack key={i+"payment"} alignItems="baseline">
                                            <PaymentRow key={i+"payment"+p.sum+p.sumDollar} index={i} exchangeRate={viewModel?.request?.exchangeRate} currency={viewModel?.request?.currency} payment={p}/>
                                        </SpacedRowStack>
                                        )})
                                      
                                       }
                                    </SpacedColumnStack>
                                    {methods.formState.errors.payments?.message  &&
                    <StyledError>{methods.formState.errors.payments.message}</StyledError>
                    }
                                </SpacedColumnStack>

                            </GlobalForm>


                        </StyledDialogContent>
                        <ModalFooterWithSave formID={InfoPopupName.ConfigureInstallments} closeClick={() => dispatch(setInfoPopup({} as InfoPopup))} />
                    </>}
                </>
            }
        </GlobalModal>

    );
}

function PaymentRow(props:{index:number, exchangeRate:number, currency:CurrencyEnum, payment:Payment}){

    const methods = useFormContext<ConfigureInstallmentsModalFormData>();
    const sum = methods.watch(`payments.${props.index}.sum`)
    const sumDollar = methods.watch(`payments.${props.index}.sumDollar`)

    useEffect(()=>{

        if(props.currency == CurrencyEnum.NIS){
            methods.setValue(`payments.${props.index}.sumDollar`, +((sum/props.exchangeRate).toFixed(2)))
        }

    },[sum])

    useEffect(()=>{

        if(props.currency == CurrencyEnum.USD){
            
            methods.setValue(`payments.${props.index}.sum`, (sumDollar*props.exchangeRate))
        }
    },[sumDollar])


    return(<>
        <Typography sx={{color:(theme)=>theme.palette.grey[400], width:"50%"}}>{`Installment ${props.index+1}`}</Typography>
        <StyledCurrencyFormInput disabled={(props.currency==CurrencyEnum.NIS || props.payment.isPaid)} currency="$" label="Sum"  name={`payments.${props.index}.sumDollar`} />                                        
        <StyledCurrencyFormInput disabled={props.currency==CurrencyEnum.USD ||  props.payment.isPaid} currency="₪"  name={`payments.${props.index}.sum`} />
        <StyledControlledFormDateInput disabled={props.payment.isPaid} name={`payments.${props.index}.paymentDate` }label="Payment Date" />  
        </>
    )
 
}


function SetPaymentRow(props:{ exchangeRate:number, currency:CurrencyEnum, comingFromTerms:boolean, payment:Payment}){

    const methods = useFormContext<ConfigureInstallmentsModalFormData>();
    const sum = methods.watch(`payments.0.sum`)
    const sumDollar = methods.watch(`payments.0.sumDollar`)

    const setPaymentDateType = methods.watch("setPaymentDateType")
    useEffect(()=>{

        if(props.currency == CurrencyEnum.NIS){
            methods.setValue(`payments.0.sumDollar`, +((sum/props.exchangeRate).toFixed(2)))
        }

    },[sum])

    useEffect(()=>{

        if(props.currency == CurrencyEnum.USD){
            methods.setValue(`payments.0.sum`, (sumDollar*props.exchangeRate))
        }
    },[sumDollar])


    return(
        <SpacedRowStack key={"payment"} >
     <Typography sx={{color:(theme)=>theme.palette.grey[400], marginTop:"1rem"}}>Installments</Typography>
    <StyledCurrencyFormInput disabled currency="$" label="Sum"  name="payments.0.sumDollar" />                                        
    <StyledCurrencyFormInput disabled currency="₪" name="payments.0.sum" />
  {props.comingFromTerms && <StyledControlledAutoCompleteInput name="setPaymentDateType" label="Set Payment Date" options={[{text:getDisplayNameOfEnumValue(SetPaymentDatesType.UponArrival), value:SetPaymentDatesType.UponArrival}, {text:getDisplayNameOfEnumValue(SetPaymentDatesType.SetDate), value:SetPaymentDatesType.SetDate}]}/>}
    {setPaymentDateType == SetPaymentDatesType.SetDate&& <StyledControlledFormDateInput name="payments.0.paymentDate" disabled={props.payment?.isPaid} label="First Installment Date" />}
    {setPaymentDateType != SetPaymentDatesType.SetDate  && <Box width={"100%"}></Box>}
    </SpacedRowStack>
    )
 
}