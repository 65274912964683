import { useEffect, useState } from "react";
import { BoldTypography, NameLogo, SpacedColumnStack } from "../Utility/shared-components";


export default function ConfirmUserEmail(){
    const [message, setMessage] = useState("")
    useEffect(()=>{
        fetch("/Login/ConfirmUserEmail" + location.search, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.text() }
                else { throw response.text() }
            })
            .then((result: string) => {
                setMessage(result);
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                })
            })
    },[])
    return(<SpacedColumnStack width="100%">
    <NameLogo width={15} />
    <BoldTypography textAlign={"center"}>{message}</BoldTypography>
    </SpacedColumnStack>)
}