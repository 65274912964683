import { Box, Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector, useDispatch, batch } from "react-redux";
import { CardTabsType } from "../Constants/client-side-enums";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { ApplicationState, InfoPopup, TempData } from "../ReduxRelatedUtils/redux-types";
import CommentsTab from "../Requests/ItemCard/comments-tab";
import { AddSupplierViewModel, AddSupplierFormData } from "../Shared/AddCardComponents/add-types";
import { CardTabs, GlobalTab } from "../Shared/AddCardComponents/card-tabs";
import EditCard, { EditCardContext, EditCardType } from "../Shared/edit-card";
import { TitleTypography, StyledFilledButton, IsEdittableContext, StyledNoOutlineButton, SvgIcon, SpacedColumnStack } from "../Utility/shared-components";
import DetailsTab from "./details-tab";
import PaymentTab from "./payment-tab";
import SupplierTabs, { supplierTabInfo } from "./supplier-card-tabs";
import VendorContactsTab from "./vendor-contacts-tab";
import { merge } from 'lodash';
import EditDrawerSkeleton from "../Shared/edit-drawer-skeleton";
import { setTempData } from "../ReduxRelatedUtils/temp-data-reducer";
import { EditDrawerTempData } from "../Utility/general-types";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { removeModal } from "../ReduxRelatedUtils/modals-reducer";
import { SvgNames } from "../Constants/app-enums";
import { setReloadIndex } from "../ReduxRelatedUtils/index-table-reducer";
import queryString from 'query-string'
import { push } from "connected-react-router";
import { removeEditDrawerViewModel, setAllReloadEditModal } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { setInfoPopup } from "../ReduxRelatedUtils/infoPopupReducer";
export function onSubmitSupplierEdit(data: AddSupplierFormData, dispatch: Function, setSubmitSucceeded: Function, e: any, location: any, isInfoPopup:boolean) {
    dispatch(setLoading(true))

    fetch("/Vendors/Edit", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
    }).then(response => {
        if (response.ok) { return response.json() }
        else { throw response.json() }
    }).then((result) => {

        let newPathname = location.pathname.replace("/" + EditCardType.EditSupplier, "")
        if (e.nativeEvent.submitter.name == "exit") {
            batch(() => {
                if(isInfoPopup){
                    dispatch(setInfoPopup({} as InfoPopup))
                }
                else{
                    dispatch(push(queryString.stringifyUrl({ url: newPathname, query: location.query })))
                }
                dispatch(removeEditDrawerViewModel({ id: data.vendor.vendorID.toString() }))
                
                dispatch(setError({ showBody: true, message: "" }))

            })
        }
        else {


            batch(() => {

                dispatch(setGlobalSnack({ open: true, message: "Saved Supplier Changes" }))
            })
        }
        setSubmitSucceeded(true)

        batch(() => {
            dispatch(setAllReloadEditModal({ reloadEditModal: true }))
            dispatch(setLoading(false))
            dispatch(setReloadIndex(true));
        })
    })

        .catch(err => {
            Promise.resolve(err).then(text => {
                console.log("in submit error")

                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.result, severity: "error" }))
                })
            })
        })
}

export default function EditSupplier() {
    const dispatch = useDispatch()
    const location = useSelector<ApplicationState>(state => state.router.location) as Location;
    const [submitSucceeded, setSubmitSucceeded] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    function onSubmit(data: AddSupplierFormData, e: any) {
        onSubmitSupplierEdit(data, dispatch, setSubmitSucceeded, e, location, false)
    }

    return (
        <EditCardContext.Provider value={{ submitSucceeded: submitSucceeded, toggleSubmitSucceeded: setSubmitSucceeded, confirmModalOpen: confirmModalOpen, toggleConfirmModalOpen: setConfirmModalOpen }}>
            <EditCard editType={EditCardType.EditSupplier} onSubmit={(data, e) => onSubmit(data, e)} />
        </EditCardContext.Provider>
    )
}


export function EditSupplierInnerForm(props: { id?: string }) {
    const [loading, setLoading] = useState<boolean>(true);

    //console.log(viewModel);
    const { control, formState, watch, getValues, reset, setValue, handleSubmit } = useFormContext<AddSupplierFormData>();
    const dispatch = useDispatch();
    const [viewModel, setViewModel] = useState({} as AddSupplierViewModel);
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<EditDrawerTempData>
    const isEdittableContext = useContext(IsEdittableContext);
    const vendor = watch("vendor")

    useEffect(() => {

        fetch("/Vendors/Edit" + (props.id ? ("?id=" + props.id) : location.search), {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: AddSupplierViewModel) => {
                setViewModel(result);

                setValue("vendor", merge(vendor, result.vendor));
                setValue("vendorContacts", result.vendorContacts);
                setValue("comments", result.comments);
                setValue("vendorCategoryTypes", result.vendorCategoryTypes);
                setLoading(false)
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text.toString(), showBody: false }))
                })
            })

    }, [])

    function discardChanges() {
        console.log("in discard changes")
        isEdittableContext.toggleIsEdittable(false)
        setViewModel(tempData.viewModel as AddSupplierViewModel)
        reset(tempData.formData as AddSupplierFormData)
        dispatch(setTempData({}))
        dispatch(setGlobalSnack({ open: true, message: "Discarded Supplier Changes" }))

    }
    function setEditable() {
        let formData = getValues()
        dispatch(setTempData({ formData: JSON.parse(JSON.stringify(formData)), viewModel: viewModel } as TempData<EditDrawerTempData>))
        isEdittableContext.toggleIsEdittable(true)
    }

    return (loading ? <EditDrawerSkeleton /> :
        <>
            <Box sx={{ padding: "1.5rem 3rem 3rem" }}>
                <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <TitleTypography>{vendor.vendorEnName}</TitleTypography>
                    <Stack spacing={1.5}>
                        {!isEdittableContext.isEdittable && <StyledNoOutlineButton endIcon={<SvgIcon name={SvgNames.Create} className="section-fill" height={1.5} />} onClick={setEditable}>
                            Edit Supplier
                        </StyledNoOutlineButton>}
                        {isEdittableContext.isEdittable &&
                            <>
                                <StyledNoOutlineButton onClick={discardChanges}>Discard Edits</StyledNoOutlineButton>
                                <StyledFilledButton form={EditCardType.EditSupplier + vendor.vendorID} type="submit">Save Edits</StyledFilledButton>
                            </>
                        }
                    </Stack>
                </Stack>
            </Box>
            <CardTabs key={CardTabsType.EditDrawer} cardTabType={CardTabsType.EditDrawer} selectedTab={0} tabs={supplierTabInfo} >
                <SupplierTabs key={"itemtabs"} viewModel={viewModel} />
            </CardTabs>
        </>

    )
}
