import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { ModalName } from "../Constants/app-enums";
import { selectModalLoading, selectModalPresent, selectEditDrawerViewModelByID } from "../ReduxRelatedUtils/customConfigureStore";
import { setEditDrawerViewModel } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../ReduxRelatedUtils/index-table-reducer";
import { setModalLoading, removeModal } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, LastSelectedLocation, Modal } from "../ReduxRelatedUtils/redux-types";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from "../Requests/Modals/modal-components";
import ModalSkeleton from "../Requests/Modals/modal-skeleton";
import { LocationModalInfo, BasicModalInfo, CustomError } from "../Utility/general-types";
import { RequestItemViewModel } from "../Utility/request-types";
import { objectToFormData } from "../Utility/root-function";
import { GlobalForm } from "../Utility/shared-components";
import { setLastSelectedLocation } from "../ReduxRelatedUtils/selectedLocationReducer";

type DeleteLocationViewModel = {
    locationName: string;
    isBuilding:boolean;
  }
  
  // DeleteLocationFormData
  type DeleteLocationFormData = {
    locationInstanceID: number;
  }

export default function DeleteLocationModal() {
    const dispatch = useDispatch();
    
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<LocationModalInfo>;    
    const methods = useForm<DeleteLocationFormData>({ mode: 'onChange' , defaultValues: {locationInstanceID:  modalInfo.locationID}});
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState<DeleteLocationViewModel>()
  
    useEffect(() => {
        var url = "/Locations/DeleteLocationModal?locationInstanceID=" + modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: DeleteLocationViewModel) => {
                setViewModel(result);
                dispatch(setModalLoading(false))
                //dispatch(setGlobalSnack({open:true, message:"In delete Modal"}))
            })
            .catch(err => {
                console.log(err)
                Promise.resolve(err).then(text => {
                    setError({message:text.errorMessage, showBody:false})
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.locationID]);


    function onSubmit(data:DeleteLocationFormData) {        
        var url = '/Locations/DeleteLocationModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(result => {
                if (!result.ok) throw result.text()
                console.log("basic modal result")
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    if(viewModel?.isBuilding){
                        dispatch(setReloadIndex(true))
                    }
                    else{

                        dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))      
                    } 
                    dispatch(setGlobalSnack({ open: true, message: "Delete Location Sucessful", severity: "success" }))
             })
    
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }

    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Delete Location" errorMessage={error.message} />
                    {error.showBody ?<>
                    <StyledDialogContent>
                     <GlobalForm methods={methods} formID={ModalName.DeleteLocation} onSubmit={onSubmit}>
                            <Typography>{`Are you sure you want to delete this location ${viewModel?.locationName}?`}</Typography>
                    </GlobalForm>
                         
                    </StyledDialogContent>
                    <ModalFooterWithSave submitButtonText='Delete' formID={ModalName.DeleteLocation} />
                    </>
                    : null
                }
                </>
            }
</>
    );
}
