import Box from '@mui/material/Box';
import Grid, { GridProps } from '@mui/material/Grid';
import Icon, { IconProps } from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SvgNames } from '../Constants/app-enums';
import { CloseButton, SvgIcon } from '../Utility/shared-components';



type Notification = {
    id: number,
    date: Date,
    description: string,
    requestName: string,
    icon: SvgNames,
    color: string,
    status: number,
    controller: string,
    action: string,
    isRead: boolean,
    timestamp: Date
}

export const StyleNotificationGridItem = styled(Grid)<GridProps>(({ theme }) => ({
    "& .MuiTypography-root": {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "1rem"
    },
    "& .MuiBox-root":{
        borderBottom: "1px solid #dee2e6"
    },
    padding: "0rem 1rem 1rem 1rem"
}));

export const StyleNotificationIcon = styled(Icon)<IconProps>(({ theme }) => ({
    borderRadius: "50%",
    fontSize: "1.75rem",
    margin: "auto",
    justifyContent: "center",
    border: ".125rem solid var(--black-87)"
}));

export default function HomeNotifications(){
    console.log("in notifications")
    const [notifications, setNotifications] = useState<Notification[]>();
    const dispatch = useDispatch();
    useEffect(() => {

        var url = "/Home/GetLatestNotifications";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then(result => {
                const notificationsList = JSON.parse(result).notifications as Notification[]
                console.log(notificationsList)
                setNotifications(notificationsList);
            })
            .catch(err => {
                console.log(err);
            })
    }, []);

    
    return (
        <Box padding={'0.5rem 0.75rem'} width={'410px'} sx={{ fontWeight: 500 }}>
            <CloseButton/>
            <Typography padding="1.5rem" sx={{ fontSize: '1.25em' }}>Notifications</Typography>
            <StyleNotificationGridItem>
                {notifications?.map(n =>
                    <Stack direction="row" spacing={1} sx={{ width:"100%",  padding:"1rem"}} alignItems="center">
                            <SvgIcon name={n.icon} height={3} className={n.color}/>
                        <Stack>
                            <Typography>{moment(n.date).format("DD/MM/YYYY")}</Typography>
                            <Typography className={n.color} >{n.description}</Typography>
                            <Typography>{n.requestName}</Typography>
                        </Stack>
                    </Stack>
                )}
            </StyleNotificationGridItem>
        </Box>
    )
}