import {  Button,  Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { useState } from "react"
import { useDispatch, batch, useSelector } from "react-redux"
import { ModalFlow, LocationTypeName, ModalName, SvgNames, PageTypeEnum, SidebarEnum } from "../Constants/app-enums"
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore"
import { setModalLoading, setModal } from "../ReduxRelatedUtils/modals-reducer"
import { Modal, ModalInfo } from "../ReduxRelatedUtils/redux-types"
import { LocationModalInfo, AddStorageUnitModalInfo } from "../Utility/general-types"
import { SvgIcon } from "../Utility/shared-components"
import Theme from "../Utility/theme"
import LocationCard from "./location-card"
import { LocationCardColumn } from "./locations-types"

export default function LocationCardColumnView(props: { column: LocationCardColumn, columnIndex: number }) {
    const [showButtonText, setShowButtonText] = useState(false)
    const dispatch = useDispatch()
    const {pageType, sidebarType} = useSelector(selectNavigationInfo)

    function addLocation() {
        let modal = { modalFlow: ModalFlow.AddLocation } as Modal<LocationModalInfo>
        switch (props.column.locationType) {
            case LocationTypeName.Floor:
                modal.modalStep = ModalName.AddFloor
                modal.modalInfo ={ parentLocationID: props.column.locationParentID } as LocationModalInfo
                //modal.modalInfo.locationID = props.column.locationParentID;
                break;
            case LocationTypeName.Lab:
                modal.modalStep = ModalName.AddLab
                modal.modalInfo={} as LocationModalInfo
                break;
            case LocationTypeName.Room:
                modal.modalStep =  sidebarType==SidebarEnum.Labs?ModalName.AssociateRoom:ModalName.AddRoom
                modal.modalInfo ={ parentLocationID: props.column.locationParentID } as LocationModalInfo
                //modal.modalInfo.locationID = props.column.locationParentID;
                break;
            case LocationTypeName.Building:
                modal.modalStep = ModalName.AddBuilding
                modal.modalInfo={} as LocationModalInfo
                break;
            case LocationTypeName.ParentStorageUnit:
                modal.modalStep = ModalName.AddStorageUnit
                modal.modalInfo ={ locationTypeID: props.column.locationParentID, parentLocationID: props.column.locationParentID } as AddStorageUnitModalInfo
                break;

                
        }
        modal.modalInfo.columnIndex = props.columnIndex-1;
        modal.modalInfo.locationType = props.column.locationType;
       
        batch(() => {
            dispatch(setModalLoading(true))
            dispatch(setModal(modal))
        })
    }
    return (
        <Box position="relative" paddingBottom="1.5rem" width={props.column.width??"25%"}>
        <Stack position="relative" height="100%" >
            <Box overflow="auto" border={`2px solid ${Theme.palette.grey[300]}`} borderLeft={props.columnIndex >0 ?"none" : `2px solid ${Theme.palette.grey[300]}`} minHeight={"100%"} sx={{ backgroundColor: Theme.palette.grey[100] }}>
                <Stack>
                        {props.column.locationCards?.map(c =>
                            <LocationCard locationType={props.column.locationType} columnIndex={props.columnIndex} key={c.id} cardInfo={c} parentLocationID={props.column.locationParentID} />
                        )}
                </Stack>
            </Box>
            {(pageType!= PageTypeEnum.RequestLocation && !props.column.parentIsBlocked && props.column.locationType != LocationTypeName.None && props.column.locationType != LocationTypeName.StorageUnit) &&
                <Button className="section-bg-color"
                    onClick={addLocation}
                    onMouseEnter={() => setShowButtonText(true)}
                    onMouseLeave={() => setShowButtonText(false)}
                    sx={{ position: "absolute", bottom: "-1.25rem", transform: "translate(-50%)", left: "50%", minWidth: "2.5rem", height: "2.5rem", padding: "0.5rem"}}>
                    <Stack direction="row" justifyContent="space-between" spacing={0.5} alignItems="center">
                        <SvgIcon color="white" name={SvgNames.Add} height={1.5} />
                        {showButtonText && <Typography noWrap fontSize="1.2rem" fontWeight={300} color="white">{"Add " + getDisplayNameOfLocationType(props.column.locationType)}</Typography>}
                    </Stack>
                </Button>}
        </Stack>
        </Box>

    )
}

function getDisplayNameOfLocationType(locationType: LocationTypeName){
    switch (locationType) {
        case LocationTypeName.Floor:
        case LocationTypeName.Lab:
        case LocationTypeName.Room:
        case LocationTypeName.Building:
            return locationType.toString()
        case LocationTypeName.ParentStorageUnit:
            return "Storage"
    }
}