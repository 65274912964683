import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { batch, useDispatch, useSelector } from "react-redux";
import { ModalFlow, ModalName, SvgNames } from "../Constants/app-enums";
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../ReduxRelatedUtils/redux-types";
import ColumnOptionsPopover from "../Requests/column-options-popover";
import IndexTableRow from "../Shared/IndexTable/index-table-row";
import { RowViewModel, TimekeeperActionModalInfo } from "../Utility/general-types";
import { FilledTableButton, SectionOutlinedTableButton, SmallTypography, SpacedColumnStack, SpacedRowStack, SvgIcon } from "../Utility/shared-components";
import { SummaryHoursViewModel } from "../Utility/timekeeper-types";

export function ReportDaysOffHeader() {
    const { totalSickDaysPerYear, totalVacationDaysPerYear } = useSelector<ApplicationState>(state => state.viewModel) as SummaryHoursViewModel;
    const dispatch = useDispatch();
    function reportOffDayClick(typeID:number){
        batch(()=>{
            dispatch(setModalLoading(true))
            dispatch(setModal({modalFlow:ModalFlow.ReportOffDayModal, modalStep:ModalName.ReportOffDayModal, modalInfo:{typeID: typeID}} as Modal<TimekeeperActionModalInfo>))
        })
    }

    return (
        <SpacedRowStack width="100%" marginBottom={"3rem"}>
            <Stack width="100%" className="section-outline-color" padding="2rem" borderRadius={"0.3rem"} alignItems="center" justifyContent='center'>
                <Stack direction="row" spacing={4}  width="50%">
                    <Stack spacing={1} width="100%" alignItems="center">
                        <FilledTableButton fullWidth onClick={()=>reportOffDayClick(2)} startIcon={<SvgIcon height={1.25} color="white"  name={SvgNames.Luggage}/>}>Report vacation day</FilledTableButton>
                        <SmallTypography color={(theme)=>theme.palette.grey[500]}>{`${totalVacationDaysPerYear} vacation days per year`}</SmallTypography>
                    </Stack>
                    <Stack spacing={1}  width="100%" alignItems="center">
                         <FilledTableButton fullWidth onClick={()=>reportOffDayClick(1)} startIcon={<SvgIcon height={1.25} color="white" name={SvgNames.Sick}/>}>Report sick day</FilledTableButton>
                         <SmallTypography  color={(theme)=>theme.palette.grey[500]}>{`${totalSickDaysPerYear} sick days per year`}</SmallTypography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack spacing={1} width={"20%"}>
                <SectionOutlinedTableButton onClick={()=>reportOffDayClick(4)}>Report special day</SectionOutlinedTableButton>
                <SectionOutlinedTableButton onClick={()=>reportOffDayClick(3)}>Report maternity leave</SectionOutlinedTableButton>
                <SectionOutlinedTableButton onClick={()=>reportOffDayClick(5)}>Report unpaid leave</SectionOutlinedTableButton>
        </Stack>
        </SpacedRowStack >
    );
}