import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, ListSubheader } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, object, string } from 'yup';
import { ModalName, SvgNames } from '../Constants/app-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal } from '../ReduxRelatedUtils/redux-types';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput, StyledControlledMultipleSelect, StyledControlledAutoCompleteInput, StyledControlledSelectInput } from '../Utility/custom-input-components';
import { LocationModalInfo, AutocompleteOption, CustomError, SelectListItem } from '../Utility/general-types';
import { GlobalForm, StyledHoverOutlineButton, SvgIcon } from '../Utility/shared-components';
import { Lab, LocationInstance } from './locations-types';
import { setLastSelectedLocation } from '../ReduxRelatedUtils/selectedLocationReducer';

export type LabViewModel = {
    applicationUsers: SelectListItem[],
    selectedApplicationUsers: SelectListItem[],
    selectedRooms : FloorRooms[],
    buildings: AutocompleteOption[]
    floors: LocationInstance[],
    lab: Lab,
    defaultBuildingID: number
}

export type LabFormData = {
    selectedApplicationUsers: SelectListItem[]
    defaultBuildingID: number,
    floorRooms: FloorRooms[]
} & Lab

export type FloorRooms = {
    floorID: number,
    rooms: AutocompleteOption[]
}

export default function LabModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [viewModel, setViewModel] = useState<LabViewModel>({} as LabViewModel)
    const [error, setError] = useState<CustomError>(new CustomError());


    const validationSchema = object<Partial<Record<keyof LabFormData, AnySchema>>>({
        name: string().required("Lab Name field is required"),
        //selectedApplicationUsers: array().min(1, "Must choose at least one employee").required("Must choose at least one employee"),
        //defaultBuildingID: number().moreThan(0,"Primary Shipping Address field required").required("Primary Shipping Address field required"),
        //rooms: array().min(1, "Must choose at least one room").required("Must choose at least one room")
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            selectedApplicationUsers: [] as SelectListItem[],
            defaultBuildingID: 0,
            floorRooms: [{ floorID: 0, rooms: [] }] as FloorRooms[]
        } as LabFormData
    }
    );

    const fieldArrayMethods = useFieldArray(
        {
            control:methods.control,
            name: "floorRooms"
        }
    );

    useEffect(() => {
        var url = "/Locations/LabModal?labID="+modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: LabViewModel) => {
                setViewModel(result);
                if(result.lab && result.lab.labID != 0){
                    methods.setValue("name", result.lab.name)
                    methods.setValue("labID", result.lab.labID)
                    methods.setValue("selectedApplicationUsers", result.selectedApplicationUsers)
                    methods.setValue("defaultBuildingID", result.defaultBuildingID)
                    methods.setValue("floorRooms", result.selectedRooms)
                }
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);

    function onSubmit(data: any, e: any) {
        var url = '/Locations/LabModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: { result: any }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))
                    dispatch(setGlobalSnack({ open: true, message: modalInfo.locationID? "Lab Updated" : "Lab Added", severity: "success" }))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={modalInfo.locationID == undefined ?"Add Lab" : "Edit Lab"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddLab} onSubmit={onSubmit} methods={methods}>
                                <Stack spacing={2} alignItems="center">
                                    <StyledControlledFormInput label="Lab Name" name="name" />
                                    <StyledControlledMultipleSelect name="selectedApplicationUsers" label="Staff" placeholder='Select Employees' options={viewModel?.applicationUsers} />
                                    <StyledControlledAutoCompleteInput label="Primary Shipping Address" name="defaultBuildingID" placeholder='Select Building'
                                    options={viewModel.buildings??[]}
                                    />
                                    {fieldArrayMethods.fields.map((f, i) => <RoomsBlock key={fieldArrayMethods.fields.length+"l"+i} buildings={viewModel.buildings} floors={viewModel?.floors} index={i} />)}
                                    {viewModel?.floors.length > 1 && <StyledHoverOutlineButton sx={{ width: "25rem" }} onClick={() => fieldArrayMethods.append({ floorID: 0, rooms: [] }as FloorRooms)}>+ Add Rooms from a different floor</StyledHoverOutlineButton>}
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddLab} />
                    </>
                    }
                </>
            }
        </>
    )
}

export function RoomsBlock(props: { floors: LocationInstance[], buildings:AutocompleteOption[], index: number }) {
    const [rooms, setRooms] = useState<AutocompleteOption[]>([])
    const { watch, setValue } = useFormContext<LabFormData>()
    const currentFloor = watch(`floorRooms.${props.index}.floorID`)
    const selectedFloors = watch("floorRooms")
    const didMount = useRef(false);

 //   console.log("currentFloor" + currentFloor)
    useEffect(() => {
     
     //   console.log("in curent floor useEffect " + currentFloor)
        if (currentFloor != 0) {
            fetch("/Locations/GetRoomsByFloor?floorID=" + currentFloor, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: AutocompleteOption[]) => {
                    if (didMount.current) {
                       setValue(`floorRooms.${props.index}.rooms`, [] as AutocompleteOption[])
                    }else{
                        didMount.current = true;
                    }
                    setRooms(result)                    
                })
                .catch(err => {
                    // Promise.resolve(err).then(text => {
                    //     dispatch(setError({ message: text.toString(), showBody: false }))
                    // })
                })
        }
  

    }, [currentFloor])
    return (
        <Box width="100%" data-testid="floorRoomBlock">
            <Grid container spacing={2}>
                <Grid item xs={4}>

                <StyledControlledSelectInput
                        label="Select Floor"
                        name={`floorRooms.${props.index}.floorID`}
                    >
                        {props.buildings.filter(b=>!b.hidden || b.value== props.floors.find(f=>f.locationInstanceID==currentFloor)?.locationInstanceParentID).map((b: AutocompleteOption, index) => {
                            return (
                                [
                                    <ListSubheader key={b.value}>{b.text}</ListSubheader>,
                                    props.floors.filter(f => f.locationInstanceParentID == b.value).filter(f=>!f.isBlocked || f.locationInstanceID==currentFloor).map((f: LocationInstance, i) => {
                                        return (<MenuItem key={f.locationInstanceID} value={f.locationInstanceID}>
                                            {f.locationInstanceName}
                                        </MenuItem>
                                        )
                                    })
                                ]
                            )
                        })}
                    </StyledControlledSelectInput>
                </Grid>
                <Grid item xs={props.index > 0 ? 7 : 8}>
                    <StyledControlledMultipleSelect label="Rooms" options={rooms} name={`floorRooms.${props.index}.rooms`} />
                </Grid>
                {props.index > 0 &&
                    <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" >
                        <Box alignItems="center" display="flex" marginRight="0.3rem" width="3rem" onClick={() => setValue("floorRooms", selectedFloors.filter((f, i) => i != props.index))}>
                            <SvgIcon name={SvgNames.Delete} height={2} className={"icon-black"} />
                        </Box>
                    </Grid>}
            </Grid>
        </Box>
    )
}

