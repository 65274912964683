import { Box, Button, FormControlLabel, Grid, ListSubheader, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { CurrencyEnum, ItemCardFields, MenuItemEnum, RoleDefinition, SvgNames } from "../../Constants/app-enums";
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore";
import { ApplicationState, LoggedInUser, TempData } from "../../ReduxRelatedUtils/redux-types";
import { AddItemFormData } from "../../Shared/AddCardComponents/add-types";
import { StyledControlledFormInput, StyledControlledRadioButton, StyledControlledRadioGroup, StyledControlledAutoCompleteInput, StyledCurrencyFormInput, StyledControlledSelectInput } from "../../Utility/custom-input-components";
import { AutocompleteOption } from "../../Utility/general-types";
import { ProductItemViewModel, RequestItemViewModel, UnitParentType, UnitType } from "../../Utility/request-types";
import { formatNumber } from "../../Utility/root-function";
import { BoldTypography, CloseButton, IsEdittableContext, SvgIcon } from "../../Utility/shared-components";
import Theme from "../../Utility/theme";
import { PriceEdittableContext } from "./edit-item";
import { DiscountBlock, PaymentsBlock, TermsBlock } from "./item-components";

export default function PriceTab(props:{ fields: ItemCardFields[]}) {
    const { watch, setValue, control } = useFormContext<AddItemFormData>();
    const requestID = watch("request.requestID")   
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, requestID)?.viewModel) as RequestItemViewModel;
    const {disablePriceEdit} = useContext(PriceEdittableContext)

     
    //form state watches
    const pricePerUnitShekel = watch("request.pricePerUnitShekel")
    const pricePerUnitDollar = watch("request.pricePerUnitDollar")
    const exchangeRate = watch("request.exchangeRate")
    const currency = watch("request.currency")

    const unit = watch("request.unit")

    const vendorCurrency = watch("request.product.vendor.country.currency.currencyName")
    const payments = watch("request.payments")


    useEffect(() => {
        switch (currency) {
            case CurrencyEnum.NIS:
                if(pricePerUnitShekel > 0){
                setValue("request.pricePerUnitDollar", (pricePerUnitShekel / exchangeRate))
                setValue("request.costDollar", ((pricePerUnitShekel / exchangeRate) * unit))
                }
                break;
            case CurrencyEnum.USD:
                if(pricePerUnitDollar > 0){
                setValue("request.pricePerUnitShekel", (pricePerUnitDollar * exchangeRate))
                setValue("request.cost", ((pricePerUnitDollar * exchangeRate) * unit))
                }
                break;
        }
    }, [exchangeRate])



    return (
        <Grid container direction="column">
            <Grid container direction="row" justifyContent={"end"} spacing={2} alignItems="center">
                {(vendorCurrency != CurrencyEnum.None && currency != vendorCurrency) &&
                    <Grid item xs={8} justifyContent={"start"}>
                        <BoldTypography color={Theme.palette.error.main}>{`Warning: Default Currency for Selected Vendor is ${vendorCurrency}`}</BoldTypography>
                    </Grid>}
                <Grid item xs={2}>
                    <StyledControlledAutoCompleteInput
                        label="Currency"
                        name='request.currency'
                        disabled={disablePriceEdit}
                        options={Object.values(CurrencyEnum).filter(c => c != CurrencyEnum.None).map(c => { return {text: c.toString(), value: c} as AutocompleteOption})??[]}
                    />
                </Grid>
                <Grid item xs={2}>
                    <StyledControlledFormInput disabled={disablePriceEdit} name="request.exchangeRate" label="Exchange Rate" type="number" />
                </Grid>
            </Grid>
            <ItemPriceInfoBlock viewModel={viewModel} />
            <Grid container  spacing={2}>
            <Grid item xs={4}>
            <StyledControlledRadioGroup  name="request.includeVAT">
                            <FormControlLabel disabled={(payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} sx={{ margin: "0" }} key={"includeVAT"} value={true}
                                control={<StyledControlledRadioButton/>}
                                label={<Typography>Include VAT</Typography>} />
                            <FormControlLabel disabled={(payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} sx={{ margin: "0" }} key={"noVAT"} value={false}
                               control={<StyledControlledRadioButton/>}
                                label={<Typography>No VAT</Typography>} />
            </StyledControlledRadioGroup>
            </Grid>
            {props.fields?.map((e, i) => {
            switch (e) {

                case ItemCardFields.Terms:
                    return (<TermsBlock key={e} cols={2} {...viewModel} />);
                    
                case ItemCardFields.Quote:
                    return (<DiscountBlock disabled={disablePriceEdit} key={e} />);
            }
        })}
        </Grid>
        </Grid>
    )
}




export function AddCardButton(props: { cardType: "Subunit" | "Subunit2", disabled: boolean, openCard: Function }) {
    const isEdittableContext = useContext(IsEdittableContext);
    return (
        <Stack direction="column" height={"100%"} width={"100%"} justifyContent={"center"} >
            <Button disabled={props.disabled || isEdittableContext.isEdittable==false} onClick={() => { props.openCard() }} sx={{ opacity: props.disabled ? "0.5" : "1", justifyContent: "center", display: "flex" }}>
                <SvgIcon name={SvgNames.Add} height={4} className="section-fill" />
            </Button>
            <Typography textAlign="center" className="section-color" sx={{ opacity: props.disabled ? "0.5" : "1" }}>
                Add {props.cardType}
            </Typography>
        </Stack>
    )
}

export function PriceCard(props: PropsWithChildren<any>) {
    return (
        <Grid item xs={4}>

            <Box className="section-outline-color" minHeight="13rem" borderRadius={"0.3rem"} padding="1.5rem" display={"flex"} alignItems={"center"} position="relative" data-testid="price-card">
                {props.children}
            </Box>
        </Grid>)
}
export type UnitCardProps = {
    viewModel: RequestItemViewModel|ProductItemViewModel,
    unitCardType: "unit" | "subUnit" | "subSubUnit",
    closeCard?: Function,
    unitText: string
}
function UnitCard(props: UnitCardProps) {
    const { watch, setValue } = useFormContext<TempData<AddItemFormData>>();

    const pricePerUnitShekel = watch("request.pricePerUnitShekel")
    const pricePerUnitDollar = watch("request.pricePerUnitDollar")
    const unitTypeID = watch("request.product.unitTypeID")
    const subUnitTypeID = watch("request.product.subUnitTypeID")
    const subUnit2TypeID = watch("request.product.subSubUnitTypeID")
    const subunit = watch("request.product.subUnit")
    const subunit2 = watch("request.product.subSubUnit")
    const parentCategoryID = watch("request.product.productSubcategory.parentCategoryID")
    const currency = watch("request.currency")
    const payments = watch("request.payments")

    const [unitParentTypesDDL, setUnitParentTypesDDL] = useState<UnitParentType[]>(props.viewModel.unitParentTypes);
    const isEdittableContext = useContext(IsEdittableContext);
    const [unitTypesDDL, setUnitTypesDDL] = useState<UnitType[]>(props.viewModel.unitTypes);

   const {disablePriceEdit} = useContext(PriceEdittableContext)
   
    function getPrice(currency: CurrencyEnum) {
        // console.log("in get price " + props.unitCardType)
        switch (props.unitCardType) {
            case "subUnit":
                return formatNumber((currency == CurrencyEnum.USD ? pricePerUnitDollar / (subunit ?? 1) : pricePerUnitShekel / (subunit ?? 1)) ?? 0)
            case "subSubUnit":
                return formatNumber((currency == CurrencyEnum.USD ? pricePerUnitDollar / (subunit ?? 1) / (subunit2 ?? 1) : pricePerUnitShekel / (subunit ?? 1) / (subunit2 ?? 1)) ?? 0)
        }
    }

    useEffect(() => {
        var unitTypes = props.viewModel.unitTypes?.filter(u => u.unitTypeParentCategory?.filter(up => up.parentCategoryID == parentCategoryID).length > 0)
        setUnitTypesDDL(unitTypes);
        switch (props.unitCardType) {
            case "unit":

                if (unitTypes.filter(u => u.unitTypeID == unitTypeID).length == 0) {
                    setValue("request.product.unitTypeID", 0)
                }
                break;
            case "subUnit":
                if (unitTypes.filter(u => u.unitTypeID == subUnitTypeID).length == 0) {

                    setValue("request.product.subUnitTypeID", null)
                }

                break;
            case "subSubUnit":

                if (unitTypes.filter(u => u.unitTypeID == subUnit2TypeID).length == 0) {
                    setValue("request.product.subSubUnitTypeID", null)
                }
                break;
        }


    }, [parentCategoryID])

    useEffect(() => {
        switch (props.unitCardType) {
            case "subUnit":

                let validParentTypes = props.viewModel.unitParentTypes.slice(getUnitTypeParentsIndex(unitTypeID))
                let subUnitTypeValid = validParentTypes.includes(props.viewModel.unitParentTypes[getUnitTypeParentsIndex(subUnitTypeID ?? 0)])
                if (!subUnitTypeValid) setValue("request.product.subUnitTypeID", null)
                setUnitParentTypesDDL(validParentTypes)
                break;
        }
    }, [unitTypeID])


    useEffect(() => {
        switch (props.unitCardType) {
            case "subSubUnit":
                let validParentTypes = props.viewModel.unitParentTypes.slice(getUnitTypeParentsIndex(subUnitTypeID ?? 0))
                let subUnit2TypeValid = validParentTypes.includes(props.viewModel.unitParentTypes[getUnitTypeParentsIndex(subUnit2TypeID ?? 0)])
                if (!subUnit2TypeValid) setValue("request.product.subSubUnitTypeID", null)
                setUnitParentTypesDDL(validParentTypes)
                break;
        }
    }, [subUnitTypeID])

    function getUnitTypeParentsIndex(unitTypeID: number) {
        let unitParentTypeID = props.viewModel.unitTypes.find(u => u.unitTypeID == unitTypeID)?.unitParentTypeID;
        return props.viewModel.unitParentTypes.findIndex(up => up.unitParentTypeID == unitParentTypeID)
    }


    return (
        <>
            {props.unitCardType != "unit" &&
                <CloseButton disabled={!isEdittableContext.isEdittable} onClick={() => { props.closeCard ? props.closeCard() : null }} />}
            <Grid container spacing={1} height={"100%"} data-testid="unit-card">
                <Grid item xs={5}>
                    <StyledControlledFormInput disabled={(payments?.length>0 && payments?.every(p=> p.isPaid)) || (props.unitCardType == "unit" && disablePriceEdit)} name={"request." + (props.unitCardType != "unit" ? "product." : "") + props.unitCardType} label="Amount" type="number" />
                </Grid>
                <Grid item xs={7}>
                    <StyledControlledSelectInput
                        label="Unit"
                        name={'request.product.' + props.unitCardType + 'TypeID'}
                    >
                        {unitParentTypesDDL.filter(up=> unitTypesDDL.filter(ut => ut.unitParentTypeID == up.unitParentTypeID).length>0).map((up: UnitParentType, index) => {
                            return (
                                [
                                    <ListSubheader key={`unitParentType${index}`}>{up.unitParentTypeDescription}</ListSubheader>,
                                    unitTypesDDL.filter(ut => ut.unitParentTypeID == up.unitParentTypeID).map((ut: UnitType, i) => {
                                        return (<MenuItem key={`unitType${i}`} value={ut.unitTypeID}>
                                            {ut.unitTypeDescription.toString()}
                                        </MenuItem>
                                        )
                                    })
                                ]
                            )
                        })}
                    </StyledControlledSelectInput>

                </Grid>
                <Grid item xs={6}>
                    {props.unitCardType == "unit" ?
                        <StyledCurrencyFormInput disabled={currency != CurrencyEnum.USD || (payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} name="request.pricePerUnitDollar" label={"Price per " + props.unitText} type="number" currency='$' /> :
                        <StyledCurrencyFormInput disabled label={"Price Per " + props.unitText} value={getPrice(CurrencyEnum.USD)} type="number" currency='$' />
                    }
                </Grid>
                <Grid item xs={6}>
                    {props.unitCardType == "unit" ?
                        <StyledCurrencyFormInput disabled={currency != CurrencyEnum.NIS || (payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} name="request.pricePerUnitShekel" type="number" testid="pricePerUnitShekel" currency="₪" /> :
                        <StyledCurrencyFormInput disabled value={getPrice(CurrencyEnum.NIS)} type="number" currency='$' testid="pricePerUnitShekel" />
                    }
                </Grid>
            </Grid>
        </>
    )
}
export function ItemPriceInfoBlock(props: { viewModel: RequestItemViewModel }) {
    const didMount = useRef(false)
    return (

        <>
            <PriceUnitBlock didMount={didMount} viewModel={props.viewModel} />
            <TotalCostInfoBlock didMount={didMount} />
        </>
    )
}
function TotalCostInfoBlock(props: { didMount: React.MutableRefObject<boolean> }) {
    const { watch, setValue } = useFormContext<TempData<AddItemFormData>>();
    const {disablePriceEdit} = useContext(PriceEdittableContext)

    const includeVAT = watch("request.includeVAT")
    const cost = watch("request.cost")
    const costDollar = watch("request.costDollar")
    const unit = watch("request.unit")
    const exchangeRate = watch("request.exchangeRate")
    const currency = watch("request.currency")
    const payments = watch("request.payments")



    useEffect(() => {
        if (currency == CurrencyEnum.USD) {
            console.log("cost dollar" + costDollar)
            if (unit > 0) { setValue("request.pricePerUnitDollar", (costDollar / unit)) }
            setValue("request.cost", (costDollar * exchangeRate))
        }

    }, [costDollar])

    useEffect(() => {
        if (currency == CurrencyEnum.NIS || !props.didMount.current) {
            console.log("cost" + cost)
            console.log("exchangeRate" + exchangeRate)
            if (unit > 0) { setValue("request.pricePerUnitShekel", (cost / unit)) }
            setValue("request.costDollar", (cost / exchangeRate))
            props.didMount.current = true;
        }
    }, [cost])
    return <Grid key={"totalCostBlock"} container direction="row" spacing={1} paddingY={"1rem"}>
        <Grid key={"cost"} item xs={2}>
            <StyledCurrencyFormInput disabled={currency != CurrencyEnum.USD || (payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} name="request.costDollar" label={"Total"} type="number" currency='$' />
        </Grid>
        <Grid key={"costDollar"} item xs={2}>
            <StyledCurrencyFormInput disabled={currency != CurrencyEnum.NIS || (payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} name="request.cost" type="number" currency="₪" testid="cost" />
        </Grid>
        {String(includeVAT)=="true" &&
            <>
                <Grid key={1} item xs={2}>
                    <StyledCurrencyFormInput value={formatNumber(costDollar * .17)} disabled label={"VAT"} type="number" currency='$' />
                </Grid>
                <Grid key={2} item xs={2}>
                    <StyledCurrencyFormInput value={formatNumber(cost * .17)} disabled type="number" currency="₪" testid="vatShekel" />
                </Grid>
                <Grid key={3} item xs={2}>
                    <StyledCurrencyFormInput value={formatNumber(costDollar * 1.17)} disabled label={"Total + VAT"} type="number" currency='$' />
                </Grid>
                <Grid item key={4} xs={2}>
                    <StyledCurrencyFormInput value={formatNumber(cost * 1.17)} disabled type="number" currency="₪" testid="totalVatShekel" />
                </Grid>
            </>}
    </Grid>;
}

function PriceUnitBlock(props: { viewModel: RequestItemViewModel, didMount: React.MutableRefObject<boolean> }) {

    const { watch, setValue, control } = useFormContext();
    const unit = watch("request.unit")
    const unitTypeID = watch("request.product.unitTypeID")
    const subunitTypeID = watch("request.product.subUnitTypeID")
    const subunit = watch("request.product.subUnit")
    const subunit2TypeID = watch("request.product.subSubUnitTypeID")
    const subunit2 = watch("request.product.subSubUnit")
    const exchangeRate = watch("request.exchangeRate")
    const currency = watch("request.currency")
    const pricePerUnitShekel = watch("request.pricePerUnitShekel")
    const pricePerUnitDollar = watch("request.pricePerUnitDollar")

    const [showSubunitCard, setShowSubunitCard] = useState((subunitTypeID || subunit) != null)
    const [showSubunit2Card, setShowSubunit2Card] = useState((subunit2TypeID || subunit2) != null)


    useEffect(() => {
        if (currency == CurrencyEnum.USD) {
            if (props.didMount.current == true) {
                console.log("price per unit dollar" + pricePerUnitDollar)
                setValue("request.pricePerUnitShekel", (pricePerUnitDollar * exchangeRate))
                setValue("request.costDollar", (pricePerUnitDollar * unit))
            }
        }
    }, [pricePerUnitDollar])

    useEffect(() => {
        if (currency == CurrencyEnum.NIS) {
            if (props.didMount.current == true) {
                console.log("price per unit shekel")
                setValue("request.pricePerUnitDollar", (pricePerUnitShekel / exchangeRate))
                setValue("request.cost", (pricePerUnitShekel * unit))
            }
        }
    }, [pricePerUnitShekel])

    useEffect(() => {
        if (props.didMount.current == true) {
            console.log("unit" + unit)
            switch (currency) {

                case CurrencyEnum.NIS:
                    setValue("request.cost", (pricePerUnitShekel * unit))
                    break;
                case CurrencyEnum.USD:
                    setValue("request.costDollar", (pricePerUnitDollar * unit))
                    break;
            }
        }
    }, [unit])

    function closeSubunitCard() {
        setShowSubunitCard(false)
        setValue("request.product.subUnit", null)
        setValue("request.product.subUnitTypeID", null)
        showSubunit2Card && closeSubunit2Card()
    }

    function closeSubunit2Card() {
        setShowSubunit2Card(false)
        setValue("request.product.subSubUnit", null)
        setValue("request.product.subSubUnitTypeID", null)
    }
    function openSubunitCard() {
        setValue("request.product.subUnit", 1)
        setValue("request.product.subUnitTypeID", 0)
        setShowSubunitCard(true)
    }
    function openSubunit2Card() {
        setValue("request.product.subSubUnit", 1)
        setValue("request.product.subSubUnitTypeID", 0)
        setShowSubunit2Card(true)
    }

    function getUnitTypeText(unitLevel: "subUnit" | "subSubUnit" | "unit") {
        switch (unitLevel) {
            case "unit":
                return props.viewModel.unitTypes.filter(ut => ut.unitTypeID == unitTypeID)[0]?.unitTypeDescription ?? "unit"
            case "subUnit":
                return props.viewModel.unitTypes.filter(ut => ut.unitTypeID == subunitTypeID)[0]?.unitTypeDescription ?? "unit"
            case "subSubUnit":
                return props.viewModel.unitTypes.filter(ut => ut.unitTypeID == subunit2TypeID)[0]?.unitTypeDescription ?? "unit"
            default:
                return "unit"
        }

    }
    return (
        <Grid container paddingY="1rem" direction="row" spacing={2} >
            <PriceCard>
                <UnitCard viewModel={props.viewModel} unitText={getUnitTypeText("unit")} unitCardType="unit" />
            </PriceCard>
            <PriceCard>
                {showSubunitCard ?
                    <UnitCard viewModel={props.viewModel} unitCardType="subUnit" closeCard={closeSubunitCard} unitText={getUnitTypeText("subUnit")} /> :
                    <AddCardButton openCard={openSubunitCard} cardType="Subunit" disabled={(unitTypeID <= 0 || unit <= 0)} />}
            </PriceCard>
            {showSubunit2Card ?
                <PriceCard>
                    <UnitCard viewModel={props.viewModel} unitCardType="subSubUnit" unitText={getUnitTypeText("subSubUnit")} closeCard={closeSubunit2Card} />
                </PriceCard> :
                (showSubunitCard ?
                    <PriceCard><AddCardButton openCard={openSubunit2Card} disabled={!subunitTypeID || !subunit || subunitTypeID <= 0 || subunit <= 0} cardType="Subunit2" /></PriceCard> :
                    null)}
        </Grid>)
}



