import React, { createContext, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { IndexColumnTypes, MenuItemEnum, RoleDefinition, SidebarEnum } from '../../Constants/app-enums';
import { selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, CheckboxInfo, IndexTableCheckboxes, LoggedInUser, Modal } from '../../ReduxRelatedUtils/redux-types';
import FloatingActionBar from '../../Requests/Utils/floating-action-bar';
import { ActionBarLink, BasicModalInfo } from '../../Utility/general-types';
import { CheckBoxColumn, RequestIndexViewModelByGroup } from '../../Utility/request-types';
import { getFloatingActionBarLink } from '../../Utility/root-function';
import { BoldTypography, SectionBoldTypography, StyledFilledButton } from '../../Utility/shared-components';
import EmptyPage from "../empty-page";
import IndexTableHeadersByGroup from './index-table-headers-by-group';
import IndexTableRow from './index-table-row';
import IndexTableSkeleton from './index-table-skeleton';
import { setCheckboxInfo } from '../../ReduxRelatedUtils/index-table-reducer';

export type FloatingActionBarContextType ={
   floatingActionBarState: {
    floatingActionBarIsVisible: boolean;
    actionBarLinks:  ActionBarLink[];
    },
   setFloatingActionBarState : Dispatch<React.SetStateAction<{
    floatingActionBarIsVisible: boolean;
    actionBarLinks: ActionBarLink[];
}>>
}
export const FloatingActionBarContext = createContext<FloatingActionBarContextType>({ } as FloatingActionBarContextType);


export default function IndexTableDataByGroup(){
    //console.log('reloading index table data by vendor')
    const dispatch = useDispatch()
    const checkboxInfo = useSelector<ApplicationState>(state=> state.indexTableCheckboxes.checkboxInfo) as CheckboxInfo[]

    const viewModel = useSelector<ApplicationState>(state=> state.viewModel) as RequestIndexViewModelByGroup
    const navigationInfo = useSelector(selectNavigationInfo)
    const {userRoles} = useSelector<ApplicationState>(state=>state.userInfo) as LoggedInUser

    //const reloadIndex = useSelector<ApplicationState>(state => state.reloadIndex) as boolean
    const showPageSkeleton = useSelector((state: ApplicationState) => state.showPageSkeleton)
  
    const [floatingActionBarState, setFloatingActionBarState] = useState({
        floatingActionBarIsVisible: false,
        actionBarLinks: [] as ActionBarLink[]
    })


    useEffect(()=>{
        console.log("changing view Model")
        const checkBoxes = viewModel.requestsByGroup?.map(g=>g.rows.map(r=>{const checkbox =r.columns?.filter(c=>c.columnType==IndexColumnTypes.Checkbox)[0] as CheckBoxColumn;
            return {checkboxID: checkbox?.objectID, groupID : g.groupByObject.int, conditions:checkbox?.checkBoxConditions } as CheckboxInfo})).flat()
        dispatch(setCheckboxInfo(checkBoxes));    
    },[viewModel])

    useEffect(()=>{
        console.log("on Change checkbox info")
        setFloatingActionBarState({floatingActionBarIsVisible:checkboxInfo?.filter(c=>c.isChecked).length>0, actionBarLinks:getFloatingActionBarLink(dispatch, navigationInfo.pageType, navigationInfo.sidebarType, checkboxInfo, userRoles, viewModel.tabValue)})  
    },[checkboxInfo])
    

   
    return (
        <>
        {showPageSkeleton ?
        <IndexTableSkeleton/> :
            viewModel.requestsByGroup?.length > 0 ?
            <Box maxHeight={"75vh"} overflow={"auto"} marginRight={"-5.625rem"} paddingRight={"5.625rem"} marginBottom={"9vh"}>

                    {viewModel.requestsByGroup?.map((rbg, i) => {
                        let groupId = rbg.groupByObject.int;
                            return (
                                <React.Fragment key={rbg.groupByObject.int}>
                                <TableContainer key="by-group" component={Box} /* height="72vh" */ >
                                    <Table stickyHeader sx={{ minWidth: 650, marginBottom: "1rem" }} >
                                        <IndexTableHeadersByGroup firstRequestOfGroup={rbg.rows[0]} groupByObject={rbg.groupByObject} showCheckbox={rbg.showGroupCheckbox}/>
                                     {console.log("outside row")}
                                        <TableBody>
                                            {rbg.rows.map((r, i) => {
                                               const checkbox = checkboxInfo.find(c=>c.checkboxID==r.id)
                                                return(
                                                <IndexTableRow key={r.id.toString()} row={r} isChecked={checkbox?.isChecked}/>
                    )})}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Stack direction="row" justifyContent="flex-end" spacing={3} paddingY="3rem" alignItems="center">                                                
                                    <SectionBoldTypography>{"Items: " + rbg.rows.length}</SectionBoldTypography>
                                    {navigationInfo.sidebarType != SidebarEnum.Quotes &&
                                    <>
                                    <BoldTypography>Total: {rbg.totalCost}</BoldTypography>                        
                                    <BoldTypography>Total + VAT: {rbg.totalCostWithVAT}</BoldTypography> 
                                    </>}
                                    {rbg.button?.buttonDescription && rbg.showGroupButton?
                                        <StyledFilledButton onClick={()=>{batch(()=>{
                                            dispatch(setModal({ modalFlow: rbg?.button?.modalFlow, modalInfo: { ids: rbg?.button.ids }, modalStep: rbg?.button.modalName} as Modal<BasicModalInfo>))
                                            dispatch(setModalLoading(true))
                                        })}}>{rbg?.button.buttonDescription}</StyledFilledButton>
                                        :
                                        <Box />
                                    }
                                </Stack>
                             </React.Fragment>
                             )
                            })}
                           
                        </Box>
                        :
                        <div>
                            <EmptyPage />
                        </div>
}
                    <FloatingActionBarContext.Provider value={{setCheckboxInfo, checkboxInfo, setFloatingActionBarState, floatingActionBarState} as FloatingActionBarContextType}>

                     {(floatingActionBarState.floatingActionBarIsVisible && floatingActionBarState.actionBarLinks?.length > 0) &&
                                <FloatingActionBar/>}
                            
                </FloatingActionBarContext.Provider>
        </>)
}
        //old checkbox code for reference when we implement checkboxes
            // <tbody>
            //     <tr className="border-0 d-none currency-warning"><td colSpan="5"><span className="text-danger-centarix">you can only select items with the same currency</span></td></tr>
            //     <tr className="border-0 d-none vendor-warning"><td colSpan="5"><span className="text-danger-centarix">you can only select items from the same vendor</span></td></tr>
            //      {
            //         rbg.map((row, i) => (
            //             <tr key={row.r.RequestID + "" + i} className={"text-center inv-list-item " + (checkboxInfo.selectedCheckboxIDs.includes(row.Columns[0].AjaxID) ? "clicked-border" : "")}>
            //                 {row.Columns.map((col, i) => {
            //                     let key = i + (col.ValueWithError.map(v => { return v.String }).join(""))
            //                     console.log('key', key)
            //                     return (<IndexTableColumn key={key} columnData={col} groupid={groupId} handleCheckbox={handleCheckbox} sidebar={props.viewModel.SidebarType} />)
            //                 })}
            //             </tr>
            //         ))
            //     }         
            // </tbody>
            