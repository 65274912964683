import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, UseFieldArrayReturn, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { AnySchema, array, number, object, string } from 'yup';
import { ModalName, SvgNames } from '../Constants/app-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal } from '../ReduxRelatedUtils/redux-types';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, CustomError } from '../Utility/general-types';
import { GlobalForm, StyledHoverOutlineButton, SvgIcon } from '../Utility/shared-components';
import { LocationInstance } from './locations-types';
import { setLastSelectedLocation } from '../ReduxRelatedUtils/selectedLocationReducer';


export type FloorFormData = {
    floors: Floor[],
    buildingID: number
}

export type Floor = LocationInstance & {
    floorNumberisUnique: boolean,
    uuid: string
}

const InitializedFloor = { floorNumberisUnique: true, locationInstanceName: "", uuid: v4() } as Floor
export default function FloorModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [error, setError] = useState<CustomError>(new CustomError());

    const validationSchema = object<Partial<Record<keyof FloorFormData, AnySchema>>>({
        floors: array<Partial<Record<keyof Floor, AnySchema>>>()
            .of(
                object<Partial<Record<keyof Floor, AnySchema>>>(
                    {
                        locationNumber: number()
                            .when('floorNumberisUnique', (floorNumberisUnique, schema) => {
                                //     { console.log(floorNumberisUnique) }
                                return (floorNumberisUnique ?
                                    number().typeError("Floor number is required.").required("Floor number is required.")
                                    : schema.test({
                                        test: () => floorNumberisUnique,
                                        message: "Floor number already exists for this building"
                                    })
                                )
                            }
                            ),
                        locationInstanceName: string().required("Floor name is required")
                    }
                )
            ),
        buildingID: number().moreThan(0).required()
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        mode: "onChange",
        resolver,
        defaultValues: {
            floors: [InitializedFloor] as Floor[],
            buildingID: modalInfo.parentLocationID
        } as FloorFormData,
    }
    );
    const fieldArrayMethods = useFieldArray(
        {
            control: methods.control,
            name: "floors"
        }
    );

    useEffect(() => {

        var url = "/Locations/FloorModal?locationID=" + modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: LocationInstance) => {
                if (result.locationInstanceID != 0) {
                    methods.setValue("floors", [{ ...result, uuid: result.locationInstanceID.toString(), floorNumberisUnique: true } as Floor])
                }


                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);
    function onSubmit(data: any, e: any) {
        var url = '/Locations/FloorModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: { result: any }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setLastSelectedLocation({ ...modalInfo, reload: true } as LastSelectedLocation))
                    dispatch(setGlobalSnack({ open: true, message: modalInfo.locationID? "Floor Updated" : "Floors Added", severity: "success" }))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={modalInfo.locationID ?"Edit Floor" : "Add Floor"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddFloor} onSubmit={onSubmit} methods={methods}>
                                <Stack spacing={2} alignItems="center">
                                    {fieldArrayMethods.fields.map((f, i) => <FloorBlock existingFloorID={modalInfo.locationID} key={f.uuid} buildingID={modalInfo.parentLocationID} index={i} fieldArrayMethods={fieldArrayMethods} />)}
                                    {modalInfo.locationID == undefined &&
                                        <StyledHoverOutlineButton sx={{ width: "20rem" }} onClick={() => fieldArrayMethods.append({ floorNumberisUnique: true, locationInstanceName: "", uuid: v4() } as Floor)}>+ Add Floor</StyledHoverOutlineButton>}
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddFloor} />
                    </>
                    }
                </>
            }
        </>
    )
}

function FloorBlock(props: { index: number, buildingID: number, existingFloorID?:number, fieldArrayMethods: UseFieldArrayReturn<FloorFormData, "floors", "id"> }) {
    const dispatch = useDispatch()
    const { watch, setValue, trigger } = useFormContext<FloorFormData>()
    const floorNumber = watch(`floors.${props.index}.locationNumber`)
    const floorName = watch(`floors.${props.index}.locationInstanceName`)
    const floors = watch("floors")
    const didMount = useRef(false);
    useEffect(() => {
        if(didMount.current){
            console.log("in curent floor useEffect " + floorNumber)
        if ((floorNumber && floorNumber != undefined) || floorNumber == 0) {
            floors.map((floor, localIndex) => {
                let existingFloorNumber = floors.find((f, i) => i != localIndex && f.locationNumber == floor.locationNumber) != undefined
                if (existingFloorNumber) {
                      //  console.log(localIndex + " existing floor number")
                    setValue(`floors.${localIndex}.floorNumberisUnique`, false)
                }
                else {
                    //console.log(localIndex + " no existing floor number")
                    fetch("/Locations/CheckExistingBuildingFloor?buildingID=" + props.buildingID + "&floorNumber=" + floors[localIndex].locationNumber +"&existingFloorID="+props.existingFloorID, {
                        method: "GET",
                    }).then(response => {
                        if (response.ok) { return response.json() }
                        else { throw response.json() }
                    }).then((result: boolean) => {
                        setValue(`floors.${localIndex}.floorNumberisUnique`, result)
                        trigger()
                    })
                        .catch(err => {
                            Promise.resolve(err).then(text => {
                                dispatch(setGlobalSnack({ open: true, severity: "error", message: text }))
                            })
                        })
                }
            })
            if (floorName == "" || floorName.startsWith("Floor")) {
                setValue(`floors.${props.index}.locationInstanceName`, "Floor " + floorNumber)
            }

            trigger("floors")
        }
    }
    else{
        didMount.current = true;
    }

    }, [floorNumber])
    return (
        <Box width="100%">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <StyledControlledFormInput 
                    key={floorNumber+" "+props.index}
                    onBlur={(e)=>{ props.fieldArrayMethods.update(props.index, {...floors[props.index], locationNumber:parseInt(e.target.value)})}} name={`floors.${props.index}.locationNumber`} type="number" label="Floor Number" />
                </Grid>
                <Grid item xs={props.index > 0 ? 7 : 8}>
                    <StyledControlledFormInput key={floorNumber+"name"+props.index} label="Floor Name (optional)" name={`floors.${props.index}.locationInstanceName`} />
                </Grid>
                {props.index > 0 &&
                    <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" >
                        <Box sx={{ cursor: "pointer" }} alignItems="center" display="flex" marginRight="0.3rem" width="3rem" onClick={() => props.fieldArrayMethods.remove(props.index)}>
                            <SvgIcon name={SvgNames.Delete} height={2} className={"icon-black"} />
                        </Box>
                    </Grid>}
            </Grid>
        </Box>
    )
}

