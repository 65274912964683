import { Button, Divider, Stack, Typography } from '@mui/material';
import { Fragment, useContext, useEffect, useState } from 'react';
import { SvgNames } from '../../Constants/app-enums';
import { FloatingActionBarContext } from '../../Shared/IndexTable/index-table-data-by-group';
import { SvgIcon } from '../../Utility/shared-components';
import { ApplicationState, IndexTableCheckboxes } from '../../ReduxRelatedUtils/redux-types';
import { useDispatch, useSelector } from 'react-redux';
import { clearCheckboxes, setCheckboxInfo } from '../../ReduxRelatedUtils/index-table-reducer';


export default function FloatingActionBar() {
    const floatingActionBarContext = useContext(FloatingActionBarContext)
    const {checkboxInfo }= useSelector<ApplicationState>(state=> state.indexTableCheckboxes) as IndexTableCheckboxes
    const [amountSelected, setAmountSelected] = useState(checkboxInfo.filter(c=>c.isChecked).length)
    const dispatch = useDispatch()
    useEffect(()=>{
        setAmountSelected(checkboxInfo.filter(c=>c.isChecked).length)
    }, [checkboxInfo])
    return (
              <Stack data-testid="floatingActionBar" direction="row" spacing={1} className='section-bg-color' sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 ,  bottom: '10%', position:'fixed', left: '50%',
              transform: 'translate(-50%, 0)', padding:'.6rem 1.25rem ', borderRadius:'.5rem' }}>
                   <Typography sx={{ color:'white', opacity:'.54', paddingRight:'2rem'}}>{amountSelected==1? amountSelected + " item selected" : amountSelected+" items selected"}</Typography>
                    {floatingActionBarContext.floatingActionBarState.actionBarLinks?.map((link, i) =>
                        <Fragment key={i}>
                            {i != 0 &&
                                <Divider sx={{ borderColor: "white", opacity:"0.54" }} key={"divider" +i} orientation="vertical" flexItem />
                            }
                           
                            <Button startIcon={ <SvgIcon color='white'  height={1.25} name={link.icon}/>    }  onClick={link.onClick} key={"link" + i} >                               
                           
                                <Typography  sx={{color:'white'}}>{link.title}</Typography>
                            
                          </Button>
              
                            
                        </Fragment>
                    )}
                       <Button sx={{opacity:'.54', paddingLeft:'2rem'}} variant='text' onClick={()=>{ 
                        floatingActionBarContext.setFloatingActionBarState({...floatingActionBarContext.floatingActionBarState, floatingActionBarIsVisible:false});
                        dispatch(clearCheckboxes())
                        }}><SvgIcon color="white" name={SvgNames.Clear} height={1} /></Button>
                        </Stack>                  
    )
}

