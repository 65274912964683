import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { push } from "connected-react-router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AnySchema, object, string } from "yup";
import { LoginResult } from "../Constants/app-enums";
import { ApplicationState } from "../ReduxRelatedUtils/redux-types";
import { setIsAuthenticated } from "../ReduxRelatedUtils/userInfoReducer";
import { LoginStyledInput } from "../Utility/custom-input-components";
import LoginPasswordInput from "../Utility/password-input";
import { objectToFormData } from "../Utility/root-function";
import { GlobalForm, LoginButton, LogoLayout } from "../Utility/shared-components";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { CustomError } from "../Utility/general-types";

export type LoginFormData = {
    Password: string,
    Email: string
}

type LoginFormProps = {
    onSubmit: (data: LoginFormData) => void
}
export type LoginResponse = {
    loginResult: LoginResult,
    code?: string,
    authenticatorUri?: string,
    email?: string
}
const validationSchema = object<Partial<Record<keyof LoginFormData, AnySchema>>>({
    Password: string()
        .required('Password field is required'),
    Email: string().required("Username field is required")
});

export default function Login(){

    const dispatch = useDispatch();

    function onSubmit(data: LoginFormData){
        dispatch(setLoading(true))
        fetch("Login/Login", {
            method: 'POST',
            body: objectToFormData(data)
        }).then((response) => {
            return response.json()
        })
            .then(result => {
                dispatch(setLoading(false))
                const loginResult = result as LoginResponse
                switch (loginResult.loginResult) {
                    case LoginResult.Succeeded:
                        dispatch(setIsAuthenticated(true));
                        break;
                    case LoginResult.RequiresTwoFactor:
                        dispatch(push("/Login2FA"))
                        break;
                    case LoginResult.NeedsToResetPassword:
                        dispatch(push("/ResetPassword?code=" + loginResult.code + "&authenticatorUri=" + encodeURIComponent(loginResult.authenticatorUri ?? "") + "&email=" + loginResult.email))
                        break;
                    case LoginResult.InvalidAttempt:
                        dispatch(setGlobalSnack({ open: true, message: "Invalid attempt", severity: "error" }))
                        break;
                    case LoginResult.Lockout:
                        dispatch(setGlobalSnack({ open: true, message: "User is locked out", severity: "error" }))
                        break;
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <LogoLayout>
            <>
                <LoginForm onSubmit={onSubmit} />
            </>
        </LogoLayout>
    );
};

export function LoginForm(props: LoginFormProps) {
    const methods = useForm({ defaultValues: { Email: "", Password: "", isSubmitting:false } as LoginFormData|any, resolver: yupResolver(validationSchema) });

    return (
        <GlobalForm methods={methods} formID="LoginForm" onSubmit={props.onSubmit}>
        <Stack spacing={2}  sx={{ padding: '0 1.094rem' }}>

            {process.env.NODE_ENV !== 'production' && <DevTool control={methods.control} />}
            <LoginStyledInput name="Email" placeholder="Username" />
            <LoginPasswordInput
                name="Password"   
                placeholder="Password"             
            />
            <LoginButton  form="LoginForm" variant="contained" type="submit">Log In</LoginButton>
        </Stack>
        </GlobalForm>
    )
}