import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, object, string } from 'yup';
import { ModalName } from '../Constants/app-enums';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput } from '../Utility/custom-input-components';
import { CustomError, LocationModalInfo } from '../Utility/general-types';
import { GlobalForm } from '../Utility/shared-components';
import { LocationInstance } from './locations-types';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';



export default function BuildingModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const [error, setError] = useState<CustomError>(new CustomError());
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>

    const validationSchema = object<Partial<Record<keyof LocationInstance, AnySchema>>>({
            locationInstanceName: string().required("Building name is required"),
            address : string().required("Building address is required")
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            locationInstanceID: modalInfo.locationID,
            locationInstanceName: "",
            address:""
        } as LocationInstance,
    }
    );

    useEffect(()=>{
        if(modalInfo.locationID ==undefined){
            dispatch(setModalLoading(false))
        }
        else{
            var url = "/Locations/BuildingModal?locationID=" + modalInfo.locationID;
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: LocationInstance) => {
                    methods.setValue("locationInstanceName", result.locationInstanceName);
                    methods.setValue("address", result.address);
                    dispatch(setModalLoading(false))
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        setError({ message: text.errorMessage, showBody: false })
                        dispatch(setModalLoading(false))
                    })
                })
        }
       
    },[modalInfo])

    function onSubmit(data: any, e: any) {
        var url = '/Locations/BuildingModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: { result: any }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message:modalInfo.locationID? "Building Updated" : "Building Added", severity: "success" }))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }

    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={modalInfo.locationID == undefined ?"Add Building" : "Edit Building"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddBuilding} onSubmit={onSubmit} methods={methods}>
                                <Stack spacing={2} alignItems="center">
                                    <StyledControlledFormInput name="locationInstanceName" label="Building Name"/>
                                    <StyledControlledFormInput name="address" label="Building Address"/>
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddBuilding} />
                    </>
                    }
                </>
            }
        </>
    )
}
