import { createSlice } from '@reduxjs/toolkit'
import { CustomError } from '../Utility/general-types'

const errorSlice = createSlice({
    name: 'error',
    initialState:{message:"", showBody:true} as CustomError,
    reducers: {
        setError(state, action:{payload:CustomError}) {
          return action.payload
        },
        setErrorMessage(state, action:{payload:string}) {
            state.message =  action.payload
        },
         
    }
})

export const { setError, setErrorMessage } = errorSlice.actions
export default errorSlice.reducer