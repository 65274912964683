import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, number, object, string } from 'yup';
import { ModalName } from '../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal, NavigationInfo } from '../ReduxRelatedUtils/redux-types';
import { setLastSelectedLocation, setLastSelectedLocationReload } from '../ReduxRelatedUtils/selectedLocationReducer';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, AutocompleteOption, CustomError,  SelectListItem } from '../Utility/general-types';
import { GlobalForm, SpacedColumnStack, SpacedRowStack } from '../Utility/shared-components';
import { Lab, LocationInstance } from './locations-types';

export type MoveStorageUnitViewModel = {
    buildingID: number,
    buildings: AutocompleteOption[],
    floors: AutocompleteOption[],
    rooms: AutocompleteOption[],
    roomID: number,
    floorID: number,
    locationInstance: LocationInstance,
}
export type MoveStorageUnitFormData = {
    locationInstanceID: number,
    buildingID?: number,
    floorID?: number,
    roomID?: number
}

export default function MoveStorageUnitModal() {
    const dispatch = useDispatch();
    const [viewModel, setViewModel] = useState<MoveStorageUnitViewModel>({} as MoveStorageUnitViewModel)
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [error, setError] = useState<CustomError>(new CustomError());
    const { sidebarType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo
    const didMount = useRef(false)


    const validationSchema = object<Partial<Record<keyof MoveStorageUnitFormData, AnySchema>>>({
        roomID: number().typeError('Room is required').moreThan(0, 'Room is required').required('Room is required'),
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            locationInstanceID: modalInfo.locationID,
        } as MoveStorageUnitFormData,
    }
    );

    const buildingID = methods.watch("buildingID")
    const floorID = methods.watch("floorID")

    useEffect(() => {

        var url = "/Locations/MoveStorageUnitModal?locationInstanceID=" + modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: MoveStorageUnitViewModel) => {
                setViewModel(result);
                methods.setValue("roomID", result.roomID)
                methods.setValue("floorID", result.floorID)
                methods.setValue("buildingID", result.buildingID)


                dispatch(setModalLoading(false))
                didMount.current = true
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);

    function onSubmit(data: any, e: any) {
        var url = '/Locations/MoveStorageUnitModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: { result: any }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: "Location Moved", severity: "success" }))
                    dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }

    useEffect(() => {
        console.log(buildingID)
        if (didMount.current && buildingID!= undefined) {
            methods.setValue("floorID", undefined)
            methods.setValue("roomID", undefined)
            fetch("/Locations/GetFloorsByBuilding?buildingID=" + buildingID, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: AutocompleteOption[]) => {
                    setViewModel({ ...viewModel, floors: result, rooms: [] as AutocompleteOption[] })

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setError({ message: text.toString(), showBody: false }))
                    })
                })
        }

    }, [buildingID])

    useEffect(() => {
        if (didMount.current && floorID!= undefined) {
            methods.setValue("roomID", undefined)
            fetch("/Locations/GetRoomsByFloor?floorID=" + floorID, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: AutocompleteOption[]) => {
                    setViewModel({ ...viewModel, rooms: result })

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setError({ message: text.toString(), showBody: false }))
                    })
                })
        }

    }, [floorID])


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Move " + viewModel.locationInstance?.locationInstanceName} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddRoom} onSubmit={onSubmit} methods={methods}>
                                <SpacedColumnStack alignItems="center">
                                    <SpacedRowStack width="100%">
                                        <StyledControlledAutoCompleteInput name="buildingID" label="Building" disableClearable 
                                            options={viewModel.buildings ?? [] as AutocompleteOption[]}
                                        />
                                        <StyledControlledAutoCompleteInput name="floorID" label="Floor" disableClearable
                                            options={viewModel.floors ?? [] as AutocompleteOption[]}
                                        />
                                        <StyledControlledAutoCompleteInput name="roomID" label="Room" 
                                            options={viewModel.rooms ?? [] as AutocompleteOption[]}
                                        />

                                    </SpacedRowStack>
                                </SpacedColumnStack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddRoom} />
                    </>
                    }
                </>
            }
        </>
    )
}
