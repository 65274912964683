import { Autocomplete, Box, Button, Checkbox, CheckboxProps, Chip, FormControl, InputAdornment, InputLabel, MenuItem, Popper, Radio, RadioGroup, RadioGroupProps, RadioProps, Select, SelectProps, TextField, TextFieldProps } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useController, useFormContext } from "react-hook-form";
import NumberFormat from 'react-number-format';
import { batch, useDispatch, useSelector } from "react-redux";
import { validate } from "uuid";
import { FolderNamesEnum, ModalFlow, ModalName, SvgNames } from "../Constants/app-enums";
import { setSearchText } from "../ReduxRelatedUtils/index-table-reducer";
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../ReduxRelatedUtils/redux-types";
import { AutocompleteOption, DocInfoModalInfo, SelectListItem } from "./general-types";
import { IsEdittableContext, SectionCheckbox, StyledError, SvgIcon } from "./shared-components";
import Theme from "./theme";

export function StyledFormInputTitle(props: TextFieldProps & { readOnly?: boolean }) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <>
            <FormControl >
                <TextField
                    {...field}
                    variant="standard"
                    InputProps={{ // <-- This is where the toggle button is added.
                        inputProps: { ...{ readOnly: props.readOnly }, ...readOnly() ?? {} },
                        className: isEdittableContext.isEdittable == false || props.readOnly == true ? "read-only-input" : "section-product-name",
                        sx: {
                            fontSize: '1.75rem', fontWeight: '500',
                            border: "none !important",
                            "& input::placeholder": { opacity: "0.5 !important" }
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                {error && <SvgIcon name={SvgNames.CentarixNotificationDidntArrive} height={1.5} color={Theme.palette.error.main} />}
                            </InputAdornment>
                        )

                    }}
                    {...props}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>);
}

export function LoginStyledInput(props: TextFieldProps) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    return (
        <>
            <FormControl >
                <TextField
                    {...field}
                    InputProps={{ // <-- This is where the toggle button is added.
                        className: "login-styled-outline-input"

                    }}
                    {...props}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>);
}

// export function StyledReadOnlyFormInput(props: TextFieldProps) {

//     return (<>
//         <FormControl fullWidth variant="standard"   >

//             <TextField
//                 variant="standard"
//                 InputLabelProps={{ shrink: true }}
//                 label={props.label}
//                 placeholder={props.placeholder}
//                 InputProps={{ // <-- This is where the toggle button is added.
//                     sx: { padding: "0.3rem" },
//                     readOnly: true,
//                 }}
//                 {...props}
//             />


//         </FormControl>
//     </>
//     )
// }

export function StyledFormInput(props: TextFieldProps & { readOnly?: boolean }) {
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (<>
        <FormControl fullWidth variant="standard"   >

            <TextField
                variant="standard"
                InputLabelProps={{ shrink: true }}
                label={props.label}
                placeholder={props.placeholder}
                InputProps={{ // <-- This is where the toggle button is added.
                    inputProps: { ...readOnly() ?? {}, ...{ readOnly: props.readOnly } },
                    className: isEdittableContext.isEdittable == false ? "read-only-input" : "section-input",
                    sx: {
                        paddingRight: props.type == "number" ? "0.2rem !important" : "0.8rem",
                        marginTop: "1.3rem !important"
                    },
                }}
                {...props}
            />


        </FormControl>
    </>
    )
}




export function StyledControlledRadioGroup(props: RadioGroupProps) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });

    return (

        <FormControl fullWidth variant="standard">
            <RadioGroup
                {...field}
                {...props}
                row>
                {props.children}
            </RadioGroup>
        </FormControl>

    );
};



export function StyledControlledRadioButton(props: RadioProps) {


    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { disabled: true } : null
    }
    return (
        <Radio inputProps={{ ...readOnly() }} {...props} />
    ) as React.ReactElement<any, any>;
};


export function StyledControlledFormInput(props: TextFieldProps & { readOnly?: boolean }) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });

    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <>
            <FormControl fullWidth variant="standard">

                <TextField
                    {...field}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label={props.label}
                    placeholder={props.placeholder}
                    InputProps={{ // <-- This is where the toggle button is added.
                        inputProps: { ...{ readOnly: props.readOnly }, ...readOnly() ?? {} },
                        className: isEdittableContext.isEdittable == false || props.readOnly ? "read-only-input" : "section-input",
                        sx: {
                            paddingRight: props.type == "number" ? "0.2rem !important" : "0.8rem",
                            marginTop: "1.3rem !important"
                        },
                        endAdornment: (
                            error && <InputAdornment position="end">
                                <SvgIcon name={SvgNames.CentarixNotificationDidntArrive} height={1.5} color={Theme.palette.error.main} />
                            </InputAdornment>)
                    }}
                    {...props}
                    value={(props.type == "number" && (Number.isNaN(field.value)) ? "" : field.value)}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
};


export function StyledControlledFormInputWithDocInfo(props: TextFieldProps & { readOnly?: boolean, folderName: FolderNamesEnum, infoID: number, editDrawerID: string }) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const dispatch = useDispatch();
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <>
            <FormControl fullWidth variant="standard">

                <TextField
                    {...field}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label={props.label}
                    placeholder={props.placeholder}
                    InputProps={{ // <-- This is where the toggle button is added.
                        inputProps: { ...{ readOnly: props.readOnly }, ...readOnly() ?? {} },
                        className: isEdittableContext.isEdittable == false || props.readOnly ? "read-only-input" : "section-input",
                        sx: {
                            paddingRight: props.type == "number" ? "0.2rem !important" : "0.8rem",
                            marginTop: "1.3rem !important"
                        },
                        endAdornment: (<InputAdornment position="end" onClick={() => {
                            batch(() => {
                                dispatch(setModal({ modalFlow: ModalFlow.DocModalInfo, modalInfo: { folderName: props.folderName, infoID: props.infoID, editDrawerID: props.editDrawerID } as DocInfoModalInfo, modalStep: ModalName.DocModalInfo } as Modal<DocInfoModalInfo>))
                                dispatch(setModalLoading(true))
                            })
                        }}>
                            <SvgIcon name={SvgNames.StorageSelected} height={1.5} className="section-fill" />
                        </InputAdornment>)
                    }}
                    {...props}
                    value={(props.type == "number" && (Number.isNaN(field.value)) ? "" : field.value)}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
};


export function StyledControlledUrlFormInput(props: TextFieldProps) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const [url, setUrl] = useState(field.value);
    useEffect(() => {
        if (field.value?.trim() != "" && !field.value?.match(/^[a-zA-Z]+:\/\//)) {
            setUrl('http://' + field.value?.toString());
        }
        else {
            setUrl(field.value)
        }
        if (field.value == undefined) {
            setUrl("")
        }
    }, [field.value])

    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <>
            <FormControl fullWidth variant="standard">
                <TextField
                    {...field}
                    value={field.value ?? ""}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label={props.label}
                    placeholder={props.placeholder}
                    InputProps={{
                        className: isEdittableContext.isEdittable == false ? "read-only-input" : "section-input",
                        inputProps: { ...readOnly() ?? {} },
                        sx: { paddingRight: "0.8rem", marginTop: "1.3rem !important" },
                        endAdornment: (
                            <InputAdornment position="end">
                                {url && url?.trim() != "" ?
                                    <a style={{ display: 'flex' }} target="_blank" href={url}>
                                        <SvgIcon name={SvgNames.CentarixBrokenLink} height={1.5} />
                                    </a> : null}
                            </InputAdornment>)
                    }}
                    {...props}
                >
                </TextField>
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
};




export function StyledControlledFormDateInput(props: TextFieldProps & { minDate?: any, maxDate?: any }) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const { setValue } = useFormContext();
    function onDateChange(e: any) {
        console.log("date change")
        if (props.onChange != undefined) props.onChange(e);
        else {
            setValue(props.name ?? "", moment(e).format("YYYY-MM-DD"))
        }
    }

    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <FormControl fullWidth variant="standard">

            <LocalizationProvider dateAdapter={AdapterMoment} >
                <DatePicker
                    {...readOnly()}
                    //{...field}
                    label={props.label}
                    inputFormat="DD MMM yyyy"
                    disableMaskedInput
                    InputProps={{
                        className: isEdittableContext.isEdittable == false ? "read-only-input" : "section-input",
                        name: props.name,
                        sx: { marginTop: "1.3rem !important" },
                        defaultValue: "dd mmm yyyy"
                    }}
                    onChange={(e) => onDateChange(e)}
                    renderInput={(params) =>
                        <TextField
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            {...params}
                        />
                    }

                    value={field.value}
                    minDate={props.minDate ? moment(props.minDate) : undefined}
                    maxDate={props.maxDate ? moment(props.maxDate) : undefined}
                    disabled={props.disabled}
                />
            </LocalizationProvider>
            <StyledError>{error?.message}</StyledError>
        </FormControl>
    );
};

export function StyledCurrencyFormInput(props: TextFieldProps & { currency: '$' | '₪', testid?: string }) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    //console.log(props.name+": "+props.value+"/"+field.value)
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <FormControl fullWidth variant="standard">
            <NumberFormat
                displayType="input"
                allowNegative={false}
                mask="_"
                InputProps={{
                    inputProps: {
                        ... { sx: { paddingX: "0" } },
                        ...readOnly() ?? {}
                    },
                    className: isEdittableContext.isEdittable == false ? "read-only-input" : "section-input",
                    sx: {
                        paddingX: "0.5rem", marginTop: "1.3rem !important",
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        }
                    },
                    startAdornment: (
                        <InputAdornment sx={{ "& .MuiTypography-root": { color: (props.disabled ? Theme.palette.grey[400] : Theme.palette.primary.main) + " !important" }, maxWidth: ".4rem", justifyContent: "center" }} position="start">{props.currency}</InputAdornment>
                    ),
                    endAdornment: (
                        error && <InputAdornment position="end">
                            <SvgIcon name={SvgNames.CentarixNotificationDidntArrive} height={1.5} color={Theme.palette.error.main} />
                        </InputAdornment>)

                }}
                customInput={TextField}
                variant="standard"
                sx={{ alignContent: "center" }}
                InputLabelProps={{ shrink: true }}
                min={0}
                decimalScale={2}
                fixedDecimalScale
                label={props.label}
                onChange={(e: any) => props.onChange != undefined ? props.onChange(e) : field.onChange(e.target.value)}
                value={(field.value ?? props.value) == 0 ? "" : (field.value ?? props.value)}
                name={props.name}
                disabled={props.disabled}
                data-testid={props.testid ?? ""}
            />
            <StyledError>{error?.message}</StyledError>
        </FormControl>
    );
};

export function StyledPercentFormInput(props: TextFieldProps) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }

    return (
        <FormControl fullWidth variant="standard">
            <NumberFormat
                displayType="input"
                mask="_"
                InputProps={{
                    inputProps: {
                        ... { sx: { paddingX: "0" } },
                        ...readOnly() ?? {}
                    },
                    className: isEdittableContext.isEdittable == false ? "read-only-input" : "section-input",
                    sx: {
                        paddingX: "0.5rem", marginTop: "1.3rem !important",
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        }
                    },
                    endAdornment: (
                        <InputAdornment sx={{ "& .MuiTypography-root": { color: (props.disabled ? Theme.palette.grey[400] : Theme.palette.primary.main) + " !important" }, maxWidth: ".4rem", justifyContent: "center" }} position="start">%</InputAdornment>
                    )

                }}
                decimalScale={3}
                //fixedDecimalScale
                customInput={TextField}
                variant="standard"
                sx={{ alignContent: "center" }}
                InputLabelProps={{ shrink: true }}
                min={0}
                label={props.label}
                onChange={(e: any) => props.onChange != undefined ? props.onChange(e) : field.onChange(e.target.value)}
                value={(field.value ?? props.value) == 0 ? "" : (field.value ?? props.value)}
                name={props.name}
                disabled={props.disabled}
            />
            <StyledError>{error?.message}</StyledError>
        </FormControl>
    );
};
export function StyledControlledFreeSoloAutoCompleteInput(props: TextFieldProps & { options: AutocompleteOption[] }) {
    const form = useFormContext();
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const { isEdittable } = useContext(IsEdittableContext);

    function readOnly() {
        return isEdittable == false ? { readOnly: true } : null
    }

    return (<>
        <FormControl fullWidth variant="standard">
            <Autocomplete
                disablePortal
                fullWidth
                freeSolo
                {...readOnly()}
                options={props.options.length > 0 ? props.options : [{ text: "", value: 0 } as AutocompleteOption]}
                disabled={props.disabled}
                id={props.name}
                {...field}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '20rem',
                        }
                    }
                }
                popupIcon={<Box alignItems="center" display="flex" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                getOptionLabel={option => {
                    let label = option?.text ?? props.options?.find(o => o.value?.toString() == option.toString())?.text ?? ""
                    return label
                }}

                filterSelectedOptions
                onChange={(event, item) => { props.onChange != undefined ? props.onChange(item) : field.onChange(item?.value) }}
                renderInput={(params) => <TextField
                    {...params}
                    {...props}
                    placeholder={props.placeholder}
                    variant='standard'
                    InputLabelProps={{ shrink: true }}
                    InputProps={
                        {
                            ...params.InputProps,

                            className: isEdittable != true ? "read-only-input" : "section-input",
                            sx: {
                                display: "inline-block",
                                paddingTop: "0",
                                marginTop: "1.3rem !important",
                                "& .MuiAutocomplete-endAdornment": { marginRight: "0.3rem", top: "0", height: "100%", display: "flex", alignItems: "center" }
                            }
                        }
                    }
                />}
            />
            <StyledError>{error?.message}</StyledError>
        </FormControl>

    </>)
}
export function StyledControlledAutoCompleteInput(props: TextFieldProps & { options: AutocompleteOption[], disableClearable?: boolean }) {
    const form = useFormContext();
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const methods = useFormContext();
    const updatedField = methods.watch(props.name ?? "");
    const { isEdittable } = useContext(IsEdittableContext);

    function readOnly() {
        return isEdittable == false ? { readOnly: true } : null
    }
    const dontFillDidMount = useRef(false);
    const listLengthDidMount = useRef(false);
    const [dontFill, setDontFill] = useState(false);
    const [options, setOptions] = useState("");


    useEffect(() => {
        if (dontFill == false) {
            if (dontFillDidMount.current) {
                if (field.value == undefined || field.value == 0) {
                    if (props.options?.length == 1) {
                        form.setValue(props.name ?? "", props.options[0].value)
                    }
                }
            }
            else {
                dontFillDidMount.current = true;
            }

            //this prevents infinite loop
            setDontFill(true);
        }

    }, [dontFill])

    useEffect(() => {
        if (listLengthDidMount.current) {
            if (field.value == undefined || field.value == 0) {
                if (props.options.length == 1) {
                    setDontFill(false);
                }
            }
        }
        else {
            listLengthDidMount.current = true;
        }
    }, [options])

    useEffect(() => {
        if (options != JSON.stringify(props.options)) {

            setOptions(JSON.stringify(props.options))

        }
    }, [props.options])


    return (<>
        <FormControl fullWidth variant="standard" >
            <Autocomplete
                disablePortal
                fullWidth
                disableClearable={props.disableClearable}
                {...readOnly()}
                options={props.options.length > 0 ? props.options : [{ text: "", value: 0 } as AutocompleteOption]}
                disabled={props.disabled}
                getOptionDisabled={(option) => { return option.disabled ?? false }}
                id={props.name}
                filterOptions={(options,value) => options.filter(o => o.hidden != true
                    && o.text?.toLowerCase()?.trim().includes(value?.inputValue?.toLowerCase()?.trim()))}

                {...field}
                isOptionEqualToValue={(option, value) => {
                    return option.value.toString() === value?.toString()
                }}
                value={updatedField}
                ListboxProps={
                    {
                        style: {
                            maxHeight: '20rem',
                        }
                    }
                }
                
                PopperComponent={(props) => { return (<Popper  {...props} placement="bottom" disablePortal={true} popperOptions={{ strategy: 'fixed' }} />) }}
                popupIcon={<Box alignItems="center" display="flex" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                getOptionLabel={option => {
                    return option?.text ?? props.options?.find(o => o.value?.toString() == option.toString())?.text ?? ""
                }}

                filterSelectedOptions
                onChange={(event, item) => {props.onChange != undefined ? props.onChange(item?.value) : field.onChange(item?.value) }}

                renderInput={(params) => {
                    return <TextField
                        {...params}
                        {...props}
                        // inputProps={{
                        //     ...params.inputProps,
                        //     value: props.options?.find(o => { return o.value?.toString() == updatedField?.toString() })?.text ?? "",
                        // }}
                        variant='standard'
                        InputLabelProps={{ shrink: true }}
                        InputProps={
                            {
                                ...params.InputProps,
                                placeholder: props.placeholder,

                                className: isEdittable != true ? "read-only-input" : "section-input",
                                sx: {
                                    display: "inline-block",
                                    paddingTop: "0",
                                    marginTop: "1.3rem !important",
                                    "& .MuiAutocomplete-endAdornment": { marginRight: "0.3rem", top: "0", height: "100%", display: "flex", alignItems: "center" }
                                }
                            }
                        }
                    />
                }}
            />
            <StyledError>{error?.message}</StyledError>
        </FormControl>

    </>)
}
export function StyledSectionAutoCompleteInput(props: TextFieldProps & { options: AutocompleteOption[] }) {
    const { isEdittable } = useContext(IsEdittableContext);

    function readOnly() {
        return isEdittable == false ? { readOnly: true } : null
    }

    return (<>
        <FormControl fullWidth variant="standard">
            <Autocomplete
                //disablePortal
                fullWidth
                sx={{ zIndex: 9999 }}
                {...readOnly()}
                getOptionDisabled={(option) => { return option.disabled ?? false }}

                options={props.options.length > 0 ? props.options : [{ text: "", value: 0 } as AutocompleteOption]}
                id={props.name}
                value={props.value as AutocompleteOption}
                filterOptions={(options,value) => options.filter(o => o.hidden != true
                    && o.text?.toLowerCase()?.trim().includes(value?.inputValue?.toLowerCase()?.trim()))}

                ListboxProps={
                    {
                        style: {
                            maxHeight: '20rem',
                        }
                    }
                }
                popupIcon={<Box alignItems="center" display="flex" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                getOptionLabel={option => {
                    return option?.text ?? props.options?.find(o => o.value?.toString() == option.toString())?.text ?? ""
                }}
                onChange={(e: any, value: any) => { return props.onChange != undefined ? props.onChange(value) : null }}
                filterSelectedOptions
                renderInput={(params) => <TextField
                    {...params}
                    {...props}
                    placeholder={props.placeholder}
                    variant='standard'
                    InputLabelProps={{ shrink: true }}
                    InputProps={
                        {
                            ...params.InputProps,

                            className: isEdittable != true ? "read-only-input" : "section-input",
                            sx: {
                                display: "inline-block",
                                paddingTop: "0",
                                marginTop: "1.3rem !important",
                                "& .MuiAutocomplete-endAdornment": { marginRight: "0.3rem", top: "0", height: "100%", display: "flex", alignItems: "center" }
                            }
                        }
                    }
                />}
            />
        </FormControl>

    </>)
}


export function StyledControlledSelectInput(props: SelectProps) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    //console.log(props.name + " " + field.value)
    return (
        <>
            <FormControl fullWidth variant="standard">
                <InputLabel id={props.name} disableAnimation shrink>{props.label}</InputLabel>
                <Select
                    {...readOnly()}
                    labelId={props.name}
                    {...field}
                    IconComponent={({ ...props }) => <Box  {...props} alignItems="center" display="flex" marginRight="0.3rem" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                    MenuProps={{
                        MenuListProps: {
                            sx: { paddingY: "0px" }
                        },
                        PaperProps: {
                            sx: {
                                boxShadow: "0px 3px 8px rgb(218 218 218)",
                                marginTop: "0.1rem",
                                maxHeight: "15rem"
                            }
                        }
                    }}
                    displayEmpty className={`${isEdittableContext.isEdittable == false ? "read-only-input" : "section-select"} ${props.value == 0 && "placeholder"}`} sx={{ marginTop: "1.3rem !important", paddingRight: "0.5rem" }} {...props}>
                    <MenuItem disabled value={0}>{props.placeholder}</MenuItem>
                    {props.children}
                </Select>
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>


    );
}
export function TableSelectInput(props: SelectProps) {

    //console.log(props.name + " " + field.value)
    return (
        <FormControl fullWidth variant="standard">
            <Select
                IconComponent={({ ...props }) => <Box  {...props} alignItems="center" display="flex" marginRight="0.3rem" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                MenuProps={{
                    MenuListProps: {
                        sx: { paddingY: "0px" }
                    },
                    PaperProps: {
                        sx: {
                            boxShadow: "0px 3px 8px rgb(218 218 218)",
                            marginTop: "0.1rem",
                            maxHeight: "15rem"
                        }
                    },

                }}
                displayEmpty className={"section-select"} sx={{ paddingRight: "0.5rem" }} {...props}>
                <MenuItem disabled value={0}>{props.placeholder}</MenuItem>
                {props.children}
            </Select>
        </FormControl>



    );
}
export function StyledSelectInput(props: SelectProps) {
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    //console.log(props.name + " " + field.value)
    return (
        <>
            <FormControl fullWidth variant="standard">
                <InputLabel id={props.name} disableAnimation shrink>{props.label}</InputLabel>
                <Select

                    {...readOnly()}
                    {...props}
                    labelId={props.name}
                    IconComponent={({ ...props }) => <Box  {...props} alignItems="center" display="flex" marginRight="0.3rem" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                    MenuProps={{
                        MenuListProps: {
                            sx: { paddingY: "0px" }
                        },
                        PaperProps: {
                            sx: {
                                boxShadow: "0px 3px 8px rgb(218 218 218)",
                                marginTop: "0.1rem",
                                maxHeight: "15rem"
                            }
                        }
                    }}
                    displayEmpty className={`${isEdittableContext.isEdittable == false ? "read-only-input" : "section-select"} ${props.value == 0 && "placeholder"}`} sx={{ marginTop: "1.3rem !important", paddingRight: "0.5rem" }} {...props}>
                    <MenuItem disabled value={0}>{props.placeholder}</MenuItem>
                    {props.children}
                </Select>
            </FormControl>

        </>


    );
}
export function StyledSectionSelectInput(props: SelectProps) {
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    //console.log(props.name + " " + field.value)
    return (
        <>
            <FormControl fullWidth variant="standard">
                <InputLabel id={props.name} disableAnimation shrink>{props.label}</InputLabel>
                <Select

                    {...readOnly()}
                    {...props}
                    labelId={props.name}
                    IconComponent={({ ...props }) => <Box  {...props} alignItems="center" display="flex" marginRight="0.3rem" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                    MenuProps={{
                        MenuListProps: {
                            sx: { paddingY: "0px" }
                        },
                        PaperProps: {
                            sx: {
                                boxShadow: "0px 3px 8px rgb(218 218 218)",
                                marginTop: "0.1rem",
                                maxHeight: "15rem"
                            }
                        }
                    }}
                    displayEmpty className={`${isEdittableContext.isEdittable == false ? "read-only-input" : "section-select section-border section-color"} ${props.value == 0 && "placeholder"}`} sx={{ marginTop: "1.3rem !important", paddingRight: "0.5rem" }} {...props}>
                    <MenuItem disabled value={0}>{props.placeholder}</MenuItem>
                    {props.children}
                </Select>
            </FormControl>

        </>


    );
}
export function StyledControlledMultipleSelect(props: TextFieldProps & { options: { text: string, value: string | number }[] }) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const { isEdittable } = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittable == false ? { readOnly: true } : null
    }
    return (
        <FormControl fullWidth variant="standard">
            <Autocomplete
                multiple
                {...field}
                {...readOnly()}
                PopperComponent={(props) => { return (<Popper {...props} placement="bottom" popperOptions={{ strategy: 'fixed' }} />) }}
                popupIcon={<Box alignItems="center" display="flex" width="0.8rem"><SvgIcon name={SvgNames.CentarixDropDownExpand} height={.8} className={"icon-black"} /></Box>}
                onChange={(event, item) => { field.onChange(item) }}
                options={props.options}
                isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                }}
                filterOptions={(options,value) => options.filter(o => o.hidden != true
                    && o.text?.toLowerCase()?.trim().includes(value?.inputValue?.toLowerCase()?.trim()))}

                getOptionLabel={(option) => option.text}
                filterSelectedOptions
                limitTags={2}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...props}
                        variant='standard'
                        InputLabelProps={{ shrink: true }}
                        InputProps={
                            {
                                ...params.InputProps,
                                className: isEdittable != true ? "read-only-input" : "section-input",
                                sx: {
                                    display: "inline-block",
                                    paddingTop: "0",
                                    marginTop: "1.3rem !important",
                                    "& input": { width: "25%", display: "inline" },
                                    "& .MuiAutocomplete-endAdornment": { marginRight: "0.3rem", top: "0", height: "100%" }
                                }
                            }
                        }
                    />
                )}
            />
            <StyledError>{error?.message}</StyledError>

        </FormControl>
    )
}

export function StyledControlledFreeSoloMultipleSelect(props: TextFieldProps & { options: SelectListItem[] }) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <FormControl fullWidth variant="standard">
            <Autocomplete
                multiple
                {...field}
                freeSolo
                onChange={(event, item) => { field.onChange(item) }}
                options={props.options}
                getOptionLabel={(option) => option.text}
                filterSelectedOptions
                limitTags={2}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option: SelectListItem, index) => (
                        <Chip
                            label={option.text}
                            {...getTagProps({ index })}
                            disabled={option.disabled}
                        />
                    ))
                }
                isOptionEqualToValue={(option, value) => {
                    return option.value === value.value
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...props}
                        variant='standard'
                        InputLabelProps={{ shrink: true }}
                        InputProps={
                            {
                                ...params.InputProps,
                                className: "section-input",
                                sx: {
                                    display: "inline-block",
                                    paddingTop: "0",
                                    marginTop: "1.3rem !important",
                                    "& input": { width: "25%", display: "inline" },
                                }
                            }
                        }
                    />
                )}
            />
            <StyledError>{error?.message}</StyledError>

        </FormControl>
    )
}

export function ControlledCheckbox(props: CheckboxProps) {
    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const isEdittableContext = useContext(IsEdittableContext);
    return (
        <Checkbox  {...field} checked={field.value == true} {...props} disabled={!isEdittableContext.isEdittable || props.disabled} size="small" className='checkbox-section-color' />
    )
};


