import { Button, Grid, Tooltip, Typography , Box, Stack} from "@mui/material"
import { useState, useEffect } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { LocationTypeName, MenuItemEnum, ModalFlow, ModalName, SidebarEnum, SvgNames } from "../Constants/app-enums"
import { LastSelectedLocation, Modal } from "../ReduxRelatedUtils/redux-types"
import { setLastSelectedLocation } from "../ReduxRelatedUtils/selectedLocationReducer"
import { SmallTypography, SvgIcon, TinyTypography } from "../Utility/shared-components"
import Theme from "../Utility/theme"
import { LocationCardViewModel } from "./locations-types"
import { setModalLoading, setModal } from "../ReduxRelatedUtils/modals-reducer"
import {LocationModalInfo } from "../Utility/general-types"
import GlobalPopover from "../Utility/global-popover"
import { getDisplayNameOfEnumValue } from "../Utility/root-function"
import { IconViewModel } from "../Utility/request-types"
import { setError } from "../ReduxRelatedUtils/errorReducer"
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer"
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore"


export default function LocationCard(props: { cardInfo: LocationCardViewModel, locationType: LocationTypeName, columnIndex: number, parentLocationID: number }) {
    const [showArrow, setShowArrow] = useState(false)
    const dispatch = useDispatch()
    const {sectionType, sidebarType} = useSelector(selectNavigationInfo)
    const [locationActions, setLocationActions] = useState<IconViewModel[]>([])
    useEffect(() => {
        setShowArrow(props.cardInfo.selected)
    }, [props.cardInfo.selected])



    useEffect(() => {
        if(sectionType ==MenuItemEnum.LabManagement &&  props.cardInfo.id!=undefined ){
            fetch("/Locations/GetLocationActions?locationInstanceID=" + props.cardInfo.id+"&sidebarType="+sidebarType+ "&isLab="+ (sidebarType == SidebarEnum.Labs && props.columnIndex == 0), {
                method: "GET"
            }) 
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: IconViewModel[]) => {           
                        setLocationActions(result);
                    
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        console.log(text)
                        dispatch(setGlobalSnack({ message:"Could not get actions for "+ props.cardInfo.title, severity: "warning", open:true }))
                    })
                })
        }
    }, [props.cardInfo.id])

    const cardRows = props.cardInfo.subTitle?
     ((props.cardInfo.hasNoRacks || props.cardInfo.isBlocked) ? 3 : 2 ) :
     ( (props.cardInfo.hasNoRacks || props.cardInfo.isBlocked)  ? 2 : 1)

    return (
        <Box
            overflow="hidden"
            onMouseEnter={() => setShowArrow(true)}
            onMouseLeave={() => { if (!props.cardInfo.selected) setShowArrow(false) }}
            padding="1rem" textAlign="left" sx={{ backgroundColor: Theme.palette.background.default}} maxHeight={"7rem"} height="7rem" borderBottom={`1px solid ${Theme.palette.grey[300]}`} className={props.cardInfo.selected ? "section-selected-side-border" : ""}>
            <Stack direction="row" justifyContent="space-between" height={"100%"} >
                <Stack maxWidth={"80%"} spacing={0.5}>
                    <div>
                    <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={cardRows >2 ? "nowrap": "normal"} lineHeight={"1.25"} fontSize="1.25rem" fontWeight="400" color={props.cardInfo.isBlocked ? Theme.palette.grey[400] : Theme.palette.primary.main}>{props.cardInfo.title}</Typography>
                    </div>
                    {props.cardInfo.subTitle && <SmallTypography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={cardRows >2 ? "nowrap": "normal"} color={props.cardInfo.isBlocked ? Theme.palette.grey[300] :Theme.palette.grey[400]}>{ props.cardInfo.subTitle}</SmallTypography>}
                    {props.cardInfo.hasNoRacks && 
                        <Stack direction="row" paddingTop={"1rem"} spacing={0.5} alignContent={"center"}>
                            <SvgIcon name={SvgNames.CancelPresentation} height={1} className={"icon-gray"} />
                            <TinyTypography color={Theme.palette.grey[400] }>{"No Racks"}</TinyTypography>
                        </Stack>
                    }
                    {props.cardInfo.isBlockedRoot && 
                        <Stack direction="row" /* paddingTop={"1rem"} */ spacing={0.5} alignContent={"center"}>
                            <SvgIcon name={SvgNames.BlockNew} height={1} className={"icon-red"} />
                            <TinyTypography color={Theme.palette.grey[400]}>{"Blocked for use"}</TinyTypography>
                        </Stack>
                    }
                </Stack>
                <Stack height="100%" flexGrow={1}>
                    <Box height={"60%"} width="100%" display={"flex"} flexDirection={"row"} justifyContent={"end"}>
                        {showArrow && <Box sx={{cursor: "pointer" }} onClick={() => dispatch(setLastSelectedLocation({ parentLocationID: props.cardInfo.id, locationType: props.locationType, columnIndex: props.columnIndex } as LastSelectedLocation))} >
                            <SvgIcon name={SvgNames.StorageSelected} className="section-fill" height={2}/> </Box>}
                        </Box>
                    {locationActions?.length > 0 &&
                    <Box height={"40%"} width="100%" display={"flex"} flexDirection={"row"} justifyContent={"end"}>
                        <GlobalPopover closeOnClick={true} key={"icons"}  >
                            <Box textAlign={"end"} width="1rem"><SvgIcon name={SvgNames.StorageOptions} className="icon-gray" height={1.3} /></Box>
                            <Grid container direction="column" width="13rem">
                                <Grid container direction="column" padding="0.5rem 1rem" >
                                    {locationActions?.map((action, i) => (
                                        <Grid item key={"action" + i}>
                                            {action.disabled?
                                            <Tooltip  title={action.disabledMessage??""} arrow >
                                                <div>
                                                    <LocationIconButtons action={action} cardInfo={props.cardInfo} columnIndex={props.columnIndex} parentLocationID={props.parentLocationID}/>
                                                 </div>
                                            </Tooltip>:
                                            <LocationIconButtons action={action} cardInfo={props.cardInfo} columnIndex={props.columnIndex} parentLocationID={props.parentLocationID}/>}
                                        </Grid>))}
                                </Grid>
                            </Grid>
                        </GlobalPopover>
                        </Box>
                    }
                </Stack>
            </Stack>

        </Box>
    )
}
function LocationIconButtons( props:{ cardInfo: LocationCardViewModel, action: IconViewModel, columnIndex:number, parentLocationID:number} ) {

    const dispatch = useDispatch();
    
    return <Button disabled={props.action.disabled ?? false} onClick={() => batch(() => {
        dispatch(setModalLoading(true))
        dispatch(setModal({ modalFlow: ModalFlow.AddLocation, modalStep: props.action.modalName??ModalName.None, modalInfo: { locationID: props.cardInfo.id, columnIndex: props.columnIndex - 1, parentLocationID: props.parentLocationID } }))
    })}>
        <Stack direction="row" spacing={0.7} alignItems="center" padding="0.5rem 0.2rem">
            <SvgIcon height={1.5} name={props.action.iconName as SvgNames} className={props.action.fillClass} />
            <Typography fontSize={"0.9rem"}>{getDisplayNameOfEnumValue(props.action.description)}</Typography>
        </Stack>
    </Button>
}

