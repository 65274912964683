
import { Container, Dialog } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InfoPopupName, ModalName, SidebarEnum } from '../../Constants/app-enums'
import { ModalSizes } from '../../Constants/client-side-enums'
import { selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore'
import { CloseButton } from '../../Utility/shared-components'
import { globalClose } from './modal-components'
import { height } from '@mui/system'

type GlobalModalProps = {
    modalKey: ModalName | InfoPopupName,
    size: ModalSizes,
    closeClick?: any
    isOpen?: boolean,
    hideBackdrop?:boolean,
    height?:string
}
export default function GlobalModal(props: PropsWithChildren<GlobalModalProps>){
    const dispatch = useDispatch()
    const sidebarType = useSelector(selectNavigationInfo).sidebarType

    function renderSizeSwitch() {
        switch (props.size) {
            case ModalSizes.xs:
                return {minWidth:"16vh"}
            case ModalSizes.sm:
                return {minWidth:"40vw"}
            case ModalSizes.md:
                return {minWidth:"50vw"}
            case ModalSizes.lg:
                return {minWidth:"70vw"}
            case ModalSizes.xl:
                return {minWidth:"90vw"}

        }
    }

    return (
        <Dialog PaperProps={{ sx: {...renderSizeSwitch(), ...{overflow:"hidden", maxHeight:props.height??"90vh", height:props.height?? "none"}} }} hideBackdrop={props.hideBackdrop??false} fullWidth open={props.isOpen??true} container={() => document.getElementById('section-wrapper')}>
{/*             <LoadingSymbol />
 */}           <CloseButton onClick={(e)=>{props.closeClick!=undefined ? props.closeClick(e) : globalClose(sidebarType!=SidebarEnum.Add, dispatch)}} />
           <Container sx={{ marginX:"1rem", marginY:"2rem", paddingX:"0 !important", minHeight:"inherit", maxWidth:"calc(100% - 2rem) !important", overflow:"hidden", width:"100%", height:"100%", display:"flex", flexDirection:"column" }}>
           {props.children}
           </Container>
        </Dialog>
    )
}
