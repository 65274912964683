import { HtmlHTMLAttributes, useState } from "react"
import { batch, useDispatch } from "react-redux"
import { setReloadIndex } from "../ReduxRelatedUtils/index-table-reducer"
import { ButtonColumn } from "../Utility/request-types"
import { FilledTableButton } from "../Utility/shared-components"
import {setViewModelError } from '../ReduxRelatedUtils/viewmodel-reducer'
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer"
import { ModalFlow, ModalName } from "../Constants/app-enums"
import { Modal } from "../ReduxRelatedUtils/redux-types"
import { BasicModalInfo } from "../Utility/general-types"
import { getDisplayNameOfEnumValue } from "../Utility/root-function"


export default function TableButtonWithClick(column: ButtonColumn) {
    
    const dispatch = useDispatch();
    
    function onClick (e: React.MouseEvent<HTMLButtonElement>){
        e.preventDefault()
        batch(()=>{
            dispatch(setModal({ modalFlow: column.modalFlow, modalStep:column.modalName,  modalInfo: { ids: [column.objectID] } } as Modal<BasicModalInfo>))
            dispatch(setModalLoading(true))  
        })
    }
    return (
        <FilledTableButton href={column.link} value={column.objectID} onClick={onClick}>{getDisplayNameOfEnumValue(column.buttonDescription)}</FilledTableButton>
    )
}