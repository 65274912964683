import { Box, MenuItem, Stack } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TableCell from '@mui/material/TableCell'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { push } from 'connected-react-router'
import { LocationState } from 'history'
import queryString from 'query-string'
import React, { useContext } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { ButtonDescription, IconDescription, ImageType, IndexColumnProperties, IndexColumnTypes, InfoPopupName, MenuItemEnum, ModalFlow, PaymentsPopoverEnum, RoleDefinition, SvgNames } from '../../Constants/app-enums'
import { selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore'
import { setError } from '../../ReduxRelatedUtils/errorReducer'
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer'
import { handleCheckboxChange, setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer'
import { setInfoPopup } from '../../ReduxRelatedUtils/infoPopupReducer'
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer'
import { setModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer'
import { ApplicationState, InfoPopup, LoggedInUser, Modal, NavigationInfo } from '../../ReduxRelatedUtils/redux-types'
import { setReloadEditModal } from '../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer'
import TableButtonWithClick from '../../Requests/resend-button'
import ApproveHoursButton from '../../Users/approve-hours-button'
import { TableSelectInput } from '../../Utility/custom-input-components'
import { ActionBarLink, BasicModalInfo, CustomError, SelectListItem, TimekeeperActionModalInfo } from '../../Utility/general-types'
import GlobalPopover from '../../Utility/global-popover'
import { ButtonColumn, ColumnViewModel, DDLColumn, IconColumn, IconViewModel, ImageColumn, TextColumn as TextColumnObject } from '../../Utility/request-types'
import { getDisplayNameOfEnumValue, objectToFormData } from '../../Utility/root-function'
import { FilledTableButton, SectionOutlinedTableButton, SectionCheckbox, SmallTypography, SpacedColumnStack, SpacedRowStack, StyledFilledButton, StyledOutlineButton, StyledTableButton, StyledTableImage, SvgIcon, OutlinedTableButton, TooltipWithClickHandler } from '../../Utility/shared-components'
//import { FloatingActionBarContext } from './index-table-data-by-group'


export default function IndexTableColumn(props: { columnData: ColumnViewModel, groupid?: number, sidebar?: string, isChecked?: boolean }) {



    return (
        <TableCell width={props.columnData.width + "%"} className={props.columnData.width == 0 ? "p-0" : ""} >
            <InsideView col={props.columnData} sidebar={props.sidebar ?? ""} isChecked={props.isChecked} />
        </TableCell>
    )

}


function InsideView(props: { col: ColumnViewModel, sidebar: string, isChecked?: boolean }) {
    const dispatch = useDispatch()
    function handleFavorite(iconName: SvgNames) {
        var favoriteItem = iconName == SvgNames.Favorite ? false : true
        console.log(favoriteItem)
        var url = "/Requests/RequestFavorite/?requestID=" + props.col.objectID + "&favoriteItem=" + favoriteItem;
        fetch(url,
            {
                method: "GET"
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else { throw response.text() }
            })
            .then((result) => {
                dispatch(setReloadIndex(true))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    dispatch(setError({ message: text, showBody: false } as CustomError))
                })
            })
    }
    function handleRemoveShare() {
        var url = "/Requests/RemoveShare/?ID=" + props.col.objectID;
        fetch(url,
            {
                method: "POST"
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else { throw response.text() }
            })
            .then((result) => {
                dispatch(setReloadIndex(true))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    dispatch(setError({ message: text, showBody: false } as CustomError))
                })
            })
    }
    function handlePayLater() {
        var url = "/Requests/ChangePaymentStatus/?newStatus=" + PaymentsPopoverEnum.PayLater;
        fetch(url,
            {
                method: "POST",
                body: objectToFormData({ requestIDs: [props.col.objectID] })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else { throw response.text() }
            })
            .then((result) => {
                dispatch(setReloadIndex(true))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    dispatch(setError({ message: text, showBody: false } as CustomError))
                })
            })
    }
    function handleApprove() {
        dispatch(setLoading(true))
        var url = "/Requests/Approve/?id=" + props.col.objectID;
        fetch(url,
            {
                method: "GET",
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.text()
                }
                else { throw response.text() }
            })
            .then((result) => {
                dispatch(setLoading(false))

                dispatch(setReloadIndex(true))
                dispatch(setGlobalSnack({ open: true, message: "Item Approved and sent to Lab Management" }))
            })
            .catch(err => {
                dispatch(setLoading(false))

                Promise.resolve(err).then(text => {
                    console.log(text)
                    dispatch(setError({ message: text, showBody: false } as CustomError))
                })
            })
    }

    function handleIconClick(icon: IconViewModel) {
        console.log(icon.description)
        switch (icon.description) {
            case IconDescription.Favorite:
            case IconDescription.Unfavorite:
                handleFavorite(icon.iconName)
                break;
            case IconDescription.PayLater:
                handlePayLater()
                break;
            case IconDescription.Approve:
                if (icon.modalFlow == ModalFlow.None) {
                    handleApprove()
                }
                else {
                    batch(() => {
                        dispatch(setModal({ modalFlow: icon.modalFlow, modalInfo: { ids: [props.col.objectID] }, modalStep: icon.modalName } as Modal<BasicModalInfo>))
                        dispatch(setModalLoading(true))
                    })
                }
                break;
            case IconDescription.RemoveShare:
                handleRemoveShare();
                break;
            default:
                batch(() => {
                    dispatch(setModal({ modalFlow: icon.modalFlow, modalInfo: { ids: [props.col.objectID] }, modalStep: icon.modalName } as Modal<BasicModalInfo>))
                    dispatch(setModalLoading(true))
                })
                break;
        }
    }


    function ddlOnClick(objectID: number, ddlOption: any & SelectListItem) {
        batch(() => {
            dispatch(setModal({ modalFlow: ddlOption.modalFlow, modalStep: ddlOption.modalName, modalInfo: { ids: [objectID.toString()], typeID: ddlOption.value } } as Modal<TimekeeperActionModalInfo>))
            dispatch(setModalLoading(true))
        })
    }

    switch (props.col.columnProperties) {
        case IndexColumnProperties.Tooltip:
            switch (props.col.columnType) {
                case IndexColumnTypes.Text:
                    return (<Tooltip key={uuid()} title={getTooltipTitle(props.col)} arrow>
                        <div><TextColumn {...props.col as TextColumnObject} /></div>
                    </Tooltip>)
                default: return <></>
            }
            break;
        case IndexColumnProperties.LinkTooltip:
            switch (props.col.columnType) {
                case IndexColumnTypes.Text:
                    return <TooltipWithClickHandler  key={IndexColumnProperties.LinkTooltip + props.col.objectID} title={getTooltipTitle(props.col)} arrow>
                        <div key={uuid()}><TextLinkColumn {...props.col as TextColumnObject} /></div>
                    </TooltipWithClickHandler>
                case IndexColumnTypes.Icon:
                    var IconColumn = (props.col as IconColumn);
                    return (<Tooltip key={"icon" + uuid()} title={getDisplayNameOfEnumValue(IconColumn.icon?.description ?? "")} arrow>
                        <Button

                            onClick={() => handleIconClick(IconColumn.icon)}>
                            <SvgIcon name={IconColumn.icon?.iconName} className={IconColumn.icon?.fillClass} height={2} />
                        </Button>
                        {/* } */}
                    </Tooltip>)
                default: return <></>
            }

            break;
        case IndexColumnProperties.ClickTooltip:
            switch (props.col.columnType) {
                case IndexColumnTypes.Text:
                    return <Tooltip key={IndexColumnProperties.ClickTooltip + props.col.objectID} title={getTooltipTitle(props.col)} arrow>
                        <div key={uuid()}><TextClickColumn {...props.col as TextColumnObject} /></div>
                    </Tooltip>
                default: return <></>
            }

            break;
        case IndexColumnProperties.Popover:
            switch (props.col.columnType) {
                case IndexColumnTypes.Icon:
                    var iconColumn = (props.col as IconColumn);
                    return <GlobalPopover closeOnClick={true} key={"icons"}>
                        <SvgIcon height={2} name={iconColumn?.icon?.iconName} className={iconColumn.icon?.fillClass} />
                        <div>{{
                            "More": <MorePopover col={iconColumn} handleIconClick={handleIconClick} />,
                            "Partial": <PartialPopover col={iconColumn} />,
                            "Clarify": <ClarifyPopover col={iconColumn} />
                        }[iconColumn.icon.description.toString()]}
                        </div>
                    </GlobalPopover>
                default: return <></>
            }
            break;
        case IndexColumnProperties.InfoPopupClick:
            switch (props.col.columnType) {
                case IndexColumnTypes.Text:
                    return (<TextInfoPopupClickColumn {...props.col as TextColumnObject} />)
                default: return <></>
            }
        default:
            switch (props.col.columnType) {
                case IndexColumnTypes.Text:
                    return (<TextColumn {...props.col as TextColumnObject} />)
                case IndexColumnTypes.Button:
                    let buttonColumn = props.col as ButtonColumn;
                    switch (buttonColumn.columnProperties) {
                        case IndexColumnProperties.Link:

                            return (<FilledTableButton onClick={() => {
                                dispatch(setLoading(true))
                                fetch(buttonColumn.link, {
                                    method: "GET"
                                })
                                    .then((response) => {
                                        if (response.ok) { return response.text() }
                                        throw response.text();
                                    })
                                    .then((result: string) => {
                                        dispatch(setLoading(false))
                                        dispatch(setGlobalSnack({ open: true, message: "Success", severity: "success" }))
                                        dispatch(setReloadIndex(true))
                                    })
                                    .catch(err => {
                                        Promise.resolve(err).then(text => {
                                            dispatch(setLoading(false))
                                            dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                                        })
                                    })
                            }} value={props.col.objectID}>{buttonColumn.buttonDescription}</FilledTableButton>)

                        case IndexColumnProperties.Click:
                            return <TableButtonWithClick {...buttonColumn} />
                        default:
                            return (<FilledTableButton value={props.col.objectID}>{buttonColumn.buttonDescription}</FilledTableButton>)

                    }
                case IndexColumnTypes.Checkbox:
                    return (<TableCheckBox col={props.col} isChecked={props.isChecked} />);
                case IndexColumnTypes.Image:
                    var imageColumn = props.col as ImageColumn;
                    switch (imageColumn.imageType) {
                        case ImageType.Avatar:
                            return <Avatar alt="userImage" sx={{ width: 65, height: 65, margin: 'auto' }} src={imageColumn.image} />
                        case ImageType.RoundedSquare:
                            return <StyledTableImage width={4.7} src={imageColumn.image} />
                        default: return <></>
                    }
                case IndexColumnTypes.Placeholder:
                    return <></>
                    break;
                case IndexColumnTypes.Icon:
                    let IconColumn = props.col as IconColumn;
                    return <SvgIcon height={1.25} name={IconColumn?.icon?.iconName} className={IconColumn.icon?.fillClass} />

                case IndexColumnTypes.DDL:
                    let ddlColumn = props.col as DDLColumn;
                    return <TableSelectInput defaultValue={0} placeholder={ddlColumn.placeholder}>
                        {ddlColumn.list?.map((b: any) =>
                            <MenuItem key={b.value + b.text} value={b.value} onClick={() => ddlOnClick(Number(ddlColumn.objectID), b)} disabled={b.disabled} >
                                {b.text}
                            </MenuItem>)}
                    </TableSelectInput>
                    break;
                default: return <></>
            }
            break;
    }
}


function TextColumn(col: TextColumnObject) {

    return (<>
        {col.valueWithError.map((ve, i) => {
            let dangerColor = ve.bool ? " error " : "";
            return (
                <React.Fragment key={uuid()}>
                    <Typography className={dangerColor}
                        sx={{
                            textTransform: "none",
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            color: theme => col.color ?? theme.palette.primary.main,
                            textDecoration: col.strikeThrough ? 'line-through' : ""
                        }}>
                        {ve.string}
                    </Typography>
                </React.Fragment>)
        })}
    </>)
}
function getTooltipTitle(col: ColumnViewModel) {
    let title = ""
    col.valueWithError.map((ve, i) => {
        title += "\n" + ve.string;
    })
    return title;
}
function TextLinkColumn(col: TextColumnObject) {
    const dispatch = useDispatch();
    const location = useSelector<ApplicationState>(state => state.router.location) as any;

    return (<>
        {col.valueWithError.map((ve, i) => {
            let dangerColor = ve.bool ? " error " : "";
            return (<Typography key={uuid()}><Link role="link" className={dangerColor}
                onClick={() => {
                    batch(() => {
                        dispatch(push(queryString.stringifyUrl({ url: location.pathname + "/" + col.link, query: { ...location.query, id: col.objectID } })));
                        dispatch(setReloadEditModal({ reloadEditModal: true, id: col.objectID }))
                    })
                }}>{ve.string}</Link></Typography>);
        })}
    </>);
}

function TextClickColumn(col: TextColumnObject) {
    console.log("rendering textclick column")

    const dispatch = useDispatch();
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    return (<>
        {col.valueWithError.map((ve, i) => {
            let dangerColor = ve.bool ? " error " : "";
            return (<Typography key={uuid()}><Link role="link" className={dangerColor}
                onClick={() => {
                    batch(() => {
                        dispatch(setReloadIndex(true))
                        dispatch(push(queryString.stringifyUrl({ url: location.pathname, query: { ...location.query, id: col.objectID } })))
                    })
                }}>{ve.string}</Link></Typography>);
        })}
    </>);
}

function TextInfoPopupClickColumn(col: TextColumnObject) {
    const dispatch = useDispatch();
    return (<>
        {col.valueWithError.map((ve, i) => {
            let dangerColor = ve.bool ? " error " : "";
            return (<Typography key={uuid()}><Link role="link" className={dangerColor}
                onClick={() => dispatch(setInfoPopup({ infoPopupName: InfoPopupName.EditItem, id: col.objectID.toString() } as InfoPopup))}>{ve.string}</Link></Typography>);
        })}
    </>);
}
function TableCheckBox(props: { col: ColumnViewModel, isChecked?: boolean }) {

    const dispatch = useDispatch()

    return (
        <SectionCheckbox data-testid={'columnCheckbox' + props.col.objectID} key={"checkbox" + props.col.objectID} value={props.col.objectID} onChange={e => dispatch(handleCheckboxChange({ checkboxID: e.target.value, checked: e.target.checked }))} checked={props.isChecked ?? false} />
    );
}
function ClarifyPopover(props: { col: IconColumn }) {

    const navigationInfo = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo
    const { userRoles } = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const dispatch = useDispatch()
    function handleClarify() {
        var url = "/Requests/HandleNotifications?requestID=" + props.col.objectID + "&type=" + navigationInfo.sidebarType;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.text() }
                throw response.text();
            })
            .then((result: string) => {
                dispatch(setReloadIndex(true))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text, showBody: false })
                })
            })
    }
    return (<Box padding="1.5rem" width="15rem">
        <Stack spacing={1}>
            <Box border={theme => `1px solid ${theme.palette.grey[400]}`} padding="0.75rem" borderRadius="0.3rem">
                <SmallTypography textAlign={"center"}>{props.col.note}</SmallTypography>
            </Box>
            {userRoles.find(u => u.mainRole == MenuItemEnum.Accounting)?.subRoles.includes(RoleDefinition.AccountingEdit) && <SmallTypography textAlign="center">Delete Clarification?</SmallTypography>}
            <Stack direction={"row"} spacing={1} justifyContent="center">
                {userRoles.find(u => u.mainRole == MenuItemEnum.Accounting)?.subRoles.includes(RoleDefinition.AccountingEdit) && <FilledTableButton sx={{ width: "50%" }} onClick={handleClarify}>Yes</FilledTableButton>}
                <OutlinedTableButton sx={{ width: "50%" }}>Cancel</OutlinedTableButton>
            </Stack>
        </Stack>
    </Box>);
}



function PartialPopover(props: { col: IconColumn }) {
    return (
        <Box padding="1.5rem" >
            <Box border={theme => `1px solid ${theme.palette.grey[400]}`} padding="0.75rem" borderRadius="0.3rem">
                <SmallTypography textAlign={"center"}>{props.col.note}</SmallTypography>
            </Box>
        </Box>
    )
}

function MorePopover(props: { col: ColumnViewModel, handleIconClick: Function }) {
    const dispatch = useDispatch();
    var iconColumn = props.col as IconColumn;
    return (
        <Grid container direction="column">
            <Grid container direction="column" padding="0.5rem 1rem" >
                {iconColumn.icon?.iconPopovers?.map((iconpopover, i) => (
                    <Grid item key={"iconPopover" + i} borderTop={i == 0 ? "none" : "1px solid #ebebeb"}>
                        <Button onClick={() => props.handleIconClick(iconpopover)}>
                            <Stack direction="row" spacing={0.5} alignItems="center" padding="0.5rem 0.2rem" >
                                <SvgIcon height={1.5} name={iconpopover.iconName as SvgNames} className={iconpopover.fillClass} />
                                <Typography>{getDisplayNameOfEnumValue(iconpopover.description)}</Typography>
                            </Stack>
                        </Button>
                    </Grid>))}
            </Grid>
        </Grid>)
}
