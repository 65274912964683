import { createSlice } from '@reduxjs/toolkit'

const loadingSlice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        setLoading(state:boolean, action) {
            return action.payload
        },
       
    }
})

export const { setLoading } = loadingSlice.actions
export const loadingReducer = loadingSlice.reducer

const pageSkeletonSlice = createSlice({
    name: 'showPageSkeleton',
    initialState: true,
    reducers: {
        setPageSkeleton(state:boolean, action:{payload:boolean}) {
            return action.payload
        },
       
    }
})

export const { setPageSkeleton } = pageSkeletonSlice.actions
export const pageSkeletonReducer = pageSkeletonSlice.reducer



const showConfirmExitSlice = createSlice({
    name: 'showConfirmExit',
    initialState: false,
    reducers: {
        setShowConfirmExit(state:boolean, action:{payload:boolean}) {
            return action.payload
        },
       
    }
})

export const { setShowConfirmExit } = showConfirmExitSlice.actions
export const showConfirmExitReducer = showConfirmExitSlice.reducer