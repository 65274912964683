import { Grid, MenuItem } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { AddSupplierViewModel, AddSupplierFormData } from "../Shared/AddCardComponents/add-types";
import { StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledControlledSelectInput } from "../Utility/custom-input-components";
import { AutocompleteOption } from "../Utility/general-types";
import { Country } from "../Utility/request-types";
import { objectToFormData } from "../Utility/root-function";
import { SectionCheckbox } from "../Utility/shared-components";

export default function DetailsTab(viewModel: AddSupplierViewModel) {
    const dispatch= useDispatch()
    const { watch, setValue, control, formState: { errors } } = useFormContext<AddSupplierFormData>();
    const vendorCategoryTypes = watch("vendorCategoryTypes")
    const businessID = watch("vendor.vendorBuisnessID")
    const countryID = watch("vendor.countryID")
    const vendorID = watch("vendor.vendorID")
    console.log(errors)

    useEffect(() => {
        if (businessID != "" && countryID > 0) {
            fetch("/Vendors/CheckUniqueCompanyIDAndCountry", {
                method: "POST",
                body: objectToFormData({ companyID: businessID, countryID: countryID, vendorID: vendorID }),
            }).then(response => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            }).then((result: boolean) => {
                setValue("vendor.uniqueCountryCompanyID", result)
            })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setGlobalSnack({ open: true, severity: "error", message: text }))
                    })
                })
        }
    }, [businessID, countryID])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <StyledControlledFormInput name="vendor.vendorEnName" label="Supplier Name [EN]*" />
            </Grid>
            <Grid item xs={12}>
                <StyledControlledFormInput name="vendor.vendorHeName" label="Supplier Name [He]*" />
            </Grid>
            <Grid item xs={6}>
                
                <StyledControlledSelectInput renderValue={(selected: any) => ((selected as number[]).map(s => viewModel.categoryTypes.find(ct => ct.categoryTypeID == s)?.categoryTypeDescription) ?? "").join(', ')} multiple name="vendorCategoryTypes" label="Category Type*">
                    {viewModel?.categoryTypes?.map((ct) => (
                        <MenuItem key={ct.categoryTypeID} value={ct.categoryTypeID}>
                            <SectionCheckbox className={"LabManagement"} checked={vendorCategoryTypes.indexOf(ct.categoryTypeID) > -1} />
                            {ct.categoryTypeDescription}
                        </MenuItem>
                    ))}
                </StyledControlledSelectInput>
            </Grid>
            <Grid item xs={6}>
                <StyledControlledFormInput name="vendor.vendorBuisnessID" label="Company ID*" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledAutoCompleteInput name="vendor.countryID" label="Country*" placeholder="Select Country"
                options={viewModel?.countries?.map(c => { return {text: c.countryName, value: c.countryID} as AutocompleteOption})??[]}
                />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.vendorCity" label="City*" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.vendorStreet" label="Street" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.vendorZip" label="Zip" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorTelephone" label="Telephone*" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorCellPhone" label="Cell" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorFax" label="Fax" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.ordersEmail" label="Orders Email*" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.quotesEmail" label="Quotes Email*" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.infoEmail" label="Info Email" />
            </Grid>
            <Grid item xs={3}>
                <StyledControlledFormInput name="vendor.vendorWebsite" label="Website" />
            </Grid>
        </Grid>
    )
}