import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import AdditionalRequestInfoModal from './additional-request-info-modal';
import ConfirmEmailModal from './confirm-email-modal';
import ConfirmOrderModal from './confirm-order-modal';
import EditEmailModal from './edit-email-modal';
import GlobalModal from './global-modal';
import ReorderModal from './reorder-modal';
import TermsModal from './terms-modal';
import UploadOrderModal from './upload-order-modal';
import UploadQuoteModal from './upload-quote-modal';



export default function ItemFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("in  temp data guid set")
        const guid = v4();
        dispatch(setTempData({ guid: guid } as TempData<AddItemFormData>))
    }, []);

    console.log("in list modal flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.UploadOrder:
                return (<UploadOrderModal />)
            case ModalName.UploadQuote:
                return (<UploadQuoteModal />)
            case ModalName.Terms:
                return (<TermsModal />)
            case ModalName.Reorder:
                return (<ReorderModal />)
            case ModalName.ReorderProduct:
                return (<ReorderModal />)
            case ModalName.ConfirmEmail:
                return (<ConfirmEmailModal />)
            case ModalName.AdditionalRequestInfo:
                return (<AdditionalRequestInfoModal />)
            case ModalName.EditEmail:
                return (<EditEmailModal />)
            default: return null;
        }
    }

    return (<>{modalStep!=ModalName.None&&
        <GlobalModal size={ ModalSizes.md} modalKey={modalStep} height={modalStep==ModalName.Reorder||modalStep==ModalName.ReorderProduct?"75vh":"69vh"}>
            <ModalContent />
        </GlobalModal>}</>
    );

}
