
import { createSlice } from '@reduxjs/toolkit'
import { GlobalSnackState } from '../Utility/general-types'

const globalSnackSlice = createSlice({
    name: 'snackState',
    initialState: {open:false, message:""} as GlobalSnackState,
    reducers: {
        setGlobalSnack(state:GlobalSnackState, action: {payload: GlobalSnackState}) {
            return action.payload
        },
       
    }
})

export const { setGlobalSnack } = globalSnackSlice.actions
export const globalSnackReducer = globalSnackSlice.reducer

