import { push } from 'connected-react-router';
import React, { PropsWithChildren, StrictMode, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import * as Routes from './Constants/Routes';
import { LabsIndex, LocationMapIndex, StorageTypesIndex } from './LabManagement/locations-index';
import Login from './Login/login';
import Login2FA from './Login/login2FA';
import ResetPassword from './Login/reset-password';
import { setErrorMessage } from './ReduxRelatedUtils/errorReducer';
import { ApplicationState, LoggedInUser } from './ReduxRelatedUtils/redux-types';
import { setIsAuthenticated, setUserInfo } from './ReduxRelatedUtils/userInfoReducer';
import AddItem from './Requests/ItemCard/add-item';
import EditItem from './Requests/ItemCard/edit-item';
import GlobalSnack from './Shared/global-snack';
import Home from './Shared/home';
import { DidntArrive, ForClarification, Installments, LabManageOrders, LabManagePendingOrders, LabManageQuotes, MissingPaymentDetails, MonthlyPayments, NoInvoice, PartialDelivery, PayLater, PayNow, RequestCartPendingOrders, RequestsCart } from './Shared/IndexTable/index-table-by-group';
import { AccountingGeneral, AwaitingApproval, BankAccounts, CreditCards, EmployeeDetails, EmployeeHours, ReportDaysOff, RequestFavorites, RequestsInventoryList, RequestsList, SearchRequestsResults, SharedRequests, SummaryHours, SuppliersList, UsersTable, RequestsDidntArrive, RequestsNotifications } from './Shared/IndexTable/index-table-components';
import { InfoPopupLoader } from './Shared/info-popup-loader';
import Layout from './Shared/layout';
import LoadingSymbol from './Shared/loading-symbol';
import { ModalLoader } from './Shared/modal-loader';
import AddSupplier from './Suppliers/add-supplier';
import EditSupplier from './Suppliers/edit-supplier';
import ReportHours from './Timekeeper/report-hours';
import { SummaryDaysOff } from './Timekeeper/summary-days-off';
import EditUser from './Users/edit-user';
import { handleFetchErrors } from './Utility/root-function';
import { getMenuByEnum, getPageTypeByEnum } from './Utility/section-menus';
import moment from 'moment-timezone'
import AddUser from './Users/add-user';
import { ItemSearchPage } from './Shared/item-search-page';
import ConfirmUserEmail from './Users/confirm-user-email';
import EditProduct from './Requests/ItemCard/edit-product';
import { setIsHome } from './ReduxRelatedUtils/isHomeReducer';

export type ModalLoaderProps = {
    modalKey: string,
    uid: string | undefined
}

interface MatchParams {
    name: string
}






const App = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector<ApplicationState>(state => state.isAuthenticated) as boolean;
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const didMount = useRef(false);
    const isHome = useSelector<ApplicationState>(state => state.isHome);
    
    const [renderLayout, setRenderLayout] = useState<boolean>(isAuthenticated);
   
    moment.tz.setDefault("Asia/Jerusalem");

    Date.prototype.toJSON = function(){ return moment(this).format(); }


    //globally sets all fetches to logout if 401
    const originalFetch = window.fetch;
    
    window.fetch = (url, options) => {
      return originalFetch(url, options).then(handleFetchErrors);
    }
 
    const [renderHome, setRenderHome] = useState(false)
    useEffect(() => {
        console.log("isauthenitcated"+isAuthenticated)
        if (isAuthenticated) {
            console.log("didmount"+didMount.current)
            if (didMount.current) {
                var url = "/Home/GetLoggedInUserWithRoles";
                fetch(url, {
                    method: "GET"
                })
                    .then((response) => {
                        if (response.ok) { return response.json() }
                        else if (response.status == 401) {
                            batch(() => {
                                dispatch(push('/Login')) ;
                            })
                        }
                        else { throw response.text() }
                    })
                    .then(result => {
                        const user = result as LoggedInUser;
                        batch(() => {
                            dispatch(setUserInfo(user))
                            dispatch(push("/"))
                            dispatch(setIsHome(true))
                        });
                        setRenderLayout(true)
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }

        }
        else {
            if(!location.pathname.includes(Routes.CONFIRM_USER_EMAIL)){
                dispatch(push('/Login'))
                setRenderLayout(false)
                didMount.current = true
            }
            
        }

    }, [isAuthenticated]);

    useEffect(() => {
   
        if(location.pathname=="/"){
            console.log("location path name useeffect: "+location.pathname)
            dispatch(setIsHome(true))
        }
        else{
            dispatch(setIsHome(false))
        }
        if (isAuthenticated) {
            if(location.pathname=="/Logout"){
                dispatch(setIsAuthenticated(false));
                
            }
            
        }
    }, [location.pathname])

    useEffect(() => {
        console.log("pathname:" +location.pathname)
        if (isAuthenticated) {
            if(location.pathname.includes(Routes.CONFIRM_USER_EMAIL)){
                console.log("confirm user email")
            }
            else if(location.pathname=="/" ||location.pathname=="/Logout" ){

            }
            else if(location.pathname=="/Login" ||location.pathname=="/Login2FA" ||location.pathname=="/ResetPassword"){
                dispatch(push("/"))
            }
           else if (((location.query.sectionType == undefined || location.query.pageType == undefined || location.query.sidebarType == undefined) ||
                (getMenuByEnum(location.query.sectionType)?.childMenuItems?.filter(m => m.pageTypeEnum?.toLowerCase() == location.query.pageType.toLowerCase()).length != 1) ||
                (getPageTypeByEnum(location.query.pageType)?.childMenuItems?.filter(p => p.sidebarTypeEnum?.toLowerCase() == location.query.sidebarType.toLowerCase()).length != 1))) {
                console.log("invalid link")
                console.log(location.query)
                batch(() => {
                    dispatch(push("/"))
                    dispatch(setErrorMessage("Invalid Link"))
                })
            }
           
        }
    }, [location.query])



    

    if (renderLayout) {
       // console.log("authenticated")
        //console.log('location', location)

        return (
            !location.pathname.includes(Routes.CONFIRM_USER_EMAIL) ?
                <Layout key="layout">
                    <UIWrapper>
                        <Switch >
                            <Route exact path='/'><Home /></Route>
                            {/*Requests*/}
                            <Route path={Routes.REQUESTS_LIST} render={() => <RequestsList />} />
                            <Route path={Routes.REQUESTS_INVENTORY_LIST} render={() => <RequestsInventoryList />} />
                            <Route path={Routes.ADD_ITEM} render={(props) => <AddItem  {...props} key={"addItem"} />} />
                            <Route path={Routes.REQUESTS_CART} render={() => <RequestsCart />} />
                            <Route path={Routes.REQUESTS_CART_PENDING_ORDERS} render={() => <RequestCartPendingOrders/>} />
                            <Route path={Routes.REQUESTS_FAVORITES} render={() => <RequestFavorites />} />
                            <Route path={Routes.SHARED_REQUESTS} render={() => <SharedRequests />} />
                            <Route path={Routes.SEARCH_REQUESTS} render={() => <ItemSearchPage />} />
                            <Route path={Routes.SEARCH_REQUESTS_RESULTS} render={() => <SearchRequestsResults />} />
                            <Route path={Routes.REQUESTS_DIDNT_ARRIVE} render={() => <RequestsDidntArrive />} />
                            <Route path={Routes.REQUESTS_NOTIFICATIONS} render={() => <RequestsNotifications />} />
                            {/*Lab Management*/}
                            <Route path={Routes.ADD_SUPPLIER} render={(props) => <AddSupplier  {...props} key={"addSupplier"} />} />
                            <Route path={Routes.SUPPLIERS_LIST} render={() => <SuppliersList />} />
                            <Route path={Routes.LAB_MANAGE_QUOTES} render={() => <LabManageQuotes />} />
                            <Route path={Routes.LAB_MANAGE_ORDERS} render={() => <LabManageOrders />} />
                            <Route path={Routes.LAB_MANAGE_PENDING_ORDERS} render={() => <LabManagePendingOrders/>} />
                            <Route path={Routes.LOCATIONS_MAP} render={() => <LocationMapIndex />} />
                            <Route path={Routes.LOCATIONS_LABS} render={() => <LabsIndex />} />
                            <Route path={Routes.LOCATIONS_STORAGE_TYPE} render={() => <StorageTypesIndex />} />
                            {/*Users*/}
                            <Route path={Routes.USERS_TABLE} render={() => <UsersTable />} />
                            <Route path={Routes.EMPLOYEE_DETAILS} render={() => <EmployeeDetails />} />
                            <Route path={Routes.EMPLOYEE_HOURS} render={() => <EmployeeHours />} />
                            <Route path={Routes.AWAITNG_APPROVAL} render={() => <AwaitingApproval />} />
                            <Route path={Routes.ADD_USER} render={() => <AddUser />} />
                            <Route path={Routes.CONFIRM_USER_EMAIL} render={() => <ConfirmUserEmail />} />

                            {/*Accounting*/}
                            <Route path={Routes.MONTHLY_PAYMENT} render={() => <MonthlyPayments key={"monthlypaymetn"} />} />
                            <Route path={Routes.PAY_NOW} render={() => <PayNow />} />
                            <Route path={Routes.PAY_LATER} render={() => <PayLater />} />
                            <Route path={Routes.INSTALLMENTS} render={() => <Installments />} />
                            <Route path={Routes.ACCOUNTING_GENERAL} render={() => <AccountingGeneral />} />
                            <Route path={Routes.NO_INVOICE} render={() => <NoInvoice />} />
                            <Route path={Routes.DIDNT_ARRIVE} render={() => <DidntArrive />} />
                            <Route path={Routes.PARTIAL_DELIVERY} render={() => <PartialDelivery />} />
                            <Route path={Routes.FOR_CLARIFICATION} render={() => <ForClarification />} />
                            <Route path={Routes.MISSING_PAYMENT_DETAILS} render={() => <MissingPaymentDetails />} />
                            <Route path={Routes.BANK_ACCOUNTS} render={() => <BankAccounts />} />
                            <Route path={Routes.CREDIT_CARDS} render={() => <CreditCards />} />
                            {/*Timekeeper*/}
                            <Route path={Routes.REPORT_HOURS} render={() => <ReportHours />} />                            
                            <Route path={Routes.REPORT_DAYS_OFF} render={() => <ReportDaysOff />} />
                            <Route path={Routes.SUMMARY_HOURS} render={() => <SummaryHours />} />
                            <Route path={Routes.SUMMARY_DAYS_OFF} render={() => <SummaryDaysOff />} />

                        </Switch>
                        <Switch>
                            <Route path={"*" + Routes.EDITREQUEST} render={() => <EditItem />} />
                            <Route path={"*" + Routes.EDITSUPPLIER} render={() => <EditSupplier />} />
                            <Route path={"*" + Routes.EDITUSER} render={() => <EditUser />} />
                            <Route path={"*" + Routes.EDITPRODUCT} render={() => <EditProduct />} />
                        </Switch>
                     </UIWrapper>
                </Layout>
                : <Switch>
                <Route path={Routes.CONFIRM_USER_EMAIL} render={() => <ConfirmUserEmail />}     />
                    </Switch>   
        )
    }
    else {
       // console.log("not authenticated");

        return (
            <>
            <LoadingSymbol/>
            <Switch>
                <Route exact path='/Login'><Login /></Route>
                <Route exact path='/Login2FA'><Login2FA /></Route>
                <Route exact path='/ResetPassword'><ResetPassword /></Route>
                <Route exact path={Routes.CONFIRM_USER_EMAIL}><ConfirmUserEmail /></Route>
            </Switch>
            <GlobalSnack/>
            </>
        )
    }

}

export default App


function UIWrapper(props:PropsWithChildren<any>){
    return(<StrictMode>
        {props.children}
        <LoadingSymbol/>
        <ModalLoader/>
        <GlobalSnack/>
        <InfoPopupLoader/>
        </StrictMode>
    )
}