import { yupResolver } from '@hookform/resolvers/yup';
import { AlertColor, Box, Grid, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, array, number, object, string } from 'yup';
import { LocationTypeName, ModalName, NumberingMethodTypeEnum } from '../Constants/app-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal } from '../ReduxRelatedUtils/redux-types';
import { setLastSelectedLocation, setLastSelectedLocationReload } from '../ReduxRelatedUtils/selectedLocationReducer';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledFormInput, StyledControlledSelectInput } from '../Utility/custom-input-components';
import { AddStorageUnitModalInfo, AutocompleteOption, CustomError } from '../Utility/general-types';
import { GlobalForm, SectionCheckbox } from '../Utility/shared-components';
import { LocationInstance, NumberingMethods } from './locations-types';


export type AddStorageUnitViewModel = {
    storageTemperature: string,
    storageType: string,
    canBeEmpty: boolean,
    rooms: AutocompleteOption[]
    childrenStorageUnits: StorageUnitChildFields[]
}

export type AddStorageUnitFormData = {
    informalName: string,
    locationTypeID: number,
    roomInstanceID: number,    
    canBeEmpty: boolean,
    storageUnitChildFieldValues: StorageUnitChildFieldValue[]
}

export type StorageUnitChildFields = {
    name: string,
    storageTypeID: number,
    canBeEmpty: boolean,
    numberingMethodTypeEnum: NumberingMethodTypeEnum,
    numberingMethods: NumberingMethods[]
}

export type StorageUnitChildFieldValue = {
    storageTypeID: number,
    value: number,
    emptyChildrenIndexes: number[],
    numberingMethodTypeEnum: NumberingMethodTypeEnum,
}
export default function AddStorageUnitModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<AddStorageUnitModalInfo>
    const [viewModel, setViewModel] = useState<AddStorageUnitViewModel>({} as AddStorageUnitViewModel)
    const [error, setError] = useState<CustomError>(new CustomError());
    const lastSelectedLocation = useSelector<ApplicationState>(state => state.lastSelectedLocation) as LastSelectedLocation


    const validationSchema = object<Partial<Record<keyof AddStorageUnitFormData, AnySchema>>>({
        informalName: string().required("Informal Name is a required field."), 
        roomInstanceID : number().required("Room is a required field.").min(1, "Room is a required field."),

        storageUnitChildFieldValues: array().when("canBeEmpty", {is:false, then: array<Partial<Record<keyof StorageUnitChildFieldValue, AnySchema>>>()
        .of( 
            object<Partial<Record<keyof StorageUnitChildFieldValue, AnySchema>>>({value:number()
            .when("numberingMethodTypeEnum", {is:(v:NumberingMethodTypeEnum)=>{console.log(v); return v==NumberingMethodTypeEnum.PredefinedList},
            then:  number().required("Must select one option").min(1, "Must select one option"),
            otherwise:  number().required("Required field.").min(1,   "Must be greater than 0"),}
            )}))
        })
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            informalName: "",
            locationTypeID: modalInfo.locationTypeID,
            roomInstanceID: 0,            
            storageUnitChildFieldValues:[],
            canBeEmpty:false
        } as AddStorageUnitFormData
    }
    );


    useEffect(() => {
        var url = "/Locations/AddStorageUnitModal?locationTypeID=" + modalInfo.locationTypeID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: AddStorageUnitViewModel) => {
                setViewModel(result);
                let storageUnitChildFieldValues = result.childrenStorageUnits.map((c,i)=>{return { storageTypeID:c.storageTypeID, emptyChildrenIndexes:[]as number[], numberingMethodTypeEnum:c.numberingMethodTypeEnum, value:0}as StorageUnitChildFieldValue})
                methods.setValue("storageUnitChildFieldValues", storageUnitChildFieldValues)
                methods.setValue("canBeEmpty", result.canBeEmpty);
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);

    function onSubmit(data: any, e: any) {
        var url = '/Locations/AddStorageUnitModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((res: { result: any }) => {
                let severity: AlertColor = "success"
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: res.toString(), severity: severity }))      
                  
                    dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.toString(), severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Add Storage Unit"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddStorageUnit} onSubmit={onSubmit} methods={methods}>
                                <Stack direction="column" spacing={2}>
                                    <Stack direction="row" key={1} spacing={2}>
                                        <StyledFormInput label="Storage Temperature" value={viewModel.storageTemperature} disabled />
                                        <StyledFormInput label="Storage Type" value={viewModel.storageType} disabled />
                                    </Stack>
                                    <Stack direction="row"  key={2} spacing={2}>
                                        <StyledControlledFormInput label="Informal Name" name="informalName" />
                                        <StyledControlledAutoCompleteInput
                                            label="Room"
                                            placeholder="Select a Room"
                                            name="roomInstanceID"
                                            options={viewModel.rooms??[]}
                                            />
                                    </Stack>
                                    <Box width={"100%"}>
                                        <Grid container spacing={2}>
                                            {viewModel.childrenStorageUnits.map((c, i) => {
                                                switch (c.numberingMethodTypeEnum) {
                                                    case NumberingMethodTypeEnum.PredefinedList:
                                                        return <Grid item xs={4}> <StyledControlledAutoCompleteInput
                                                            label={c.name}
                                                            placeholder="Select"
                                                            key={i}
                                                            name={`storageUnitChildFieldValues.${i}.value`}
                                                            options={c?.numberingMethods?.map(n => { return {text:`${n.width} X ${n.height}`, value: n.numberingMethodID} as AutocompleteOption})??[]}
                                                        />
                                                        </Grid>
                                                    case NumberingMethodTypeEnum.AnyAmount:
                                                        return (<>
                                                            <Grid  key={i} item xs={4}>  <StyledControlledFormInput  name={`storageUnitChildFieldValues.${i}.value`} type="number" label={c.name} key={i} /></Grid>
                                                            {c.canBeEmpty &&<Grid item xs={4}>  <EmptyBlockDDL storageTypeName={c.name} nameAttr={`storageUnitChildFieldValues.${i}`} /></Grid>}
                                                        </>)

                                                }
                                            })}
                                        </Grid>
                                    </Box>
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddStorageUnit} />
                    </>
                    }
                </>
            }
        </>
    )
}

function EmptyBlockDDL(props:{storageTypeName:string, nameAttr:string}) {

    const methods = useFormContext();
    const amt =  methods.watch(props.nameAttr+".value")
    const emptyChildrenNameAttr =props.nameAttr+".emptyChildrenIndexes"
    useEffect(()=>{
        methods.setValue(emptyChildrenNameAttr, []);
    },[amt])
    const list= [] as {text:string, value:number}[];
    for(let i=0; i<amt; i++){
        list.push({text:`${props.storageTypeName} ${i+1}`, value: i});
    }
    const emptyChildren = methods.watch(emptyChildrenNameAttr)
    return (
        
    <StyledControlledSelectInput renderValue={(selected: any) => ((selected as number[]).map(s => list.find(ct => ct.value == s)?.text) ?? "").join(', ')} multiple  label={`Empty ${props.storageTypeName}`}
                                            name={emptyChildrenNameAttr}>
                    {list.map((l) => (
                        <MenuItem key={l.value} value={l.value}>
                            <SectionCheckbox className={"LabManagement"} checked={emptyChildren.indexOf(l.value) > -1} />
                            {l.text}
                        </MenuItem>
                    ))}
                </StyledControlledSelectInput>
         
)
}