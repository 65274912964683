import { MenuItemEnum, PageTypeEnum, RoleDefinition, SidebarEnum, SvgNames } from "../Constants/app-enums";
import * as Routes from '../Constants/Routes';
import { NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { MenuItem } from "./section-menus";
export const requestRequestLastItem: MenuItem = {
    description: "Last Item",
    href: Routes.REQUESTS_LIST,
    navigationInfo: { pageType: PageTypeEnum.RequestRequest, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const requestRequestAddItem: MenuItem = {
    description: "Add Item",
    href: Routes.ADD_ITEM,
    navigationInfo: { pageType: PageTypeEnum.RequestRequest } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Add,
    iconName: SvgNames.AddCircleOutline,
    requiredRole:RoleDefinition.RequestsRequestItem
}

export const requestRequestType: MenuItem = {
    description: "Type",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.RequestRequest } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Type,
    iconName: SvgNames.Category
}

export const requestRequestVendors: MenuItem = {
    description: "Vendors",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.RequestRequest, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Vendors,
    iconName: SvgNames.Storefront
}

export const requestRequestOwner: MenuItem = {
    description: "Owner",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.RequestRequest } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Owner,
    iconName: SvgNames.Face
}

export const requestRequestSearch: MenuItem = {
    description: "Search",
    href: Routes.SEARCH_REQUESTS,
    navigationInfo: { pageType: PageTypeEnum.RequestRequest, sidebarType: SidebarEnum.Search, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const requestInventoryLastItem: MenuItem = {
    description: "Last Item",
    href: Routes.REQUESTS_INVENTORY_LIST,
    navigationInfo: { pageType: PageTypeEnum.RequestInventory, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const requestInventoryAddItem: MenuItem = {
    description: "Add Sample",
    href: Routes.ADD_ITEM,
    navigationInfo: { pageType: PageTypeEnum.RequestInventory } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Add,
    iconName: SvgNames.AddCircleOutline
}

export const requestInventoryType: MenuItem = {
    description: "Type",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.RequestInventory } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Type,
    iconName: SvgNames.Category
}

export const requestInventoryVendors: MenuItem = {
    description: "Vendors",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.RequestInventory, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Vendors,
    iconName: SvgNames.Storefront
}

export const requestInventoryOwner: MenuItem = {
    description: "Owner",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.RequestInventory } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Owner,
    iconName: SvgNames.Face
}

export const requestInventorySearch: MenuItem = {
    description: "Search",
    href: Routes.SEARCH_REQUESTS,
    navigationInfo: { pageType: PageTypeEnum.RequestInventory, sidebarType: SidebarEnum.Search, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const requestCartOrders: MenuItem = {
    description: "Orders",
    href: Routes.REQUESTS_CART,
    navigationInfo: { pageType: PageTypeEnum.RequestCart, sidebarType: SidebarEnum.Cart, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Orders,
    iconName: SvgNames.ShoppingCart
}
export const requestCartPendingOrders: MenuItem = {
    description: "Pending Orders",
    href: Routes.REQUESTS_CART_PENDING_ORDERS,
    navigationInfo: { pageType: PageTypeEnum.RequestCart } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.PendingOrders,
    iconName: SvgNames.PendingOrders
}
export const requestCartFavorite: MenuItem = {
    description: "Favorite",
    href: Routes.REQUESTS_FAVORITES,
    navigationInfo: { pageType: PageTypeEnum.RequestCart } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Favorites,
    iconName: SvgNames.FavoriteBorder
}

export const requestCartShared: MenuItem = {
    description: "Shared",
    href: Routes.SHARED_REQUESTS,
    navigationInfo: { pageType: PageTypeEnum.RequestCart, sidebarType: SidebarEnum.SharedRequests, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SharedRequests,
    iconName: SvgNames.CentarixNotificationShared
}

export const requestCartNotifications: MenuItem = {
    description: "Notifications",
    href: Routes.REQUESTS_NOTIFICATIONS,
    navigationInfo: { pageType: PageTypeEnum.RequestCart } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Notifications,
    iconName: SvgNames.Notifications
}

export const requestCartDidntArrive: MenuItem = {
    description: "Didn't Arrive",
    href: Routes.REQUESTS_DIDNT_ARRIVE,
    navigationInfo: { pageType: PageTypeEnum.RequestCart } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.DidntArrive,
    iconName: SvgNames.LocalShipping
}

export const requestCartMyLists: MenuItem = {
    description: "My Lists",
    href: Routes.INDEX_TABLE,
    navigationInfo: { pageType: PageTypeEnum.RequestCart, sidebarType: SidebarEnum.MyLists, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.MyLists,
    iconName: SvgNames.LibraryBooks
}

export const requestCartSharedLists: MenuItem = {
    description: "Shared Lists",
    href: Routes.INDEX_TABLE,
    navigationInfo: { pageType: PageTypeEnum.RequestCart, sidebarType: SidebarEnum.SharedLists, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SharedLists,
    iconName: SvgNames.CentarixMainTab
}

export const requestSearchInventory: MenuItem = {
    description: "Inventory",
    href: Routes.SEARCH_REQUESTS,
    navigationInfo: { pageType: PageTypeEnum.RequestSearch, sectionType: MenuItemEnum.Requests } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Inventory,
    iconName: SvgNames.Search
}

export const requestLocationsStorageTypes: MenuItem = {
    description: "Storage Type",
    href: Routes.LOCATIONS_STORAGE_TYPE,
    navigationInfo: { pageType: PageTypeEnum.RequestLocation, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.StorageType,
    iconName: SvgNames.StorageType
}

export const requestLocationsLocationMap: MenuItem = {
    description: "Location Map",
    href: Routes.LOCATIONS_MAP,
    navigationInfo: { pageType: PageTypeEnum.RequestLocation } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.LocationMap,
    iconName: SvgNames.LocationMap
}
export const requestLocationsLabs: MenuItem = {
    description: "Labs",
    href: Routes.LOCATIONS_LABS,
    navigationInfo: { pageType: PageTypeEnum.RequestLocation } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Labs,
    iconName: SvgNames.LocationsLabs
}

// export const requestSummaryLastItem:MenuItem = {
// description: "Last Item",
// href: "",
// navigationInfo: {pageType:PageTypeEnum.RequestSummary, sidebarType:SidebarEnum.List, sectionType:MenuItemEnum.Requests} as NavigationInfo,
// sidebarTypeEnum : SidebarEnum.List,
// iconName: SvgNames.FormatListBulleted
// }

// export const requestSummaryAddItem:MenuItem = {
// description: "Add Item",
// href: "",
// navigationInfo: {pageType:PageTypeEnum.RequestSummary} as NavigationInfo,
// sidebarTypeEnum : SidebarEnum.Add,
// iconName: SvgNames.AddCircleOutline
// }

// export const requestSummaryType:MenuItem = {
// description: "Type",
// href: "",
// navigationInfo: {pageType:PageTypeEnum.RequestSummary} as NavigationInfo,
// sidebarTypeEnum : SidebarEnum.Type,
// iconName: SvgNames.Category
// }

// export const requestSummaryVendors:MenuItem = {
// description: "Vendors",
// href: "",
// navigationInfo: {pageType:PageTypeEnum.RequestSummary,  sectionType:MenuItemEnum.Requests} as NavigationInfo,
// sidebarTypeEnum : SidebarEnum.Vendors,
// iconName: SvgNames.Storefront
// }

// export const requestSummaryOwner:MenuItem = {
// description: "Owner",
// href: "",
// navigationInfo: {pageType:PageTypeEnum.RequestSummary} as NavigationInfo,
// sidebarTypeEnum : SidebarEnum.Owner,
// iconName: SvgNames.Face
// }

// export const requestSummarySearch:MenuItem = {
// description: "Search",
// href: "",
// navigationInfo: {pageType:PageTypeEnum.RequestSummary, sidebarType:SidebarEnum.Search, sectionType:MenuItemEnum.Requests} as NavigationInfo,
// sidebarTypeEnum : SidebarEnum.Search,
// iconName: "icon-zoom_in-24px-01"
// }

export const accountingNotificationsNoInvoice: MenuItem = {
    description: "No Invoice",
    href: Routes.NO_INVOICE,
    navigationInfo: { pageType: PageTypeEnum.AccountingNotifications } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.NoInvoice,
    iconName: SvgNames.CancelPresentation
}

export const accountingNotificationsMissingPaymentDetails: MenuItem = {
    description: "Missing Payment Details",
    href: Routes.MISSING_PAYMENT_DETAILS,
    navigationInfo: { pageType: PageTypeEnum.AccountingNotifications } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.MissingPaymentDetails,
    //MissingIcon
}

export const accountingNotificationsDidntArrive: MenuItem = {
    description: "Didn't Arrive",
    href: Routes.DIDNT_ARRIVE,
    navigationInfo: { pageType: PageTypeEnum.AccountingNotifications } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.DidntArrive,
    iconName: SvgNames.LocalShipping
}

export const accountingNotificationsPartialDelivery: MenuItem = {
    description: "Partial Delivery",
    href: Routes.PARTIAL_DELIVERY,
    navigationInfo: { pageType: PageTypeEnum.AccountingNotifications } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.PartialDelivery,
    iconName: SvgNames.RemoveShoppingCart
}

export const accountingNotificationsForClarification: MenuItem = {
    description: "For Clarification",
    href: Routes.FOR_CLARIFICATION,
    navigationInfo: { pageType: PageTypeEnum.AccountingNotifications } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.ForClarification,
    iconName: SvgNames.CentarixNotificationDidntArrive
}

export const accountingGeneralList: MenuItem = {
    description: "List",
    href: Routes.ACCOUNTING_GENERAL,
    navigationInfo: { pageType: PageTypeEnum.AccountingGeneral, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const accountingGeneralSearch: MenuItem = {
    description: "Search",
    href: Routes.SEARCH_REQUESTS,
    navigationInfo: { pageType: PageTypeEnum.AccountingGeneral, sidebarType: SidebarEnum.Search, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const accountingSuppliersAll: MenuItem = {
    description: "All",
    href: Routes.SUPPLIERS_LIST,
    navigationInfo: { pageType: PageTypeEnum.AccountingSuppliers, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.AllSuppliers,
    iconName: SvgNames.Storefront
}

export const accountingSuppliersNewSupplier: MenuItem = {
    description: "New Supplier",
    href: "/AddSupplier",
    navigationInfo: { pageType: PageTypeEnum.AccountingSuppliers, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.NewSupplier,
    iconName: SvgNames.AddCircleOutline
}

export const accountingSuppliersSearch: MenuItem = {
    description: "Search",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.AccountingSuppliers, sectionType: MenuItemEnum.Accounting } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const accountingPaymentsMonthlyPayment: MenuItem = {
    description: "Monthly Payment",
    href: Routes.MONTHLY_PAYMENT,
    navigationInfo: { pageType: PageTypeEnum.AccountingPayments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.MonthlyPayment,
    iconName: SvgNames.MonetizationOn
}

export const accountingPaymentsPayNow: MenuItem = {
    description: "Pay Now",
    href: Routes.PAY_NOW,
    navigationInfo: { pageType: PageTypeEnum.AccountingPayments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.PayNow,
    iconName: SvgNames.Payment
}

export const accountingPaymentsPayLater: MenuItem = {
    description: "Pay Later",
    href: Routes.PAY_LATER,
    navigationInfo: { pageType: PageTypeEnum.AccountingPayments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.PayLater,
    iconName: SvgNames.Timer
}

export const accountingPaymentsInstallments: MenuItem = {
    description: "Installments",
    href: Routes.INSTALLMENTS,
    navigationInfo: { pageType: PageTypeEnum.AccountingPayments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Installments,
    iconName: SvgNames.CentarixInstallments
}

export const accountingPaymentsStandingOrders: MenuItem = {
    description: "Standing Orders",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.AccountingPayments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.StandingOrders,
    iconName: SvgNames.CentarixStandingOrders
}

export const accountingPaymentsSpecifyPayment: MenuItem = {
    description: "Specify Payment",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.AccountingPayments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SpecifyPayment,
    iconName: SvgNames.CentarixStandingOrders
}
export const accountingSettingsBankAccounts: MenuItem = {
    description: "Bank Accounts",
    href: Routes.BANK_ACCOUNTS,
    navigationInfo: { pageType: PageTypeEnum.AccountingSettings } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.BankAccounts,
    iconName: SvgNames.Business
}
export const accountingSettingsCreditCards: MenuItem = {
    description: "Credit Cards",
    href: Routes.CREDIT_CARDS,
    navigationInfo: { pageType: PageTypeEnum.AccountingSettings } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.CreditCards,
    iconName: SvgNames.CreditCard
}

export const labManagementSuppliersAll: MenuItem = {
    description: "All",
    href: Routes.SUPPLIERS_LIST,
    navigationInfo: { pageType: PageTypeEnum.LabManagementSuppliers, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.AllSuppliers,
    iconName: SvgNames.Storefront
}

export const labManagementSuppliersNewSupplier: MenuItem = {
    description: "New Supplier",
    href: "/AddSupplier",
    navigationInfo: { pageType: PageTypeEnum.LabManagementSuppliers, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.NewSupplier,
    iconName: SvgNames.AddCircleOutline
}

export const labManagementSuppliersSearch: MenuItem = {
    description: "Search",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementSuppliers, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const labManagementLocationsStorageTypes: MenuItem = {
    description: "Storage Type",
    href: Routes.LOCATIONS_STORAGE_TYPE,
    navigationInfo: { pageType: PageTypeEnum.LabManagementLocations, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.StorageType,
    iconName: SvgNames.StorageType
}

export const labManagementLocationsLocationMap: MenuItem = {
    description: "Location Map",
    href: Routes.LOCATIONS_MAP,
    navigationInfo: { pageType: PageTypeEnum.LabManagementLocations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.LocationMap,
    iconName: SvgNames.LocationMap
}
export const labManagementLocationsLabs: MenuItem = {
    description: "Labs",
    href: Routes.LOCATIONS_LABS,
    navigationInfo: { pageType: PageTypeEnum.LabManagementLocations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Labs,
    iconName: SvgNames.LocationsLabs
}

export const labManagementEquipmentCalibrate: MenuItem = {
    description: "Calibrate",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementEquipment, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Calibrate,
    //iconName: "icon-calibrate-24px"
}

export const labManagementEquipmentList: MenuItem = {
    description: "List",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementEquipment, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const labManagementEquipmentCategories: MenuItem = {
    description: "Categories",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementEquipment } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Category,
    iconName: SvgNames.Category
}

export const labManagementEquipmentSearch: MenuItem = {
    description: "Search",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementEquipment, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const labManagementQuotesQuotes: MenuItem = {
    description: "Incoming Requests",
    href: Routes.LAB_MANAGE_QUOTES,
    navigationInfo: { pageType: PageTypeEnum.LabManagementQuotes } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Quotes,
    iconName: SvgNames.IncomingOrders
}

export const labManagementQuotesOrders: MenuItem = {
    description: "Ready to Order",
    href: Routes.LAB_MANAGE_ORDERS,
    navigationInfo: { pageType: PageTypeEnum.LabManagementQuotes } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Orders,
    iconName: SvgNames.ShoppingCart
}
export const labManagementPendingOrders: MenuItem = {
    description: "Pending Orders",
    href: Routes.LAB_MANAGE_PENDING_ORDERS,
    navigationInfo: { pageType: PageTypeEnum.LabManagementQuotes } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.PendingOrders,
    iconName: SvgNames.PendingOrders
}
export const labManagementSearchSearch: MenuItem = {
    description: "Search",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementSearch, sectionType: MenuItemEnum.LabManagement } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const labManagementSettingsInventory: MenuItem = {
    description: "Inventory",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.LabManagementSettings } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Inventory,
    //iconName: "icon-centarix-icons-05"
}

export const timeKeeperReportHours: MenuItem = {
    description: "Hours",
    href: Routes.REPORT_HOURS,
    navigationInfo: { pageType: PageTypeEnum.TimeKeeperReport } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.ReportHours,
    iconName: SvgNames.Assignment
}

export const timeKeeperReportDaysOff: MenuItem = {
    description: "Days Off",
    href:Routes.REPORT_DAYS_OFF,
    navigationInfo: { pageType: PageTypeEnum.TimeKeeperReport } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.ReportDaysOff,
    iconName: SvgNames.Event
}

export const timekeeperSummaryHours: MenuItem = {
    description: "Hours",
    href: Routes.SUMMARY_HOURS,
    navigationInfo: { pageType: PageTypeEnum.TimekeeperSummary } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SummaryHours,
    iconName: SvgNames.Schedule
}

export const timekeeperSummaryDaysOff: MenuItem = {
    description: "Days Off",
    href:Routes.SUMMARY_DAYS_OFF,
    navigationInfo: { pageType: PageTypeEnum.TimekeeperSummary } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SummaryDaysOff,
    iconName: SvgNames.HighlightOff
}

export const usersUserList: MenuItem = {
    description: "List",
    href: Routes.USERS_TABLE,
    navigationInfo: { pageType: PageTypeEnum.UsersUser } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const usersUserAdd: MenuItem = {
    description: "Add",
    href:Routes.ADD_USER,
    navigationInfo: { pageType: PageTypeEnum.UsersUser } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Add,
    iconName: SvgNames.AddCircleOutline,
    requiredRole: RoleDefinition.UsersAddUser
}

export const usersWorkersDetails: MenuItem = {
    description: "Details",
    href: Routes.EMPLOYEE_DETAILS,
    navigationInfo: { pageType: PageTypeEnum.UsersWorkers } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Details,
    iconName: SvgNames.Assignment
}

export const usersWorkersHours: MenuItem = {
    description: "Hours",
    href: Routes.EMPLOYEE_HOURS,
    navigationInfo: { pageType: PageTypeEnum.UsersWorkers } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Hours,
    iconName: SvgNames.Schedule,
    requiredRole: RoleDefinition.UsersViewHours
}

export const usersWorkersAwaitingApproval: MenuItem = {
    description: "Awaiting Approval",
    href: Routes.AWAITNG_APPROVAL,
    navigationInfo: { pageType: PageTypeEnum.UsersWorkers } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.AwaitingApproval,
    iconName: SvgNames.Pending,
    requiredRole: RoleDefinition.UsersApproveHours
}

export const operationsRequestLastItem: MenuItem = {
    description: "Last Item",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsRequest, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const operationsRequestAddItem: MenuItem = {
    description: "Add Item",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsRequest, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Add,
    iconName: SvgNames.AddCircle
}

export const operationsRequestType: MenuItem = {
    description: "Type",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsRequest, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Type,
    iconName: SvgNames.Category
}

export const operationsRequestVendor: MenuItem = {
    description: "Vendor",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsRequest, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Vendors,
    iconName: SvgNames.Storefront
}

export const operationsRequestOwner: MenuItem = {
    description: "Owner",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsRequest, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Owner,
    iconName: SvgNames.Face
}

export const operationsInventoryLastItem: MenuItem = {
    description: "Last Item",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsInventory, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const operationsInventoryAddItem: MenuItem = {
    description: "Add Item",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsInventory, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Add,
    iconName: SvgNames.AddCircleOutline
}

export const operationsInventoryType: MenuItem = {
    description: "Type",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsInventory, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Type,
    iconName: SvgNames.Category
}

export const operationsInventoryVendor: MenuItem = {
    description: "Vendor",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsInventory, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Vendors,
    iconName: SvgNames.Storefront
}

export const operationsInventoryOwner: MenuItem = {
    description: "Owner",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsInventory, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Owner,
    iconName: SvgNames.Face
}

export const operationsCartCart: MenuItem = {
    description: "Cart",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsCart, sidebarType: SidebarEnum.Cart, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Cart,
    iconName: SvgNames.ShoppingCart
}

export const operationsCartNotifications: MenuItem = {
    description: "Notifications",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsCart, sidebarType: SidebarEnum.Notifications, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Notifications,
    iconName: SvgNames.Notifications
}

export const operationsCartDidntArrive: MenuItem = {
    description: "Didn't Arrive",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.OperationsCart, sidebarType: SidebarEnum.DidntArrive, sectionType: MenuItemEnum.Operations } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.DidntArrive,
    iconName: SvgNames.LocalShipping
}

export const expensesSummaryPieCharts: MenuItem = {
    description: "Pie Charts",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesSummary } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.PieCharts,
    iconName: SvgNames.PieChart
}

export const expensesSummaryTables: MenuItem = {
    description: "Tables",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesSummary } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Tables,
    iconName: SvgNames.TableChart
}

export const expensesSummaryGraphs: MenuItem = {
    description: "Graphs",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesSummary } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Graphs,
    iconName: SvgNames.InsertChart
}

export const expensesStatisticsProject: MenuItem = {
    description: "Project",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesStatistics } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Project,
    iconName: SvgNames.FolderOpen
}

export const expensesStatisticsItem: MenuItem = {
    description: "Item",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesStatistics } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Item,
    //iconName: "icon-centarix-icons-05"
}

export const expensesStatisticsWorker: MenuItem = {
    description: "Worker",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesStatistics } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Worker,
    iconName: SvgNames.Face
}

export const expensesStatisticsCategory: MenuItem = {
    description: "Category",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesStatistics } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Category,
    iconName: SvgNames.Storefront
}

export const expensesStatisticsVendor: MenuItem = {
    description: "Vendor",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesStatistics } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Vendors,
    iconName: SvgNames.Storefront
}

export const expensesCostProject: MenuItem = {
    description: "Project",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesCost } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Project,
    iconName: SvgNames.ZoomIn
}

export const expensesCostAdvancedSearch: MenuItem = {
    description: "Advanced Search",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesCost } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Search,
    iconName: SvgNames.Search
}

export const expensesCostAdvancedList: MenuItem = {
    description: "Advanced List",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesCost } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.CentarixSearchList
}

export const expensesWorkersDetails: MenuItem = {
    description: "Details",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesWorkers } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Details,
    iconName: SvgNames.Assignment
}

export const expensesWorkersHours: MenuItem = {
    description: "Hours",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesWorkers } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Hours,
    iconName: SvgNames.Schedule
}

export const expensesWorkersSalary: MenuItem = {
    description: "Salary",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ExpensesWorkers } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Salary,
    iconName: SvgNames.MonetizationOn
}

export const protocolsWorkflowCurrentProtocols: MenuItem = {
    description: "Current Protocols",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsWorkflow } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.CurrentProtocols,
    iconName: SvgNames.Assignment
}

export const protocolsWorkflowProjects: MenuItem = {
    description: "Projects",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsWorkflow } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Project,
    iconName: SvgNames.Schedule
}

export const protocolsWorkflowSharedProjects: MenuItem = {
    description: "Shared Projects",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsWorkflow } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SharedProjects,
    iconName: SvgNames.CentarixNotificationShared
}

export const protocolsWorkflowCalendar: MenuItem = {
    description: "Calendar",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsWorkflow } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Calendar,
    iconName: SvgNames.MonetizationOn
}

export const protocolsProtocolsList: MenuItem = {
    description: "List",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsProtocols } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.List,
    iconName: SvgNames.FormatListBulleted
}

export const protocolsProtocolsMyProtocols: MenuItem = {
    description: "My Protocols",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsProtocols } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.MyProtocols,
    iconName: SvgNames.Schedule
}

export const protocolsProtocolsFavorites: MenuItem = {
    description: "Favorites",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsProtocols } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Favorites,
    iconName: SvgNames.FavoriteBorder
}

export const protocolsProtocolsSharedWithMe: MenuItem = {
    description: "Shared With Me",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsProtocols } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SharedWithMe,
    iconName: SvgNames.CentarixNotificationShared
}

export const protocolsProtocolsLastProtocol: MenuItem = {
    description: "Last Protocol",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsProtocols } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.LastProtocol,
    iconName: SvgNames.MonetizationOn
}

export const protocolsCreateResearchProtocol: MenuItem = {
    description: "Research Protocol",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsCreate } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.ResearchProtocol,
    iconName: SvgNames.Assignment
}

export const protocolsCreateKitProtocol: MenuItem = {
    description: "Kit Protocol",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsCreate } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.KitProtocol,
    iconName: SvgNames.Schedule
}

export const protocolsCreateSOPProtocol: MenuItem = {
    description: "SOP Protocol",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsCreate } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SOPProtocol,
    iconName: SvgNames.MonetizationOn
}

export const protocolsCreateBufferCreating: MenuItem = {
    description: "Buffer Creating",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsCreate } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.BufferCreating,
    //iconName: "icon-monetization_on-24px"
}

export const protocolsCreateRoboticProtocol: MenuItem = {
    description: "Robotic Protocol",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsCreate } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.RoboticProtocol,
    //iconName: "icon-monetization_on-24px"
}

export const protocolsCreateMaintenanceProtocol: MenuItem = {
    description: "Maintenance Protocol",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsCreate } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.MaintenanceProtocol,
    //iconName: "icon-monetization_on-24px"
}

export const protocolsReportsWeeklyReports: MenuItem = {
    description: "Weekly Reports",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsReports } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.WeeklyReports,
    //iconName: "icon-access_time-24px"
}

export const protocolsReportsFavorites: MenuItem = {
    description: "Favorites",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsReports } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Favorites,
    //iconName: "icon-favorite_border-24px"
}

export const protocolsResourcesLibrary: MenuItem = {
    description: "Library",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsResources } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Library,
    //iconName: "icon-assignment_ind-24px-1"
}

export const protocolsResourcesPersonal: MenuItem = {
    description: "Personal",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsResources } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Personal,
    //iconName: "icon-access_time-24px"
}

export const protocolsResourcesSharedWithMe: MenuItem = {
    description: "Shared With Me",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsResources } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.SharedWithMe,
    iconName: SvgNames.CentarixNotificationShared
}

export const protocolsResourcesFavorites: MenuItem = {
    description: "Favorites",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.ProtocolsResources } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.Favorites,
    iconName: SvgNames.FavoriteBorder
}

export const biomarkersExperimentsHumanTrials: MenuItem = {
    description: "Human Trials",
    href: "",
    navigationInfo: { pageType: PageTypeEnum.BiomarkersExperiments } as NavigationInfo,
    sidebarTypeEnum: SidebarEnum.HumanTrials,
    iconName: SvgNames.CentarixHumanTrials
}

