import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { Employee } from '../Utility/user-types'
import { EntryExitEnum, ModalFlow, ModalName, SvgNames } from "../Constants/app-enums";
import { TimekeeperNotification } from "../Utility/timekeeper-types";
import { Avatar, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BoldTypography, SectionOutlinedTableButton, SpacedColumnStack, SpacedRowStack, StyledError, StyledOutlineButton, StyledSectionOutlineButton, SvgIcon, TitleTypography } from "../Utility/shared-components";
import moment, { Moment } from "moment";
import { getDisplayNameOfEnumValue, getTimeDuration, objectToFormData } from "../Utility/root-function";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Label } from "recharts";
import Theme from "../Utility/theme";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { batch, useDispatch, useSelector } from "react-redux";
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ModalInfo, Modal, ApplicationState } from "../ReduxRelatedUtils/redux-types";
import { setViewModel } from "../ReduxRelatedUtils/viewmodel-reducer";
import { ViewModelBase, TimekeeperActionModalInfo, CustomError } from "../Utility/general-types";
import { setLoading, setPageSkeleton } from "../ReduxRelatedUtils/utilsReducer";
import IndexTableSkeleton from "../Shared/IndexTable/index-table-skeleton";

export type ReportHoursViewModel = ViewModelBase & {
    entryExitEnum: EntryExitEnum;
    entry?: Date;
    timekeeperNotifications: TimekeeperNotification[];
    daysWorkedThisWeek: HoursData[];
    applicationUser: Employee;
    hoursWorkedToday?: string
}

export type HoursData = {
    name: string,
    amt: number,
    hoursPerDay: number,
    date: Date
}

export default function ReportHours() {
    const [currentTime, setCurrentTime] = useState<Moment>(moment())
    const viewModel = useSelector<ApplicationState>(state => state.viewModel) as ReportHoursViewModel
    const dispatch = useDispatch()
    const showPageSkeleton = useSelector<ApplicationState>(state => state.showPageSkeleton) as boolean
    const [error, setError] = useState<CustomError>(new CustomError());

    //console.log(showPageSkeleton)
    useEffect(() => {
        console.log("report hours")
        if (showPageSkeleton) {
            var url = "/Timekeeper/ReportHours"
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: ReportHoursViewModel) => {
                    batch(() => {
                        dispatch(setPageSkeleton(false));
                        dispatch(setViewModel(result))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then( text => {
                        dispatch(setPageSkeleton(false));
                        setError({ message: text.errorMessage, showBody: false })
                        console.log(text)
                    }
                    )
                })
        }
    }, [showPageSkeleton])
    
    useEffect(() => {
        const timer = setInterval(() => {

            if(currentTime.date() != moment().date()){
                clearInterval(timer)
                window.location.href = window.location.href
            }
            else{
            setCurrentTime(moment())
            }
        }, 1000);

        return () => clearInterval(timer)

    }, []);

    function handleEntryExitClick() {

        switch (viewModel?.entryExitEnum) {
            case EntryExitEnum.Entry1:
            case EntryExitEnum.Entry2:
                dispatch(setLoading(true));
                fetch("/Timekeeper/ReportHours", {
                    method: "POST",
                    body: JSON.stringify(viewModel),
                    headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
                })
                    .then((response) => {
                        if (response.ok) { return response.text() }
                        throw response.text;
                    })
                    .then((result: string) => {
                        batch(() => {
                            dispatch(setPageSkeleton(true));

                            dispatch(setLoading(false));
                        })
                    })
                    .catch(err => {
                        Promise.resolve(err).then(text => {
                            dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                        })
                    })
                break;
            case EntryExitEnum.Exit1:
            case EntryExitEnum.Exit2:
                batch(() => {
                    dispatch(setModalLoading(true))
                    dispatch(setModal({ modalFlow: ModalFlow.ExitHours, modalStep: ModalName.ExitHours } as Modal<ModalInfo>))

                })

        }
    }

    function deleteNotification(notificationID: number) {
        dispatch(setLoading(true))
        fetch("/Timekeeper/DeleteNotification", {
            method: "POST",
            body: objectToFormData({ id: notificationID })
        })
            .then((response) => {
                if (response.ok) { return response.text() }
                throw response.text();
            })
            .then((result: string) => {
                batch(() => {
                    dispatch(setLoading(false))

                    dispatch(setGlobalSnack({ open: true, message: "Notification Deleted", severity: "success" }))
                    dispatch(setPageSkeleton(true))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })

    }

    function getEntryExitButtonText() {
        switch (viewModel?.entryExitEnum) {
            case EntryExitEnum.Entry1:
                return "Entry"
            case EntryExitEnum.Entry2:
                return "Entry 2"
            case EntryExitEnum.Exit1:
                return "Exit"
            case EntryExitEnum.Exit2:
                return "Exit 2"
            default:
                return "Entry"
        }
    }

    function openUpdateHoursModal(workFromHome: boolean, date?: Date, employeeHoursID?: number) {
        batch(() => {
            dispatch(setModalLoading(true))
            dispatch(setModal({ modalFlow: ModalFlow.UpdateHours, modalStep: ModalName.UpdateHours, modalInfo: { ids: employeeHoursID ? [employeeHoursID.toString()] : [], typeID: workFromHome ? 1 : null, date: date ?? new Date() } } as Modal<TimekeeperActionModalInfo>))
        })
    }


    return (showPageSkeleton ? <IndexTableSkeleton /> :
    <>
        {error.message && <StyledError>{error.message}</StyledError>}
        {error.showBody && 
        <Box marginBottom={"9.5vh"}>
            <SpacedColumnStack>
                <Stack direction="row" justifyContent={"space-between"}>
                    <SpacedRowStack key="3" alignItems="center">
                        <Avatar alt="userImage" sx={{ width: 90, height: 90 }} src={viewModel?.applicationUser?.userImage?.split("\\").slice(-2).join("\\")} />
                        <Stack spacing={0.5}>
                            <Typography key="t1" variant="h5" fontWeight="500">Welcome!</Typography>
                            <Typography key="t2" variant="h5">{`${viewModel?.applicationUser?.firstName} ${viewModel?.applicationUser?.lastName}`}</Typography>
                        </Stack>
                    </SpacedRowStack>
                    <SpacedRowStack key="4" alignItems="center">
                        <Typography color={theme => theme.palette.grey[600]} variant="h5">{moment().format("dddd, MMMM D, YYYY")}</Typography>
                        <Box borderRadius={"0.3rem"} padding="1.5rem 3rem" boxShadow={theme => "inset 0 0 0.3rem 0.3rem " + theme.palette.grey[200]}>
                            <Typography color={theme => theme.palette.grey[600]} fontWeight="500" variant="h4">{currentTime.format("HH:mm")}</Typography>
                        </Box>
                    </SpacedRowStack>
                </Stack>
                <Box key="box1" width="100%" className="section-outline-color" padding="4rem" borderRadius={"0.3rem"}>
                    <Stack direction="row" justifyContent="space-between" width="100%" alignItems={"center"}>
                        <Stack width="40%" spacing={3}  padding={"2rem"}>
                            <Button
                                disabled={viewModel.entryExitEnum == EntryExitEnum.None}
                                onDoubleClick={handleEntryExitClick}
                                fullWidth
                                sx={{
                                    backgroundColor: viewModel.entryExitEnum?.includes("Exit") ? "#E95252" : "#00CA72",
                                    color: "white", height: "3.5rem",
                                    fontSize: "1.5rem",
                                    "&:hover": {
                                        backgroundColor: viewModel.entryExitEnum?.includes("Exit") ? "#E95252" : "#00CA72",
                                    }
                                }}
                                startIcon={<SvgIcon height={1.5} name={viewModel.entryExitEnum?.includes("Exit") ? SvgNames.Exit : SvgNames.Entry} className="icon-white" />}>
                                {getEntryExitButtonText()}
                            </Button>
                            {viewModel.entry ?
                                <Typography textAlign="center" color={"#E95252"} variant="h5">{`Entered ${moment(viewModel.entry).format("HH:mm")}, Working ${getTimeDuration(currentTime, moment(viewModel.entry))}`}</Typography>
                                :
                                <Typography textAlign="center" color={theme => theme.palette.grey[600]} variant="h5">{`${viewModel.hoursWorkedToday ? moment.duration(viewModel.hoursWorkedToday).hours() + ":" + moment.duration(viewModel.hoursWorkedToday).minutes().toString().padStart(2, "0") : 0} hours recorded today`}</Typography>
                                
                            }
                            <SpacedRowStack width="100%">
                                <SectionOutlinedTableButton key="updateHours" sx={{ width: "100%" }} onClick={() => openUpdateHoursModal(false)}>Update Hours</SectionOutlinedTableButton>
                                <SectionOutlinedTableButton key="workFromHome" sx={{ width: "100%" }} onClick={() => openUpdateHoursModal(true)}>Report Work From Home</SectionOutlinedTableButton>
                            </SpacedRowStack>
                        </Stack>
                        <Box display="flex" marginTop={"2rem"} marginRight={"2rem"} alignItems="center">
                            <BarChart margin={{ top: 10, right: 30, left: 55, bottom: 20 }} barGap={-47.5} width={600} height={300} data={viewModel.daysWorkedThisWeek}>
                                <CartesianGrid horizontal vertical={false} stroke={Theme.palette.grey[500]} />

                                <XAxis dataKey="name" tickLine={false} tick={{ fill: Theme.palette.grey[500] }} />
                                {/* {viewModel.daysWorkedThisWeek.map(d => <Label color="#FF9800" fontWeight="bold" value={moment(d.date).format("d/M")}/>)}
                                    </XAxis> */}


                                <YAxis tickCount={10} tickFormatter={tick => Number.isInteger(tick) ? tick : tick.toFixed(2)} interval={0} domain={[0, 10]} tickLine={false} tick={{ fill: Theme.palette.grey[500] }} />
                                <Bar radius={[5, 5, 0, 0]} barSize={55} dataKey="hoursPerDay" opacity={"0.8"} fill={Theme.palette.grey[300]} />
                                <Bar radius={[5, 5, 0, 0]} barSize={40} dataKey="amt" className={"section-fill"} />


                            </BarChart>
                        </Box>
                    </Stack>
                </Box>
                <SpacedColumnStack>
                {viewModel.timekeeperNotifications?.slice(0, 20).map(tn => {
                    return <Box key={tn.notificationID} width="100%" borderRadius="0.3rem" padding="1rem" border={theme => "1px solid " + theme.palette.error.main}>
                        <Stack direction="row" justifyContent="space-between">
                            <SpacedRowStack key="1" alignItems="center">
                                <SvgIcon height={2} name={SvgNames.CentarixNotificationDidntArrive} className="icon-red" />
                                <Typography>{"No hours reported for " + moment(tn.notificationDate).format("DD/MM/yyyy")}</Typography>
                            </SpacedRowStack>
                            <SpacedRowStack key="2" alignItems="center">
                                <Button
                                    onClick={() => openUpdateHoursModal(false, tn.notificationDate, tn.employeeHoursID)}
                                    sx={{ width: "10rem", border: theme => "1px solid" + theme.palette.error.main, padding: "0rem 0.5rem", borderRadius: "0.5rem", color: theme => theme.palette.error.main, fontSize: theme => theme.typography.fontSize }}>
                                    Update Now
                                </Button>
                                <Box onClick={() => deleteNotification(tn.notificationID)}>
                                    <SvgIcon height={1} name={SvgNames.Clear} />
                                </Box>
                            </SpacedRowStack>
                        </Stack>
                    </Box>
                })}
                </SpacedColumnStack>
            </SpacedColumnStack>
        </Box>}
                </>
    )
}