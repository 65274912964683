import { FormControlLabel, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectEditDrawerViewModelByID, selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { ApplicationState, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { AddUserFormData, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { StyledControlledAutoCompleteInput, StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledFreeSoloAutoCompleteInput, StyledControlledMultipleSelect, StyledControlledRadioButton, StyledControlledRadioGroup, StyledFormInput } from "../Utility/custom-input-components";
import { AutocompleteOption } from "../Utility/general-types";
import { SpacedColumnStack, SpacedRowStack } from "../Utility/shared-components";
import { JobSubcategoryType, Team, Track } from "../Utility/user-types";

export default function DetailsTab() {
    const dispatch = useDispatch()
    const { watch, setValue, control, formState: { errors } } = useFormContext<AddUserFormData>();
    const { sidebarType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo
    const userID = watch("employee.id")
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, userID)?.viewModel) as AddUserViewModel;

    const [probableCentarixID, setProbableCentarixID] = useState(viewModel.centarixID ?? "")
    const [jobSubcategoryTypes, setJobSubcategoryTypes] = useState<AutocompleteOption[]>([])
    const [teams, setTeams] = useState<AutocompleteOption[]>(viewModel.teams)
    const [tracks, setTracks] = useState<AutocompleteOption[]>(viewModel.tracks)



    //useEffect - radio on centarix ID


    const jobCategoryTypeID = watch("employee.jobSubcategoryType.jobCategoryTypeID")
    const dob = watch("employee.dob")
    const employeeStatusID = watch("employee.employeeStatusID")
    const jobSubcategoryTypeID = watch("employee.jobSubcategoryTypeID")
    const team = watch("employee.team")
    const teamID = watch("employee.teamID")
    const track = watch("employee.track")
    const trackID = watch("employee.trackID")

    useEffect(() => {
        if (employeeStatusID != viewModel.employee.employeeStatusID) {
            fetch("/Users/GetProbableNextCentarixID?StatusID=" + employeeStatusID, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.text() }
                    else { throw response.text() }
                })
                .then((result: string) => {
                    setProbableCentarixID(result)

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
        }
        else {
            setProbableCentarixID(viewModel.centarixID)

        }
    }, [employeeStatusID])

    useEffect(() => {
        console.log("in load tab")
        if (teamID == -1){
            console.log(" update teams")
            setTeams([...viewModel.teams, {text: team.teamDescription, value:-1}])
        }
        if (trackID == -1){
            setTracks([...viewModel.tracks, {text: track.trackDescription, value:-1}])
        }
    }, [])

    useEffect(() => {
        const birth = moment(dob, "YYYY-MM-DD")
        const current = moment();

        const diffDuration = moment.duration(current.diff(birth));
        const age = diffDuration.years()
        setValue("employee.age", age)
    }, [dob])

    useEffect(() => {

        if (viewModel?.jobSubcategoryTypes?.filter(js => js.jobCategoryTypeID == jobCategoryTypeID).map(js => js.jobSubcategoryTypeID).findIndex(js => js == jobSubcategoryTypeID) == -1) {
            console.log("clearing jobsubcategory")
            setValue("employee.jobSubcategoryTypeID", 0)
        }
        setJobSubcategoryTypes(viewModel?.jobSubcategoryTypes?.filter(js => js.jobCategoryTypeID == jobCategoryTypeID).map(js => { return { text: js.description, value: js.jobSubcategoryTypeID } as AutocompleteOption }))
    }, [jobCategoryTypeID])

    function handleJobSubcategoryChange(e: any) {
        console.log(e)
        if (e) {
            if (!jobSubcategoryTypes.includes(e)) {
                if (e.target.value == "") {
                    setValue("employee.jobSubcategoryTypeID", 0)
                }
                else {
                    setValue("employee.jobSubcategoryTypeID", -1)
                    setValue("employee.jobSubcategoryType", { description: e.target.value, jobCategoryTypeID: jobCategoryTypeID } as JobSubcategoryType)
                }
            }
            else {
                setValue("employee.jobSubcategoryTypeID", e.value)
            }
        }
        else {
            setValue("employee.jobSubcategoryTypeID", 0)
        }
    }
    function handleTeamChange(e: any) {
        console.log(e)
        if (e) {
            if (!viewModel.teams.includes(e)) {
                if (e.target.value == "") {
                    setValue("employee.teamID", undefined)

                }
                else {
                    setValue("employee.teamID", -1)
                    setValue("employee.team", { teamDescription: e.target.value } as Team)
                }
            }
            else {
                setValue("employee.teamID", e.value)
            }
        }
        else {
            setValue("employee.teamID", undefined)
        }
    }
    function handleTrackChange(e: any) {
        console.log(e)
        if (e) {
            if (!viewModel.tracks.includes(e)) {
                if (e.target.value == "") {
                    setValue("employee.trackID", undefined)
                }
                else {
                    setValue("employee.trackID", -1)
                    setValue("employee.track", { trackDescription: e.target.value } as Track)
                }
            }
            else {
                setValue("employee.trackID", e.value)
            }
        }
        else {
            setValue("employee.trackID", undefined)
        }
    }
    return (
        <SpacedColumnStack paddingBottom={"2rem"}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StyledControlledRadioGroup name="employee.employeeStatusID">
                        <FormControlLabel sx={{ marginRight: "3rem" }} key={"employee"} value={1}
                            control={<StyledControlledRadioButton />}
                            label={<Typography>Employee</Typography>} />
                        <FormControlLabel sx={{ marginRight: "3rem" }} key={"freelancer"} value={2}
                            control={<StyledControlledRadioButton />}
                            label={<Typography>Freelancer</Typography>} />
                        <FormControlLabel sx={{ marginRight: "3rem" }} key={"advisor"} value={3}
                            control={<StyledControlledRadioButton />}
                            label={<Typography>Advisor</Typography>} />
                        <FormControlLabel sx={{ marginRight: "3rem" }} key={"user"} value={4}
                            control={<StyledControlledRadioButton />}
                            label={<Typography>User</Typography>} />
                    </StyledControlledRadioGroup>
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput name="employee.firstName" label="First Name*" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput name="employee.lastName" label="Last Name" />
                </Grid>
                <Grid item xs={6}>
                    <StyledFormInput readOnly value={probableCentarixID} label="Centarix ID" />
                </Grid>
                {employeeStatusID != 4 &&
                    <>
                        <Grid item xs={6}>
                            <StyledControlledFormInput name="employee.idNumber" label="ID Number*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFormDateInput maxDate={new Date()} name="employee.startedWorking" label="Started Working*" />
                        </Grid>
                    </>
                }
                <Grid item xs={6}>
                    <StyledControlledFormInput name="employee.email" label="Email Address*" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput name="employee.phoneNumber" label="Phone Number*" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput name="employee.phoneNumber2" label="Phone Number 2" />
                </Grid>
                {employeeStatusID != 4 &&
                    <>
                        <Grid item xs={6}>
                            <StyledControlledFormDateInput maxDate={new Date()} name="employee.dob" label="Date of Birth*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFormInput disabled name="employee.age" label="Age" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledAutoCompleteInput options={viewModel?.jobCategoryTypes} name="employee.jobSubcategoryType.jobCategoryTypeID" label="Job Category*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFreeSoloAutoCompleteInput key={"js" + JSON.stringify(jobSubcategoryTypes)} onChange={handleJobSubcategoryChange} options={jobSubcategoryTypes} name="employee.jobSubcategoryTypeID" label="Job Title*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFreeSoloAutoCompleteInput key={"team" + JSON.stringify(teams)} onChange={handleTeamChange} options={teams} name="employee.teamID" label="Team" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFreeSoloAutoCompleteInput key={"tracks" + JSON.stringify(tracks)} onChange={handleTrackChange} options={tracks} name="employee.trackID" label="Track" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledMultipleSelect options={viewModel?.labs} name="labs" label="Labs" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledAutoCompleteInput options={viewModel?.degrees} name="employee.degreeID" label="Degree*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledAutoCompleteInput options={viewModel?.maritalStatuses} name="employee.maritalStatusID" label="Relationship Status*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledAutoCompleteInput options={viewModel?.citizenships} name="employee.citizenshipID" label="Citizenship*" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFormInput type="number" name="employee.taxCredits" label="Tax Credits*" />
                        </Grid>
                    </>
                }
            </Grid>
        </SpacedColumnStack>
    )
}