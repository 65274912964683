import { Stack } from "@mui/material";
import { merge } from 'lodash';
import { createContext, useEffect, useRef, useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ItemCardFields, ParentCategoryEnum, ModalFlow, ModalName, SvgNames, MenuItemEnum, RoleDefinition } from "../../Constants/app-enums";
import { CardTabsType } from "../../Constants/client-side-enums";
import { selectEditDrawerViewModelByID, selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { setError } from "../../ReduxRelatedUtils/errorReducer";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../../ReduxRelatedUtils/index-table-reducer";
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { setModal, setModalLoading } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, InfoPopup, LoggedInUser, Modal, NavigationInfo, TempData } from "../../ReduxRelatedUtils/redux-types";
import { setTempData } from "../../ReduxRelatedUtils/temp-data-reducer";
import { AddItemFormData, AddSupplierFormData, AddSupplierViewModel } from "../../Shared/AddCardComponents/add-types";
import { CardTabs, GlobalTab, Tab } from "../../Shared/AddCardComponents/card-tabs";
import EditCard, { EditCardContext, EditCardType } from "../../Shared/edit-card";
import EditDrawerSkeleton from "../../Shared/edit-drawer-skeleton";
import { StyledFormInputTitle } from "../../Utility/custom-input-components";
import { BasicModalInfo, EditDrawerTempData, RowViewModel } from "../../Utility/general-types";
import { Request, RequestItemViewModel } from "../../Utility/request-types";
import { DocumentTypeWithID, getAddItemCardFieldTypesByCategory, getDocumentTypesByCategory } from "../../Utility/root-function";
import { IsEdittableContext, SpacedColumnStack, StyledFilledButton, StyledNoOutlineButton, StyledTableImage, SvgIcon } from "../../Utility/shared-components";
import CommentsTab from "./comments-tab";
import { ItemTabs } from "./item-card-tabs";
import { DocumentsTab, getTabs } from "./item-components";
import ItemTab from "./item-details-tab";
import PriceTab from "./price-tab";
import Typography from "@mui/material/Typography";

import queryString from 'query-string'

import { removeEditDrawerViewModel, setAllReloadEditModal, setReloadEditModal, setEditDrawerViewModel } from "../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import React from "react";
import { push } from "connected-react-router";
import { setInfoPopup } from "../../ReduxRelatedUtils/infoPopupReducer";
import { ConfirmSaveModal } from "../Modals/confirm-save-modal";
export function EditItemInnerForm(props: { id?: string, dontAllowEdits?: boolean }) {
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const { watch, setValue, getValues, reset, formState:{isDirty, dirtyFields}} = useFormContext<TempData<AddItemFormData>>();
    const [disabled, setDisabled] = useState(true);
    const navigationInfo = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo;
    const [view, setView] = useState<{ fields: ItemCardFields[], docCards: DocumentTypeWithID[] }>();
    const dispatch = useDispatch();
    const isEdittableContext = useContext(IsEdittableContext);
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.id ?? 0)?.viewModel) as RequestItemViewModel;
    const reloadEditModal = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.id ?? 0)?.reloadEditModal) as boolean;
    const productSubcategoryID = watch("request.product.productSubcategoryID");
    const parentCategory = watch("request.product.productSubcategory.parentCategory");
    const { userRoles } = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const requestByProduct = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.id ?? 0)?.viewModel as RequestItemViewModel)?.requestsByProduct) as  RowViewModel[];

    const [disablePriceEdit, setDisablePriceEdit] = useState(false)
    const [tabs, setTabs] = useState<Tab[]>([])
    const [didMount, setDidMount] = useState(false);
    const request = watch("request")
    const parentCategoryDidMount = useRef(false)
    const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);
    useEffect(() => {
        console.log(didMount)
        if (didMount) {
            console.log("get tabs")
            setTabs(getTabs(true, viewModel.request?.product.productSubcategory.parentCategory.parentCategoryDescriptionEnum == ParentCategoryEnum.Samples, viewModel.request?.requestStatusID == 3, false, viewModel?.request?.payments?.length > 0));
        }
    }, [didMount])

    useEffect(() => {
        if (parentCategory != undefined) {
            console.log(parentCategory)
            console.log(viewModel?.request?.product?.productSubcategory?.parentCategory)
            if (viewModel?.additionalCardFields != undefined) {
                
                if (viewModel?.request?.product?.productSubcategory?.parentCategory.parentCategoryDescriptionEnum !=parentCategory.parentCategoryDescriptionEnum) { //we dont want this to run an extra time
                    setView({
                        fields: (getAddItemCardFieldTypesByCategory(parentCategory?.parentCategoryDescriptionEnum) ?? []).concat(viewModel?.additionalCardFields ?? []),
                        docCards: getDocumentTypesByCategory("", request.requestID, parentCategory?.parentCategoryDescriptionEnum) ?? []
                    })
                    
                }
            }


        }

    }, [parentCategory]);
    useEffect(() => {
        if (didMount) {
            let productSubcategory = viewModel?.productSubcategories?.filter(p => p.id == productSubcategoryID)?.[0];
            if (productSubcategory != undefined) {
                let parentCategory = viewModel?.parentCategories?.filter(p => p.id == productSubcategory.parentCategoryID)?.[0];
                if (viewModel?.additionalCardFields != undefined) {
                    setView({
                        fields: (getAddItemCardFieldTypesByCategory(parentCategory?.parentCategoryDescriptionEnum, productSubcategory?.description) ?? []).concat(viewModel?.additionalCardFields ?? []),
                        docCards: getDocumentTypesByCategory("", viewModel?.request?.requestID, parentCategory?.parentCategoryDescriptionEnum, productSubcategory?.description) ?? []
                    })
                }
            }
        }

    }, [productSubcategoryID]);
    useEffect(() => {
        dispatch(setReloadEditModal({ reloadEditModal: true, id: props.id ?? "0" }))
    }, [])

    useEffect(() => {
        if (reloadEditModal) {
            setDidMount(false)
            setConfirmSaveModalOpen(false);
            fetch("/Requests/GetEditModalJson" + "?id=" + props.id, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: RequestItemViewModel) => {

                    let requestResult = result.request;
                    var docCards = getDocumentTypesByCategory("", requestResult?.requestID, requestResult?.product?.productSubcategory?.parentCategory?.parentCategoryDescriptionEnum, requestResult?.product.productSubcategory.description) ?? []
                    console.log(docCards)
                    setView({
                        fields: (getAddItemCardFieldTypesByCategory(requestResult?.product?.productSubcategory?.parentCategory?.parentCategoryDescriptionEnum, requestResult?.product.productSubcategory.description) ?? []).concat(result.additionalCardFields),
                        docCards: docCards.concat(result.additionalSpecificDocFolders)
                    })
                    if (result.selectLocationViewModel?.error?.bool) {
                        reset(merge(JSON.parse(JSON.stringify(getValues())), { request: requestResult }));
                    }
                    else {
                        reset(merge(JSON.parse(JSON.stringify(getValues())), { ...result.selectLocationFormData, request: requestResult }));
                    }
                    setValue("comments", result.comments);
                    setValue("isSample", result.isSample)
                    var guid = v4();
                    setValue("guid", guid)
                    setDisablePriceEdit(result.request != undefined && result.request.requestStatusID == 3 &&
                        !userRoles.find(ur => ur.mainRole == MenuItemEnum.Requests)?.subRoles.includes(RoleDefinition.RequestsEditReceived))
                    dispatch(setEditDrawerViewModel({ viewModel: result, id: props.id ?? "0", reloadEditModal: false }))
                    console.log("setting did mount " + didMount)
                    setDidMount(true)

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setReloadEditModal({ reloadEditModal: false, id: props.id ?? "0" }))

                        dispatch(setError({ message: text.toString(), showBody: false }))
                    })
                })
        }

    }, [reloadEditModal]);



    function discardChanges() {
        //      console.log("in discard changes")
        isEdittableContext.toggleIsEdittable(false)

        batch(() => {
            dispatch(setReloadEditModal({ reloadEditModal: true, id: props.id ?? "0" }))
            dispatch(setGlobalSnack({ open: true, message: "Discarded Item Changes" }))
        })

    }
    function setEditable() {
        isEdittableContext.toggleIsEdittable(true)
    }


    return (reloadEditModal || viewModel == undefined ? <EditDrawerSkeleton /> : <>
        <Stack sx={{ padding: "3rem" }} direction="row" alignItems="center" justifyContent={"space-between"}>
            <Stack direction="row" spacing={2} alignItems="center">
                <StyledTableImage width={6} src={viewModel?.productSubcategories?.filter(p => p.id == productSubcategoryID)?.[0]?.imageURL ?? ""} />
                <Stack spacing={1}>
                    <StyledFormInputTitle placeholder="Product Name" name="request.product.productName" />
                    <Typography color={theme => theme.palette.grey[500]}>{`Product Serial: ${viewModel?.request?.product?.serialNumber} | Item Serial: ${viewModel?.request?.serialNumber}`}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={1.5}>
                {!props.dontAllowEdits && !isEdittableContext.isEdittable &&
                    <>
                        <StyledNoOutlineButton endIcon={<SvgIcon name={SvgNames.Create} className="section-fill" height={1.5} />} onClick={setEditable}>
                            Edit Item
                        </StyledNoOutlineButton>
                        {viewModel.request?.product.productSubcategory.parentCategory.parentCategoryDescriptionEnum != ParentCategoryEnum.Samples &&
                            (userRoles.find(ur => ur.mainRole == MenuItemEnum.Requests)?.subRoles.includes(RoleDefinition.RequestsRequestItem) || userRoles.find(ur => ur.mainRole == MenuItemEnum.Requests)?.subRoles.includes(RoleDefinition.RequestsOrderItem)) &&
                            <StyledFilledButton onClick={() => {
                                //console.log("click request button"); 
                                dispatch(setModalLoading(true));
                                dispatch(setModal({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.Reorder, modalInfo: { ids: [viewModel.request?.requestID.toString()] } } as Modal<BasicModalInfo>))
                            }}>Request</StyledFilledButton>}
                    </>}

                {isEdittableContext.isEdittable &&
                    <>
                        <StyledNoOutlineButton onClick={discardChanges}>Discard Edits</StyledNoOutlineButton>

                      
                        {dirtyFields.request?.product ==undefined || requestByProduct?.length==undefined ||requestByProduct?.length==0?
                            <StyledFilledButton form={EditCardType.EditItem + props.id} type="submit">Save Edits</StyledFilledButton>
                        :<>
                            <StyledFilledButton form={EditCardType.EditItem+  props.id} onClick={()=>setConfirmSaveModalOpen(true)} >Save Edits</StyledFilledButton>
                            {confirmSaveModalOpen &&  <ConfirmSaveModal formID={props.id?.toString()??""} editType={EditCardType.EditItem}  setConfirmModalOpen={setConfirmSaveModalOpen} />}
                        </>}
                  
                    </>
                }
            </Stack>
        </Stack>

        <PriceEdittableContext.Provider value={{ disablePriceEdit: disablePriceEdit }}>
            <CardTabs key={CardTabsType.EditDrawer} cardTabType={CardTabsType.EditDrawer} selectedTab={0} tabs={tabs} >
                <ItemTabs cardType={CardTabsType.EditDrawer} key={"itemtabs"} tabs={tabs} view={view} editID={props.id} />
            </CardTabs>
        </PriceEdittableContext.Provider>
    </>)
}

export function onSubmitItemEdit(data: AddItemFormData, dispatch: Function, setSubmitSucceeded: Function, e: any, location: any, isInfoPopup: boolean) {
    dispatch(setLoading(true))
    fetch("/Requests/EditModalView", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
    }).then(response => {
        if (response.ok) { return response.json() }
        else { throw response.json() }
    }).then((result) => {
        let newPathname = location.pathname.replace("/" + EditCardType.EditItem, "")
        if (e.nativeEvent.submitter.name == "exit") {
            batch(() => {

                dispatch(removeEditDrawerViewModel({ id: data.request.requestID.toString() }))
                if (isInfoPopup) {
                    dispatch(setInfoPopup({} as InfoPopup))
                }
                else {
                    dispatch(push(queryString.stringifyUrl({ url: newPathname, query: { pageType: location.query.pageType, sectionType: location.query.sectionType, sidebarType: location.query.sidebarType } })))
                    dispatch(setError({ showBody: true, message: "" }))
                }


            })
        }
        else {
            batch(() => {

                dispatch(setGlobalSnack({ open: true, message: "Saved Item Changes" }))
            })
        }
        setSubmitSucceeded(true)

        batch(() => {
            dispatch(setAllReloadEditModal({ reloadEditModal: true }))
            dispatch(setLoading(false))
            dispatch(setReloadIndex(true));
        })

    })
        .catch(err => {
            Promise.resolve(err).then(text => {
                //   console.log("in submit error")

                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.result, severity: "error" }))
                })
            })
        })
}

export default function EditItem() {
    const dispatch = useDispatch();
    const [submitSucceeded, setSubmitSucceeded] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    function onSubmit(data: AddItemFormData, e: any) {
        onSubmitItemEdit(data, dispatch, setSubmitSucceeded, e, location, false)
    }

    return (
        <EditCardContext.Provider value={{ submitSucceeded: submitSucceeded, toggleSubmitSucceeded: setSubmitSucceeded, toggleConfirmModalOpen: setConfirmModalOpen, confirmModalOpen: confirmModalOpen }}>
            <EditCard editType={EditCardType.EditItem} onSubmit={(data, e) => onSubmit(data, e)} />
        </EditCardContext.Provider>
    )
}

export const PriceEdittableContext = React.createContext({ disablePriceEdit: false });


