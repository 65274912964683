import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, Typography } from "@mui/material";
import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { SvgNames } from "../../Constants/app-enums";
import { CardTabsType, TabsName } from "../../Constants/client-side-enums";
import { CustomSwitch, IsEdittableContext, SvgIcon, TitleTypography } from "../../Utility/shared-components";
//import { SvgIcon } from "../Utility/shared-components";

type ItemCardProps = {
  tabs: Tab[]
  selectedTab: number
  cardTabType:CardTabsType
}
export type Tab = {
  name: TabsName,
  icon: SvgNames

}
export function CardTabs(props: PropsWithChildren<ItemCardProps>) {

  const { trigger, setValue, watch, formState} = useFormContext();
 const currentTab = watch("currentTab")
 const [tabValue, setTabValue] = useState(0)

 useEffect(()=>{
  setTabValue(props.tabs.findIndex(t=> t.name == currentTab)==-1?0:props.tabs.findIndex(t=> t.name == currentTab))
 }, [currentTab])
  async function handleChange(event: any, newValue: number) {
    console.log("change tabs")
    trigger().then((isValid) => {
console.log(isValid)
      if (isValid || (newValue < tabValue)) {
        setValue("currentTab", props.tabs[newValue].name)
      }
    }
    )
  };

  return (
    <TabContext value={tabValue.toString()} >
      <TabList variant="scrollable" sx={{flexShrink:"0", "& .MuiTabs-flexContainer": { justifyContent: "space-around"}, borderBottom:(theme)=>props.cardTabType==CardTabsType.AddCard?"solid .125rem": ` 0.125rem solid ${theme.palette.grey[300]}`}} onChange={handleChange} >
        {props.tabs.map((t, i) => {
          let colorClass = tabValue == i ? "section-color" : "gray-700";
          let fillClass = tabValue == i ? "section-fill" : "gray-700-fill";
          return (<Tab key={t.name} value={i.toString()} className={colorClass} icon={<SvgIcon className={fillClass} height={1.875} name={t.icon as SvgNames} />} label={t.name} sx={{ fontSize: '1.125rem', fontWeight: 'regular' }} />)
        })}
      </TabList>
      {props.children}
    </TabContext>
  )
}

type TabProps ={
  tab:Tab,
  index: number,
  cardType?: CardTabsType
}
export function GlobalTab(props:PropsWithChildren<TabProps>){
  const isEdittableContext = useContext(IsEdittableContext);
  function switchChange(){
    isEdittableContext.toggleIsEdittable(!isEdittableContext.isEdittable);
  }

return(
  <TabPanel sx={{overflow:"auto", flexGrow:"1"}} key={props.tab.name} value={props.index.toString()}>
  <Stack >
  <Stack justifyContent="space-between" direction="row" paddingBottom={"1rem"}>
      <TitleTypography>{props.tab.name}</TitleTypography>
   
  </Stack>
  <Box  flexGrow={1}>
      {props.children}
      </Box>
      </Stack>
</TabPanel>
);
}

