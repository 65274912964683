import { yupResolver } from '@hookform/resolvers/yup';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { AnySchema, array, boolean, date, mixed, number, object, string } from 'yup';
import { CurrencyEnum, FolderNamesEnum, ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledCurrencyFormInput, StyledPercentFormInput } from '../../Utility/custom-input-components';
import { AutocompleteOption, BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Country, ParentQuote, Request, Vendor } from '../../Utility/request-types';
import { handleCalendarUnitCalcChange, handleDateCalcChange } from '../../Utility/root-function';
import { GlobalForm, ModalItemTableCell, ModalItemTableRow, SectionCheckbox, SpacedColumnStack, StyledTableImage } from '../../Utility/shared-components';
import Theme from '../../Utility/theme';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


export type EditQuoteDetailsViewModel = {
    requests: Request[],
    parentQuote: ParentQuote,
    exchangeRate: number,
    vendor: Vendor
}
export type EditQuoteDetailsFormData = {
    parentQuote: ParentQuote,
    exchangeRate: number,
    hasFile: boolean,
    requests: Request[],
    currency: CurrencyEnum,
    includeVAT: boolean,
    guid: string
}
export default function EditQuoteDetailsModal() {
    const dispatch = useDispatch();
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState({} as EditQuoteDetailsViewModel);

    function onSubmit(data: any, e: any) {
        var url = '/Requests/EditQuoteDetails'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(result => {
                if (!result.ok) throw result.text()
                console.log("basic modal result")
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Success" }))
                })

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }

    const validationSchema = object<Partial<Record<keyof EditQuoteDetailsFormData, AnySchema>>>({
        parentQuote: object<Partial<Record<keyof ParentQuote, AnySchema>>>({
            quoteNumber: string().typeError("Quote Number is required").required("Quote Number is required"),
            expirationDate: date().typeError("Expiration Date must be a vaild date").required("Expiration Date is required").min(moment().startOf("day"), "Expiration date cannot be in the past"),
            discount: number().nullable()
        }),
        requests: array<Partial<Record<keyof Request, AnySchema>>>()
            .of(
                object<Partial<Record<keyof Request, AnySchema>>>(
                    {
                        expectedSupplyDays: number().typeError("Supply Days field must be at least 0").integer("Supply Days field is a number").min(0, "Supply Days field must be at least 0").required("Supply Days field is required"),
                        cost: number().min(0, "Cost field must be at least 0").required("Cost field is required"),
                    }
                )
            ),
        hasFile: boolean().isTrue("File is required"),
        exchangeRate: number().moreThan(0, "Exchange Rate field must be more than 0").required("Exchange Rate field is required"),
        currency: mixed().oneOf(Object.values(CurrencyEnum).filter(c => c != CurrencyEnum.None)),

    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues:
            {
                parentQuote: {
                    quoteNumber: "",
                    discount: 0
                },
                hasFile: false,
                exchangeRate: 1,
                currency: CurrencyEnum.USD,
                includeVAT: true,
                guid: v4()
            } as EditQuoteDetailsFormData
    }
    );
    const currency = methods.watch('currency')
    const exchangeRate = methods.watch('exchangeRate')
    const guid = methods.watch("guid")
    useEffect(() => {
        var url = "/Requests/EditQuoteDetails?IDs=" + modalInfo.ids + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: EditQuoteDetailsViewModel) => {
                setViewModel(result);
                methods.setValue("parentQuote", result.parentQuote)
                methods.setValue("exchangeRate", result.exchangeRate)
                methods.setValue("currency", (result.vendor.country??{}as Country).currency.currencyName == CurrencyEnum.None ? CurrencyEnum.NIS : (result.vendor.country??{}as Country).currency.currencyName)
                methods.setValue(`requests`, result.requests.map(r => {
                    return {cost: r.cost, exchangeRate:r.exchangeRate, productID:r.productID, includeVAT:r.includeVAT, expectedSupplyDays:r.expectedSupplyDays, requestID:r.requestID, currency:r.currency} as Request
                }))
                dispatch(setModalLoading(false))
                //dispatch(setGlobalSnack({open:true, message:"In delete Modal"}))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);
    return (<>{
            loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Upload Price Quote" errorMessage={error.message} />
                    {error.showBody ? <>
                        <StyledDialogContent>

                            <GlobalForm formID={ModalName.EditQuoteDetails} onSubmit={onSubmit} methods={methods}>
                                <SpacedColumnStack height={"100%"}>
                                <Typography>{viewModel.vendor.vendorEnName}</Typography>

                                <Stack direction="row" spacing={2} paddingY="2rem">
                                    <DocumentsCard allowMultipleFiles folderID={guid} folderName={FolderNamesEnum.Quotes} />
                                    <Stack spacing={2}>
                                        <Stack direction="row" spacing={2} width="50%">
                                            <StyledControlledFormInput label="Quote Number" name="parentQuote.quoteNumber" />
                                            <StyledControlledFormDateInput label="Expiration Date" name="parentQuote.expirationDate" />
                                        </Stack>
                                        <Stack direction="row" spacing={2}>
                                            <StyledControlledAutoCompleteInput
                                                label="Currency"
                                                name='currency'
                                                options={Object.values(CurrencyEnum).filter(c => c != CurrencyEnum.None).map(c => { return {text: c.toString(), value: c} as AutocompleteOption})??[]}
                                            />
                                            <StyledControlledFormInput label="Exchange Rate" type="number" name="exchangeRate" />
                                            <StyledPercentFormInput label="Discount" name="parentQuote.discount" />
                                            <Controller
                                                name={`includeVAT`}
                                                control={methods.control}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <FormControlLabel control={<SectionCheckbox checked={value} onChange={onChange} />} label="IncludeVAT" />
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Table>
                                    <TableBody>
                                        {viewModel?.requests?.map((r, i) => {
                                            return (
                                                <RequestRow request={r} currency={currency} index={i} exchangeRate={exchangeRate} />
                                            )
                                        })
                                        }
                                    </TableBody>
                                </Table>
                                </SpacedColumnStack>
                            </GlobalForm>

                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.EditQuoteDetails} />
                    </>
                        : null
                    }
                </>}
                </>
            

    );
}
function RequestRow(props: { request: Request, currency: CurrencyEnum, index: number, exchangeRate: number }) {
    const { watch, setValue } = useFormContext<EditQuoteDetailsFormData>()
    const cost = watch(`requests.${props.index}.cost`)
    const costDollar = watch(`requests.${props.index}.costDollar`)
    const includeVAT = watch("includeVAT")

    useEffect(() => {
        if (props.currency == CurrencyEnum.NIS) {
            setValue(`requests.${props.index}.costDollar`, cost / props.exchangeRate)
        }
    }, [cost])
    useEffect(() => {
        if (props.currency == CurrencyEnum.USD) {
            setValue(`requests.${props.index}.cost`, costDollar * props.exchangeRate)
        }
    }, [costDollar])

    function getTotal() {
        if (props.currency == CurrencyEnum.NIS) {
            return includeVAT ? cost * 1.17 : cost
        }
        else {
            return includeVAT ? costDollar * 1.17 : costDollar
        }
    }
    const expectedSupplyDaysName = `requests.${props.index}.expectedSupplyDays`
    const expectedSupplyDateName = `requests.${props.index}.expectedSupplyDate`
    function handleSupplyDaysChange(e: any) {
        handleCalendarUnitCalcChange(e, expectedSupplyDaysName, expectedSupplyDateName, setValue, "days");
    }
    function handleSupplyDateChange(e: any) {
        handleDateCalcChange(e, expectedSupplyDaysName, expectedSupplyDateName, setValue, "days");
    }
    return (
        <ModalItemTableRow  key={props.request.requestID}>
            <ModalItemTableCell width={10}>
                <StyledTableImage width={4.7} src={props.request.product.productSubcategory.imageURL} />
            </ModalItemTableCell>
            <ModalItemTableCell width={20}>
                <Typography>{props.request.product.productName}</Typography>
            </ModalItemTableCell>
            <ModalItemTableCell width={10}>
                <Stack>
                    <Typography>{`${props.request.unit} ${props.request.product.unitType.unitTypeDescription}`}</Typography>
                    {props.request.product.subUnit && <Typography>{`${props.request.product.subUnit ?? ""} ${props.request.product.subUnitType?.unitTypeDescription ?? ""}`}</Typography>}
                    {props.request.product.subSubUnit && <Typography>{`${props.request.product.subSubUnit ?? ""} ${props.request.product.subSubUnitType?.unitTypeDescription ?? ""}`}</Typography>}
                </Stack>
            </ModalItemTableCell>
            <ModalItemTableCell width={15}>
                {props.currency == CurrencyEnum.NIS ?
                    <StyledCurrencyFormInput label="Price" name={`requests.${props.index}.cost`} currency={"₪"} />
                    : <StyledCurrencyFormInput label="Price" name={`requests.${props.index}.costDollar`} currency={"$"} />}
            </ModalItemTableCell>
            <ModalItemTableCell width={15}>
                <StyledCurrencyFormInput label="Price + VAT" disabled value={getTotal()} currency={props.currency == CurrencyEnum.NIS ? "₪" : "$"} />
            </ModalItemTableCell>
            <ModalItemTableCell width={15}>
                <StyledControlledFormInput label="Expected Supply Days" name={expectedSupplyDaysName} onChange={handleSupplyDaysChange} />
            </ModalItemTableCell>
            <ModalItemTableCell width={15}>
                <StyledControlledFormDateInput label="Expected Supply Date" name={expectedSupplyDateName} minDate={new Date()} onChange={handleSupplyDateChange} />
            </ModalItemTableCell>
        </ModalItemTableRow>
    )
}
