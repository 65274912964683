import { MenuItemEnum, PageTypeEnum, RoleDefinition, SidebarEnum, SvgNames } from "../Constants/app-enums";
import * as Routes from '../Constants/Routes';
import { NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import * as TopBarConsts from './topbar-menus';
const RequestImage = require( '../images/main_menu_icons/main_inventory_button.png' );
const RequestImageSmall = require( '../images/main_menu_small_icons/inventory_menu_button.png' );
const ProtocolImage = require( '../images/main_menu_icons/main_protocol_button.png' );
const ProtocolImageSmall = require( '../images/main_menu_small_icons/protocols_menu_button.png' );
const OperationsImage = require( '../images/main_menu_icons/main_operations_button.png' );
const OperationImageSmall = require( '../images/main_menu_small_icons/operations_menu_button.png' );
const BiomarkersImage = require( '../images/main_menu_icons/main_biomarkers_button.png' );
const BiomarkersImageSmall = require( '../images/main_menu_small_icons/biomarkers_menu_button.png' );
const UsersImage = require( '../images/main_menu_icons/main_users_button.png' );
const UsersImageSmall = require( '../images/main_menu_small_icons/users_menu_button.png' );
const TimekeeperImage = require( '../images/main_menu_icons/main_timekeeper_button.png' );
const TimekeeperImageSmall = require( '../images/main_menu_small_icons/timekeeper_menu_button.png' );
const AccountingImage = require( '../images/main_menu_icons/main_accounting_button.png' );
const AccountingImageSmall = require( '../images/main_menu_small_icons/accounting_menu_button.png' );
const IncomeImage = require( '../images/main_menu_icons/main_income_button.png' );
const IncomeImageSmall = require( '../images/main_menu_small_icons/income_menu_button.png' );
const ReportsImage = require( '../images/main_menu_icons/main_reports_button.png' );
const ReportsImageSmall = require( '../images/main_menu_small_icons/reports_menu_button.png' );
const LabManagementImage = require( '../images/main_menu_icons/main_labmanagment_button.png');
const LabManagementImageSmall = require( '../images/main_menu_small_icons/lab_managment_menu_button.png' );

export type MenuItem = {
    description:string,
    href:string,
    navigationInfo?:NavigationInfo,
    imageName?: string
    iconName?: SvgNames,
    imageNameSmall?: string,
    childMenuItems?: MenuItem[],
    menuEnum?: MenuItemEnum,
    pageTypeEnum?:PageTypeEnum,
    sidebarTypeEnum?:SidebarEnum,
    sectionColor?:string,
    requiredRole?:RoleDefinition
}

export const ordersAndInventory:MenuItem ={
    description:"Orders & Inventory",
    href:Routes.REQUESTS_LIST,
    navigationInfo: {pageType: PageTypeEnum.RequestRequest, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Requests} as NavigationInfo,
    imageName: RequestImage,
    imageNameSmall:RequestImageSmall,
    childMenuItems: [TopBarConsts.requestsRequests, TopBarConsts.requestsInventory, TopBarConsts.requestsLocations, TopBarConsts.requestsPersonal],
    menuEnum: MenuItemEnum.Requests,
    sectionColor:"#2196F3"
   
} 
// export const protocols:MenuItem ={
//     description:"Protocols",
//     href:Routes.INDEX_TABLE,
//     navigationInfo: {pageType: PageTypeEnum.ProtocolsReports, sidebarType: SidebarEnum.WeeklyReports, sectionType: MenuItemEnum.Protocols} as NavigationInfo,
//     imageName: ProtocolImage,
//     imageNameSmall:ProtocolImageSmall,
//     childMenuItems: [TopBarConsts.protocolsReport],
//     menuEnum: MenuItemEnum.Protocols,
//     sectionColor:"#009688"
   
// } 
// export const operations:MenuItem ={
//     description:"Operation",
//     href:Routes.INDEX_TABLE,
//     navigationInfo: {pageType: PageTypeEnum.OperationsRequest, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Operations} as NavigationInfo,
//     imageName: OperationsImage,
//     imageNameSmall:OperationImageSmall,
//     childMenuItems: [TopBarConsts.operationsRequests, TopBarConsts.operationsPersonal, TopBarConsts.operationsItemList ],
//     menuEnum: MenuItemEnum.Operations,
//     sectionColor:"#3F51B5",

// } 
// export const biomarkers:MenuItem ={
//     description:"Biomarkers",
//     href:Routes.INDEX_TABLE,
//     navigationInfo: {pageType: PageTypeEnum.BiomarkersExperiments, sidebarType: SidebarEnum.HumanTrials, sectionType: MenuItemEnum.Biomarkers} as NavigationInfo,
//     imageName: BiomarkersImage,
//     imageNameSmall:BiomarkersImageSmall, 
//     childMenuItems: [TopBarConsts.biomarkersExperiments],
//     menuEnum: MenuItemEnum.Biomarkers,
//     sectionColor:"#F44336",
    
// } 
export const timekeeper:MenuItem ={
    description:"Timekeeper",
    href:Routes.REPORT_HOURS,
    navigationInfo: {pageType: PageTypeEnum.TimeKeeperReport, sidebarType: SidebarEnum.ReportHours, sectionType: MenuItemEnum.TimeKeeper} as NavigationInfo,
    imageName: TimekeeperImage,
    imageNameSmall:TimekeeperImageSmall,
    childMenuItems: [TopBarConsts.timeKeeperReports, TopBarConsts.timeKeeperSummary],
    menuEnum: MenuItemEnum.TimeKeeper,
    sectionColor:"#FF9800",
    
} 
export const labManagement:MenuItem ={
    description:"Lab Management",
    href:Routes.LAB_MANAGE_QUOTES,
    navigationInfo: {pageType: PageTypeEnum.LabManagementQuotes, sidebarType: SidebarEnum.Quotes, sectionType: MenuItemEnum.LabManagement} as NavigationInfo,
    imageName: LabManagementImage,
    imageNameSmall:LabManagementImageSmall,
    childMenuItems: [TopBarConsts.labManagementOrders, TopBarConsts.labManagementSuppliers, TopBarConsts.labManagementLocations ],
    menuEnum: MenuItemEnum.LabManagement,
    sectionColor:"rgb(125,155,170)",
   
} 
export const accounting:MenuItem ={
    description:"Accounting",
    href:Routes.MONTHLY_PAYMENT,
    navigationInfo: {pageType: PageTypeEnum.AccountingPayments, sidebarType: SidebarEnum.MonthlyPayment, sectionType: MenuItemEnum.Accounting} as NavigationInfo,
    imageName: AccountingImage,
    imageNameSmall:AccountingImageSmall, 
    childMenuItems: [TopBarConsts.accountingPayments, TopBarConsts.accountingNotifications, TopBarConsts.accountingGeneral, TopBarConsts.accountingSuppliers, TopBarConsts.accountingSettings],
    menuEnum: MenuItemEnum.Accounting,
    sectionColor:"#A26FF0",
   
} 
// export const reports:MenuItem ={
//     description:"Reports",
//     href:Routes.INDEX_TABLE,
//     navigationInfo: {} as NavigationInfo,
//     imageName: ReportsImage,
//     imageNameSmall:ReportsImageSmall,
//     childMenuItems: [TopBarConsts.reportsCosts, TopBarConsts.reportsStatistics, TopBarConsts.reportsSummary],
//     menuEnum: MenuItemEnum.Reports,
//     sectionColor:"#00BCD4",
   
// } 
// export const income:MenuItem ={
//     description:"Income",
//     href:Routes.INDEX_TABLE,
//     navigationInfo: {} as NavigationInfo,
//     imageName: IncomeImage,
//     imageNameSmall:IncomeImageSmall,
//     menuEnum: MenuItemEnum.Income,
//     sectionColor:"#4CAF50",
   
// } 
export const users:MenuItem ={
    description:"Users",
    href:Routes.USERS_TABLE,
    navigationInfo: {pageType: PageTypeEnum.UsersUser, sidebarType: SidebarEnum.List, sectionType: MenuItemEnum.Users} as NavigationInfo,
    imageName: UsersImage,
    imageNameSmall:UsersImageSmall,
    childMenuItems: [TopBarConsts.usersUsers, TopBarConsts.usersEmployees],
    menuEnum: MenuItemEnum.Users,
    sectionColor:"#795548",
   
} 
export  function getMenus(roles:MenuItemEnum[]){
    return [ordersAndInventory, timekeeper, labManagement, accounting, users]
    .filter((item)=> roles?.findIndex(role=>role==item.menuEnum)>-1)
}

export  function getMenuByEnum(menuEnum?:MenuItemEnum){
  switch(menuEnum)
        {
            // case MenuItemEnum.Biomarkers:
            //     return biomarkers;
            case MenuItemEnum.Accounting:
                return accounting;
            case MenuItemEnum.Requests:
                return ordersAndInventory;
            // case MenuItemEnum.Protocols:
            //     return protocols;
            // case MenuItemEnum.Operations:
            //     return operations;
            case MenuItemEnum.TimeKeeper:
                return timekeeper;
            case MenuItemEnum.LabManagement:
                return labManagement;
            // case MenuItemEnum.Reports:
            //     return reports;
            case MenuItemEnum.Users:
                return users;
            // case MenuItemEnum.Income:
            //     return income;
            default:
                return {}as MenuItem
        }
    }


export  function getPageTypeByEnum(pageTypeEnum?:PageTypeEnum){
    return Object.values(TopBarConsts)
    .filter((item)=> pageTypeEnum==item.pageTypeEnum)?.[0];
}
