import { AutocompleteGroupedOption } from "@mui/base";
import { createSlice } from "@reduxjs/toolkit";
import { ChildStorageSelectList, SelectLocationViewModel, VisualState } from "../Requests/Modals/receive-location-modal";
import { AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { AutocompleteOption } from "../Utility/general-types";
import {  ProductItemViewModel, RequestItemViewModel } from "../Utility/request-types";
import { UserIndexViewModel } from "../Utility/user-types";

const initialState={} as ({id:string, viewModel:RequestItemViewModel | AddUserViewModel |ProductItemViewModel
    ,reloadEditModal:boolean}[]) ;
const editDrawerViewModelSlice = createSlice({
    name: 'editDrawerViewModel',
    initialState:[] as {viewModel:RequestItemViewModel|AddUserViewModel |ProductItemViewModel, id:string, reloadEditModal:boolean}[],
    reducers: {
        setEditDrawerViewModel(state, action:{payload:{viewModel:RequestItemViewModel|AddUserViewModel|ProductItemViewModel, id:string, reloadEditModal:boolean}}) {
           console.log(action.payload)
            return [...state.filter(state=>state.id != action.payload.id), action.payload]
        },  
        removeEditDrawerViewModel(state, action:{payload:{ id:string}}) {
            console.log(action.payload)
            return [...state.filter(state=>state.id != action.payload.id)]
        }, 
        clearEditDrawerViewModel(state) {
            return [] as any;
        }, 
        setReloadEditModal(state, action:{payload:{reloadEditModal: boolean, id:string}}) {
            var currentViewModel = state.find(f=>f.id==action.payload.id);
            if(currentViewModel==undefined){
                currentViewModel={id:action.payload.id, reloadEditModal:action.payload.reloadEditModal, viewModel:{} as any};
            }
            return [...state.filter(state=>state.id != action.payload.id), {...currentViewModel, reloadEditModal: action.payload.reloadEditModal}]
        }, 
        setAllReloadEditModal(state, action:{payload:{reloadEditModal: boolean}}) {
            state.map(s=>s.reloadEditModal=action.payload.reloadEditModal)
        },    
        setLocationViewModel(state, action:{payload:{viewModel:SelectLocationViewModel, id:string}}) {
            var currentViewModel =state.find(f=>f.id==action.payload.id);
            console.log(currentViewModel)
            if(currentViewModel==undefined){
                currentViewModel={id:action.payload.id, reloadEditModal:false,  viewModel:{} as RequestItemViewModel};
                (currentViewModel.viewModel as RequestItemViewModel).selectLocationViewModel = action.payload.viewModel
                return [...state.filter(state=>state.id != action.payload.id), {...currentViewModel}]

            }
            (currentViewModel.viewModel as RequestItemViewModel).selectLocationViewModel = action.payload.viewModel
          
        },    
        setRooms(state, action:{payload:{rooms:AutocompleteOption[],visualState:VisualState, id:string}}) {
            var currentViewModel =state.find(f=>f.id==action.payload.id)?.viewModel;          
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.rooms = action.payload.rooms;
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.visualState = action.payload.visualState;
        },  
        setFloors(state, action:{payload:{floors:AutocompleteOption[],visualState:VisualState, id:string}}) {
            var currentViewModel =state.find(f=>f.id==action.payload.id)?.viewModel;          
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.floors = action.payload.floors;
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.visualState = action.payload.visualState;
        },
        setStorageType(state, action:{payload:{storageTypes:AutocompleteOption[],visualState:VisualState, id:string}}) {
            var currentViewModel =state.find(f=>f.id==action.payload.id)?.viewModel;          
           
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.storageTypes = action.payload.storageTypes;            
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.visualState = action.payload.visualState;
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.childrenStorageUnits= [];
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.storageUnits=[];
        },     
        setStorageUnits(state, action:{payload:{storageUnits:AutocompleteOption[],visualState:VisualState, id:string}}) {
            var currentViewModel =state.find(f=>f.id==action.payload.id)?.viewModel;          
           
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.storageUnits = action.payload.storageUnits;            
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.visualState = action.payload.visualState;
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.childrenStorageUnits= [];
        },   
        setChildrenUnits(state, action:{payload:{childrenStorageUnits:ChildStorageSelectList[],visualState:VisualState, id:string}}) {
            var currentViewModel =state.find(f=>f.id==action.payload.id)?.viewModel;          
           
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.childrenStorageUnits = action.payload.childrenStorageUnits;            
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.visualState = action.payload.visualState;
        },   
        setShowVisual(state, action:{payload:{visualState:VisualState, id:string}}) {  
            var currentViewModel =state.find(f=>f.id==action.payload.id)?.viewModel;          
           
            (currentViewModel as RequestItemViewModel).selectLocationViewModel.visualState = action.payload.visualState;
        }, 
    }
})

export const {setEditDrawerViewModel: setEditDrawerViewModel,setReloadEditModal, setAllReloadEditModal, setLocationViewModel, removeEditDrawerViewModel: removeEditDrawerViewModel,clearEditDrawerViewModel: clearEditDrawerViewModel,  setStorageType, setRooms, setFloors, setStorageUnits, setChildrenUnits,setShowVisual} = editDrawerViewModelSlice.actions

export default  editDrawerViewModelSlice.reducer
