import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from 'react-loading-skeleton';

export default function EditDrawerSkeleton(){
    return(
        <Box data-testid="editDrawerSkeleton" padding="2rem">
        <Stack spacing={2} direction = "column">
            <Stack width="100%" direction="row" alignItems="end">
                 <Skeleton style={{marginRight:"3rem"}} height="6rem" width="6rem" />
                 <Skeleton height="2rem" width="35rem"  />
            </Stack>
            <Skeleton style={{marginTop:"3rem"}} height="2rem" count={8} />
            
        </Stack>
        </Box>
    )
}