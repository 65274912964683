import { List, ListItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { ModalFlow, ModalName, SidebarEnum, SvgNames } from '../../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { BasicModalInfo, CustomError, MoveToListViewModel, NewListModalInfo } from '../../Utility/general-types';
import { RequestIndexViewModel } from '../../Utility/request-types';
import { objectToFormData } from '../../Utility/root-function';
import { StyledFilteredButton, StyledNoOutlineButton, SvgIcon } from '../../Utility/shared-components';
import { ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';

export default function MoveToListModal(){
    const dispatch = useDispatch()
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const modal = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const tabValue = useSelector((state:ApplicationState) => (state.viewModel as RequestIndexViewModel).tabValue)
    const sidebarType = useSelector<ApplicationState>(state => selectNavigationInfo(state).sidebarType) as SidebarEnum
    const [error, setError] = useState(new CustomError())
    const [viewModel, setViewModel] = useState<MoveToListViewModel>()
    



    useEffect(() => {
        var url = "/Requests/MoveToListModal/?requestID=" + modal.modalInfo.ids[0];
        if (sidebarType == (SidebarEnum.MyLists || SidebarEnum.SharedLists)) {
            url = url + "&prevListName=" + tabValue;
        }
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result:MoveToListViewModel) => {
                setViewModel(result);
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({message: text, showBody: false})
                })
            })

    }, [modal.modalStep])

    function disableNewListValue(){
        return ((viewModel?.requestLists?.length??0) >= 5 || (viewModel?.previousListID != 0 && (viewModel?.requestLists?.length??0) >= 4))
    }

    function chooseList(listID: number){
        dispatch(setLoading(true))
        var url = "/Requests/MoveToListModal";
        var formData = objectToFormData(viewModel);
        formData.set("newListID", listID)
        fetch(url, {
            method: "POST",
            body: formData
        })
            .then(response => {
                if (!response.ok) throw response.text()
                batch(() => {
                    dispatch(setReloadIndex(true));
                    dispatch(removeModal());
                    dispatch(setLoading(false))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }
    function openNewList (){
        batch(() => {
            dispatch(setModalStep({ modalFlow: ModalFlow.List, modalStep: ModalName.NewList, modalInfo: {requestPreviousListID: viewModel?.previousListID, requestToAddID: viewModel?.request.requestID} as NewListModalInfo }))
            dispatch(setTempData({guid:uuid(), data:viewModel as MoveToListViewModel}))
        })
    }

    return (
        
        loading ?
            <ModalSkeleton />
            :
        <>
            <ModalHeader headerText={"Move " + viewModel?.productName} errorMessage={error.message} />
            <StyledDialogContent>
                {error.showBody ?
                    <form method="post" encType="multipart/form-data" id={ModalName.MoveToList}>
                        <Stack direction="column" spacing={1}>
                            <List>
                                {viewModel?.requestLists?.map((v: any, i: number) => {
                                    return (
                                        <ListItem sx={{ justifyContent: "center" }} key={i} onClick={() => chooseList(viewModel?.requestLists[i]?.listID)} id={"List" + viewModel?.requestLists[i]?.listID}>
                                            <StyledFilteredButton sx={{ fontSize: "1rem", color: "black", width: "85%" }}>
                                                {viewModel?.requestLists[i]?.title}
                                            </StyledFilteredButton>
                                        </ListItem>)
                                })}
                            </List>
                            <StyledNoOutlineButton disabled={disableNewListValue()} startIcon={<SvgIcon name={SvgNames.AddCircleOutline} height={1.5} className={disableNewListValue() ? "icon-gray" : "section-fill"} />}
                                onClick={openNewList}
                            >
                                New List
                            </StyledNoOutlineButton>
                        </Stack>
                    </form> : null}
            </StyledDialogContent>
        </>
        )
}