import { yupResolver } from "@hookform/resolvers/yup";
import { Box, BoxProps } from "@mui/material";
import { useForm, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../ReduxRelatedUtils/redux-types";
import { AddItemInnerForm } from "../../Requests/ItemCard/add-item";
import { AddSupplierInnerForm } from "../../Suppliers/add-supplier";
import { AddUserInnerForm } from "../../Users/add-user";
import { CustomError } from "../../Utility/general-types";
import { StyledError, GlobalForm, StyledFullPageOutlinedBox } from "../../Utility/shared-components";
import { AddCardFormProps, AddCardType, AddItemFormData } from "./add-types";
import { requestDefaultValues, supplierDefaultValues, userDefaultValues } from "./default-values";
import { requestValidationSchema, supplierValidationSchema, userValidationSchema } from "./validation-schemas";
import { useEffect } from "react";
import { setShowConfirmExit } from "../../ReduxRelatedUtils/utilsReducer";



export default function AddCardForm(props: AddCardFormProps) {
    const error = useSelector<ApplicationState>(state => state.error) as CustomError
    //const [isSubmitting, setIsSubmitting] = useState(false)
    const defaultValues = props.addType == AddCardType.AddItem ?
            requestDefaultValues():
            props.addType == AddCardType.AddSupplier?
            supplierDefaultValues():
            userDefaultValues()
    
    const methods = useForm({resolver: props.addType == AddCardType.AddItem ?
        yupResolver(requestValidationSchema) :
        props.addType == AddCardType.AddSupplier?
         yupResolver(supplierValidationSchema) :
         yupResolver(userValidationSchema)
      ,mode:"onChange", defaultValues: defaultValues});
      
    return (<>
        {error.message && <StyledError>{error.message}</StyledError>}
        {error.showBody ? <StyledFullPageOutlinedBox  >

            <GlobalForm methods={methods} onSubmit={props.onSubmit} formID="addCard">
                    <InnerForm addType={props.addType}/>
            </GlobalForm>
        </StyledFullPageOutlinedBox> : null}
    </>
    )
}


function InnerForm(props:{addType: AddCardType}) {
   // console.log(props.addType)

   const {formState}=  useFormContext()
    const dispatch = useDispatch();
   useEffect(()=>{
        console.log("isdirty: "+formState.isDirty)
        if(formState.isDirty){
            dispatch(setShowConfirmExit(true))
        }
   }, [formState.isDirty])
    switch (props.addType) {
        case AddCardType.AddItem:
            return (<AddItemInnerForm />)
        case AddCardType.AddSupplier:
            return (<AddSupplierInnerForm />)
            case AddCardType.AddUser:
            return (<AddUserInnerForm />)
        default:return(<></>)
    }
}


