import { Box, Stack, Tooltip } from "@mui/material";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CurrencyEnum, FolderNamesEnum, InfoPopupName, SvgNames } from "../../Constants/app-enums";
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore";
import { setInfoPopup } from "../../ReduxRelatedUtils/infoPopupReducer";
import { ApplicationState, InfoPopup, TempData } from "../../ReduxRelatedUtils/redux-types";
import { StyledControlledFormInputWithDocInfo, StyledControlledFormDateInput, StyledControlledFormInput, StyledFormInput, StyledCurrencyFormInput } from "../../Utility/custom-input-components";
import { ConfigureInstallmentsModalInfo, RowViewModel } from "../../Utility/general-types";
import { RequestItemViewModel } from "../../Utility/request-types";
import { IsEdittableContext, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack, StyledFilledButton, SvgIcon } from "../../Utility/shared-components";
import { ConfigureInstallmentsModal } from "../Modals/configure-installments-modal";
import { useFormContext, useFieldArray } from "react-hook-form";
import { AddItemFormData } from "../../Shared/AddCardComponents/add-types";

export function InstallmentsTab(props: { editID?: string }) {
    const viewModel = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.editID ?? "0")?.viewModel)) as RequestItemViewModel;
    const [configureInstallmentsModalIsOpen, setConfigureInstallmentsModalIsOpen] = useState(false);
    const isEdittableContext = useContext(IsEdittableContext);
    const { control, formState, watch, getValues, reset, setValue } = useFormContext<TempData<AddItemFormData>>()

    const payments = watch("request.payments")
    const currency  = watch("request.currency")
    const dispatch = useDispatch()
    return (
        <SpacedColumnStack>
            {payments.map((p, i) => {
                return (
                    <SpacedColumnStack sx={{ borderRadius: "0.3rem", border: (theme) => `.1rem solid ${theme.palette.grey[300]}`, padding:"2rem" }}>
                        <SpacedRowStack width="100%">
                            <StyledControlledFormInput label={"Installment No"} disabled name={`request.payments.${i}.installmentNumber`} />
                            <StyledCurrencyFormInput label={"Amount"} currency={currency== CurrencyEnum.NIS?"₪":"$"} disabled name={`request.payments.${i}.sum`} />
                            <StyledControlledFormDateInput label={"Payment Date"} disabled name={`request.payments.${i}.paymentDate`} />
                            <StyledFormInput label={"Status"} disabled value={p.isPaid?"Paid":"Awaiting Payment"} />
                        </SpacedRowStack>
                        {p.isPaid&&
                        <PaymentDetailsBlock  name={`request.payments.${i}`} creditCards={viewModel?.creditCards} companyAccounts={viewModel?.companyAccounts} paymentTypes={viewModel?.paymentTypes} />
                        }
                        {p.invoiceID != null && <SpacedRowStack >
                      
                            <StyledControlledFormInputWithDocInfo fullWidth={false} sx={{width:"calc(50% - 1rem)"}}  disabled label="Invoice Number" name={`request.payments.${i}.invoice.invoiceNumber`} folderName={FolderNamesEnum.Invoices} infoID={p.invoiceID} editDrawerID={props.editID?.toString() ?? "0"} />
                            <Box  sx={{width:"100%"}}  ></Box>
                        </SpacedRowStack> }
                        
                    </SpacedColumnStack>)
            })}
            <SpacedColumnStack>
                <Stack direction="row" justifyContent={"center"}>
                <StyledFilledButton  sx={{maxWidth:"22rem !important"}} disabled={isEdittableContext.isEdittable || payments.every(p=>p.isPaid)}
                 onClick={() => dispatch(setInfoPopup({infoPopupName: InfoPopupName.ConfigureInstallments, id: viewModel.request.requestID.toString(), comingFromTerms:false} as ConfigureInstallmentsModalInfo))} >Reconfigure Installments</StyledFilledButton>
                 {(isEdittableContext.isEdittable || payments.every(p=>p.isPaid) )&& <Tooltip title={`Cannot reconfigure installments when ${payments.every(p=>p.isPaid) ? "all payments are paid": "edit mode is on"}`} arrow>
                                                    <Box marginLeft="0.5rem" display="flex" alignItems="center"><SvgIcon height={1.5} name={SvgNames.Info} /></Box>
                                                </Tooltip>}
                 </Stack>
            </SpacedColumnStack>
        </SpacedColumnStack>
    )

}