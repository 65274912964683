
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { CardTabsType, TabsName } from "../../Constants/client-side-enums";
import { AddCardType, AddItemFormData } from "../../Shared/AddCardComponents/add-types";
import { GlobalTab, Tab } from "../../Shared/AddCardComponents/card-tabs";
import { RequestItemViewModel } from "../../Utility/request-types";
import { SelectLocationViewModel } from "../Modals/receive-location-modal";
import CommentsTab from "./comments-tab";
import HistoryTab from "./history-tab";
import { InstallmentsTab } from "./installments-tab";
import { DocumentsTab } from "./item-components";
import ItemTab from "./item-details-tab";
import { LocationTab } from "./location-tab";
import OrderTab from "./order-tab";
import PriceTab from "./price-tab";



export function ItemTabs(props:{tabs:Tab[], view:any, cardType?: CardTabsType, editID?:string, isProduct?:boolean}){
    const form = useFormContext();
    const [tabComponents, setTabComponents] = useState<JSX.Element[]>([]);
    const requestID = form.watch("request.requestID");
    const productID = form.watch("product.productID");
    useEffect(()=>{
       // console.log(props.view)
        let localTabs = [];
        for(let i=0; i<props.tabs.length; i++){
           localTabs.push(
             <GlobalTab key={props.tabs[i].name} tab={props.tabs[i]} index={i} cardType={props.cardType}>   
                  <TabInnerComponent   currentTab={props.tabs[i]}  view={props.view} editID={props.editID} isProduct={props.isProduct}/>
              </GlobalTab>)
         }

         setTabComponents(localTabs);
    },[props.tabs, props.view?.fields, props.view?.docCards, requestID, productID,])
  
    function TabInnerComponent(props:{currentTab:Tab, view:any, editID?:string, isProduct?:boolean}){

        switch(props.currentTab.name){
            case TabsName.Item:
                return <ItemTab  editID={props.editID}  fields={props.view?.fields??[]} isProduct={props.isProduct}/>
            case TabsName.Price:
                return  <PriceTab  fields={props.view?.fields??[]}/>                
            case TabsName.Payments:
                return  <InstallmentsTab  editID={props.editID}/>
            case TabsName.Locations:
                return  <LocationTab  requestID={requestID} />         
            case TabsName.Documents:
                return  <DocumentsTab docCards={props.view?.docCards??[]}/>
            case TabsName.Comments:
                return  <CommentsTab  />
            case TabsName.Order:
                return  <OrderTab/>
            case TabsName.History:
                return  <HistoryTab  editID={props.editID}/>
            default: return <></>
        }
    }
    return (<>{tabComponents}</>);
}