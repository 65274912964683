import { yupResolver } from '@hookform/resolvers/yup';
import { TextFieldProps, FormControl, TextField, InputAdornment, Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment, { Duration } from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useController, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, number, object, string } from 'yup';
import { ModalName, SvgNames } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading, setPageSkeleton } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledAutoCompleteInput, StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledSelectInput, StyledFormInput } from '../Utility/custom-input-components';
import { AutocompleteOption, BasicModalInfo, CustomError, TimekeeperActionModalInfo } from '../Utility/general-types';
import { GlobalForm, IsEdittableContext, OutlinedTableButton, SectionOutlinedTableButton, SpacedColumnStack, SpacedRowStack, StyledError, StyledHoverOutlineButton, StyledNoOutlineButton, StyledSectionOutlineButton, SvgIcon } from '../Utility/shared-components';
import Theme from '../Utility/theme';
import { EmployeeHours } from '../Utility/timekeeper-types';


type UpdateHoursViewModel = {
    employeeHour: EmployeeHours,
    hoursPerDay: number,
    partialOffDayTypes: AutocompleteOption[]
    isForgotToReport: boolean
}
type UpdateHoursFormData = {
    employeeHour: EmployeeHours,
    showSecondEntry: boolean,
    isForgotToReport: boolean
}
export default function UpdateHoursModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<TimekeeperActionModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const [reload, setReload] = useState(true);

    const validationSchema = object<Partial<Record<keyof UpdateHoursFormData, AnySchema>>>({
        employeeHour: object<Partial<Record<keyof EmployeeHours, AnySchema>>>({
            entry1: string().nullable()
                .test("entry-1", "Entry 1 is required", function (value) {
                    const { totalHours } = this.parent;
                    if (totalHours && moment(totalHours, "HH:mm").isValid() && moment(totalHours, "HH:mm").isAfter(moment("00:00", "HH:mm"))) {
                        return true
                    }
                    else {
                        return moment(value, "YYYY-MM-DD HH:mm").isValid()
                    }
                }),
            exit1: string().nullable()
                .test("entry-1", "Exit 1 must be after Entry 1", function (value) {
                    const { totalHours, entry1 } = this.parent;
                    if (totalHours && moment(totalHours, "HH:mm").isValid() && moment(totalHours, "HH:mm").isAfter(moment("00:00", "HH:mm"))) {
                        console.log(totalHours)
                        return true
                    }
                    else {
                        return moment(value, "YYYY-MM-DD HH:mm").isAfter(moment(entry1, "YYYY-MM-DD HH:mm"))
                    }
                }),
            partialOffDayTypeID: number().when("partialOffDayHours", {
                is: (hours: string) => hours != null && moment(hours, "HH:mm").isValid() && moment(hours, "HH:mm").isAfter(moment("00:00", "HH:mm")),
                then: number().typeError("Partial Off Day Type cannot be null if hours selected").moreThan(0).required("Partial Off Day Type cannot be null if hours selected"),
                otherwise: number().nullable()
            }),
            totalHours: string().nullable().when(["entry1", "exit1"], {
                is: (entry1: string, exit1: string) => (entry1 || entry1 != "") && (exit1 || exit1 != ""),
                then: string().required("Total Hours is required")
                    .test("valid total hours", "Total Hours must be a valid time", function (value) {
                        return moment(value, "HH:mm").isValid() && moment(value, "HH:mm").isAfter(moment("00:00", "HH:mm"));
                    }),
                otherwise: string().nullable()
            }),
            partialOffDayHours: string().nullable()
                .test("partial hours", "Partial hours cannot be empty if Off Day selected", function (value) {
                    const { partialOffDayTypeID } = this.parent;
                    if (partialOffDayTypeID != null && partialOffDayTypeID > 0) {
                        return moment(value, "HH:mm").isValid() && moment(value, "HH:mm").isAfter(moment("00:00", "HH:mm"))
                    }
                    else {
                        return true
                    }
                })

        }).when("showSecondEntry", {
            is: true,
            then: object({
                entry2: string().typeError("Entry 2 cannot be empty")
                    .required("Entry 2 cannot be empty")
                    .test("is-greater", "Entry 2 must be greater than Exit 1", function (value) {
                        const { exit1 } = this.parent;
                        return moment(value, "YYYY-MM-DD HH:mm").isAfter(moment(exit1, "YYYY-MM-DD HH:mm"));
                    }),
                exit2: string().typeError("Exit 2 cannot be empty")
                    .required("Exit 2 cannot be empty")
                    .test("is-greater", "Exit 2 must be greater than Entry 2", function (value) {
                        const { entry2 } = this.parent;
                        return moment(value, "YYYY-MM-DD HH:mm").isAfter(moment(entry2, "YYYY-MM-DD HH:mm"));
                    })
            })
        })
    })
    const resolver = yupResolver(validationSchema)

    const methods = useForm<UpdateHoursFormData>({
        resolver,
        defaultValues: {
            showSecondEntry: false,
            employeeHour: {
                employeeHoursStatusEntry1ID: 0,
                totalHours: "",
                partialOffDayTypeID: 0,
                date: moment(modalInfo.date).format("YYYY-MM-DD")
            }
        }
    });

    const [viewModel, setViewModel] = useState<UpdateHoursViewModel>({} as UpdateHoursViewModel)

    const date = methods.watch("employeeHour.date")
    const entry1 = methods.watch("employeeHour.entry1")
    const exit1 = methods.watch("employeeHour.exit1")
    const exit2 = methods.watch("employeeHour.exit2")
    const entry2 = methods.watch("employeeHour.entry2")
    const totalHours = methods.watch("employeeHour.totalHours")
    const showSecondEntry = methods.watch("showSecondEntry")
    const partialOffDayTypeID = methods.watch("employeeHour.partialOffDayTypeID")
    const partialOffDayHours = methods.watch("employeeHour.partialOffDayHours")


    useEffect(() => {
        if (reload) {
            var url = '/Timekeeper/UpdateHours?chosenDate=' + date + "&isWorkFromHome=" + (modalInfo.typeID == 1) + "&employeeHoursID=" + modalInfo.ids?.[0];
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: UpdateHoursViewModel) => {
                    dispatch(setModalLoading(false))
                    methods.setValue("employeeHour", { ...result.employeeHour, date: moment(result.employeeHour.date).format("YYYY-MM-DD") })
                    if (result.employeeHour.entry2) methods.setValue("showSecondEntry", true)
                    setViewModel(result);
                    methods.setValue("isForgotToReport", result.isForgotToReport)
                    setReload(false)
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        console.log(text)
                        setError({ message: text.errorMessage, showBody: false })
                        dispatch(setModalLoading(false))
                    })
                })
        }

    }, [reload]);

    useEffect(() => {
        console.log(entry1)
        console.log(exit1)
        console.log(entry2)
        console.log(exit2)
        let totalHours = ""
        let duration = {} as Duration
        if (entry1 != null && exit1 != null) {
            if (moment(exit1).isAfter(moment(entry1))) {
                
                duration = moment.duration(moment(exit1).diff(entry1))
                console.log(duration.hours())
                if (entry2 != null && exit2 != null && (entry2 > exit1)) {
                    duration = moment.duration(duration.asMilliseconds() + moment.duration(moment(exit2).diff(entry2)).asMilliseconds())
                    
                    totalHours = duration.hours() + ":" + duration.minutes()
                }
                totalHours = duration.hours() + ":" + duration.minutes()
            }
        }
        if (totalHours != "") methods.setValue("employeeHour.totalHours", moment(totalHours, "HH:mm").format("HH:mm"))
    }, [entry1, entry2, exit1, exit2])

    useEffect(() => {
        if (partialOffDayTypeID != null && partialOffDayTypeID != 0 && (partialOffDayHours == null || !moment(partialOffDayHours, "HH:mm").isValid() || moment(partialOffDayHours, "HH:mm").isSame(moment("00:00", "HH:mm")))) //partial hours is empty
        {

            let hoursAndMinutesWorked = totalHours?.split(":")??["00","00"]
            let totalHoursDecimal = parseFloat(hoursAndMinutesWorked[0]) + (parseFloat(hoursAndMinutesWorked[1]) / 60)
            let remainingHours = viewModel.hoursPerDay - totalHoursDecimal
            let hours = Math.floor(remainingHours)
            let remainingHoursTime = hours.toString() + ":" + Math.round((Number(remainingHours) - hours) * 60)
            methods.setValue("employeeHour.partialOffDayHours", moment(remainingHoursTime, "HH:mm").format("HH:mm"))
        }
        else if (partialOffDayTypeID == 0) {
            methods.setValue("employeeHour.partialOffDayHours", "")
        }
    }, [partialOffDayTypeID])

    function handleDateChange(e: any) {
        methods.setValue("employeeHour.date", moment(e).format("YYYY-MM-DD"))
        setReload(true)
    }

    function onSubmit(data: UpdateHoursFormData) {
        var url = '/Timekeeper/UpdateHours'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((result: string) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: result, severity: "success" }))
                    dispatch(setReloadIndex(true))
                    dispatch(setPageSkeleton(true))
                })
            })
            .catch(err => {

                Promise.resolve(err).then(text => {
                    console.log(text)
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }

    return (
        <GlobalModal key={ModalName.UpdateHours} size={ModalSizes.md} modalKey={ModalName.UpdateHours}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Update Hours"} errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.UpdateHours} onSubmit={(data: any) => onSubmit(data)} methods={methods}>
                                <SpacedColumnStack justifyContent={"space-between"} height="100%">
                                    <SpacedColumnStack>
                                        <SpacedRowStack>
                                            <StyledFormInput label="Day" readOnly value={moment(date).format("dddd")} />
                                            <StyledControlledFormDateInput onChange={handleDateChange} disabled={modalInfo.ids?.length > 0} label="Date" name="employeeHour.date" maxDate={new Date()} />
                                            <EntryTypeDDL name="employeeHour.employeeHoursStatusEntry1ID" />
                                        </SpacedRowStack>
                                        <SpacedRowStack>
                                            <StyledControlledTimeInput label="Entry" name="employeeHour.entry1" />
                                            <StyledControlledTimeInput label="Exit" name="employeeHour.exit1" />
                                            <TimeSpanInput label="Total Hours" name="employeeHour.totalHours" />
                                        </SpacedRowStack>
                                        {showSecondEntry &&
                                            <SpacedRowStack>
                                                <StyledControlledTimeInput label="Entry" name="employeeHour.entry2" />
                                                <StyledControlledTimeInput label="Exit" name="employeeHour.exit2" />
                                                <EntryTypeDDL name="employeeHour.employeeHoursStatusEntry2ID" />
                                            </SpacedRowStack>

                                        }
                                        <Box width="25%">
                                            {showSecondEntry ?
                                                <OutlinedTableButton
                                                    fullWidth
                                                    onClick={() => {
                                                        methods.setValue("showSecondEntry", false)
                                                        methods.setValue("employeeHour.entry2", null)
                                                        methods.setValue("employeeHour.exit2", null)
                                                    }}>- Remove Entry</OutlinedTableButton>
                                                :

                                                <StyledHoverOutlineButton fullWidth onClick={() => methods.setValue("showSecondEntry", true)}>+ Add Entry</StyledHoverOutlineButton>
                                            }
                                        </Box>
                                    </SpacedColumnStack>
                                    <SpacedRowStack>
                                        <StyledControlledAutoCompleteInput label="Partial Days" name="employeeHour.partialOffDayTypeID" options={[{ value: 0, text: "No Partial Hours" }, ...(viewModel.partialOffDayTypes ?? [])]} />
                                        <TimeSpanInput label="Hours" name="employeeHour.partialOffDayHours" />
                                    </SpacedRowStack>
                                </SpacedColumnStack>

                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.UpdateHours} />
                    </>}
                </>
            }
        </GlobalModal>

    );
}

function EntryTypeDDL(props: { name: string }) {
    return (
        <StyledControlledSelectInput key={props.name} label="Entry Type" name={props.name}>
            <MenuItem key={1} value={1}>Worked from Home</MenuItem>
            <MenuItem key={-1} value={-1}>Worked from Office</MenuItem>
        </StyledControlledSelectInput>
    )
}

function TimeSpanInput(props: TextFieldProps & { readOnly?: boolean }) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const { setValue, watch } = useFormContext();

    function onTimeChange(e: any) {
        setValue(props.name ?? "", e.target.value)
        if (props.name == "employeeHour.totalHours") {
            setValue("employeeHour.entry1", null)
            setValue("employeeHour.exit1", null)
            setValue("employeeHour.entry2", null)
            setValue("employeeHour.exit2", null)
        }
    }
    return (
        <>
            <FormControl fullWidth variant="standard">

                <TextField
                    //{...field}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label={props.label}
                    placeholder={props.placeholder}
                    InputProps={{ // <-- This is where the toggle button is added.
                        className: "section-input section-color",
                        sx: {
                            paddingRight: props.type == "number" ? "0.2rem !important" : "0.8rem",
                            marginTop: "1.3rem !important"
                        },
                        endAdornment: (
                            error && <InputAdornment position="end">
                                <SvgIcon name={SvgNames.CentarixNotificationDidntArrive} height={1.5} color={Theme.palette.error.main} />
                            </InputAdornment>)
                    }}
                    {...props}
                    onChange={(e) => onTimeChange(e)}
                    value={field.value ?? ""}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
};


function StyledControlledTimeInput(props: TextFieldProps & { readOnly?: boolean }) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    const { setValue, watch } = useFormContext();

    const date = watch("employeeHour.date")

    function onTimeChange(e: any) {

        let currentDate = moment(date).format("YYYY-MM-DD")

        setValue(props.name ?? "", moment(currentDate + ' ' + e.target.value, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm"))
    }
    return (
        <>
            <FormControl fullWidth variant="standard">

                <TextField
                    //{...field}
                    type="time"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label={props.label}
                    placeholder={props.placeholder}
                    InputProps={{ // <-- This is where the toggle button is added.
                        className: "section-input",
                        sx: {
                            paddingRight: props.type == "number" ? "0.2rem !important" : "0.8rem",
                            marginTop: "1.3rem !important"
                        },
                        endAdornment: (
                            error && <InputAdornment position="end">
                                <SvgIcon name={SvgNames.CentarixNotificationDidntArrive} height={1.5} color={Theme.palette.error.main} />
                            </InputAdornment>)
                    }}
                    {...props}
                    onChange={(e) => onTimeChange(e)}
                    value={moment(field.value).format("HH:mm")}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
};

