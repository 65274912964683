import { yupResolver } from '@hookform/resolvers/yup';
import { AlertColor, Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { push } from 'connected-react-router';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, boolean, date, object, string } from 'yup';
import { AddItemResult, FolderNamesEnum, ModalFlow, ModalName, OrderMethod, SidebarEnum } from '../../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal, ModalInfo, NavigationInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { ParentQuote } from '../../Utility/request-types';
import { GlobalForm, SmallTypography, StyledOutlineButton } from '../../Utility/shared-components';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { setShowConfirmExit } from '../../ReduxRelatedUtils/utilsReducer';


// type UploadQuoteViewModel = {
//     parentQuote: ParentQuote
// }
type UploadQuoteFormData = {
    isReorder: boolean,
    parentQuote: ParentQuote,
    expectedSupplyDays: number | null,
    expectedSupplyDate: string,
    hasFile: boolean,
    fieldsRequired: boolean
}
export default function UploadQuoteModal() {
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<AddItemFormData>
    const fromBackButton = (useSelector<ApplicationState>(state => state.modalWithLoading.modal.future) as Modal<ModalInfo>[]).length > 0



    function onSubmit(data: UploadQuoteFormData, e: any) {
        var url = '/Requests/UploadQuoteModal'
        let tempDataCopy = { ...tempData, request: { ...tempData.request } }
        tempDataCopy.request.parentQuote = data.parentQuote
        tempDataCopy.request.parentQuoteID = tempDataCopy.request.parentQuote.parentQuoteID
        if (data.expectedSupplyDays) { tempDataCopy.request.expectedSupplyDays = data.expectedSupplyDays }
        if (data.isReorder) {
            dispatch(setModalLoading(true))
            dispatch(setTempData(tempDataCopy))
            if (fromBackButton) {
                dispatch({ type: "redoModals" })
                dispatch({ type: "redoTempData" })
            }
            else {
                dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.AdditionalRequestInfo }))
            }
        }
        else {
            if (tempData.request.orderMethod.descriptionEnum == OrderMethod.AddToCart || tempData.request.orderMethod.descriptionEnum == OrderMethod.RequestItem) {

                dispatch(setLoading(true))
                fetch(url, {
                    method: "POST",
                    body: JSON.stringify(tempDataCopy),
                    headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
                })
                    .then(response => {
                        if (response.ok) { return response.json() }
                        throw response.json();
                    })
                    .then((res: { result: AddItemResult }) => {
                        let message = "";
                        let severity: AlertColor = "success"
                        switch (res.result) {
                            case AddItemResult.OutOfBudget:
                                message = "Item is out of budget and is awaiting approval";
                                severity = "warning";
                                break;
                            case AddItemResult.ItemRequested:
                                message = "Item was Requested";
                                break;
                            default:
                                message = "Item was Added to Cart";
                        }
                        batch(() => {
                            batch(() => {
                                dispatch(setLoading(false))
                                dispatch(removeModal())
                                dispatch(setTempData({}))
                                dispatch(setGlobalSnack({ open: true, message: message, severity: severity }))
                                dispatch(setShowConfirmExit(false))

                                if (navigationInfo.sidebarType == SidebarEnum.Add) {
                                    dispatch(push("/RequestsList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))
                                }
                                else {
                                    dispatch(setReloadIndex(true))
                                }
                            })
                        })
                    })
                    .catch(err => {
                        Promise.resolve(err).then(text => {
                            dispatch(setLoading(false))
                            dispatch(setGlobalSnack({ open: true, message: text.errorMessage, severity: "error" }))
                        })
                    })
            }
            else {
                dispatch(setLoading(true))

                fetch("/Requests/CheckIfMoveToTerms", {
                    method: "POST",
                    body: JSON.stringify(tempDataCopy),
                    headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
                }).then(response => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                }).then((res: { result: AddItemResult }) => {
                    dispatch(setLoading(false))

                    if (res.result == AddItemResult.OutOfBudget) {
                        batch(() => {
                            batch(() => {
                                dispatch(removeModal())
                                dispatch(setTempData({}))
                                dispatch(setShowConfirmExit(false))

                                dispatch(setGlobalSnack({ open: true, message: "Item out of Budget", severity: "warning" }))
                            })
                            if (navigationInfo.sidebarType == SidebarEnum.Add) {
                                dispatch(push("/RequestsList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))
                            }
                            else {
                                dispatch(setReloadIndex(true))
                            }
                        })
                    }
                    else {
                        batch(() => {

                            dispatch(setModalLoading(true))
                            dispatch(setTempData(tempDataCopy))
                            if (fromBackButton) {
                                dispatch({ type: "redoModals" })
                                dispatch({ type: "redoTempData" })
                            }
                            else {
                                dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.Terms }))
                            }
                        })
                    }

                })
                    .catch(err => {
                        Promise.resolve(err).then(text => {
                            batch(() => {
                                dispatch(setLoading(false))
                                dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                            })
                        })
                    })

            }
        }
    }

    return (


        <UploadQuoteForm tempData={tempData} onSubmit={onSubmit} fromBackButton={fromBackButton} />

    );
}


type UploadQuoteFormProps = {
    onSubmit: any,
    tempData: TempData<AddItemFormData>,
    fromBackButton: boolean
}
export function UploadQuoteForm(props: UploadQuoteFormProps) {

    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);

    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<BasicModalInfo>

    const dispatch = useDispatch();
    const validationSchema = object<Partial<Record<keyof UploadQuoteFormData, AnySchema>>>({
        isReorder: boolean(),
        parentQuote: object().when(["fieldsRequired"], {
            is: true,
            then: object<Partial<Record<keyof ParentQuote, AnySchema>>>({
                quoteNumber: string().required("Quote Number is required"),
                expirationDate: date().typeError("Expiration Date must be a vaild date").required("Expiration Date is required").min(moment().startOf("day"), "Expiration date cannot be in the past"),
            }),
            otherwise: object<Partial<Record<keyof ParentQuote, AnySchema>>>({
                quoteNumber: string().nullable(),
                expirationDate: date().nullable().typeError("Expiration Date must be a vaild date").min(moment().startOf("day"), "Expiration date cannot be in the past"),
            })
        }),
        hasFile: boolean().when("fieldsRequired", {
            is: true,
            then: boolean().isTrue("File is required"),
            otherwise: boolean().nullable()
        })
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues:
            {
                parentQuote: {
                    quoteNumber: props.tempData.request?.parentQuote?.quoteNumber ?? "",
                    expirationDate: (props.tempData.request?.parentQuote?.expirationDate ? moment(props.tempData.request?.parentQuote?.expirationDate) : moment()).format("YYYY-MM-DD"),
                },
                isReorder: props.tempData.request?.productID > 0,
                fieldsRequired: props.tempData.request.orderMethod.descriptionEnum != OrderMethod.RequestItem
            } as UploadQuoteFormData
    }
    );
    const isReorder = methods.watch("isReorder")


    // const [viewModel, setViewModel] = useState({} as UploadQuoteViewModel);
    const [error, setError] = useState<CustomError>(new CustomError());

    const parentQuote = methods.watch("parentQuote")
    const hasFile = methods.watch("hasFile")

    useEffect(() => {
        if (props.tempData.request.orderMethod.descriptionEnum == OrderMethod.RequestItem) {
            if (parentQuote.quoteNumber == "" && !hasFile) {
                methods.setValue("fieldsRequired", false)
            }
            else {
                methods.setValue("fieldsRequired", true)

            }
        }
    }, [parentQuote.quoteNumber, hasFile])
    useEffect(() => {
        if (!props.fromBackButton) {
            var url = "/Requests/UploadQuoteModal?productID=" + props.tempData.request?.productID + "";
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: { parentQuote: ParentQuote, errorMessage: string }) => {
                    //setViewModel(result);
                    methods.setValue("parentQuote", result.parentQuote)

                    dispatch(setModalLoading(false))
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        setError({ message: text.errorMessage, showBody: false })
                        dispatch(setModalLoading(false))
                    })
                })
        }
        else {
            methods.setValue("parentQuote.parentQuoteID", props.tempData.request?.parentQuote?.parentQuoteID ?? 0)

            dispatch(setModalLoading(false))

        }

    }, [modalInfo.ids]);


    function handleClearQuote() {
        let newParentQuote = { parentQuoteID: 0, expirationDate: moment().format("YYYY-MM-DD"), quoteNumber: "" } as ParentQuote
        //setViewModel({ ...viewModel, parentQuote: newParentQuote })
        methods.setValue("parentQuote", newParentQuote)
        if (props.tempData.request.orderMethod.descriptionEnum == OrderMethod.RequestItem) {
            methods.setValue("fieldsRequired", false)
        }
    }

    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Upload Quote"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.UploadQuote} onSubmit={props.onSubmit} methods={methods}>
                                <Stack direction="row" spacing={2}>
                                    <Stack direction="column" spacing={2}>
                                        {console.log(parentQuote)}
                                        <DocumentsCard allowMultipleFiles folderID={(parentQuote != null && parentQuote?.parentQuoteID != null && parentQuote.parentQuoteID != 0) ? parentQuote.parentQuoteID.toString() : props.tempData.guid} folderName={FolderNamesEnum.Quotes} />
                                        {parentQuote.parentQuoteID != 0 && <Box width={"6rem"}><StyledOutlineButton onClick={handleClearQuote}><SmallTypography>Clear Quote</SmallTypography> </StyledOutlineButton></Box>}
                                    </Stack>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <StyledControlledFormInput name={"parentQuote.quoteNumber"} readOnly={parentQuote.parentQuoteID != null && parentQuote.parentQuoteID != 0} label="Quote Number" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <StyledControlledFormDateInput disabled={parentQuote.parentQuoteID != null && parentQuote.parentQuoteID != 0} label="Expiration Date" name={"parentQuote.expirationDate"} minDate={new Date()} />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.UploadQuote} submitButtonText={props.tempData.request?.orderMethod.descriptionEnum == OrderMethod.AddToCart ? "Add To Cart" : "Next"} >
                            {isReorder && <ModalBackButton modalFlowKey={ModalFlow.AddItem} />}
                        </ModalFooterWithSave></>}
                </>}
        </>)
}