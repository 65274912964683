import { Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalName } from "../Constants/app-enums";
import { ModalSizes } from "../Constants/client-side-enums";
import { selectModalPresent } from "../ReduxRelatedUtils/customConfigureStore";
import { setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../ReduxRelatedUtils/redux-types";
import GlobalModal from "../Requests/Modals/global-modal";
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from "../Requests/Modals/modal-components";
import ModalSkeleton from "../Requests/Modals/modal-skeleton";
import { BasicModalInfo, CustomError } from "../Utility/general-types";
import { basicModalPost, objectToFormData } from "../Utility/root-function";
import { Employee } from "../Utility/user-types";


export default function SuspendUser() {
    console.log('in suspend user')
    const dispatch = useDispatch()
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>; 

    const [viewModel, setViewModel] = useState<Employee>({} as Employee);
    const [error, setError] = useState<CustomError>(new CustomError())

    useEffect(() => {
        let url = "/Users/SuspendUserModal?Id=" + modalInfo.ids[0];
        fetch(url, {
            method: "GET"
        })
            .then((response) => {

                if (response.ok) {
                    return response.json()
                }
                else { throw response.text() }
            })
            .then((result: Employee) => {
                setViewModel(result);
                dispatch(setModalLoading(false))

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text, showBody: false } as CustomError)
                    dispatch(setModalLoading(false))
                })
            })
    }, [])

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        var formData = objectToFormData(viewModel)
        var url = "/Users/SuspendUserModal";
        basicModalPost(url, setError, dispatch, formData)
    }

    let suspendText = 'Suspend';
    if ((viewModel.lockoutEnabled ?? false) && moment(viewModel.lockoutEnd) > moment()) {
        suspendText = 'Unsuspend'
    }
    return (
        <GlobalModal modalKey={ModalName.SuspendUser} size={ModalSizes.sm}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={(viewModel.firstName ?? "") + ' ' + (viewModel.lastName ?? "")} errorMessage={error.message} />
                    <StyledDialogContent>
                        {error.showBody ?
                            <form onSubmit={onSubmit} id={ModalName.SuspendUser}>
                                <Typography>Are you sure you would like to {suspendText.toLowerCase()} this user?</Typography>
                            </form>
                            : null
                        }
                    </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.SuspendUser} submitButtonText={suspendText} />
                </>
            }
        </GlobalModal>
    )
}