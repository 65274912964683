import { createSlice } from "@reduxjs/toolkit";
import { RequestIndexViewModel, RequestItemViewModel } from "../Utility/request-types";
import { UserIndexViewModel } from "../Utility/user-types";
import { ApplicationState } from "./redux-types";

const initialState={} as RequestItemViewModel | RequestIndexViewModel | UserIndexViewModel;
const viewmodelSlice = createSlice({
    name: 'viewmodel',
    initialState,
    reducers: {
        setViewModel(state, action) {
            return action.payload
        },
        setViewModelError(state, action) {
            state.errorMessage = action.payload; 
        }
    }
})

export const {setViewModel, setViewModelError} = viewmodelSlice.actions

export default  viewmodelSlice.reducer

export const selectErrorMessage = (state: ApplicationState) => state.viewModel.errorMessage;

export const selectPagedList = (state:ApplicationState) => (state.viewModel as RequestIndexViewModel).pagedList