import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { BasicModalInfo, CustomError, TimekeeperActionModalInfo } from '../Utility/general-types';
import { GlobalForm } from '../Utility/shared-components';
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from 'moment';
type ReportOffDayViewModel = {
  offDayType: string
}
type ReportOffDayFormData = {
  toDate:string,
  fromDate:string
  typeID:number
}
export default function ReportOffDayModal() {
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<TimekeeperActionModalInfo>;    
    const [error, setError] = useState<CustomError>(new CustomError());
    const methods = useForm<ReportOffDayFormData>({
        defaultValues:{
            fromDate: moment(new Date()).format('YYYY-MM-DD'),
            typeID: modalInfo.typeID
        }
      });
  
    const [viewModel, setViewModel] = useState<ReportOffDayViewModel>({} as ReportOffDayViewModel)
    
    useEffect(() => {
        var url = '/Timekeeper/ReportOffDayModal?offDayTypeID='+modalInfo.typeID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: ReportOffDayViewModel) => {
                dispatch(setModalLoading(false))
                setViewModel(result);     
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, []);

       
    function onSubmit(data: ReportOffDayFormData) {       
        var url = '/Timekeeper/ReportOffDayModal'
        dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then(( result:string ) => {
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(removeModal())
                        dispatch(setGlobalSnack({ open: true, message: result.toString(), severity: "success" }))
                        dispatch(setReloadIndex(true))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
    }
    const [state, setState] = useState([
        {
          startDate: new Date(),
          endDate:new Date(),
          key: "selection"
        }
      ]);

      const handleOnChange = (ranges:any) => {
        const { selection } = ranges;        
        setState([selection]);
        methods.setValue("fromDate", moment(selection.startDate).format('YYYY-MM-DD'))
        if(moment(selection.startDate).diff(moment(selection.endDate), "days")!=0){
            methods.setValue("toDate", moment(selection.endDate).format('YYYY-MM-DD'))
        }
      };
    
    return (
        <GlobalModal key={ModalName.ReportOffDayModal} size={ModalSizes.sm} modalKey={ModalName.ReportOffDayModal}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Report "+ viewModel.offDayType} errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.ReportOffDayModal} onSubmit={(data: any) => onSubmit(data)} methods={methods}>
                            <DateRangePicker
                                 onChange={handleOnChange}
                               
                                moveRangeOnFirstSelection={false}
                                months={1}
                                showDateDisplay={false}
                                showMonthAndYearPickers={false}
                               ranges={state}
                                direction="horizontal"
                                renderStaticRangeLabel={()=>{}}
                                staticRanges={[]}
                               // inputRanges={[]}
                                />
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.ReportOffDayModal} submitButtonText="Confirm" />
                    </>}
                </>
            }
        </GlobalModal>

    );
}
