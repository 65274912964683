import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { BasicModalInfo, CustomError } from '../Utility/general-types';
import { GlobalForm } from '../Utility/shared-components';


type DeleteHoursModalViewModel = {
  
}
type DeleteHoursFormData = {
  employeeHoursID:number
}
export default function DeleteHoursModal() {
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;    
    const [error, setError] = useState<CustomError>(new CustomError());
    const methods = useForm<DeleteHoursFormData>({
        defaultValues:{
            employeeHoursID:Number(modalInfo.ids[0])
        }
      });
  
    const [viewModel, setViewModel] = useState<DeleteHoursModalViewModel>()
    
    useEffect(() => {
        dispatch(setModalLoading(false));
    }, [modalInfo.ids]);


       
    function onSubmit(data: DeleteHoursFormData) {       
        var url = '/Timekeeper/DeleteHourModal'
        dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then(( result:string ) => {
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(removeModal())
                        dispatch(setGlobalSnack({ open: true, message: result.toString(), severity: "success" }))
                        dispatch(setReloadIndex(true))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
    }

    return (
        <GlobalModal key={ModalName.DeleteHours} size={ModalSizes.xs} modalKey={ModalName.DeleteHours}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Delete Hours" errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.DeleteHours} onSubmit={(data: any) => onSubmit(data)} methods={methods}>
                                <Typography>
                                    Confirm that you would like to delete these hours that you have clocked.
                                </Typography>
                              
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.DeleteHours} submitButtonText="Delete" />
                    </>}
                </>
            }
        </GlobalModal>

    );
}
