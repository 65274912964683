
import { AutocompleteOption, BasicModalInfo, CustomError, SelectListItem } from '../../Utility/general-types';
import Typography from '@mui/material/Typography';
import { ControlledCheckbox, StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledFormInput } from '../../Utility/custom-input-components';

import { useController, useForm, useFormContext, useWatch } from 'react-hook-form';
import { ModalSizes } from '../../Constants/client-side-enums';
import { ModalHeader, ModalFooterWithSave, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { ModalFlow, ModalName, ParentCategoryEnum, SvgNames } from '../../Constants/app-enums';
import { ApplicationState, Modal, TempData } from '../../ReduxRelatedUtils/redux-types';

import { BoldTypography, GlobalForm, SectionCheckbox, SpacedColumnStack, SpacedRowStack, StyledError, StyledFilledButton, StyledOutlineButton, StyledTableImage, StyledWarning, SvgIcon } from '../../Utility/shared-components';
import { Button, FormControlLabel, Grid, List, MenuItem, Popover, SelectProps, TextareaAutosize, Tooltip } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { BatchLotBlock, WarrantyBlock } from '../ItemCard/item-components';
import GlobalModal from './global-modal';
import moment from 'moment';
import PopupState, { bindHover, bindPopover, bindToggle, bindTrigger } from 'material-ui-popup-state';
import { Box, Stack } from '@mui/system';
import { SelectLocationsVisual } from '../select-locations-visual';
import { AnySchema, object, string, array, number, date } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { setLastSelectedLocationReload } from '../../ReduxRelatedUtils/selectedLocationReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { fontFamily } from 'html2canvas/dist/types/css/property-descriptors/font-family';
import { v4 } from 'uuid';
import { Employee } from '../../Utility/user-types';
import LocationDDLs from '../../Shared/select-location-view';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { SelectLocationFormData, SelectLocationViewModel } from './receive-location-modal';
import { Request } from '../../Utility/request-types';


export type ReceiveItemViewModel = {
    productName: string,
    productImage: string,
    isPartialDisabledMessage:string,
    amount: number,
    batchLot: number,
    parentCategoryEnum: ParentCategoryEnum,
    expirationDate: string,
    receivedBy: string,
    showIsPartial: boolean,
    unitTypeDescription: string,
    users: SelectListItem[],
    orderDate:""
} & SelectLocationViewModel


export type ReceiveItemFormData = {
    request: Request,
    amount: number,
    amountArrived: number,
    receivedBy: string
} & SelectLocationFormData
export default function ReceiveModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const fromBackButton = (useSelector<ApplicationState>(state => state.modalWithLoading.modal.future) as Modal<BasicModalInfo>[]).length > 0
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<ReceiveItemFormData>


    const validationSchema = object<Partial<Record<keyof ReceiveItemFormData, AnySchema>>>({
        request: object<Partial<Record<keyof Request, AnySchema>>>({
            batchExpiration: date().nullable().typeError("Expiration Date must be a valid date"),
            arrivalDate: date().typeError("Arrival Date must be a vaild date").required("Arrival Date is required").max(moment().add("day").startOf("day"), "Arrival Date cannot be in the future"),
        }),
        amountArrived: number().test("test max amount", "Amount arrived must be less than the amount ordered", function (this, options) {
            if (this.parent.request.isPartial) {
                return this.parent.amount > this.parent.amountArrived
            }
            else return true;
        })

    })

    const methods = useForm<ReceiveItemFormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            request: {
                requestID: Number.parseFloat(modalInfo.ids[0]),
                batch: fromBackButton ? tempData.request.batch : undefined,
                batchExpiration: fromBackButton ? tempData.request.batchExpiration : undefined,
                isPartial: fromBackButton ? tempData.request.isPartial : false,
                isClarify: fromBackButton ? tempData.request.isClarify : false,
                noteForClarifyDelivery: fromBackButton ? tempData.request.noteForClarifyDelivery : "",
                arrivalDate: fromBackButton ? tempData.request.arrivalDate : moment().format("YYYY-MM-DD"),
            },
            amount: fromBackButton ? tempData.amount : 0,
            amountArrived: fromBackButton ? tempData.amountArrived : 0,
            receivedBy: fromBackButton ? tempData.receivedBy : "",
        }
    });
    const arrivalDate = useWatch({name:"request.arrivalDate", control:methods.control})
    const isPartial = methods.watch("request.isPartial");
    const isClarify = methods.watch("request.isClarify");
    const [viewModel, setViewModel] = useState({} as ReceiveItemViewModel);
    useEffect(() => {
        var url = "/Requests/ReceiveModal?requestID=" + modalInfo.ids[0] + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response;
            })
            .then((result: ReceiveItemViewModel) => {
                if (!fromBackButton) {
                    methods.setValue("amount", result.amount);
                    methods.setValue("request.batch", result.batchLot);
                    methods.setValue("receivedBy", result.receivedBy);
                }
                console.log("result of get")
                setViewModel(result);
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);



    function onSubmit(data: ReceiveItemFormData, e: any) {
        batch(() => {
            dispatch(setTempData({ ...data } as TempData<ReceiveItemFormData>))
            dispatch(setModalStep({ modalFlow: ModalFlow.ReceiveItem, modalStep: ModalName.ReceiveLocation }))
        })

    }

    var showBatch = viewModel.parentCategoryEnum == ParentCategoryEnum.ReagentsAndChemicals || viewModel.parentCategoryEnum == ParentCategoryEnum.Biological || viewModel.parentCategoryEnum == ParentCategoryEnum.Clinical;

    return (
        <>

            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Receive Item" errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.ReceiveItem} onSubmit={(data: any, e: any) => onSubmit(data, e)} methods={methods}>

                                <SpacedColumnStack>
                                    <SpacedRowStack key={0} alignItems="center">
                                        <StyledTableImage width={6} src={viewModel?.productImage ?? ""} />
                                        <BoldTypography variant="h6" >{viewModel.productName}</BoldTypography>
                                    </SpacedRowStack>
                                    <SpacedRowStack key={1} alignItems="start">
                                        <Stack direction="column" width={"100%"}>
                                        <StyledControlledFormDateInput name="request.arrivalDate" label="Arrival Date" />
                                       <StyledWarning> {moment(arrivalDate).isBefore(moment(viewModel.orderDate).startOf("day"))? "Warning: Arrival Date is before Order Date":""}</StyledWarning>
                                        </Stack>
                                        <StyledControlledAutoCompleteInput name="receivedBy" label="Received By"
                                            options={viewModel?.users?.map(u => { return { text: u.text, value: u.value } as AutocompleteOption }) ?? []}
                                        />
                                        <FormControlLabel key="clarifyCheckbox" sx={{alignSelf:"center"}}
                                            control={<ControlledCheckbox name="request.isClarify" />}
                                            label={<Typography>Clarify</Typography>} />

                                        {<FormControlLabel sx={{ width: "100%", alignSelf:"center" }} key="partialCheckbox" disabled={!viewModel.showIsPartial}
                                            control={<ControlledCheckbox name="request.isPartial" />}
                                            label={<Typography sx={{ opacity: viewModel.showIsPartial ? "1" : "0.5", display: "flex" }}>Partial Delivery {!viewModel.showIsPartial &&
                                                <Tooltip title={viewModel.isPartialDisabledMessage} arrow>
                                                    <Box marginLeft="0.5rem" display="flex" alignItems="center"><SvgIcon height={1.5} name={SvgNames.Info} /></Box>
                                                </Tooltip>}</Typography>} />}
                                    </SpacedRowStack>
                                    {isClarify && <SpacedRowStack>
                                        <StyledControlledFormInput sx={{ width: '100&' }} label="Clarification Note" name="request.noteForClarifyDelivery"></StyledControlledFormInput>
                                    </SpacedRowStack>}
                                    {isPartial && <SpacedRowStack>
                                        <StyledControlledFormInput sx={{ width: '25%' }} type="number" label={`Out of ${viewModel.amount} ${viewModel.unitTypeDescription} arrived `} name="amountArrived"></StyledControlledFormInput>
                                    </SpacedRowStack>}
                                    <SpacedRowStack alignItems={"center"} key={2}>
                                        {showBatch && <BatchLotBlock />}
                                    </SpacedRowStack>
                                </SpacedColumnStack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.ReceiveItem} submitButtonText="Choose Location" />
                    </>
                    }
                </>
            }
        </>

    );
}





