
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from 'react-redux';
import { selectPagedList } from "../../ReduxRelatedUtils/viewmodel-reducer";
import ColumnOptionsPopover from "../../Requests/column-options-popover";
import { SmallTypography } from "../../Utility/shared-components";

export default function IndexTableHeaders(){

    const pagedList  = useSelector(selectPagedList);

    return (
        <TableHead sx={{display:"table", height:"3.75rem", tableLayout:"fixed", width:"100%"}}>
            <TableRow sx={{borderBottom:"none"}}>

                {pagedList[0].columns.map((col, i) =>
                    <TableCell key={col.title+i+col.width} width={`${col.width}%`}>
                        {col.headerProperties.containsDisplayOptions ?
                            <ColumnOptionsPopover columnDisplayOptions={col.headerProperties.columnDisplayOptions} title={col.title} />
                        :
                        <SmallTypography>{col.title}</SmallTypography>
                        }

                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}
