
import { Typography , Box, Tooltip} from "@mui/material";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { ModalFlow, ModalName, SvgNames } from "../../Constants/app-enums";
import { setModalLoading, setModal } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../../ReduxRelatedUtils/redux-types";
import { AddItemFormData } from "../../Shared/AddCardComponents/add-types";
import LocationDDLs from "../../Shared/select-location-view";
import { BasicModalInfo } from "../../Utility/general-types";
import { IsEdittableContext, SpacedColumnStack, StyledFilledButton, SvgIcon } from "../../Utility/shared-components";
import { SelectLocationViewModel } from "../Modals/receive-location-modal";
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore";
import { RequestItemViewModel } from "../../Utility/request-types";

export function LocationTab(props:{requestID:number }){
    const dispatch = useDispatch()
    const methods = useFormContext<AddItemFormData>();
    const isEdittableContext = useContext(IsEdittableContext)
    const requestID = methods.watch("request.requestID")
    const viewModel = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.requestID)?.viewModel as RequestItemViewModel)?.selectLocationViewModel) as SelectLocationViewModel;
   
    function archiveRequest(){
        batch(()=>{
        dispatch(setModalLoading(true))
        dispatch(setModal({modalFlow: ModalFlow.AddLocation, modalStep: ModalName.ConfirmArchive, modalInfo:{ids:[requestID.toString()]}} as Modal<BasicModalInfo>))

    })
    }
    return (
        <SpacedColumnStack>
            {props.requestID>0 && <Typography></Typography>}
    <LocationDDLs requestID={props.requestID?.toString()} key={"locationddls"+isEdittableContext.isEdittable} />
    {(!viewModel?.isArchived && requestID > 0 ) && <Box width="100%" display={"flex"} justifyContent={"center"}><StyledFilledButton onClick={archiveRequest} disabled={isEdittableContext.isEdittable}>Clear Location</StyledFilledButton>{isEdittableContext.isEdittable && <Tooltip title="Cannot clear location when edit mode is on" arrow>
                                                    <Box marginLeft="0.5rem" display="flex" alignItems="center"><SvgIcon height={1.5} name={SvgNames.Info} /></Box>
                                                </Tooltip>}</Box> }
    </SpacedColumnStack>
    )
}