import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { InfoPopupName, ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, InfoPopup, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, BasicModalInfo, CustomError } from '../Utility/general-types';
import { Request } from '../Utility/request-types';
import { basicModalPost, objectToFormData } from '../Utility/root-function';
import { BoldTypography, SmallTypography, SpacedRowStack, StyledTableImage } from '../Utility/shared-components';
import { LocationInstance } from './locations-types';
import queryString from 'query-string'
import { push } from 'connected-react-router'
import EditCardPopover from '../Shared/edit-card-popover';
import { EditCardType } from '../Shared/edit-card';
import { setInfoPopup } from '../ReduxRelatedUtils/infoPopupReducer';

type LocationRequest = {
    requestID: number,
     productName: string, 
     productImage: string
    }
type LocationRequestsModalViewModal = {
    parentLocation: LocationInstance,
    requests: LocationRequest[],
    locationParents: string[],
    locationName:string
}
export default function LocationRequestsModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<LocationModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState<LocationRequestsModalViewModal>()
    const [itemModal, setItemModal] = useState<{open:boolean, id:string}>({open:false, id:""})


    useEffect(() => {
        var url = "/Locations/LocationRequestsModal?locationID=" + modalInfo.parentLocationID + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: LocationRequestsModalViewModal) => {
                setViewModel(result);
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.parentLocationID]);


    return (
        <GlobalModal key={ModalName.LocationRequests} size={ModalSizes.sm} modalKey={ModalName.LocationRequests}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader subHeaderText={viewModel?.locationParents.join(" > ")} headerText={(viewModel?.parentLocation.locationType?.locationTypeName??"")+" "+(viewModel?.locationName??"")} errorMessage={error.message} />
                    {error.showBody ? <>
                        <StyledDialogContent>

                            <Box maxHeight={"90%"} overflow="scroll" borderRadius="0.15rem" border={(theme) => "solid 1px " + theme.palette.grey[400]}>
                                <Stack>
                                    {viewModel?.requests?.map((r, i) =>
                                        <SpacedRowStack alignItems={"center"} padding="1.5rem" borderBottom={(theme) => "solid 1px " + theme.palette.grey[400]}>
                                            <StyledTableImage width={5} src={r.productImage ?? ""} />
                                            <BoldTypography sx={{cursor:"pointer"}}   onClick={() => dispatch(setInfoPopup({infoPopupName: InfoPopupName.EditItem, id: r.requestID.toString()} as InfoPopup))}>{r.productName}</BoldTypography>
                                        </SpacedRowStack>
                                    )}
                                </Stack>
                            </Box>
                        </StyledDialogContent>
                    </>
                        : null
                    }
                </>
            }
        </GlobalModal>

    );
}
