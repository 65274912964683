import { createSlice } from '@reduxjs/toolkit'
import { LoggedInUser } from './redux-types'

const usersInfoSlice = createSlice({
    name: 'userInfo',
    initialState: {} as LoggedInUser,
    reducers: {
        setUserInfo(state:LoggedInUser, action) {
            return action.payload
        },
       
    }
})

export const { setUserInfo } = usersInfoSlice.actions
export default usersInfoSlice.reducer

const isAuthenticatedSlice = createSlice({
    name: 'isAuthenticated',
    initialState: false,
    reducers: {
        setIsAuthenticated(state:boolean, action) {
            return action.payload
        },
       
    }
})

export const { setIsAuthenticated } = isAuthenticatedSlice.actions
export const isAuthenticatedReducer = isAuthenticatedSlice.reducer
