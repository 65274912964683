import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import LocationRequestsModal from './location-requests-modal';
import LocationZoomModal from './location-zoom-modal';



export default function LocationZoomFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName

   // console.log("in add location flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.LocationZoom:
                return (<LocationZoomModal />)
            case ModalName.LocationRequests:
                return (<LocationRequestsModal />)
            default: return null;
        }
    }

    return (
            <ModalContent />
    );

}
