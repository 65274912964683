import { shallowEqual, useSelector } from 'react-redux';
import { ModalFlow } from '../Constants/app-enums';
import AddLocationFlow from '../LabManagement/add-location-flow';
import { selectModalFlow } from '../ReduxRelatedUtils/customConfigureStore';
import CartTotalModal from '../Requests/Modals/cart-total-modal';
import DeleteModal from '../Requests/Modals/delete-modal';
import EditQuoteDetailsModal from '../Requests/Modals/edit-quote-details-modal';
import ItemFlow from '../Requests/Modals/item-flow';
import ListModalFlow from '../Requests/Modals/list-modal-flow';
import QuoteEmailModal from '../Requests/Modals/quote-email-modal';
import ShareModal from '../Requests/Modals/share-modal';
import DenyHoursModal from '../Users/deny-hours-modal';
import SuspendUser from '../Users/suspend-user-modal';
import ReceiveModal from '../Requests/Modals/receive-modal';
import LocationZoomModal from '../LabManagement/location-zoom-modal';
import LocationZoomFlow from '../LabManagement/location-zoom-flow';
import { useState } from 'react';
import ReceiveItemFlow from '../Requests/Modals/receive-item-flow';
import { PayModal } from '../Requests/Modals/pay-modal';
import AddInvoiceModal from '../Requests/Modals/add-invoice-modal';
import AddPaymentMethodFlow from '../Accounting/add-payment-method-flow';
import ExitHoursModal from '../Timekeeper/exit-hours-modal';
import DeleteHoursModal from '../Timekeeper/delete-hours-modal';
import ConfirmDayOffModal from '../Timekeeper/confirm-day-off-modal';
import UpdateHoursModal from '../Timekeeper/update-hours-modal';
import ReportOffDayModal from '../Timekeeper/report-off-day-modal';
import { RevertStatusModal } from '../Requests/Modals/revert-status-modal';
import { DocInfoModal } from '../Requests/Modals/doc-info-modal';
import ConfirmOrderModal from '../Requests/Modals/confirm-order-modal';
import ReviewQuoteFlow from '../Requests/Modals/review-quote-flow';
import AssociateRoomModal from '../LabManagement/associate-room-modal';
import OrderLateModal from '../Requests/Modals/order-late-modal';
import { ConfirmExitModal } from './confirm-exit-modal';

export const ModalLoader = () => {
    const modalFlow = useSelector(selectModalFlow, shallowEqual) as ModalFlow
    function Modal() {
        switch (modalFlow) {
            case ModalFlow.List:
                return <ListModalFlow key='listmodalflow' />

            case ModalFlow.Share:
                return <ShareModal key='sharemodal' />
            case ModalFlow.DeleteItem:
                return <DeleteModal key='deletemodal' />
            case ModalFlow.AddItem:
                return <ItemFlow key='itemFlow' />
            case ModalFlow.SuspendUser:
                return <SuspendUser key='suspendusermodal' />
            case ModalFlow.DenyHours:
                return <DenyHoursModal key='denyhoursmodal' />
            case ModalFlow.CartTotal:
                return <CartTotalModal key='cartTotalModal' />
            case ModalFlow.AskForQuote:
                return <QuoteEmailModal key='quoteEmailModal' />
            case ModalFlow.EditQuoteDetails:
                return <ReviewQuoteFlow key='editQuoteDetailsModal' />
            case ModalFlow.AddLocation:
                return <AddLocationFlow key='addLocationModal' />
            case ModalFlow.ReceiveItem:
                return <ReceiveItemFlow key='receiveModal' />
            case ModalFlow.LocationZoom:
                return <LocationZoomFlow key='locationZoom' />
            case ModalFlow.Pay:
                return <PayModal key='payModal' />
            case ModalFlow.AddInvoice:
                return <AddInvoiceModal key='addInvoiceModal' />
            case ModalFlow.AddPaymentMethod:
                return <AddPaymentMethodFlow key='paymentMethodModal' />
            case ModalFlow.ExitHours:
                return <ExitHoursModal key='exitHoursModal' />
            case ModalFlow.DeleteHours:
                return <DeleteHoursModal key='DeleteHours' />
            case ModalFlow.ConfirmDayOff:
                return <ConfirmDayOffModal key='ConfirmDayOffModal' />
            case ModalFlow.UpdateHours:
                return <UpdateHoursModal key='updateHours' />
            case ModalFlow.RevertStatus:
                return <RevertStatusModal key='revertStatusModal' />
            case ModalFlow.ReportOffDayModal:
                return <ReportOffDayModal key='ReportOffDayModal' />
            case ModalFlow.DocModalInfo:
                return <DocInfoModal key='DocInfoModal' />
            case ModalFlow.ConfirmOrder:
                return <ConfirmOrderModal key='ConfirmOrderModal' />
            case ModalFlow.ReviewQuote:
                return <ReviewQuoteFlow key='reviewQuote' />
                case ModalFlow.ConfirmExitModal:
                    return <ConfirmExitModal key='ConfirmExitModal' />
            case ModalFlow.OrderLate:
                return <OrderLateModal key='orderLateModal' />
            default:
                return (<></>);

        }

    }

    return <Modal />;

}
