import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function ModalSkeleton() {
    return (
        <Box data-testid="skeleton" height="100%" padding={"2rem"}>
            <Stack spacing={2} height="100%" justifyContent={"space-between"}>
                <Skeleton style={{ margin: "auto" }} height="2rem" />
                <Divider sx={{ margin: "0rem 1.5rem" }} />
                <Skeleton style={{ marginTop: "1rem" }} height={"2rem"} count={4} />
                <Stack justifyContent="end" spacing={1} direction="row">
                    <Skeleton height="2rem" width="5rem" />
                    <Skeleton height="2rem" width="5rem" />
                </Stack>
            </Stack>
        </Box>
    )
}