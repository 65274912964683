import { Avatar, Box, Button, FormControl, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Stack } from "@mui/system";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Controller, useController, useFieldArray, useFormContext } from "react-hook-form";
import { UseFieldArrayReturn } from "react-hook-form/dist/types";
import { useSelector } from "react-redux";
import { CommentTypeEnum, SvgNames } from "../../Constants/app-enums";
import { ApplicationState, LoggedInUser, TempData } from "../../ReduxRelatedUtils/redux-types";
import { AddItemFormData } from "../../Shared/AddCardComponents/add-types";
import { Comment } from "../../Utility/general-types";
import { IsEdittableContext, SmallTypography, StyledError, SvgIcon } from "../../Utility/shared-components";
import Theme from "../../Utility/theme";
import { Employee } from "../../Utility/user-types";

const InitializedComment = {
    commentText: "",
    commentTimeStamp: new Date(),
    commentType: { descriptionEnum: CommentTypeEnum.Comment },
    isCurrentUsersComment: true
} as Comment

export default function CommentsTab() {
    const { control, formState, watch, getValues, reset, setValue } = useFormContext<TempData<AddItemFormData>>()
    const userInfo = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const isEdittableContext = useContext(IsEdittableContext);

    const fieldArrayMethods = useFieldArray(
        {
            control,
            name: "comments"
        }
    );
    const formComments = watch("comments")
    // useEffect(() => {
    //     return () => {
    //         fieldArrayMethods.remove(0);
    //     };
    // }, []);
    return (
        <Stack direction="column" spacing={2}>
            {isEdittableContext.isEdittable && <FirstCommentBox fieldArrayMethods={fieldArrayMethods} />}
            {fieldArrayMethods.fields.map((comment, index) => {
              //  console.log(comment.id)
                return (
                    <CommentBox key={comment.id} index={index} commentUser={comment.applicationUser ?? userInfo}
                        fieldArrayMethods={fieldArrayMethods} />
                )
            })}
        </Stack>
    )
}

function CommentBox(props: { index: number, commentUser: LoggedInUser | Employee, fieldArrayMethods: UseFieldArrayReturn<TempData<AddItemFormData>, "comments", "id"> }) {
    const [editMode, setEditMode] = useState(false)
    const { watch, control, setValue } = useFormContext<TempData<AddItemFormData>>()
    const commentTypeEnum = watch(`comments.${props.index}.commentType.descriptionEnum`)
    const userInfo = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const comment = watch(`comments.${props.index}`)
    const isEdittableContext = useContext(IsEdittableContext);

    return (
        <Box
            data-testid="comment-box"
            border={"solid .1rem " + ((commentTypeEnum == CommentTypeEnum.Warning && editMode) ? Theme.palette.error.main + " !important" : Theme.palette.grey[400])}
            className={editMode && commentTypeEnum == CommentTypeEnum.Comment ? "section-1px-border" : ""}
            padding="1rem"
            borderRadius="0.3rem">
            <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar alt="userImage" sx={{ width: 40, height: 40 }} src={window.location.origin + props.commentUser.userImage} />
                    <StyledControlledCommentInput
                        name={`comments.${props.index}.commentText`}
                        readOnly={(!editMode) || !isEdittableContext.isEdittable}
                        iswarning={commentTypeEnum == CommentTypeEnum.Warning} />
                </Stack>
                {
                    <Stack direction="row" justifyContent="space-between" padding="0 0.5rem 0 4rem">
                        <>
                            {editMode ?
                                <Controller
                                    name={`comments.${props.index}.commentType.descriptionEnum`}
                                    render={({ field: { onChange } }) => {
                                        return (
                                            <Button
                                                startIcon={<SvgIcon name={SvgNames.ReportProblem} height={1} className="icon-black" />}
                                                onClick={() => onChange(commentTypeEnum == CommentTypeEnum.Warning ? CommentTypeEnum.Comment : CommentTypeEnum.Warning)}>
                                                <SmallTypography>{(commentTypeEnum == CommentTypeEnum.Warning ? "Unmark" : "Mark") + " as warning"}</SmallTypography>
                                            </Button>)
                                    }}
                                /> :
                                <SmallTypography fontWeight="500" color={Theme.palette.grey[600]}>{`${props.commentUser.firstName} ${props.commentUser.lastName} ${moment(comment.commentTimeStamp).format("DD MMM yyyy h:mmA")}`}</SmallTypography>
                            }
                            {(usersEqual(userInfo, props.commentUser) && isEdittableContext.isEdittable) &&
                                <Stack direction="row" spacing={1.8}>
                                    <Button sx={{ justifySelf: "end" }}
                                        onClick={() => { props.fieldArrayMethods.remove(props.index) }}>
                                        <SmallTypography fontWeight="500" className="section-color">Delete</SmallTypography>
                                    </Button>
                                    <Button sx={{ justifySelf: "end" }}
                                        onClick={() => {
                                            if (editMode) { props.fieldArrayMethods.update(props.index, { ...comment, commentTimeStamp: new Date() }) }
                                            setEditMode(!editMode)
                                        }}>
                                        <SmallTypography fontWeight="500" className="section-color">{editMode ? "Post" : "Edit"}</SmallTypography>
                                    </Button>
                                </Stack>
                            }
                        </>
                    </Stack>
                }
            </Stack>
        </Box>
    )
}

function FirstCommentBox(props: { fieldArrayMethods: UseFieldArrayReturn<TempData<AddItemFormData>, "comments", "id"> }) {
    const [editMode, setEditMode] = useState(false)
    const [comment, setComment] = useState<Comment>(InitializedComment)
    const userInfo = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const isEdittableContext = useContext(IsEdittableContext);

    return (
        <Box
            data-testid="comment-box"
            border={"solid .1rem " + ((comment.commentType.descriptionEnum == CommentTypeEnum.Warning && editMode) ? Theme.palette.error.main + " !important" : Theme.palette.grey[400])}
            className={editMode && comment.commentType.descriptionEnum == CommentTypeEnum.Comment ? "section-1px-border" : ""}
            padding="1rem"
            borderRadius="0.3rem">
            <Stack direction="column" spacing={0.5}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar alt="userImage" sx={{ width: 40, height: 40 }} src={window.location.origin + userInfo.userImage} />
                    {(!editMode) && <SmallTypography width={"10rem"}>Write a comment</SmallTypography>}
                    <StyledControlledCommentInput
                        onClick={() => { if (!editMode && isEdittableContext.isEdittable) setEditMode(true) }}
                        readOnly={!isEdittableContext.isEdittable}
                        value={comment.commentText}
                        onChange={(e) => { console.log(e); setComment({ ...comment, commentText: e.target.value }) }}
                        iswarning={comment.commentType.descriptionEnum == CommentTypeEnum.Warning} />
                </Stack>
                {(editMode) &&
                    <Stack direction="row" justifyContent="space-between" padding="0 0.5rem 0 4rem">
                        <>
                            <Button
                                startIcon={<SvgIcon name={SvgNames.ReportProblem} height={1} className="icon-black" />}
                                onClick={() => setComment({ ...comment, commentType: { ...comment.commentType, descriptionEnum: comment.commentType.descriptionEnum == CommentTypeEnum.Warning ? CommentTypeEnum.Comment : CommentTypeEnum.Warning } })}>
                                <SmallTypography>{(comment.commentType.descriptionEnum == CommentTypeEnum.Warning ? "Unmark" : "Mark") + " as warning"}</SmallTypography>
                            </Button>
                            <Button sx={{ justifySelf: "end" }}
                                onClick={() => {
                                    props.fieldArrayMethods.prepend({...comment, commentTimeStamp: new Date()})
                                    setComment(InitializedComment)
                                    setEditMode(!editMode)
                                }}>
                                <SmallTypography fontWeight="500" className="section-color">Post</SmallTypography>
                            </Button>
                        </>
                    </Stack>
                }
            </Stack>
        </Box>
    )
}

export function StyledControlledCommentInput(props: TextFieldProps & { readOnly?: boolean, iswarning: boolean }) {

    const { field, fieldState: { error } } = useController({ name: props.name ?? "" });
    //console.log("iswarning " + props.iswarning)
    return (
        <>
            <FormControl fullWidth variant="standard">

                <TextField
                    data-testid="comment-textfield"
                    {...field}
                    variant="standard"
                    placeholder={props.placeholder}
                    InputProps={{ // <-- This is where the toggle button is added.
                        className: props.readOnly ? "read-only-input" : "styled-outline-input",
                        inputProps: { readOnly: props.readOnly },
                        sx: {
                            paddingRight: props.type == "number" ? "0.3rem !important" : "0.8rem"
                        },
                        startAdornment: (
                            props.iswarning && <InputAdornment sx={{ paddingRight: "0.5rem", margin: "0" }} position="start">
                                <SvgIcon name={SvgNames.ReportProblem} height={1.5} color={Theme.palette.error.main} />
                            </InputAdornment>)
                    }}
                    value={props.value??field.value}
                    onChange={(e) => props.onChange != undefined ? props.onChange(e) : field.onChange(e)}
                    onClick={props.onClick}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
};

function usersEqual(user1: LoggedInUser | Employee, user2: LoggedInUser | Employee) {
    return (
        user1.firstName === user2.firstName &&
        user1.lastName === user2.lastName)
        // (user1.userImage ===null||
        // user1.userImage?.substring(user1.userImage?.lastIndexOf('\\') + 1) === user2.userImage?.substring(user2.userImage?.lastIndexOf('\\') + 1)))
}

