import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer'
import { ApplicationState } from '../ReduxRelatedUtils/redux-types'
import { GlobalSnackState } from '../Utility/general-types'


export default function GlobalSnack() {
  const snackState = useSelector<ApplicationState>(state => state.snackState) as GlobalSnackState
  const dispatch = useDispatch()
  
  return (
    <Snackbar 
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackState.open}
      // sx={{height:"100%"}}
      autoHideDuration={snackState.severity == "error"? null : 6000}
      onClose={() => dispatch(setGlobalSnack({ open: false, message: "", severity:snackState.severity }))}
    >
       <Alert variant='filled' severity={snackState.severity??"success"}>{snackState.message}</Alert>
    </Snackbar>
  )
}