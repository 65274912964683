import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setIsHome } from '../ReduxRelatedUtils/isHomeReducer';
import { ApplicationState, LoggedInUser } from '../ReduxRelatedUtils/redux-types';
import { CustomError } from '../Utility/general-types';
import { StyledNavGridItem, StyleNavBoxItem } from '../Utility/nav-styled-components';
import { getMenus, MenuItem } from '../Utility/section-menus';
import { CenterChildren, StyledError } from '../Utility/shared-components';
const logo = require('../images/Centarix_Logo.png')

export default function Home(){
    const userInfo = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser;
    const menus = getMenus(userInfo.userRoles.map(ur=> ur.mainRole));
    const dispatch = useDispatch();
    const globalError = useSelector<ApplicationState>(state => state.error) as CustomError;


    useEffect(() => {
        dispatch(setIsHome(true));
    }, []);


    const menuButtonClick = (item: MenuItem) => {
        batch(() => {
            var url = queryString.stringifyUrl({ url: item.href, query: item.navigationInfo })
            dispatch(push(url))
            dispatch(setIsHome(false));
        })

    }
    return (
        <CenterChildren height="75vh">
        <Stack width="60%" margin="auto" textAlign="center" spacing={2}>
            <Box component='img' alt="logo" src={logo} sx={{
                width: '41%',
                margin: 'auto',
                textAlign: 'center'
            }} />
            <StyledError textAlign="center">{globalError.message}</StyledError>
                <Grid item xs={12} style={{ fontWeight: 400, textAlign: 'center', fontSize: '2em' }}>{"Welcome " + userInfo.firstName + " " + userInfo.lastName + "!"} </Grid>
                <Grid container xs={12} item justifyContent='center' >
                    {menus.map((item, i) => (
                        <StyledNavGridItem key={i} minWidth="18%" item  >
                            <StyleNavBoxItem>
                                <Link onClick={() => menuButtonClick(item)} minWidth="0" >
                                    <Box component='img'
                                        alt="logo"
                                        src={item.imageName}
                                        width='7em'
                                    />
                                    <Typography style={{ fontWeight: 400, fontSize: '1.25rem' }} >{item.description}</Typography>
                                </Link>
                            </StyleNavBoxItem>
                        </StyledNavGridItem>

                    ))}
                    <Grid item xs={12} paddingTop="2%" style={{ textAlign: 'center' }}><Typography>Version: 14.0.2</Typography> </Grid>

                </Grid>
                </Stack>
    </CenterChildren>

    );

};

