import { Box,  Stack } from "@mui/material";
import Skeleton from 'react-loading-skeleton';
import Theme from "../Utility/theme";

export default function LocationTabsSkeleton(){
    return(

         <Skeleton width={"50%"} height={"3rem"}/>


    )
}