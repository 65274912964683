import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import 'regenerator-runtime/runtime';
import { AddItemResult, ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { convertScriptsToScriptTag, convertStyleSheetsToCss } from '../../Utility/root-function';
import { GlobalForm } from '../../Utility/shared-components';
import OrderEmail, { OrderEmailWithLayout } from '../order-email';
import { ConfirmEmailViewModel } from './confirm-email-modal';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';

export type ConfirmQuoteFormData = {
    requestIDs: string[],
    orderPDF : string
}

export default function QuoteEmailModal() {
    const dispatch = useDispatch();
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;

    function onSubmit(viewModel: ConfirmEmailViewModel) {
        var orderPdf = ReactDOMServer.renderToString(<OrderEmailWithLayout {...viewModel} isRequestPriceQuote />)
        const fullHtml = `
            <!doctype html>
            <html>
                <head>
                <style type="text/css">${convertStyleSheetsToCss()}</style>
                ${convertScriptsToScriptTag()}
                </head>
                <body>
                ${orderPdf}
                </body>
            </html>
            `;
        let formData = {requestIDs: modalInfo.ids, orderPDF: fullHtml } as ConfirmQuoteFormData
        var url = '/Requests/ConfirmQuoteEmailModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(formData),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((res: { result: AddItemResult }) => {
                dispatch(setLoading(false))
                switch (res.result) {
                    case AddItemResult.QuoteRequested:
                        batch(() => {
                            dispatch(removeModal())
                            dispatch(setGlobalSnack({ open: true, message: "Quote Request Sent!" }))
                            dispatch(setReloadIndex(true))
                        })

                        break;
                }

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.errorMessage, severity: "error" }))
                })
            })
    }


    return (
        <ConfirmQuoteEmailForm onSubmit={onSubmit} />
    );
}
type ConfirmQuoteEmailFormProps = {
    onSubmit: Function
}

export function ConfirmQuoteEmailForm(props: ConfirmQuoteEmailFormProps) {
    const dispatch = useDispatch();

    const [viewModel, setViewModel] = useState({} as ConfirmEmailViewModel);
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());

    const methods = useForm()

    useEffect(() => {
        var url = "/Requests/ConfirmQuoteEmailModal?requestIds=" + modalInfo.ids;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: ConfirmEmailViewModel) => {
                setViewModel(result);
                dispatch(setModalLoading(false))

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);
    return (
        <GlobalModal key={ModalName.AskForQuote} size={ModalSizes.md} modalKey={ModalName.AskForQuote}>

            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Request Quote"} errorMessage={error.message} />
                    {error.showBody ? <>
                        <StyledDialogContent>
                            <GlobalForm methods={methods} formID={ModalName.AskForQuote} onSubmit={(data: any, e: any) => props.onSubmit(viewModel)}>
                                <OrderEmail {...viewModel} isRequestPriceQuote />
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AskForQuote} submitButtonText={"Send"}>
                        </ModalFooterWithSave>
                    </>
                        : null}

                </>}
        </GlobalModal>
    )
}