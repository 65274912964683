import { Box, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import GlobalPopover from '../Utility/global-popover';
import { getDisplayNameOfEnumValue } from '../Utility/root-function';

import {
    useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnDisplayOptionProperty, ColumnValueDisplayOption, SvgNames } from '../Constants/app-enums';
import { setDisplayFormatOptions, setDisplayValueOptions } from '../ReduxRelatedUtils/index-table-reducer';
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import { DisplayOption } from '../Utility/request-types';
import { SectionCheckbox, SmallTypography, SvgIcon } from '../Utility/shared-components';

type ColumnOptionsPopoverProps = {
    columnDisplayOptions: DisplayOption[],
    title: string
}

export default function ColumnOptionsPopover(props: ColumnOptionsPopoverProps) {

    const [checkboxDisplayOptions, setCheckboxDisplayOptions] = useState(props.columnDisplayOptions.filter(d => d.displayOptionProperty == ColumnDisplayOptionProperty.Checkbox))
    const radioDisplayOptions = props.columnDisplayOptions.filter(d => d.displayOptionProperty == ColumnDisplayOptionProperty.RadioButton) as DisplayOption[]
    const selectedDisplayValueOptions = useSelector<ApplicationState>(state => state.indexTableObject.selectedDisplayValueOptions) as ColumnValueDisplayOption[]
    const selectedDisplayFormatOptions = useSelector<ApplicationState>(state => state.indexTableObject.selectedDisplayFormatOptions) as ColumnValueDisplayOption[]

    const dispatch = useDispatch();

    function changeSelectedFormatOption(e: any) {
       // console.log(e.target.value)
        dispatch(setDisplayFormatOptions(e.target.defaultValue));
    }
    function changeSelectedDisplayOptions(e: any, displayOption: ColumnValueDisplayOption) {

        if (e.target.checked == true) {
      //      console.log("checked")
            var checkboxDisplayOptionsCopy = checkboxDisplayOptions.map(d => d.displayOptionType == displayOption ? { ...d, selected: true } : d)
        }
        else {
       //     console.log("unchecked")
            var checkboxDisplayOptionsCopy = checkboxDisplayOptions.map(d => d.displayOptionType == displayOption ? { ...d, selected: false } : d)
        }
        setCheckboxDisplayOptions(checkboxDisplayOptionsCopy)
        var selectedDisplayOptionsCopy = [...selectedDisplayValueOptions];
        checkboxDisplayOptions.map(d => selectedDisplayOptionsCopy = selectedDisplayOptionsCopy.filter(dv => dv != d.displayOptionType))
        console.log(selectedDisplayOptionsCopy)
        checkboxDisplayOptionsCopy.filter(d => d.selected).map(d => selectedDisplayOptionsCopy.push(d.displayOptionType))
        dispatch(setDisplayValueOptions(selectedDisplayOptionsCopy));


    }
    return (

        <GlobalPopover popoverClassName="column-options-popover">
            <Stack direction={"row"} spacing={0.5} alignItems="center" justifyContent={"center"}>
                <SmallTypography >{props.title}</SmallTypography>
                <SvgIcon name={SvgNames.CentarixDropDownExpand} height={0.7} />
            </Stack>
            <Box className='section-top-border' width="8rem" padding="0.2rem 0.7rem">
                <FormGroup>
                    {checkboxDisplayOptions.map((d, i) =>
                        <Box key={i + "checkbox"} borderTop={i != 0 ? "1px solid #ebebeb" : "none"}>
                            <FormControlLabel sx={{ margin: 0, paddingY: "0.3rem" }}
                                control={<SectionCheckbox checked={d.selected} onChange={(e) => changeSelectedDisplayOptions(e, d.displayOptionType)} />}
                                label={<SmallTypography>{getDisplayNameOfEnumValue(d.displayOptionType)}</SmallTypography>} />
                        </Box>
                    )
                    }
                    {radioDisplayOptions.length > 0 &&
                        <RadioGroup
                            row
                            defaultValue={radioDisplayOptions.filter(r => r.selected)[0].displayOptionType}
                            onChange={(e) => changeSelectedFormatOption(e)}>
                            {radioDisplayOptions.map(d =>
                                <FormControlLabel sx={{ margin: "0" }} key={d.displayOptionType} value={d.displayOptionType}
                                    control={<Radio />}
                                    label={<SmallTypography>{getDisplayNameOfEnumValue(d.displayOptionType)}</SmallTypography>} />
                            )
                            }
                        </RadioGroup>
                    }
                </FormGroup>
            </Box>
        </GlobalPopover>

    )
}

