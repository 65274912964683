import { yupResolver } from '@hookform/resolvers/yup';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, object, string } from 'yup';
import { ModalName } from '../Constants/app-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal } from '../ReduxRelatedUtils/redux-types';
import { setViewModel } from '../ReduxRelatedUtils/viewmodel-reducer';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput } from '../Utility/custom-input-components';
import { CustomError, LocationModalInfo } from '../Utility/general-types';
import { GlobalForm } from '../Utility/shared-components';
import { LocationInstance } from './locations-types';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { setLastSelectedLocation, setLastSelectedLocationReload } from '../ReduxRelatedUtils/selectedLocationReducer';


export default function EditStorageUnitNameModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const [error, setError] = useState<CustomError>(new CustomError());
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>


    const validationSchema = object<Partial<Record<keyof LocationInstance, AnySchema>>>({
            locationInstanceName: string().required("Location name is required"),
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            locationInstanceName: "",
        } as LocationInstance,
    }
    );

    const locationTypeName = methods.watch("locationType.locationTypeName")
    useEffect(() => {
        var url = "/Locations/EditStorageUnitNameModal?locationInstanceID="+modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((result: LocationInstance) => {
                methods.reset(result)
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);

    function onSubmit(data: any, e: any) {
        console.log("in onsubmit")
        var url = '/Locations/EditStorageUnitNameModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: {  }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))       
                    dispatch(setGlobalSnack({ open: true, message: "Location Name Updated", severity: "success" }))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Edit "+locationTypeName+ " Name"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.EditStorageUnitName} onSubmit={onSubmit} methods={methods}>
                                <Stack spacing={2} alignItems="center">
                                    <StyledControlledFormInput name="locationInstanceName" label={locationTypeName+" Name"}/>
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.EditStorageUnitName} />
                    </>
                    }
                </>
            }
        </>
    )
}