import { yupResolver } from '@hookform/resolvers/yup';
import { AlertColor, Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { push } from 'connected-react-router';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, boolean, date, number, object, string } from 'yup';
import { AddItemResult, FolderNamesEnum, ModalFlow, ModalName, OrderMethod, ParentCategoryEnum, SidebarEnum } from '../../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal, ModalInfo, NavigationInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Request } from '../../Utility/request-types';
import { itemPost } from '../../Utility/root-function';
import { GlobalForm, SmallTypography, SpacedColumnStack, StyledOutlineButton } from '../../Utility/shared-components';
import { BatchLotBlock, SupplyDaysBlock, WarrantyBlock } from '../ItemCard/item-components';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';

type AdditionalRequestInfoFormData = {
    request: Request
}
export default function AdditionalRequestInfoModal() {
    const dispatch = useDispatch();
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<AddItemFormData>
    const fromBackButton = (useSelector<ApplicationState>(state => state.modalWithLoading.modal.future) as Modal<ModalInfo>[]).length > 0
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<BasicModalInfo>
    const validationSchema = object<Partial<Record<keyof AdditionalRequestInfoFormData, AnySchema>>>({
        request: object<Partial<Record<keyof Request, AnySchema>>>({
            expectedSupplyDays: number().when("orderMethod",{
                is:(orderMethod:{descriptionEnum:OrderMethod})=> orderMethod?.descriptionEnum != OrderMethod.RequestItem,
                then: number().integer("Supply Days field must be more than 0").typeError("Supply Days field must be more than 0").min(0, "Supply Days field must be more than 0").required("Supply Days field is required"),
                otherwise: number().nullable()
            })
        })
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues:
            {
                request: {
                    expectedSupplyDate: moment().format("YYYY-MM-DD"),
                    expectedSupplyDays: tempData?.request?.expectedSupplyDays ?? null,
                    warranty: tempData?.request?.warranty ?? 0,
                    warrantyEndDate: tempData?.request?.warrantyEndDate ?? moment().format("YYYY-MM-DD"),
                    batch: tempData?.request?.batch ?? 0,
                    batchExpiration: tempData?.request?.batchExpiration ?? moment().format("YYYY-MM-DD"),
                    orderMethod :tempData?.request?.orderMethod ?? {}
                }
            } as AdditionalRequestInfoFormData
    }
    );

    const [error, setError] = useState<CustomError>(new CustomError());

    function onSubmit(data: AdditionalRequestInfoFormData, e: any) {
        let tempDataCopy = { ...tempData, request: { ...tempData.request } }
        tempDataCopy.request.expectedSupplyDays = data.request.expectedSupplyDays
        tempDataCopy.request.warranty = data.request.warranty
        tempDataCopy.request.batch = data.request.batch
        tempDataCopy.request.batchExpiration = data.request.batchExpiration

  
        dispatch(setTempData(tempDataCopy))
        if (fromBackButton) {
            dispatch(setModalLoading(true))
            dispatch({ type: "redoModals" })
            dispatch({ type: "redoTempData" })
        }
        else {
            switch (tempData.request.orderMethod.descriptionEnum as OrderMethod) {
                case OrderMethod.AddToCart:
                case OrderMethod.RequestItem:
                    dispatch(setLoading(true))
                    fetch('/Requests/UploadQuoteModal', {
                        method: "POST",
                        body: JSON.stringify(tempDataCopy),
                        headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
                    })
                        .then(response => {
                            if (response.ok) { return response.json() }
                            throw response.json();
                        })
                        .then((res: { result: AddItemResult }) => {
                            let message = "";
                            let severity: AlertColor = "success"
                            switch (res.result) {
                                case AddItemResult.OutOfBudget:
                                    message = "Item is out of budget and is awaiting approval";
                                    severity = "warning";
                                    break;
                                case AddItemResult.ItemRequested:
                                    message = "Item was Requested";
                                    break;
                                default:
                                    message = "Item was Added to Cart";
                            }
                            batch(() => {
                                batch(() => {
                                    dispatch(setLoading(false))
                                    dispatch(removeModal())
                                    dispatch(setTempData({}))
                                    dispatch(setGlobalSnack({ open: true, message: message, severity: severity }))
                                    dispatch(setReloadIndex(true))
                                })
                            })
                        })
                        .catch(err => {
                            Promise.resolve(err).then(text => {
                                dispatch(setLoading(false))
                                dispatch(setGlobalSnack({ open: true, message: text.errorMessage, severity: "error" }))
                            })
                        })
                    break;
                case OrderMethod.OrderNow:
                    dispatch(setLoading(true))
                    fetch("/Requests/CheckIfMoveToTerms", {
                        method: "POST",
                        body: JSON.stringify(tempDataCopy),
                        headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
                    }).then(response => {
                        if (response.ok) { return response.json() }
                        else { throw response.text() }
                    }).then((res: {result:AddItemResult}) => {
                        dispatch(setLoading(false))

                        if (res.result == AddItemResult.OutOfBudget) {
                            batch(() => {
                                dispatch(removeModal())
                                dispatch(setTempData({}))
                                dispatch(setGlobalSnack({ open: true, message: "Item out of Budget", severity: "warning" }))
                                dispatch(setReloadIndex(true))
                            })
                        }
                        else {
                            batch(() => {
                                dispatch(setModalLoading(true))
                                    dispatch(setTempData(tempDataCopy))
                                    dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.Terms }))
                            })
                        }

                    })
                        .catch(err => {
                            Promise.resolve(err).then(text => {
                                batch(() => {
                                    dispatch(setLoading(false))
                                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                                })
                            })
                        })

                    break;
                case OrderMethod.AlreadyPurchased:
                    batch(() => {
                        dispatch(setTempData(tempDataCopy));
                        dispatch(setModalLoading(true))
                        dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.Terms } as Modal<BasicModalInfo>))
                    })
                    break
            }

        }
    }
    useEffect(() => {
        dispatch(setModalLoading(false))
    }, [])


    function getRequestFieldsByCategory() {
        switch (tempData.request.product.productSubcategory.parentCategory.parentCategoryDescriptionEnum) {
            case ParentCategoryEnum.Biological:
            case ParentCategoryEnum.Clinical:
                return <BatchLotBlock cols={6} />
            case ParentCategoryEnum.Reusable:
            case ParentCategoryEnum.Safety:
            case ParentCategoryEnum.General:
                return <WarrantyBlock cols={6} />
            default:
                return null
        }
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Request Details"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AdditionalRequestInfo} onSubmit={onSubmit} methods={methods}>
                                <SpacedColumnStack>
                                    <Grid container spacing={2}>
                                        <SupplyDaysBlock cols={6} />
                                        {getRequestFieldsByCategory()}
                                    </Grid>
                                </SpacedColumnStack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AdditionalRequestInfo} submitButtonText={tempData.request.orderMethod.descriptionEnum==OrderMethod.OrderNow || tempData.request.orderMethod.descriptionEnum==OrderMethod.AlreadyPurchased  ? "Next" :"Request"} >
                            {<ModalBackButton modalFlowKey={ModalFlow.AddItem} />}
                        </ModalFooterWithSave></>}
                </>}
        </>)
}
