import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { MenuItemEnum, OrderMethod, RoleDefinition } from "../../Constants/app-enums";
import { ApplicationState, LoggedInUser } from "../../ReduxRelatedUtils/redux-types";
import { extractErrorValues, getDisplayNameOfEnumValue } from "../../Utility/root-function";
import { StyledError, StyledSectionOutlineButton } from "../../Utility/shared-components";


export default function OrderTab() {
    const form = useFormContext();
    const {userRoles} = useSelector<ApplicationState>(state=> state.userInfo) as LoggedInUser
    var disableOrder =  !userRoles.find(ur => ur.mainRole == MenuItemEnum.Requests)?.subRoles.includes(RoleDefinition.RequestsOrderItem)

    return (
        <>
        {form.formState.errors  &&
                    <StyledError>{extractErrorValues(form.formState.errors).join(", ")}</StyledError>
                    }
        <Stack direction="column" spacing={2}>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography>If you want to process a single order immediately</Typography>
                <StyledSectionOutlineButton disabled={disableOrder}  type="submit" name={OrderMethod.OrderNow} sx={{ width: "19rem" }}>{getDisplayNameOfEnumValue(OrderMethod.OrderNow)}</StyledSectionOutlineButton>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography>If you want to order multiple items</Typography>
                <StyledSectionOutlineButton  type="submit" name={OrderMethod.AddToCart}  sx={{ width: "19rem" }}>{getDisplayNameOfEnumValue(OrderMethod.AddToCart)}</StyledSectionOutlineButton>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography>Update the purchase of an item</Typography>
                <StyledSectionOutlineButton disabled={disableOrder}   type="submit" name={OrderMethod.AlreadyPurchased}  sx={{ width: "19rem" }}>{getDisplayNameOfEnumValue(OrderMethod.AlreadyPurchased)}</StyledSectionOutlineButton>
            </Stack>
            <Stack direction="row" justifyContent={"space-between"}>
                <Typography>Request an item to be ordered in Lab Management</Typography>
                <StyledSectionOutlineButton  type="submit" name={OrderMethod.RequestItem}  sx={{ width: "19rem" }}>{getDisplayNameOfEnumValue(OrderMethod.RequestItem)}</StyledSectionOutlineButton>
            </Stack>
        </Stack>
        </>
    )
}

