import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { SmallTypography } from "../../Utility/shared-components";
import ColumnOptionsPopover from "../column-options-popover";
import { Request, RequestItemViewModel } from "../../Utility/request-types";
import { ApplicationState } from "../../ReduxRelatedUtils/redux-types";
import { useSelector } from "react-redux";
import moment from "moment";
import IndexTableRow from "../../Shared/IndexTable/index-table-row";
import { RowViewModel } from "../../Utility/general-types";
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore";
import { useFormContext } from "react-hook-form";

export default function HistoryTab(props:{editID?:string}) {

    const requests = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.editID??"0")?.viewModel as RequestItemViewModel).requestsByProduct) as RowViewModel[];

    return (<TableContainer key="scrollable-table" component={Box}>
        <Table stickyHeader sx={{ minWidth: 650, marginBottom: "1rem" }} aria-label="simple table">
            <TableHead sx={{ display: "table", height: "3.75rem", tableLayout: "fixed", width: "100%" }}>
                <TableRow sx={{borderBottom:"none"}}>
                {requests?.[0]?.columns?.map((col, i) =>
                    <TableCell key={col.title+i+col.width} width={`${col.width}%`}>                      
                        <SmallTypography>{col.title}</SmallTypography>                       
                    </TableCell>
                )}
                </TableRow>
            </TableHead>
            <TableBody sx={{ overflowY: "auto", display: "block", height:'100%'}} >

                {requests?.map((row, i) =>                                 
                    <IndexTableRow key={row.id} row={row} displaytable="true" />            
                )}

            </TableBody>
        </Table>
    </TableContainer>

    );
}