import { Grid, FormControlLabel, Typography, MenuItem, TextFieldProps } from "@mui/material";
import { useState, useRef, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { SelectLocationViewModel, ChildStorageSelectList, SelectLocationFormData } from "../Requests/Modals/receive-location-modal";
import { SelectLocationsVisual } from "../Requests/select-locations-visual";
import { ControlledCheckbox, StyledControlledAutoCompleteInput, StyledFormInput, StyledSelectInput } from "../Utility/custom-input-components";
import { AutocompleteOption, BasicModalInfo, SelectListItem } from "../Utility/general-types";
import { BoldTypography, IsEdittableContext, SpacedColumnStack, SpacedRowStack, StyledError, StyledFilledButton, TitleTypography } from "../Utility/shared-components";
import { merge } from 'lodash';
import { ApplicationState, Modal } from "../ReduxRelatedUtils/redux-types";
import { setChildrenUnits, setFloors, setLocationViewModel, setEditDrawerViewModel, setRooms, setShowVisual, setStorageType, setStorageUnits } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { RequestItemViewModel } from "../Utility/request-types";
import { Box } from "@mui/system";
import { ModalFlow, ModalName } from "../Constants/app-enums";
import { Style } from "@mui/icons-material";
import { selectEditDrawerViewModelByID } from "../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
export default function LocationDDLs(props: { showTemporary?: boolean, requestID: string}) {
    const dispatch = useDispatch();
    const methods = useFormContext();
    const viewModel = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.requestID)?.viewModel as RequestItemViewModel)?.selectLocationViewModel) as SelectLocationViewModel;
   
    const [localForm, setLocalForm] = useState<SelectLocationFormData>({} as SelectLocationFormData);
    const [disableDDLs, setDisableDDLs] = useState(false)

    const selectedParentStorageType = methods.watch("selectedParentStorageType");
    const selectedTemperature = methods.watch("selectedTemperature");
    const selectedStorageUnit = methods.watch("selectedStorageUnit");
    const selectedRoom = methods.watch("selectedRoom");
    const selectedFloor = methods.watch("selectedFloor");
    const selectedBuilding = methods.watch("selectedBuilding");
    const selectedChildren = methods.watch("selectedChildren");
    const getFloorDidMount = useRef(false);
    const getRoomsDidMount = useRef( false);
    const getStorageTypesDidMount = useRef(false);
    const getStorageUnitsDidMount = useRef(false);
    const getChildStorageUnitsDidMount = useRef(false);
    const requestID = methods.watch("request.requestID")
    const isTemporary = methods.watch("isTemporary")
    const [locationName, setLocationName] = useState("");

    useEffect(()=>{
        setDisableDDLs(viewModel?.isArchived)
    }, [viewModel?.isArchived])

    useEffect(() => {
        console.log("is quartzy" + viewModel?.isQuartzy)
        methods.setValue("isQuartzy", viewModel?.isQuartzy)
    }, [viewModel?.isQuartzy])

    useEffect(()=>{
        let selectedChildrenToPassBack = selectedChildren == undefined ? '': selectedChildren;
        var url = "/Locations/GetLocationName?buildingID=" + selectedBuilding+"&selectedFloor="+selectedFloor+"&selectedRoom="
        +selectedRoom+"&requestID="+requestID+"&selectedStorageUnit="+selectedStorageUnit+"&selectedChildren="+selectedChildrenToPassBack
        "&isTemporary="+isTemporary;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.text() }
                throw response;
            })
            .then((result:string) => {  
                console.log(result)  
                setLocationName(result)                            
          
            })
            .catch(err => {
                console.log(err)
            })

    
    }, [selectedParentStorageType, selectedStorageUnit, selectedRoom, selectedFloor, selectedChildren, selectedBuilding, isTemporary])


    useEffect(() => {
        console.log("in building use"+selectedBuilding)
        if(getFloorDidMount.current){
            methods.setValue("selectedLocations", []);
            methods.setValue("selectedRoom",0);     
            methods.setValue("selectedStorageUnit", 0);
            methods.setValue("selectedChildren", []);                
            methods.setValue("selectedFloor",0);  
            if (selectedBuilding != 0) {
       
                var url = "/Locations/GetFloors?buildingID=" + selectedBuilding;
                fetch(url, {
                    method: "GET"
                })
                    .then((response) => {
                        if (response.ok) { return response.json() }
                        throw response;
                    })
                    .then((result) => {    
                        batch(()=>{
                            dispatch(setFloors({ floors:result , visualState:{ showVisual: false, parentID: 0 }, id:props.requestID}));
                        })                                   
                  
                    })
                    .catch(err => {
    
                    })
    
            }
        }
        else{
            getFloorDidMount.current=true;
        }

    }, [selectedBuilding]);



    
    useEffect(() => {
        console.log("in floor use"+selectedFloor)

        if(getRoomsDidMount.current){       
            methods.setValue("selectedLocations", []);          
            methods.setValue("selectedStorageUnit", 0);            
            methods.setValue("selectedRoom",0);    
            methods.setValue("selectedChildren", []);
         
            if (selectedFloor != 0) {
        
                var url = "/Locations/GetRooms?floorID=" + selectedFloor;
                fetch(url, {
                    method: "GET"
                })
                    .then((response) => {
                        if (response.ok) { return response.json() }
                        throw response;
                    })
                    .then((result) => {    
                        batch(()=>{
                            dispatch(setRooms({ rooms: result, visualState:{ showVisual: false, parentID: 0 }, id:props.requestID}));
                        })                                   
  
                    })
                    .catch(err => {
    
                    })
    
            }
        }
        else{
            getRoomsDidMount.current=true;
        }

    }, [selectedFloor]);


    useEffect(() => {
        console.log("in get storage types use"+selectedTemperature)
     
        if(getStorageTypesDidMount.current){
            methods.setValue("selectedParentStorageType", 0);
            methods.setValue("selectedStorageUnit", 0);
            methods.setValue("selectedChildren", []);
            methods.setValue("selectedLocations", []);
        if (selectedTemperature != 0 )   {
            
            var url = "/Locations/GetStorageTypes?temperatureID=" + selectedTemperature + "";
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response;
                })
                .then((result: AutocompleteOption[]) => {
                    dispatch(setStorageType({ storageTypes: result, visualState:{ showVisual: false, parentID: 0 }, id:props.requestID}));    
           
                })
                .catch(err => {

                })
        }
    }else{
        getStorageTypesDidMount.current=true;
    }
    }, [selectedTemperature]);

    useEffect(() => {
        console.log("in get storage units use") 
        if(getStorageUnitsDidMount.current){                     
      
                         
            methods.setValue("selectedChildren", []);
            methods.setValue("selectedLocations", []);
        if (selectedParentStorageType != 0) {
            var url = "/Locations/GetStorageUnits?storageTypeID=" + selectedParentStorageType + "&roomID=" + selectedRoom;
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response;
                })
                .then((result: AutocompleteOption[]) => {
                    dispatch(setStorageUnits({ storageUnits: result,  visualState:{ showVisual: false, parentID: 0 }, id:props.requestID }));
        
                })
                .catch(err => {

                })
        }
    }else{
            getStorageUnitsDidMount.current= true;
        
    }
    
    }, [selectedParentStorageType, selectedRoom]);


    useEffect(() => {
        console.log("in children storage use effect"+ getChildStorageUnitsDidMount.current)
        if(getChildStorageUnitsDidMount.current){
                   
            methods.setValue("selectedLocations", []);
            methods.setValue("selectedChildren", []);   
        if (selectedStorageUnit != 0) {

            var url = "/Locations/GetChildrenStorageUnits?storageUnitID=" + selectedStorageUnit + "";
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response;
                })
                .then((result: ChildStorageSelectList[]) => {
                    console.log(result)
                    if (result.length == 0) {
                        console.log("show visual")
                        dispatch(setShowVisual({visualState:{ showVisual: true, parentID: selectedStorageUnit }, id:props.requestID}))
                    }
                    else {
                        dispatch(setChildrenUnits({  childrenStorageUnits: result, visualState:{ showVisual: false, parentID: 0 } , id:props.requestID}));
                    }
                })
                .catch(err => {
                    console.log(err)
                    dispatch(setGlobalSnack({ message: 'Error loading children storage units', severity: 'error', open: true }))
                })
        }
    }else{
        getChildStorageUnitsDidMount.current=true;
    }
    }, [selectedStorageUnit]);

    function onChangeChildrenLocations(e: any, i: number) {

        var url = "/Locations/GetChildrenStorageUnitsListItems?parentLocationID=" + e.target.value + "";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response;
            })
            .then((result: SelectListItem[]) => {

                var childrenUnits = JSON.parse(JSON.stringify(viewModel?.childrenStorageUnits)) as ChildStorageSelectList[];
                for (let y = (i + 1); y < childrenUnits.length; y++) {
                    childrenUnits[y].list = []
                }
                var currentSelectedElement =childrenUnits[i].list.find(l=>l.value==e.target.value)??{}as SelectListItem;
                childrenUnits[i].list.map(c=>c.selected=false);
                currentSelectedElement.selected= true;
                console.log(childrenUnits[i].list)
                let childrenCopy = [...selectedChildren];
                childrenCopy.splice(i);
                console.log(childrenCopy)
                methods.setValue("selectedChildren", [...childrenCopy, e.target.value]);
                methods.setValue("selectedLocations", []);

                //need to clear all the ones that come after
               
                console.log(result.length)
                if (result.length == 0) {
                    dispatch(setLocationViewModel({viewModel:{...viewModel,  visualState:{ showVisual: true, parentID: e.target.value}, childrenStorageUnits:childrenUnits } , id:props.requestID}))
                }
                else {          
                    childrenUnits[i + 1].list = result
                    dispatch(setLocationViewModel({viewModel:{...viewModel, visualState:{ showVisual: false, parentID: 0 }, childrenStorageUnits:childrenUnits}, id:props.requestID }))
                }
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                console.log(err)
            })

    }

    
    
    return (<SpacedColumnStack>
            <SpacedColumnStack>
            <SpacedRowStack sx={{justifyContent:"center"}} key="0">
                <BoldTypography variant="h6" >{locationName}</BoldTypography>
                </SpacedRowStack>
                <SpacedRowStack key="1">
                    {<FormControlLabel disabled={disableDDLs} key="isTemporaryLocation"
                        control={<ControlledCheckbox name="isTemporary" />}
                        label={<Typography>Temporary</Typography>} />}
                <StyledControlledAutoCompleteInput disableClearable disabled={disableDDLs} key={"building"} options={viewModel?.buildings ?? []} placeholder="Select a building"
                    name="selectedBuilding" label="Building" />
                    <StyledControlledAutoCompleteInput disableClearable disabled={disableDDLs} key={"floor"} options={viewModel?.floors??[]} placeholder="Select a floor" name="selectedFloor" label="Floor" />
                <StyledControlledAutoCompleteInput disableClearable disabled={disableDDLs} key={"storageTemp"} options={viewModel?.storageTemperatures ?? []} placeholder="Select a temperature"
                    name="selectedTemperature" label="Storage Temperature" />

                </SpacedRowStack>
                <SpacedRowStack key="2">

                {!viewModel?.isArchived && <StyledControlledAutoCompleteInput disableClearable disabled={disableDDLs} key={"roomsbuiliding"} options={viewModel?.rooms ?? []} placeholder="Select a room"
                    name="selectedRoom" label="Room" />}
                    {viewModel?.isArchived &&<StyledFormInput disabled key={"archivedRoom"} value={viewModel.archivedRoomName}/>}
                <StyledControlledAutoCompleteInput disableClearable disabled={disableDDLs} key={"storageType"} options={viewModel?.storageTypes ?? []} placeholder="Select a storage type"
                    name="selectedParentStorageType" label="Storage Type" />
                <StyledControlledAutoCompleteInput disableClearable disabled={disableDDLs} key={"storageunit"} options={viewModel?.storageUnits ?? []} placeholder="Select a storage unit"
                    name="selectedStorageUnit" label="Storage Unit" />
                </SpacedRowStack>
                <SpacedRowStack key="3">
                    {viewModel?.childrenStorageUnits?.map((v, i) =>

                        <StyledSelectInput disabled={disableDDLs} key={selectedStorageUnit + v.name} placeholder={"Select a " + v.name} onChange={(e) => onChangeChildrenLocations(e, i)}
                            label={v.name} value={v.list.filter(l => l.selected)?.[0]?.value ?? 0}>
                            {v.list?.map((b, i) =>
                                <MenuItem key={b.value} value={b.value} >
                                    {b.text}
                                </MenuItem>)}
                        </StyledSelectInput>

                    )}
                </SpacedRowStack>
            </SpacedColumnStack>
            {viewModel?.error?.bool&& <StyledError>{viewModel.error.string}</StyledError> }
            {viewModel?.visualState?.showVisual && <SelectLocationsVisual  parentLocationID={viewModel?.visualState?.parentID} isArchived={viewModel?.isArchived}/>}
            {methods.formState.errors["selectedLocations"] && <StyledError>{methods.formState.errors["selectedLocations"].message}</StyledError>}

    </SpacedColumnStack>
    )

    
}
