
//Users Pages
export const USERS_TABLE = '/UsersTable'
export const EMPLOYEE_DETAILS = '/EmployeeDetails'
export const EMPLOYEE_HOURS = '/EmployeeHours'
export const AWAITNG_APPROVAL = '/AwaitingApproval'
export const ADD_USER = '/AddUser'
export const CONFIRM_USER_EMAIL = '/Users/ConfirmUserEmail'


//Requests Pages
export const ADD_ITEM = '/AddItem'
export const REQUESTS_LIST = '/RequestsList'
export const REQUESTS_INVENTORY_LIST = '/RequestsInventoryList'
export const REQUESTS_CART = '/RequestsCart'
export const REQUESTS_CART_PENDING_ORDERS = '/RequestCartPendingOrders'
export const REQUESTS_FAVORITES = '/RequestsFavorites'
export const SHARED_REQUESTS = '/SharedRequests'
export const SEARCH_REQUESTS = '/SearchRequests'
export const SEARCH_REQUESTS_RESULTS ="/SearchRequestsResults"
export const REQUESTS_NOTIFICATIONS = '/RequestsNotifications'
export const REQUESTS_DIDNT_ARRIVE = '/RequestsDidntArrive'
//Lab Management Pages
export const SUPPLIERS_LIST = '/SuppliersList'
export const LAB_MANAGE_QUOTES = '/LabManageQuotes'
export const LAB_MANAGE_ORDERS = '/LabManageOrders'
export const LAB_MANAGE_PENDING_ORDERS = '/LabManagePendingOrders'
export const LOCATIONS_STORAGE_TYPE = '/LocationsStorageType'
export const LOCATIONS_MAP = '/LocationsMap'
export const LOCATIONS_LABS = '/LocationsLabs'

export const ADD_SUPPLIER = '/AddSupplier'

//Accounting payments
export const MONTHLY_PAYMENT = '/MonthlyPayment'
export const INSTALLMENTS = '/Installments'
export const PAY_LATER = '/PayLater'
export const PAY_NOW = '/PayNow'
export const ACCOUNTING_GENERAL = '/AccountingGeneral'
//accounting notifications
export const NO_INVOICE = '/NoInvoice'
export const DIDNT_ARRIVE = '/DidntArrive'
export const PARTIAL_DELIVERY = '/PartialDelivery'
export const FOR_CLARIFICATION = '/ForClarification'
export const MISSING_PAYMENT_DETAILS = '/MissingPaymentDetails'
//accounting settings
export const BANK_ACCOUNTS = '/BankAccounts'
export const CREDIT_CARDS = '/CreditCards'

//timekeeper
export const REPORT_HOURS = '/ReportHours'
export const REPORT_DAYS_OFF = '/ReportDaysOff'
export const SUMMARY_HOURS = '/SummaryHours'
export const SUMMARY_DAYS_OFF = '/SummaryDaysOff'


//Inner
export const INDEX_TABLE = '/IndexTable'
export const INDEX_TABLE_BY_GROUP = '/IndexTableByGroup'
export const ORDER_EMAIL = '/OrderEmail' 
export const CHANGE_PAYMENT_STATUS = '/ChangePaymentStatus' 
export const PAYMENTS_INFO = '/PaymentsInfo' 
export const SUSPEND_USER = '/SuspendUser'


//Misc
export const SETTINGS_INVENTORY = 'SettingsInventory'
export const ORDER_TAB = '/OrderTab' 
export const INSTALLMENTS_TAB = '/InstallmentsTab' 
export const REORDER_BUTTONS = '/ReorderButtons' 

//Drawers
export const EDITUSER = '/EditUser'
export const EDITREQUEST = '/EditRequest'
export const EDITSUPPLIER= '/EditSupplier'
export const EDITPRODUCT= '/EditProduct'



