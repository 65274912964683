import { Backdrop } from '@mui/material'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../ReduxRelatedUtils/redux-types'

const loadingImg = require('../images/Loading/elixir_loading.gif')

export default function LoadingSymbol() {
    const loading = useSelector<ApplicationState>(state=>state.loading)as boolean
    console.log(loading+" loading")
    return (
        <Backdrop
        sx={{/*  position:"absolute",  */zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loading}
        >
            <Box alt={"loading"} height="8rem" sx={{ top:400}} component='img' src={loadingImg}/>
        </Backdrop>
    )
}