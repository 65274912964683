import { SelectLocationFormData } from "../../Requests/Modals/receive-location-modal"
import { Vendor, Country, Request, Product } from "../../Utility/request-types"
import { VendorContact, CategoryType } from "../../Utility/supplier-types"
import { AutocompleteOption, Comment } from "../../Utility/general-types" 
import { CardTabsType, TabsName } from "../../Constants/client-side-enums"
import { Employee, JobSubcategoryType, Role} from "../../Utility/user-types"

export type AddCardFormProps = {
    onSubmit: (data: any, optionalParam1?: any) => void,
    addType: AddCardType,
}


export enum AddCardType {
    AddItem = "AddItem",
    AddSupplier = "AddSupplier",
    AddUser = "AddUser"
}

export type AddItemFormData ={
    request: Request,
    comments: Comment[]
    isSample: boolean
} & SelectLocationFormData &AddCardFormData

export type EditCardFormData<T> =T  &{
    submitionSuccessful:boolean
}
export type AddCardFormData={
    isSubmitting: boolean,
    
    currentTab: TabsName,
}
export type AddSupplierFormData = {
    uniqueCountryCompanyID: boolean,
    vendor: Vendor,
    currentTab: TabsName,
    comments: Comment[],
    vendorContacts: VendorContact[],
    vendorCategoryTypes: number[],
}&AddCardFormData

export type AddSupplierViewModel = {
    vendor: Vendor,
    currentTab: number,
    comments: Comment[],
    vendorContacts: VendorContact[],
    categoryTypes: CategoryType[],
    vendorCategoryTypes: number[]
    countries: Country[]
}

export type AddUserViewModel = {
    employee: Employee,
    password: string,
    secureAppPass:string,
    centarixID: string,
    userImage: string,
    jobCategoryTypes: AutocompleteOption[],
    jobSubcategoryTypes: JobSubcategoryType[],
    employeeStatuses: AutocompleteOption[],
    maritalStatuses: AutocompleteOption[],
    degrees: AutocompleteOption[],
    labs: AutocompleteOption[],
    citizenships: AutocompleteOption[],
    confirmedEmail: boolean,
    orderRoles: Role[],
    protocolRoles: Role[],
    operationRoles: Role[],
    biomarkerRoles: Role[],
    labManagementRoles: Role[],
    accountingRoles: Role[],
    timekeeperRoles: Role[],
    expensesRoles: Role[],
    incomeRoles: Role[],
    userRoles: Role[],
    userLabs: AutocompleteOption[],
    roles: string[],
    teams: AutocompleteOption[],
    tracks: AutocompleteOption[]
}

export type AddUserFormData = {
    currentTab: TabsName,
    employee: Employee,
    password: string,
    guid: string
    roles: string[],
    labs: AutocompleteOption[],
} & AddCardFormData


export type EditProductFormData = {
    product: Product;
    comments: Comment[];
  }&AddCardFormData