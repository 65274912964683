import { createTheme } from '@mui/material/styles';

const globalTheme = createTheme({
    spacing: (factor: number) => `${factor}rem`,

    palette: {
        mode: 'light',
        primary: {
            main: '#000000DE'
        },
        error: {
            main: '#E95252',
        }
    },
    typography: {
        fontSize: 16,
        body1: {
            fontSize: "1rem"
        },
        body2: {
            fontSize: "0.9rem"
        },
        fontFamily: ['Roboto', 'Segoe UI'].join(",")
    }
})
const Theme = createTheme({

    components: {
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    height: 80,
                    minHeight: 80,
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: "50%",
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
            styleOverrides: {
                root: {
                    cursor: 'pointer'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: globalTheme.palette.text.primary,
                    fontWeight: globalTheme.typography.fontWeightRegular,
                },
                body1: {
                    fontSize: globalTheme.typography.fontSize,
                }
            }

        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1.25rem",
                    textTransform: 'none',
                    fontWeight: globalTheme.typography.fontWeightRegular,
                    minWidth: '0',
                    padding: '0',
                    ":hover": {
                        backgroundColor: "inherit"
                    },
                    "&.Mui-disabled": {
                        //color: "white",
                        opacity: ".5"
                    },
                    cursor: 'pointer'
                }
            },
            defaultProps: {
                disableRipple: true

            }
        },
        MuiTextField: {
            defaultProps: {
                InputLabelProps: { shrink: false },
            },
            styleOverrides: {
                root: {

                }
            },
        },
        MuiTabs: {
            defaultProps: {
                TabIndicatorProps: {
                    className: " section-bg-color",
                    sx: {
                        height: "0.188rem"
                    }
                },

            },

        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    padding: "0",
                    color: globalTheme.palette.text.primary
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                input: {
                    "&::placeholder": {
                        color: globalTheme.palette.text.primary,
                        opacity: "1"
                    },

                    padding: "0"
                },
                root: {
                    fontSize: globalTheme.typography.body1.fontSize,
                    lineHeight: "2rem",
                    border: "solid .1rem " + globalTheme.palette.grey[300] + " !important",
                    borderRadius: "0.3rem",
                    "&:hover:not(.Mui-disabled)": {
                        backgroundColor: "#eff4f8"
                    },
                    padding: "0.3rem .8rem"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: globalTheme.palette.text.primary,
                    "&.Mui-error": {
                        color: globalTheme.palette.text.primary
                    },
                }
            }
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.MuiGrid-item': {
                        //paddingLeft:"0",
                        //alignSelf:"center"
                    },
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    padding: "1rem"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    textAlign: "center",
                    borderBottom: "none",
                    alignContent: "center",
                    paddingLeft: '0',
                    paddingRight: '0'
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: globalTheme.palette.primary.main
                    }
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom:"2px solid " + globalTheme.palette.grey[300],
                    borderCollapse:"collapse",
                    //display: "table",
                    '& td:first-of-type': {
                        //borderLeft: "2px solid " + globalTheme.palette.grey[300],
                    },
                    '& td:last-child': {
                        //borderRight: "2px solid " + globalTheme.palette.grey[300],
                        paddingRight: '1rem'
                    },
                    "&:hover": {
                        backgroundColor: 'rgba(0,0,0,0.075)',
                        transition:'.5s',
                    }
                }

            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse:"collapse",
                    "&.MuiTable-root": {
                        marginBottom: 0
                    }
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    borderCollapse:"collapse",
                    border: "2px solid #ebebeb"
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    "&:focus": {
                        backgroundColor: "transparent",
                    },
                    lineHeight: "1.5rem"
                }
            }
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    color: globalTheme.palette.grey[400],
                    fontSize: "1rem"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: "1.75rem",
                    fontWeight: "500"
                }
            }
        },
        MuiAlert:{
            variants:[
                {      props:{
                    severity:"success"
                },
                style:{
                    backgroundColor:"#00CA72"
                }
            }
            ]
        }

    }
},
    globalTheme
);
export default Theme