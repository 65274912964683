import { useSelector } from "react-redux";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { ApplicationState, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { BoldTypography, CenterChildren, SpacedColumnStack } from "../Utility/shared-components";
import { MenuItemEnum } from "../Constants/app-enums";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function EmptyPage() {
    const { sectionType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo

    // function getText() {
    //     switch (sectionType) {
    //         case MenuItemEnum.Requests:
    //             return "orders"
    //         case MenuItemEnum.Accounting:
    //             return "payments"
    //         case MenuItemEnum.Users:
    //             return "users"
    //         default:
    //             return "items"
    //     }
    // }
    return (
        <CenterChildren height='60vh'>
            <SpacedColumnStack alignItems={"center"}>
            <Box component="img" height="17rem" src="/images/css/emptyState.png" alt="Empty Page" />
            <Typography variant="h5" color={(theme) => theme.palette.grey[500]} >{"No current items"}</Typography>
            </SpacedColumnStack>
        </CenterChildren>)
}