import { AlertColor } from "@mui/material";
import { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import { CommentTypeEnum, FolderNamesEnum, PageHeaderEnum, SvgNames } from "../Constants/app-enums";
import { InfoPopup, LastSelectedLocation, ModalInfo, NavigationInfo, TempData } from "../ReduxRelatedUtils/redux-types";
import { ConfigureInstallmentsModalFormData, ConfigureInstallmentsModalViewModel } from "../Requests/Modals/configure-installments-modal";
import { AddItemFormData, AddSupplierFormData, AddSupplierViewModel, AddUserFormData, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { ColumnViewModel, Request, RequestItemViewModel, RequestList } from "./request-types";
import { Employee } from "./user-types";

export class CustomError {
    message = "";
    showBody = true;
}

export type StringWithBool = {
    string: string,
    bool: boolean
}


export type BasicModalInfo = ModalInfo &{
    ids:string[]
}

export type ConfirmExitModalInfo = ModalInfo &{
    pathname:string,
    navigationInfo:NavigationInfo
}
export type DocInfoModalInfo = ModalInfo &{
    infoID:number,
    folderName: FolderNamesEnum,
    editDrawerID:string
}
export type ConfigureInstallmentsModalInfo = InfoPopup &{
    comingFromTerms: boolean,
    viewModel:ConfigureInstallmentsModalViewModel,
    saveConfigureInstallments: (formData: ConfigureInstallmentsModalFormData) => void
}

export type LocationModalInfo={
    locationID: number
} &LastSelectedLocation &ModalInfo

export type TimekeeperActionModalInfo = BasicModalInfo & {
    typeID: number
    date: Date
}

export type AddStorageUnitModalInfo = ModalInfo &{
    locationTypeID: number
}&LocationModalInfo
 export type DocumentCard = DocumentsModalParams;

export type DocumentsModalInfo ={}  & DocumentsModalParams;

export type DeleteDocumentModalInfo = {setFileCount: Dispatch<SetStateAction<number>>,
    fileStrings :string[],
    deleteDocModalIsOpen:boolean} & DeleteDocumentModalParams;

export type ViewModelBase = {
    errorMessage?: string;
}

export type SelectListItem = {
    text: string,
    value: string|number,
    disabled: boolean,
    selected:boolean
}

//modal view models

export type MoveToListViewModel = {
    previousListID: number,
    newListID: number,
    productName: string,
    request: Request,
    requestLists: RequestList[],
}

export type NewListModalInfo = ModalInfo & NewListModalParams

export type NewListFormData = NewListModalParams & {
    listTitle:string
}

export type EditEmailModalInfo = ModalInfo & {
    orderNumber: number
}

export type ActionBarLink = {
    icon : SvgNames,
    title : string,
    onClick:any
}

export type StringWithInt = {
    string: string,
    int: number
}

export type IndexViewModel = ViewModelBase & {
    headerText: string;
    subheaderText: string;
    pageHeaders: PageHeaderEnum[];
    tableHeight?:string
}

export type RowViewModel = {
    columns: ColumnViewModel[];
    id: string;
}

//private types - these should not be exported. they have to be used within a different type
type DocumentsModalParams = BasicDocumentParams &{
    allowMultipleFiles:boolean;
}

type DeleteDocumentModalParams = BasicDocumentParams &{
    fileString: string
}

type BasicDocumentParams ={
    folderID: string,
    folderName: FolderNamesEnum,
}

type NewListModalParams = {
    requestToAddID?: number;
    requestPreviousListID: number;
}

export type Comment ={
    commentID :number,
    commentText :string,
    commentType :{descriptionEnum: CommentTypeEnum},
    commentTimeStamp: Date,
    isCurrentUsersComment: boolean,
    applicationUser : Employee
}

export type GlobalSnackState= {
    open: boolean,
    message: string,
    severity?:AlertColor
}

export type AutocompleteOption = {
    value: string | number,
    text: string,
    disabled?: boolean,
    hidden?: boolean
}

export type EditDrawerTempData = {
viewModel: RequestItemViewModel | AddSupplierViewModel | AddUserViewModel,
formData : AddItemFormData | AddSupplierFormData | AddUserFormData
}

export type Notification = {
    notificationDate: Date,
    isRead: boolean,
    applicationUserID: string,
    notificationID: number,
    description: string,
    notificationStatusID: number,
}