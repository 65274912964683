import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import GlobalPopover from '../Utility/global-popover';
import { StyledBadge, StyledDrawer, StyledNavGridItem, StyleNavBoxItem } from '../Utility/nav-styled-components';
import { getMenuByEnum, getMenus } from '../Utility/section-menus';

import { push } from 'connected-react-router';
import { LocationState } from 'history';
import queryString from 'query-string';
import { MenuItemEnum, ModalFlow, ModalName, PageTypeEnum, SidebarEnum, SvgNames } from '../Constants/app-enums';
import { clearIndexTableObject } from '../ReduxRelatedUtils/index-table-reducer';
import { ApplicationState, LoggedInUser, Modal, NavigationInfo } from '../ReduxRelatedUtils/redux-types';
import { setIsAuthenticated } from '../ReduxRelatedUtils/userInfoReducer';
import { setViewModel } from '../ReduxRelatedUtils/viewmodel-reducer';
import { MenuItem } from '../Utility/section-menus';
import { NameLogo, SvgIcon } from '../Utility/shared-components';
import HomeNotifications from './home-notifications';
import { setPageSkeleton, setShowConfirmExit } from '../ReduxRelatedUtils/utilsReducer';
import { ConfirmExitModalInfo } from '../Utility/general-types';
import { ConfirmExitModal } from './confirm-exit-modal';
import { setModal } from '../ReduxRelatedUtils/modals-reducer';
const logo = require('../images/Centarix_Logo_only.png')

export default function NavMenu(globalNavigationInfo: NavigationInfo) {
    // console.log("in nav menu")

    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const dispatch = useDispatch();

    var currentNav = location.query as NavigationInfo

    const [menus, setMenus] = useState<MenuItem[]>();
    const [navigationInfo, setNavigationInfo] = useState<NavigationInfo>(globalNavigationInfo);
    const [selectedMenu, setSelectedMenu] = useState<MenuItem>(getMenuByEnum(currentNav.sectionType));
    const localNewPage = selectedMenu?.childMenuItems?.filter(p => p.pageTypeEnum == currentNav.pageType)?.[0];
    const localNewSideBar = localNewPage?.childMenuItems?.filter(p => p.sidebarTypeEnum == currentNav.sidebarType)?.[0];
    const [selectedPage, setSelectedPage] = useState<MenuItem | undefined>(localNewPage);
    const [selectedSidebar, setSelectedSidebar] = useState<MenuItem | undefined>(localNewSideBar);
    const [notificationsCount, setNotificationsCount] = useState<number>(0)

    const userInfo = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser;
    const isHome = (useSelector<ApplicationState>(state => state.isHome) || location.pathname == '/');
    const showConfirmExit = useSelector<ApplicationState>(state => state.showConfirmExit)
    const didMount = useRef(false);


    useEffect(() => {
        setMenus(getMenus(userInfo.userRoles?.map(ur => ur.mainRole)));

    }, [userInfo.userRoles]);

    useEffect(() => {
        if (didMount.current) {
            console.log("in selected menu")
            if (!isHome) {
                setNavigationInfo({ ...navigationInfo, sectionType: selectedMenu.menuEnum ?? MenuItemEnum.Requests });
            }
        }

    }, [selectedMenu]);

    useEffect(() => {
        if (!isHome) {
            var params = queryString.parse(location.search)
            var currentNav = { pageType: params?.pageType, sidebarType: params?.sidebarType, sectionType: params?.sectionType } as NavigationInfo;
            var item = getMenuByEnum(currentNav.sectionType)
            setSelectedMenu(item);
        }
    }, [isHome]);


    useEffect(() => {
        if (didMount.current) {
            if (!isHome) {
                console.log("nav info section type")
                const localPage = selectedMenu?.childMenuItems?.[0]
                setSelectedPage(localPage);

            }
        }
    }, [navigationInfo.sectionType]);

    useEffect(() => {
        if (didMount.current) {
            if (!isHome) {
                console.log("selected page")
                //dispatch(resetState())
                setNavigationInfo({ ...navigationInfo, pageType: selectedPage?.pageTypeEnum ?? PageTypeEnum.None });
            }
        }
    }, [selectedPage]);

    useEffect(() => {
        if (didMount.current) {
            if (!isHome) {
                console.log("nav info page type")
                const localSideBar = selectedPage?.childMenuItems?.[0]
                setSelectedSidebar(localSideBar);
                //dispatch(resetState())
            }

        }
    }, [navigationInfo.pageType]);

    useEffect(() => {
        console.log("nav info sidebartype1")
        if (didMount.current) {
            if (!isHome) {
                console.log("nav info sidebartype")
                const localSideBar = selectedPage?.childMenuItems?.filter(p => p.sidebarTypeEnum == navigationInfo.sidebarType)?.[0]
                setSelectedSidebar(localSideBar);
                //dispatch(resetState())
            }

        }
    }, [navigationInfo.sidebarType]);

    useEffect(() => {
        console.log("did mount current change sidebar:" + didMount.current)
        if (didMount.current) {
            if (!isHome) {
                console.log("selected sidebar")
                const navigationInfoCopy = { ...navigationInfo };
                navigationInfoCopy.sidebarType = selectedSidebar?.sidebarTypeEnum ?? SidebarEnum.None
                var url = queryString.stringifyUrl({ url: selectedSidebar?.href ?? "", query: navigationInfoCopy })
                dispatch(push(url))
                batch(() => {
                    dispatch(setViewModel({}))
                    dispatch(setPageSkeleton(true))
                    dispatch(clearIndexTableObject())
                })
                setNavigationInfo(navigationInfoCopy);
            }
        }
        else {
            didMount.current = true;
        }
    }, [selectedSidebar]);

    useEffect(() => {
        var url = "/Home/GetNotificationCount";
        setInterval(() => {
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then(result => {
                    const notifications = JSON.parse(result).notifications as number

                    setNotificationsCount(notifications);
                })
                .catch(err => {
                    console.log(err);
                })
        }, 30000);

    }, [])


    function homePopoverClick(item: MenuItem) {
        if (showConfirmExit) {
            openConfirmExitModal(item.href, item.navigationInfo??{} as NavigationInfo)
        }
        else {
            var params = queryString.parse(location.search)
            var currentNav = { pageType: params?.pageType, sidebarType: params?.sidebarType, sectionType: params?.sectionType } as NavigationInfo;
            const localNewPage = item?.childMenuItems?.filter(p => p.pageTypeEnum == item.navigationInfo?.pageType)?.[0];
            const localNewSideBar = localNewPage?.childMenuItems?.filter(p => p.sidebarTypeEnum == item.navigationInfo?.sidebarType)?.[0];
            if (currentNav?.sectionType != item.navigationInfo?.sectionType) {
                setSelectedMenu(item);
            }
            else if (currentNav?.pageType != item.navigationInfo?.pageType) {
                setSelectedPage(localNewPage);
            }
            else if (currentNav?.sidebarType != item.navigationInfo?.sidebarType) {
                setSelectedSidebar(localNewSideBar);
            }
        }

    }
    function pageTypeClick(item: MenuItem) {
        
        const localPage = selectedMenu?.childMenuItems?.filter(p => p.pageTypeEnum == item.pageTypeEnum)?.[0]
        if (showConfirmExit) {
            openConfirmExitModal(localPage?.childMenuItems?.[0]?.href??"", {...navigationInfo, pageType:item.pageTypeEnum??PageTypeEnum.None, sidebarType:localPage?.childMenuItems?.[0].sidebarTypeEnum??SidebarEnum.None})
        }
        else {
            setSelectedPage(localPage);
        }
    }
    function sidebarTypeClick(item: MenuItem) {
        const localSideBar = selectedPage?.childMenuItems?.filter(p => p.sidebarTypeEnum == item.sidebarTypeEnum)?.[0]
          
        if (showConfirmExit) {
            openConfirmExitModal(localSideBar?.href??"", {...navigationInfo, sidebarType:item.sidebarTypeEnum??SidebarEnum.None})
        }
        else {
            setSelectedSidebar(localSideBar)
        }


    }
    function openConfirmExitModal(pathname: string, navigationInfo: NavigationInfo) {
        batch(() => {
            dispatch(setModal({ modalFlow: ModalFlow.ConfirmExitModal, modalInfo: { pathname: pathname, navigationInfo: navigationInfo } as ConfirmExitModalInfo, modalStep: ModalName.ConfirmExitModal } as Modal<ConfirmExitModalInfo>))
        })
    }
    function logoutClick() {
        fetch("Login/Logout", {
            method: 'POST'
        }).then((response) => {
            if (response.ok) { return response }
            else { throw response.text() }
        })
            .then((result) => {
                dispatch(setIsAuthenticated(false));
            })
            .catch(err => {
                console.log(err);
            })
    }
    return (

        <Box sx={{ display: 'flex'}}>
            <CssBaseline />

            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: (theme) => theme.palette.background.paper, height:"8.5vh",  boxShadow: '0px 6px 6px rgba(0,0,0, .16)', color: (theme) => theme.palette.primary.main, }}>

                <Toolbar variant='regular' sx={{ boxShadow: '0px 0px 12px rgba(0,0,0, .16)', height:"8.5vh" }} >
                    {isHome && <NameLogo width={15} />}
                    {!isHome && <HomePopover homePopoverClick={homePopoverClick} menus={menus} />}
                    {!isHome && <TopBarMenu pageTypeClick={pageTypeClick} selectedPage={selectedPage} selectedMenu={selectedMenu} />}
                    <Stack direction={"row"} flexGrow={1} justifyContent="right" spacing={2}>
                        <GlobalPopover closeOnClick={true} style={{ alignItems: "center", display: 'flex' }}>

                            <StyledBadge badgeContent={notificationsCount}>
                                <SvgIcon className='icon-dark-gray' name={SvgNames.CentarixNotificationTopMenu} height={3} />
                            </StyledBadge>
                            <HomeNotifications />

                        </GlobalPopover>
                        <Typography component="p" style={{ alignSelf: "center", display: 'flex' }}>{userInfo.firstName + " " + userInfo.lastName}</Typography>
                        <GlobalPopover>
                            <Avatar alt="userImage" sx={{ width: 50, height: 50 }} src={userInfo.userImage?.split("\\").slice(-2).join("\\")} />

                            <Button onClick={logoutClick} variant="text">
                                <Typography padding={"0.5rem 1rem"}>Logout</Typography>
                            </Button>
                        </GlobalPopover>
                    </Stack>
                </Toolbar>

            </AppBar>
            {!isHome && <SideBar selectedSidebar={selectedSidebar} sidebarTypeClick={sidebarTypeClick} selectedPage={selectedPage} selectedMenu={selectedMenu} />}
        </Box>
    );
}

function SideBar(props: { sidebarTypeClick: (arg0: MenuItem) => void, selectedPage?: MenuItem, selectedSidebar?: MenuItem, selectedMenu: MenuItem }) {
    const { userRoles } = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser;

    return (
        <StyledDrawer variant="permanent">
            <Box sx={{ overflow: 'auto' }}>
                <Stack direction="column" paddingTop="8.2rem">
                    {props.selectedPage?.childMenuItems?.map((item, i) => {
                        var showMenuItem = (item.requiredRole == undefined ||
                            (item.requiredRole && userRoles.filter(ur => ur.mainRole == props.selectedMenu.menuEnum)?.[0].subRoles.includes(item.requiredRole)))
                        var classes = "";
                        var iconClasses = ""
                        if (item.sidebarTypeEnum == props.selectedSidebar?.sidebarTypeEnum) {
                            classes = "section-color";
                            iconClasses = "section-fill"
                        }
                        return (
                            showMenuItem &&
                            <Link className={classes} key={i} onClick={() => props.sidebarTypeClick(item)} sx={{
                                textAlign: 'center',
                                fontSize: ".906rem",
                                fontWeight: "400",
                                "&:hover": { backgroundColor: 'rgba(0,0,0,.08)' }
                            }}>
                                <Box key={i} component="div" flexDirection="column" display="flex" paddingY="2.25rem" paddingX='.5rem'>
                                    <SvgIcon className={iconClasses} name={item.iconName || SvgNames.Add} height={1.75} />
                                    {item.description}
                                </Box>
                            </Link>
                        );
                    })}

                </Stack>

            </Box>
        </StyledDrawer>)
};



function HomePopover(props: { homePopoverClick: (arg0: MenuItem) => void, menus?: MenuItem[] }) {
    return (<GlobalPopover width={400}>
        <Box
            component='img'
            sx={{
                height: "1.5rem",
            }}
            alt="logo"
            src={logo} />
        <Grid container sx={{
            padding: '25px',

        }}  >
            {props.menus?.map((item, i) => (
                <StyledNavGridItem key={i} item xs={4} >
                    <StyleNavBoxItem >
                        <Link onClick={() => props.homePopoverClick(item)} minWidth="0" >
                            <Box
                                component='img'
                                alt="logo"
                                src={item.imageNameSmall}
                            ></Box>
                            <Typography whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden" fontSize="14px" >{item.description}</Typography>
                        </Link>
                    </StyleNavBoxItem>
                </StyledNavGridItem>

            ))}</Grid>
    </GlobalPopover>);
}
function TopBarMenu(props: { pageTypeClick: (arg0: MenuItem) => void, selectedMenu?: MenuItem, selectedPage?: MenuItem }) {

    return (
        <Stack direction="row" paddingLeft="5.625rem" >
            {props.selectedMenu?.childMenuItems?.map((item, i) => {
                var classes = "";
                if (item.pageTypeEnum == props.selectedPage?.pageTypeEnum) {
                    classes = "section-color";
                }
                return (
                    <Link data-testid="page-link" key={i} onClick={() => props.pageTypeClick(item)} className={classes} sx={{
                        fontSize: "1.5rem",
                        fontWeight: "400", marginRight: "4.375rem"
                    }} >{item.description}</Link>

                )
            })}
        </Stack>
    );
}