import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { AnySchema, boolean, date, object, string } from 'yup';
import { FolderNamesEnum, ModalFlow, ModalName } from '../../Constants/app-enums';
import { selectModalLoading } from '../../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState, Modal, ModalInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import DocumentsCard from '../../Shared/documents-card';
import { StyledControlledFormDateInput, StyledControlledFormInput } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { ParentRequest } from '../../Utility/request-types';
import { GlobalForm } from '../../Utility/shared-components';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';


type UploadOrderViewModel = {

}
type UploadOrderFormData = {
    isReorder: boolean
    parentRequest: ParentRequest,
    expectedSupplyDays: number | null,
    expectedSupplyDate: string
}
export default function UploadOrderModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const [error, setError] = useState<CustomError>(new CustomError());
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<AddItemFormData>
    const fromBackButton = (useSelector<ApplicationState>(state => state.modalWithLoading.modal.future) as Modal<ModalInfo>[]).length > 0


    function onSubmit(data: UploadOrderFormData) {
        console.log("on submit upload order")
        console.log(data)
        let tempDataDeepCopy = { ...tempData, request: { ...tempData.request } }
        tempDataDeepCopy.request.parentRequest = data.parentRequest;
        if (data.isReorder) {
            //dispatch(setModalLoading(true))
            dispatch(setTempData(tempDataDeepCopy))
            if (fromBackButton) {
                dispatch({ type: "redoModals" })
                dispatch({ type: "redoTempData" })
            }
            else {
                dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.AdditionalRequestInfo }))
            }
        }
        else {
            batch(() => {
                dispatch(setTempData(tempDataDeepCopy));
                dispatch(setModalLoading(true))
                dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.Terms } as Modal<BasicModalInfo>))
            })
        }
    }

    useEffect(() => {
        dispatch(setModalLoading(false));

    }, [])

    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Upload Order"} errorMessage={error.message} />
                    {error.showBody &&
                        <UploadOrderForm onSubmit={onSubmit} tempData={tempData} />

                    }

                </>
            }
        </>
    )

}
type UploadOrderFormProps = {
    onSubmit: any,
    tempData: TempData<AddItemFormData>
}
export function UploadOrderForm(props: UploadOrderFormProps) {
    const validationSchema = object<Partial<Record<keyof UploadOrderViewModel, AnySchema>>>({
        isReorder: boolean(),
        parentRequest: object<Partial<Record<keyof ParentRequest, AnySchema>>>({
            supplierOrderNumber: string().required("Supplier Order Number is required"),
            orderDate: date().typeError("Order date must be a valid date").required("Order date is required"),
        }),
        hasFile: boolean().isTrue("File is required")
    })

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:
            {
                expectedSupplyDate: moment().add(props.tempData?.request?.expectedSupplyDays ?? 0, "days").format("YYYY-MM-DD"),
                expectedSupplyDays: props.tempData?.request?.expectedSupplyDays ?? null,
                parentRequest: {
                    supplierOrderNumber: props.tempData?.request?.parentRequest?.supplierOrderNumber ?? "",
                    orderDate: props.tempData?.request?.parentRequest?.orderDate ?? moment().format()
                },
                isReorder: props.tempData?.request?.productID > 0
            } as UploadOrderFormData
    }
    );


    const isReorder = methods.watch("isReorder")

    return (<>
        <StyledDialogContent>
            <GlobalForm formID={ModalName.UploadOrder} onSubmit={props.onSubmit} methods={methods}>
                <Stack direction="row" spacing={2}>
                    <DocumentsCard allowMultipleFiles folderID={props.tempData.guid} folderName={FolderNamesEnum.Orders} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <StyledControlledFormInput name={"parentRequest.supplierOrderNumber"} label="Supplier Order Number" />
                        </Grid>
                        <Grid item xs={6}>
                            <StyledControlledFormDateInput label="Order Date" name={"parentRequest.orderDate"} />
                        </Grid>
                    </Grid>
                </Stack>
            </GlobalForm>
        </StyledDialogContent>

        <ModalFooterWithSave formID={ModalName.UploadOrder} submitButtonText={"Next"} >
            {isReorder && <ModalBackButton modalFlowKey={ModalFlow.AddItem} />}
        </ModalFooterWithSave>
    </>
    );
}