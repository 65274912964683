import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ItemCardFields } from "../../Constants/app-enums";
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore";
import { ApplicationState, TempData } from "../../ReduxRelatedUtils/redux-types";
import { AddItemFormData } from "../../Shared/AddCardComponents/add-types";
import { ProductItemViewModel, RequestItemViewModel } from "../../Utility/request-types";
import { UnitBlockWithOutPrice, UnitCardWithOutPrice } from "./edit-product";
import { BatchLotBlock, CategoryBlock, CreationDateBlock, InvoiceBlock, OrderBlock as OrderBlock, PassageAmountBlock, QuoteBlock, ReceivedBlock, SupplierOrderNumberBlock, SupplyDaysBlock, UrlBlock, VendorBlock, WarrantyBlock } from "./item-components";

export default function ItemTab(props: { fields: ItemCardFields[], editID?:string, isProduct?:boolean }) {
    const dispatch = useDispatch()
    const { watch, setValue, control, formState: { errors } } = useFormContext<TempData<AddItemFormData>>();
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.editID??"0")?.viewModel) as RequestItemViewModel|ProductItemViewModel;
    //console.log(viewModel)
    return (<Grid container spacing={2}>
        <Grid item xs={12}>
            <CategoryBlock name={props.isProduct?"":"request." }  viewModel={viewModel} key={"category"+viewModel?.parentCategories?.map(p=>p.description).join("")} editID={props.editID}/>
        </Grid>
        {props.fields?.map((e, i) => {
            switch (e) {
                case ItemCardFields.Vendor:
                    return (<VendorBlock  name={props.isProduct?"":"request." } key={e} viewModel={viewModel} />)
                case ItemCardFields.Unit:
                    return (<UnitBlockWithOutPrice viewModel={viewModel} />);                       
                case ItemCardFields.BatchLot:
                    return (<BatchLotBlock key={e} />)
                case ItemCardFields.Warranty:
                    return (<WarrantyBlock key={e} />);
                case ItemCardFields.SupplyDays:
                    return (<SupplyDaysBlock key={e} />);
                case ItemCardFields.Url:
                    return (<UrlBlock key={e} name={props.isProduct?"":"request." } />);
                case ItemCardFields.Passage:
                    return (<PassageAmountBlock key={e} />);
                case ItemCardFields.CreationDate:
                    return (<CreationDateBlock key={e} />);
                case ItemCardFields.Quote:
                    return (<QuoteBlock key={e}/>);
                case ItemCardFields.Order:
                    return (<OrderBlock key={e} requestStatusID={(viewModel as RequestItemViewModel).request.requestStatusID}/>);
                case ItemCardFields.SupplierOrderNumber:
                    return (<SupplierOrderNumberBlock key={e}/>);   
                case ItemCardFields.Received:
                   return (<ReceivedBlock key={e} users={(viewModel as RequestItemViewModel)?.applicationUsers?? []}/>);
            }
        })}

    </Grid>)
}
