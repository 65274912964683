import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormControl, IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useContext, useState } from 'react';
import { useController } from 'react-hook-form';
import { IsEdittableContext, StyledError } from './shared-components';

export default function LoginPasswordInput(props:any){
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { field, fieldState: { error } } = useController({ name: props.name ??"" });
    return (
        <FormControl>
        <TextField
          {...field}
            placeholder='Password'
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            InputProps={{ // <-- This is where the toggle button is added.
                className:"login-styled-outline-input",            
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <Visibility sx={{height:"1.6rem"}} /> : <VisibilityOff sx={{height:"1.6rem"}} />}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...props}
        />
                       <StyledError>{error?.message}</StyledError>
        </FormControl>

    );
};

export function PasswordInput(props:TextFieldProps & {readOnly?:boolean}){
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const { field, fieldState: { error } } = useController({ name: props.name ??"" });

    const isEdittableContext = useContext(IsEdittableContext);
    function readOnly() {
        return isEdittableContext.isEdittable == false ? { readOnly: true } : null
    }
    return (
        <>
            <FormControl fullWidth variant="standard">

                <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label={props.label}
                    placeholder={props.placeholder}
                    InputProps={{
                        inputProps: {...{ readOnly: props.readOnly }, ...readOnly() ?? {} },
                        className: isEdittableContext.isEdittable == false || props.readOnly ? "read-only-input" : "section-input",
                        sx: {
                            paddingRight: props.type == "number" ? "0.2rem !important" : "0.8rem",
                            marginTop: "1.3rem !important"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility sx={{height:"1.6rem"}} /> : <VisibilityOff sx={{height:"1.6rem"}} />}
                            </IconButton>
                        </InputAdornment>
                        )
                    }}
                    {...props}
                />
                <StyledError>{error?.message}</StyledError>
            </FormControl>

        </>
    );
}