import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { StyledFormInput } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Request } from '../../Utility/request-types';
import { basicModalPost, objectToFormData } from '../../Utility/root-function';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


type DeleteModalViewModal = {
    requests: Request[]
}
export default function DeleteModal() {
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;    
    const [error, setError] = useState<CustomError>(new CustomError());
    const [state, setState] = useState({
        viewModel: {} as DeleteModalViewModal,
    });

    useEffect(() => {
        var url = "/Requests/DeleteModal?IDs=" + modalInfo.ids+"";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: DeleteModalViewModal) => {
                var viewModel = result;
                setState({ ...state, viewModel: viewModel });
                dispatch(setModalLoading(false))
                //dispatch(setGlobalSnack({open:true, message:"In delete Modal"}))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({message:text.errorMessage, showBody:false})
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);


    function onSubmit(e:any) {        
        var url = '/Requests/DeleteModal'
        basicModalPost(url, setError, dispatch, objectToFormData({IDs:modalInfo.ids}))
    }

    return (
        <GlobalModal key={ModalName.DeleteItem} size={ModalSizes.sm} modalKey={ModalName.DeleteItem}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Delete Item" errorMessage={error.message} />
                    {error.showBody ?<>
                    <StyledDialogContent>
                     
                            <form id={ModalName.DeleteItem} onSubmit={handleSubmit(onSubmit)}>
                            <Typography>Confirm that you would like to delete:</Typography>
                                {state.viewModel?.requests?.map((r, i)=>{
                                   return( <>
                                        <Typography fontWeight={500} fontSize="1.5rem" paddingY="1rem">{(state.viewModel?.requests.length>1 ? (i+1)+".\t":"")+r.product.productName}</Typography>
                                        <Stack direction="row" spacing={1}>
                                            <StyledFormInput disabled label="Parent Category" value={r.product?.productSubcategory?.parentCategory?.description ?? ''} />
                                            <StyledFormInput disabled  label="Product Subcategory" value={r.product?.productSubcategory?.description ?? ''} />
                                            <StyledFormInput disabled  label="Vendor" value={r.product?.vendor?.vendorEnName ?? ''} />
                                        </Stack>
                                    </>)
                                    })
                                }
                            </form>
                         
                    </StyledDialogContent>
                    <ModalFooterWithSave submitButtonText='Delete' formID={ModalName.DeleteItem} />
                    </>
                    : null
                }
                </>
            }
        </GlobalModal>

    );
}
