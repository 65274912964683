import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { batch, useDispatch, useSelector } from 'react-redux';
import { PageHeaderEnum, ModalFlow, ModalName, SvgNames, SidebarEnum } from '../../Constants/app-enums';
import { setReloadIndex, setSearchText, setSelectedVendor } from '../../ReduxRelatedUtils/index-table-reducer';
import { setModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { AccountingSelectsViewModel, ApplicationState, Modal, ModalInfo } from '../../ReduxRelatedUtils/redux-types';
import { ReportDaysOffHeader } from '../../Timekeeper/report-days-off-header';
import { SummaryHoursTotalHeader } from '../../Timekeeper/summary-hours-total-header';
import { IndexTableObject, RequestIndexViewModel, RequestIndexViewModelByGroup, Vendor } from '../../Utility/request-types';
import { FilledTableButton, StyledFilledButton, SvgIcon } from '../../Utility/shared-components';
import { SummaryHoursViewModel } from '../../Utility/timekeeper-types';
import { UserIndexViewModel } from '../../Utility/user-types';
import IndexTableTabs from './index-table-tabs';
import PageTitle from './page-title';
import { push } from 'connected-react-router'
import queryString from 'query-string'
import { selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { RouteSharp } from '@mui/icons-material';
import { SEARCH_REQUESTS, SEARCH_REQUESTS_RESULTS } from '../../Constants/Routes';
import { AutocompleteOption } from '../../Utility/general-types';
import { InstallmentsGroupAndSort, VendorDropdown, StatusDropdown, SearchInput, MonthlyYearlyToggleButtons, MonthDropdown, YearDropdown, ExcelExportButton } from './page-header-components';
import IndexFilter from './index-filter';
//import VendorDropdown from './vendor-dropdown';
export default function IndexTablePageHeaders() {

    const dispatch = useDispatch()
    const viewModel = useSelector<ApplicationState>(state => state.viewModel) as RequestIndexViewModel | UserIndexViewModel | SummaryHoursViewModel | RequestIndexViewModelByGroup
    const loading = useSelector<ApplicationState>(state => state.showPageSkeleton) as boolean
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const navigationInfo = useSelector(selectNavigationInfo);

    return (
<>
{!loading && viewModel?.pageHeaders?.includes(PageHeaderEnum.ReportDaysOff) &&
                <ReportDaysOffHeader />
            }
        <Grid container key={loading + ""} flexDirection="row" position="sticky" pb="3.2rem" alignItems="flex-end" height="9.5vh">
            
            {((!loading || (("tabs" in viewModel/*for typescript*/ && viewModel?.tabs?.length > 0))) && viewModel?.pageHeaders?.includes(PageHeaderEnum.BackArrow)) &&
                <Button
                    sx={{ marginRight: "2rem" }}
                    onClick={() => {
                        batch(() => {
                            dispatch(setReloadIndex(true))
                            if (navigationInfo.sidebarType == SidebarEnum.Search) {
                                dispatch(push(queryString.stringifyUrl({ url: SEARCH_REQUESTS, query: { ...location.query } })))
                            } else {
                                dispatch(push(queryString.stringifyUrl({ url: location.pathname, query: { ...location.query, id: null } })))
                            }
                        })
                    }}><SvgIcon name={SvgNames.ArrowBackIos} height={2} /></Button>
            }

            {("tabs" in viewModel/*for typescript*/ && viewModel?.tabs?.length > 0) ?
                <IndexTableTabs viewModel={viewModel as RequestIndexViewModel} />
                :
                !loading ?<>  <PageTitle viewModel={viewModel} /> 
                {viewModel?.pageHeaders?.includes(PageHeaderEnum.MonthlyYearlyToggle) &&
                    <MonthlyYearlyToggleButtons yearlyMonthlyEnum={(viewModel as UserIndexViewModel).yearlyMonthlyEnum} />
                } </>: <></>
            }
            

            {!loading &&
                <Grid item marginLeft="auto">
                    <Grid container columnSpacing={2}>
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.AddBankAccount) &&
                            <Grid item>
                                <FilledTableButton sx={{marginTop:"1.3rem"}} onClick={() => {
                                    dispatch(setModalLoading(true))
                                    dispatch(setModal({ modalFlow: ModalFlow.AddPaymentMethod, modalStep: ModalName.AddBankAccount } as Modal<ModalInfo>))
                                }}>+  Add Bank Account</FilledTableButton>
                            </Grid>
                        }
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.AddCreditCard) &&
                            <Grid item>
                                <FilledTableButton sx={{marginTop:"1.3rem"}} onClick={() => {
                                    dispatch(setModalLoading(true))
                                    dispatch(setModal({ modalFlow: ModalFlow.AddPaymentMethod, modalStep: ModalName.AddCreditCard } as Modal<ModalInfo>))
                                }}>+  Add Credit Card</FilledTableButton>
                            </Grid>
                        }
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.Search) &&
                            <Grid item>
                                <SearchInput />
                            </Grid>
                        }
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.InstallmentsGroupAndSort) &&
                            <InstallmentsGroupAndSort />
                        }
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.VendorSelect) &&
                            <Grid item>
                                <VendorDropdown vendorList={(viewModel as RequestIndexViewModelByGroup).vendors} />
                            </Grid>
                        }
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.StatusSelect) &&
                            <StatusDropdown />
                        }

                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.Filter)  &&
                        <IndexFilter  showFilter={viewModel.pageHeaders?.includes(PageHeaderEnum.Filter)} />
                    }

                        {/*viewModel?.pageHeaders?.includes(PageHeaderEnum.ListSettingsButton) &&
                        <ListSettingsButtons canAddList={(viewModel as RequestIndexViewModel).tabs?.length < 5} />
                    }*/}

                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.MonthsDropdown) &&
                            <MonthDropdown selectedMonth={(viewModel as SummaryHoursViewModel).month} />
                        }
                        {viewModel?.pageHeaders?.includes(PageHeaderEnum.YearsDropdown) &&
                            <YearDropdown selectedYear={(viewModel as UserIndexViewModel).year} />
                        }
{viewModel?.pageHeaders?.includes(PageHeaderEnum.ExportTable) &&
                           <ExcelExportButton/>
                        }


                    </Grid>
                </Grid>}
           


        </Grid>
         {!loading && viewModel?.pageHeaders?.includes(PageHeaderEnum.TotalsSummary) &&
            <SummaryHoursTotalHeader />
        }</>
    
    )
}

