import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControlLabel, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { AnySchema, boolean, date, number, object, string } from "yup";
import { CurrencyEnum, FolderNamesEnum, ModalName, SvgNames } from "../../Constants/app-enums";
import { ModalSizes } from "../../Constants/client-side-enums";
import { selectModalLoading, selectModalPresent } from "../../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../../ReduxRelatedUtils/index-table-reducer";
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { removeModal, setModalLoading } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../../ReduxRelatedUtils/redux-types";
import { setViewModel } from "../../ReduxRelatedUtils/viewmodel-reducer";
import DocumentsCard from "../../Shared/documents-card";
import { ControlledCheckbox, StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledRadioButton, StyledControlledRadioGroup, StyledCurrencyFormInput } from "../../Utility/custom-input-components";
import { AutocompleteOption, BasicModalInfo, CustomError } from "../../Utility/general-types";
import { CompanyAccount, CreditCard, Invoice, Payment, PaymentType } from "../../Utility/request-types";
import { basicModalPost } from "../../Utility/root-function";
import { AddInvoiceBlock, GlobalForm, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack, SvgIcon } from "../../Utility/shared-components";
import GlobalModal from "./global-modal";
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from "./modal-components";
import ModalSkeleton from "./modal-skeleton";

export type PayModalFormData={
    paymentIDs:number[]
    payment:Payment,    
    partialAmtToPay:number,
    percentageToPay:number,
    totalVatShekel:number,
    totalVatDollar:number,
    isPartialPayment: boolean,
    addInvoice:boolean,
    guid : string,
    hasFile: boolean, 
    currency: CurrencyEnum,
    invoiceForCurrentPayment:boolean
}
export type PayModalViewModel={
    sumDollarWithoutVat:number,
    sumShekelWithoutVat:number,
    sumShekelVat:number,
    sumDollarVat:number,
    currency:CurrencyEnum,
    paymentTypes: AutocompleteOption[],
    companyAccounts : AutocompleteOption[],
    creditCards: CreditCard[],
    canAddInvoice:boolean, 
    isInstallments:boolean
}

const validationSchema = object<Partial<Record<keyof PayModalFormData, AnySchema>>>({
    payment: object<Partial<Record<keyof Payment, AnySchema>>>({
            paymentReferenceDate:date().when("paymentTypeID", {
                is: (paymentTypeID: number) => paymentTypeID != 2,
                then: date().typeError("Reference date must be a valid date").required("Reference date is required").max(moment().add("day").startOf("day"), "Reference date cannot be in the future"),
                otherwise: date().typeError("Reference date must be a valid date").required("Reference date is required"),
            }),
        companyAccountID:  number().min(1, "Bank field is required.").required("Bank field is required."),        
        paymentTypeID: number().min(1, "Payment Type field is required.").required("Payment Type field is required."),
        creditCardID: number().when("paymentTypeID", {
            is: 1,
            then: number().min(1, "Credit Card field is required.").required("Credit Card field is required."),
            otherwise: number().nullable()
        }),
        checkNumber: string().when("paymentTypeID", {
            is: 2,
            then: string().min(1, "Check Number field is required.").required("Check Number field is required."),
            otherwise: string().nullable()
        }),
        reference: string().when("paymentTypeID", {
            is: 3,
            then: string().min(1, "Reference field is required.").required("Reference field is required."),
            otherwise: string().nullable()
        }),
     }).when("addInvoice", {is:true,
        then:object<Partial<Record<keyof Payment, AnySchema>>>({
            invoice: object<Partial<Record<keyof Invoice, AnySchema>>>({
                invoiceNumber: string().required("Invoice Number is required"),
                invoiceDate: date().typeError("Invoice Date must be a vaild date").required("Invoice Date is required").max(moment().add("day").startOf("day"), "Invoice date cannot be in the future"),
        
            })}),
        otherwise:object()
        }),
    hasFile: boolean().when("addInvoice", {is:true,
         then:boolean().isTrue("File is required"),
         otherwise:boolean()
        }),
    partialAmtToPay: number().when(["isPartialPayment"], (isPartialPayment, schema) => {
        return (isPartialPayment ?
            number().typeError("Pay now is required.").required("Pay now is required.").test("test max amount", "Pay now must be less than the total cost", function (this:{parent:PayModalFormData}, options) {
                return this.parent.currency == CurrencyEnum.NIS? this.parent.totalVatShekel > this.parent.partialAmtToPay+1 : this.parent.totalVatDollar > this.parent.partialAmtToPay+1
            }).min(1, "The minimum amount to pay is one")
            : number()
        )
    },
    
    )
})

export function PayModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const methods = useForm<PayModalFormData>({
      resolver:yupResolver(validationSchema),
      defaultValues:{
        payment: {
            paymentTypeID:0,
            paymentReferenceDate:"",
            companyAccountID:0,
            creditCardID:0,
            checkNumber:"",
            reference:"",
            invoice:{invoiceNumber:"", invoiceDate:""},
            
        },
        invoiceForCurrentPayment:false,
        guid:v4(),
        addInvoice:false,
        isPartialPayment:false,
        partialAmtToPay:0,
        percentageToPay:1,
        paymentIDs:modalInfo.ids.map(function (x) { 
            return parseInt(x, 10); 
          })
      }
    });

    const [viewModel, setViewModel] = useState<PayModalViewModel>()
    const payment = methods.watch("payment");
    const isPartialPayment = methods.watch("isPartialPayment");
    const partialAmtToPay = methods.watch("partialAmtToPay");
    const addInvoice = methods.watch("addInvoice");
    const guid = methods.watch("guid");



    useEffect(() => {
        if (viewModel?.currency == CurrencyEnum.NIS) {
            methods.setValue("percentageToPay",  partialAmtToPay/viewModel?.sumShekelVat )
        }
        else if (viewModel?.currency == CurrencyEnum.USD) {
            methods.setValue("percentageToPay",  partialAmtToPay/viewModel?.sumDollarVat )
        }
    }, [partialAmtToPay])


    useEffect(() => {
        var url = "/Requests/PayModal?paymentIDs="+modalInfo.ids;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: PayModalViewModel) => {
                dispatch(setModalLoading(false))
                setViewModel(result);     
                methods.setValue("totalVatShekel", result.sumShekelVat);
                methods.setValue("totalVatDollar", result.sumDollarVat);
                methods.setValue("currency", result.currency);
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, []);
    
    function onSubmit(data: PayModalFormData, e: any) { 
        var url = '/Requests/PayModal'
        dispatch(setLoading(true))
            fetch(url, {
                method: "POST",
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
            })
                .then(response => {
                    if (response.ok) { return response.text() }
                    throw response.text();
                })
                .then(( result:string ) => {
                    batch(() => {
                        dispatch(setLoading(false))
                        dispatch(removeModal())
                        dispatch(setGlobalSnack({ open: true, message: result, severity: "success" }))
                        dispatch(setReloadIndex(true))
                    })
                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setLoading(false))
                        dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                    })
                })
    }

    return (
        <GlobalModal size={ModalSizes.md} modalKey={ModalName.Pay}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Pay" errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.Pay} onSubmit={(data: any, e: any) => onSubmit(data, e)} methods={methods}>

                                <SpacedColumnStack>
                                    <SpacedRowStack key="1">                                        
                                        <StyledCurrencyFormInput currency="₪" value={viewModel?.sumShekelWithoutVat} label="Sum" disabled/>
                                        <StyledCurrencyFormInput currency="$" value={viewModel?.sumDollarWithoutVat} disabled/>
                                        <StyledCurrencyFormInput currency="₪" value={viewModel?.sumShekelVat} label="Total + VAT" disabled/>
                                        <StyledCurrencyFormInput currency="$" value={viewModel?.sumDollarVat} disabled/>
                                    </SpacedRowStack>
                                    <SpacedRowStack key="2" sx={{width:"50%"}}><Box sx={{width:"50%", display:"flex"}}>
                                    <FormControlLabel key="partialPayment" sx={{width:"100%"}}
                        control={<ControlledCheckbox name="isPartialPayment" />}
                        label={<Typography>Partial Payment</Typography>} /><Tooltip title={"This will create a new payment with the specified amount"} arrow>
                        <Box marginLeft="0.5rem" display="flex" alignItems="center"><SvgIcon height={1.5} name={SvgNames.Info} /></Box>
                    </Tooltip></Box>
                                  {
                                   isPartialPayment&& 
                                   <Box sx={{width:"50%"}}>
                                        <StyledCurrencyFormInput label="Pay Now"  name='partialAmtToPay' currency={viewModel?.currency== CurrencyEnum.NIS?"₪":"$"} />    
                                        </Box>
                                   }
                                    </SpacedRowStack>
                                    <PaymentDetailsBlock  creditCards={viewModel?.creditCards ?? []} name={`payment`} paymentTypes={viewModel?.paymentTypes} companyAccounts={viewModel?.companyAccounts}/>                       
                               
                                 {viewModel?.canAddInvoice&& <>  <SpacedRowStack key="3" >
                                    <FormControlLabel key="addInvoice" 
                        control={<ControlledCheckbox name="addInvoice" />}
                        label={<Typography>Add Invoice</Typography>} />
                        {addInvoice&&  <StyledControlledRadioGroup name="invoiceForCurrentPayment">
                                            <FormControlLabel  sx={{ margin: "0" }} key={"currentPayment"} value={true}
                                                control={<StyledControlledRadioButton />}
                                                label={<Typography>Invoice For Current Payment</Typography>} disabled={!isPartialPayment && !viewModel.isInstallments}/>
                                            <FormControlLabel sx={{ margin: "0" }} key={"entireItem"} value={false} 
                                                control={<StyledControlledRadioButton />}
                                                label={<Typography>Invoice For Entire Item</Typography>} />
                                        </StyledControlledRadioGroup>}
                        </SpacedRowStack>
                        {addInvoice&&  
                         <AddInvoiceBlock name="payment." guid={guid}/>}</>}
                                  </SpacedColumnStack>
                            </GlobalForm>


                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.Pay} />
                    </>}
                </>
            }
        </GlobalModal>

    );
}

