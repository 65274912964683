import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setPageSkeleton } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import GlobalModal from '../Requests/Modals/global-modal';
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { BasicModalInfo, CustomError } from '../Utility/general-types';
import { getTimeDuration } from '../Utility/root-function';
import { BoldTypography, SpacedColumnStack } from '../Utility/shared-components';
import { EmployeeHours } from '../Utility/timekeeper-types';


export default function ExitHoursModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const [viewModel, setViewModel] = useState<EmployeeHours>()

    useEffect(() => {
        fetch("Timekeeper/ExitModal", {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.text;
            })
            .then((result: EmployeeHours) => {
                setViewModel(result)
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                console.log(err)
            })
    }, [modalInfo]);

    
    useEffect(() => {


        return(()=>{dispatch(setPageSkeleton(true))})
        
    },[]);

    useEffect(() => {
        if (viewModel != null) {
            setTimeout(() => {
                dispatch(removeModal())
            }
                , 20000);

        }
    }, [viewModel])


    return (
        <GlobalModal key={ModalName.ExitHours} size={ModalSizes.xs} modalKey={ModalName.ExitHours}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Today's Hours Updated" errorMessage={error.message} />
                    {error.showBody ? <>
                        <StyledDialogContent>
                            <SpacedColumnStack>
                                <Typography>{moment(viewModel?.date).format("dddd, DD/MM/yyyy")}</Typography>
                                <Typography>
                                    {`You have worked from ${moment(viewModel?.entry1).format("HH:mm")} - ${moment(viewModel?.exit1).format("HH:mm")}`}
                                    {viewModel?.entry2 && ` and from ${moment(viewModel?.entry2).format("HH:mm")} - ${moment(viewModel?.exit2).format("HH:mm")}`}
                                </Typography>
                                <BoldTypography>{`A total of ${moment.duration(viewModel?.totalHours.toString()).hours()}:${moment.duration(viewModel?.totalHours.toString()).minutes().toString().padStart(2,"0")}`}</BoldTypography>

                            </SpacedColumnStack>

                        </StyledDialogContent>
                    </>
                        : null
                    }
                </>
            }
        </GlobalModal>

    );
}
