import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Stack } from "@mui/material";
import { push } from "connected-react-router";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { batch, useDispatch } from "react-redux";
import { AnySchema, number, object } from "yup";
import { LoginResult } from "../Constants/app-enums";
import { setErrorMessage as globalSetErrorMessage } from "../ReduxRelatedUtils/errorReducer";
import { setIsAuthenticated } from "../ReduxRelatedUtils/userInfoReducer";
import { LoginStyledInput } from "../Utility/custom-input-components";
import { CustomError } from "../Utility/general-types";
import { LoginButton, LogoLayout, StyledError } from "../Utility/shared-components";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
export type Login2FAFormData = {
    Code: number | null
}
const validationSchema = object<Partial<Record<keyof Login2FAFormData, AnySchema>>>({
    Code: number().nullable().typeError("Authentication code must be a number").required("Authentication Code is required"),
});
export default function Login2FA() {

    const dispatch = useDispatch();

    function onSubmit(data: any) {
        dispatch(setLoading(true))
        fetch("Login/Login2Fa", {
            method: 'POST',
            body: data.Code.toString(),
            headers: { 'Content-Type': 'application/json; charset=utf-8', 'Data-Type': 'json' },
        }).then((response) => {
            return response.json();
        })
            .then((result: LoginResult) => {
                dispatch(setLoading(false))
                switch (result) {
                    case LoginResult.InvalidAttempt:
                        dispatch(setGlobalSnack({ open: true, message: "Invalid attempt", severity: "error" }))
                        break;
                    case LoginResult.Lockout:
                        batch(() => {
                            dispatch(push("/Login"));
                            dispatch(setGlobalSnack({ open: true, message: "User is locked out", severity: "error" }))
                        })
                        break;
                    case LoginResult.Succeeded:
                        console.log("succeeded 2fa")
                        dispatch(setIsAuthenticated(true));
                        break;
                }
            })
            .catch(err => {
                console.log(err);
            })
    }


    return (<>
        <LogoLayout>
            <Login2FAForm onSubmit={onSubmit}></Login2FAForm>
        </LogoLayout>
    </>

    );
};

export type Login2FAFormProps = {
    onSubmit: (data: Login2FAFormData) => void
}

export function Login2FAForm(props: Login2FAFormProps) {

    const methods= useForm({ resolver: yupResolver(validationSchema), defaultValues: { Code: null } as Login2FAFormData },);
    return (
        <FormProvider {...methods}>
        <Stack spacing={2} component={'form'} onSubmit={methods.handleSubmit(props.onSubmit)} sx={{ padding: '0 1.094rem' }}>
            {process.env.NODE_ENV !== 'production' && <DevTool control={methods.control} />}
            <LoginStyledInput name="Code" placeholder="Authentication Code" />           
            <LoginButton variant="contained" type="submit">Verify</LoginButton>
        </Stack>
        </FormProvider>
    )
}

