import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectEditDrawerViewModelByID, selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { setEditDrawerViewModel } from '../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer';
import { StyledFormInput } from '../../Utility/custom-input-components';
import { LocationModalInfo, BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Request, RequestItemViewModel } from '../../Utility/request-types';
import { basicModalPost, objectToFormData } from '../../Utility/root-function';
import { GlobalForm } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


export default function ConfirmArchiveModal() {
    const dispatch = useDispatch();
    const methods = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;    
    const [error, setError] = useState<CustomError>(new CustomError());
    const [locationName, setLocationName] = useState("")
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, modalInfo.ids[0]??0)?.viewModel) as RequestItemViewModel;
  
    useEffect(() => {
        var url = "/Requests/ConfirmArchiveModal?requestID=" + modalInfo.ids[0];
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.text() }
                throw response.text();
            })
            .then((result: string) => {
                setLocationName(result);
                dispatch(setModalLoading(false))
                //dispatch(setGlobalSnack({open:true, message:"In delete Modal"}))
            })
            .catch(err => {
                console.log(err)
                Promise.resolve(err).then(text => {
                    setError({message:text.errorMessage, showBody:false})
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);


    function onSubmit(e:any) {        
        var url = '/Requests/ArchiveRequestLocationInstances'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: objectToFormData({requestId: modalInfo.ids[0]})
        })
            .then(result => {
                if (!result.ok) throw result.text()
                console.log("basic modal result")
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Success" }))
                    dispatch(setEditDrawerViewModel({reloadEditModal:false, id:modalInfo.ids[0], viewModel:{...viewModel, selectLocationViewModel:{...viewModel.selectLocationViewModel, isArchived: true}} as RequestItemViewModel}))
                })
    
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }

    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Clear Location" errorMessage={error.message} />
                    {error.showBody ?<>
                    <StyledDialogContent>
                     <GlobalForm methods={methods} formID={ModalName.ConfirmArchive} onSubmit={onSubmit}>
                            <Typography>{`Are you sure you want to clear this location ${locationName}?`}</Typography>
                    </GlobalForm>
                         
                    </StyledDialogContent>
                    <ModalFooterWithSave submitButtonText='Clear' formID={ModalName.ConfirmArchive} />
                    </>
                    : null
                }
                </>
            }
</>
    );
}
