import undoable from 'redux-undo'

import { createSlice } from '@reduxjs/toolkit'
import { TempData } from './redux-types'

const initialState = {} as TempData<any>
const tempDataSlice = createSlice({
    name: 'tempData',
    initialState,
    reducers: {
        setTempData(state, action:{payload:TempData<any>}) {
            return action.payload
        },
        resetTempData(state, action:{}) {
            return initialState;
        }
    }
})

export const { setTempData, resetTempData } = tempDataSlice.actions

export default undoable(tempDataSlice.reducer,{undoType:"undoTempData", redoType:"redoTempData"})