import { DevTool } from "@hookform/devtools";
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, object, string } from 'yup';
import { ModalFlow, ModalName, SidebarEnum } from '../../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal, NavigationInfo } from '../../ReduxRelatedUtils/redux-types';
import { StyledControlledFormInput } from '../../Utility/custom-input-components';
import { CustomError, MoveToListViewModel, NewListFormData, NewListModalInfo } from '../../Utility/general-types';
import { objectToFormData } from '../../Utility/root-function';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';


export default function NewListModal (){
    const dispatch = useDispatch();
    const navigationInfo = useSelector<ApplicationState>( (state)=>selectNavigationInfo(state)) as NavigationInfo
    const validationSchema = object<Partial<Record<keyof NewListFormData, AnySchema>>>({
        listTitle: string().required("List Title is required").max(20)
    })
    const tempData = useSelector<ApplicationState>(state => state.tempData.present.data) as MoveToListViewModel
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<NewListModalInfo>;       
    const defaultValues = { 
        listTitle: "", 
        requestPreviousListID: tempData?.previousListID ?? modalInfo.requestPreviousListID, 
        requestToAddID: tempData?.request?.requestID ?? modalInfo.requestToAddID 
    } as NewListFormData
    const resolver = yupResolver(validationSchema);
    const methods = useForm({ resolver: resolver, defaultValues: defaultValues });
    const [state, setState] = useState({
        error: new CustomError(),
    });

    function onSubmit (data:any) {
        var formData = objectToFormData(data)
        var url = "/Requests/NewListModal";
        dispatch(setLoading(true))

        fetch(url,
            {
                method: "POST",
                body: formData
            }
        )
            .then(response => {
                if (response.ok) { return response }
                else { throw response.text() }
            })
            .then(result => {
                console.log("in new list result")
                batch(()=>{
                navigationInfo.sidebarType == SidebarEnum.MyLists && dispatch(setReloadIndex(true))
                dispatch(removeModal())
                dispatch(setLoading(false))
                })

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setState({
                        ...state, error: { message: text.toString(), showBody: true }
                    })
                    dispatch(setLoading(false))
                })
            })

    };

    return (
        <>
            <ModalHeader headerText='New List' errorMessage={state.error.message} />
            <StyledDialogContent>
                {state.error.showBody ?
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} method="post" encType="multipart/form-data" id={ModalName.NewList}>
                            {process.env.NODE_ENV !== 'production' && <DevTool control={methods.control} />}
                            <Box>
                                <StyledControlledFormInput name="listTitle" label="List Name (up to 20 characters)" />
                            </Box>
                        </form>
                    </FormProvider>
                    : null}
            </StyledDialogContent>
            <ModalFooterWithSave formID={ModalName.NewList} >
                {modalInfo.requestToAddID && <ModalBackButton modalFlowKey={ModalFlow.List}/>}
            </ModalFooterWithSave>
        </>
    );

}
