
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel, Grid, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { merge } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, number, object } from "yup";
import { CurrencyEnum, MenuItemEnum, ModalFlow, ModalName, OrderMethod, OrderType, ParentCategoryEnum, RoleDefinition, SvgNames } from '../../Constants/app-enums';
import { selectModalLoading, selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LoggedInUser, Modal, NavigationInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import { StyledControlledAutoCompleteInput, StyledControlledFormInput, StyledControlledRadioButton, StyledControlledRadioGroup } from '../../Utility/custom-input-components';
import { AutocompleteOption, BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Product, Request, RequestItemViewModel } from '../../Utility/request-types';
import { getDisplayNameOfEnumValue, itemPost } from '../../Utility/root-function';
import { BoldTypography, GlobalForm, SmallTypography, StyledOutlineButton, StyledSectionOutlineButton, StyledWarning, SvgIcon } from '../../Utility/shared-components';
import Theme from '../../Utility/theme';
import { BatchLotBlock, SupplyDaysBlock, WarrantyBlock } from '../ItemCard/item-components';
import { ItemPriceInfoBlock } from '../ItemCard/price-tab';
import { globalClose, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';


const requestValidationSchema = object<Partial<Record<keyof AddItemFormData, AnySchema>>>({
    request: object<Partial<Record<keyof Request, AnySchema>>>({
        cost: number().min(0, "Cost field must be at least 0").required("Cost field is required"),
        pricePerUnitDollar: number(),
        pricePerUnitShekel: number(),
        unit: number().typeError("Amount must be a valid number").integer().min(1, "Unit field must be more than 0").required("Unit field is required"),
        //expectedSupplyDays: number().typeError("Supply Days field must be more than 0").integer().min(1, "Supply Days field must be more than 0").required("Supply Days field is required"),
        //warranty: number().nullable().integer().min(1, "Warranty field must be more than 0"),
        //batch: number().nullable().integer().min(1, "Batch/Lot field must be more than 0"),
        product: object<Partial<Record<keyof Product, AnySchema>>>({
            unitTypeID: number().typeError("Unit type was not selected").min(1, "Unit field is required.").required("Unit field is required."),
            subUnit: number().nullable().integer().min(1, "subUnit field must be more than 0"),
            subUnitTypeID: number().nullable().when('subUnit', {
                is: (val: number) => val > 0,
                then: number().typeError("Subunit type was not selected").min(1, "subUnitType field is required.").required("subUnitType field is required."),
                otherwise: number().nullable()
            }),
            subSubUnit: number().nullable().integer().min(1, "subSubUnit field must be more than 0"),
            subSubUnitTypeID: number().nullable().when('subSubUnit', {
                is: (val: number) => val > 0,
                then: number().typeError("Subsubunit type was not selected").min(1, "subSubUnitType field is required.").required("subSubUnitType field is required."),
                otherwise: number().nullable()
            }),
        })
    })
});


const requestDefaultValues = (tempData?: TempData<AddItemFormData>) => {
    //console.log("in request default values ")
    return ({
        request: {
            product: {
                unitTypeID: tempData?.request?.product?.unitTypeID ?? 0,
                subSubUnit: tempData?.request?.product?.subSubUnit ?? null,
                subUnit: tempData?.request?.product?.subUnit ?? null,
                subSubUnitTypeID: tempData?.request?.product?.subSubUnitTypeID ?? null,
                subUnitTypeID: tempData?.request?.product?.subUnitTypeID ?? null,
                orderType: OrderType.SingleOrder
            },
            currency: CurrencyEnum.NIS,
            exchangeRate: tempData?.request?.exchangeRate ?? 1,
            pricePerUnitDollar: 0,
            pricePerUnitShekel: 0,
            unit: tempData?.request?.unit ?? 0,
            includeVAT: tempData?.request?.includeVAT ?? true,
            cost: tempData?.request?.cost ?? 0,
            costDollar: tempData?.request?.costDollar ?? 0,
            expectedSupplyDate: moment().format("YYYY-MM-DD"),
            expectedSupplyDays: tempData?.request?.expectedSupplyDays ?? null,
            warranty: 0,
            warrantyEndDate: moment().format("YYYY-MM-DD"),
            batch: 0,
            batchExpiration: moment().format("YYYY-MM-DD"),
        },
        guid: tempData?.guid ?? ""
    } as TempData<AddItemFormData>)
}



export default function ReorderModal() {
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo

    function onSubmit(data: TempData<AddItemFormData>, e: any) {
        data.request.orderMethod = { descriptionEnum: e.nativeEvent.submitter.name as OrderMethod };

        // if (data.request.orderMethod.descriptionEnum == OrderMethod.RequestPriceQuote) {
        //     dispatch(setLoading(true))
        //     fetch("/Requests/RequestPriceQuote", {
        //         method: "POST",
        //         body: JSON.stringify(data),
        //         headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        //     }).then(response => {
        //         if (response.ok) { return response.json() }
        //         else { throw response.text() }
        //     }).then((result) => {
        //         batch(() => {
        //             dispatch(setLoading(false))
        //             dispatch(removeModal())
        //             dispatch(setTempData({}))
        //             dispatch(setGlobalSnack({ open: true, message: "Request for a price quote succeeded!" }))
        //         })

        //     })
        //         .catch(err => {
        //             Promise.resolve(err).then(text => {
        //                 batch(() => {
        //                     dispatch(setLoading(false))
        //                     dispatch(setTempData({}))
        //                     dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
        //                 })
        //             })
        //         })
        // }
        // else {
        //     batch(() => {
        //         dispatch(setModalLoading(true))
        //         dispatch(setTempData(JSON.parse(JSON.stringify(data))));
        //         dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.AdditionalRequestInfo }))
        //     })
        // }
        itemPost(e.nativeEvent.submitter.name as OrderMethod, data, dispatch);
    }


    return (
        <ReorderForm onSubmit={onSubmit} />

    );
}

type ReorderFormProps = {
    onSubmit: any,
}
export function ReorderForm(props: ReorderFormProps) {
    const { modalInfo, modalStep } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    console.log(modalInfo.ids)
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const { userRoles } = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const [error, setError] = useState<CustomError>(new CustomError());
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<AddItemFormData>
    const methods = useForm<TempData<AddItemFormData>>({
        resolver: async (data, context, options) => {
            // you can debug your validation schema here
            // console.log('formData', data)
            // console.log('validation result', await yupResolver(requestValidationSchema)(data, context, options))
            return yupResolver(requestValidationSchema)(data, context, options)
        }, defaultValues: requestDefaultValues(tempData.request ? tempData : undefined)
    });
    const [viewModel, setViewModel] = useState({} as RequestItemViewModel);
    const dispatch = useDispatch();
    const guid = useSelector<ApplicationState>(state => state.tempData.present.guid) as string;

    useEffect(() => {
        methods.setValue("guid", guid)
    }, [guid]);

    const request = methods.watch("request")
    useEffect(() => {
        var url = "/Requests/ReorderModal?id=" + modalInfo.ids[0] + "&modalName=" + modalStep;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response;
            })
            .then((result: RequestItemViewModel) => {
                let requestResult = result.request ?? {} as Request

                //console.log(request)
                setViewModel(result);
                //console.log(merge(request, requestResult))
                if (tempData.request != null) {
                    methods.setValue("request", tempData.request);
                }
                else {
                    methods.setValue("request", merge(request, requestResult));
                }
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log(text)
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);

    var disableOrder = !userRoles.find(ur => ur.mainRole == MenuItemEnum.Requests)?.subRoles.includes(RoleDefinition.RequestsOrderItem)
    const pricePerUnitShekel = methods.watch("request.pricePerUnitShekel")
    const pricePerUnitDollar = methods.watch("request.pricePerUnitDollar")
    const exchangeRate = methods.watch("request.exchangeRate")
    const unit = methods.watch("request.unit")

    const vendorCurrency = methods.watch("request.product.vendor.country.currency.currencyName")
    const currency = methods.watch("request.currency")


    useEffect(() => {
        switch (currency) {
            case CurrencyEnum.NIS:
                if (pricePerUnitShekel > 0) {
                    methods.setValue("request.pricePerUnitDollar", (pricePerUnitShekel / exchangeRate))
                    methods.setValue("request.costDollar", ((pricePerUnitShekel / exchangeRate) * unit))
                }
                break;
            case CurrencyEnum.USD:
                if (pricePerUnitDollar > 0) {
                    methods.setValue("request.pricePerUnitShekel", (pricePerUnitDollar * exchangeRate))
                    methods.setValue("request.cost", ((pricePerUnitDollar * exchangeRate) * unit))
                }
                break;
        }
    }, [exchangeRate])

    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={viewModel?.request?.product?.productName ?? ""} errorMessage={error?.message ?? ""} />
                    {error.showBody &&
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.Reorder} onSubmit={(data: any, e: any) => props.onSubmit(data, e)} methods={methods}>
                                <Grid container direction="row" justifyContent={"end"} spacing={2} alignItems="center">
                                    {(vendorCurrency != CurrencyEnum.None && currency != vendorCurrency) &&
                                        <Grid item xs={8} justifyContent={"start"}>
                                            <BoldTypography color={Theme.palette.error.main}>{`Warning: Default Currency for Selected Vendor is ${vendorCurrency}`}</BoldTypography>
                                        </Grid>}
                                    <Grid item xs={2}>
                                        <StyledControlledAutoCompleteInput
                                            label="Currency"
                                            name='request.currency'
                                            options={Object.values(CurrencyEnum).filter(c => c != CurrencyEnum.None).map(c => { return { text: c.toString(), value: c } as AutocompleteOption }) ?? []}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <StyledControlledFormInput name="request.exchangeRate" label="Exchange Rate" type="number" />
                                    </Grid>
                                </Grid>
                                <ItemPriceInfoBlock viewModel={viewModel} />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <StyledControlledRadioGroup name="request.includeVAT">
                                            <FormControlLabel sx={{ margin: "0" }} key={"includeVAT"} value={true}
                                                control={<StyledControlledRadioButton />}
                                                label={<Typography>Include VAT</Typography>} />
                                            <FormControlLabel sx={{ margin: "0" }} key={"noVAT"} value={false}
                                                control={<StyledControlledRadioButton />}
                                                label={<Typography>No VAT</Typography>} />
                                        </StyledControlledRadioGroup>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1.2} marginTop="auto" marginBottom={"1rem"}>
                                    <Grid item xs={4} sx={{ marginTop: "1rem" }} />

                                    <OrderButton disabled={disableOrder} orderMethod={OrderMethod.OrderNow} description="If you want to process a single order immediately" />


                                    <OrderButton orderMethod={OrderMethod.AddToCart} description="If you want to order multiple items" />


                                    <Grid item xs={3.5} sx={{ marginTop: "1rem" }}>
                                        <StyledOutlineButton onClick={() => { globalClose(true, dispatch) }}>Cancel</StyledOutlineButton>
                                    </Grid>
                                    <Grid item xs={0.5} sx={{ marginTop: "1rem" }} />

                                    <OrderButton disabled={disableOrder} orderMethod={OrderMethod.AlreadyPurchased} description="Update the purchase of an item" />

                                    <OrderButton orderMethod={OrderMethod.RequestItem}
                                        warning={viewModel.hasQuote ? "This Product Already Has A Quote" : undefined}
                                        description="Ask The Secretary To Get A Price Quote" />
                                </Grid>
                            </GlobalForm>
                        </StyledDialogContent>
                    }
                </>
            }
        </>
    )
}
function OrderButton(props: { orderMethod: OrderMethod, description: string, warning?: string, disabled?: boolean }) {
    return (<Grid item xs={4} sx={{ marginTop: "1rem", marginBottom:"0.5rem" }}>
          <Box flexDirection={"row"} display={"flex"} width="100%">
            <Stack flexGrow={1}  sx={{marginRight:"0.25rem" }}>
                <StyledSectionOutlineButton disabled={props.disabled} type="submit" name={props.orderMethod}>{getDisplayNameOfEnumValue(props.orderMethod)}</StyledSectionOutlineButton>
                {props.warning && <StyledWarning textAlign={"center"}>{props.warning}</StyledWarning>}
                </Stack>
            <Tooltip title={props.description}><Box paddingTop={"0.5rem"} data-testid={"tooltip" + props.orderMethod}><SvgIcon name={SvgNames.Info} className="icon-gray" height={1.5} /></Box></Tooltip>
    </Box>
    </Grid >)
}

