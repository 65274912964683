import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useFieldArray, UseFieldArrayReturn, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { AnySchema, array, number, object, string } from 'yup';
import { ModalName, SvgNames } from '../Constants/app-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledAutoCompleteInput, StyledControlledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, AutocompleteOption, CustomError } from '../Utility/general-types';
import { CompanyAccount, CreditCard } from '../Utility/request-types';
import { GlobalForm, StyledHoverOutlineButton, SvgIcon } from '../Utility/shared-components';



export default function AddCreditCardModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [error, setError] = useState<CustomError>(new CustomError());
    const [companyAccounts, setCompanyAccounts] = useState<CompanyAccount[]>([]);

    const validationSchema = object<Partial<Record<keyof CreditCard, AnySchema>>>({
        companyAccountID: number().moreThan(0, "Bank Account is required").required("Bank Account is required"),
        cardNumber: string().length(4, "4 digits required").required("Last 4 digits is required")
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        mode: "onChange",
        resolver,
        defaultValues: {
            companyAccountID: 0,
            cardNumber: ""
        } as CreditCard,
    }
    );

    useEffect(() => {
        var url = "/CreditCards/AddCreditCardModal";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: CompanyAccount[]) => {
                setCompanyAccounts(result);

                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, []);

    function onSubmit(data: CreditCard, e: any) {
        var url = '/CreditCards/AddCreditCardModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.text() }
                throw response.text();
            })
            .then((res: string) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Credit Card Added", severity: "success" }))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Add Credit Card"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddCreditCard} onSubmit={onSubmit} methods={methods}>
                                <Stack spacing={2} alignItems="center" overflow={"scroll"} height="100%">
                                    <StyledControlledAutoCompleteInput name="companyAccountID" label="Bank Account"
                                        options={companyAccounts.map(ca => { return { value: ca.companyAccountID, text: ca.companyBankName } as AutocompleteOption })} />
                                    <StyledControlledFormInput name="cardNumber" label="Last 4 digits of the credit card" />
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddCreditCard} />
                    </>
                    }
                </>
            }
        </>
    )
}

