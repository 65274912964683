import { ToggleButtonGroup } from "@mui/material";
import { styled } from '@mui/material/styles';



const StyledToggleButtonGroup = styled(ToggleButtonGroup) ({
    '& .MuiToggleButtonGroup-grouped': {
        padding: ".35rem 2rem",
        margin: "0 2rem",
        border: 0,
        '&:not(:first-of-type)': {
            borderRadius: "1.188rem",
            textTransform: "none",
            color: "black"
        },
        '&:first-of-type': {
            borderRadius: "1.188rem",
            textTransform: "none",
            color: "black"
        },
    },
})

export default StyledToggleButtonGroup;
