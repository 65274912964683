import IndexTableDataByGroup from "./index-table-data-by-group"
import IndexTablePageHeaders from "./index-table-page-headers"
import IndexTableReduxStore from "./index-table-use-effects"

function IndexTableByGroup(){

    return (
    <>
        <IndexTablePageHeaders />
        <IndexTableDataByGroup key="indextabldatabygroup" />
        <IndexTableReduxStore />
    </>
    )
} 
export function RequestsCart (){
    return (
        <IndexTableByGroup/>
    )
}

export function LabManageOrders() {
    return (
        <IndexTableByGroup />
    )
}
export function LabManagePendingOrders() {
    return (
        <IndexTableByGroup />
    )
}
export function RequestCartPendingOrders() {
    return (
        <IndexTableByGroup />
    )
}
export function LabManageQuotes() {
    return (
        <IndexTableByGroup />
    )
}

export function MonthlyPayments() {
    return (
        <IndexTableByGroup key="monthlypayments"/>
    )
}

export function PayNow() {
    return (
        <IndexTableByGroup />
    )
}

export function PayLater() {
    return (
        <IndexTableByGroup />
    )
}

export function Installments() {
    return (
        <IndexTableByGroup />
    )
}
export function DidntArrive() {
    return (
        <IndexTableByGroup />
    )
}
export function PartialDelivery() {
    return (
        <IndexTableByGroup />
    )
}
export function NoInvoice() {
    return (
        <IndexTableByGroup />
    )
}
export function ForClarification() {
    return (
        <IndexTableByGroup />
    )
}
export function MissingPaymentDetails() {
    return (
        <IndexTableByGroup />
    )
}



