import { Grid, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { ModalFlow, ModalName, SvgNames } from "../Constants/app-enums";
import { setModalLoading, setModal } from "../ReduxRelatedUtils/modals-reducer";
import { Modal } from "../ReduxRelatedUtils/redux-types";
import { LocationModalInfo } from "../Utility/general-types";
import { SmallTypography, StyledLocationBox, SvgIcon, TinyTypography } from "../Utility/shared-components";
import { VisualItem } from "./locations-types";

export function LocationVisualItem(props: { item: VisualItem, width: number, index:number }) {

    const dispatch = useDispatch()
    return (
        props.width > 1 ?
            <StyledLocationBox  borderLeft={((props.index % props.width) == 0)?  theme=>"solid 1px " + theme.palette.grey[400] :"none"} sx={{ aspectRatio: "1/1", backgroundColor: props.item.containsItems ? "#eff4f8" : "white", padding: "0.5rem", justifyContent: "end" }} item xs={1} key={props.item.locationInstanceID}>
                <TinyTypography color={(theme) => props.item.containsItems ? theme.palette.primary.main : theme.palette.grey[400]} >{props.item.title}</TinyTypography>
            </StyledLocationBox>
            :
            <StyledLocationBox borderLeft={theme=>"solid 1px " + theme.palette.grey[400]}
                onClick={() => {
                    if(props.item.containsItems){
                    dispatch(setModalLoading(true))
                    dispatch(setModal({ modalFlow: ModalFlow.LocationZoom, modalStep: ModalName.LocationRequests, modalInfo: { parentLocationID: props.item.locationInstanceID } } as Modal<LocationModalInfo>))
                    }
                }}
                sx={{ backgroundColor: props.item.containsItems ? "#eff4f8" : "white", minHeight: "5rem", padding: "1rem", cursor:props.item.containsItems ? "pointer" : "inherit",  maxHeight:"5rem" }} item xs={1} key={props.item.locationInstanceID}>
                <Stack spacing={1}>
                    <Typography>{props.item.title}</Typography>
                    <Stack direction="row" spacing={0.5} alignContent={"center"}>
                        <SvgIcon name={SvgNames.StorageOccupancy} height={1} className="icon-gray" />
                        <TinyTypography color={(theme) => theme.palette.grey[400]}>{props.item.subTitle}</TinyTypography></Stack>
                </Stack>
            </StyledLocationBox>
    )



}