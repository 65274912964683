import { yupResolver } from '@hookform/resolvers/yup';
import { InstallMobile } from '@mui/icons-material';
import { FormControl, Grid, MenuItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import { push } from 'connected-react-router';
import moment from 'moment';
import { MuiChipsInput } from 'mui-chips-input';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, boolean, number, object } from 'yup';
import { AddItemResult, CurrencyEnum, InfoPopupName, InstallmentDivisionType, ModalFlow, ModalName, OrderMethod, SetPaymentDatesType, SidebarEnum } from '../../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setInfoPopup } from '../../ReduxRelatedUtils/infoPopupReducer';
import { setLoading, setShowConfirmExit } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading, setModalStep } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal, ModalInfo, NavigationInfo, TempData } from '../../ReduxRelatedUtils/redux-types';
import { setTempData } from '../../ReduxRelatedUtils/temp-data-reducer';
import viewmodelReducer from '../../ReduxRelatedUtils/viewmodel-reducer';
import { AddItemFormData } from '../../Shared/AddCardComponents/add-types';
import { StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledAutoCompleteInput, StyledCurrencyFormInput } from '../../Utility/custom-input-components';
import { AutocompleteOption, BasicModalInfo, ConfigureInstallmentsModalInfo, CustomError, SelectListItem } from '../../Utility/general-types';
import { ParentRequest, Payment, PaymentStatus } from '../../Utility/request-types';
import { extractErrorValues } from '../../Utility/root-function';
import { GlobalForm, SectionOutlinedTableButton, StyledError, StyledOutlineButton, StyledSectionOutlineButton } from '../../Utility/shared-components';
import { ConfigureInstallmentsModal, ConfigureInstallmentsModalFormData, ConfigureInstallmentsModalViewModel } from './configure-installments-modal';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';

export type TermsViewModel = {
    parentRequest: ParentRequest,
    termsList: PaymentStatus[],
    sum: number,
    vat: number,
    currency: CurrencyEnum,
    exchangeRate: number,
    addresses: AutocompleteOption[],
    defaultShippingAddress:number
}
export type TermsFormData = {
    selectedTerm: number,
    shipping: number,
    shippingDollar: number,
    payments: Payment[],
    setPaymentDatesType: SetPaymentDatesType,
    installmentDate: string,
    emailAddresses: string[],
    requestIDs: string[]
    addItemFormData: AddItemFormData,
    noteToSupplier: string,
    emailSubject: string,
    emailContent: string,
    orderPDF: any,
    installments: number,
    selectedShippingAddress:number,
    installmentsConfigured: boolean, divisionType:InstallmentDivisionType
}
export default function TermsModal() {
    const dispatch = useDispatch();
    const fromBackButton = (useSelector<ApplicationState>(state => state.modalWithLoading.modal.future) as Modal<ModalInfo>[]).length > 0

    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo

    function onSubmit(data: TermsFormData, e: any) {
        var url = '/Requests/TermsModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((res: { result: AddItemResult }) => {
                dispatch(setLoading(false))
                switch (res.result) {
                    case AddItemResult.Ordered:
                        batch(() => {

                            dispatch(removeModal())
                            dispatch(setGlobalSnack({ open: true, message: "Item Ordered!" }))
                            if (navigationInfo.sidebarType == SidebarEnum.Add) {
                                dispatch(setShowConfirmExit(false))
                                dispatch(push("/RequestsList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))
                            }
                            else {
                                dispatch(setReloadIndex(true))
                            }   
                        })

                        break;
                    case AddItemResult.SendToComfirmEmail:
                        dispatch(setModalLoading(true))
                        dispatch(setTempData(data))
                        fromBackButton ?
                            dispatch({ type: "redoModals" }) :
                            dispatch(setModalStep({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.ConfirmEmail }))
                        break;
                    case AddItemResult.OutOfBudget:
                        batch(() => {
                            dispatch(removeModal())
                            dispatch(setGlobalSnack({ open: true, message: "Item is out of budget!", severity: "warning" }))                                //redirect to request list
                            if (navigationInfo.sidebarType == SidebarEnum.Add) {
                                dispatch(push("/RequestsList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.List } as NavigationInfo)))
                            }
                            else {
                                dispatch(setReloadIndex(true))
                            }
                        })

                        break;
                }

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.errorMessage, severity: "error" }))
                })
            })
    }




    return (
        <TermsModalForm onSubmit={onSubmit} fromBackButton={fromBackButton} />
    );
}

type TermsModalFormProps = {
    onSubmit: any,
    fromBackButton: boolean
}

export function TermsModalForm(props: TermsModalFormProps) {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo, modalStep } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<AddItemFormData | TermsFormData>
    const [viewModel, setViewModel] = useState({} as TermsViewModel);

    const singleRequest = (tempData as TempData<AddItemFormData>).request || (tempData as TempData<TermsFormData>).addItemFormData?.request
    const existingRequestInTempData = singleRequest != null
    const currency = existingRequestInTempData ? singleRequest?.currency : viewModel.currency;
    const exchangeRate = existingRequestInTempData ? singleRequest?.exchangeRate : viewModel.exchangeRate;

    const currencySymbol = currency == CurrencyEnum.NIS ? '₪' : '$'

    const validationSchema = object<Partial<Record<keyof TermsFormData, AnySchema>>>({
        selectedTerm: number().required("Terms is required"),
        selectedShippingAddress : number().moreThan(0,"Shipping Address is required").required("Shipping Address is required"),
        shipping: number().min(0, "Shipping must be at least 0"),
        installmentsConfigured: boolean().when("selectedTerm", {is:5, then: boolean().isTrue("Installments must be configured")}),
        installments: number().when("selectedTerm", {is:5 , then:number().required("Installment field is required").min(1, "Installments minimum is 1")})
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues:
            {
                installmentDate: (tempData as TempData<TermsFormData>).installmentDate ?? moment().format("YYYY-MM-DD"),
                payments: (tempData as TempData<TermsFormData>).payments ?? [],
                setPaymentDatesType: (tempData as TempData<TermsFormData>).setPaymentDatesType ?? SetPaymentDatesType.None,
                selectedTerm: (tempData as TempData<TermsFormData>).selectedTerm ?? 2,
                shipping: (tempData as TempData<TermsFormData>).shipping ?? 0,
                shippingDollar: ((tempData as TempData<TermsFormData>).shipping ?? 0) / exchangeRate,
                requestIDs: modalInfo.ids,
                addItemFormData: (tempData as TempData<TermsFormData>).addItemFormData || (tempData as TempData<AddItemFormData>),
                guid: tempData.guid,
                noteToSupplier: (tempData as TempData<TermsFormData>).noteToSupplier ?? "",
                selectedShippingAddress: (tempData as TempData<TermsFormData>).selectedShippingAddress ?? 0,
                orderPDF: "",
                installments:(tempData as TempData<TermsFormData>).installments??1,
                divisionType: (tempData as TempData<TermsFormData>).divisionType ?? InstallmentDivisionType.SetPayments,
                installmentsConfigured:((tempData as TempData<TermsFormData>).selectedTerm ?? 2) !=5
            } as TempData<TermsFormData>
    }
    );
    const selectedTerm = methods.watch("selectedTerm")
    const shipping = methods.watch("shipping")
    const shippingDollar = methods.watch("shippingDollar")
    const payments = methods.watch("payments")
    const setPaymentDatesType = methods.watch("setPaymentDatesType")
    const divisionType = methods.watch("divisionType")
    const installments = methods.watch("installments")
    useEffect(() => {
        var url = "/Requests/TermsModal?IDs=" + modalInfo.ids;
        if (singleRequest?.product?.vendorID != null && singleRequest?.product?.vendorID != 0) { url += "&vendorID=" + singleRequest?.product.vendorID }
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: TermsViewModel) => {
                setViewModel(result);
                methods.setValue("selectedShippingAddress", result.defaultShippingAddress)
                dispatch(setModalLoading(false))

            })
            .catch(err => {
                console.log("in error")
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })


    }, []);

    useEffect(() => {
        if (currency == CurrencyEnum.NIS) {
            methods.setValue("shippingDollar", shipping / exchangeRate)
        }
        if (selectedTerm == 5  && modalInfo.ids.length==1) {

            methods.setValue("installmentsConfigured", false)
        }

    }, [shipping])
    useEffect(() => {
        methods.setValue("installments", 1);
        methods.setValue("payments", [])
        if (selectedTerm == 5 && modalInfo.ids.length==1) {
            methods.setValue("installmentsConfigured", false)
        }

    }, [selectedTerm])
    useEffect(() => {
        if (currency == CurrencyEnum.USD) {
            methods.setValue("shipping", shippingDollar * exchangeRate)
        }
    }, [shippingDollar])

    function getSum() {
        return existingRequestInTempData ?
            currency == CurrencyEnum.NIS ? singleRequest.cost : (singleRequest.cost / singleRequest.exchangeRate) :
            viewModel.sum;
    }
    function getVAT() {
        return existingRequestInTempData ?
            (String(singleRequest.includeVAT)=="true" ?
                (currency == CurrencyEnum.NIS ? singleRequest.cost * 0.17 : ((singleRequest.cost / singleRequest.exchangeRate) * 0.17)) : 0)
            : viewModel.vat;
    }
    function getTotal() {
        return existingRequestInTempData ?
            (String(singleRequest.includeVAT)=="true" ?
                (currency == CurrencyEnum.NIS ? singleRequest.cost * 1.17 : ((singleRequest.cost / singleRequest.exchangeRate) * 1.17)) : getSum())
            : viewModel.vat + viewModel.sum;
    }

    function saveConfigureInstallments(formData: ConfigureInstallmentsModalFormData) {
        methods.setValue("payments", formData.payments);
        methods.setValue("setPaymentDatesType", formData.setPaymentDateType);
        methods.setValue("installments", formData.installments);
        methods.setValue("installmentsConfigured", true)
        methods.setValue("divisionType", String(formData.isSetPayment)=="true"?InstallmentDivisionType.SetPayments: InstallmentDivisionType.VariedPayments);
    }
    console.log(singleRequest)
    return (<>
        {loading ?
            <ModalSkeleton />
            :
            <>
                <ModalHeader headerText={"Terms & Shipping"} errorMessage={error.message} />
                {error.showBody ? <>
                    <StyledDialogContent>
                        <GlobalForm methods={methods} formID={ModalName.Terms} onSubmit={props.onSubmit}>
                            <Stack direction="column" spacing={2}>
                                <Grid container spacing={2}>
                                    {methods.formState.errors.installmentsConfigured?.message &&
                                        <Grid item xs={12}>
                                            <StyledError>{methods.formState.errors.installmentsConfigured.message}</StyledError>
                                        </Grid>
                                    }
                                    <Grid item xs={3}>
                                        <StyledControlledAutoCompleteInput
                                            label="Terms"
                                            name='selectedTerm'
                                            options={viewModel?.termsList.map(t => { return { text: t.paymentStatusDescription, value: t.paymentStatusID } as AutocompleteOption }) ?? []}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {currency == CurrencyEnum.NIS ? <StyledCurrencyFormInput label="Shipping" name='shipping' currency="₪" /> :
                                            <StyledCurrencyFormInput label="Shipping" name='shippingDollar' currency="$" />
                                        }
                                    </Grid>
                                    {modalInfo.ids.length == 1 && selectedTerm == 5 && <Grid item xs={4}> <StyledSectionOutlineButton sx={{ marginTop: "1rem" }}
                                        onClick={() => dispatch(setInfoPopup({
                                            infoPopupName: InfoPopupName.ConfigureInstallments, saveConfigureInstallments: saveConfigureInstallments, id: modalInfo.ids[0].toString(),
                                            comingFromTerms: true, viewModel: {
                                                request: singleRequest ?? {
                                                    exchangeRate: viewModel.exchangeRate, currency: viewModel.currency,
                                             
                                                }, divisionType: divisionType,
                                                setPaymentDateType:  setPaymentDatesType,
                                                fullCost: (Number(getTotal()) + Number(getVAT() != 0 ? shipping * 1.17 : shipping)), 
                                                installments: installments, payments: payments?.length>0?payments: [{ sum: (getSum() + getVAT()+ (getVAT() != 0 ? shipping * 1.17 : shipping)), paymentDate: moment().format("YYYY-MM-DD"), installmentNumber: 1 } as Payment]
                                            } as ConfigureInstallmentsModalViewModel
                                        } as ConfigureInstallmentsModalInfo))}  >Configure Installments</StyledSectionOutlineButton>      </Grid>
                                    }
                                    {selectedTerm == 5 && modalInfo.ids.length != 1 &&
                                        <>
                                            <Grid item xs={3}>
                                                <StyledControlledFormInput type="number" label="Installments" name="installments" />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <StyledControlledFormDateInput label="Installment Date" name="installmentDate" />
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                <StyledControlledFormInput label="Note To Supplier" name="noteToSupplier" />
                                <StyledControlledAutoCompleteInput options={viewModel.addresses} name="selectedShippingAddress" label="Shipping Address"/>
                                <Stack direction="row" spacing={2}>
                                    <StyledCurrencyFormInput disabled label="Sum" currency={currencySymbol}
                                        value={getSum()}
                                    />
                                    <StyledCurrencyFormInput disabled label="VAT" currency={currencySymbol}
                                        value={getVAT()}
                                    />
                                    <StyledCurrencyFormInput disabled label="Total + VAT" currency={currencySymbol}
                                        value={getTotal()} />
                                </Stack>
                            </Stack>

                        </GlobalForm>

                    </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.Terms} submitButtonText={singleRequest?.orderMethod.descriptionEnum == OrderMethod.AlreadyPurchased ? "Save Order" : "Next"} >
                        {existingRequestInTempData && <ModalBackButton undoTempData={(tempData as TermsFormData).addItemFormData != null} modalFlowKey={ModalFlow.AddItem} />}
                    </ModalFooterWithSave>
                </>
                    : null}
            </>
        }
    </>);
}