import { Box, Stack } from "@mui/system";
import { merge } from 'lodash';
import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { batch, useDispatch, useSelector } from "react-redux";
import { SvgNames } from "../Constants/app-enums";
import { CardTabsType, TabsName } from "../Constants/client-side-enums";
import { selectEditDrawerViewModelByID } from "../ReduxRelatedUtils/customConfigureStore";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../ReduxRelatedUtils/index-table-reducer";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { ApplicationState, TempData } from "../ReduxRelatedUtils/redux-types";
import { removeEditDrawerViewModel, setAllReloadEditModal, setEditDrawerViewModel, setReloadEditModal } from "../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { setTempData } from "../ReduxRelatedUtils/temp-data-reducer";
import { AddSupplierFormData, AddUserFormData, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { CardTabs, Tab } from "../Shared/AddCardComponents/card-tabs";
import EditCard, { EditCardContext, EditCardType } from "../Shared/edit-card";
import EditDrawerSkeleton from "../Shared/edit-drawer-skeleton";
import { EditDrawerTempData } from "../Utility/general-types";
import { IsEdittableContext, StyledFilledButton, StyledNoOutlineButton, SvgIcon } from "../Utility/shared-components";
import UserTabs, { userTabInfo } from "./user-card-tabs";
import UserHeader from "./user-header";
import { push } from "connected-react-router";
import queryString from 'query-string'

export function onSubmitUserEdit(data:AddUserFormData ,dispatch:Function, setSubmitSucceeded: Function, e:any, location:any){
    dispatch(setLoading(true))

    fetch("/Users/EditUser", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
    }).then(response => {
        if (response.ok) { return response.json() }
        else { throw response.text() }
    }).then((result) => {
        let newPathname = location.pathname.replace("/"+EditCardType.EditUser,"")
        if(e.nativeEvent.submitter.name=="exit"){
            batch(()=>{

                dispatch(removeEditDrawerViewModel({ id: data.employee.id }))
                dispatch(push(queryString.stringifyUrl({ url: newPathname, query: location.query })))
                dispatch(setError({showBody:true, message:""}))
           
            })
        }
     
        else{
            batch(() => {
                dispatch(setGlobalSnack({ open: true, message: "Saved User Changes" }))
            })
        }
        setSubmitSucceeded(true)

        batch(() => {
            dispatch(setAllReloadEditModal({ reloadEditModal: true }))
            dispatch(setLoading(false))
            dispatch(setReloadIndex(true));
        })

    })
        .catch(err => {
            Promise.resolve(err).then(text => {
                console.log("in submit error")

                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
        })
}


export default function EditUser() {
    const location = useSelector<ApplicationState>(state => state.router.location) as Location;
    const dispatch = useDispatch()
    const [submitSucceeded, setSubmitSucceeded] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    function onSubmit(data: AddUserFormData, e:any) {
        console.log("submit edit user")
        onSubmitUserEdit(data, dispatch, setSubmitSucceeded, e, location)
    }

    return (
        <EditCardContext.Provider value={{submitSucceeded:submitSucceeded, toggleSubmitSucceeded:setSubmitSucceeded, confirmModalOpen:confirmModalOpen, toggleConfirmModalOpen:setConfirmModalOpen}}>
             <EditCard editType={EditCardType.EditUser} onSubmit={(data, e) => onSubmit(data,e )} />
        </EditCardContext.Provider>
    )
}

export function EditUserInnerForm(props: { id: string }) {
    const [loading, setLoading] = useState<boolean>(true);
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.id??"")?.viewModel) as AddUserViewModel;
    const [tabs, setTabs] = useState<Tab[]>(userTabInfo)
    const reloadEditModal = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.id ?? 0)?.reloadEditModal) as boolean;


    //console.log(viewModel);
    const { control, formState, watch, getValues, reset, setValue, handleSubmit } = useFormContext<AddUserFormData>();
    const dispatch = useDispatch();
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<EditDrawerTempData>
    const isEdittableContext = useContext(IsEdittableContext);
    const employee = watch("employee")
    const employeeStatusID = watch("employee.employeeStatusID")


    useEffect(() => {
        setTabs(employeeStatusID == 4 ? userTabInfo.filter(t => t.name!= TabsName.WorkScope): userTabInfo);
    }, [employeeStatusID])

    useEffect(() => {
        dispatch(setReloadEditModal({ reloadEditModal: true, id: props.id ?? "0" }))
    }, [])

    useEffect(() => {
if(reloadEditModal){
        fetch("/Users/EditUser" + location.search, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: AddUserViewModel) => {
                dispatch(setEditDrawerViewModel({viewModel:result, id:props.id??"", reloadEditModal:false}));
                
                setValue("employee", merge(employee, result.employee));
                setValue("labs", result.userLabs)
                setValue("roles", result.roles)
                setLoading(false)
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text, showBody: false }))
                })
            })
        }
    }, [reloadEditModal])

    function discardChanges() {
        console.log("in discard changes")
        isEdittableContext.toggleIsEdittable(false)
        dispatch(setEditDrawerViewModel({viewModel:tempData.viewModel as AddUserViewModel, id:props.id, reloadEditModal:true}))
        reset(tempData.formData as AddUserFormData)
        dispatch(setTempData({}))
        dispatch(setGlobalSnack({ open: true, message: "Discarded User Changes" }))

    }
    function setEditable() {
        let formData = getValues()
        dispatch(setTempData({ formData: JSON.parse(JSON.stringify(formData)), viewModel: viewModel } as TempData<EditDrawerTempData>))
        isEdittableContext.toggleIsEdittable(true)
    }

    return (reloadEditModal || viewModel == undefined ? <EditDrawerSkeleton /> :
        <>
            <Box sx={{ padding: "3rem" }}>
                <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                    <UserHeader/>
                    <Stack spacing={1.5}>
                        {!isEdittableContext.isEdittable && <StyledNoOutlineButton endIcon={<SvgIcon name={SvgNames.Create} className="section-fill" height={1.5} />} onClick={setEditable}>
                            Edit User
                        </StyledNoOutlineButton>}
                        {isEdittableContext.isEdittable && 
                        <>
                        <StyledNoOutlineButton onClick={discardChanges}>Discard Edits</StyledNoOutlineButton>
                        <StyledFilledButton form={EditCardType.EditUser + employee.id} type="submit">Save Edits</StyledFilledButton>
                        </>
                        }
                    </Stack>                </Stack>
            </Box>
            <CardTabs key={CardTabsType.AddCard} cardTabType={CardTabsType.AddCard} selectedTab={0} tabs={tabs} >
                <UserTabs key={"usertabs"} tabs={tabs}/>
            </CardTabs>
        </>

    )
}
