import { Typography } from "@mui/material";
import { error } from "console";
import { batch, useDispatch, useSelector } from "react-redux";
import { ModalName } from "../Constants/app-enums";
import { selectModalLoading, selectModalPresent } from "../ReduxRelatedUtils/customConfigureStore";
import { ModalHeader, StyledDialogContent, ModalFooterWithSave } from "../Requests/Modals/modal-components";
import ModalSkeleton from "../Requests/Modals/modal-skeleton";
import { GlobalForm } from "../Utility/shared-components";
import { useEffect, useState } from "react";
import { CustomError, LocationModalInfo } from "../Utility/general-types";
import GlobalModal from "../Requests/Modals/global-modal";
import { ModalSizes } from "../Constants/client-side-enums";
import { useForm } from "react-hook-form";
import { removeModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, LastSelectedLocation, Modal } from "../ReduxRelatedUtils/redux-types";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../ReduxRelatedUtils/index-table-reducer";
import { setLastSelectedLocation } from "../ReduxRelatedUtils/selectedLocationReducer";



type BlockLocationFormData = {
    locationInstanceID: number;
}
type BlockLocationViewModel = {
    locationName: string;
    numRequestsInLocation: number;
    isCurrentlyBlocked: boolean;
    isBuilding: boolean;
}

export default function BlockLocationModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const [error, setError] = useState<CustomError>(new CustomError());
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<LocationModalInfo>;   
    const [viewModel, setViewModel] = useState<BlockLocationViewModel>() 
    const methods = useForm<BlockLocationFormData>({ mode: 'onChange' , defaultValues: {locationInstanceID:  modalInfo.locationID}});
 
    useEffect(() => {
        var url = "/Locations/BlockLocationModal?locationInstanceID=" + modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: BlockLocationViewModel) => {
                setViewModel(result);
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                console.log(err)
                Promise.resolve(err).then(text => {
                    setError({message:text.errorMessage, showBody:false})
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.locationID]);


    function onSubmit(data:BlockLocationFormData) {        
        var url = '/Locations/BlockLocationModal'
        let snackText = viewModel?.locationName + " has been blocked for future use";
        if(viewModel?.isCurrentlyBlocked) {
            snackText = viewModel?.locationName + " has been unblocked and is now available for use";
        }
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(result => {
                if (!result.ok) throw result.text()
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: snackText, severity: "success" }))
                    if(viewModel?.isBuilding) {
                        dispatch(setReloadIndex(true))
                    } else {
                        dispatch(setLastSelectedLocation({...modalInfo, reload:true} as LastSelectedLocation))                  
                    } 
             })
    
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text, showBody: true })
                    dispatch(setLoading(false))
                })
            })
    }
    let headerText = "Block For Future Use";

    let confirmText = "Confirm that you would like to block " +viewModel?.locationName + " for future use. Blocking this location will also block all locations within";
    let buttonText = "Block";
    if(viewModel?.isCurrentlyBlocked) {
        headerText = "Unblock Location";
        confirmText = "Confirm that you would like to unblock " +viewModel?.locationName + " and open it for use. Unblocking this location will also unblock all locations within";
        buttonText = "Unblock";
    } 
    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={headerText} errorMessage={error.message} />
                    {error.showBody ?<>
                    <StyledDialogContent>
                     <GlobalForm methods={methods} formID={ModalName.BlockLocation} onSubmit={onSubmit}>
                            {viewModel != undefined && viewModel?.numRequestsInLocation > 0 && 
                            <Typography>{`This location contains ${viewModel?.numRequestsInLocation} item(s)`}</Typography>}
                            <Typography>{confirmText}</Typography>
                    </GlobalForm>
                         
                    </StyledDialogContent>
                    <ModalFooterWithSave submitButtonText={buttonText} formID={ModalName.BlockLocation} />
                    </>
                    : null
                }
                </>
            }
        </>
    );
}