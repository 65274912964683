import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import ConfirmArchiveModal from '../Requests/Modals/confirm-archive-modal';
import GlobalModal from '../Requests/Modals/global-modal';
import AddBankAccountModal from './add-bank-account-modal';
import AddCreditCardModal from './add-credit-card-modal';



export default function AddPaymentMethodFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName

    // console.log("in add location flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.AddCreditCard:
                return (<AddCreditCardModal />)
            case ModalName.AddBankAccount:
                return (<AddBankAccountModal />)
            default: return null;
        }
    }

    return (
        <GlobalModal size={ModalSizes.xs} modalKey={modalStep}>
            <ModalContent />
        </GlobalModal>
    );

}
