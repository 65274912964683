import { DocumentCard } from "../Utility/general-types";
import { CommentTypeEnum, FolderNamesEnum, SvgNames } from "./app-enums";

export const documentsIcons = new Map<FolderNamesEnum, SvgNames>([
    [FolderNamesEnum.Quotes, SvgNames.CentarixOrderedTab], 
    [FolderNamesEnum.Orders, SvgNames.ChromeReaderMode],
    [FolderNamesEnum.Invoices, SvgNames.Book],
    [FolderNamesEnum.Shipments, SvgNames.LocalShipping],
    [FolderNamesEnum.Info, SvgNames.Info],
    [FolderNamesEnum.Pictures, SvgNames.CameraAlt],
    [FolderNamesEnum.S, SvgNames.ChromeReaderMode],
    [FolderNamesEnum.Map, SvgNames.ChromeReaderMode]
 ]);

 export const commentsIcons = new Map<CommentTypeEnum, {iconName:SvgNames, iconColor: string}>([
    [CommentTypeEnum.Warning, { iconName :SvgNames.ReportProblem, iconColor: "icon-red"}], 
    [CommentTypeEnum.Comment,{ iconName :SvgNames.Comment, iconColor: "icon-green"}]
 ]);

 export const yearStartedTimekeeper = 2021;