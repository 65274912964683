import { styled, TableCell, TableHead, TableRow, Typography, TypographyProps } from "@mui/material";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IndexColumnProperties, IndexColumnTypes, MenuItemEnum, RoleDefinition, SidebarEnum } from "../../Constants/app-enums";
import { selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { ApplicationState, CheckboxInfo, IndexTableCheckboxes, LoggedInUser } from "../../ReduxRelatedUtils/redux-types";
import ColumnOptionsPopover from "../../Requests/column-options-popover";
import { RowViewModel, StringWithInt } from "../../Utility/general-types";
import { CheckBoxColumn, RequestIndexViewModelByGroup } from "../../Utility/request-types";
import { SectionCheckbox, SmallTypography } from "../../Utility/shared-components";
import { setCheckboxInfo } from "../../ReduxRelatedUtils/index-table-reducer";

type HeaderProps = {
    groupByObject: StringWithInt,
    firstRequestOfGroup: RowViewModel,
    showCheckbox: boolean
}

const StyledGroupTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: "1.688rem",
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "left",
}));

export default function IndexTableHeadersByGroup({ firstRequestOfGroup, groupByObject, showCheckbox }: HeaderProps) {
    const dispatch = useDispatch()
    const { userRoles } = useSelector<ApplicationState>(state => state.userInfo) as LoggedInUser
    const { checkboxInfo } = useSelector<ApplicationState>(state => state.indexTableCheckboxes) as IndexTableCheckboxes

    function handleVendorCheckbox(e: any) {
        console.log("vendor checked: " + e.target.value)
        const checkboxes = JSON.parse(JSON.stringify(checkboxInfo)) as CheckboxInfo[]
        if (e.target.checked)//is being checked
        {
            checkboxes.map(c => c.groupID == e.target.value ? c.isChecked = true : c.isChecked = false)
            dispatch(setCheckboxInfo(checkboxes))
        }
        else {
            checkboxes.map(c => c.isChecked = false)
            dispatch(setCheckboxInfo(checkboxes))
        }

    }

    return (
        <TableHead >
            <TableRow sx={{ borderBottom: "none" }}>
                {showCheckbox &&
                    <TableCell colSpan={1}><SectionCheckbox data-testid={"vendorCheckbox" + groupByObject.int} key={"checkbox" + groupByObject.int} value={groupByObject.int} onChange={handleVendorCheckbox} checked={checkboxInfo?.filter(c => c.groupID == groupByObject.int && !c.isChecked).length == 0} />
                    </TableCell>}

                <TableCell colSpan={showCheckbox ? 2 : 3}>

                    <StyledGroupTitle key={groupByObject.int}>{groupByObject.string}</StyledGroupTitle>
                </TableCell>
                {firstRequestOfGroup.columns.map((col, i) =>
                    (col.title) &&
                    <TableCell key={col.title + i + col.width} width={`${col.width}%`} >
                        {col.headerProperties.containsDisplayOptions ?
                            <ColumnOptionsPopover columnDisplayOptions={col.headerProperties.columnDisplayOptions} title={col.title} />
                            :
                            <SmallTypography>{col.title}</SmallTypography>
                        }
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}

