import { configureStore, createSelector, createSlice } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import queryString from 'query-string';
import { combineReducers, PreloadedState } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import errorReducer from './errorReducer';
import { globalSnackReducer } from './globalSnackReducer';
import { accountingSelectsViewModelReducer, checkboxInfoReducer, columnDisplayFormatOptionsReducer, columnDisplayValueOptionsReducer, monthYearFiltersReducer, pageNumberReducer, reloadIndexReducer, requestsSearchViewModelReducer, selectedFiltersReducer, tabValueReducer } from './index-table-reducer';
import { infoPopupReducer } from './infoPopupReducer';
import isHomeReducer from './isHomeReducer';
import { loadingReducer, pageSkeletonReducer, showConfirmExitReducer } from './utilsReducer';
import { modalLoadingReducer, modalReducer } from './modals-reducer';
import { ApplicationState, NavigationInfo } from './redux-types';
import editDrawerViewModelReducer from './edit-drawer-viewmodel-reducer';
import { selectedLocationReducer } from './selectedLocationReducer';
import tempDataReducer from './temp-data-reducer';
import userInfoReducer, { isAuthenticatedReducer } from './userInfoReducer';
import viewmodelReducer from './viewmodel-reducer';
import { getPersistConfig } from 'redux-deep-persist';
import { Reducer } from 'react';

export default function customConfigureStore(history: History, initialState?: ApplicationState) {
   
    return setupStore(history, initialState);
}

const rootReducer = (history : History)=> {return combineReducers({
    router: connectRouter(history),
    viewModel: viewmodelReducer,
    modalWithLoading:combineReducers({
        modalLoading : modalLoadingReducer,
        modal : modalReducer
    }),
    indexTableObject: combineReducers({
        selectedRequestFilters: selectedFiltersReducer,
        tabValue: tabValueReducer,
        pageNumber: pageNumberReducer,
        selectedDisplayValueOptions: columnDisplayValueOptionsReducer,
        selectedDisplayFormatOptions: columnDisplayFormatOptionsReducer,
        accountingSelectsViewModel: accountingSelectsViewModelReducer,
        monthYearFilters: monthYearFiltersReducer,
        requestsSearchViewModel: requestsSearchViewModelReducer
    }), 
    tempData: tempDataReducer,
    reloadIndex: reloadIndexReducer,
    indexTableCheckboxes: checkboxInfoReducer,
    userInfo: userInfoReducer,
    isHome: isHomeReducer,
    isAuthenticated:isAuthenticatedReducer,
    error:errorReducer,
    loading: loadingReducer,
    showPageSkeleton: pageSkeletonReducer,
    showConfirmExit:showConfirmExitReducer,
    snackState: globalSnackReducer,
    lastSelectedLocation: selectedLocationReducer,
    editDrawerViewModel: editDrawerViewModelReducer,
    infoPopup: infoPopupReducer
})}
  








// const rootReducer = (history:History) => {
//     // if (action.type === 'reset/resetState') {
//     //     const {isAuthenticated, isHome, userInfo, router} = state
//     //     state = {isAuthenticated, isHome, userInfo, router} as ApplicationState  
//     // }
//     return appReducer(history);
// } 

export type RootState = ReturnType<typeof rootReducer>

export function setupStore (history:History<any>, preloadedState?: PreloadedState<RootState>){

    const persistConfig = getPersistConfig({
        key: "rootV13",
        storage,
        whitelist:["isAuthenticated", "userInfo", "indexTableObject.tabValue", "indexTableObject.requestsSearchViewModel"],
        rootReducer: rootReducer(history)
    });

    return configureStore({
    middleware: [thunk, routerMiddleware(history)],
    reducer: persistReducer(persistConfig, rootReducer(history)),
    preloadedState: preloadedState,
})}


export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

const applicationStateSlice = createSlice({
    name: 'reset',
    initialState: {} as ApplicationState ,
    reducers: {
        resetState(state:ApplicationState) {
            return state
        }
       
    }
})

export const { resetState } = applicationStateSlice.actions
export const applicationStateReducer = applicationStateSlice.reducer


import {isEqual} from 'lodash';
const getQueryParams = (() => {
    let cachedParams = null as any;
  
    return (state:ApplicationState) => {
      const queryParams = queryString.parse(state.router.location.search);
  
      // Check if the property values have changed
      if (isEqual(queryParams, cachedParams)) {
        // If the values are the same, return the cached object
        return cachedParams;
      }
  
      // If the values have changed, update the cached object and return it
      cachedParams = queryParams;
      return queryParams;
    };
  })();
export const selectNavigationInfo = createSelector(
    [getQueryParams],
    (params) =>{return {pageType: params?.pageType, sidebarType:params?.sidebarType , sectionType:params?.sectionType, id:params?.id} as NavigationInfo}
);

export const selectModalWithLoading = (state:ApplicationState) => state.modalWithLoading


export const selectEditDrawerViewModelByID= createSelector(
    [(state:ApplicationState) => state.editDrawerViewModel,  (state, id) => id],
    (editDrawer, id) =>  {
        console.log(id);
        console.log(editDrawer);
        return editDrawer.find(e=>e.id ==id)}
  )

export const selectModalPresent= createSelector(
    [selectModalWithLoading],
    (modalWithLoading) =>  modalWithLoading?.modal?.present
  )

  export const selectModalFlow= createSelector(
    [selectModalWithLoading],
    (modalWithLoading) =>  modalWithLoading?.modal?.present?.modalFlow
  )

  export const selectModalLoading= createSelector(
    [selectModalWithLoading],
    (modalWithLoading) =>  modalWithLoading?.modalLoading
  )


