
import Typography from '@mui/material/Typography';
import { ControlledCheckbox } from '../../Utility/custom-input-components';
import { AutocompleteOption, BasicModalInfo, CustomError, SelectListItem } from '../../Utility/general-types';

import { useController, useForm } from 'react-hook-form';
import { ModalFlow, ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { ApplicationState, Modal, TempData } from '../../ReduxRelatedUtils/redux-types';
import { ModalBackButton, ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormControlLabel, Grid, Popover, TextareaAutosize } from '@mui/material';
import { Stack } from '@mui/system';
import PopupState, { bindPopover, bindToggle } from 'material-ui-popup-state';
import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, array, number, object } from 'yup';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import LocationDDLs from '../../Shared/select-location-view';
import { GlobalForm } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ReceiveItemFormData } from './receive-modal';
import { removeEditDrawerViewModel, setLocationViewModel, setEditDrawerViewModel } from '../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer';



export type SelectLocationViewModel = {
    buildings?: AutocompleteOption[]
    floors?: AutocompleteOption[],
    rooms?:AutocompleteOption[],
    storageTemperatures?: AutocompleteOption[],
    storageTypes?: AutocompleteOption[],
    storageUnits?:AutocompleteOption[]
    childrenStorageUnits?: ChildStorageSelectList[],
    visualState?:VisualState,
    error?:{string:string, bool:boolean}
    isArchived: boolean,
    isQuartzy: boolean,
    archivedRoomName: string,
}
export type VisualState ={
    showVisual: boolean, parentID: number 
}
export type SelectLocationFormData = {
    isTemporary: boolean,
    selectedBuilding: number,
    selectedFloor: number,
    selectedRoom: number,
    selectedTemperature: number,
    selectedParentStorageType: number,
    selectedStorageUnit: number
    selectedChildren: number[],
    selectedLocations: number[],
    isQuartzy: boolean
}


export type ChildStorageSelectList = {
    name: string,
    list: SelectListItem[]
}

const validationSchema = object<Partial<Record<keyof ReceiveItemFormData, AnySchema>>>({
    selectedLocations: array().when("isTemporary", {
        is: false,
        then: array().min(1, "Location has not been selected"),
        otherwise: array().nullable()
    }),
    selectedFloor: number().when("isTemporary", {
        is: true,
        then: number().min(1, "Floor field is required").required("Floor field is required"),
        otherwise: number
    }),
    selectedTemperature: number().min(1, "Temperature field is required").required("Temperature field is required"),
})

export default function ReceiveLocationModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state))
    const tempData = useSelector<ApplicationState>(state => state.tempData.present) as TempData<ReceiveItemFormData>
    const { modalInfo } = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;
    const [error, setError] = useState<CustomError>(new CustomError());

    
    const methods = useForm<SelectLocationFormData>({
        resolver: yupResolver(validationSchema),
        defaultValues:{
            selectedFloor: 0,
            selectedBuilding: 0,
            selectedParentStorageType: 0,
            selectedStorageUnit: 0,
            selectedTemperature: 0,
            selectedRoom: 0,
            selectedLocations: [] as number[],
            isTemporary: false,
        }
    });


    useEffect(() => {
        
       dispatch(removeEditDrawerViewModel({id:modalInfo.ids[0]}))
        var url = "/Requests/FillSelectedLocationsViewModel";
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response;
            })
            .then((result: SelectLocationViewModel) => {
                batch(()=>{
                    dispatch(setLocationViewModel({viewModel:{...result, visualState:{showVisual: false, parentID: 0}}, id:modalInfo.ids[0]}));
                    dispatch(setModalLoading(false))
                })
    
            })
            .catch(err => {
                console.log(err)
            })        
    }, []);
   

   

    function onSubmit(data: SelectLocationFormData, e: any) {
        console.log(tempData)
        var combinedData = {...tempData, ...data} as ReceiveItemFormData
        console.log(combinedData)
        var url = '/Requests/ReceiveModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(combinedData),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: { result: any }) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: "Request Received", severity: "success" }))
                    dispatch(setReloadIndex(true))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Receive Item" errorMessage={error?.message ?? ""} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.ReceiveItem} onSubmit={(data: any, e: any) => onSubmit(data, e)} methods={methods}>
                                    <Box height="100%">
                                    <LocationDDLs requestID={modalInfo.ids[0]} showTemporary/>
                                    </Box>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.ReceiveItem} submitButtonText="Receive" ><ModalBackButton modalFlowKey={ModalFlow.ReceiveItem}/></ModalFooterWithSave>
                    </>
                    }
                </>
            }
</>
    );
}



