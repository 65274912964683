﻿import { useEffect } from "react"
import IndexTableData from "./index-table-data"
import IndexTablePageHeaders from './index-table-page-headers'
import IndexTableReduxStore from "./index-table-use-effects"

export default function IndexTable(){


    return (<>
        <IndexTableReduxStore/>
        <IndexTablePageHeaders />
        <IndexTableData  />
    </>
    )
}


export function SuppliersList (){
    return (
        <IndexTable />
    )
}
export function RequestsList () {
    return (
        <IndexTable/>
    )
}
export function RequestsInventoryList () {
    return (
        <IndexTable/>
    )
}
export function SearchRequestsResults () {
    return (
        <IndexTable/>
    )
}4
export function AwaitingApproval (){
    return (
        <IndexTable/>
    )
}
export function EmployeeDetails() {
    return (
        <IndexTable/>
    )
}
export function SummaryHours (){
    return (
        <IndexTable/>
    )
}
export function ReportDaysOff (){
    return (
        <IndexTable/>
    )
}
export function UsersTable (){
    return (
        <IndexTable/>
    )
}
export function EmployeeHours() {
    return (
        <IndexTable/>
    )
}
export function RequestFavorites() {
    return (
        <IndexTable/>
    )
}
export function SharedRequests() {
    return (
        <IndexTable/>
    )
}
export function AccountingGeneral() {
    return (
        <IndexTable/>
    )
}
export function BankAccounts() {
    return (
        <IndexTable/>
    )
}
export function CreditCards() {
    return (
        <IndexTable/>
    )
}
export function RequestsNotifications() {
    return (
        <IndexTable/>
    )
}
export function RequestsDidntArrive() {
    console.log('got here')
    return (
        <IndexTable/>
    )
}