import { Dispatch, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { IndexTabs, PageTypeEnum, SidebarEnum } from '../../Constants/app-enums';
import { selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setPageNumber, setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { setPageSkeleton } from '../../ReduxRelatedUtils/utilsReducer';
import { ApplicationState, NavigationInfo } from '../../ReduxRelatedUtils/redux-types';
import { setViewModel, setViewModelError } from '../../ReduxRelatedUtils/viewmodel-reducer';
import { IndexTableObject, RequestIndexViewModel, RequestIndexViewModelByGroup } from '../../Utility/request-types';
import { objectToFormData } from '../../Utility/root-function';

export default function IndexTableReduxStore() {
    const reloadIndex = useSelector((state: ApplicationState) => state.reloadIndex)
    const indexTableObject = useSelector((state: ApplicationState) => state.indexTableObject)
    const location = useSelector<ApplicationState>(state => state.router.location.search) as Location
    const navigationInfo = useSelector(selectNavigationInfo);
    const pageNumber = useRef(1)
    const dispatch = useDispatch();
    useEffect(() => {
        // console.time("timer")
        // console.log("reloadindex")
        if (reloadIndex) {
            //  console.log("reloadindex true")
            //dispatch(resetCheckboxInfo());
            useEffectFunc(indexTableObject, location, navigationInfo, dispatch);
        }
       
    }, [reloadIndex])

    // useEffect(() => {
    //     console.log("reloadpagenumber")
    //     useEffectFunc(indexTableObject,location, navigationInfo.sectionType, dispatch);
    // }, [indexTableObject.pageNumber])

    useEffect(() => { //separate use effect so skeleton won't show when change category and price popover
        dispatch(setPageSkeleton(true));
    }, [indexTableObject.tabValue, indexTableObject.selectedRequestFilters, indexTableObject.monthYearFilters, indexTableObject.accountingSelectsViewModel])


    useEffect(() => {
        //  console.log('in use effect')
        if (pageNumber.current == indexTableObject.pageNumber) {
            resetPageNumber(dispatch)
            pageNumber.current = 1;
        }
        else {
            dispatch(setPageSkeleton(true));
            useEffectFunc(indexTableObject, location, navigationInfo, dispatch);
            pageNumber.current = indexTableObject.pageNumber
        }

  

    }, [indexTableObject])


    return null;
}


function useEffectFunc(state: IndexTableObject, location: Location, navigationInfo: NavigationInfo, dispatch: Dispatch<any>) {
    var testJSON = {};

    var formdata = objectToFormData(state, testJSON);
    // var navFormData = objectToFormData(navigationInfo)
    // var formdata = combineTwoFormDatas(stateFormdata, navFormData)
    let date = new Date();
    // console.timeLog("timer", "--before fetch--"+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":" + date.getMilliseconds())
    // console.log(location);
    let controllerName = getControllerName(navigationInfo, state.tabValue)
    // console.log(controllerName)

    fetch("/" + controllerName + "/GetIndexTableJson" + location, {
        method: "POST",
        body: formdata,
    })
        .then(response => {
            let date = new Date();
            // console.timeLog("timer", "--in response--"+date.getHours()+":"+date.getMinutes()+":"+date.getSeconds()+":" + date.getMilliseconds())
            if (!response.ok) throw response.text()
            return response.json();
        })
        .then((result: RequestIndexViewModel | RequestIndexViewModelByGroup) => {
            if (result != undefined) {
                //      console.log("setting index view model")
                dispatch(setViewModel(result));
            }
            batch(() => {
                dispatch(setPageSkeleton(false))
                dispatch(setReloadIndex(false));
            })

        }).catch(err => {
            //    console.log("error")
            Promise.resolve(err).then(text => {
                dispatch(setViewModelError(text))
                //dispatch(setPageSkeleton(true))
                dispatch(setReloadIndex(false));
            })
        });
    //  console.timeLog("after fetch object to fomdata")   
}
function resetPageNumber(dispatch: Dispatch<any>) {
    batch(() => {
        //  console.log("resetpagenumber")
        dispatch(setPageNumber(1));
        dispatch(setReloadIndex(true));
    })
}


function getControllerName(navigationInfo: NavigationInfo, tabValue: string): string {
    let controllerName = '';
    console.log(tabValue)

    switch (navigationInfo.pageType) {
        case PageTypeEnum.LabManagementSuppliers:
        case PageTypeEnum.AccountingSuppliers:
            controllerName = "Vendors";
            break;
        case PageTypeEnum.AccountingPayments:
        case PageTypeEnum.AccountingNotifications:
        case PageTypeEnum.AccountingGeneral:
        case PageTypeEnum.LabManagementQuotes:
            controllerName = "Requests";
            break;
        case PageTypeEnum.AccountingSettings:
            if (navigationInfo.sidebarType == SidebarEnum.BankAccounts) controllerName = "BankAccounts";
            else if (navigationInfo.sidebarType == SidebarEnum.CreditCards) controllerName = "CreditCards";
            break;
        case PageTypeEnum.RequestInventory:
            controllerName = (tabValue == IndexTabs.Main || tabValue=="")? "Products" : "Requests";
            break;
        default:
            controllerName = navigationInfo.sectionType;
            break;
    }
    return controllerName;
}