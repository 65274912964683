import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import 'regenerator-runtime/runtime';
import { FolderNamesEnum, ModalName, SvgNames } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setLoading as setGlobalLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { ApplicationState, NavigationInfo } from '../../ReduxRelatedUtils/redux-types';
import { DocumentsModalProps } from '../../Shared/documents-card';
import { GetFileFramePath, UploadFile } from "../../Utility/document-functions";
import { CustomError, DocumentsModalInfo } from '../../Utility/general-types';
import { StyledFilledButton, SvgIcon } from '../../Utility/shared-components';
import DeleteDocumentModal from './delete-document-modal';
import GlobalModal from './global-modal';
import { ModalFooterWithCustomSave, ModalHeader, StyledDialogContent } from './modal-components';
const noDocsImage = "/images/css/emptyState.png";

export type DocumentModalFormData = {
    filesToSave: File,
    folderID: string,
    folderName: FolderNamesEnum
    isFirstPart: boolean,
}





export default function DocumentsModal(props: DocumentsModalInfo & DocumentsModalProps &{hideBackDrop?:boolean}) {
    const dispatch = useDispatch();
    const { sectionType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo
    const { watch } = useForm<DocumentModalFormData>({ defaultValues: { folderName: props.folderName, folderID: props.folderID } as DocumentModalFormData });
    const data = watch();
    const [deleteDocsModalIsOpen, setDeleteDocsModalIsOpen] = useState<boolean>(false);
    const [currentFileToDelete, setCurrentFileToDelete] = useState<string>("");
    const [error, setError] = useState<CustomError>({} as CustomError);
    const [isEdittable, setIsEdittable]= useState(props.isEdittable??true);
    function openDeleteDocsModal(fileToDelete: string){
      setDeleteDocsModalIsOpen(true)
      setCurrentFileToDelete(fileToDelete)
    }
    function closeDocModal(){
        console.log("in close doc modal")
        props.setDocsModalIsOpen(false);
    }

    useEffect(()=>{
        setIsEdittable(props.isEdittable??true)
    },[props.isEdittable])
    function handleDocChange(e: any) {
        console.log("in change")
        if(e.target.files.length>0)
        {
            dispatch(setGlobalLoading(true))  
        } 
        let numFilesUploaded = 0
        const promise = new Promise((resolve, reject) => {
        for (let i = 0; i < e.target.files.length; i++) {
            UploadFile(e.target.files[i], data, sectionType).then((results) => {
                if (results.ok) {
                    numFilesUploaded++
                    console.log("results: "+numFilesUploaded)
                 if(numFilesUploaded== e.target.files.length) resolve("on last file of the loop")
                }
                else {
                    setError({ message: "something went wrong while uploading"} as CustomError)
                }
            })
        }})
        promise.then((data)=>{
            console.log(data)
            props.setFileCount(props.fileStrings.length+e.target.files.length);
        })
    }
    return ( <GlobalModal closeClick={closeDocModal} size={ModalSizes.md} modalKey={ModalName.Documents} hideBackdrop={props.hideBackDrop??false}>
           <>
                <ModalHeader headerText={props.folderName + " Files"} errorMessage={error?.message??""}/>
                <StyledDialogContent>
    
                    <form action="" data-string="" method="post" encType="multipart/form-data">
                        <Box>
                            {(props.fileStrings == null || props.fileStrings.length == 0) ?
                                <Box sx={{ textAlign: "center" }}>
                                    <Box
                                        component='img'
                                        sx={{
    
                                        }}
                                        alt="logo"
                                        src={noDocsImage} />
                                    <Typography sx={{ fontSize: "2rem", color: "1.125rem", fontWeight: "400" }}>No {props.folderName} have been uploaded</Typography>
    
                                </Box>
                                :
                                <Carousel
                                    
                                    renderButtonGroupOutside
    
                                    responsive={{
                                        desktop: {
                                            breakpoint: {
                                                max: 3000,
                                                min: 93
                                            },
                                            paritialVisibilityGutter: 80,
                                            items: 3
                                        }
                                    }}
                                    deviceType="desktop">
    
                                    {props.fileStrings.map((fileString:string, i) => (
                                            <CarouselItem key={fileString+i} isEdittable={isEdittable} fileString={fileString}  openDeleteDocsModal={openDeleteDocsModal}/>
                                        )
                                    )}
                                </Carousel>}
    
                        </Box>
                    </form>
                </StyledDialogContent>
                <ModalFooterWithCustomSave cancelButtonText='Done' closeClick={closeDocModal}>
                    {isEdittable&&
                    <StyledFilledButton sx={{marginLeft: "0.5rem"}} component="label" >Upload File
                        <input type="file" hidden
                            onChange={handleDocChange} multiple={props.allowMultipleFiles} />
                    </StyledFilledButton>}
                </ModalFooterWithCustomSave>
             {deleteDocsModalIsOpen &&    <DeleteDocumentModal setFileCount={props.setFileCount} fileStrings={props.fileStrings} setDeleteDocsModalIsOpen={setDeleteDocsModalIsOpen} fileName={currentFileToDelete}  setError={setError}/>}
            </>
    </GlobalModal>)
}

export function CarouselItem(props:{fileString: string, openDeleteDocsModal: (fileToDelete: string) => void, isEdittable?:boolean} ): JSX.Element {
    return <Box sx={{
        margin: '5%',
        border: (theme) => `1px solid ${theme.palette.primary.main} !important;`,
        borderRadius: '.25rem',
    }}>
        <iframe src={GetFileFramePath(props.fileString)} title="View" scrolling="no" style={{ position: 'relative', width: '100%', height: '15.625rem', borderRadius: '.25rem .25rem 0 0', }}></iframe>
        <Stack direction={'row'} sx={{ padding: '1rem', justifyContent: 'space-between' }}>
            <Link className='section-color' href={"\\" + props.fileString} target="_blank" sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis', overflow: 'hidden', width: '50%', marginTop: 'auto'
            }}>
                {props.fileString.split('\\').pop()}
            </Link>
          {props.isEdittable&&  <Button onClick={() => { props.openDeleteDocsModal(props.fileString); } }>
                <SvgIcon className='section-fill' height={2} name={SvgNames.Delete} />
            </Button>}
        </Stack>

    </Box>;
}

