import { createSlice } from '@reduxjs/toolkit'
import undoable from 'redux-undo'
import { ModalFlow, ModalName } from '../Constants/app-enums'
import { Modal, ModalInfo } from './redux-types'


const modalSlice = createSlice({
    name: 'modal',
    initialState: {} as Modal,
    reducers: {
        setModal(state, action:{payload:Modal}) {
            return action.payload
        },
        removeModal(state) {
            return  { } as Modal
        },
        setModalStep(state, action:{payload:{modalFlow:ModalFlow, modalStep: ModalName, modalInfo?:ModalInfo}}){
            state.modalStep = action.payload.modalStep
            if(action.payload.modalInfo != undefined){
                state.modalInfo = action.payload.modalInfo
            }
            return state
        }
    }
})
export const { setModal, removeModal, setModalStep} = modalSlice.actions
export const modalReducer = undoable(modalSlice.reducer, {undoType:"undoModals", redoType:"redoModals", clearHistoryType:"clearModalsHistory"})

const modalLoadingSlice = createSlice({
    name: 'modalLoading',
    initialState: false,
    reducers: {
        setModalLoading(state, action:{payload:boolean}){
            return action.payload
    }
    }
})

export const { setModalLoading} = modalLoadingSlice.actions
export const modalLoadingReducer = modalLoadingSlice.reducer
