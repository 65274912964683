import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { ApplicationState } from "../ReduxRelatedUtils/redux-types";
import ColumnOptionsPopover from "../Requests/column-options-popover";
import IndexTableRow from "../Shared/IndexTable/index-table-row";
import { RowViewModel } from "../Utility/general-types";
import { SmallTypography } from "../Utility/shared-components";
import { SummaryHoursViewModel } from "../Utility/timekeeper-types";

export function SummaryHoursTotalHeader(){
    const row = useSelector<ApplicationState>(state=>(state.viewModel as SummaryHoursViewModel).row) as RowViewModel;
    return (
        <TableContainer key="scrollable-table" component={Box}sx={{ overflow: "hidden", paddingBottom:"2rem" }}>
        <Table stickyHeader sx={{ minWidth: 650, marginBottom: "1rem", height: "100%"}} aria-label="simple table">
        <TableHead sx={{display:"table", height:"3.75rem", tableLayout:"fixed", width:"100%"}}>
            <TableRow sx={{borderBottom:"none"}}>
                {row.columns.map((col, i) =>
                    <TableCell key={col.title+i+col.width} width={`${col.width}%`}>                      
                        <SmallTypography>{col.title}</SmallTypography>                       
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
            <TableBody sx={{ display: "block", }} className="section-border">               
                    <IndexTableRow sx={{borderBottom:"none"}} key={row.id} row={row} displaytable="true" />            
            </TableBody>
        </Table>
    </TableContainer>
    );
}