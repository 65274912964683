import { yupResolver } from '@hookform/resolvers/yup';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { AnySchema, number, object, string } from 'yup';
import { ModalName } from '../Constants/app-enums';
import { selectModalPresent, selectNavigationInfo } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, LastSelectedLocation, Modal, NavigationInfo } from '../ReduxRelatedUtils/redux-types';
import { setLastSelectedLocation, setLastSelectedLocationReload } from '../ReduxRelatedUtils/selectedLocationReducer';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput, StyledControlledFreeSoloMultipleSelect, StyledControlledAutoCompleteInput, StyledFormInput, StyledControlledMultipleSelect } from '../Utility/custom-input-components';
import { AutocompleteOption, CustomError, LocationModalInfo, SelectListItem } from '../Utility/general-types';
import { GlobalForm, SpacedColumnStack, SpacedRowStack } from '../Utility/shared-components';
import { Lab, LocationInstance } from './locations-types';

export type RoomViewModel = {
    buildingName: string,
    labs: Lab[],
    floorName: string,
    associatedLabs?: Lab[],
    room?: LocationInstance
}
export type RoomFormData = {
    room: LocationInstance,
    associatedLabs: AutocompleteOption[]
}

export default function RoomModal() {
    const dispatch = useDispatch();
    const [viewModel, setViewModel] = useState<RoomViewModel>({} as RoomViewModel)
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [error, setError] = useState<CustomError>(new CustomError());
    const { sidebarType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo


    const validationSchema = object<Partial<Record<keyof RoomFormData, AnySchema>>>({
        room: object<Partial<Record<keyof LocationInstance, AnySchema>>>({
            locationInstanceName: string().required("Room name field is required"),
            locationInstanceParentID: number().moreThan(0, 'Floor field is required').required('Floor field is required'),
        })
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        resolver,
        defaultValues: {
            room: {
                locationInstanceName: "",
                locationInstanceParentID: 0
            }

        } as RoomFormData,
    }
    );

    useEffect(() => {

        var url = "/Locations/RoomModal?parentID=" + modalInfo.parentLocationID + "&locationID=" + modalInfo.locationID;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                throw response.json();
            })
            .then((result: RoomViewModel) => {
                setViewModel(result);
                if (result.room != undefined) {
                    methods.setValue("room", result.room)
                }
                else {
                    methods.setValue("room.locationInstanceParentID", modalInfo.parentLocationID ?? 0)
                }
                methods.setValue("associatedLabs", result.associatedLabs ? result.associatedLabs.map(l => { return { text: l.name, value: l.labID } as AutocompleteOption }) : [] as AutocompleteOption[])

                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError({ message: text.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo]);

    function onSubmit(data: any, e: any) {
        var url = '/Locations/RoomModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.json() }
                throw response.text();
            })
            .then((res: {}) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: modalInfo.locationID? "Room Updated" : "Room Added", severity: "success" }))
                    dispatch(setLastSelectedLocation({ ...modalInfo, reload: true } as LastSelectedLocation))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }



    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={modalInfo.locationID == undefined ?"Add Room" : "Edit Room"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddRoom} onSubmit={onSubmit} methods={methods}>
                                <SpacedColumnStack alignItems="center">
                                    <SpacedRowStack width="100%">
                                            <StyledFormInput value={viewModel.buildingName} disabled label="Building" />
                                            
                                            <StyledFormInput value={viewModel.floorName} disabled label="Floor" />
                                    </SpacedRowStack>
                                    <StyledControlledFormInput name="room.locationInstanceName" label="Room Name" />
                                    <StyledControlledMultipleSelect name="associatedLabs" label="Associated Labs" options={viewModel.labs.map(l => { return { text: l.name, value: l.labID } as SelectListItem })} />
                                </SpacedColumnStack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddRoom} />
                    </>
                    }
                </>
            }
        </>
    )
}
