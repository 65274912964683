import { Box, Grid } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { UseFieldArrayReturn } from "react-hook-form/dist/types";
import { SvgNames } from "../Constants/app-enums";
import { AddSupplierFormData } from "../Shared/AddCardComponents/add-types";
import { StyledControlledFormInput } from "../Utility/custom-input-components";
import { StyledSectionOutlineButton, SvgIcon } from "../Utility/shared-components";
import { VendorContact } from "../Utility/supplier-types";
import Theme from "../Utility/theme";

const InitializedComment = {
    vendorContactID:0,
    vendorContactEmail:"",
    vendorContactName:"",
    vendorContactPhone:""
} as VendorContact

export default function VendorContactsTab() {
    const { control, formState, watch, getValues, reset, setValue } = useFormContext<AddSupplierFormData>()

    const fieldArrayMethods = useFieldArray(
        {
            control,
            name: "vendorContacts"
        }
    );
    const formComments = watch("vendorContacts")
    console.log(formComments)
    useEffect(() => {if(formComments.length==0){fieldArrayMethods.prepend(InitializedComment)}
    }, [])

    return (
        <Stack direction="column" spacing={2} alignItems="center">
            {fieldArrayMethods.fields.map((comment, index) => {
                console.log(comment.id)
                return (
                    <ContactBox key={comment.id} index={index} fieldArrayMethods={fieldArrayMethods} />
                )
            })}
            <StyledSectionOutlineButton onClick={()=>fieldArrayMethods.prepend(InitializedComment)}>+ Add Contact</StyledSectionOutlineButton>
        </Stack>
    )
}

function ContactBox(props: { index: number, fieldArrayMethods: UseFieldArrayReturn<AddSupplierFormData, "vendorContacts", "id"> }) {
    const { control, formState, watch, getValues, reset, setValue } = useFormContext<AddSupplierFormData>()
    const contactsLength = watch("vendorContacts").length
    return (
        <Box
            data-testid="contact-box"
            border={"solid .1rem "+ Theme.palette.grey[400]}
            className={"section-1px-border"}
            padding="1rem"
            borderRadius="0.3rem"
            display="flex"
            flexDirection="column">
               {contactsLength>1 && <Box width="1rem" sx={{cursor:"pointer"}} onClick={()=>props.fieldArrayMethods.remove(props.index)} alignSelf="end"><SvgIcon name={SvgNames.Clear} height={1} className="icon-gray" /></Box>}
            <Grid container spacing={0.5}>
               <Grid item xs={12}>
                    <StyledControlledFormInput name={`vendorContacts.${props.index}.vendorContactName`} label="Name*"/>
               </Grid>
               <Grid item xs={6}>
                    <StyledControlledFormInput name={`vendorContacts.${props.index}.vendorContactEmail`} label="Email*"/>
               </Grid>
               <Grid item xs={6}>
                    <StyledControlledFormInput name={`vendorContacts.${props.index}.vendorContactPhone`} label="Phone*"/>
               </Grid>
            </Grid>
        </Box>
    )
}

