import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../Constants/app-enums';
import { ModalSizes } from '../Constants/client-side-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import ConfirmArchiveModal from '../Requests/Modals/confirm-archive-modal';
import GlobalModal from '../Requests/Modals/global-modal';
import BuildingModal from './building-modal';
import FloorModal from './floor-modal';
import LabModal from './lab-modal';
import RoomModal from './room-modal';
import AddStorageUnitModal from './add-storage-unit-modal';
import AssociateRoomModal from './associate-room-modal';
import DeleteLocationModal from './delete-location-modal';
import EditStorageUnitNameModal from './edit-storage-unit-name-modal';
import MoveStorageUnitModal from './move-storage-unit-modal';
import DeleteLabModal from './delete-lab-modal';
import BlockLocationModal from './block-location-modal';
import RemoveRoomModal from './remove-room-modal';



export default function AddLocationFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName

    // console.log("in add location flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.AddLab:
                return (<LabModal />)
            case ModalName.AddStorageUnit:
                return (<AddStorageUnitModal />)
            case ModalName.AddFloor:
                return (<FloorModal />)
            case ModalName.AddBuilding:
                return (<BuildingModal />)
            case ModalName.AddRoom:
                return (<RoomModal />)
            case ModalName.ConfirmArchive:
                return (<ConfirmArchiveModal />)
            case ModalName.AssociateRoom:
                return <AssociateRoomModal />
            case ModalName.MoveStorageUnit:
                return <MoveStorageUnitModal />
            case ModalName.EditStorageUnitName:
                return <EditStorageUnitNameModal />
            case ModalName.DeleteLocation:
                return <DeleteLocationModal />
            case ModalName.DeleteLab:
                    return <DeleteLabModal />
            case ModalName.BlockLocation:
                return <BlockLocationModal />
            case ModalName.RemoveRoom:
                return <RemoveRoomModal />
            default: return null;
        }
    }

    return (
        <GlobalModal size={ModalSizes.sm} modalKey={modalStep}>
            <ModalContent />
        </GlobalModal>
    );

}
