import { Button, FormControlLabel, Grid, MenuItem, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { request } from "http";
import moment from "moment";
import { r } from "msw/lib/glossary-dc3fd077";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Value } from "sass";
import { CurrencyEnum, FolderNamesEnum, InfoPopupName, PaymentTypes, SvgNames } from "../../Constants/app-enums";
import { TabsName } from "../../Constants/client-side-enums";
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setInfoPopup } from "../../ReduxRelatedUtils/infoPopupReducer";
import { ApplicationState, InfoPopup } from "../../ReduxRelatedUtils/redux-types";
import { AddItemFormData, EditProductFormData } from "../../Shared/AddCardComponents/add-types";
import { Tab } from "../../Shared/AddCardComponents/card-tabs";
import DocumentsCard from "../../Shared/documents-card";
import { EditCardType } from "../../Shared/edit-card";
import EditCardPopover from "../../Shared/edit-card-popover";
import { ControlledCheckbox, StyledControlledAutoCompleteInput, StyledControlledFormDateInput, StyledControlledFormInput, StyledControlledFormInputWithDocInfo, StyledControlledUrlFormInput, StyledCurrencyFormInput } from "../../Utility/custom-input-components";
import { AutocompleteOption, SelectListItem } from "../../Utility/general-types";
import { CompanyAccount, CreditCard, ParentCategory, Payment, PaymentType, ProductItemViewModel, ProductSubcategory, RequestItemViewModel, Vendor } from "../../Utility/request-types";
import { DocumentTypeWithID, getDisplayNameOfEnumValue, handleCalendarUnitCalcChange, handleDateCalcChange } from "../../Utility/root-function";
import { BoldTypography, PaymentDetailsBlock, SpacedColumnStack, SpacedRowStack, SvgIcon } from "../../Utility/shared-components";
import Theme from "../../Utility/theme";
import { PriceEdittableContext } from "./edit-item";

export function BatchLotBlock(props: { cols?: number }) {

    return (
        <Grid item xs={props.cols ?? 8}>
            <Stack direction="row" spacing={2}>
                <StyledControlledFormInput type="number" label="Batch/Lot" name="request.batch" />
                <StyledControlledFormDateInput label="Expiration Date" name="request.batchExpiration" />
            </Stack>
        </Grid>

    );
}



export function QuoteBlock(props: { cols?: number }) {

    return (
        <Grid item xs={props.cols ?? 6}>
            <Stack direction="row" spacing={2}>
                <StyledControlledFormInput  label="Quote Number" name="request.parentQuote.quoteNumber" />
                <StyledControlledFormDateInput label="Quote Expiration Date" name="request.parentQuote.expirationDate" />
            </Stack>
        </Grid>

    );
}
export function DiscountBlock(props: { cols?: number, disabled?:boolean }) {

    return (
        <Grid item xs={props.cols ?? 2}>
            <Stack direction="row" spacing={2}>
            <StyledControlledFormInput disabled={props.disabled??false} name="request.parentQuote.discount" label="Quote Discount" type="number" />
            </Stack>
        </Grid>

    );
}

export function OrderBlock(props: { cols?: number, requestStatusID:number}) {

    return (
        <Grid item xs={props.cols ?? 6}>
            <Stack direction="row" spacing={2}>
                <StyledControlledFormInput disabled type="number" label="Order Number" name="request.parentRequest.orderNumber" />
                {props.requestStatusID!=10 &&
                <StyledControlledFormDateInput label="Order Date" name="request.parentRequest.orderDate" />}
            </Stack>
        </Grid>

    );
}

export function ReceivedBlock(props: { cols?: number, users?: AutocompleteOption[] }) {
    const { watch } = useFormContext<AddItemFormData>();
    const isClarify = watch("request.isClarify")
    return (
        <>
            <Grid item xs={props.cols ?? 12}>
                <Stack direction="row" spacing={2}>
                    <StyledControlledFormDateInput name="request.arrivalDate" label="Arrival Date" />
                    <StyledControlledAutoCompleteInput name="request.applicationUserReceiverID" label="Received By"
                        options={props.users ?? []}
                    />
                    <FormControlLabel  key="clarifyCheckbox" sx={{ opacity: "0.5" }}
                        control={<ControlledCheckbox disabled name="request.isClarify" />}
                        label={<Typography>Clarify</Typography>} />

                    <FormControlLabel sx={{ width: "100%", opacity: "0.5" }} key="partialCheckbox"
                        control={<ControlledCheckbox disabled name="request.isPartial" />}
                        label={<Typography>Partial Delivery</Typography>} />
                </Stack>
            </Grid>
            {isClarify &&
                <Grid item xs={props.cols ?? 12}>
                    <StyledControlledFormInput label="Clarification Note" name="request.noteForClarifyDelivery" />
                </Grid>}
        </>
    );
}

export function InvoiceBlock(props: { cols?: number }) {
    const methods = useFormContext<AddItemFormData>()
    const invoiceID = methods.watch("request.payments.0.invoiceID")
    const requestID = methods.watch("request.requestID")
    return (
        <Grid item xs={props.cols ?? 6}>
            <Stack direction="row" spacing={2}>
                <StyledControlledFormInputWithDocInfo label="Invoice Number" name="request.payments.0.invoice.invoiceNumber" folderName={FolderNamesEnum.Invoices} infoID={invoiceID??0} editDrawerID={requestID.toString()}/>
                <StyledControlledFormDateInput label="Invoice Date" name="request.payments.0.invoice.invoiceDate" />
            </Stack>
        </Grid>

    );
}

export function UrlBlock(props: {name:string}) {
    return (

        <Grid item xs={4}>
            <StyledControlledUrlFormInput label="URL" name={`${props.name}product.url`} type="url" />
        </Grid>

    );
}

export function SupplierOrderNumberBlock(props: {}) {
    return (

        <Grid item xs={3}>
            <StyledControlledFormInput label="Supplier Order Number" name="request.parentRequest.supplierOrderNumber" />
        </Grid>

    );
}

export function CreationDateBlock(props: {}) {
    return (

        <Grid item xs={4}>
            <StyledControlledFormDateInput label="CreationDate" name="request.editableCreationDate" />
        </Grid>

    );
}
export function WarrantyBlock(props: { cols?: number }) {
    const { setValue, watch } = useFormContext();

    const daysName = "request.warranty";
    const dateName = "request.warrantyEndDate";

    const requestStatusID= watch("request.requestStatusID")
    const warrantyMonths= watch(daysName)
    const warrantyDate= watch(dateName)
    const orderDate= watch("request.parentRequest.orderDate")

    const didMount = useRef(false);
    useEffect(()=>{
        //console.log("date")
        if(didMount.current){
            if(requestStatusID==2 || requestStatusID==3){
                setValue(daysName, moment(warrantyDate).diff(moment(orderDate).startOf('month'), "months"))
            }
            else{
                setValue(daysName, moment(warrantyDate).diff(moment().startOf('month'), "months"))
    
            }
        }
        else{
            didMount.current= true;
        }        
    },[warrantyDate])
    
    useEffect(()=>{
        //console.log("days")
        if(requestStatusID==2 || requestStatusID==3){
            setValue(dateName, moment(orderDate).add(warrantyMonths, "months").format("YYYY-MM-DD"))
        }
        else{
            setValue(dateName, moment().add(warrantyMonths, "months").format("YYYY-MM-DD"))

        }
    },[warrantyMonths])

    return (
        <Grid item xs={props.cols ?? 8}>
            <Stack direction="row" spacing={2}>
                <StyledControlledFormInput label="Warranty (Months)" name={daysName} type="number" />
                <StyledControlledFormDateInput label="Estimated End Date" minDate={requestStatusID==2 || requestStatusID==3 ?orderDate: new Date()} name={dateName}/>
            </Stack>
        </Grid>

    );
}
export function PassageAmountBlock(props: { cols?: number }) {
    return (
        <Grid item xs={props.cols ?? 4}>
            <StyledControlledFormInput label="Passage" name="request.passage" type="number" />
        </Grid>

    );
}
export function TermsBlock(props: { cols?: number }) {

    const { watch, setValue } = useFormContext<AddItemFormData>();
    const currency = watch("request.currency")
    const shipping = watch("request.shipping")
    const exchangeRate = watch("request.exchangeRate")
    const payments = watch("request.payments")
    const {disablePriceEdit} = useContext(PriceEdittableContext)


    //const [shippingDollar, setShippingDollar] = useState(shipping / exchangeRate)

    return (
        <>


            <Grid item xs={props.cols ?? 3}>
                <StyledControlledFormInput disabled label="Terms" name="request.paymentStatus.paymentStatusDescription" />
            </Grid>
            <Grid item xs={props.cols ?? 3}>
                {currency == CurrencyEnum.NIS ?
                    <StyledCurrencyFormInput disabled={(payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} key="shekel" label="Shipping" name='request.shipping' currency="₪" /> :
                    <StyledCurrencyFormInput disabled={(payments?.length>0 && payments?.every(p=> p.isPaid)) || disablePriceEdit} key="dollar" label="Shipping" onChange={(e) => {console.log(e.target.value); setValue("request.shipping", Number.parseFloat(e.target.value) * exchangeRate)}} value={shipping / exchangeRate} currency="$" />
                }
            </Grid>
        </>

    );
}
export function SupplyDaysBlock(props: { cols?: number }) {
    const { setValue, watch } = useFormContext<AddItemFormData>();
    const requestStatusID= watch("request.requestStatusID")
    const expectedSupplyDays= watch("request.expectedSupplyDays")
    const expectedSupplyDate= watch("request.expectedSupplyDate")
    const orderDate= watch("request.parentRequest.orderDate")
    const daysName = "request.expectedSupplyDays";
    const dateName = "request.expectedSupplyDate";
    const didMount = useRef(false);
    useEffect(()=>{
        console.log("date")
        if(didMount.current){
            if(requestStatusID==2 || requestStatusID==3){
                setValue(daysName, moment(expectedSupplyDate).diff(moment(orderDate).startOf('day'), "days"))
            }
            else{
                setValue(daysName, moment(expectedSupplyDate).diff(moment().startOf('day'), "days"))
    
            }
        }
        else{
            didMount.current= true;
        }        
    },[expectedSupplyDate])
    
    useEffect(()=>{
        console.log("days")
        if(requestStatusID==2 || requestStatusID==3){
            setValue(dateName, moment(orderDate).add(expectedSupplyDays, "days").format("YYYY-MM-DD"))
        }
        else{
            setValue(dateName, moment().add(expectedSupplyDays, "days").format("YYYY-MM-DD"))

        }
    },[expectedSupplyDays])

    return (
        <Grid item xs={props.cols ?? 8}>
            <Stack direction="row" spacing={2}>
                <StyledControlledFormInput label="Expected Supply Days" name={daysName}/>
                <StyledControlledFormDateInput label="Expected Supply Date" name={dateName} minDate={requestStatusID==2 || requestStatusID==3 ?orderDate: new Date()} />
            </Stack>
        </Grid>

    );
}


export function VendorBlock(props:{name:string, viewModel?: RequestItemViewModel|ProductItemViewModel}) {
    const [itemModal, setItemModal] = useState<{open:boolean, id:string}>({open:false, id:""})

    const { setValue, watch } = useFormContext();
    const dispatch = useDispatch()

    function vendorChange(e: any) {
        console.log(e)
        if(!isNaN(e) || e == undefined){
        setValue(`${props.name}product.vendor.vendorBuisnessID`, props.viewModel?.vendors?.find(v => v.vendorID == e)?.vendorBuisnessID ?? "");
   //     console.log(viewModel?.vendors?.find(v => v.vendorID == e)?.country?.currency?.currencyName)
        setValue(`${props.name}product.vendor.country.currency.currencyName`, props.viewModel?.vendors?.find(v => v.vendorID == e)?.country?.currency?.currencyName ?? CurrencyEnum.None);
        setValue(`${props.name}product.vendorID`, e);
        }
    }
    const vendorID = watch(`${props.name}product.vendorID`)
    const catalogNumber = watch(`${props.name}product.catalogNumber`)
    const productID = watch(`${props.name}product.productID`)

    useEffect(() => {
        if (catalogNumber != "" && vendorID > 0) {
         //   console.log("check unique vendor catalog number")
          //  console.log(productID)
            fetch("/Requests/CheckUniqueVendorAndCatalogNumber?VendorID=" + vendorID + "&CatalogNumber=" + catalogNumber + "&ProductID=" + productID, {
                method: "GET",
            }).then(response => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            }).then((result: boolean) => {
            //    console.log(result)
                setValue(`${props.name}.product.uniqueVendorCatalogNumber`, result)
            })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        console.log(text)
                        dispatch(setGlobalSnack({ open: true, severity: "error", message: text }))
                    })
                })
        }
    }, [catalogNumber, vendorID])

    const currency = watch(`${props.name}.currency`)
    const vendorCurrency = watch(`${props.name}product.vendor.country.currency.currencyName`)
   // console.log("vendor currency " + vendorCurrency)
    return (
        <>
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <StyledControlledAutoCompleteInput
                        label="Vendor"
                        placeholder="Select a vendor"
                        name={`${props.name}product.vendorID`}
                        options={props.viewModel?.vendors?.map(v => { return { text: v.vendorEnName, value: v.vendorID } as AutocompleteOption }) ?? []}
                        onChange={vendorChange} />
                    <StyledControlledFormInput disabled label="Company ID" name={`${props.name}product.vendor.vendorBuisnessID`} />
                    {vendorID==0?<SvgIcon name={SvgNames.AccountBox} height={4} className={"icon-gray"}/>:
                    <Box component={Button} onClick={() => dispatch(setInfoPopup({infoPopupName: InfoPopupName.EditSupplier, id: vendorID.toString()} as InfoPopup))}><SvgIcon name={SvgNames.AccountBox} height={4} /></Box>}
                </Stack>
          {props.name!=""&& (vendorCurrency && vendorCurrency != CurrencyEnum.None && currency != vendorCurrency) &&
                    <BoldTypography color={Theme.palette.error.main}>{`Warning: Selected Currency is ${currency}`}</BoldTypography>
                }
            </Grid>

            <Grid item xs={4}>
                <StyledControlledFormInput label="Catalog Number" name={`${props.name}product.catalogNumber`} />
            </Grid>
        </>
    );
}
export function CategoryBlock(props:{name:string, editID?:string, viewModel?: RequestItemViewModel|ProductItemViewModel}) {
    const methods = useFormContext();
    const [productSubcategories, setProductSubCategories]= useState<ProductSubcategory[]>();
    const parentCategoryID = methods.watch(`${props.name}product.productSubcategory.parentCategoryID`);
    const didMount = useRef(false);
    useEffect(()=>{       
        setProductSubCategories(props.viewModel?.productSubcategories?.filter(ps=>ps.parentCategoryID==parentCategoryID))   
        if(didMount.current){
            methods.setValue(`${props.name}product.productSubcategory.parentCategory`, props.viewModel?.parentCategories.find(p=>p.id==parentCategoryID))     
            methods.setValue(`${props.name}product.productSubcategoryID`, 0)
        }
        else{
            didMount.current = true;
        }
            }, [parentCategoryID, props.viewModel])
    return (
        <Stack direction="row" spacing={2}>

            <StyledControlledAutoCompleteInput key={"category"}
                label="Category"
                placeholder="Select a category"
                name={`${props.name}product.productSubcategory.parentCategoryID`}
                options={props.viewModel?.parentCategories?.map(pc => { return { text: pc.description, value: pc.id } as AutocompleteOption }) ?? []}
            />

            <StyledControlledAutoCompleteInput key={"subcategory"}
                label="Subcategory"
                placeholder="Select a Subcategory"
                name={`${props.name}product.productSubcategoryID`}
                options={productSubcategories?.map(ps => { return { text: ps.description, value: ps.id } as AutocompleteOption }) ?? []}
            />

        </Stack>
    );
}

export function PaymentsBlock(viewModel?: RequestItemViewModel) {
    const { watch } = useFormContext<AddItemFormData>();
    const payments = watch("request.payments")
    const invoiceID = watch("request.payments.0.invoiceID")
    const requestID = watch("request.requestID")
    return (
        <>
            <Grid item xs={12}>
                <SpacedRowStack>
                    <Box width="15%">
                        <StyledControlledFormInput label="Payments" disabled type="number" name="request.installments" />
                    </Box>
                    <SpacedColumnStack>
                        {payments?.filter(p => p.isPaid)?.map((p, i) =>
                        <>
                           <PaymentDetailsBlock  name={`request.payments.${i}`} creditCards={viewModel?.creditCards} companyAccounts={viewModel?.companyAccounts} paymentTypes={viewModel?.paymentTypes}/>
                            {p.invoiceID != null && <SpacedRowStack sx={{width:"50%"}}>
                            <StyledControlledFormInputWithDocInfo label="Invoice Number" name={`request.payments.${i}.invoice.invoiceNumber`} folderName={FolderNamesEnum.Invoices} infoID={invoiceID??0} editDrawerID={requestID.toString()}/>
                <StyledControlledFormDateInput label="Invoice Date" name={`request.payments.${i}.invoice.invoiceDate`} />
            </SpacedRowStack>}
                            </>
                        )}
                    </SpacedColumnStack>
                </SpacedRowStack>
            </Grid>
        </>
    );
}

export function getTabs(isEdit: boolean, isSample: boolean, showLocation?: boolean ,isProduct?:boolean, showInstallments?:boolean) {
    let tabs = [
        { icon: SvgNames.Categories, name: TabsName.Item },
        { icon: SvgNames.MonetizationOn, name: TabsName.Price },
        { icon: SvgNames.CentarixInstallments, name: TabsName.Payments },
        { icon: SvgNames.Place, name: TabsName.Locations },
        { icon: SvgNames.AttachFile, name: TabsName.Documents },
        { icon: SvgNames.CentarixComments, name: TabsName.Comments },
        { icon: SvgNames.Clipboard, name: TabsName.Order },
        { icon: SvgNames.History, name: TabsName.History },
    ] as Tab[]
    console.log(isProduct)
    if(isProduct==true){
        return tabs.filter(t => t.name == TabsName.Item||t.name == TabsName.Comments ||t.name == TabsName.History);
    }
    if (isEdit) {
        if (showLocation ) {
        
             tabs= tabs.filter(t => t.name != TabsName.Order);
             if(!showInstallments){
                tabs =  tabs.filter(t => t.name != TabsName.Payments)
             }
             return tabs

        }
        else if (isSample){
            return tabs.filter(t => t.name != TabsName.Order && t.name != TabsName.Price  && t.name != TabsName.History && t.name !=TabsName.Payments)
        }
        else {
            
            tabs = tabs.filter(t => t.name != TabsName.Order && t.name != TabsName.Locations)
            if(!showInstallments){
                tabs =  tabs.filter(t => t.name != TabsName.Payments)
             }
             console.log(tabs)
             return tabs
        }
    }
    else if (isSample) {
        return tabs.filter(t => t.name != TabsName.Order && t.name != TabsName.Price  &&  t.name != TabsName.History && t.name !=TabsName.Payments)
    }
    else {
        return tabs.filter(t => t.name != TabsName.Locations &&  t.name != TabsName.History && t.name !=TabsName.Payments);
    }
}


export function DocumentsTab(props: { docCards: DocumentTypeWithID[] }) {
    useEffect(() => {
      //  console.log("doc tab")
    }, [])
    return <Grid container spacing={2}>

        {props.docCards?.map((v, i) => (
            <Grid item key={i}>
                <DocumentsCard folderName={v.folderName} folderID={v.id} allowMultipleFiles={true} />
            </Grid>)
        )}
    </Grid>;
}


