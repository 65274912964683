import { Button, Grid, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import 'regenerator-runtime/runtime';
import { MenuItemEnum, SvgNames } from '../Constants/app-enums';
import { selectNavigationInfo } from '../ReduxRelatedUtils/customConfigureStore';
import { setError } from '../ReduxRelatedUtils/errorReducer';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setLoading as setGlobalLoading } from '../ReduxRelatedUtils/utilsReducer';
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import DocumentsModal, { DocumentModalFormData } from '../Requests/Modals/documents-modal';
import { UploadFile } from '../Utility/document-functions';
import { CustomError, DocumentCard } from '../Utility/general-types';
import { getDocumentCardsDetailsByFolderEnum } from '../Utility/root-function';
import { StyledError, SvgIcon } from '../Utility/shared-components';


export type DocumentsFlowProps = {
    fileStrings: string[],
    setFileCount: Function,

}
export type DocumentsModalProps = DocumentsFlowProps & {
    setDocsModalIsOpen: Dispatch<SetStateAction<boolean>>,
    isEdittable?: boolean
}

export default function DocumentsCard(props: DocumentCard) {

    const sectionType = useSelector<ApplicationState>(state => selectNavigationInfo(state).sectionType) as MenuItemEnum
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useFormContext();

    const [fileStrings, setFileStrings] = useState<string[]>([])
    const [fileCount, setFileCount] = useState<number>(0)
    const [fileCountInitialized, setFileCountInitialized] = useState(false)
    const [docsModalIsOpen, setDocsModalIsOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<DocumentModalFormData>({ folderName: props.folderName, folderID: props.folderID } as DocumentModalFormData);
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData({ ...formData, folderID: props.folderID })
    }, [props.folderID])

    function handleDocChange(e: any) {
        console.log("files to save change" + e.target.files.length)
        if (e.target.files.length > 0) {
            console.log("set loading")
            dispatch(setGlobalLoading(true))
        }
        let numFilesUploaded = 0;
        const promise = new Promise((resolve, reject) => {
            for (let i = 0; i < e.target.files.length; i++) {
                UploadFile(e.target.files[i], formData, sectionType).then((results: Response) => {
                    if (results.ok) {
                        numFilesUploaded++
                        console.log("results: " + numFilesUploaded)
                        if (numFilesUploaded == e.target.files.length) resolve("on last file of the loop")
                    }
                    else {
                        batch(() => {
                            dispatch(setGlobalSnack({ open: true, message: "something went wrong while uploading", severity: "error" }))
                            dispatch(setGlobalLoading(false))
                        })

                    }
                })
            }
        })
        promise.then((data) => {
            console.log(data)
            console.log(fileStrings.length + e.target.files.length)
            setFileCount(fileStrings.length + e.target.files.length);
            dispatch(setGlobalLoading(false))

        })

    }

    useEffect(() => {
        setValue("folderID", props.folderID)
    }, [props.folderID])

    function getFileStrings(setCount:boolean) {
        let urlbeginning = "/Requests/"
        if (sectionType === "Protocols") {
            urlbeginning = "/Protocols/"
        }
        else if (sectionType == "Biomarkers") {
            urlbeginning = "/Biomarkers/"
        }
        var url = urlbeginning + "DocumentsModal?id=" + props.folderID + "&folderName=" + props.folderName;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: string[]) => {
                setFileStrings(result);
                if (setCount) {
                    setFileCount(result.length)
                }
                setValue("hasFile", result?.length > 0 ? true : false)
                dispatch(setGlobalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text.toString(), showBody: false } as CustomError))

                })
            })
    }

    useEffect(() => {
        console.log("changed folderID")
        getFileStrings(true)

    }, [props.folderID]);

    useEffect(() => {
        console.log("filecount " + fileCount)
        if (fileCountInitialized) {
            getFileStrings(false)
        }
        else {
            setFileCountInitialized(true)
        }

    }, [fileCount]);

    let hasFile = fileStrings?.length > 0 ? true : false;
    const iconFilter = hasFile ? "section-fill" : "gray-700-fill"

    return (
        <Stack spacing={.25} sx={{ textAlign: 'center' }}>
            <input type="hidden" {...register("hasFile")} value={hasFile.toString()} />
            <Grid container direction={"column"} className="document-card" data-testid="documentsCard" sx={{
                border: (theme) => `1px solid ${theme.palette.primary.main} !important;`,
                borderRadius: '.25rem', textAlign: 'center', padding: 1, height: '9.688rem', width: '9.688rem'
            }}>
                <Grid item xs={4} />
                <Grid item xs={4}>
                    <Button aria-label="open" onClick={() => {
                        setDocsModalIsOpen(true);
                    }}>
                        <SvgIcon name={getDocumentCardsDetailsByFolderEnum(props.folderName) ?? SvgNames.CentarixOrderedTab} height={2} className={iconFilter}></SvgIcon>
                    </Button>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex' }}>
                    <Grid container direction={'row'}>

                        <Grid item xs={8} sx={{ alignSelf: 'end', textAlign: 'left' }}>
                            <Typography sx={{ fontSize: '.75rem' }}>{hasFile ? fileStrings?.length : 0} Files</Typography>
                        </Grid>
                        <Grid xs={4} item sx={{ alignSelf: 'end', textAlign: 'right' }}>
                            <Button component="label" >
                                <SvgIcon name={SvgNames.CentarixFileUpload}
                                    height={1.25}
                                    className={iconFilter + ' btn-direct-upload'}></SvgIcon>
                                <input type="file" hidden
                                    multiple={props.allowMultipleFiles}
                                    onChange={handleDocChange} data-testid="fileUpload" />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Typography >{props.folderName}</Typography>
            {errors["hasFile"] && <StyledError>{errors["hasFile"]?.message}</StyledError>}
            {docsModalIsOpen && <DocumentsModal setFileCount={setFileCount} fileStrings={fileStrings} setDocsModalIsOpen={setDocsModalIsOpen} folderID={props.folderID} folderName={props.folderName} allowMultipleFiles={props.allowMultipleFiles} />}
        </Stack>
    )
}
