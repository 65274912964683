import { Box, Button, Grid, GridProps, Stack, Typography } from "@mui/material";
import { SvgNames } from "../Constants/app-enums";
import { useContext, useState } from 'react'
import { IsEdittableContext, SmallTypography, StyledLocationBox, SvgIcon, TinyTypography } from "../Utility/shared-components";
import { VisualItem } from "../LabManagement/locations-types";
import { useFormContext } from "react-hook-form";
import { ReceiveItemFormData } from "./Modals/receive-modal";
import { styled } from '@mui/material/styles';


export function SelectLocationVisualItem(props: { item: VisualItem, width: number, index: number, isArchived:boolean }) {
    const { control, formState, watch, getValues, reset, setValue } = useFormContext()
    const isEdittableContext = useContext(IsEdittableContext)
    const selectedLocations = watch("selectedLocations")
    const [item, setItem] = useState(props.item)

    function SelectLocationIcon() {
        const { setValue, trigger } = useFormContext<ReceiveItemFormData>()
    
        function handleClick(){
            if(selectedLocations?.includes(item.locationInstanceID)){
                if( props.width > 1){
                    setItem({...item, containsItems:false})
                }
                setValue("selectedLocations", selectedLocations.filter((l:number) => l != item.locationInstanceID) )
            }
            else{
                setValue("selectedLocations",   [...selectedLocations, item.locationInstanceID] )
            }
            trigger("selectedLocations")
        }
    
        return (
    
            <Box
                sx={{ alignSelf: "end" , cursor:"pointer"}}
                onClick={handleClick}>
                <SvgIcon name={selectedLocations?.includes(item.locationInstanceID) ?SvgNames.Delete:SvgNames.Add} height={1} />
            </Box>
    
        )
    }

    return (
        props.width > 1 ?
            <StyledLocationBox borderLeft={((props.index % props.width) == 0)?  theme=>"solid 1px " + theme.palette.grey[400] :"none"} className={selectedLocations?.includes(item.locationInstanceID) ? "section-bg-color" : ""} sx={{opacity: (props.isArchived ?".5":"1"), aspectRatio: "1/1", backgroundColor: item.containsItems ? "#eff4f8" : "white", padding: "0.3rem", justifyContent:"space-between", flexDirection:"column-reverse"}} item xs={1} key={item.locationInstanceID}>
                <TinyTypography alignSelf={"start"}>{item.title}</TinyTypography>
                {((!item.containsItems || selectedLocations?.includes(item.locationInstanceID)) && isEdittableContext.isEdittable && !props.isArchived) && <SelectLocationIcon/>}

            </StyledLocationBox>
            :
            <StyledLocationBox borderLeft={theme=>"solid 1px " + theme.palette.grey[400]} className={selectedLocations?.includes(item.locationInstanceID) ? "section-bg-color" : ""} sx={{opacity: (props.isArchived ?".5":"1"), minHeight: "5rem", padding: "1rem", maxHeight:"5rem" }} item xs={1} key={item.locationInstanceID}>
                {(isEdittableContext.isEdittable && !props.isArchived )&& <SelectLocationIcon />}
                <Typography>{item.title}</Typography>
            </StyledLocationBox>
    )

}


