import { DevTool } from "@hookform/devtools";
import { TableRows } from "@mui/icons-material";
import { Checkbox, CheckboxProps, Grid, GridProps, Select, SelectProps, SwitchProps, TableCell, TableRow, TableRowProps, Tooltip, TooltipProps, Typography, TypographyProps } from "@mui/material";
import Box, { BoxProps } from '@mui/material/Box';
import Button, { ButtonProps } from "@mui/material/Button";
import Stack, { StackProps } from "@mui/material/Stack";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Tab, { TabProps } from "@mui/material/Tab";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { FormProvider, useFormContext, useWatch } from "react-hook-form";
import { FolderNamesEnum, SvgNames } from "../Constants/app-enums";
import { ElixirSvg, Svgs } from '../images/Icons/SvgIcons';
import DocumentsCard from "../Shared/documents-card";
import { StyledControlledAutoCompleteInput, StyledControlledFormDateInput, StyledControlledFormInput } from "./custom-input-components";
import { AutocompleteOption, SelectListItem } from "./general-types";
import { Payment, CreditCard, CompanyAccount, PaymentType } from "./request-types";
const logo = require('../images/Centarix_Logo.png')
const nameLogo = require('../images/centarix_logo-02.png')


export const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)"
        }
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",

            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "white",
                border: "solid 1px black"
            }
        }
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        color: "black",
        transition: theme.transitions.create(["width"], {
            duration: 200
        })
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: "white",
        border: "solid 1px black",
        boxSizing: "border-box"
    }
}));


export function LogoLayout(props: PropsWithChildren<any>) {


    return (
        <CenterChildren>
            <NameLogo props={{
                sx: {
                    float: 'left',
                    position: 'absolute',
                    left: 0,
                    top: '.5%'
                }
            } as BoxProps} width={15} />
            <Stack spacing={4}
                sx={{
                    width: '21%',
                    margin: 'auto',
                    textAlign: 'center'
                }} >
                <Box component='img' alt="logo" src={logo} />
                {props.children}
            </Stack>
        </CenterChildren>
    );
};

type NameLogoProps = {
    props?: BoxProps,
    width: number
}
export function NameLogo(props: NameLogoProps) {
    return (<Box
        component='img'
        alt="logo"
        width={`${props.width}%`}
        src={nameLogo}
        {...props.props}
    />)
}


export const StyledError = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.error.main,
    fontSize: '.8rem',
    whiteSpace: 'pre-wrap'
}));
export const StyledWarning = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.palette.warning.main,
    fontSize: '.8rem',
    whiteSpace: 'pre-wrap'
}));


export const TitleTypography2 = styled(Typography)<TypographyProps>({
    fontSize: '1.75rem',
    fontWeight: "500"
});

export const TitleTypography = (props: TypographyProps) => {
    return <Typography fontSize="1.75rem" fontWeight="500" variant="h3" {...props} />
}

export const SmallTypography = styled(Typography)<TypographyProps>({
    fontSize: '.9rem',
});
export const TinyTypography = styled(Typography)<TypographyProps>({
    fontSize: '.7rem',
});

export const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: "4rem",
    minHeight: '41px'
}));

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: "0rem 1rem",
    height: "2.37rem",
    borderRadius: "0.5rem",
    fontSize: "1.2rem",
    fontWeight: theme.typography.fontWeightRegular,
    minWidth: "10rem",
    maxWidth: "15rem",
    whiteSpace:"nowrap"
}))

const StyledButtonWithoutMaxWidth = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: "0rem 0.5rem",
    height: "2.37rem",
    borderRadius: "0.5rem",
    fontSize: "1.2rem",
    fontWeight: theme.typography.fontWeightRegular,

}))

export const StyledFilteredButton = (props: ButtonProps) => {
    return <StyledButton className="section-bg-filter" {...props} />
}

export const StyledNoOutlineButton = (props: ButtonProps) => {
    return (
        <StyledButton className={props.disabled ? "" : "section-color"} {...props} sx={{ width: "auto" }} />)
}

export function StyledOutlineButton(props: ButtonProps) {
    return (
        <StyledButton fullWidth {...props} sx={{ border: (theme) => "0.1rem solid " + theme.palette.primary.main }} />
    );
}

export const StyledHoverOutlineButton = (props: ButtonProps) => {
    return (
        <Button className={props.disabled ? "" : "section-hover-outline"} {...props} />)
}

export function StyledSectionOutlineButton(props: ButtonProps) {
    return (
        <StyledButton fullWidth {...props} className='section-outline-color' />
    );
};

export function StyledFilledButton<C extends React.ElementType>(props: ButtonProps<C, { component?: C }>) {
    return (
        <StyledButton fullWidth  {...props} className={'section-bg-color ' + props.className} sx={{...{
            color: "white",
            fontWeight: "400",
            "&.Mui-disabled": {
                color: "white",
                opacity: ".5"
            }
        }, ...props.sx}} />
    );
}


export const LoginButton = styled(Button)<ButtonProps>(({ theme }) => ({
    backgroundColor: "#FF4852",
    width: 'auto',
    ":hover": { backgroundColor: "#FF4852" },
    color: "white",
    boxShadow: "none",
    height: "2.7rem",
    padding: "0rem 1rem",
    borderRadius: "0.5rem",
    fontSize: "1.2rem",
}))



export const StyledTableButton = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: "0rem 1.25rem",
    fontSize: theme.typography.fontSize,
    borderRadius: "0.375rem",
    fontWeight: theme.typography.fontWeightRegular,
    height: "2.25rem",
    color: "white"
}))

export function FilledTableButton(props: ButtonProps) {
    return (
        <StyledTableButton {...props} className='section-bg-color' />
    );
};
export function SectionOutlinedTableButton(props: ButtonProps) {
    return (
        <StyledTableButton {...props} className='section-outline-color' />
    );
};

export function OutlinedTableButton(props: ButtonProps) {
    return (
        <StyledTableButton {...props} sx={{ border: (theme) => "0.1rem solid " + theme.palette.primary.main, color: theme => theme.palette.primary.main }} />
    );
}

export function StyledTableImage(props: { src: string, width: number }) {
    return (
        <Box component="img" src={props.src} sx={{
            width: props.width + "rem",
            height:props.width +"rem",
            verticalAlign: "middle",
            borderRadius: "0.5rem",
            border: ".31px solid #ebebeb"
        }} />
    );
};

export function CenterChildren(props: PropsWithChildren<{ height?: string }>) {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: props.height ?? "98vh",
                width: "100%"
            }}
        >
            {props.children}
        </Box>)
}


interface IconProps {
    name: SvgNames,
    width?: number,
    height?: number,
    className?: string,
    color?: string
}

export function SvgIcon(props: IconProps) {
    var IconComponent = Svgs.find((i: ElixirSvg) => i.name == props.name)?.icon.ReactComponent ?? undefined

    return IconComponent != undefined ? <IconComponent data-testid={"svg" + props.name} className={props.className} name={props.name} height={props.height + "rem"} fill={props.color} /> : null
}

export const StyledHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: "1rem",
    //alignSelf: 'center',
    fontSize: "1.375rem",
    //alignItems: 'flex-end'
}));

export const StyledSubheader = (props: TypographyProps) => {
    return (
        <StyledHeader {...props} sx={{ fontSize: "1.125rem" }} className='section-color' />
    )
};

export const SectionCheckbox = (props: CheckboxProps) => {
    return (
        <Checkbox {...props} size="small" className='checkbox-section-color' />
    )
};

export function CloseButton(props: ButtonProps) {
    return (
        <Button variant='text' sx={{ position: "absolute", top: "1rem", right: "1rem", minWidth: "1rem" }} {...props}><SvgIcon name={SvgNames.Clear} height={1} color="gray" /></Button>
    )
}
export function CloseButtonLeftAlign(props: ButtonProps) {
    return (
        <Button variant='text' sx={{ paddingTop: "1rem", paddingLeft: "1rem", justifyContent: "start" }} {...props}><SvgIcon name={SvgNames.Clear} height={1} color="gray" /></Button>
    )
}

export function StyledOutlinedSectionDropdown(props: SelectProps) {
    return (
        <Select className="styled-outline-input section-outline-color" {...props} />
    )
}

export const BoldTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: theme.typography.fontWeightMedium,
}));

export function SectionBoldTypography(props: TypographyProps) {
    return (
        <BoldTypography className="section-color" {...props} />
    )
}

export function SpacedRowStack(props: StackProps) {
    return (
        <Stack direction="row" spacing={2} {...props} />
    )
}

export function SpacedColumnStack(props: StackProps) {
    return (
        <Stack spacing={2} {...props} />
    )
}

type FormProps = {
    formID: string,
    onSubmit: any,
    methods: any,
    isEdittable?: boolean
}
export const IsEdittableContext = React.createContext({ isEdittable: true, toggleIsEdittable: (v: boolean) => { } });


export function GlobalForm(props: PropsWithChildren<FormProps>) {
    const [submitState, setSubmitState] = useState<{ data: any, event: any, isSubmitting:boolean }>({ data: {}, event: {}, isSubmitting:false })
    const [isEdittable, setIsEdittable] = useState(props.isEdittable ?? true);
    const onError = (errors: any, e: any) => console.log(errors, e);
    const isSubmitting = props.methods.watch("isSubmitting")
    function onSubmitWrapper(data: any, e: any) {
        setSubmitState({ data: data, event: e , isSubmitting:true})
        console.log(data)
        console.log("in submit wrapper")
        props.methods.setValue("isSubmitting", true)
    }

    useEffect(() => {
        console.log("is submitting change " + isSubmitting)
        if (submitState.isSubmitting == true) {
            console.log("is valid " + props.methods.formState.isValid)

            props.methods.trigger().then((isValid: boolean) => {
                if (isValid) {
                    props.onSubmit(submitState.data, submitState.event)
                    props.methods.setValue("isSubmitting", false)
                    setSubmitState({...submitState, isSubmitting:false})
                }
                else {
                    props.methods.setValue("isSubmitting", false)
                    setSubmitState({...submitState, isSubmitting:false})
                }
            })
        }
    }, [submitState.isSubmitting])
    return (
        <IsEdittableContext.Provider value={{ isEdittable: isEdittable, toggleIsEdittable: setIsEdittable }}>
            <FormProvider {...props.methods}>
                <form style={{ display: "flex", flexDirection: "column", flexGrow:"1", paddingBottom:"0.5rem", maxHeight:"100%"}} id={props.formID} onSubmit={props.methods.handleSubmit((isSubmitting ? props.onSubmit : onSubmitWrapper), onError)}>
                    {process.env.NODE_ENV !== 'production' && <DevTool control={props.methods.control} />}
                    {props.children}

                </form>
            </FormProvider>
        </IsEdittableContext.Provider>
    )
}

export const StyledLocationBox = styled(Grid)<GridProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    borderRight: "solid 1px " + theme.palette.grey[400],
    borderBottom: "solid 1px " + theme.palette.grey[400]
}))




export function PaymentDetailsBlock(props: { creditCards?: CreditCard[], companyAccounts?: AutocompleteOption[], paymentTypes?: AutocompleteOption[], name: string }) {
    const form = useFormContext();
    const didMount = useRef(false)
    const payment= form.watch(props.name+".paymentTypeID") as Payment;
   return (<SpacedRowStack key="paymentDetails">
        <SpacedRowStack width="50%">
            <StyledControlledAutoCompleteInput label="Bank Name" options={props.companyAccounts  ?? [{text:"", value:0}] as AutocompleteOption[]} name={`${props.name}.companyAccountID`} />
            <StyledControlledAutoCompleteInput label="Payment Type" options={props.paymentTypes  ?? [{text:"", value:0}] as AutocompleteOption[]} name={`${props.name}.paymentTypeID`} />
        </SpacedRowStack>
        <SpacedRowStack width="50%">
            <PaymentTypeInfoBlock  key={payment?.paymentTypeID??""} creditCards={props.creditCards ?? []} name={props.name} />
        </SpacedRowStack>
    </SpacedRowStack>);
}

export function PaymentTypeInfoBlock(props: { creditCards: CreditCard[], name: string }) {
    const form = useFormContext();
    const didMount = useRef(false)
  
    const paymentTypeID=useWatch({name:props.name+".paymentTypeID"}) as number
    const companyAccountID= useWatch({name:props.name+".companyAccountID"})
    const [fieldsCleared, setFieldsCleared] = useState(false);
    useEffect(() => {
        console.log("paymet typeid")
        if (didMount.current) {
            form.setValue(`${props.name}.paymentReferenceDate`, "");
            form.setValue(`${props.name}.checkNumber`, "");
            form.setValue(`${props.name}.reference`, "");
            form.setValue(`${props.name}.creditCardID`, null);
            setFieldsCleared(!fieldsCleared);
        }
        else {
            didMount.current = true
        }
    }, [paymentTypeID])

    return (
        <>
            {
                {
                    1: <>
                        <StyledControlledAutoCompleteInput label="Card Number" options={props.creditCards?.filter(c => c.companyAccountID == companyAccountID)?.map(cc => { return { text: cc.cardNumber, value: cc.creditCardID } as AutocompleteOption }) ?? []} name={`${props.name}.creditCardID`} />
                        <StyledControlledFormDateInput key={"referenceDate" + fieldsCleared} label={"Reference Date"} maxDate={new Date()} name={`${props.name}.paymentReferenceDate`} />
                    </>,
                    2: <>
                        <StyledControlledFormDateInput key={"cashOutDate" + fieldsCleared}  label={"Cash Out Date"} name={`${props.name}.paymentReferenceDate`} />

                        <StyledControlledFormInput label={"Check Number"} name={`${props.name}.checkNumber`} />
                    </>,
                    3: <>
                        <StyledControlledFormDateInput key={"trasferDate" + fieldsCleared} label={"Transfer Date"} maxDate={new Date()} name={`${props.name}.paymentReferenceDate`} />

                        <StyledControlledFormInput label={"Payment Reference"} name={`${props.name}.reference`} />
                    </>
                }[paymentTypeID] || <></>
            }
        </>
    );
}

export function ModalItemTableCell(props: PropsWithChildren<any> & { width: number }) {

    return (
        <TableCell width={props.width + "%"} sx={{ paddingX: "1rem" }}>
            {props.children}
        </TableCell>
    )
}


export const ModalItemTableRow = styled(TableRow)<TableRowProps>(({ theme }) => ({
    border: "1px solid " + theme.palette.grey[300]
}))


export function AddInvoiceBlock(props: { guid: string, name: string }) {
    return <SpacedRowStack key="4" alignContent={"center"}>
        <SpacedColumnStack>
            <DocumentsCard allowMultipleFiles folderID={props.guid} folderName={FolderNamesEnum.Invoices} />
        </SpacedColumnStack>
        <SpacedColumnStack >
            <SpacedRowStack >
                <StyledControlledFormInput name={props.name + "invoice.invoiceNumber"} label="Invoice Number" />
            </SpacedRowStack>
            <SpacedRowStack >
                <StyledControlledFormDateInput label="Invoice Date" name={props.name + "invoice.invoiceDate"} maxDate={new Date()} />
            </SpacedRowStack>
        </SpacedColumnStack>
    </SpacedRowStack>;
}

export function StyledFullPageOutlinedBox(props: BoxProps){
    return( <Box className="section-color" sx={{display:"flex", flexDirection:"column", width: '55%', border: 'solid .125rem', margin: 'auto', borderRadius: "1.25rem", maxHeight:"80vh", height:"80vh" }} >
   
              {props.children}
           </Box>)
   }

export const AndroidSwitch = 
styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
    //   '&:after': {
    //     backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
    //       theme.palette.getContrastText(theme.palette.primary.main),
    //     )}" d="M19,13H5V11H19V13Z" /></svg>')`,
    //     right: 12,
    //   },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

export const SectionAndroidSwitch = (props:SwitchProps) =>{
    return <AndroidSwitch {...props} className="section-switch-color"/>
}

export function TooltipWithClickHandler(props: TooltipProps) {
    const [open, setOpen] = useState<boolean>(false);

    return <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onClick={() => setOpen(false)}
    >
        <Tooltip {...props} open={open} disableHoverListener disableFocusListener />
    </div>;
}