import 'regenerator-runtime/runtime';
import { MenuItemEnum } from '../Constants/app-enums';
import { DocumentModalFormData } from '../Requests/Modals/documents-modal';
import { objectToFormData } from './root-function';


var MaxFileSizeMB = 1;
var BufferChunkSize = MaxFileSizeMB * (1024 * 1024) * 2;



export async function UploadFile(TargetFile: any, formData: DocumentModalFormData, sectionType:MenuItemEnum) {

    // create array to store the buffer chunks
    let FileChunk: any[] = [];
    // the file object itself that we will work with
    let file = TargetFile;
    // set up other initial vars

    let FileStreamPos = 0;
    // set the initial chunk length
    let EndPos = BufferChunkSize;
    let Size = file.size;
    // add to the FileChunk array until we get to the end of the file
    while (FileStreamPos < Size) {
        // "slice" the file from the starting position/offset, to  the required length
        FileChunk.push(file.slice(FileStreamPos, EndPos));
        FileStreamPos = EndPos; // jump by the amount read
        EndPos = FileStreamPos + BufferChunkSize; // set next chunk length
    }
    // get total number of "files" we will be sending
    let TotalParts = FileChunk.length;
    let PartCount = 0;
    // loop through, pulling the first item from the array each time and sending it
    let FilePartName = file.name;
    let chunk:any;

    return new Promise<Response>(async (resolve, reject) => {
    while (PartCount !=TotalParts) {
        if (PartCount == 0) {
            formData.isFirstPart = true;
        }
        else {
            formData.isFirstPart = false;
        }
        chunk = FileChunk.shift();
        let response = await UploadFileChunk(chunk, FilePartName, formData, sectionType);
        PartCount++;
        console.log("partcount: "+PartCount+"/"+TotalParts)

        if(!response.ok){
            console.log("upload file failed")
            return resolve(response);            
        }
        if(PartCount==TotalParts){

            return resolve(response)
        }
    }})
}

async function UploadFileChunk(Chunk: any, FileName: any, formData: DocumentModalFormData, sectionType:MenuItemEnum) {
    console.log("upload file")
    formData.filesToSave=new File( [Chunk], FileName);
    let fileName = FileName;
    let urlbeginning = "/Requests/"
    if (sectionType === "Protocols") {
        urlbeginning = "/Protocols/"
    }
    else if (sectionType == "Biomarkers") {
        urlbeginning = "/Biomarkers/"
    }
    else if (sectionType == "Users") {
        urlbeginning = "/Users/"
    }
    return fetch(urlbeginning + 'UploadFile/',
    {
        method: "POST",
        body: objectToFormData(formData)
    }).then((response)=>{
        console.log("in response of upload chunk")
        return response
    })


}





export function GetFileFramePath(fileString: string) {
    var newFileString = "";
   // console.log("filestring: " + fileString)
    var ext = fileString?.split(".").pop();
    switch (ext) {
        case "pptx":
        case "ppt":
            newFileString = "images/powerpoint.png";
            break;
        case "doc":
        case "docx":
            newFileString = "images/wordicon.png";
            break;
        case "xlsx":
            newFileString = "images/excel.png";
            break;
        case "doc":
        case "docx":
            newFileString = "images/wordicon.png";
        default:
            newFileString = "/"+fileString
    }
    return newFileString;
}

export function GetRelativePath(longPathName:string, amountOfFiles: number) {
    console.log(longPathName);
    let index = 0; //initializing so no ts error...
    let stringToSearch = longPathName;

    for(let i = 0; i < amountOfFiles; i++) {
        index = stringToSearch.lastIndexOf("\\")
        stringToSearch = stringToSearch.substring(0, index)
    }
    let relativePath = longPathName.substring(index + 1)
    console.log(relativePath)
    return relativePath;
}