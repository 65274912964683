import { useState, useEffect } from "react";
import { SvgNames } from "../Constants/app-enums";
import { TabsName } from "../Constants/client-side-enums";
import CommentsTab from "../Requests/ItemCard/comments-tab";
import { AddSupplierViewModel, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { GlobalTab, Tab } from "../Shared/AddCardComponents/card-tabs";
import BudgetTab from "./budget-tab";
import DetailsTab from "./details-tab";
import PermissionsTab from "./permissions-tab";
import SecurityTab from "./security-tab";
import WorkScopeTab from "./work-scope-tab";

export const userTabInfo = [
    { icon: SvgNames.Face, name: TabsName.Details },
    { icon: SvgNames.MonetizationOn, name: TabsName.WorkScope },
    { icon: SvgNames.Security, name: TabsName.Security },
    { icon: SvgNames.Https, name:TabsName.Permissions },
    { icon: SvgNames.MonetizationOn, name:TabsName.Budget }
] as Tab[]


export default function UserTabs(props:{tabs:Tab[]}) {
    const [tabComponents, setTabComponents] = useState<JSX.Element[]>([]);
    
    useEffect(() => {
        let localTabs = [];
        for (let i = 0; i <props.tabs.length; i++) {
            localTabs.push(
                <GlobalTab key={props.tabs[i].name} tab={props.tabs[i]} index={i}>
                    <TabInnerComponent currentTab={props.tabs[i]} />
                </GlobalTab>)
        }

        setTabComponents(localTabs);
    }, [props.tabs])

    function TabInnerComponent(props: { currentTab: Tab}) {

        switch (props.currentTab.name) {
            case TabsName.Details:
                return <DetailsTab/>
                case TabsName.WorkScope:
                return <WorkScopeTab/>
                case TabsName.Security:
                return <SecurityTab/>
                case TabsName.Permissions:
                return <PermissionsTab/>
                case TabsName.Budget:
                return <BudgetTab/>
            default: return <></>
        }
    }
    return (<>{tabComponents}</>);
}