import { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';


import { DevTool } from "@hookform/devtools";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { ModalName, OrderType } from '../../Constants/app-enums';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { Request } from '../../Utility/request-types';
//mport { createModal } from '../../ReduxRelatedUtils/redux-types';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalLoading, selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';

type CartTotalViewModel = {
    request: Request,
    vendorName: string,
    vendorCartTotal: number,
    orderType: OrderType
}

export default function CartTotalModal() {
    console.log("in cart modal")
    const dispatch = useDispatch();
    const { handleSubmit, control } = useForm({ mode: 'onChange' });
    const loading = useSelector<ApplicationState>(state => selectModalLoading(state));
    const {modalInfo, modalStep} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;    

    const [state, setState] = useState({
        error: new CustomError(),
        viewModel: {} as CartTotalViewModel
    });

    useEffect(() => {
        fetch("/Requests/CartTotalModalGet/?requestID=" + modalInfo.ids[0], {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.json() }
            })
            .then((result: CartTotalViewModel) => {
                
                setState({ ...state, viewModel: result});
                dispatch(setModalLoading(false))
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setState({
                        ...state, error: { message: text.errorMessage, showBody: false }
                    })
                })
            })

    }, [modalInfo]);

    function onSubmit(e: any) {
        dispatch(setLoading(true))
        var url = "/Requests/CartTotalModal?requestID=" + modalInfo.ids[0]
        fetch(url, {
            method: "POST"
        })
            .then(response => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then(result => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message:"Approved. Added to cart.", severity: "success" }))
                })
            })
            .catch(err => {
                dispatch(setLoading(false))
                Promise.resolve(err).then(text => {
                    setState({
                        ...state, error: { message: text, showBody: true }
                    })
                })
            })

    };

    function getHeader() {
        return state.viewModel.request?.applicationUserCreator.firstName + " " + state.viewModel.request?.applicationUserCreator.lastName + " Budget Summary"
    }

    return (
        <GlobalModal key={ModalName.CartTotal} size={ModalSizes.sm} modalKey={ModalName.CartTotal}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={getHeader()} errorMessage={state.error.message} />
                    {state.error.showBody ?<>
                    <StyledDialogContent>
                <form onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data" id={modalStep}>
                    {process.env.NODE_ENV !== 'production' && <DevTool control={control} />}
                    <Grid container direction="column">
                        <Grid item>
                            <Typography>Current Cart Total for Supplier <span style={{fontWeight:"500"}}>{state.viewModel.vendorName}</span>:  ₪{Number(state.viewModel.vendorCartTotal).toFixed(2)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>Current Request Total:  ₪{Number(state.viewModel.request?.cost).toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                </form>
                </StyledDialogContent>
                    <ModalFooterWithSave submitButtonText='Approve' formID={ModalName.CartTotal} />
                    </>
                    : null
                }
                </>
            }
        </GlobalModal>

    );

}
