import { Typography, DialogActions } from "@mui/material"
import { Stack } from "@mui/system"
import { Dispatch, SetStateAction, useState } from "react"
import { useFormContext } from "react-hook-form"
import { InfoPopupName, ModalName } from "../../Constants/app-enums"
import { ModalSizes } from "../../Constants/client-side-enums"
import { StyledFilledButton, StyledNoOutlineButton, StyledOutlineButton, StyledSectionOutlineButton } from "../../Utility/shared-components"
import GlobalModal from "./global-modal"
import { ModalHeader, StyledDialogContent, ModalFooterWithCustomSave, ModalFooterWithSave } from "./modal-components"
import { EditCardType } from "../../Shared/edit-card"
import { ConfirmSaveModal } from "./confirm-save-modal"
import { useSelector } from "react-redux"
import { ApplicationState } from "../../ReduxRelatedUtils/redux-types"
import { selectEditDrawerViewModelByID } from "../../ReduxRelatedUtils/customConfigureStore"
import { RequestItemViewModel } from "../../Utility/request-types"
import { RowViewModel } from "../../Utility/general-types"

export function ConfirmExitModal(props: { setConfirmModalOpen: (arg:boolean)=>void,  discardAndExit:()=>void, formID:string , editType:EditCardType}) {
    const requestByProduct = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.formID ?? 0)?.viewModel as RequestItemViewModel)?.requestsByProduct) as  RowViewModel[];

    const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);
    const methods = useFormContext()
    return (
        <GlobalModal size={ModalSizes.sm} modalKey={ModalName.ConfirmDocInfoChange} hideBackdrop={true} closeClick={() => { props.setConfirmModalOpen(false) }}>

            <ModalHeader headerText="Close Item Card" errorMessage="" />

            <StyledDialogContent>
                <Typography>You have chosen to close the item card.
               Would you like to save the edits you made?</Typography>

            </StyledDialogContent>         
            
            <DialogActions sx={{ padding: "1rem 0 1rem 3rem" }}>
            <Stack direction={'row-reverse'} spacing={1} width="100%" justifyContent={"space-between"} alignItems="center">
                <ModalFooterWithCustomSave closeClick={()=>props.setConfirmModalOpen(false)}>
                {methods.formState.dirtyFields.request?.product ==undefined || requestByProduct?.length==undefined ||requestByProduct?.length==0?
                            <StyledFilledButton form={props.editType + props.formID} type="submit" name="exit">Save & Exit</StyledFilledButton>
                        :<>
                                <StyledFilledButton form={props.editType + props.formID} onClick={() => setConfirmSaveModalOpen(true)} >Save & Exit</StyledFilledButton>
                            {confirmSaveModalOpen &&  <ConfirmSaveModal formID={props.formID?.toString()??""} editType={props.editType} isExit={true} setConfirmModalOpen={setConfirmSaveModalOpen} />}
                        </>}
                </ModalFooterWithCustomSave>
                <StyledSectionOutlineButton onClick={props.discardAndExit}>Discard & Exit</StyledSectionOutlineButton>
            </Stack>
        </DialogActions>
          
          
                  
        </GlobalModal>

    )
}