import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { SvgNames } from '../Constants/app-enums';
import { CustomError } from '../Utility/general-types';
import { StyledError, SvgIcon } from '../Utility/shared-components';
import { LocationVisualItem } from '../LabManagement/location-visual-item';
import { VisualLocationViewModel } from '../LabManagement/locations-types';
import { SelectLocationVisualItem } from './select-location-visual-item';
import { ReceiveItemFormData } from './Modals/receive-modal';
import { useFieldArray, useFormContext } from 'react-hook-form';

export function SelectLocationsVisual(props:{parentLocationID: number, isArchived:boolean}){
    const [viewModel, setViewModel] = useState<VisualLocationViewModel>({} as VisualLocationViewModel)
    const [error, setError] = useState<CustomError>(new CustomError());

    useEffect(()=>{
            var url = "/Locations/GetLocationsVisual?parentLocationInstanceID="+props.parentLocationID;
            fetch(url, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    throw response.json();
                })
                .then((result: VisualLocationViewModel) => {
                    setViewModel(result);

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        setError({ message: "There was an error loading the visual: "+text.errorMessage, showBody: false })
                    })
                })
    },[props.parentLocationID])

    return(<Grid container flexGrow="1" alignItems={"center"}  maxWidth={"100%"} display="flex" justifyContent={"center"} border= {(theme)=>"solid 1px "+ theme.palette.grey[400]}>
  
    <Grid container  width={viewModel?.width>1?"100%":"20rem"}  borderTop={(theme)=>"solid 1px "+ theme.palette.grey[400]}  columns={viewModel?.width}>
       <StyledError>{error.message}</StyledError> 
    {viewModel?.visualItems?.map((v, i)=>
       <SelectLocationVisualItem key={v.locationInstanceID}  item={v} width={viewModel?.width} index={i} isArchived={props.isArchived} />
    )}
    
    </Grid>
     </Grid>)   
}