import Box from '@mui/material/Box';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigationInfo } from '../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState } from '../ReduxRelatedUtils/redux-types';
import { selectErrorMessage } from '../ReduxRelatedUtils/viewmodel-reducer';
import { StyledError } from '../Utility/shared-components';
import NavMenu from './nav-menu';

export default  (props: { children: JSX.Element})=>{
   const globalNavigationInfo = useSelector(selectNavigationInfo);
   const isHome = useSelector<ApplicationState>(state=>state.isHome) as boolean;
   const isAuthenticated = useSelector<ApplicationState>(state=>state.isAuthenticated) as boolean;
   const errorMessage = useSelector(selectErrorMessage)
   const dispatch = useDispatch();
//    useEffect(() => {
//         batch(() =>  {
//             dispatch(setViewModel({}))
//             dispatch(clearIndexTableObject())
//         })
//     }, [globalNavigationInfo]);

   return( 
  
        <Box overflow="auto" height="100%" className={globalNavigationInfo.sectionType} id="section-wrapper"  marginTop={'8.5vh'}>
        <NavMenu key={globalNavigationInfo.sectionType+globalNavigationInfo.sidebarType+globalNavigationInfo.pageType} {...globalNavigationInfo}/>
        <Box component="main" sx={{ flexGrow: 1, marginLeft: `${isHome||!isAuthenticated?'5.625rem':'12.625rem'}`,  marginRight: '5.625rem', marginTop:"5vh", marginBottom:"1vh"}}>
        <StyledError >{errorMessage}</StyledError>
           {props.children}
        </Box>
        </Box>

)};

