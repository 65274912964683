import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPageNumber } from '../../ReduxRelatedUtils/index-table-reducer';
import { ApplicationState } from '../../ReduxRelatedUtils/redux-types.js';
import IndexTableHeaders from './index-table-headers';
import { IndexTableObject, RequestIndexViewModel } from '../../Utility/request-types.js';
import EmptyPage from "../empty-page";
import IndexTableRow from './index-table-row';
import { selectPagedList } from '../../ReduxRelatedUtils/viewmodel-reducer';
import IndexTableSkeleton from './index-table-skeleton';


export default function IndexTableData() {
    //  console.log("index table data")
    const pagedList = useSelector(selectPagedList);
    const pageNumbers = useSelector<ApplicationState>(state => (state.viewModel as RequestIndexViewModel).pageNumbers) as number;
    const tableHeight = useSelector<ApplicationState>(state => (state.viewModel as RequestIndexViewModel).tableHeight) as string;
console.log("table height "+tableHeight)
    const pageNumber = useSelector<ApplicationState>(state => state.indexTableObject.pageNumber) as number;
    //const reloadIndex = useSelector<ApplicationState>(state => state.reloadIndex) as boolean
    const showPageSkeleton = useSelector((state: ApplicationState) => state.showPageSkeleton)

    const dispatch = useDispatch();

    // const [scrolled, setScrolled] = useState(false)
    // const [scrolledToBottom, setScrolledToBottom] = useState(false)
    // const prev = window.scrollY
    // function handleScroll (e:any) {
    //     const window = e.target;

    //     if (prev < window.scrollTop) {
    //       if(!scrolled){
    //         setScrolled(true)
    //       }
    //     } 
    //     else{
    //         if(scrolled)
    //       {setScrolled(false)}
    //     }
    //     const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    //     if (bottom) {
    //         setScrolledToBottom(true)
    //     }
    //     else{
    //         setScrolledToBottom(false)
    //     }
    // };

    return (
        <>
            {showPageSkeleton ?
                <IndexTableSkeleton /> :
                (pagedList?.length > 0) ?
                    <>
                        <TableContainer key="scrollable-table" component={Box}  height={tableHeight??"69vh"} sx={{ overflow: "hidden" }}>
                            <Table stickyHeader sx={{ minWidth: 650,  marginBottom: "1rem", maxHeight:tableHeight??"69vh", borderBottom:"1px solid #ebebeb"}} aria-label="simple table">
                                <IndexTableHeaders />
                                <TableBody sx={{ overflowY: "auto", display: "block", maxHeight: "calc("+(tableHeight??"69vh")+" - 3.7rem)" }} >
                                    {pagedList.map((r, i) => (
                                        <IndexTableRow sx={{borderBottom:i===pagedList?.length-1?"none !important":"1px solid #ebebeb"}} key={r.id} row={r} displaytable="true"/>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
{pageNumbers > 1 &&
                        <Stack spacing={2}>
                            <Pagination
                                page={pageNumber}
                                count={pageNumbers}
                                siblingCount={1}
                                boundaryCount={0}
                                showFirstButton
                                showLastButton
                                onChange={(e: any, page: number) => dispatch(setPageNumber(page))}
                                renderItem={(item) => (<PaginationItem
                                    components={{ first: KeyboardDoubleArrowLeft, last: KeyboardDoubleArrowRight, }}
                                    {...item}
                                />)}
                            />
                        </Stack>
}
                    </>
                    :
                    <div>
                        <EmptyPage />
                    </div>
            }
        </>
    )
}