import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChooseLocationResult, LocationTypeName, SidebarEnum } from "../Constants/app-enums";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { setReloadIndex } from "../ReduxRelatedUtils/index-table-reducer";
import { ApplicationState, LastSelectedLocation, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { clearLastSelectedLocation, setLastSelectedLocationReload } from "../ReduxRelatedUtils/selectedLocationReducer";
import viewmodelReducer, { setViewModel } from "../ReduxRelatedUtils/viewmodel-reducer";
import { CustomError } from "../Utility/general-types";
import { StyledError } from "../Utility/shared-components";
import LocationCardColumnView from "./location-card-column-view";
import LocationIndexTabs from "./locations-index-tabs";
import { LocationCardColumn, LocationIndexViewModel } from "./locations-types";
import { LocationsVisual } from "./locations-visual";
import LocationColumnSkeleton from "./location-column-skeleton";
import LocationTabsSkeleton from "./location-tabs-skeleton";

function LocationsIndex() {
    const dispatch = useDispatch()
    const [showVisual, setShowVisual] = useState(false)
    const error = useSelector<ApplicationState>(state => state.error) as CustomError
    const[isLoading,setIsLoading]=useState(true)
    const viewModel = useSelector<ApplicationState>(state => state.viewModel) as LocationIndexViewModel
    const reloadIndex = useSelector<ApplicationState>(state => state.reloadIndex)
    const lastSelectedLocation = useSelector<ApplicationState>(state => state.lastSelectedLocation) as LastSelectedLocation

    const { sidebarType, sectionType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo
    useEffect(() => {
      //  console.log(viewModel?.tabsViewModel?.selectedTab)
        getLocationIndexViewModel()
      
        dispatch(clearLastSelectedLocation())        
     
    }, [viewModel?.tabsViewModel?.selectedTab])

    useEffect(() => {
        if (reloadIndex) {
            getLocationIndexViewModel()
            dispatch(setReloadIndex(false))
        }
    }, [reloadIndex])

    useEffect(() => {
        if (lastSelectedLocation.reload) {       
        
            loadChildrenLocationInstances()
            dispatch(setLastSelectedLocationReload(false))
        }
    }, [lastSelectedLocation.reload])

    async function loadChildrenLocationInstances() {
        if(lastSelectedLocation.columnIndex ==-1){
            dispatch(setReloadIndex(true))
        }
        else{
       //    console.log(result);
        var newColumns = JSON.parse(JSON.stringify(viewModel.locationCardColumns)) as LocationCardColumn[];
        newColumns.splice(lastSelectedLocation.columnIndex + 1);
        newColumns[lastSelectedLocation.columnIndex].locationCards.map(card => card.selected = card.id == lastSelectedLocation.parentLocationID);
        dispatch(setViewModel({ ...viewModel, locationCardColumns: [...newColumns, {isLoading:true} as LocationCardColumn] }));
   
        await getLocationChildren(lastSelectedLocation, newColumns, viewModel.locationCardColumns,  setShowVisual, dispatch, sidebarType);
       
   
        dispatch(setViewModel({ ...viewModel, locationCardColumns: newColumns }));
        }
    }

    function getLocationIndexViewModel() {
        setShowVisual(false)
        dispatch(setViewModel({ ...viewModel, locationCardColumns: [ {isLoading:true} as LocationCardColumn] }));
   
        fetch("/Locations/LocationIndex?sidebar=" + sidebarType+"&sectionType=" + sectionType  + "&selectedTabId=" + viewModel?.tabsViewModel?.selectedTab ?? 0, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: LocationIndexViewModel) => {
                setIsLoading(false);
                dispatch(setViewModel(result));
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text.toString(), showBody: false }))
                })
            })
    }

    useEffect(() => {
        if (lastSelectedLocation?.parentLocationID) {
            loadChildrenLocationInstances();
        }
    }, [lastSelectedLocation.parentLocationID])

    return (
        <>
            {error.message && <StyledError>{error.message}</StyledError>}
            {error.showBody &&
                <Stack spacing={2} height="75vh" >
                    {viewModel?.tabsViewModel?.tabs?.length > 0 && <LocationIndexTabs {...viewModel.tabsViewModel} />
                    }
                    {isLoading && sidebarType!=SidebarEnum.Labs && <LocationTabsSkeleton/>}
                    <Stack direction="row" display="flex" height="100%" width="100%">
                        {viewModel?.locationCardColumns?.map((col, i) => {
                        if(col.isLoading){
                            return <LocationColumnSkeleton  key={i+"id"}/>
                        }else{
                           return <LocationCardColumnView columnIndex={i} key={i+"id"+col.locationParentID} column={col} />
                        }
                     
                             })
                        }
                        {showVisual && <LocationsVisual parentLocationID={lastSelectedLocation.parentLocationID} />}
                    </Stack>
                </Stack>
            }
        </>
    )
}



async function getLocationChildren(lastSelectedLocation: LastSelectedLocation, newColumns: LocationCardColumn[], existingColumn:LocationCardColumn[], setShowVisual: any, dispatch: any, sidebarType?: SidebarEnum,) {
   
    setShowVisual(false);
    return fetch("/Locations/GetLocationChildren?sidebar=" + sidebarType + "&parentLocationID=" + lastSelectedLocation.parentLocationID + "&parentColumnIndex=" + lastSelectedLocation.columnIndex,
        {
            method: "GET"
        })
        .then((response) => {
            if (response.ok) { return response.json(); }
            else { throw response.json(); }
        })
        .then((result: { column: LocationCardColumn; resultEnum: ChooseLocationResult; }) => {
 

            switch (result.resultEnum) {

                case ChooseLocationResult.ShowVisual:
                //    console.log("show visual");
                    setShowVisual(true);
                    break;
                case ChooseLocationResult.AddColumn:

                    newColumns[lastSelectedLocation.columnIndex+1]= result.column;
                    setShowVisual(false);
                    break;
            }

        })
        .catch(err => {
            Promise.resolve(err).then(text => {
                dispatch(setError({ message: text.errorMessage, showBody: true }));
                return existingColumn;
            });
        });
}

export function StorageTypesIndex() {
    return <LocationsIndex/>
}
export function LocationMapIndex() {
    return <LocationsIndex/>
}
export function LabsIndex() {
    return <LocationsIndex/>
}