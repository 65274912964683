import { createSlice } from '@reduxjs/toolkit'

const isHomeSlice = createSlice({
    name: 'isHome',
    initialState: false,
    reducers: {
        setIsHome(state:boolean, action) {
            return action.payload
        },
       
    }
})

export const { setIsHome } = isHomeSlice.actions
export default isHomeSlice.reducer
