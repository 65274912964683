import { FormControlLabel, Grid, Stack } from "@mui/material";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { MenuItemEnum } from "../Constants/app-enums";
import { selectEditDrawerViewModelByID } from "../ReduxRelatedUtils/customConfigureStore";
import { ApplicationState } from "../ReduxRelatedUtils/redux-types";
import { AddUserFormData, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { StyledCurrencyFormInput } from "../Utility/custom-input-components";
import { SectionAndroidSwitch, SpacedColumnStack, IsEdittableContext, SpacedRowStack, BoldTypography } from "../Utility/shared-components";
import { Role } from "../Utility/user-types";

export default function PermissionsTab() {
    const { watch, setValue, control, formState: { errors } } = useFormContext<AddUserFormData>();
    const userID = watch("employee.id")
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, userID)?.viewModel) as AddUserViewModel;
    const isEdittableContext = useContext(IsEdittableContext);


    const roles = watch("roles")
    function handlePermissionSwitch(roleDefinition: string) {
        console.log(roleDefinition)
        if (roles.includes(roleDefinition)) {
            console.log(Object.values(MenuItemEnum).map(m=>m.toString()))
            if(Object.values(MenuItemEnum).map(m=>m.toString()).includes(roleDefinition)){
                setValue("roles", roles.filter(r => !r.startsWith(roleDefinition)))
            }
            else{
            setValue("roles", roles.filter(r => r != roleDefinition))}
        }
        else {
            setValue("roles", [...roles, roleDefinition])
        }
    }

    const PermissionSwitch = (props:Role)=>{
        return <FormControlLabel
        disabled = {!isEdittableContext.isEdittable}
        onChange={() => handlePermissionSwitch(props.roleDefinition)}
        checked= {roles.includes(props.roleDefinition)}
        control={<SectionAndroidSwitch />}
        label={props.roleName}
        />
    }
    return (
        <SpacedColumnStack paddingBottom={"2rem"}>
            <Stack>
            <BoldTypography fontSize={"1.25rem"}>Orders and Inventory</BoldTypography>
            {viewModel.orderRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack>
            {/* <Stack>
            <BoldTypography fontSize={"1.25rem"}>Operations</BoldTypography>
            {viewModel.operationRoles.map(r =><PermissionSwitch {...r}/>  )}
            </Stack> */}
            <Stack>
            <BoldTypography fontSize={"1.25rem"}>Lab Managment</BoldTypography>
            {viewModel.labManagementRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack>
            {/* <Stack>
            <BoldTypography fontSize={"1.25rem"}>Reports</BoldTypography>
            {viewModel.expensesRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack> */}
            <Stack>
            <BoldTypography fontSize={"1.25rem"}>Timekeeper</BoldTypography>
            {viewModel.timekeeperRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack>
            {/* <Stack>
            <BoldTypography fontSize={"1.25rem"}>Protocols</BoldTypography>
            {viewModel.protocolRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack> */}
            <Stack>
            <BoldTypography fontSize={"1.25rem"}>Accounting</BoldTypography>
            {viewModel.accountingRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack>
            {/* <Stack>
            <BoldTypography fontSize={"1.25rem"}>Biomarkers</BoldTypography>
            {viewModel.biomarkerRoles.map(r =><PermissionSwitch {...r}/> )}
            </Stack> */}
            {/* <Stack>
            <BoldTypography fontSize={"1.25rem"}>Income</BoldTypography>
            {viewModel.incomeRoles.map(r => <PermissionSwitch {...r}/> )}
            </Stack> */}
            <Stack>
            <BoldTypography fontSize={"1.25rem"}>Users</BoldTypography>
            {viewModel.userRoles.map(r => <PermissionSwitch {...r}/>  )}
            </Stack>
        </SpacedColumnStack>
    )
}

