
import { Box } from "@mui/system";
import { resolve } from "path";
import { useContext, useState } from "react"
import { useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FolderNamesEnum, MenuItemEnum, ModalName } from "../Constants/app-enums";
import { ModalSizes } from "../Constants/client-side-enums";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { setLoading } from "../ReduxRelatedUtils/utilsReducer";
import { DocumentModalFormData } from "../Requests/Modals/documents-modal";
import GlobalModal from "../Requests/Modals/global-modal";
import { ModalFooterWithCustomSave, StyledDialogContent } from "../Requests/Modals/modal-components";
import { AddUserFormData } from "../Shared/AddCardComponents/add-types";
import { UploadFile } from "../Utility/document-functions";
import { CustomError } from "../Utility/general-types";
import { IsEdittableContext, SpacedColumnStack, SpacedRowStack, StyledFilledButton, StyledTableImage, TitleTypography } from "../Utility/shared-components"

export default function UserHeader() {
    const dispatch = useDispatch()
    const [userImageModalOpen, setUserImageModalOpen] = useState(false)
    const isEdittableContext = useContext(IsEdittableContext);


    const { watch, setValue, control, formState: { errors } } = useFormContext<AddUserFormData>();
    const employee = watch("employee")
    const guid = watch("guid")


    function handleDocChange(e: any) {
        console.log("user doc change")
        const data = {
            filesToSave: e.target.files[0],
            folderName: FolderNamesEnum.Users,
            folderID: guid,
            isFirstPart: true
        } as DocumentModalFormData
        const promise = new Promise((resolve, reject) => {
            UploadFile(e.target.files[0], data, MenuItemEnum.Users).then((results) => {
                if (results.ok) {
                    setValue("employee.userImage", "\\UserImages\\" + guid + "\\" + e.target.files[0].name)
                    dispatch(setLoading(false))
                }
                else {
                    dispatch(setError({ message: "something went wrong while uploading" } as CustomError))
                    dispatch(setLoading(false))

                }
            })
        })
    }



    return (
        <SpacedRowStack height="6rem" alignItems="center" paddingLeft={'2rem'}>
            <Box sx={{ cursor: "pointer" }} onClick={() => { isEdittableContext.isEdittable? setUserImageModalOpen(true): null }}>
                <StyledTableImage src={employee.userImage ? employee.userImage.substring(employee.userImage.indexOf("UserImages") - 1) : (window.location.origin + "/UserImages/user-image-placeholder.png")} width={6} />
            </Box>
            <TitleTypography>{`${employee.firstName ?? ""} ${employee.lastName ?? ""}`}</TitleTypography>
            <GlobalModal modalKey={ModalName.UserImage} isOpen={userImageModalOpen} closeClick={() => setUserImageModalOpen(false)} size={ModalSizes.sm}>
                <StyledDialogContent>
                    <SpacedColumnStack width="100%" alignItems={"center"}>
                        <StyledTableImage src={window.location.origin + (employee.userImage ? employee.userImage.substring(employee.userImage.indexOf("UserImages") - 1) : "/UserImages/user-image-placeholder.png")} width={20} />
                        <StyledFilledButton component={"label"}>Select Image
                            <input type="file" hidden
                                onChange={handleDocChange} multiple={false} />
                        </StyledFilledButton>
                    </SpacedColumnStack>
                </StyledDialogContent>
            </GlobalModal>
        </SpacedRowStack>
    )
}