import { Grid } from "@mui/material";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { ApplicationState, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { AddUserFormData, AddUserViewModel } from "../Shared/AddCardComponents/add-types";
import { StyledControlledFormInput } from "../Utility/custom-input-components";
import LoginPasswordInput, { PasswordInput } from "../Utility/password-input";
import { IsEdittableContext, OutlinedTableButton, SectionOutlinedTableButton, SpacedColumnStack } from "../Utility/shared-components";

export default function SecurityTab() {
    const dispatch = useDispatch()
    const { watch, setValue, control, formState: { errors } } = useFormContext<AddUserFormData>();
    const { sidebarType } = useSelector<ApplicationState>(selectNavigationInfo) as NavigationInfo
    const isEdittableContext = useContext(IsEdittableContext);

    function generatePassword(){
        fetch("/Users/GetGeneratedPassword", {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.text() }
                else { throw response.text() }
            })
            .then((result: string) => {
                setValue("password", result)

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setGlobalSnack({open:true, message: text, severity:"error"}))
                })
            })
    }
    return (
        <SpacedColumnStack paddingBottom={"2rem"}>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PasswordInput readOnly name="password" label="Password" />
                </Grid>
                <Grid item xs={6}>
                    <StyledControlledFormInput name="secureAppPass" label="Google Secure App Password" />
                </Grid>
                <Grid item xs={6}>
                    <SectionOutlinedTableButton disabled={!isEdittableContext.isEdittable} fullWidth onClick={generatePassword}>Generate Password</SectionOutlinedTableButton>
                </Grid>
            </Grid>
        </SpacedColumnStack>
    )
}