import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AnySchema, array, object } from 'yup';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent, selectNavigationInfo } from '../../ReduxRelatedUtils/customConfigureStore';
import { setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { StyledControlledMultipleSelect } from '../../Utility/custom-input-components';
import { BasicModalInfo, CustomError, SelectListItem } from '../../Utility/general-types';
import { basicModalPost, objectToFormData } from '../../Utility/root-function';
import { GlobalForm } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';

type ShareModalViewData = {
    objectDescription: string,
    applicationUsers: SelectListItem[]
}

type ShareModalFormData = {
    id: string,
    selectedApplicationUsers: SelectListItem[]
}

export default function ShareModal() {
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo)
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;   
    const validationSchema = object<Partial<Record<keyof ShareModalFormData, AnySchema>>>({
        selectedApplicationUsers: array().min(1, "User is required")
    })
    const [error, setError] = useState<CustomError>(new CustomError())
    const [viewModel, setViewModel] = useState( {} as ShareModalViewData)

    const methods = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues:
            { id: modalInfo.ids[0], selectedApplicationUsers: [] as { text: string, value: string }[] } as ShareModalFormData
    }
    );


    useEffect(() => {
        var url = "/Requests/ShareModal?id=" + modalInfo.ids[0] //+ "&modelsEnum=" + state.modelsEnum;
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: ShareModalViewData) => {
                setViewModel(result);
                dispatch(setModalLoading(false))

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    setError( { message: text, showBody: false })
                })
            })

    }, [modalInfo.ids]);

    function onSubmit(data: ShareModalFormData) {
        var url = "/" + navigationInfo.sectionType + "/ShareModal";
        var formData = objectToFormData(data);
        basicModalPost(url, setError, dispatch, formData)
    }

    return (
        <GlobalModal key={ModalName.Share} size={ModalSizes.xs} modalKey={ModalName.Share}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Share " + viewModel?.objectDescription + " With"} errorMessage={error.message} />
                    <StyledDialogContent>
                        {error.showBody ?
                          <GlobalForm methods={methods} formID={ModalName.Share} onSubmit={onSubmit}>
                                <StyledControlledMultipleSelect name="selectedApplicationUsers" label="Users" placeholder='Add User' options={viewModel?.applicationUsers}/>
                               
                            </GlobalForm>
                            : null}
                    </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.Share} />
                </>
            }
        </GlobalModal>
    );

}