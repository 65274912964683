import { Grid } from "@mui/material";
import { StyledControlledFormInput } from "../Utility/custom-input-components";

export default function PaymentTab() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorBank" label="Bank Name" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorBankBranch" label="Branch" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorAccountNum" label="Account Number" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorSwift" label="Swift" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorBIC" label="BIC" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorRoutingNum" label="Routing Number" />
            </Grid>
            <Grid item xs={4}>
                <StyledControlledFormInput name="vendor.vendorGoldAccount" label="Gold Account" />
            </Grid>
        </Grid>
    )
}