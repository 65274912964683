export enum PriceSortEnum {
Unit = 'Unit',
Total = 'Total',
Vat = 'Vat',
TotalVat = 'TotalVat',
}

export enum RecurrenceEndStatuses {
NoEnd = 'NoEnd',
EndDate = 'EndDate',
LimitedOccurrences = 'LimitedOccurrences',
}

export enum TimePeriods {
Days = 'Days',
Weeks = 'Weeks',
Months = 'Months',
}

export enum TermsModalEnum {
PayNow = 'PayNow',
PayWithInMonth = 'PayWithInMonth',
Installments = 'Installments',
Paid = 'Paid',
}

export enum RoleEnum {
ApproveOrders = 'ApproveOrders',
}

export enum PageTypeEnum {
None = 'None',
RequestRequest = 'RequestRequest',
RequestInventory = 'RequestInventory',
RequestCart = 'RequestCart',
RequestSearch = 'RequestSearch',
RequestLocation = 'RequestLocation',
RequestSummary = 'RequestSummary',
RequestFavorite = 'RequestFavorite',
AccountingNotifications = 'AccountingNotifications',
AccountingGeneral = 'AccountingGeneral',
AccountingExpenses = 'AccountingExpenses',
AccountingSuppliers = 'AccountingSuppliers',
AccountingPayments = 'AccountingPayments',
AccountingSettings = 'AccountingSettings',
LabManagementSuppliers = 'LabManagementSuppliers',
LabManagementLocations = 'LabManagementLocations',
LabManagementEquipment = 'LabManagementEquipment',
LabManagementQuotes = 'LabManagementQuotes',
LabManagementSearch = 'LabManagementSearch',
LabManagementSettings = 'LabManagementSettings',
TimeKeeperReport = 'TimeKeeperReport',
TimekeeperSummary = 'TimekeeperSummary',
UsersUser = 'UsersUser',
UsersWorkers = 'UsersWorkers',
OperationsRequest = 'OperationsRequest',
OperationsInventory = 'OperationsInventory',
OperationsCart = 'OperationsCart',
ExpensesSummary = 'ExpensesSummary',
ExpensesStatistics = 'ExpensesStatistics',
ExpensesCost = 'ExpensesCost',
ExpensesWorkers = 'ExpensesWorkers',
ProtocolsWorkflow = 'ProtocolsWorkflow',
ProtocolsProtocols = 'ProtocolsProtocols',
ProtocolsCreate = 'ProtocolsCreate',
ProtocolsReports = 'ProtocolsReports',
ProtocolsResources = 'ProtocolsResources',
ProtocolsSearch = 'ProtocolsSearch',
ProtocolsTask = 'ProtocolsTask',
BiomarkersExperiments = 'BiomarkersExperiments',
}

export enum SetPaymentDatesType {
None = 'None',
UponArrival = 'UponArrival',
SetDate = 'SetDate',
}

export enum ImageType {
RoundedSquare = 'RoundedSquare',
Avatar = 'Avatar',
}

export enum SidebarEnum {
None = 'None',
Type = 'Type',
Vendors = 'Vendors',
Owner = 'Owner',
Search = 'Search',
General = 'General',
AllSuppliers = 'AllSuppliers',
NewSupplier = 'NewSupplier',
Orders = 'Orders',
Quotes = 'Quotes',
PendingOrders = 'PendingOrders',
List = 'List',
Calibrate = 'Calibrate',
Location = 'Location',
Cart = 'Cart',
Favorites = 'Favorites',
Notifications = 'Notifications',
MyLists = 'MyLists',
SharedLists = 'SharedLists',
ReportHours = 'ReportHours',
SummaryHours = 'SummaryHours',
ReportDaysOff = 'ReportDaysOff',
SummaryDaysOff = 'SummaryDaysOff',
Documents = 'Documents',
CompanyAbsences = 'CompanyAbsences',
PieCharts = 'PieCharts',
Tables = 'Tables',
Graphs = 'Graphs',
Project = 'Project',
Item = 'Item',
Worker = 'Worker',
Category = 'Category',
Details = 'Details',
Hours = 'Hours',
Salary = 'Salary',
MonthlyPayment = 'MonthlyPayment',
PayNow = 'PayNow',
PayLater = 'PayLater',
Installments = 'Installments',
StandingOrders = 'StandingOrders',
NoInvoice = 'NoInvoice',
MissingPaymentDetails = 'MissingPaymentDetails',
DidntArrive = 'DidntArrive',
PartialDelivery = 'PartialDelivery',
ForClarification = 'ForClarification',
Add = 'Add',
AwaitingApproval = 'AwaitingApproval',
SpecifyPayment = 'SpecifyPayment',
CurrentProtocols = 'CurrentProtocols',
Projects = 'Projects',
SharedProjects = 'SharedProjects',
Calendar = 'Calendar',
MyProtocols = 'MyProtocols',
ResearchProtocol = 'ResearchProtocol',
KitProtocol = 'KitProtocol',
SOPProtocol = 'SOPProtocol',
BufferCreating = 'BufferCreating',
RoboticProtocol = 'RoboticProtocol',
MaintenanceProtocol = 'MaintenanceProtocol',
DailyReports = 'DailyReports',
WeeklyReports = 'WeeklyReports',
MonthlyReports = 'MonthlyReports',
Library = 'Library',
Personal = 'Personal',
SharedWithMe = 'SharedWithMe',
Active = 'Active',
Done = 'Done',
LastProtocol = 'LastProtocol',
SharedRequests = 'SharedRequests',
HumanTrials = 'HumanTrials',
Inventory = 'Inventory',
StorageType = 'StorageType',
LocationMap = 'LocationMap',
Labs = 'Labs',
BankAccounts = 'BankAccounts',
CreditCards = 'CreditCards',
}

export enum LocationTypeName {
None = 'None',
Building = 'Building',
Room = 'Room',
Floor = 'Floor',
ParentStorageUnit = 'ParentStorageUnit',
StorageUnit = 'StorageUnit',
Lab = 'Lab',
}

export enum IndexTableTypes {
Approved = 'Approved',
Ordered = 'Ordered',
ReceivedInventory = 'ReceivedInventory',
ReceivedInventoryFavorites = 'ReceivedInventoryFavorites',
ReceivedInventoryShared = 'ReceivedInventoryShared',
Summary = 'Summary',
AccountingGeneral = 'AccountingGeneral',
SummaryProprietary = 'SummaryProprietary',
ReceivedInventoryOperations = 'ReceivedInventoryOperations',
OrderedOperations = 'OrderedOperations',
RecurringExpensesOperations = 'RecurringExpensesOperations',
Cart = 'Cart',
CartOperations = 'CartOperations',
AccountingNotifications = 'AccountingNotifications',
AccountingPaymentsDefault = 'AccountingPaymentsDefault',
AccountingPaymentsInstallments = 'AccountingPaymentsInstallments',
LabQuotes = 'LabQuotes',
LabOrders = 'LabOrders',
RequestLists = 'RequestLists',
UsersList = 'UsersList',
EmployeeDetails = 'EmployeeDetails',
EmployeeHours = 'EmployeeHours',
AwaitingApproval = 'AwaitingApproval',
SuppliersList = 'SuppliersList',
}

export enum NumberingMethodTypeEnum {
None = 'None',
PredefinedList = 'PredefinedList',
AnyAmount = 'AnyAmount',
LimitedAmount = 'LimitedAmount',
}

export enum FilterEnum {
None = 'None',
Price = 'Price',
Category = 'Category',
Amount = 'Amount',
}

export enum YearlyMonthlyEnum {
None = 'None',
Yearly = 'Yearly',
Monthly = 'Monthly',
}

export enum EntryExitEnum {
Entry1 = 'Entry1',
Exit1 = 'Exit1',
Entry2 = 'Entry2',
Exit2 = 'Exit2',
None = 'None',
}

export enum CommentTypeEnum {
Warning = 'Warning',
Comment = 'Comment',
}

export enum TempDataTypes {
MenuType = 'MenuType',
PageType = 'PageType',
SidebarType = 'SidebarType',
}

export enum IndexTabs {
None = 'None',
Requests = 'Requests',
Ordered = 'Ordered',
Received = 'Received',
RecurringExpenses = 'RecurringExpenses',
Main = 'Main',
Samples = 'Samples',
NeedsQuote = 'NeedsQuote',
NeedsConfirmation = 'NeedsConfirmation',
}

export enum FolderNamesEnum {
Files = 'Files',
Orders = 'Orders',
Invoices = 'Invoices',
Shipments = 'Shipments',
Quotes = 'Quotes',
Info = 'Info',
Pictures = 'Pictures',
Returns = 'Returns',
Credits = 'Credits',
More = 'More',
Warranty = 'Warranty',
Manual = 'Manual',
S = 'S',
Map = 'Map',
Details = 'Details',
Custom = 'Custom',
Users = 'Users',
}

export enum ParentFolderName {
None = 'None',
Protocols = 'Protocols',
Requests = 'Requests',
Materials = 'Materials',
FunctionLine = 'FunctionLine',
Reports = 'Reports',
ParentQuote = 'ParentQuote',
ExperimentEntries = 'ExperimentEntries',
ParentRequest = 'ParentRequest',
FunctionResults = 'FunctionResults',
UserImages = 'UserImages',
Invoice = 'Invoice',
}

export enum MenuItemEnum {
Requests = 'Requests',
Protocols = 'Protocols',
Operations = 'Operations',
Biomarkers = 'Biomarkers',
TimeKeeper = 'TimeKeeper',
LabManagement = 'LabManagement',
Accounting = 'Accounting',
Reports = 'Reports',
Income = 'Income',
Users = 'Users',
}

export enum ModalType {
None = 'None',
Terms = 'Terms',
UploadOrder = 'UploadOrder',
UploadQuote = 'UploadQuote',
ConfirmEmail = 'ConfirmEmail',
Reorder = 'Reorder',
}

export enum RequestStatus {
New = 'New',
Ordered = 'Ordered',
ReceivedAndIsInventory = 'ReceivedAndIsInventory',
Approved = 'Approved',
SavedToInventory = 'SavedToInventory',
AwaitingQuoteRequest = 'AwaitingQuoteRequest',
AwaitingQuoteResponse = 'AwaitingQuoteResponse',
QuoteReceived = 'QuoteReceived',
AwaitingQuoteConfirmation = 'AwaitingQuoteConfirmation',
AwaitingOrderConfirmation = 'AwaitingOrderConfirmation',
}

export enum VendorCountries {
Israel = 'Israel',
NorthAmerica = 'NorthAmerica',
SouthAmerica = 'SouthAmerica',
Europe = 'Europe',
Asia = 'Asia',
}

export enum StatusFilterEnum {
Ordered = 'Ordered',
Received = 'Received',
PendingPayment = 'PendingPayment',
PartialPayment = 'PartialPayment',
Paid = 'Paid',
NoInvoice = 'NoInvoice',
PartialInvoice = 'PartialInvoice',
Invoiced = 'Invoiced',
SelectAll = 'SelectAll',
}

export enum StatusFilterType {
Order = 'Order',
Payment = 'Payment',
Invoice = 'Invoice',
SelectAll = 'SelectAll',
}

export enum InstallmentDivisionType {
SetPayments = 'SetPayments',
VariedPayments = 'VariedPayments',
}

export enum InfoPopupName {
EditSupplier = 'EditSupplier',
EditItem = 'EditItem',
ConfigureInstallments = 'ConfigureInstallments',
}

export enum ColumnDisplayOptionProperty {
None = 'None',
Checkbox = 'Checkbox',
RadioButton = 'RadioButton',
}

export enum ColumnValueDisplayOption {
None = 'None',
Source = 'Source',
Category = 'Category',
Subcategory = 'Subcategory',
Unit = 'Unit',
Total = 'Total',
Vat = 'Vat',
TotalVat = 'TotalVat',
USD = 'USD',
NIS = 'NIS',
Units = 'Units',
Subunits = 'Subunits',
Subunits2 = 'Subunits2',
}

export enum ColumnValueDisplayFormat {
NIS = 'NIS',
USD = 'USD',
}

export enum PageHeaderEnum {
Search = 'Search',
Filter = 'Filter',
MonthlyYearlyToggle = 'MonthlyYearlyToggle',
MonthsDropdown = 'MonthsDropdown',
YearsDropdown = 'YearsDropdown',
ListSettingsButton = 'ListSettingsButton',
VendorSelect = 'VendorSelect',
StatusSelect = 'StatusSelect',
InstallmentsGroupAndSort = 'InstallmentsGroupAndSort',
AddBankAccount = 'AddBankAccount',
AddCreditCard = 'AddCreditCard',
BackArrow = 'BackArrow',
TotalsSummary = 'TotalsSummary',
ReportDaysOff = 'ReportDaysOff',
ExportTable = 'ExportTable',
}

export enum RoleDefinition {
Requests = 'Requests',
RequestsRequestItem = 'RequestsRequestItem',
RequestsOrderItem = 'RequestsOrderItem',
RequestsApproveOrders = 'RequestsApproveOrders',
RequestsEditReceived = 'RequestsEditReceived',
RequestsDeleteReceived = 'RequestsDeleteReceived',
Protocols = 'Protocols',
ProtocolsBiomarkers = 'ProtocolsBiomarkers',
ProtocolsRejuvenation = 'ProtocolsRejuvenation',
ProtocolsDeliverySystems = 'ProtocolsDeliverySystems',
Operations = 'Operations',
OperationsApproveOrders = 'OperationsApproveOrders',
Biomarkers = 'Biomarkers',
TimeKeeper = 'TimeKeeper',
LabManagement = 'LabManagement',
LabManagementOrder = 'LabManagementOrder',
Accounting = 'Accounting',
Reports = 'Reports',
AccountingEdit = 'AccountingEdit',
Income = 'Income',
Users = 'Users',
UsersViewHours = 'UsersViewHours',
UsersAddUser = 'UsersAddUser',
UsersApproveHours = 'UsersApproveHours',
}

export enum EditDrawerEnum {
EditUser = 'EditUser',
EditSupplier = 'EditSupplier',
EditRequest = 'EditRequest',
EditProduct = 'EditProduct',
}

export enum RoleItems {
Admin = 'Admin',
CEO = 'CEO',
}

export enum CurrencyEnum {
None = 'None',
NIS = 'NIS',
USD = 'USD',
}

export enum PaymentsPopoverEnum {
MonthlyPayment = 'MonthlyPayment',
PayNow = 'PayNow',
PayLater = 'PayLater',
Installments = 'Installments',
SpecifyPayment = 'SpecifyPayment',
}

export enum PaymentsEnum {
ToPay = 'ToPay',
PayNow = 'PayNow',
}

export enum SuppliersEnum {
All = 'All',
NewSupplier = 'NewSupplier',
Search = 'Search',
}

export enum CategoryTypeEnum {
Operations = 'Operations',
Lab = 'Lab',
}

export enum ParentCategoryEnum {
Consumables = 'Consumables',
ReagentsAndChemicals = 'ReagentsAndChemicals',
Samples = 'Samples',
Reusable = 'Reusable',
Equipment = 'Equipment',
Operation = 'Operation',
Biological = 'Biological',
Safety = 'Safety',
General = 'General',
Clinical = 'Clinical',
}

export enum RequestModalType {
Create = 'Create',
Edit = 'Edit',
Summary = 'Summary',
Reorder = 'Reorder',
}

export enum ProtocolModalType {
None = 'None',
Create = 'Create',
CheckListMode = 'CheckListMode',
Summary = 'Summary',
Edit = 'Edit',
SummaryFloat = 'SummaryFloat',
CreateNewVersion = 'CreateNewVersion',
}

export enum VendorModalType {
Create = 'Create',
Edit = 'Edit',
SummaryFloat = 'SummaryFloat',
}

export enum OrderMethod {
None = 'None',
RequestItem = 'RequestItem',
OrderNow = 'OrderNow',
AddToCart = 'AddToCart',
AlreadyPurchased = 'AlreadyPurchased',
Save = 'Save',
ExcelUpload = 'ExcelUpload',
}

export enum CartStatus {
None = 'None',
InCart = 'InCart',
Ordered = 'Ordered',
}

export enum OrderType {
SingleOrder = 'SingleOrder',
RecurringOrder = 'RecurringOrder',
StandingOrder = 'StandingOrder',
}

export enum OffDayTypeEnum {
VacationDay = 'VacationDay',
SickDay = 'SickDay',
MaternityLeave = 'MaternityLeave',
SpecialDay = 'SpecialDay',
UnpaidLeave = 'UnpaidLeave',
}

export enum PaymentTypes {
CreditCard = 'CreditCard',
Check = 'Check',
Wire = 'Wire',
}

export enum IconDescription {
More = 'More',
Share = 'Share',
Delete = 'Delete',
Reorder = 'Reorder',
RemoveShare = 'RemoveShare',
Start = 'Start',
Continue = 'Continue',
AddToList = 'AddToList',
MoveToList = 'MoveToList',
DeleteFromList = 'DeleteFromList',
MonthlyPayment = 'MonthlyPayment',
PayNow = 'PayNow',
PayLater = 'PayLater',
Installments = 'Installments',
Order = 'Order',
Recieve = 'Recieve',
SpecifyPayment = 'SpecifyPayment',
Favorite = 'Favorite',
Unfavorite = 'Unfavorite',
Approve = 'Approve',
NeedsApproval = 'NeedsApproval',
Resend = 'Resend',
UploadQuote = 'UploadQuote',
Pay = 'Pay',
AddInvoice = 'AddInvoice',
UpdatePaymentDetails = 'UpdatePaymentDetails',
Partial = 'Partial',
Clarify = 'Clarify',
DidntArrive = 'DidntArrive',
Suspend = 'Suspend',
Unsuspend = 'Unsuspend',
DenyHours = 'DenyHours',
RevertStatus = 'RevertStatus',
ConfirmOrder = 'ConfirmOrder',
NeedConfirmation = 'NeedConfirmation',
ReviewQuote = 'ReviewQuote',
RemoveRoom = 'RemoveRoom',
Edit = 'Edit',
Move = 'Move',
OutOfService = 'OutOfService',
Block = 'Block',
Unblock = 'Unblock',
}

export enum ButtonDescription {
Resend = 'Resend',
Approve = 'Approve',
UpdateExit = 'UpdateExit',
UpdateHours = 'UpdateHours',
Ask = 'Ask',
}

export enum PopoverEnum {
None = 'None',
}

export enum FavoriteModels {
Resources = 'Resources',
Requests = 'Requests',
Protocols = 'Protocols',
}

export enum FavoriteTables {
FavoriteResources = 'FavoriteResources',
FavoriteRequests = 'FavoriteRequests',
FavoriteProtocols = 'FavoriteProtocols',
}

export enum FavoriteIconTitle {
FilledIn = 'FilledIn',
Empty = 'Empty',
}

export enum ProtocolFunctionTypes {
AddImage = 'AddImage',
AddTimer = 'AddTimer',
AddComment = 'AddComment',
AddWarning = 'AddWarning',
AddTip = 'AddTip',
AddStop = 'AddStop',
AddLinkToProduct = 'AddLinkToProduct',
AddLinkToProtocol = 'AddLinkToProtocol',
AddFile = 'AddFile',
}

export enum FunctionTypes {
AddImage = 'AddImage',
AddTimer = 'AddTimer',
AddComment = 'AddComment',
AddWarning = 'AddWarning',
AddTip = 'AddTip',
AddStop = 'AddStop',
AddLinkToProduct = 'AddLinkToProduct',
AddLinkToProtocol = 'AddLinkToProtocol',
AddFile = 'AddFile',
}

export enum ReportsFunctionTypes {
AddFile = 'AddFile',
}

export enum ResultsFunctionTypes {
AddImage = 'AddImage',
AddLinkToProduct = 'AddLinkToProduct',
AddLinkToProtocol = 'AddLinkToProtocol',
AddFile = 'AddFile',
}

export enum ReportTypes {
Daily = 'Daily',
Weekly = 'Weekly',
Monthly = 'Monthly',
}

export enum IconNamesEnum {
Share = 'Share',
Favorite = 'Favorite',
MorePopover = 'MorePopover',
Edit = 'Edit',
RemoveShare = 'RemoveShare',
}

export enum ModelsEnum {
Request = 'Request',
Resource = 'Resource',
Protocols = 'Protocols',
RequestLists = 'RequestLists',
Product = 'Product',
ParentQuote = 'ParentQuote',
ParentRequest = 'ParentRequest',
Payment = 'Payment',
RequestComment = 'RequestComment',
ProductComment = 'ProductComment',
RequestNotification = 'RequestNotification',
}

export enum GlobalInfoType {
ExchangeRate = 'ExchangeRate',
TimekeeperNotificationUpdated = 'TimekeeperNotificationUpdated',
BirthdayNotificationUpdated = 'BirthdayNotificationUpdated',
LoginUpdates = 'LoginUpdates',
LastProtocolLine = 'LastProtocolLine',
MondayBoardUpdated = 'MondayBoardUpdated',
LastOrderNumber = 'LastOrderNumber',
OrderLateNotificationsUpdated = 'OrderLateNotificationsUpdated',
}

export enum DataTypeEnum {
String = 'String',
Double = 'Double',
DateTime = 'DateTime',
Bool = 'Bool',
File = 'File',
LongText = 'LongText',
}

export enum DataCalculation {
None = 'None',
BMI = 'BMI',
}

export enum GroupByOptions {
Vendor = 'Vendor',
Month = 'Month',
OrderNumber = 'OrderNumber',
}

export enum SortByOptions {
Date = 'Date',
Product = 'Product',
}

export enum LoginResult {
Succeeded = 'Succeeded',
NeedsToResetPassword = 'NeedsToResetPassword',
RequiresTwoFactor = 'RequiresTwoFactor',
InvalidAttempt = 'InvalidAttempt',
Lockout = 'Lockout',
UserNotLoggedIn = 'UserNotLoggedIn',
InvalidTwoFACode = 'InvalidTwoFACode',
}

export enum AddItemResult {
AddedToCart = 'AddedToCart',
Ordered = 'Ordered',
OutOfBudget = 'OutOfBudget',
Exception = 'Exception',
SendToComfirmEmail = 'SendToComfirmEmail',
QuoteRequested = 'QuoteRequested',
RequiresOrderConfirmation = 'RequiresOrderConfirmation',
ItemRequested = 'ItemRequested',
SendToTerms = 'SendToTerms',
}

export enum ChooseLocationResult {
AddColumn = 'AddColumn',
ShowVisual = 'ShowVisual',
}

export enum IndexColumnTypes {
Text = 'Text',
Image = 'Image',
Icon = 'Icon',
Checkbox = 'Checkbox',
Button = 'Button',
Header = 'Header',
Placeholder = 'Placeholder',
DDL = 'DDL',
}

export enum IndexColumnProperties {
Link = 'Link',
LinkTooltip = 'LinkTooltip',
Popover = 'Popover',
Tooltip = 'Tooltip',
Click = 'Click',
None = 'None',
ClickTooltip = 'ClickTooltip',
InfoPopupClick = 'InfoPopupClick',
}

export enum IndexColumnPopoverTypes {
Partial = 'Partial',
Clarify = 'Clarify',
}

export enum CheckboxCondition {
Installment = 'Installment',
Resend = 'Resend',
}

export enum SvgNames {
AccountBox = 'AccountBox',
Add = 'Add',
AddBox = 'AddBox',
AddCircle = 'AddCircle',
AddCircleOutline = 'AddCircleOutline',
AddTask = 'AddTask',
Approve = 'Approve',
Archive = 'Archive',
ArrowBackIos = 'ArrowBackIos',
ArrowDropDown = 'ArrowDropDown',
ArrowForwardIos = 'ArrowForwardIos',
Assignment = 'Assignment',
AttachFile = 'AttachFile',
AttachItem = 'AttachItem',
AttachProtocol = 'AttachProtocol',
Block = 'Block',
Book = 'Book',
Build = 'Build',
Business = 'Business',
Calibrate = 'Calibrate',
CameraAlt = 'CameraAlt',
CancelPresentation = 'CancelPresentation',
Categories = 'Categories',
Category = 'Category',
CentarixBrokenLink = 'CentarixBrokenLink',
CentarixCloseItem = 'CentarixCloseItem',
CentarixComments = 'CentarixComments',
CentarixDropDownExpand = 'CentarixDropDownExpand',
CentarixFileDownload = 'CentarixFileDownload',
CentarixFileUpload = 'CentarixFileUpload',
CentarixFileView = 'CentarixFileView',
CentarixHumanTrials = 'CentarixHumanTrials',
CentarixInstallments = 'CentarixInstallments',
CentarixItemTab = 'CentarixItemTab',
CentarixKitProtocol = 'CentarixKitProtocol',
CentarixLists = 'CentarixLists',
CentarixMainTab = 'CentarixMainTab',
CentarixMoveList = 'CentarixMoveList',
CentarixNotificationApprove = 'CentarixNotificationApprove',
CentarixNotificationBirthday = 'CentarixNotificationBirthday',
CentarixNotificationCart = 'CentarixNotificationCart',
CentarixNotificationDidntArrive = 'CentarixNotificationDidntArrive',
CentarixNotificationLiked = 'CentarixNotificationLiked',
CentarixNotificationList = 'CentarixNotificationList',
CentarixNotificationOrdered = 'CentarixNotificationOrdered',
CentarixNotificationReceived = 'CentarixNotificationReceived',
CentarixNotificationQuote = 'CentarixNotificationQuote',
CentarixNotificationsBorder = 'CentarixNotificationsBorder',
CentarixNotificationShared = 'CentarixNotificationShared',
CentarixNotificationsNumber = 'CentarixNotificationsNumber',
CentarixNotificationTime = 'CentarixNotificationTime',
CentarixNotificationTimekeeper = 'CentarixNotificationTimekeeper',
CentarixNotificationTopMenu = 'CentarixNotificationTopMenu',
CentarixOrderedTab = 'CentarixOrderedTab',
CentarixPageFirst = 'CentarixPageFirst',
CentarixPageLast = 'CentarixPageLast',
CentarixPageNext = 'CentarixPageNext',
CentarixPagePrevious = 'CentarixPagePrevious',
CentarixProtocolTab = 'CentarixProtocolTab',
CentarixResourcesTab = 'CentarixResourcesTab',
CentarixResults = 'CentarixResults',
CentarixResultsDropdown = 'CentarixResultsDropdown',
CentarixResultsTab = 'CentarixResultsTab',
CentarixSearchAdvanced = 'CentarixSearchAdvanced',
CentarixSearchFilter = 'CentarixSearchFilter',
CentarixSearchList = 'CentarixSearchList',
CentarixSharedListBlack = 'CentarixSharedListBlack',
CentarixSopProtocol = 'CentarixSopProtocol',
CentarixStandingOrders = 'CentarixStandingOrders',
CentarixStar = 'CentarixStar',
CentarixStarBlack = 'CentarixStarBlack',
CentarixStop = 'CentarixStop',
CentarixTabSelected = 'CentarixTabSelected',
CentarixTheoryTab = 'CentarixTheoryTab',
CentarixTip = 'CentarixTip',
CentarixTroubleshooting = 'CentarixTroubleshooting',
CentarixVisits = 'CentarixVisits',
CentarixYearly = 'CentarixYearly',
CheckBox = 'CheckBox',
CheckBoxOutlineBlank = 'CheckBoxOutlineBlank',
ChromeReaderMode = 'ChromeReaderMode',
Clear = 'Clear',
Clipboard = 'Clipboard',
Comment = 'Comment',
ConclusionsTab = 'ConclusionsTab',
Create = 'Create',
CreateProject = 'CreateProject',
CreditCard = 'CreditCard',
Dashboard = 'Dashboard',
Delete = 'Delete',
Description = 'Description',
Done = 'Done',
DoneBox = 'DoneBox',
Download = 'Download',
EditDoc = 'EditDoc',
EditListBlack = 'EditListBlack',
Entry = 'Entry',
Event = 'Event',
Exit = 'Exit',
Face = 'Face',
Favorite = 'Favorite',
FavoriteBorder = 'FavoriteBorder',
FileCopy = 'FileCopy',
FolderOpen = 'FolderOpen',
FormatListBulleted = 'FormatListBulleted',
FormatListNumbered = 'FormatListNumbered',
HighlightOff = 'HighlightOff',
History = 'History',
Home = 'Home',
HomeWorkBlack = 'HomeWorkBlack',
Https = 'Https',
Info = 'Info',
InhouseMaintainance = 'InhouseMaintainance',
InsertChart = 'InsertChart',
InsertDriveFile = 'InsertDriveFile',
Inventory = 'Inventory',
InventoryBlack = 'InventoryBlack',
InvoiceProforma = 'InvoiceProforma',
Label = 'Label',
LibraryBooks = 'LibraryBooks',
LocalMall = 'LocalMall',
LocalOffer = 'LocalOffer',
LocalShipping = 'LocalShipping',
Logbook = 'Logbook',
Luggage = 'Luggage',
Measurements = 'Measurements',
MiscellaneousServices = 'MiscellaneousServices',
MonetizationOn = 'MonetizationOn',
More = 'More',
MoreVert = 'MoreVert',
NotificationImportant = 'NotificationImportant',
Notifications = 'Notifications',
Pause = 'Pause',
Payment = 'Payment',
Pending = 'Pending',
PermContactCalendar = 'PermContactCalendar',
PieChart = 'PieChart',
Place = 'Place',
PlayCircleOutline = 'PlayCircleOutline',
Print = 'Print',
PriorityHigh = 'PriorityHigh',
RadioButtonChecked = 'RadioButtonChecked',
RadioButtonUnchecked = 'RadioButtonUnchecked',
Received = 'Received',
RemoveRedEye = 'RemoveRedEye',
RemoveShoppingCart = 'RemoveShoppingCart',
ReportProblem = 'ReportProblem',
Research = 'Research',
Schedule = 'Schedule',
School = 'School',
Security = 'Security',
Settings = 'Settings',
Share = 'Share',
ShoppingCart = 'ShoppingCart',
Sick = 'Sick',
Storefront = 'Storefront',
Sync = 'Sync',
TableChart = 'TableChart',
Timer = 'Timer',
ToggleOff = 'ToggleOff',
ToggleOn = 'ToggleOn',
Update = 'Update',
WidgetsBlack = 'WidgetsBlack',
ZoomIn = 'ZoomIn',
BlockNew = 'BlockNew',
BuildingAddress = 'BuildingAddress',
DeleteNew = 'DeleteNew',
StorageUnitCounter = 'StorageUnitCounter',
StorageType = 'StorageType',
StorageSelected = 'StorageSelected',
StorageOptions = 'StorageOptions',
StorageOccupancy = 'StorageOccupancy',
ShelfEmpty = 'ShelfEmpty',
SettingsNew = 'SettingsNew',
RoomCounter = 'RoomCounter',
Preview = 'Preview',
LocationsLabs = 'LocationsLabs',
LocationMove = 'LocationMove',
LocationMap = 'LocationMap',
EmployeesCounter = 'EmployeesCounter',
OpenFull = 'OpenFull',
NeedsConfirmation = 'NeedsConfirmation',
SpecifyPayment = 'SpecifyPayment',
PaymentList = 'PaymentList',
OrderList = 'OrderList',
UploadFile = 'UploadFile',
UploadQuote = 'UploadQuote',
MoreHorizontal = 'MoreHorizontal',
EditLocationDetails = 'EditLocationDetails',
PendingOrders = 'PendingOrders',
IncomingOrders = 'IncomingOrders',
RevertStatus = 'RevertStatus',
ConfirmOrder = 'ConfirmOrder',
Search = 'Search',
}

export enum ModalName {
None = 'None',
DeleteItem = 'DeleteItem',
Reorder = 'Reorder',
ReorderProduct = 'ReorderProduct',
Share = 'Share',
MoveToList = 'MoveToList',
Documents = 'Documents',
DeleteDocuments = 'DeleteDocuments',
OrderOperations = 'OrderOperations',
UploadInvoice = 'UploadInvoice',
Terms = 'Terms',
ConfirmEmail = 'ConfirmEmail',
UploadQuote = 'UploadQuote',
UploadOrder = 'UploadOrder',
ChangePaymentStatus = 'ChangePaymentStatus',
CartTotal = 'CartTotal',
DeleteListRequest = 'DeleteListRequest',
SuspendUser = 'SuspendUser',
NewList = 'NewList',
DenyHours = 'DenyHours',
AddInvoice = 'AddInvoice',
Pay = 'Pay',
AskForQuote = 'AskForQuote',
EditQuoteDetails = 'EditQuoteDetails',
EditEmail = 'EditEmail',
AddStorageUnit = 'AddStorageUnit',
AddLab = 'AddLab',
AddRoom = 'AddRoom',
AddFloor = 'AddFloor',
AddBuilding = 'AddBuilding',
ReceiveItem = 'ReceiveItem',
LocationZoom = 'LocationZoom',
LocationRequests = 'LocationRequests',
ItemCard = 'ItemCard',
ReceiveLocation = 'ReceiveLocation',
AdditionalRequestInfo = 'AdditionalRequestInfo',
ConfirmArchive = 'ConfirmArchive',
AddBankAccount = 'AddBankAccount',
AddCreditCard = 'AddCreditCard',
DeleteHours = 'DeleteHours',
UpdateHours = 'UpdateHours',
ConfirmDayOff = 'ConfirmDayOff',
ExitHours = 'ExitHours',
ReportOffDayModal = 'ReportOffDayModal',
UserImage = 'UserImage',
RevertStatus = 'RevertStatus',
DocModalInfo = 'DocModalInfo',
ConfirmDocInfoChange = 'ConfirmDocInfoChange',
ConfirmOrder = 'ConfirmOrder',
BlockLocation = 'BlockLocation',
ReviewQuote = 'ReviewQuote',
AssociateRoom = 'AssociateRoom',
ConfirmExitModal = 'ConfirmExitModal',
OrderLate = 'OrderLate',
EditStorageUnitName = 'EditStorageUnitName',
DeleteLocation = 'DeleteLocation',
MoveStorageUnit = 'MoveStorageUnit',
DeleteLab = 'DeleteLab',
ExcelDownload = 'ExcelDownload',
RemoveRoom = 'RemoveRoom',
ConfirmSaveModal = 'ConfirmSaveModal',
}

export enum ModalFlow {
List = 'List',
DeleteItem = 'DeleteItem',
Share = 'Share',
AddItem = 'AddItem',
None = 'None',
SuspendUser = 'SuspendUser',
DenyHours = 'DenyHours',
CartTotal = 'CartTotal',
AskForQuote = 'AskForQuote',
EditQuoteDetails = 'EditQuoteDetails',
AddLocation = 'AddLocation',
ReceiveItem = 'ReceiveItem',
LocationZoom = 'LocationZoom',
AddInvoice = 'AddInvoice',
Pay = 'Pay',
AddPaymentMethod = 'AddPaymentMethod',
DeleteHours = 'DeleteHours',
UpdateHours = 'UpdateHours',
ConfirmDayOff = 'ConfirmDayOff',
ExitHours = 'ExitHours',
ReportOffDayModal = 'ReportOffDayModal',
RevertStatus = 'RevertStatus',
DocModalInfo = 'DocModalInfo',
ConfirmDocInfoChange = 'ConfirmDocInfoChange',
ConfigureInstallments = 'ConfigureInstallments',
ConfirmOrder = 'ConfirmOrder',
ReviewQuote = 'ReviewQuote',
AssociateRoom = 'AssociateRoom',
ConfirmExitModal = 'ConfirmExitModal',
OrderLate = 'OrderLate',
ExcelDownload = 'ExcelDownload',
}

export enum ItemCardFields {
Warranty = 'Warranty',
BatchLot = 'BatchLot',
SupplyDays = 'SupplyDays',
Vendor = 'Vendor',
Category = 'Category',
Url = 'Url',
Quote = 'Quote',
Order = 'Order',
SupplierOrderNumber = 'SupplierOrderNumber',
Payments = 'Payments',
Invoice = 'Invoice',
Installments = 'Installments',
Received = 'Received',
Passage = 'Passage',
CreationDate = 'CreationDate',
Terms = 'Terms',
Unit = 'Unit',
}

