import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { setLoading } from '../../ReduxRelatedUtils/utilsReducer';
import { DocumentsFlowProps } from '../../Shared/documents-card';
import { GetFileFramePath } from '../../Utility/document-functions';
import { CustomError } from '../../Utility/general-types';
import { StyledFilledButton } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ModalHeader, StyledDialogContent } from './modal-components';


export default function DeleteDocumentModal(props:{fileName:string,  setDeleteDocsModalIsOpen:Dispatch<SetStateAction<boolean>>, setError:Dispatch<SetStateAction<CustomError>>} &DocumentsFlowProps) {
    const dispatch = useDispatch();
    function closeDeleteDocModal() {
        props.setDeleteDocsModalIsOpen(false)
    }
    
    function deleteFile() {
        dispatch(setLoading(true))
        var url = "/Requests/DeleteDocumentModal?fileName=" + props.fileName;
        fetch(url, {
            method: "POST"
        })
            .then(result => {
                if (!result.ok) throw result.text()
                console.log("results of delete docs modal " + props.fileStrings.length)
                var newFileCount =props.fileStrings.length-1;
                props.setFileCount(newFileCount);
                closeDeleteDocModal();
                dispatch(setLoading(false))


            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                   props.setError({ message: text.toString() } as CustomError)                    
                })
                closeDeleteDocModal();
            })
    }


    return (
        <GlobalModal closeClick={closeDeleteDocModal} hideBackdrop={true} size={ModalSizes.xs} modalKey={ModalName.DeleteDocuments}>
            <>
                <ModalHeader headerText={"Delete File"} errorMessage={""} />
                <StyledDialogContent>
                    <form action="" method="post" encType="multipart/form-data" id="myForm" className="modal-padding DeleteDocumentModalForm">
                        <Box sx={{
                            margin: 'auto',
                            width:"18rem",
                            border: (theme) => `1px solid ${theme.palette.primary.main} !important;`,
                            borderRadius: '.25rem',
                        }}>
                            <iframe src={GetFileFramePath(props.fileName)} title="View" scrolling="no" style={{ position: 'relative', width: '100%', height: '11rem', borderRadius: '.25rem .25rem 0 0', }}></iframe>
                            <Stack spacing={1} direction={'column'} alignItems='center' padding='1rem'>
                                <Typography >Are you sure you want to delete?</Typography>
                                <StyledFilledButton onClick={deleteFile}>Delete</StyledFilledButton>
                            </Stack>
                        </Box>
                    </form>
                </StyledDialogContent>
            </>
        </GlobalModal>
    );
}