
import { createSlice } from '@reduxjs/toolkit'
import { LastSelectedLocation } from './redux-types'

const selectedLocationSlice = createSlice({
    name: 'lastSelectedLocation',
    initialState: {} as LastSelectedLocation,
    reducers: {
        setLastSelectedLocation(state:LastSelectedLocation, action: {payload: LastSelectedLocation}) {
            return action.payload
        },
        clearLastSelectedLocation(){
            return {} as LastSelectedLocation
        },
        setLastSelectedLocationReload(state:LastSelectedLocation, action: {payload: boolean}) {
            state.reload = action.payload;
        },
    }
})

export const { setLastSelectedLocation, clearLastSelectedLocation, setLastSelectedLocationReload } = selectedLocationSlice.actions
export const selectedLocationReducer = selectedLocationSlice.reducer

