import { ButtonProps, Grid, Popover } from '@mui/material';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { useSelector } from 'react-redux';
import IndexFilterResults from './index-filter-results';

import { ApplicationState } from '../../ReduxRelatedUtils/redux-types.js';
import { StyledTableButton } from '../../Utility/shared-components';

export default function IndexFilter(props:{showFilter: boolean}){

    const numFiltersApplied = useSelector<ApplicationState>(state => state.indexTableObject.selectedRequestFilters.numFilters) as number


    let buttonText = 'Filter';
    if(numFiltersApplied > 1){buttonText = numFiltersApplied + ' Filters'}
    else if (numFiltersApplied == 1) {buttonText = '1 Filter'}


    return (
        <Grid item>
                <PopupState  variant="popover" popupId="filterPopover">                   
                    {(popupState) => {
                        return (
                            <>
                                    {(props.showFilter ) && <FilterButton sx={{marginTop:"1.3rem", width:"10rem"}} aria-describedby="filterPopover" filled={numFiltersApplied > 0 || popupState.isOpen}
                                      {...bindTrigger(popupState)}>
                                    {buttonText}
                                </FilterButton>
                                }

                                <Popover
                                    {...bindPopover(popupState)}
                                    container={() => document.getElementsByTagName("main")[0]}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <IndexFilterResults key="InventoryFilterResults" popupState={popupState}/>
                                </Popover>
                         </>   
                        )
                    }}
                </PopupState>
                </Grid>

    )
}

function FilterButton(props: ButtonProps & {filled: boolean}) {
    let classes = props.filled ? 'section-bg-color' : 'section-outline-color';
    return (
        <StyledTableButton {...props} className={classes} /> 
    );
};