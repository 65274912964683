import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { useFieldArray, UseFieldArrayReturn, useForm, useFormContext } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { AnySchema, array, number, object, string } from 'yup';
import { ModalName, SvgNames } from '../Constants/app-enums';
import { selectModalPresent } from '../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../ReduxRelatedUtils/index-table-reducer';
import { setLoading } from '../ReduxRelatedUtils/utilsReducer';
import { removeModal, setModalLoading } from '../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../ReduxRelatedUtils/redux-types';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from '../Requests/Modals/modal-components';
import ModalSkeleton from '../Requests/Modals/modal-skeleton';
import { StyledControlledFormInput } from '../Utility/custom-input-components';
import { LocationModalInfo, CustomError } from '../Utility/general-types';
import { CompanyAccount } from '../Utility/request-types';
import { GlobalForm, StyledHoverOutlineButton, SvgIcon } from '../Utility/shared-components';



export default function AddBankAccountModal() {
    const dispatch = useDispatch();
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const { modalInfo } = useSelector<ApplicationState>(selectModalPresent) as Modal<LocationModalInfo>
    const [error, setError] = useState<CustomError>(new CustomError());


    const validationSchema = object<Partial<Record<keyof CompanyAccount, AnySchema>>>({
        companyBankName: string().required("Bank Name is required")
    })

    const resolver = yupResolver(validationSchema)
    const methods = useForm({
        mode: "onChange",
        resolver,
        defaultValues: {
            companyBankName:""
        } as CompanyAccount,
    }
    );

    useEffect(() => {
        dispatch(setModalLoading(false))
    }, [])

    function onSubmit(data: CompanyAccount, e: any) {
        var url = '/BankAccounts/AddBankAccountModal'
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.text() }
                throw response.text();
            })
            .then((res: string) => {
                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setReloadIndex(true))
                    dispatch(setGlobalSnack({ open: true, message: "Bank Account Added", severity: "success" }))
                })
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
    }


    return (
        <>
            {loading ?
                <ModalSkeleton />
                : <>
                    <ModalHeader headerText={"Add Bank Account"} errorMessage={error.message} />
                    {error.showBody && <>
                        <StyledDialogContent>
                            <GlobalForm formID={ModalName.AddBankAccount} onSubmit={onSubmit} methods={methods}>
                                <Stack spacing={2} alignItems="center">
                                   <StyledControlledFormInput name="companyBankName" label="Bank Name" placeholder='Enter name here'/>
                                </Stack>
                            </GlobalForm>
                        </StyledDialogContent>
                        <ModalFooterWithSave formID={ModalName.AddBankAccount} />
                    </>
                    }
                </>
            }
        </>
    )
}

