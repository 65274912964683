import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import { batch, useDispatch, useSelector } from "react-redux";
import { MenuItemEnum, ModalFlow, ModalName, PageTypeEnum, SvgNames } from "../Constants/app-enums";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setModal, setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal, ModalInfo } from "../ReduxRelatedUtils/redux-types";
import { clearLastSelectedLocation } from "../ReduxRelatedUtils/selectedLocationReducer";
import { setViewModel } from "../ReduxRelatedUtils/viewmodel-reducer";
import { StyledNoOutlineButton, StyledTab, SvgIcon } from "../Utility/shared-components";
import { LocationCardViewModel, LocationIndexViewModel, LocationTab, LocationTabsViewModel } from "./locations-types";
import GlobalPopover from "../Utility/global-popover";
import { IconViewModel } from "../Utility/request-types";
import { getDisplayNameOfEnumValue } from "../Utility/root-function";
import { useState } from "react";
import Theme from "../Utility/theme";
import { minWidth } from "@mui/system";


export default function LocationIndexTabs(tabsViewModel: LocationTabsViewModel) {

    const viewModel = useSelector<ApplicationState>(state => state.viewModel) as LocationIndexViewModel
    const { pageType, sectionType } = useSelector(selectNavigationInfo)


    const dispatch = useDispatch();


    return (
        <Stack direction="row" spacing={1.5} alignItems="center" >
            <Tabs value={tabsViewModel?.selectedTab} >
                {tabsViewModel?.tabs?.map((t: LocationTab, i: number) => {
                    var selected = t.id == tabsViewModel.selectedTab
                    console.log(selected);
                    return (<StyledTab sx={{ cursor: "inherit",/* , justifyContent: "end" */minWidth:"0" }} className={selected ? "section-color" : ""}
                        value={t.id}
                        key={t.id}
                        label={<Box sx={{ cursor: "pointer",  /* marginRight:".5rem" */ opacity: (t.isBlocked && !selected)?".3":"1" }} onClick={(e) =>
                            batch(() => {
                                dispatch(setViewModel({ ...viewModel, tabsViewModel: { ...tabsViewModel, selectedTab: t.id } }))
                                dispatch(clearLastSelectedLocation())
                            })}>{t.name}</Box>}
                        iconPosition="end"
                        icon={
                            <Stack marginLeft={"0.5rem !important"} direction={"row"} spacing={.4} alignItems={"center"} >
                                {t.isBlocked && <Box display={"flex"} alignItems={"center"}><SvgIcon name={SvgNames.BlockNew} className="icon-red" height={1.2} /></Box>}
                                {t.actions == undefined || sectionType != MenuItemEnum.LabManagement ? <></> :<GlobalPopover closeOnClick={true} key={"icons"} >
                                    <Box display={"flex"} alignItems={"center"} textAlign={"end"}  /*onMouseLeave={() => setShowThreeDots(false)}*/ width="1.5rem">
                                        <SvgIcon name={SvgNames.MoreHorizontal} className="icon-gray" height={1.3} />
                                    </Box>
                                    <Grid container direction="column" width="10rem">
                                        <Grid container direction="column" padding="0.5rem 1rem" >
                                            {t?.actions?.map((action, i) => (
                                                <Grid item key={"action" + i}>
                                                    {action.disabled ?
                                                        <Tooltip title={action.disabledMessage ?? ""} arrow >
                                                            <div>
                                                                <LocationIconButtons action={action} locationID={t.id} />
                                                            </div>
                                                        </Tooltip> :
                                                        <LocationIconButtons action={action} locationID={t.id} />}
                                                </Grid>))}
                                        </Grid>
                                    </Grid>
                                </GlobalPopover>
                        }
                            </Stack>
                        } />)

                }
                )}
            </Tabs>
            {((pageType != PageTypeEnum.RequestLocation) && tabsViewModel.addTabButton) && <StyledNoOutlineButton
                onClick={() =>
                    batch(() => {
                        dispatch(setModal({ modalFlow: ModalFlow.AddLocation, modalStep: tabsViewModel.addTabButton.modal, modalInfo: {} } as Modal<ModalInfo>))
                        dispatch(setModalLoading(true))
                    })}>
                {`+ Add ${tabsViewModel.addTabButton.description}`}
            </StyledNoOutlineButton>}
        </Stack>
    )
}

function LocationIconButtons(props: { locationID: number, action: IconViewModel }) {

    const dispatch = useDispatch();

    return <Button disabled={props.action.disabled ?? false} onClick={() => batch(() => {
        dispatch(setModalLoading(true))
        dispatch(setModal({ modalFlow: ModalFlow.AddLocation, modalStep: props.action.modalName ?? ModalName.None, modalInfo: { locationID: props.locationID } }))
    })}>
        <Stack direction="row" spacing={0.7} alignItems="center" padding="0.5rem 0.2rem">
            <SvgIcon height={1.5} name={props.action.iconName as SvgNames} className={props.action.fillClass} />
            <Typography fontSize={"0.9rem"}>{getDisplayNameOfEnumValue(props.action.description)}</Typography>
        </Stack>
    </Button>
}
