import moment from "moment";
import { OrderType, CurrencyEnum } from "../../Constants/app-enums";
import { TempData } from "../../ReduxRelatedUtils/redux-types";
import { RequestItemViewModel, Request, ProductItemViewModel } from "../../Utility/request-types";
import { VendorContact } from "../../Utility/supplier-types";
import { AddItemFormData, AddSupplierFormData, AddUserFormData, EditProductFormData } from "./add-types";
import { AutocompleteOption, Comment } from "../../Utility/general-types";
import { CardTabsType, TabsName } from "../../Constants/client-side-enums";
import { Employee } from "../../Utility/user-types";
import { v4 } from "uuid";


export const requestDefaultValues = (viewModel?: RequestItemViewModel, requestID?:number) => {
    // console.log("in request default values ")
    return ({
        currentTab: TabsName.Item,
        request: {
            requestID:requestID??0,
            product: {
                productName: "",
                productSubcategory: { id: 0, parentCategoryID: 0, parentCategory:{} },
                vendorID: 0,
                productSubcategoryID: 0,
                vendor: { vendorBuisnessID: "" },
                catalogNumber: "",
                unitTypeID: 0,
                subSubUnit: null,
                subUnit: null,
                subSubUnitTypeID: null,
                subUnitTypeID: null,
                orderType: OrderType.SingleOrder,
                uniqueVendorCatalogNumber: true,
                url: "",
            },
            currency: CurrencyEnum.NIS,
            exchangeRate: viewModel?.request?.exchangeRate ?? 1,
            pricePerUnitDollar: 0,
            pricePerUnitShekel: 0,
            unit: 0,
            includeVAT: true,
            cost: 0,
            costDollar: 0,
            expectedSupplyDate: moment().format("YYYY-MM-DD"),
            expectedSupplyDays: null,
            warranty: 0,
            warrantyEndDate: "",
            batch: 0,
            batchExpiration: moment().format("YYYY-MM-DD"),
            editableCreationDate: moment().format("YYYY-MM-DD"),
            passage: 0
        },
        comments: [] as Comment[],
        guid: "",

        isSample: false,
        selectedFloor: 0,
        selectedBuilding: 0,
        selectedParentStorageType: 0,
        selectedStorageUnit: 0,
        selectedTemperature: 0,
        selectedRoom: 0,
        selectedLocations: [] as number[],
        isTemporary: false,
        isSubmitting: false
    } as TempData<AddItemFormData>)
}

export const productDefaultValues = (productID?:number) => {
    // console.log("in request default values ")
    return ({
        currentTab: TabsName.Item,
        product: {
            productID:productID,
            productName: "",
            productSubcategory: { id:  0, parentCategoryID:  0, parentCategory:{id:0} },
            vendorID: 0,
            productSubcategoryID:  0,
            vendor: { vendorBuisnessID: "" },
            catalogNumber: "",
            unitTypeID: 0,
            subSubUnit: null,
            subUnit: null,
            subSubUnitTypeID: null,
            subUnitTypeID: null,
            orderType: OrderType.SingleOrder,
            uniqueVendorCatalogNumber: true,
            url: "",
        },
        comments: [] as Comment[],
        guid: "",
        isSubmitting: false

    } as TempData<EditProductFormData>)
}


export const supplierDefaultValues = () => {
    return ({
        currentTab: TabsName.Item,
        vendor: {
            countryID: 0,
            uniqueCountryCompanyID: true,
        },
        vendorCategoryTypes: [] as number[],
        vendorContacts: [] as VendorContact[],
        isSubmitting: false
    }) as AddSupplierFormData
}


export const userDefaultValues = (id?:string) => {
    return ({
        currentTab: TabsName.Details,
        employee: {
            id:id??"",
            jobSubcategoryTypeID: 0,
            jobSubcategoryType: {
                jobCategoryTypeID: 0
            },
            degreeID: 0,
            citizenshipID:0,
            maritalStatusID:0,
            firstName:"",
            lastName:"",
            age:0,
            idNumber:"",
            startedWorking:moment().format("YYYY-MM-DD"),
            dob: moment().format("YYYY-MM-DD"),
            employeeStatusID: 1,
            taxCredits: 0
        } as Employee,
        labs:[] as AutocompleteOption[],
        guid: v4(),
        roles:[] as string[],
        isSubmitting: false
    }) as AddUserFormData
}