import { shallowEqual, useSelector } from 'react-redux';
import {InfoPopupName, ModalFlow } from '../Constants/app-enums';
import AddLocationFlow from '../LabManagement/add-location-flow';
import { selectModalFlow } from '../ReduxRelatedUtils/customConfigureStore';
import CartTotalModal from '../Requests/Modals/cart-total-modal';
import DeleteModal from '../Requests/Modals/delete-modal';
import EditQuoteDetailsModal from '../Requests/Modals/edit-quote-details-modal';
import ItemFlow from '../Requests/Modals/item-flow';
import ListModalFlow from '../Requests/Modals/list-modal-flow';
import QuoteEmailModal from '../Requests/Modals/quote-email-modal';
import ShareModal from '../Requests/Modals/share-modal';
import DenyHoursModal from '../Users/deny-hours-modal';
import SuspendUser from '../Users/suspend-user-modal';
import ReceiveModal from '../Requests/Modals/receive-modal';
import LocationZoomModal from '../LabManagement/location-zoom-modal';
import LocationZoomFlow from '../LabManagement/location-zoom-flow';
import { useState } from 'react';
import ReceiveItemFlow from '../Requests/Modals/receive-item-flow';
import { ApplicationState, InfoPopup } from '../ReduxRelatedUtils/redux-types';
import EditCardPopover from './edit-card-popover';
import { EditCardType } from './edit-card';
import { ConfigureInstallmentsModal } from '../Requests/Modals/configure-installments-modal';

export const InfoPopupLoader = () => {
    const {infoPopupName} = useSelector<ApplicationState>(state=> state.infoPopup) as InfoPopup
    function InfoPopup() {
        switch (infoPopupName) {
            case InfoPopupName.EditItem:
                 return <EditCardPopover editCardType={EditCardType.EditItem}/>

            case InfoPopupName.EditSupplier:
                return <EditCardPopover editCardType={EditCardType.EditSupplier}/>
            case InfoPopupName.ConfigureInstallments:
                return <ConfigureInstallmentsModal />
    
            default:      
                return (<></>);

        }
  
    }

    return <InfoPopup />;

}
