import { Box, Button, Grid, ListSubheader, MenuItem, Stack } from "@mui/material";
import { merge } from 'lodash';
import { createContext, useEffect, useRef, useContext, useState, PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";
import { ItemCardFields, ParentCategoryEnum, ModalFlow, ModalName, SvgNames, CurrencyEnum } from "../../Constants/app-enums";
import { CardTabsType } from "../../Constants/client-side-enums";
import { selectEditDrawerViewModelByID, selectNavigationInfo } from "../../ReduxRelatedUtils/customConfigureStore";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 } from "uuid";
import { setError } from "../../ReduxRelatedUtils/errorReducer";
import { setGlobalSnack } from "../../ReduxRelatedUtils/globalSnackReducer";
import { setReloadIndex } from "../../ReduxRelatedUtils/index-table-reducer";
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";
import { setModal, setModalLoading } from "../../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal, NavigationInfo, TempData } from "../../ReduxRelatedUtils/redux-types";
import { setTempData } from "../../ReduxRelatedUtils/temp-data-reducer";
import { AddItemFormData, AddSupplierFormData, AddSupplierViewModel, EditProductFormData } from "../../Shared/AddCardComponents/add-types";
import { CardTabs, GlobalTab, Tab } from "../../Shared/AddCardComponents/card-tabs";
import EditCard, { EditCardContext, EditCardType } from "../../Shared/edit-card";
import EditDrawerSkeleton from "../../Shared/edit-drawer-skeleton";
import { StyledControlledFormInput, StyledControlledSelectInput, StyledCurrencyFormInput, StyledFormInputTitle } from "../../Utility/custom-input-components";
import { BasicModalInfo, EditDrawerTempData, RowViewModel } from "../../Utility/general-types";
import { ProductItemViewModel, Request, RequestItemViewModel, UnitParentType, UnitType } from "../../Utility/request-types";
import { DocumentTypeWithID, getAddItemCardFieldTypesByCategory, getDocumentTypesByCategory } from "../../Utility/root-function";
import { CloseButton, IsEdittableContext, SpacedColumnStack, SpacedRowStack, StyledFilledButton, StyledNoOutlineButton, StyledTableImage, SvgIcon } from "../../Utility/shared-components";
import CommentsTab from "./comments-tab";
import { ItemTabs } from "./item-card-tabs";
import { DocumentsTab, getTabs } from "./item-components";
import ItemTab from "./item-details-tab";
import PriceTab, { UnitCardProps } from "./price-tab";
import Typography from "@mui/material/Typography";
import queryString from 'query-string'
import { removeEditDrawerViewModel, setReloadEditModal, setEditDrawerViewModel } from "../../ReduxRelatedUtils/edit-drawer-viewmodel-reducer";
import { push } from "connected-react-router";
import { ConfirmSaveModal } from "../Modals/confirm-save-modal";
export function EditProductInnerForm(props: { id?: string }) {
    const { watch, setValue, getValues, reset , formState:{dirtyFields}} = useFormContext<TempData<EditProductFormData>>();
    const [view, setView] = useState<{ fields: ItemCardFields[], docCards: DocumentTypeWithID[] }>();
    const dispatch = useDispatch();
    const isEdittableContext = useContext(IsEdittableContext);
    const viewModel = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.id ?? 0)?.viewModel) as ProductItemViewModel;
    const reloadEditModal = useSelector<ApplicationState>(state => selectEditDrawerViewModelByID(state, props.id ?? 0)?.reloadEditModal) as boolean;
    const productSubcategoryID = watch("product.productSubcategoryID");
    const requestByProduct = useSelector<ApplicationState>(state => (selectEditDrawerViewModelByID(state, props.id ?? 0)?.viewModel as RequestItemViewModel)?.requestsByProduct) as  RowViewModel[];

    const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);

    const [tabs, setTabs] = useState<Tab[]>([])
    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            setTabs(getTabs(true, false, false, true));

        }
    }, [didMount.current])
    useEffect(() => {
        dispatch(setReloadEditModal({ reloadEditModal: true, id: props.id ?? "0" }))
    }, [])

    useEffect(() => {
        setConfirmSaveModalOpen(false);
        if (reloadEditModal) {
            fetch("/Products/GetEditModalJson" + "?id=" + props.id, {
                method: "GET"
            })
                .then((response) => {
                    if (response.ok) { return response.json() }
                    else { throw response.text() }
                })
                .then((result: ProductItemViewModel) => {
                    dispatch(setEditDrawerViewModel({ viewModel: result, id: props.id ?? "0", reloadEditModal: false }));
                    didMount.current = true;
                    let productResult = result.product;
                    var docCards = getDocumentTypesByCategory("", productResult.productID, productResult?.productSubcategory?.parentCategory?.parentCategoryDescriptionEnum, productResult?.productSubcategory.description) ?? []
                    setView({
                        fields: (getAddItemCardFieldTypesByCategory(productResult?.productSubcategory?.parentCategory?.parentCategoryDescriptionEnum, productResult?.productSubcategory.description, true) ?? []).concat(result.additionalCardFields),
                        docCards: docCards.concat(result.additionalSpecificDocFolders)
                    })
                    reset(merge(JSON.parse(JSON.stringify(getValues())), { product: productResult }));
                    setValue("guid", v4())
                    setValue("comments", result.comments);

                })
                .catch(err => {
                    Promise.resolve(err).then(text => {
                        dispatch(setReloadEditModal({ reloadEditModal: false, id: props.id ?? "0" }))

                        dispatch(setError({ message: text.toString(), showBody: false }))
                    })
                })
        }

    }, [reloadEditModal]);

    function discardChanges() {
        //      console.log("in discard changes")
        isEdittableContext.toggleIsEdittable(false)

        batch(() => {
            dispatch(setReloadEditModal({ reloadEditModal: true, id: props.id ?? "0" }))
            dispatch(setGlobalSnack({ open: true, message: "Discarded Item Changes" }))
        })

    }
    function setEditable() {
        isEdittableContext.toggleIsEdittable(true)
    }

    return (reloadEditModal || viewModel == undefined ? <EditDrawerSkeleton /> : <>
        <Stack sx={{ padding: "3rem" }} direction="row" alignItems="center" justifyContent={"space-between"}>
            <Stack direction="row" spacing={2} alignItems="center">
                <StyledTableImage width={6} src={viewModel?.productSubcategories?.filter(p => p.id == productSubcategoryID)?.[0]?.imageURL ?? ""} />
                <Stack spacing={1}>
                    <StyledFormInputTitle placeholder="Product Name" name="product.productName" />
                    <Typography color={theme => theme.palette.grey[500]}>{`Product Serial: ${viewModel?.product?.serialNumber}`}</Typography>
                </Stack>
            </Stack>
            <Stack spacing={1.5}>
                {!isEdittableContext.isEdittable && <> <StyledNoOutlineButton endIcon={<SvgIcon name={SvgNames.Create} className="section-fill" height={1.5} />} onClick={setEditable}>
                    Edit Item
                </StyledNoOutlineButton>
                    <StyledFilledButton onClick={() => {
                        //console.log("click request button"); 
                        dispatch(setModalLoading(true));
                        dispatch(setModal({ modalFlow: ModalFlow.AddItem, modalStep: ModalName.Reorder, modalInfo: { ids: [viewModel.product.productID.toString()] } } as Modal<BasicModalInfo>))
                    }}>Request</StyledFilledButton>
                </>}

                {isEdittableContext.isEdittable &&
                    <>
                        <StyledNoOutlineButton onClick={discardChanges}>Discard Edits</StyledNoOutlineButton>
                        {dirtyFields?.product ==undefined || requestByProduct?.length==undefined ||requestByProduct?.length==0?
                            <StyledFilledButton form={EditCardType.EditProduct + props.id} type="submit">Save Edits</StyledFilledButton>
                        :<>
                        <StyledFilledButton form={EditCardType.EditProduct+  props.id} onClick={()=>setConfirmSaveModalOpen(true)} >Save Edits</StyledFilledButton>
                      {confirmSaveModalOpen &&  <ConfirmSaveModal formID={props.id?.toString()??""} editType={EditCardType.EditProduct}  setConfirmModalOpen={setConfirmSaveModalOpen} />}
                        </>}
                    </>
                }
            </Stack>
        </Stack>


        <CardTabs key={CardTabsType.EditDrawer} cardTabType={CardTabsType.EditDrawer} selectedTab={0} tabs={tabs} >
            <ItemTabs cardType={CardTabsType.EditDrawer} key={"itemtabs"} tabs={tabs} view={view} editID={props.id} isProduct={true} />
        </CardTabs>
    </>)
}

export function onSubmitItemEdit(data: EditProductFormData, dispatch: Function, setSubmitSucceeded: Function, e:any, location:any) {
    dispatch(setLoading(true))
    fetch("/Products/EditModalView", {
        method: "POST",
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
    }).then(response => {
        if (response.ok) { return response.json() }
        else { throw response.json() }
    }).then((result) => {
        setSubmitSucceeded(true)
        let newPathname = location.pathname.replace("/"+EditCardType.EditProduct,"")
        if(e.nativeEvent.submitter.name=="exit"){
            batch(()=>{

                dispatch(removeEditDrawerViewModel({ id: data.product.productID.toString() }))
                dispatch(push(queryString.stringifyUrl({ url: newPathname, query: location.query })))
                dispatch(setError({showBody:true, message:""}))
           
            })
        }
        else{
                batch(() => {
                dispatch(setReloadEditModal({ reloadEditModal: true, id: data.product.productID + "" }))
                dispatch(setGlobalSnack({ open: true, message: "Saved Item Changes" }))
            })
        }

        setSubmitSucceeded(true)

        batch(() => {

            dispatch(setLoading(false))
            dispatch(setReloadIndex(true));
        })

    })
        .catch(err => {
            Promise.resolve(err).then(text => {
                //   console.log("in submit error")

                batch(() => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text.result, severity: "error" }))
                })
            })
        })
}

export default function EditProduct() {
    const dispatch = useDispatch();
    const location = useSelector<ApplicationState>(state => state.router.location) as Location;
    const [submitSucceeded, setSubmitSucceeded] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    function onSubmit(data: EditProductFormData, e:any) {
        onSubmitItemEdit(data, dispatch, setSubmitSucceeded, e, location)
    }

    return (
        <EditCardContext.Provider value={{ submitSucceeded: submitSucceeded, toggleSubmitSucceeded: setSubmitSucceeded, confirmModalOpen:confirmModalOpen, toggleConfirmModalOpen:setConfirmModalOpen }}>
            <EditCard editType={EditCardType.EditProduct} onSubmit={(data, e) => onSubmit(data, e)} />
        </EditCardContext.Provider>
    )
}

export function UnitBlockWithOutPrice(props: { viewModel: ProductItemViewModel | RequestItemViewModel }) {

    const { watch, setValue, control } = useFormContext();
    const unitTypeID = watch("product.unitTypeID")
    const subunitTypeID = watch("product.subUnitTypeID")
    const subunit = watch("product.subUnit")
    const subunit2TypeID = watch("product.subSubUnitTypeID")
    const subunit2 = watch("product.subSubUnit")

    const [showSubunitCard, setShowSubunitCard] = useState((subunitTypeID || subunit) != null)
    const [showSubunit2Card, setShowSubunit2Card] = useState((subunit2TypeID || subunit2) != null)



    function closeSubunitCard() {
        setShowSubunitCard(false)
        setValue("request.product.subUnit", null)
        setValue("request.product.subUnitTypeID", null)
        showSubunit2Card && closeSubunit2Card()
    }

    function closeSubunit2Card() {
        setShowSubunit2Card(false)
        setValue("request.product.subSubUnit", null)
        setValue("request.product.subSubUnitTypeID", null)
    }
    function openSubunitCard() {
        setValue("request.product.subUnit", 1)
        setValue("request.product.subUnitTypeID", 0)
        setShowSubunitCard(true)
    }
    function openSubunit2Card() {
        setValue("request.product.subSubUnit", 1)
        setValue("request.product.subSubUnitTypeID", 0)
        setShowSubunit2Card(true)
    }

    function getUnitTypeText(unitLevel: "subUnit" | "subSubUnit" | "unit") {
        switch (unitLevel) {
            case "unit":
                return props.viewModel.unitTypes.filter(ut => ut.unitTypeID == unitTypeID)[0]?.unitTypeDescription ?? "unit"
            case "subUnit":
                return props.viewModel.unitTypes.filter(ut => ut.unitTypeID == subunitTypeID)[0]?.unitTypeDescription ?? "unit"
            case "subSubUnit":
                return props.viewModel.unitTypes.filter(ut => ut.unitTypeID == subunit2TypeID)[0]?.unitTypeDescription ?? "unit"
            default:
                return "unit"
        }

    }
    return (
        
               <Grid item xs={12} spacing={1} >
                <SpacedRowStack >
                    <UnitCard>
                        <UnitCardWithOutPrice viewModel={props.viewModel} unitText={getUnitTypeText("unit")} unitCardType="unit" />
                    </UnitCard>
                    <UnitCard>
                        {showSubunitCard ?
                            <UnitCardWithOutPrice viewModel={props.viewModel} unitCardType="subUnit" closeCard={closeSubunitCard} unitText={getUnitTypeText("subUnit")} /> :
                            <AddCardButton openCard={openSubunitCard} cardType="Subunit" disabled={(unitTypeID <= 0)} />}
                    </UnitCard>
                    {showSubunit2Card ?
                        <UnitCard>
                            <UnitCardWithOutPrice viewModel={props.viewModel} unitCardType="subSubUnit" unitText={getUnitTypeText("subSubUnit")} closeCard={closeSubunit2Card} />
                        </UnitCard> :
                        (showSubunitCard ?
                            <UnitCard><AddCardButton openCard={openSubunit2Card} disabled={!subunitTypeID || !subunit || subunitTypeID <= 0 || subunit <= 0} cardType="Subunit2" /></UnitCard> :
                            null)}
                            </SpacedRowStack>
                </Grid>
            
      )
}


export function AddCardButton(props: { cardType: "Subunit" | "Subunit2", disabled: boolean, openCard: Function }) {
    const isEdittableContext = useContext(IsEdittableContext);
    return (
        <Stack direction="column" height={"100%"} width={"100%"} justifyContent={"center"} >
            <Button disabled={props.disabled || isEdittableContext.isEdittable==false} onClick={() => { props.openCard() }} sx={{ opacity: props.disabled ? "0.5" : "1", justifyContent: "center" }}>
                <SvgIcon name={SvgNames.Add} height={2}  />
            </Button>
            <Typography textAlign="center" sx={{ opacity: props.disabled ? "0.5" : "1" }}>
                Add {props.cardType}
            </Typography>
        </Stack>
    )
}

export function UnitCard(props: PropsWithChildren<any>) {
    return (


            <Box width={"100%"}  borderRadius={"0.3rem"} border={(theme)=>`.1rem solid ${theme.palette.grey[300]}`} padding="1.5rem" display={"flex"} alignItems={"center"} position="relative" data-testid="price-card">
                {props.children}
            </Box>)
}

export function UnitCardWithOutPrice(props: UnitCardProps) {
    const { watch, setValue } = useFormContext<TempData<EditProductFormData>>();

    const unitTypeID = watch("product.unitTypeID")
    const subUnitTypeID = watch("product.subUnitTypeID")
    const subUnit2TypeID = watch("product.subSubUnitTypeID")
    const parentCategoryID = watch("product.productSubcategory.parentCategoryID")


    const [unitParentTypesDDL, setUnitParentTypesDDL] = useState<UnitParentType[]>(props.viewModel.unitParentTypes);
    const isEdittableContext = useContext(IsEdittableContext);
    const [unitTypesDDL, setUnitTypesDDL] = useState<UnitType[]>(props.viewModel.unitTypes);


    useEffect(() => {
        var unitTypes = props.viewModel.unitTypes?.filter(u => u.unitTypeParentCategory?.filter(up => up.parentCategoryID == parentCategoryID).length > 0)
        console.log(unitTypes);

        setUnitTypesDDL(unitTypes);
        switch (props.unitCardType) {
            case "unit":
                console.log(unitTypeID)
                if (unitTypes.filter(u => u.unitTypeID == unitTypeID).length == 0) {
                    setValue("product.unitTypeID", 0)
                }
                break;
            case "subUnit":
                console.log(subUnitTypeID)
                if (unitTypes.filter(u => u.unitTypeID == subUnitTypeID).length == 0) {

                    setValue("product.subUnitTypeID", null)
                }

                break;
            case "subSubUnit":
                console.log(subUnit2TypeID)
                if (unitTypes.filter(u => u.unitTypeID == subUnit2TypeID).length == 0) {
                    setValue("product.subSubUnitTypeID", null)
                }
                break;
        }


    }, [parentCategoryID])

    useEffect(() => {
        switch (props.unitCardType) {
            case "subUnit":

                let validParentTypes = props.viewModel.unitParentTypes.slice(getUnitTypeParentsIndex(unitTypeID))
                let subUnitTypeValid = validParentTypes.includes(props.viewModel.unitParentTypes[getUnitTypeParentsIndex(subUnitTypeID ?? 0)])
                if (!subUnitTypeValid) setValue("product.subUnitTypeID", null)
                setUnitParentTypesDDL(validParentTypes)
                break;
        }
    }, [unitTypeID])


    useEffect(() => {
        switch (props.unitCardType) {
            case "subSubUnit":
                let validParentTypes = props.viewModel.unitParentTypes.slice(getUnitTypeParentsIndex(subUnitTypeID ?? 0))
                let subUnit2TypeValid = validParentTypes.includes(props.viewModel.unitParentTypes[getUnitTypeParentsIndex(subUnit2TypeID ?? 0)])
                if (!subUnit2TypeValid) setValue("product.subSubUnitTypeID", null)
                setUnitParentTypesDDL(validParentTypes)
                break;
        }
    }, [subUnitTypeID])

    function getUnitTypeParentsIndex(unitTypeID: number) {
        let unitParentTypeID = props.viewModel.unitTypes.find(u => u.unitTypeID == unitTypeID)?.unitParentTypeID;
        return props.viewModel.unitParentTypes.findIndex(up => up.unitParentTypeID == unitParentTypeID)
    }

    return (
        <>
            {props.unitCardType != "unit" &&
                <CloseButton disabled={!isEdittableContext.isEdittable} onClick={() => { props.closeCard ? props.closeCard() : null }} />}
            <Grid container spacing={1} height={"100%"} data-testid="unit-card">
                {props.unitCardType != "unit" &&
                    <Grid item xs={5}>
                        <StyledControlledFormInput name={"product." + props.unitCardType} label="Amount" type="number" />
                    </Grid>
                }
                <Grid item xs={7}>
                    <StyledControlledSelectInput
                        label="Unit"
                        name={'product.' + props.unitCardType + 'TypeID'}
                    >
                        {unitParentTypesDDL.filter(up => unitTypesDDL.filter(ut => ut.unitParentTypeID == up.unitParentTypeID).length > 0).map((up: UnitParentType, index) => {
                            return (
                                [
                                    <ListSubheader key={`unitParentType${index}`}>{up.unitParentTypeDescription}</ListSubheader>,
                                    unitTypesDDL.filter(ut => ut.unitParentTypeID == up.unitParentTypeID).map((ut: UnitType, i) => {
                                        return (<MenuItem key={`unitType${i}`} value={ut.unitTypeID}>
                                            {ut.unitTypeDescription.toString()}
                                        </MenuItem>
                                        )
                                    })
                                ]
                            )
                        })}
                    </StyledControlledSelectInput>

                </Grid>

            </Grid>
        </>
    )
}