import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalName } from "../Constants/app-enums";
import { ModalSizes } from "../Constants/client-side-enums";
import { selectModalPresent } from "../ReduxRelatedUtils/customConfigureStore";
import { setModalLoading } from "../ReduxRelatedUtils/modals-reducer";
import { ApplicationState, Modal } from "../ReduxRelatedUtils/redux-types";
import GlobalModal from "../Requests/Modals/global-modal";
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from "../Requests/Modals/modal-components";
import ModalSkeleton from "../Requests/Modals/modal-skeleton";
import { BasicModalInfo, CustomError } from "../Utility/general-types";
import { basicModalPost } from "../Utility/root-function";



export default function DenyHoursModal() {
    const dispatch = useDispatch()

    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const {modalInfo} = useSelector<ApplicationState>(state => selectModalPresent(state)) as Modal<BasicModalInfo>;  
      const [error, setError] = useState<CustomError>(new CustomError())

    useEffect(() => {
        dispatch(setModalLoading(false))
    }, []);


    function onSubmit(e:any) {
        e.preventDefault()
        var url = "/Users/DenyApprovalRequest?ehaaID=" + modalInfo.ids[0];
        basicModalPost(url, setError, dispatch)
    }

    return (
        <GlobalModal modalKey={ModalName.DenyHours} size={ModalSizes.sm}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText="Deny" errorMessage={error.message} />
                    <StyledDialogContent>
                        {error.showBody ?
                            <form onSubmit={onSubmit} id={ModalName.DenyHours}>
                                <Typography>Are you sure you would like to deny this request?</Typography>
                            </form>
                            : null
                        }
                    </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.DenyHours} submitButtonText={"Deny"} />
                </>
            }
        </GlobalModal>
    )
}