import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { push } from "connected-react-router";
import queryString from 'query-string';
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FieldError } from "react-hook-form/dist/types";
import { batch, useDispatch, useSelector } from "react-redux";
import { SidebarEnum, SvgNames } from "../Constants/app-enums";
import { CardTabsType, TabsName } from "../Constants/client-side-enums";
import { selectNavigationInfo } from "../ReduxRelatedUtils/customConfigureStore";
import { setError } from "../ReduxRelatedUtils/errorReducer";
import { setGlobalSnack } from "../ReduxRelatedUtils/globalSnackReducer";
import { setLoading, setPageSkeleton, setShowConfirmExit } from "../ReduxRelatedUtils/utilsReducer";
import { ApplicationState, NavigationInfo } from "../ReduxRelatedUtils/redux-types";
import { setViewModel } from "../ReduxRelatedUtils/viewmodel-reducer";
import CommentsTab from "../Requests/ItemCard/comments-tab";
import AddCardForm from "../Shared/AddCardComponents/add-card";
import { AddSupplierViewModel, AddSupplierFormData, AddCardType } from "../Shared/AddCardComponents/add-types";
import { CardTabs, GlobalTab, Tab } from "../Shared/AddCardComponents/card-tabs";
import IndexTableSkeleton from "../Shared/IndexTable/index-table-skeleton";
import { Comment } from "../Utility/general-types";
import { Country, Vendor } from "../Utility/request-types";
import { StyledFilledButton, TitleTypography } from "../Utility/shared-components";
import { CategoryType, VendorContact } from "../Utility/supplier-types";
import DetailsTab from "./details-tab";
import PaymentTab from "./payment-tab";
import SupplierTabs, { supplierTabInfo } from "./supplier-card-tabs";
import VendorContactsTab from "./vendor-contacts-tab";





export function AddSupplierInnerForm() {
    const viewModel = useSelector<ApplicationState>(state => state.viewModel) as AddSupplierViewModel;
    const location = useSelector<ApplicationState>(state => state.router.location) as any;
    const showPageSkeleton = useSelector<ApplicationState>(state => state.showPageSkeleton)


    //console.log(viewModel);
    const { control, formState, watch, getValues, reset, setValue, handleSubmit } = useFormContext<AddSupplierFormData>();
    const dispatch = useDispatch();

    const vendorContacts = watch("vendorContacts")

    useEffect(() => {
        dispatch(setPageSkeleton(true))

        fetch("/Vendors/Create", {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.text() }
            })
            .then((result: AddSupplierViewModel) => {
                dispatch(setViewModel(result));
                dispatch(setPageSkeleton(false))

            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setError({ message: text.toString(), showBody: false }))
                })
            })

    }, [])



    return (
        showPageSkeleton ?
        <Box padding="2rem" height="75vh" overflow={"hidden"}>
                <IndexTableSkeleton />
            </Box>
            :
            <>
                <Box sx={{ padding: "3rem" }}>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <TitleTypography>New Supplier</TitleTypography>
                        <StyledFilledButton className={(formState.isSubmitting || !formState.isValid || vendorContacts.length == 0) ? "disabled-section-button" : ""} type="submit">Save</StyledFilledButton>

                    </Stack>
                </Box>
                <CardTabs key={CardTabsType.AddCard} cardTabType={CardTabsType.AddCard} selectedTab={0} tabs={supplierTabInfo} >
                    <SupplierTabs key={"itemtabs"} viewModel={viewModel} />
                </CardTabs>
            </>

    )
}

const extractValues = (obj: any): string[] => {
    const values = [];

    for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object') {
            if ("message" in (value ?? {})) {
                values.push((value as FieldError).message)
            }
            else {
                values.push(...extractValues(value));
            }
        }
    }

    return values as string[];
}

export default function AddSupplier() {
    const dispatch = useDispatch();
    const navigationInfo = useSelector(selectNavigationInfo) as NavigationInfo

    function onSubmit(data: AddSupplierFormData, e: any) {
        console.log("submitting form")
        console.log(data)

        dispatch(setLoading(true))
        fetch("/Vendors/Create", {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        }).then(response => {
            if (response.ok) { return response.json() }
            else { throw response.text() }
        }).then((result) => {
            batch(() => {
                dispatch(setLoading(false))
                dispatch(setGlobalSnack({ open: true, message: "Supplier Added" }))
                dispatch(push("/SuppliersList?" + queryString.stringify({ ...navigationInfo, sidebarType: SidebarEnum.AllSuppliers } as NavigationInfo)))
                dispatch(setShowConfirmExit(false))
            })
        })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, severity: "error", message: text }))
                })
            })
    }

    return (
        <AddCardForm addType={AddCardType.AddSupplier} onSubmit={(data, e) => onSubmit(data, e)} />

    )
}



