import { useSelector } from 'react-redux';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState } from '../../ReduxRelatedUtils/redux-types';
import GlobalModal from './global-modal';
import MoveToListModal from './move-to-list-modal';
import NewListModal from './new-list-modal';
import ReceiveLocationModal from './receive-location-modal';
import ReceiveModal from './receive-modal';



export default function ReceiveItemFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName

    //console.log("in receive item flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.ReceiveItem:
                return (<ReceiveModal />)
            case ModalName.ReceiveLocation:
                return (<ReceiveLocationModal />)
            default: return null;
        }
    }

    return (
        <GlobalModal size={ModalSizes.md} modalKey={modalStep} height='65vh'>
            <ModalContent />
        </GlobalModal>
    );

}
