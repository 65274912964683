import { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useForm } from 'react-hook-form';
import { batch, useDispatch, useSelector } from 'react-redux';
import 'regenerator-runtime/runtime';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { setGlobalSnack } from '../../ReduxRelatedUtils/globalSnackReducer';
import { setReloadIndex } from '../../ReduxRelatedUtils/index-table-reducer';
import { removeModal, setModalLoading } from '../../ReduxRelatedUtils/modals-reducer';
import { ApplicationState, Modal } from '../../ReduxRelatedUtils/redux-types';
import { BasicModalInfo, CustomError } from '../../Utility/general-types';
import { getElixirDateFormat } from '../../Utility/root-function';
import { GlobalForm } from '../../Utility/shared-components';
import GlobalModal from './global-modal';
import { ModalFooterWithSave, ModalHeader, StyledDialogContent } from './modal-components';
import ModalSkeleton from './modal-skeleton';
import { Typography } from '@mui/material';
import { setLoading } from "../../ReduxRelatedUtils/utilsReducer";


type OrderLateViewModel = {
    vendorName: string,
    userFullName: string,
    productName: string,
    orderNumber: number,
    expectedArrivalDate: Date
}

export default function OrderLateModal() {
    const dispatch = useDispatch()
    
    const loading = useSelector<ApplicationState>(state => state.modalWithLoading.modalLoading);
    const {modalInfo} = useSelector<ApplicationState>(selectModalPresent) as Modal<BasicModalInfo>;  
    const [viewModel, setViewModel] = useState( {} as OrderLateViewModel)
    const [error, setError] = useState<CustomError>(new CustomError())
    
    useEffect(() => {
        var url = "/Requests/OrderLateModal?id=" + modalInfo.ids[0];
        //console.log(modalInfo.ids)
        fetch(url, {
            method: "GET"
        })
            .then((response) => {
                if (response.ok) { return response.json() }
                else { throw response.json() }
            })
            .then((result: OrderLateViewModel) => {
                setViewModel(result);
                console.log(viewModel)
                dispatch(setModalLoading(false))

            })
            .catch(err => {
                Promise.resolve(err).then(viewModel => {
                    setError( { message: viewModel.errorMessage, showBody: false })
                    dispatch(setModalLoading(false))
                })
            })

    }, [modalInfo.ids]);
    
    const methods = useForm(
        {defaultValues: {
            requestId: modalInfo.ids[0]
        }}
    );

    function onSubmit(data: any) {
        var url = "/Requests/OrderLateModal"
        dispatch(setLoading(true))
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json; charset=UTF-8', "Accept": "application/json", }
        })
            .then(response => {
                if (response.ok) { return response.text() }
                throw response.text();
            })
            .then((res: string) => {
                batch(() => {
                    console.log('got to res')

                    dispatch(setLoading(false))
                    dispatch(removeModal())
                    dispatch(setGlobalSnack({ open: true, message: "Email Sent" }))
                })
                
            })
            .catch(err => {
                Promise.resolve(err).then(text => {
                    console.log('got to catch')
                    dispatch(setLoading(false))
                    dispatch(setGlobalSnack({ open: true, message: text, severity: "error" }))
                })
            })
        
    }

    return (
        <GlobalModal modalKey={ModalName.OrderLate} size={ModalSizes.sm}>
            {loading ?
                <ModalSkeleton />
                :
                <>
                    <ModalHeader headerText={"Send Message To " + viewModel.vendorName} errorMessage={error.message} />
                {error.showBody ?
                <>
                    <StyledDialogContent>
                            <GlobalForm onSubmit={onSubmit} formID={ModalName.OrderLate} methods={methods}>
                                <Typography> 
                                    Hello, <br /> <br /> Order number {viewModel.orderNumber} for {viewModel.productName} which 
                                    was scheduled to arrive on {getElixirDateFormat(viewModel.expectedArrivalDate)} has not arrived yet.<br />
                                    Please update us on the matter.<br /><br/>
                                    Best regards,<br />
                                    {viewModel.userFullName}<br />
                                    Centarix
                                </Typography>
                            </GlobalForm>
                        
                    </StyledDialogContent>
                    <ModalFooterWithSave formID={ModalName.OrderLate} submitButtonText={"Send"} />
                </>
                    : null}
                </>
            }
        </GlobalModal>
    )
}