import { useState, useEffect } from "react";
import { SvgNames } from "../Constants/app-enums";
import { TabsName } from "../Constants/client-side-enums";
import CommentsTab from "../Requests/ItemCard/comments-tab";
import { AddSupplierViewModel } from "../Shared/AddCardComponents/add-types";
import { GlobalTab, Tab } from "../Shared/AddCardComponents/card-tabs";
import DetailsTab from "./details-tab";
import PaymentTab from "./payment-tab";
import VendorContactsTab from "./vendor-contacts-tab";

export const supplierTabInfo = [
    { icon: SvgNames.Storefront, name: TabsName.Details },
    { icon: SvgNames.MonetizationOn, name: TabsName.Payment },
    { icon: SvgNames.CentarixComments, name: TabsName.Comments },
    { icon: SvgNames.AccountBox, name:TabsName.Contact },
] as Tab[]


export default function SupplierTabs(props: {  viewModel: AddSupplierViewModel}) {
    const [tabComponents, setTabComponents] = useState<JSX.Element[]>([]);
    
    useEffect(() => {
        let localTabs = [];
        for (let i = 0; i <supplierTabInfo.length; i++) {
            localTabs.push(
                <GlobalTab key={supplierTabInfo[i].name} tab={supplierTabInfo[i]} index={i}>
                    <TabInnerComponent currentTab={supplierTabInfo[i]} viewModel={props.viewModel} />
                </GlobalTab>)
        }

        setTabComponents(localTabs);
    }, [])

    function TabInnerComponent(props: { currentTab: Tab, viewModel: AddSupplierViewModel}) {

        switch (props.currentTab.name) {
            case TabsName.Details:
                return <DetailsTab {...props.viewModel} />
            case TabsName.Payment:
                return <PaymentTab />
            case TabsName.Comments:
                return <CommentsTab/>
            case TabsName.Contact:
                return <VendorContactsTab />
            default: return <></>
        }
    }
    return (<>{tabComponents}</>);
}