import { useSelector } from 'react-redux';
import { ModalName } from '../../Constants/app-enums';
import { ModalSizes } from '../../Constants/client-side-enums';
import { selectModalPresent } from '../../ReduxRelatedUtils/customConfigureStore';
import { ApplicationState } from '../../ReduxRelatedUtils/redux-types';
import GlobalModal from './global-modal';
import MoveToListModal from './move-to-list-modal';
import NewListModal from './new-list-modal';



export default function ListModalFlow() {
    const modalStep = useSelector<ApplicationState>(state => selectModalPresent(state)?.modalStep) as ModalName

    console.log("in list modal flow")
    function ModalContent() {
        switch (modalStep) {
            case ModalName.MoveToList:
                return (<MoveToListModal />)
            case ModalName.NewList:
                return (<NewListModal />)
            default: return null;
        }
    }

    return (
        <GlobalModal size={ModalSizes.xs} modalKey={modalStep}>
            <ModalContent />
        </GlobalModal>
    );

}
